import * as Logger from 'js-logger';
import * as Sentry from '@sentry/react';
import { Breadcrumb } from '@sentry/react';

export const setupDefaultLogger = () => {
  const consoleHandler = Logger.createDefaultHandler();
  const sentryHandler = (message: string) => {
    const breadcrumb: Breadcrumb = {
      type: 'debug',
      category: 'logger',
      message: message,
    };

    Sentry.addBreadcrumb(breadcrumb);
  };
  Logger.setHandler(function (messages, context) {
    sentryHandler(messages[0]);
    consoleHandler(messages, context);
  });
};

export interface IWrapperLogger {
  info(msg: string): void;
  error(msg: string, error?: Error): void;
  debug(msg: any): void;
}

class LoggerWrapper implements IWrapperLogger {
  private loggerImpl: any;

  constructor(loggerImpl: any) {
    if (loggerImpl == null) {
      throw Error('Logger is not configured');
    }
    this.loggerImpl = loggerImpl;
  }

  public info(msg: any) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.loggerImpl.info(msg);
    } catch {}
  }

  public debug(...args: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.loggerImpl.debug(...args);
  }

  public dir(msg: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.loggerImpl.dir(msg);
  }

  public error(msg: string, error?: any) {
    const message = `${msg}. ${error && `Details: ${error.message}`}`;
    Sentry.captureException(error || new Error(message), (context) => {
      context.setTag('Logger-Error', 'JS');
      return context;
    });
    this.loggerImpl.error(message);
  }
}

export const logger = () => {
  return new LoggerWrapper(console);
};
