﻿import React, { FC } from 'react';
import { IAdminStudyDto } from '../../../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import { ExpandedRowHeader } from '../../../../components/expandedRow/header/expandedRowHeader.component';
import { AppButton } from 'uikit/buttons';
import { ReactComponent as EditIcon } from 'src/assets/img/common/edit_20.svg';
import ExpandedRowSections from 'uikit/table/ExpandedRowSections';
import PropertiesList from 'uikit/propertiesList/PropertiesList';

export const StudyConfigurationSection: FC<{ study: IAdminStudyDto; onEdit: () => void }> = ({ study, onEdit }) => {
  const { t } = useTranslation('dev');

  return (
    <ExpandedRowSections.Section>
      <ExpandedRowHeader
        text={t('Admin.StudyList.Configuration.Caption')}
        rightHeader={
          <AppButton
            onClick={onEdit}
            variant={'icon-link'}
            colorSchema={'primary'}
            text={t('Admin.StudyList.Configuration.Action')}
            Icon={EditIcon}
          />
        }
      />
      <PropertiesList>
        <PropertiesList.Property
          name={t('Admin.StudyList.Configuration.TherapyAggregation')}
          value={study.therapyAggregationPeriod}
        />
        <PropertiesList.Property
          name={t('Admin.StudyList.Configuration.PatientStudyStartAdvanceDays')}
          value={study.patientStudyStartAdvanceDays}
        />
        <PropertiesList.Property
          name={t('Admin.StudyList.Configuration.HasTherapy')}
          value={JSON.stringify(study.hasTherapy)}
        />
        <PropertiesList.Property
          name={t('Admin.StudyList.Configuration.HasMonitoring')}
          value={JSON.stringify(study.hasMonitoring)}
        />
        <PropertiesList.Property
          name={t('Admin.StudyList.Configuration.HasPdfSigning')}
          value={JSON.stringify(study.hasPdfSigning)}
        />
        <PropertiesList.Property
          name={t('Admin.StudyList.Configuration.HasDbImport')}
          value={JSON.stringify(study.hasDbImport)}
        />
        <PropertiesList.Property
          name={t('Admin.StudyList.Configuration.HasSurveys')}
          value={JSON.stringify(study.hasSurveys)}
        />
        <PropertiesList.Property
          name={t('Admin.StudyList.Configuration.HasEcrf')}
          value={JSON.stringify(study.hasEcrf)}
        />
      </PropertiesList>
    </ExpandedRowSections.Section>
  );
};
