import clsx from 'clsx';
import React from 'react';
import ReactPaginate from 'react-paginate';

import styles from './AppPagination.module.scss';

export type AppPaginationProps<TPagination extends { page?: number }> = {
  page: number;
  perPage: number;
  totalCount: number;
  changePagination: (action: (old: TPagination) => TPagination & { page: number }) => void;
  className?: string;
};

export const AppPagination = <TPagination extends { page?: number; perPage?: number }>({
  perPage,
  changePagination,
  totalCount,
  page,
  className,
}: AppPaginationProps<TPagination>) => {
  const pageCount = Math.ceil((totalCount ?? 0) / perPage);

  if (totalCount === 0 || pageCount < 2) {
    return null;
  }

  return (
    <div className={clsx(styles.container, className)}>
      <ReactPaginate
        pageCount={pageCount}
        forcePage={page - 1}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        previousLabel={<></>}
        nextLabel={<></>}
        breakLabel={'...'}
        breakClassName={styles.break}
        containerClassName={styles.paginationContainer}
        pageLinkClassName={styles.page}
        activeLinkClassName={styles.activePage}
        nextLinkClassName={styles.pageIcon}
        previousLinkClassName={styles.pageIcon}
        onPageChange={(e) => {
          changePagination((old) => ({
            ...old,
            page: e.selected + 1,
          }));
        }}
      />
    </div>
  );
};
