﻿import React, { FC, PropsWithChildren } from 'react';
import styles from './RepeatableFormSection.module.scss';
import { ReactComponent as Cross } from 'src/assets/img/common/cross_20.svg';
import { AppButton } from 'uikit/buttons';
import { TypographyStyles } from 'styles';
import clsx from 'clsx';

type RepeatableFormSectionProps = PropsWithChildren<{
  title: string;
  withBackground?: boolean;
  onDelete: () => void;
}>;

export const RepeatableFormSection: FC<RepeatableFormSectionProps> = (props) => {
  return (
    <div className={clsx(styles.repeatableFormSection, { [styles.withBackground]: props.withBackground })}>
      <div className={styles.header}>
        <span className={TypographyStyles.heading2}>{props.title}</span>
        <AppButton
          variant={'icon-link'}
          iconClassName={styles.crossIcon}
          colorSchema={'primary'}
          Icon={Cross}
          onClick={props.onDelete}
        />
      </div>
      {props.children}
    </div>
  );
};
