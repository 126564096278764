import React, { useMemo, useState } from 'react';
import { DateFormats, localFormat } from '../../../../helpers/date-helpers';
import clsx from 'clsx';
import { TypographyStyles } from 'styles';
import Style from './RecentUpdatesColumn.module.scss';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { Loader } from 'uikit/loader/loader.component';
import { Trans, useTranslation } from 'react-i18next';
import { PatientChangesEvent } from '../../../../services/api/api-client';
import { useGetPatientChangesInfoQuery } from '../../../../services/api/api-client/PatientQuery';
import { LocalizedResourceDictionaryKeys } from '../../../../application/localisation/i18next';

export const RecentUpdatesColumn = (props: { date: Date; patientId: string }) => {
  const { date, patientId } = props;
  const { t } = useTranslation();
  const [isHover, setHover] = useState(false);
  const changesInfoRequest = useGetPatientChangesInfoQuery(patientId, { suspense: false, enabled: isHover });

  const changesData = useMemo(() => {
    if (changesInfoRequest.isInitialLoading) {
      return <Loader />;
    }

    if (changesInfoRequest.data) {
      return (
        <div className={Style.tooltipContainer}>
          <div>
            <Trans
              i18nKey={'Dashboard.PatientTable.RecentUpdate.Person'}
              values={{ value: changesInfoRequest.data.modifyBy || '' }}
            >
              <span className={Style.tooltipHeaderText}>
                {t('Dashboard.PatientTable.RecentUpdate.Person', { value: changesInfoRequest.data.modifyBy })}
              </span>
            </Trans>
          </div>
          <div>
            <Trans
              i18nKey={
                `Dashboard.PatientTable.RecentUpdate.Events.${
                  PatientChangesEvent[changesInfoRequest.data.modifyEvent]
                }` as LocalizedResourceDictionaryKeys
              }
              values={{ value: changesInfoRequest.data.additionalInfo || '' }}
            >
              <span className={Style.tooltipHeaderText} />
            </Trans>
          </div>
        </div>
      );
    }

    return <span>{t('Common_Error')}</span>;
  }, [changesInfoRequest.isInitialLoading, changesInfoRequest.data, t]);

  return (
    <Tooltip text={''} hostStyles={Style.tooltipHost} tooltipContent={changesData}>
      <div className={Style.container} onMouseEnter={() => setHover(true)}>
        <div className={Style.date}>{localFormat(date, DateFormats.longDate)}</div>
        <div className={Style.time}>{localFormat(date, DateFormats.timeOnly)}</div>
      </div>
    </Tooltip>
  );
};
