import React from 'react';
import Style from './navAppBar.module.css';
import { useTranslation } from 'react-i18next';
import { NavButton } from './navButton.component';
import { AppRoutes } from '../../navigation/routes';
import { PermissionsCheck } from 'src/helpers/components/PermissionCheck/PermissionCheck.component';
import { Permissions } from 'src/services/api/api-client';

export const NavAdminBar = () => {
  const { t } = useTranslation();

  return (
    <div className={Style.secondaryNavContainer}>
      <div className={Style.tabsContainer}>
        <PermissionsCheck permissions={Permissions.StudyManage}>
          <NavButton title={t('NavMenu.Tabs.Studies')} route={AppRoutes.AdminStudies} variant={'secondary'} />
        </PermissionsCheck>
        <PermissionsCheck permissions={Permissions.UserManage}>
          <NavButton title={t('NavMenu.Tabs.Users')} route={AppRoutes.Users} variant={'secondary'} />
        </PermissionsCheck>
        <PermissionsCheck permissions={Permissions.RoleAssign}>
          <NavButton title={t('NavMenu.Tabs.Roles')} route={AppRoutes.Roles} variant={'secondary'} />
        </PermissionsCheck>
      </div>
    </div>
  );
};
