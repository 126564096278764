import React, { useMemo } from 'react';
import { IIndicatorModel } from '../../../services/api/api-client';
import { ReactComponent as AHIIcon } from '../../../assets/img/patient/indicators/ahi_36.svg';
import { useTranslation } from 'react-i18next';
import Style from '../baseIndicator.module.css';
import clsx from 'clsx';
import { WarningSignIndicator } from '../warning.component';
import { getIndicatorStyleColor } from '../../../helpers/color-helper';
import { TherapyTooltipComponent } from '../therapyTooltip/therapyTooltip.component';

export const AHIIndicator = (props: { indicator?: IIndicatorModel | null }) => {
  const { t } = useTranslation();

  const value = useMemo(() => {
    if (props?.indicator?.value || props?.indicator?.value === 0) {
      return t('Common_AHI', { value: Math.round(props?.indicator?.value) });
    }

    return '';
  }, [t, props?.indicator?.value]);

  const icon = useMemo(() => {
    if (props?.indicator?.therapyIsNotReceivedForAggregatedPeriod) {
      return <WarningSignIndicator />;
    }

    return (
      <AHIIcon
        className={clsx(getIndicatorStyleColor(props?.indicator?.qualityMetric), Style.base, Style.defaultCursor)}
      />
    );
  }, [props?.indicator?.qualityMetric, props?.indicator?.therapyIsNotReceivedForAggregatedPeriod]);

  const durationText = useMemo(() => {
    if (
      props?.indicator?.deviceIsNotUsedForAggregatedPeriod ||
      props?.indicator?.therapyIsNotReceivedForAggregatedPeriod ||
      props?.indicator?.telemonitoringIsDisabled
    ) {
      return null;
    }

    return value;
  }, [
    props?.indicator?.deviceIsNotUsedForAggregatedPeriod,
    props?.indicator?.therapyIsNotReceivedForAggregatedPeriod,
    props?.indicator?.telemonitoringIsDisabled,
    value,
  ]);

  return (
    <TherapyTooltipComponent
      indicator={props?.indicator}
      tooltipValueText={value}
      testId={'ahi'}
      Icon={AHIIcon}
      values={props?.indicator?.therapyDayValues || []}
    >
      <div className={Style.indicator}>
        {icon}
        {durationText && <span className={Style.durationText}>{durationText}</span>}
      </div>
    </TherapyTooltipComponent>
  );
};
