import { BooleanParam, QueryParamConfig, StringParam } from 'use-query-params';
import { CardSectionsEnum, TableColumnsEnum } from 'src/services/api/api-client';
import { hasFlag } from 'src/helpers/enum-helper';
import { DropdownOption } from '../../../components/uikit/inputs/dropdown/appDropdownInput';

export const studyFilterParams = () => ({
  withFailedToken: BooleanParam,

  searchQuery: StringParam,
});

export type StudyFilterQueryParameters = {
  withFailedToken: QueryParamConfig<boolean | null | undefined, boolean | null | undefined>;
  searchQuery: QueryParamConfig<string | null | undefined, string | null | undefined>;
};

export function studyFilterToBackendRequest(query: { withFailedToken?: boolean | null }): {
  withFailedToken?: boolean | null;
} {
  return {
    withFailedToken: query.withFailedToken ?? undefined,
  };
}

export type PatientCardOption = DropdownOption<string> & {
  column: number;
  card: CardSectionsEnum;
};

export function mapPatientCardsToDropdownOptions(config: CardSectionsEnum[][]): PatientCardOption[] {
  return config.flatMap((column, columnIndex) =>
    column.map((m) => {
      return {
        key: `${columnIndex}_${m}`,
        card: m,
        column: columnIndex,
        text: `[Column ${columnIndex + 1}] ${CardSectionsEnum[m]}`,
      };
    }),
  );
}

export const patientCardsToDropdownOptions = (): PatientCardOption[] => {
  const allCardOptions = Object.keys(CardSectionsEnum)
    .filter((x) => !isNaN(Number(CardSectionsEnum[x])))
    .map((x) => Number(CardSectionsEnum[x]));
  return mapPatientCardsToDropdownOptions([allCardOptions, allCardOptions, allCardOptions]);
};

export function mapTableColumnsToDropdownOptions(columns: TableColumnsEnum) {
  const result = [];
  for (const tableColumnsOption of tableColumnsOptions()) {
    if (hasFlag(columns, tableColumnsOption.value)) {
      result.push(tableColumnsOption);
    }
  }

  return result;
}

export const tableColumnsOptions = () => {
  return Object.keys(TableColumnsEnum)
    .filter((x) => !isNaN(Number(TableColumnsEnum[x])))
    .map((x) => Number(TableColumnsEnum[x]))
    .map((x) => {
      return {
        key: x,
        value: x,
        text: TableColumnsEnum[x],
      };
    });
};
