import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { TypographyStyles } from '../../styles';
import { NavButtonProps } from './navButton';

import Style from './navButton.module.css';

export const NavButton = (props: NavButtonProps) => {
  const { route, title, isHidden, testId, variant, replace, end, Icon, styles } = props;

  if (isHidden) {
    return <></>;
  }

  return (
    <NavLink to={route} replace={replace} className={Style.linkOverride} end={end}>
      {({ isActive }) => {
        return (
          <div
            className={clsx(
              Style.navBase,
              TypographyStyles.buttonLinkText,
              {
                [Style.navTab]: variant === 'primary',
                [Style.adminTab]: variant === 'secondary',
                [Style.linkTab]: variant === 'link',
              },
              styles,
            )}
            data-is-active={isActive}
            data-test-id={testId}
          >
            {Icon && <Icon />}
            {title}
          </div>
        );
      }}
    </NavLink>
  );
};
