import React, { useMemo } from 'react';
import { useSkipReasonText } from 'src/components/formEditor/controls/Inputs/base/hooks';
import { useContextSelector } from 'use-context-selector';
import { ITimeInput } from '../../../../forms/base/controls/inputs/TimeInput';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';
import { millsToTimeString } from 'src/helpers/date-helpers';

export const TimeInput: ITimeInput = ({ label, dataKey, skipCheckBoxText }) => {
  const inputValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  const skipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.[dataKey]);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const answerValue = useMemo(() => {
    if (skipReason !== undefined) {
      return skipReasonText;
    }

    return singleInputValue ? millsToTimeString(+singleInputValue) : null;
  }, [singleInputValue, skipReason, skipReasonText]);

  return <Field label={label ?? dataKey} value={answerValue} />;
};
