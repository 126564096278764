import React, { FC } from 'react';
import { RPDF } from '../../../react-pdf';
import { ReportColors } from '../../../report-colors';

export const DoubleCheckIcon: FC = () => {
  return (
    <RPDF.Svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        d="M9.84123 3.02713C10.0722 2.77057 10.0514 2.37539 9.79481 2.14447C9.53825 1.91355 9.14307 1.93433 8.91215 2.19089L4.63585 6.94199C4.40493 7.19855 4.42571 7.59373 4.68228 7.82465C4.93884 8.05557 5.33402 8.03479 5.56494 7.77822L9.84123 3.02713ZM6.84602 3.0384C7.07433 2.77952 7.04954 2.38457 6.79066 2.15626C6.53177 1.92795 6.13682 1.95273 5.90851 2.21162L2.44498 6.13902L1.08338 4.67006C0.848726 4.4169 0.453283 4.40191 0.200129 4.63656C-0.0530242 4.87121 -0.0680232 5.26665 0.166628 5.51981L1.99815 7.49574L2.46806 8.00271L2.92528 7.48426L6.84602 3.0384Z"
        fill={ReportColors.green500}
      />
    </RPDF.Svg>
  );
};
