import { useState, useEffect, SetStateAction, Dispatch } from 'react';

function getStorageValue<T>(key: string, defaultValue: T) {
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved!);
  return initial === undefined || initial === null ? defaultValue : initial;
}

export function useLocalStorage<T>(key: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => getStorageValue(key, defaultValue));

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
