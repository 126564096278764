import { createId } from 'src/helpers/typeUtils';
import { SelectionData } from '../selectionData';
import { isNullOrEmpty } from 'src/helpers/string-helper';

export function cloneSelectionData(): SelectionData | null {
  const selection = window.getSelection();

  if (selection && selection.rangeCount > 0) {
    const ranges = [...new Array(selection.rangeCount)].map((_, i) => selection.getRangeAt(i).cloneRange());

    const copiedSelection: SelectionData = {
      text: selection.toString(),
      ranges: ranges,
    };

    return copiedSelection;
  }

  return null;
}

export function makeSelectionLink(linkValue: string, oldSelection: SelectionData) {
  if (oldSelection?.ranges) {
    restoreSelection(oldSelection?.ranges);
    const newSelection = window.getSelection();
    if (newSelection) {
      const range = newSelection.getRangeAt(0);
      const link = document.createElement('a');
      link.href = linkValue;
      link.target = '_blank';
      link.id = createId();
      link.textContent = range.toString();
      range.deleteContents();
      range.insertNode(link);
    }
  }
}

export function restoreSelection(ranges: Range[]) {
  const selection = window.getSelection();
  selection?.removeAllRanges();

  ranges.forEach((range) => {
    if (range.startContainer && range.endContainer) {
      selection?.addRange(range);
    }
  });
}

export function getLinkByStartContainer(selection: Selection): string | null {
  let node = getNodeByStartContainer(selection);

  if (!node?.parentElement) return null;

  if (node?.nodeName !== 'A') return null;

  const anchor = node as HTMLAnchorElement;

  return anchor.href;
}

export function getLinkByQuerySelection(selection: Selection): string | null {
  const anchor = getNodeByQuerySelection(selection) as HTMLAnchorElement;

  if (!anchor) return null;

  const link = anchor.href;

  return link;
}

export function getNodeByQuerySelection(selection: Selection): Node | null {
  if (!selection?.rangeCount) return null;

  const range = selection.getRangeAt(0);

  const fragment = range.cloneContents();

  const anchors = fragment.querySelectorAll('a');

  if (anchors.length === 0) return null;

  const anchor = anchors[0];

  let commonAncestor: Node | null = range.commonAncestorContainer;

  if (commonAncestor.nodeType === Node.TEXT_NODE) commonAncestor = commonAncestor.parentNode;

  if (!commonAncestor) return null;

  const fullAnchor = findNodeInParentNode(commonAncestor, anchor);

  return fullAnchor;
}

export function findNodeInParentNode(parentNode: Node, targetNode: Node): Node | null {
  if (!(parentNode instanceof HTMLElement) || !(targetNode instanceof HTMLElement)) return null;

  const range = document.createRange();
  range.selectNodeContents(parentNode);

  if (targetNode.id === parentNode.id) {
    return parentNode;
  }

  for (let child of parentNode.children) {
    const foundNode = findNodeInParentNode(child as HTMLElement, targetNode);
    if (foundNode) return foundNode;
  }

  return null;
}

export function selectionHasOneLink(selection: Selection): boolean {
  if (!selection?.rangeCount) return false;

  const range = selection.getRangeAt(0);

  const fragment = range.cloneContents();

  const anchors = fragment.querySelectorAll('a');

  if (anchors.length > 1) return false;

  return true;
}

export function getNodeByStartContainer(selection: Selection): Node | null {
  if (!selection?.rangeCount) return null;

  const range = selection.getRangeAt(0);
  let node = range.startContainer;

  return node.parentElement;
}

export function getAnchorNode(selection: Selection): Node | null {
  let nodeByRange = getNodeByStartContainer(selection);
  let nodeByQuery = getNodeByQuerySelection(selection);

  if (nodeByRange?.nodeName === 'A') {
    return nodeByRange;
  } else if (nodeByQuery?.nodeName === 'A') {
    return nodeByQuery;
  }

  return null;
}

export function deleteLink(selectionRanges: Range[]): void {
  restoreSelection(selectionRanges);
  const newSelection = window.getSelection();

  if (!newSelection) return;

  let node = getAnchorNode(newSelection) as HTMLAnchorElement | null;

  if (!node) return;

  if (node.nodeName !== 'A') return;

  node.replaceWith(...node.childNodes);
}

export function getLinkFromSelection(selection: Selection): string | null {
  const linkByQuery = getLinkByQuerySelection(selection);
  const linkByParent = getLinkByStartContainer(selection);

  const currentLink = linkByQuery === null ? linkByParent : linkByQuery;

  if (!currentLink) return null;

  return currentLink;
}

export function editCurrentLink(oldSelection: SelectionData, link: string) {
  if (!oldSelection) return;

  restoreSelection(oldSelection.ranges);

  const newSelection = window.getSelection();

  if (!newSelection) return;

  const node = getAnchorNode(newSelection) as HTMLAnchorElement;

  node.href = link;
}

export function checkLinkForDeleting(oldLink: string, newLink: string): boolean {
  const isLinkDifferent = newLink !== oldLink;

  return isNullOrEmpty(newLink) && isLinkDifferent;
}

/*
    The regular expression is taken from here:
    https://learn.microsoft.com/en-us/previous-versions/msp-n-p/ff650303(v=pandp.10)?redirectedfrom=MSDN#:~:text=%5E(ht%7Cf)tp(s%3F)%5C%3A%5C/%5C/%5B0%2D9a%2DzA%2DZ%5D(%5B%2D.%5Cw%5D*%5B0%2D9a%2DzA%2DZ%5D)*(%3A(0%2D9)*)*(%5C/%3F)(%5Ba%2DzA%2DZ0%2D9%5C%2D%5C.%5C%3F%5C%2C%5C%27%5C/%5C%5C%5C%2B%26amp%3B%25%5C%24%23_%5D*)%3F%24
*/

export function validateUrl(link: string): boolean {
  const urlCheckRegex = new RegExp(
    "(ht|f)tp(s?)://[0-9a-zA-Z]([-.w]*[0-9a-zA-Z])*(:(0-9)*)*(/?)([a-zA-Z0-9-.?,'/\\+&amp;%$#_]*)?",
  );
  const isUrlValid = urlCheckRegex.test(link);

  return isUrlValid;
}
