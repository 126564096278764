import { appReducer } from '../../features/app/app-reducer';
import { RootState } from './store-types';
import { combineReducers } from 'redux';
import { CombinedState, createAction, Reducer } from '@reduxjs/toolkit';
import { faqReducer } from '../../features/faq/faq-reducer';

const combinedReducer: Reducer<CombinedState<RootState>> = combineReducers({
  app: appReducer,
  faq: faqReducer,
});

export const logoutAction = createAction('logout');

export const rootReducer: Reducer<CombinedState<RootState>> = (state, action) => {
  if (action.type === logoutAction.type) {
    state = undefined;
  }

  return combinedReducer(state, action);
};
