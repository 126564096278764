import React, { FC, PropsWithChildren, useCallback } from 'react';
import { BufferItemType, FormBuilderContext } from './formBuilder.provider';
import { useLocalStorage } from 'src/helpers/useLocalStorage';

export const FormBuilderContextProvider: FC<PropsWithChildren> = (props) => {
  const [buffer, setBuffer] = useLocalStorage<BufferItemType[]>('Buffer', []);
  const addToBuffer = useCallback(
    (nodeTree: BufferItemType) => {
      setBuffer((state) => [...state, nodeTree]);
    },
    [setBuffer],
  );
  const removeFromBuffer = useCallback(
    (name: string) => {
      setBuffer((state) => state.filter((x) => x.name !== name));
    },
    [setBuffer],
  );

  return (
    <FormBuilderContext.Provider
      value={{
        buffer,
        addToBuffer,
        removeFromBuffer,
      }}
    >
      {props.children}
    </FormBuilderContext.Provider>
  );
};
