import React, { FC } from 'react';
import { RPDF } from '../../../react-pdf';

export const EditIcon: FC = () => {
  return (
    <RPDF.Svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        d="M9.10439 1.14648C8.90913 0.951215 8.59255 0.951215 8.39729 1.14648L7.14704 2.39672L4.3965 5.14725C4.31788 5.22587 4.26771 5.32846 4.25391 5.43879L4.00387 7.43918C3.98473 7.59228 4.03735 7.74564 4.14645 7.85475C4.25555 7.96385 4.40892 8.01647 4.56202 7.99733L6.56241 7.74728C6.67274 7.73349 6.77533 7.68332 6.85395 7.6047L9.60449 4.85417L10.8547 3.60392C10.9485 3.51016 11.0012 3.38298 11.0012 3.25037C11.0012 3.11776 10.9485 2.99058 10.8547 2.89682L9.10439 1.14648ZM9.25094 3.79351L8.2077 2.75027L8.75084 2.20714L9.79408 3.25037L9.25094 3.79351ZM7.50059 3.45738L8.54383 4.50061L6.26815 6.77629L5.07588 6.92532L5.22491 5.73305L7.50059 3.45738ZM1.5 2.00003C1.22386 2.00003 1 2.22389 1 2.50003V10.501C1 10.7771 1.22386 11.001 1.5 11.001H9.5C9.77614 11.001 10 10.7771 10 10.501V7.50003C10 7.22389 9.77614 7.00003 9.5 7.00003C9.22386 7.00003 9 7.22389 9 7.50003V10.001H2V3.00003H4.5C4.77614 3.00003 5 2.77618 5 2.50003C5 2.22389 4.77614 2.00003 4.5 2.00003H1.5Z"
        fill="#667878"
      />
    </RPDF.Svg>
  );
};
