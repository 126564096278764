import React, { useCallback, useMemo } from 'react';
import { isConditionalOption } from 'src/helpers/question-helper';
import { ISurveyOption, QuestionScheme, SurveyOption } from 'src/services/api/api-client';
import { useContextSelector } from 'use-context-selector';
import { changeField } from '../../components/editableField/editableField.component';
import { EditableQuestionContext, QuestionSchemeEditorContext } from '../../container/questionSchemeUIEditor.context';
import { OptionToolBoxType } from './optionToolBox';
import Style from './optionToolBox.module.css';
import { AppTextInput } from 'uikit/inputs';
import { AppTextField } from 'uikit/fields';
import { AppInputLabel } from 'uikit/wrappers';
import { AppCheckboxInput } from 'uikit/inputs';

export const OptionToolBox = (props: OptionToolBoxType) => {
  const { optionId } = props;
  const editableQuestion = useContextSelector(EditableQuestionContext, (x) => x.editableQuestion);
  const editOption = useContextSelector(EditableQuestionContext, (x) => x.editOption)!;
  const errors = useContextSelector(QuestionSchemeEditorContext, (x) => x.errors)!;

  const option = useMemo(() => {
    if (!editableQuestion || !editableQuestion.options) {
      return null;
    }

    return editableQuestion?.options[optionId];
  }, [editableQuestion, optionId]);

  const OptionalValueHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!option) {
        return;
      }

      const newValue = e.target.value;
      editOption(optionId, changeField<ISurveyOption>(option, 'optionValue', newValue));
    },
    [editOption, option, optionId],
  );

  return (
    <>
      {option && (
        <div className={Style.container}>
          <AppInputLabel text={'Option text'}>
            <AppTextInput
              value={option.optionText}
              type={'text-area'}
              onChange={(e) => {
                editOption(optionId, changeField<ISurveyOption>(option, 'optionText', e.target.value));
              }}
            />
          </AppInputLabel>

          <AppInputLabel text={'Score value'}>
            <AppTextInput
              value={option.scoreValue}
              type={'number'}
              onChange={(e) => editOption(optionId, changeField<ISurveyOption>(option, 'scoreValue', e.target.value))}
            />
          </AppInputLabel>

          <AppTextField
            value={option.optionValue}
            labelProps={{ text: 'Option value (unique)' }}
            onChange={OptionalValueHandler}
            errorProps={{ errors: errors.optionValue }}
          />
          <AppCheckboxInput
            label={'Mark as answered'}
            checked={option.optionValue === option.answerValue}
            onChange={(e) => {
              editOption(
                optionId,
                changeField<ISurveyOption>(option, 'answerValue', e.target.checked ? option.optionValue : undefined),
              );
            }}
          />
          <AppCheckboxInput
            label={'Mark as default'}
            checked={option.optionValue === option.defaultValue}
            onChange={(e) => {
              editOption(
                optionId,
                changeField<ISurveyOption>(option, 'defaultValue', e.target.checked ? option?.optionValue : undefined),
              );
            }}
          />
          <AppCheckboxInput
            label={'Conditional option'}
            checked={isConditionalOption(option)}
            onChange={(e) => {
              if (e.target.checked) {
                const newOption = new SurveyOption();
                newOption.init({
                  ...option,
                  questionScheme: new QuestionScheme({ version: '', description: '', legend: '', questions: [] }),
                });
                editOption(optionId, newOption);
              } else {
                const newOption = new SurveyOption();
                newOption.init({ ...option });
                editOption(optionId, newOption);
              }
            }}
          />
        </div>
      )}
    </>
  );
};
