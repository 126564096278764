import React from 'react';
import { FormDto } from '../../services/api/api-client';

// Although now in this context there are properties
// needed only for last note column
// it is better to name this context as it is named now
// because:
// * Something like "LastNotesContext" or "LastNoteColumnContext"
//   can be easily confused with existed "LastNoteContext"
// * In the future we can add properties related to other columns

export interface IPatientTableContextValue {
  formConfigForNotes: FormDto | null;
  mainNoteFieldDataKey: string | null;
  noteDateDataKey: string | null;
}

export const patientTableContextDefaultValue: IPatientTableContextValue = {
  formConfigForNotes: null,
  mainNoteFieldDataKey: null,
  noteDateDataKey: null,
};

export const PatientTableContext = React.createContext<IPatientTableContextValue>(patientTableContextDefaultValue);
