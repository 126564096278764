﻿import React, { FC } from 'react';
import TablePageLayout from '../../components/tablePageLayout/TablePageLayout';
import { createPagingParams, pagingSortingToBackendRequest } from '../../helpers/pagination-helper';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParams } from 'react-router-url-params';
import { useGetParticipantsQuery } from '../../services/api/api-client/ParticipantsQuery';
import { useSortBy } from 'uikit/table/updateSortByInUrl';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { IParticipantDto, Permissions } from '../../services/api/api-client';
import { useParticipantsColumns } from './ParticipantsPage.columns';
import { AppTable } from 'uikit/table/AppTable';
import { ParticipantsPageExpandedRow } from './ParticipantsPage.ExpandedRow';
import { ReactComponent as Plus } from 'src/assets/img/common/plus_40.svg';
import { useParticipantCreating } from './useParticipantCreating';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const participantsQueryParamsConfig = {
  ...createPagingParams({
    defaultPerPage: 30,
    defaultSortBy: 'id',
    sortingKeys: ['id'],
  }),
  search: StringParam,
};

export const ParticipantsPage: FC = () => {
  const participantCreating = useParticipantCreating();
  const { id: studyId, closingApprovedAt } = useStudy() ?? {};
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useQueryParams(participantsQueryParamsConfig);

  const participantsQuery = useGetParticipantsQuery(
    {
      studyId: studyId!,
      ...pagingSortingToBackendRequest(queryParams),
      search: queryParams.search,
    },
    {
      keepPreviousData: true,
    },
  );

  const sortBy = useSortBy(queryParams, setQueryParams);

  const table = useReactTable<IParticipantDto>({
    data: participantsQuery?.data?.data || [],
    manualSorting: false,
    onSortingChange: sortBy.onSortingChange,
    state: {
      sorting: sortBy.sortingState,
    },
    columns: useParticipantsColumns(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TablePageLayout>
      <TablePageLayout.Header>
        <TablePageLayout.Header.Row>
          <TablePageLayout.Header.Title
            text={t('Participants.Page.Title')}
            secondaryText={participantsQuery.data?.totalCount.toString()}
          />
          <TablePageLayout.Header.Search
            value={queryParams.search ?? ''}
            onChange={(e) => setQueryParams({ search: e.target.value })}
            placeholder={t('Participants.Page.SearchPlaceholder')}
          />
        </TablePageLayout.Header.Row>
      </TablePageLayout.Header>
      <TablePageLayout.TableContainer>
        <AppTable
          table={table}
          expandedRow={(row) => <ParticipantsPageExpandedRow participant={row.original} />}
          placeholder={{ hide: participantsQuery.isPreviousData || participantsQuery.isLoading }}
        />
        <TablePageLayout.TableContainer.Pagination
          page={queryParams.page}
          perPage={queryParams.perPage}
          totalCount={participantsQuery.data?.totalCount || 0}
          changePagination={setQueryParams}
        />
        {!closingApprovedAt && (
          <PermissionsCheck permissions={Permissions.ParticipantsEdit}>
            <TablePageLayout.TableContainer.FloatingButton
              Icon={Plus}
              onClick={() => participantCreating.start(studyId!)}
            />
          </PermissionsCheck>
        )}
      </TablePageLayout.TableContainer>
      {participantCreating.element}
    </TablePageLayout>
  );
};
