import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModal } from '../../../../application/hooks/useModal';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import _ from 'lodash';
import Style from './notesOverviewModal.module.scss';
import { Note } from '../Note';
import { FormResultDto } from '../../../../services/api/api-client';
import { NoteListItem } from './NoteListItem';
import { addDays, toDate } from 'date-fns';
import { ReactComponent as Placeholder } from 'src/assets/img/comment/placeholder.svg';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { DateRangePickerV3 } from '../../../daterangePickerV3/DateRangePickerV3.component';
import { Loading } from 'uikit/suspense/Loading';
import { useGetPatientQuery } from 'src/services/api/api-client/PatientQuery';
import { Permissions } from 'src/services/api/api-client';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import {
  useGetFormByIdQuery,
  useGetFormResultDatesQuery,
  useGetFormResultsQuery,
} from 'src/services/api/api-client/FormsQuery';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { useStudy } from 'src/helpers/hooks/useStudy';

type OpenNotesOverviewType = {
  patientId: string;
  configId: number;
  resultId?: number | null;
};

export const useNotesOverview = () => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.NotesSection');
  const { hasPermission } = useHasPermissions();
  const { visible, closeModal, openModal } = useModal();

  const [formConfigId, setFormConfigId] = useState<number | null>(null);
  const [resultId, setResultId] = useState<number | null>(null);
  const [patientId, setPatientId] = useState<string>();

  const [selectedItem, setSelectedItem] = useState<FormResultDto | undefined>();
  const [selectedInterval, setSelectedInterval] = useState<Interval | undefined>();

  const { data: patient, isInitialLoading: isPatientLoading } = useGetPatientQuery(
    { id: patientId! },
    {
      suspense: false,
      enabled: !!patientId,
    },
  );
  const { data: formConfig, isInitialLoading: isFormConfigLoading } = useGetFormByIdQuery(formConfigId!, {
    suspense: false,
    enabled: !!formConfigId,
  });

  const study = useStudy();
  const patientsGroup = study?.groups.find((g) => g.id === patient?.groupId);

  const { data: notesFilledDates, isInitialLoading: isDatesLoading } = useGetFormResultDatesQuery(
    {
      patientId: patientId,
      formConfigId: formConfigId,
      byDataKey: patientsGroup?.noteDateDataKey,
    },
    {
      suspense: false,
      enabled: !!formConfigId && !!patientsGroup?.noteDateDataKey,
    },
  );
  const {
    data: formResults,
    isInitialLoading: isFormResultLoading,
    isLoading,
  } = useGetFormResultsQuery(
    {
      patientId: patientId,
      formConfigId: formConfigId,
      orderByDataKey: patientsGroup?.noteDateDataKey,
      dataKeyDateFilter_DataKey: patientsGroup?.noteDateDataKey,
      dataKeyDateFilter_Since: selectedInterval && toDate(selectedInterval.start),
      dataKeyDateFilter_Until: selectedInterval && addDays(toDate(selectedInterval.end), 1),
    },
    {
      suspense: false,
      enabled: !!formConfigId && !!patientsGroup?.noteDateDataKey && !!selectedInterval,
    },
  );

  // TODO: Think about refactoring this useEffect
  useEffect(() => {
    if (selectedInterval || !notesFilledDates?.minAvailableDate || !notesFilledDates.maxAvailableDate) return;

    if (resultId) {
      setSelectedInterval({
        start: notesFilledDates.minAvailableDate,
        end: notesFilledDates.maxAvailableDate,
      });
      return;
    }

    setSelectedInterval({
      start: patient?.studyStartedAt ?? notesFilledDates.minAvailableDate,
      end: notesFilledDates.maxAvailableDate,
    });
  }, [selectedInterval, notesFilledDates, resultId, patient?.studyStartedAt]);

  // We don't want that already loaded data has disappeared during filter-request, so we cache and use it in component
  // We could have use `keepPreviousData` from 'react-query' but in this case we can't rely on "loading-fetching" props
  const dataCacheRef = useRef(formResults?.data || []);

  const dataCache = useMemo(() => {
    if (!isFormResultLoading) {
      dataCacheRef.current = formResults?.data || [];
    }

    return dataCacheRef.current;
  }, [isFormResultLoading, formResults?.data]);

  useEffect(() => {
    if (resultId) {
      setSelectedItem(dataCache.find((x) => x.id === resultId));
      return;
    }

    setSelectedItem(_.first(dataCache));
  }, [dataCache, formResults?.data, resultId]);

  const hasData = dataCache.length > 0 && !isFormResultLoading && !isLoading;
  const anyDataLoading = isFormResultLoading || isDatesLoading || isFormConfigLoading || isPatientLoading;

  const historyList = useMemo(() => {
    if (!patientsGroup?.noteDateDataKey || !patient || !formConfig || !patientsGroup.mainNoteFieldDataKey) {
      return <></>;
    }

    if (!hasData) {
      return <div className={Style.placeholderText}>{t('Overview.NoData')}</div>;
    }

    return dataCache.map((x) => (
      <NoteListItem
        formConfig={formConfig}
        formResult={_.last(x.versions)!}
        onSelect={(id) => setSelectedItem(dataCache.find((d) => d.id === id))}
        key={x.id}
        isSelected={x.id === selectedItem?.id}
        noteDateDataKey={patientsGroup.noteDateDataKey!}
        mainNoteFieldDataKey={patientsGroup.mainNoteFieldDataKey!}
        patientUniqId={patient.patientId}
      />
    ));
  }, [
    patientsGroup?.noteDateDataKey,
    patientsGroup?.mainNoteFieldDataKey,
    patient,
    formConfig,
    hasData,
    dataCache,
    t,
    selectedItem?.id,
  ]);

  const selectedItemComponent = useMemo(() => {
    if (anyDataLoading) {
      return <></>;
    }

    if (patient && patientsGroup?.noteDateDataKey && formConfig && selectedItem) {
      return (
        <Note
          testId={'note'}
          formDto={formConfig}
          formResult={selectedItem}
          isOverview={true}
          noteDateDataKey={patientsGroup?.noteDateDataKey}
          patientUniqueId={patient?.patientId}
          canEdit={hasPermission(Permissions.PatientCreate) && !patient?.isStudyFinished}
        />
      );
    }

    if (!hasData || !selectedItem) {
      return (
        <div className={Style.placeholder} data-test-id={'placeholder'}>
          <Placeholder />
          <div className={Style.placeholderText}>{t('Overview.NotSelected')}</div>
        </div>
      );
    }

    return <></>;
  }, [patient, patientsGroup?.noteDateDataKey, formConfig, selectedItem, anyDataLoading, hasData, hasPermission, t]);

  const resetAll = useCallback(() => {
    setSelectedInterval(undefined);
  }, []);

  const header = useMemo(() => {
    return (
      <IssueMark
        issueContext={{
          subject: 'Patient',
          topic: 'Notes',
          linkedPatientUniqId: patient?.patientId,
        }}
        countDescendants={false}
        position={{ top: 2, right: 8 }}
        containerClassName={Style.issueMarkContainer}
      >
        <div className={Style.issueMarkTarget} />
      </IssueMark>
    );
  }, [patient?.patientId]);

  return {
    open: (args: OpenNotesOverviewType) => {
      setFormConfigId(args.configId);
      setPatientId(args.patientId);
      setResultId(args.resultId || null);
      openModal();
    },
    close: closeModal,
    element: (
      <AppModalContainer
        visible={visible}
        onHide={closeModal}
        onDismissed={resetAll}
        title={t('Header')}
        bodyClassName={Style.modalBody}
        testId={'notes-overview'}
        header={header}
        headerClassName={Style.modalHeader}
      >
        <Loading loading={anyDataLoading} testId={'notes-overview'}>
          <div className={Style.container}>
            {selectedInterval && (
              <>
                <div className={Style.leftColumn}>
                  <div className={Style.filteredList} data-test-id={'notes-list-container'}>
                    <div className={Style.calendarContainer}>
                      <DateRangePickerV3
                        selectedInterval={selectedInterval}
                        onSelectRange={setSelectedInterval}
                        filledDays={notesFilledDates?.dates || []}
                        minFilledDate={patient?.studyStartedAt ?? notesFilledDates?.minAvailableDate ?? undefined}
                        maxFilledDate={notesFilledDates?.maxAvailableDate || undefined}
                        withPredefinedPeriods={true}
                      />
                    </div>
                    <div className={Style.notesList} data-test-id={'notes-list'}>
                      {historyList}
                    </div>
                  </div>
                </div>
                <div className={Style.rightColumn} data-test-id={'selected-note'}>
                  {selectedItemComponent}
                </div>
              </>
            )}
          </div>
        </Loading>
      </AppModalContainer>
    ),
  };
};
