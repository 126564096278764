﻿import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { PatientFilterStatus, UpdatePatientFilterDto } from 'src/services/api/api-client';
import { QueryFactory } from 'src/services/api';
import { showErrorToast } from 'src/components/toast/toast-helper';

const useApplyPatientFilter = () => {
  const queryClient = useQueryClient();

  return useCallback(
    async (patientId: string, key: keyof PatientFilterStatus, value: boolean) => {
      const payload = { [key]: value };

      try {
        await QueryFactory.PatientQuery.Client.applyFilterToPatient(
          patientId,
          new UpdatePatientFilterDto({ ...payload }),
        );
        await queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientsQueryKey());
      } catch (err: any) {
        showErrorToast(err);
      }
    },
    [queryClient],
  );
};

export default useApplyPatientFilter;
