/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { SerializedNode, SerializedNodes, NodeId, NodeProvider, Resolver } from '@craftjs/core';
import _ from 'lodash';

export type SerializedNodeWithId = SerializedNode & { id: string };

export const deserializeNodes = (nodes: SerializedNodes): SerializedNodeWithId[] => {
  return Object.entries(nodes).map(([id, val]) => ({ id, ...val }));
};

export const getNodeById = (nodes: SerializedNodeWithId[], id: NodeId) => {
  return _.find(nodes, (node) => node.id === id);
};

export function getDescendants(
  nodes: SerializedNodeWithId[],
  id: NodeId,
  deep = false,
  includeOnly?: 'linkedNodes' | 'childNodes',
): SerializedNodeWithId[] {
  function appendChildNode(id: NodeId, descendants: NodeId[] = [], depth: number = 0) {
    if (deep || (!deep && depth === 0)) {
      const node = getNodeById(nodes, id);

      if (!node) {
        return descendants;
      }

      if (includeOnly !== 'childNodes') {
        // Include linkedNodes if any
        const linkedNodes = node.linkedNodes;

        _.each(linkedNodes, (nodeId) => {
          descendants.push(nodeId);
          descendants = appendChildNode(nodeId, descendants, depth + 1);
        });
      }

      if (includeOnly !== 'linkedNodes') {
        const childNodes = node.nodes;

        _.each(childNodes, (nodeId) => {
          descendants.push(nodeId);
          descendants = appendChildNode(nodeId, descendants, depth + 1);
        });
      }

      return descendants;
    }
    return descendants;
  }
  return _.compact(_.map(appendChildNode(id), (nid) => getNodeById(nodes, nid)));
}

export const renderNode = (nodes: SerializedNodeWithId[], resolver: Resolver, nodeId: NodeId): JSX.Element => {
  const node = getNodeById(nodes, nodeId);

  if (!node) {
    throw new Error(`Could not find node with id ${nodeId}`);
  }

  const ResolvedComponent = _.get(resolver, (node.type as any).resolvedName);
  const descendants = getDescendants(nodes, nodeId);
  const children = _.map(descendants, (descendant) => renderNode(nodes, resolver, descendant.id));

  return (
    <NodeProvider key={node.id} id={node.id}>
      <ResolvedComponent {...node.props}>{children}</ResolvedComponent>
    </NodeProvider>
  );
};
