import React, { useCallback, useState } from 'react';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';
import { QuestionType, SkipOptions } from 'src/services/api/api-client';
import { QuestionOverviewProps } from './questionOverview';
import Style from './questionOverview.module.css';

import { ReactComponent as Delete } from '../../../../assets/img/common/delete.svg';
import { ReactComponent as Chevron } from '../../../../assets/img/common/blueChevron.svg';
import { OptionOverview } from '../optionOverview/optionOverview.component';
import { useContextSelector } from 'use-context-selector';
import clsx from 'clsx';
import { QuestionSchemeEditorContext } from '../../container/questionSchemeUIEditor.context';

export const QuestionOverview = (question: QuestionOverviewProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const questionFromContext = useContextSelector(QuestionSchemeEditorContext, (x) => x.selectedQuestion);
  const setEditableQuestion = useContextSelector(QuestionSchemeEditorContext, (x) => x.selectQuestion);
  const deleteQuestion = useContextSelector(QuestionSchemeEditorContext, (x) => x.deleteQuestion);
  const copyQuestion = useContextSelector(QuestionSchemeEditorContext, (x) => x.copySelectedQuestion);
  const moveQuestion = useContextSelector(QuestionSchemeEditorContext, (x) => x.moveQuestion);

  const editHandler = useCallback(
    (e: React.MouseEvent) => {
      e?.stopPropagation();
      if (question && setEditableQuestion) {
        setEditableQuestion(question);
      }
    },
    [question, setEditableQuestion],
  );

  const deleteHandler = useCallback(() => {
    if (question && deleteQuestion) {
      setIsDisabled(true);
      deleteQuestion(question.id);
      setIsDisabled(false);
    }
  }, [deleteQuestion, question]);

  const copyQuestionHandler = useCallback(async () => {
    if (question && copyQuestion) {
      setIsDisabled(true);
      await copyQuestion(question.id);
      setIsDisabled(false);
    }
  }, [copyQuestion, question]);

  const moveUpHandler = useCallback(async () => {
    if (question && moveQuestion) {
      moveQuestion(question.id, 'Up');
    }
  }, [moveQuestion, question]);

  const moveDownHandler = useCallback(async () => {
    if (question && moveQuestion) {
      moveQuestion(question.id, 'Down');
    }
  }, [moveQuestion, question]);

  return (
    <div
      datatype={QuestionType[question.questionType]}
      className={clsx(Style.container, {
        [Style.selected]: questionFromContext?.id === question.id,
        [Style.questionDisabled]: isDisabled,
      })}
      onClick={editHandler}
    >
      <div className={Style.header}>
        <div className={Style.chipsContainer}>
          <div className={Style.chips}>{QuestionType[question.questionType]}</div>
          <div className={Style.chips}>{`Skip: ${SkipOptions[question.isSkippedRender]}`}</div>
          {question.canBeSkipped && <div className={Style.chips}>{'CanBeSkippedByUser'}</div>}
        </div>
        <div className={Style.buttons}>
          <AppButton
            disabled={isDisabled}
            variant={'icon-link'}
            colorSchema={'decline'}
            Icon={Delete}
            className={Style.button}
            onClick={deleteHandler}
          />
        </div>
      </div>
      <div className={Style.questionText}>
        <div className={Style.index}>{question.index}</div>
        <div>{question.questionText}</div>
      </div>
      <div className={Style.optionsContainer}>
        {question.options?.map((option, i) => (
          <OptionOverview key={i} option={option} route={{ optionIndex: i, questionId: question.id }} />
        ))}
      </div>
      <div className={Style.footer}>
        <div className={Style.buttons}>
          {question.index !== 1 && (
            <AppButton
              disabled={isDisabled || question.index === 1}
              variant={'icon-link'}
              colorSchema={'secondary'}
              Icon={Chevron}
              iconClassName={Style.turned}
              className={Style.button}
              onClick={moveUpHandler}
            />
          )}
          {question.index !== question.lastIndex && (
            <AppButton
              disabled={isDisabled || question.index === question.lastIndex}
              variant={'icon-link'}
              colorSchema={'secondary'}
              Icon={Chevron}
              className={Style.button}
              onClick={moveDownHandler}
            />
          )}
          <AppButton
            disabled={isDisabled}
            variant={'icon-link'}
            colorSchema={'secondary'}
            text="Copy"
            onClick={copyQuestionHandler}
          />
        </div>
      </div>
    </div>
  );
};
