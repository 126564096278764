import { useEditor } from '@craftjs/core';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FaqEvents } from '../../../../features/faq/faq-reducer';
import { useFaqBuffer } from '../../../../features/faq/faq-selectors';
import { ExpandedRowHeader } from '../../../expandedRow/header/expandedRowHeader.component';
import { getCloneTree } from '../../../formEditor/uiEditor/uiEditor-helper';
import { SelectorItem } from '../../selectors/selectorItem/selectorItem';
import Style from './viewport.module.css';
import { AppButton } from '../../../uikit/buttons/button/button.component';

export const BufferPanel = () => {
  const dispatch = useDispatch();
  const {
    query,
    selected: [id],
  } = useEditor((state) => ({
    enabled: state.options.enabled,
    selected: state.events.selected,
  }));

  const buffer = useFaqBuffer();

  const addToBufferHandler = useCallback(() => {
    const tree = getCloneTree(query, id);
    dispatch(FaqEvents.addToBuffer(tree));
  }, [dispatch, id, query]);

  const canAdd = useMemo(() => id && !query.node(id).isLinkedNode() && !query.node(id).isRoot(), [id, query]);

  return (
    <div className={Style.bufferPanel}>
      <div className={Style.bufferHeader}>
        <ExpandedRowHeader
          text={'Buffer'}
          rightHeader={
            <AppButton
              disabled={!canAdd}
              onClick={addToBufferHandler}
              variant={'icon-link'}
              colorSchema={'primary'}
              text={'Add to buffer'}
            />
          }
        />
      </div>

      {buffer.map((node) => (
        <SelectorItem
          key={node.rootNodeId}
          id={node.rootNodeId}
          component={() => getCloneTree(query, node)}
          name={node.nodes[node.rootNodeId].data.custom.displayName || node.nodes[node.rootNodeId].data.name}
        />
      ))}
    </div>
  );
};
