import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import i18n from 'i18next';
import Style from '../patientTable.module.scss';
import PatientRiskGroupStyle from '../../patientRiskGroup/patientRiskGroup.module.css';
import {
  IPatientListDto,
  ISurveyDto,
  PatientCategoryEnum,
  SurveyType,
  TableColumnsEnum,
} from '../../../services/api/api-client';
import { LastNote } from './lastNote/lastNote.component';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { ReactComponent as ReportIcon } from 'assets/img/notification/report_filled_20.svg';
import { ReactComponent as ResetIcon } from 'assets/img/common/reset_24_bold.svg';
import { ComplianceIndicator } from '../../indicators/compliance/compliance.component';
import { SyncButton } from '../../syncButton/syncButton';
import { LeakageIndicator } from '../../indicators/leakage/leakage.component';
import { PatientRiskGroupComponent } from '../../patientRiskGroup/patientRiskGroup.component';
import { hasFlag } from '../../../helpers/enum-helper';
import { AHIIndicator } from '../../indicators/ahi/ahi.component';
import { PipelineComponent } from '../../pipeline/pipeline.component';
import { SurveyIndicator } from '../../indicators/survey/surveyIndicator.component';
import { ColumnDef, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import TableCommonStyles from 'uikit/table/appTableCommonStyles.module.scss';
import { RecentUpdatesColumn } from './recentUpdatesColumn/RecentUpdatesColumn';
import { conditionalConcat } from '../../../helpers/arrayHelpers';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const defaultSort = (tableColumns: TableColumnsEnum): ColumnSort[] => {
  if (hasFlag(tableColumns, TableColumnsEnum.Categorization)) {
    return [
      {
        id: 'category',
        desc: true,
      },
    ];
  }

  return [
    {
      id: 'uniqueId',
      desc: false,
    },
  ];
};

type ColumnOptions = {
  getIsColored: () => boolean;
  tableColumns: TableColumnsEnum;
};

export const useUniqueIdColumn = (options: ColumnOptions): ColumnDef<IPatientListDto> => {
  const { t } = useTranslation();

  const isColored = options.getIsColored();

  return useMemo(() => {
    return {
      accessorKey: 'uniqueId',
      header: t('Dashboard.PatientTable.Column.Id'),
      cell: ({ row }) => {
        const indicatorProps = getIndicatorProps(row.original);
        return (
          <IssueMark
            issueContext={{
              subject: 'Patient',
              linkedPatientUniqId: row.original.uniqueId,
            }}
            disabled={row.original.isStudyFinished}
          >
            <div className={Style.idContainer}>
              <div className={Style.uniqId}>
                <div className={Style.indicator}>
                  <IdColumnIndicator enabled={!!indicatorProps} {...indicatorProps} />
                </div>
                {row.original.uniqueId}
              </div>
            </div>
          </IssueMark>
        );
      },
      size: 120,
      meta: {
        headerTooltip: t('Dashboard.PatientTable.ColumnTooltip.Id'),
        cellClassName: clsx(TableCommonStyles.noPadding, Style.idColumn),
        centered: true,
        isColored,
      },
    };
  }, [isColored, t]);
};

type IdColumnIndicatorProps = {
  tooltip?: string;
  icon?: JSX.Element;
  enabled?: boolean;
  testIdPrefix?: string;
};

const getIndicatorProps = (patient: IPatientListDto): Omit<IdColumnIndicatorProps, 'enabled'> | undefined => {
  if (patient.filters.needsChecking)
    return {
      tooltip: i18n.t('Dashboard.PatientTable.ColumnTooltip.ReportReady'),
      icon: <ReportIcon data-test-id={'prisma-report-indicator'} />,
      testIdPrefix: 'report-reminder',
    };

  if (patient.isStudyFinished && !patient.archivedAt && !patient.canArchive)
    return {
      tooltip: i18n.t('Dashboard.PatientTable.ColumnTooltip.CanNotBeArchived'),
      icon: <ResetIcon data-test-id={'cant-be-archived-indicator'} />,
      testIdPrefix: 'cant-be-archived',
    };

  return undefined;
};

const IdColumnIndicator: FC<IdColumnIndicatorProps> = ({ icon, tooltip, testIdPrefix, enabled }) => {
  return (
    <Tooltip
      text={tooltip ?? ''}
      hostStyles={clsx(Style.idReportIcon, { [Style.hidden]: !enabled })}
      testIdPrefix={testIdPrefix}
    >
      {icon}
    </Tooltip>
  );
};

export const useIndicatorColumns = (options: ColumnOptions): ColumnDef<IPatientListDto>[] => {
  const { t } = useTranslation();
  const study = useStudy();

  const hasIndicator =
    hasFlag(options.tableColumns, TableColumnsEnum.Ahi) ||
    hasFlag(options.tableColumns, TableColumnsEnum.Compliance) ||
    hasFlag(options.tableColumns, TableColumnsEnum.Leakage);

  const isColored = hasIndicator && options.getIsColored();

  return useMemo(() => {
    return conditionalConcat(
      hasFlag(options.tableColumns, TableColumnsEnum.Compliance) && [
        {
          id: 'compliance',
          header: t('Dashboard.PatientTable.Column.Compliance'),
          cell: ({ row }) => <ComplianceIndicator indicator={row.original.compliance} />,
          size: 120,
          meta: {
            headerTooltip: t('Dashboard.PatientTable.ColumnTooltip.Compliance', {
              days: study?.therapyAggregationPeriod,
            }),
            centered: true,
            isColored,
          },
        },
      ],
      hasFlag(options.tableColumns, TableColumnsEnum.Leakage) && [
        {
          id: 'leakage',
          header: t('Dashboard.PatientTable.Column.Leakage'),
          cell: ({ row }) => <LeakageIndicator indicator={row.original.leakage} />,
          size: 120,
          meta: {
            headerTooltip: t('Dashboard.PatientTable.ColumnTooltip.Leakage', { days: study?.therapyAggregationPeriod }),
            centered: true,
            isColored,
          },
        },
      ],
      hasFlag(options.tableColumns, TableColumnsEnum.Ahi) && [
        {
          id: 'ahi',
          header: t('Dashboard.PatientTable.Column.AHI'),
          cell: ({ row }) => <AHIIndicator indicator={row.original.ahi} />,
          size: 120,
          meta: {
            headerTooltip: t('Dashboard.PatientTable.ColumnTooltip.AHI', { days: study?.therapyAggregationPeriod }),
            centered: true,
            isColored,
          },
        },
      ],
      hasIndicator && [
        {
          id: 'sync',
          header: SyncButton,
          meta: {
            cellClassName: Style.syncColumn,
            headerClassName: Style.syncColumnHeader,
            dontUseHeaderWidth: true,
            isColored,
          },
        },
      ],
    );
  }, [hasIndicator, isColored, options.tableColumns, study?.therapyAggregationPeriod, t]);
};

type SurveyColumnsOptions = ColumnOptions & {
  surveys?: ISurveyDto[];
  styles?: any;
  onClick?: (survey: SurveyType, patient: string, headerTitle: string, answerId: number | null) => void;
};

export const useSurveyColumns = (options: SurveyColumnsOptions): ColumnDef<IPatientListDto>[] => {
  const isColored = (options.surveys?.length ?? 0) > 0 && options.getIsColored();
  const columns = useMemo(() => {
    return options.surveys?.map((survey) => {
      return {
        id: survey.typeString,
        header: survey.typeString,
        cell: ({ row }) => (
          <SurveyIndicator
            patientId={row.original.id}
            survey={survey}
            collection={row.original.surveyIndicators}
            handleSurveyStatisticsClick={options.onClick}
          />
        ),
        meta: {
          headerTooltip: survey.description,
          dontUseHeaderWidth: true,
          centered: true,
          headerClassName: clsx(TableCommonStyles.wrap, Style.surveyColumnHeader),
          cellClassName: TableCommonStyles.width120,
          isColored,
        },
      } as ColumnDef<IPatientListDto>;
    });
  }, [isColored, options.onClick, options.surveys]);

  return columns ?? [];
};

export const useCategoryColumn = (options: ColumnOptions): ColumnDef<IPatientListDto> | null => {
  const { t } = useTranslation();

  const columnEnabled = hasFlag(options.tableColumns, TableColumnsEnum.Categorization);

  // Ensure that next column isn't colored
  // because category column is always colored
  if (columnEnabled && !options.getIsColored()) options.getIsColored();

  return useMemo(() => {
    if (!columnEnabled) return null;

    return {
      accessorKey: 'category',
      size: 260,
      header: t('Dashboard.PatientTable.Column.RiskGroup'),
      cell: ({ row }) => <PatientRiskGroupComponent category={row.original.category} />,
      meta: {
        cellClassName: ({ row }) =>
          clsx(
            TableCommonStyles.noPadding,
            // To display background color correctly in firefox
            row.original.category && PatientRiskGroupStyle[PatientCategoryEnum[row.original.category?.quality]],
          ),
        headerTooltip: t('Dashboard.PatientTable.ColumnTooltip.RiskGroup'),
      },
    };
  }, [columnEnabled, t]);
};

export const useStageColumn = (options?: ColumnOptions): ColumnDef<IPatientListDto> | null => {
  const { t } = useTranslation();

  const columnEnabled = !options || hasFlag(options.tableColumns, TableColumnsEnum.Stage);
  const isColored = columnEnabled && (options?.getIsColored() ?? false);

  return useMemo(() => {
    if (!columnEnabled) return null;

    return {
      meta: {
        cellClassName: clsx(Style.stageColumn, TableCommonStyles.width360),
        headerTooltip: t('Dashboard.PatientTable.ColumnTooltip.Stage'),
        isColored,
      },
      id: 'stage',
      header: t('Dashboard.PatientTable.Column.Stage'),
      cell: ({ row }) => (
        <PipelineComponent
          routeProgress={row.original.studyRouteProgress}
          patientId={row.original.id}
          patientUniqueId={row.original.uniqueId}
        />
      ),
    };
  }, [columnEnabled, isColored, t]);
};

export const useLastNoteColumn = (options: ColumnOptions): ColumnDef<IPatientListDto> | null => {
  const { t } = useTranslation();

  const columnEnabled = hasFlag(options.tableColumns, TableColumnsEnum.Note);
  const isColored = columnEnabled && options.getIsColored();

  return useMemo(() => {
    if (!columnEnabled) return null;

    return {
      id: 'lastNote',
      header: t('Dashboard.PatientTable.Column.LastNote'),
      meta: {
        headerTooltip: t('Dashboard.PatientTable.ColumnTooltip.LastNote'),
        cellClassName: clsx(Style.commentColumn),
        dontUseHeaderWidth: true,
        isColored,
      },
      cell: ({ row }) => <LastNote lastNoteFields={row.original.lastNoteFields} />,
    };
  }, [columnEnabled, isColored, t]);
};

export const useRecentChangesColumn = (options: ColumnOptions): ColumnDef<IPatientListDto> | null => {
  const { t } = useTranslation();

  const columnEnabled = hasFlag(options.tableColumns, TableColumnsEnum.CreationDate);
  const isColored = columnEnabled && options.getIsColored();

  return useMemo(() => {
    if (!columnEnabled) return null;

    return {
      id: 'changedAt',
      header: t('Dashboard.PatientTable.Column.RecentUpdateDate'),
      accessorKey: 'changedAt',
      meta: {
        headerClassName: TableCommonStyles.width160,
        headerTooltip: t('Dashboard.PatientTable.ColumnTooltip.RecentUpdateDate'),
        cellClassName: Style.recentUpdateColumn,
        dontUseHeaderWidth: true,
        isColored,
      },
      cell: ({ row }) => <RecentUpdatesColumn date={row.original.changedAt} patientId={row.original.id} />,
    };
  }, [columnEnabled, isColored, t]);
};
