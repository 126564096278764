import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import clsx from 'clsx';
import { Container } from './Container';
import { AppStepper } from 'src/components/stepper/stepper.component';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';
import { UIEditorBlockWidth } from '../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/common/cross_16.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper';
import styles from '../controlsStyle.module.css';
import { useTranslation } from 'react-i18next';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { ITabsContainer, ITabsContainerProps } from '../../../../features/forms/base/controls/containers/TabsContainer';
import { AppTextInput } from '../../../uikit/inputs/text/appTextInput.component';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { PanelContainer } from '../../toolbox/components/panelContainer';

export const TabsContainer: ITabsContainer = (props) => {
  const pageRef = useRef(null);
  const { connectors, id } = useNode((state) => ({
    selected: state.events.selected,
    id: state.id,
  }));

  const { connect, drag } = connectors;
  const { size, steps, tabLabelList } = {
    ...TabsContainerDefaultProps,
    ...props,
  };

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [tabSwiper, setTabSwiper] = useState<SwiperClass | null>(null);
  const [currentTab, setCurrentTab] = useState(1);
  const currentPage = useContextSelector(FormFillContext, (v) => v.currentPageStack);
  const goToPage = useContextSelector(FormFillContext, (v) => v.goToPage);

  useEffect(() => {
    const findPage = currentPage?.find((p) => p.nodeId === id);
    if (findPage) {
      setCurrentTab(findPage.pageNumber);

      // First "- 1" is needed because pageNumber starts from 1
      // and second is needed to show one tab left
      tabSwiper?.slideTo(Math.max(0, findPage.pageNumber - 1 - 1), 2);

      if (pageRef?.current) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        pageRef?.current?.scrollIntoView(true);
      }
    }
  }, [currentPage, id, tabSwiper]);

  const content = Array.from({ length: steps }, (_, i) => (
    <React.Fragment key={i}>
      {enabled && <div className={styles.tabLabel}>{(tabLabelList && tabLabelList[i]) ?? ''}</div>}
      <Element id={i.toString()} is={Container} canvas flexBoxGap={12} />
    </React.Fragment>
  ));

  const viewComponent = (
    <>
      <Swiper
        freeMode={true}
        onSwiper={setTabSwiper}
        slidesPerView={'auto'}
        className={styles.tabSwiper}
        spaceBetween={20}
      >
        {content.map((_, i) => {
          // Steps start from 1
          const tabNumber = i + 1;
          return (
            <SwiperSlide key={tabNumber} className={styles.tabSwiperSlide}>
              <AppButton
                className={clsx(styles.tabButton, {
                  [styles.tabsCurrentButton]: tabNumber === currentTab,
                })}
                textClassName={styles.tabButtonText}
                variant={'icon-link'}
                colorSchema={'primary'}
                text={props.tabLabelList && props.tabLabelList[i]}
                onClick={() => goToPage?.({ nodeId: id, pageNumber: i + 1 })}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      {content.map((x, i) => {
        // Tabs start from 1
        const tabNumber = i + 1;
        const tabName = props.tabLabelList && props.tabLabelList.length > 0 ? props.tabLabelList[i] : tabNumber;
        return (
          <div
            key={tabNumber}
            data-test-tab-name={tabName}
            className={clsx({ [styles.stepVisibility]: tabNumber !== currentTab, 'preview-tab': isOnlyView })}
          >
            {x}
          </div>
        );
      })}
    </>
  );

  const editableComponent = (
    <div className={styles.tabWrapper}>
      <AppStepper
        activeStep={currentTab ?? 1}
        stepCount={props.steps ?? 1}
        labels={props.tabLabelList}
        variant={'tabs'}
        onStepClick={(stepNumber) => goToPage?.({ nodeId: id, pageNumber: stepNumber })}
      />
      <div className={styles.tabBody}>
        <div ref={pageRef}>
          {content.map((x, i) => {
            // Tabs start from 1
            const tabNumber = i + 1;
            const tabName = props.tabLabelList && props.tabLabelList.length > 0 ? props.tabLabelList[i] : tabNumber;
            return (
              <div
                data-test-tab-name={tabName}
                key={tabNumber}
                className={clsx({ [styles.stepVisibility]: tabNumber !== currentTab })}
              >
                {x}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const constructorControl = (
    <div
      className={clsx({
        [styles.containerBlock]: true,
        [styles.previewContainer]: isOnlyView,
      })}
      style={{ width: size }}
      ref={(ref: HTMLDivElement) => enabled && connect(drag(ref))}
    >
      <>
        <div>
          <AppStepper activeStep={1} stepCount={props.steps ?? 1} variant={'tabs'} labels={props.tabLabelList} />
        </div>
        {content}
      </>
    </div>
  );

  return <>{enabled ? constructorControl : isOnlyView ? viewComponent : editableComponent}</>;
};

export const TabsContainerSettings = () => {
  const { t } = useTranslation('dev');
  const { props, actions } = useNode((node) => ({
    props: node.data.props as ITabsContainerProps,
    linkedNodes: node.data.linkedNodes,
  }));

  const { setProp } = actions;
  const [tabLabel, setTabLabel] = useState<string | undefined>();
  const [tabLabelsList, setTabLabelsList] = useState<string[]>(props.tabLabelList ?? []);

  const addTabLabelToList = useCallback(() => {
    setTabLabelsList((x) => [...x, tabLabel ?? '']);
  }, [tabLabel]);

  // TODO: Rework it in the future
  // const deleteTabLabel = useCallback(
  //   (label: string) => {
  //     const tabIndex = tabLabelsList.findIndex((l) => l === label);
  //
  //     nodes[linkedNodes[tabIndex]].data.nodes.forEach((node) => deleteNode(node));
  //     setTabLabelsList((state) => state.filter((s) => s !== label));
  //   },
  //   [deleteNode, linkedNodes, nodes, tabLabelsList],
  // );

  useEffect(() => {
    setProp((setProps: ITabsContainerProps) => (setProps.steps = tabLabelsList.length ?? 1));
    setProp((setProps: ITabsContainerProps) => (setProps.tabLabelList = tabLabelsList));
    setTabLabel('');
  }, [setProp, tabLabelsList]);

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')}>
      <ControlsGroup flexible>
        {tabLabelsList.map((label) => (
          <div key={label} className={styles.tabLabelSettings}>
            {label}
            <AppButton
              Icon={DeleteIcon}
              variant={'icon-link'}
              colorSchema={'primary'}
              onClick={() => setTabLabelsList((state) => state.filter((s) => s !== label))}
            />
          </div>
        ))}
      </ControlsGroup>

      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.tabsContainer.tabLabel')} flexible>
        <AppTextInput
          value={tabLabel}
          onChange={(e) => {
            setTabLabel(e.target?.value);
          }}
        />
        <AppButton
          variant={'icon-link'}
          colorSchema={'primary'}
          text={t('StudySettingsPage.FormBuilder.settings.tabsContainer.addTab')}
          onClick={addTabLabelToList}
          disabled={!tabLabel || tabLabelsList.includes(tabLabel)}
          className={styles.addTabButton}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

export const TabsContainerDefaultProps: ITabsContainerProps = {
  size: UIEditorBlockWidth.Stretch,
  steps: 2,
  tabLabelList: ['Teil 1', 'Teil 2'],
};

TabsContainer.craft = {
  defaultProps: TabsContainerDefaultProps,
  related: {
    settings: TabsContainerSettings,
  },
};
