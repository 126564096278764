import React from 'react';
import { PatientTable } from '../../components/patientTable/patientTable.component';
import { IssuesBar } from '../../components/issue/bar/issuesBar.container';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import { Permissions } from 'src/services/api/api-client';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { StudyNotFoundError } from 'src/helpers/customErrors/customErrors';

export const Dashboard = () => {
  const study = useStudy();
  const { hasPermission } = useHasPermissions();

  if (!study) {
    throw new StudyNotFoundError();
  }

  const { id, isTokenActive, groups, hasTherapy, hasMonitoring, surveys } = study;

  return (
    <div data-test-id="main-page" style={{ display: 'flex', flex: '1 1 auto', overflow: 'auto' }}>
      <PatientTable
        studyId={id}
        isSyncActive={isTokenActive}
        groups={groups}
        surveys={surveys}
        hasTherapy={hasTherapy}
      />
      {hasMonitoring && hasPermission(Permissions.IssueView) && <IssuesBar />}
    </div>
  );
};
