﻿import { IFormDto, IGroupDto, IRouteProgressDto, RouteProgressStateEnum } from '../../../services/api/api-client';
import { getFormResults, getForms } from '../../../services/api/api-client/FormsClient';
import { FilledRecord } from '../records/recordsReport';
import _ from 'lodash';
import { getIssuesForStudy } from '../../../services/api/api-client/IssueClient';
import { getRecordResultListItemVms } from '../../../components/formEditor/controls/ConstantBlocks/RecordResultList/useGetFormList';
import { createReport, triggerDownloadFileDialog } from '../report-helpers';
import { EcrfReportLayout } from './ecrfReport.layout';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { normalizeFileName } from '../../../helpers/string-helper';
import React from 'react';

export const generateEcrfReport = async (
  study: {
    id: number;
    title: string;
    studyNumber: string;
    groups: IGroupDto[];
    hasEcrf: boolean;
    patientFinishingFormId: number | null | undefined;
  },
  patient: { id: string; uniqueId: string; routeProgress: IRouteProgressDto },
  authorFullName: string,
) => {
  const formResults = await getFormResults(undefined, undefined, undefined, patient.id);
  const configIds = [...new Set(formResults.data.map((fr) => fr.formConfigId))];
  const formConfigs = await getForms(configIds);

  //#region Final form

  const finalFormConfig = formConfigs.find((x) => x.id === study.patientFinishingFormId);
  if (!finalFormConfig) throw new Error("Config of final form wasn't found");

  const finalFormResult = formResults.data.find((x) => x.formConfigId === study.patientFinishingFormId);
  if (!finalFormResult) throw new Error("Result of final form wasn't found");

  const stepWithFinalForm = patient.routeProgress.steps.find((x) =>
    x.forms.some((y) => y.formConfigId === study.patientFinishingFormId),
  );
  if (!stepWithFinalForm) throw new Error("Step with a final form wasn't found");

  const filledFinalFormRecord: FilledRecord = {
    form: finalFormConfig,
    resultDto: finalFormResult,
    stepName: stepWithFinalForm.name,
  };

  //#endregion

  //#region Mandatory forms

  const filledRecords = patient.routeProgress.steps
    .map((step) => ({
      formResults: step.forms
        .filter((x) => x.formResultId !== null && x.formConfigId !== finalFormConfig.id)
        .map((x) => ({ id: x.formResultId!, formConfigId: x.formConfigId })),
      stepName: step.name,
    }))
    .filter((x) => x.formResults.length > 0)
    .flatMap((step) =>
      step.formResults?.map((fr) => {
        const formResult = formResults.data.find((x) => x.id === fr.id)!;
        const lastVersion = _.last(formResult.versions)!;
        return {
          form: formConfigs.find((x) => x.id === fr.formConfigId)!,
          resultDto: { ...formResult, versions: [lastVersion] },
          stepName: step.stepName,
        };
      }),
    );

  const missedRecords = patient.routeProgress.steps
    .filter((step) => step.state === RouteProgressStateEnum.Missed)
    .map((step) => ({
      stepName: step.name,
      forms: step.forms
        .filter((x) => x.formResultId === null)
        .map((x) => ({
          formType: x.formType,
          formConfigId: x.formConfigId,
        })),
    }))
    .flatMap((step) =>
      step.forms.map((x) => ({
        stepName: step.stepName,
        type: x.formType,
        formConfigId: x.formConfigId,
      })),
    );

  //#endregion

  //#region Multi instance forms

  const formConfigIdsForNotes = study.groups
    .filter((x) => x.formConfigIdForNotes)
    .map((x) => x.formConfigIdForNotes as number);

  const multiInstanceFilledRecords = formResults.data
    .map((fr) => ({
      form: _.find(formConfigs, { id: fr.formConfigId }) as IFormDto,
      resultDto: { ...fr, versions: [_.last(fr.versions)!] },
    }))
    .filter((x) => x.form.isMultiInstance && !formConfigIdsForNotes.includes(x.form.id))
    .reverse()
    .map((multiform, i) => {
      return { ...multiform, stepName: `#${i + 1}` };
    });

  //#endregion

  //#region Issues

  const issues = await getIssuesForStudy(
    study.id,
    patient.uniqueId,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    0,
    9999,
    'id',
  );

  //#endregion

  const { mandatoryForms, multipleForms } = getRecordResultListItemVms(
    study.patientFinishingFormId,
    formResults.data,
    formConfigIdsForNotes,
    patient.routeProgress,
  );

  const blob = await createReport(
    <EcrfReportLayout
      {...{
        filledRecords: [filledFinalFormRecord, ...filledRecords, ...multiInstanceFilledRecords],
        missedRecords,
        issues: issues.data,
        studyNumber: study.studyNumber,
        studyName: study.title,
        studyGroups: study.groups,
        studyHasEcrf: study.hasEcrf,
        patientId: patient.id,
        patientUniqueId: patient.uniqueId,
        authorFullName: authorFullName,
        mandatoryForms,
        multipleForms,
      }}
    />,
  );

  const filename = `Study-${study.studyNumber}-Patient-${patient.uniqueId}-eCRF-${localFormat(
    new Date(),
    DateFormats.forFiles,
  )}`;

  triggerDownloadFileDialog(blob, normalizeFileName(filename));
};
