import React from 'react';
import { useNode } from '@craftjs/core';
import { ContainerSettings } from './containerSettings';

export type ContainerProps = {
  background: string;
  flexDirection: string;
  alignItems: string;
  justifyContent: string;
  fillSpace: string;
  width: string;
  height: string;
  padding: number[];
  margin: number[];
  borderWidth: number[];
  borderColor: string;
  borderStyle: string;
  borderRadius: number;
  children: React.ReactNode;
};

const defaultProps = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  fillSpace: 'no',
  padding: [0, 0, 0, 0],
  margin: [0, 0, 0, 0],
  borderWidth: [0, 0, 0, 0],
  borderColor: '#dfe2e2',
  borderStyle: 'solid',
  background: '#fcfcfc',
  radius: 0,
  width: '100%',
  height: 'auto',
};

export const Container = (props: Partial<ContainerProps>) => {
  props = {
    ...defaultProps,
    ...props,
  };

  const { connectors } = useNode((state) => ({
    selected: state.events.selected,
    childrenIds: state.data.nodes,
  }));
  const { connect, drag } = connectors;
  const {
    flexDirection,
    alignItems,
    justifyContent,
    fillSpace,
    background,
    padding,
    margin,
    borderRadius: radius,
    children,
    borderWidth,
    borderColor,
    borderStyle,
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        minHeight: '24px',
        overflow: 'hidden',
        height: props.height,
        width: props.width,
        justifyContent,
        // @ts-ignore
        flexDirection,
        alignItems,
        background: background,
        padding: padding ? `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px` : '',
        margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : '',
        borderRadius: `${radius}px`,
        borderWidth: borderWidth
          ? `${borderWidth[0]}px ${borderWidth[1]}px ${borderWidth[2]}px ${borderWidth[3]}px`
          : '',
        borderColor: borderColor,
        borderStyle: borderStyle,
        flex: fillSpace === 'yes' ? 1 : 'unset',
      }}
      ref={(ref: HTMLDivElement) => connect(drag(ref))}
    >
      {children}
    </div>
  );
};

Container.craft = {
  displayName: 'Container',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: ContainerSettings,
  },
};
