import React, { FC, PropsWithChildren, useState } from 'react';
import { useLayer } from '@craftjs/layers';
import { useEditor } from '@craftjs/core';
import { ReactComponent as Arrow } from 'assets/img/formBuilder/filled_arrow_24.svg';
import { ReactComponent as EyeOpened } from 'assets/img/formBuilder/eye_opened_24.svg';
import { ReactComponent as EyeClosed } from 'assets/img/formBuilder/eye_closed_24.svg';
import Style from './layerItem.module.scss';
import { SelectorResolver } from '../controlsResolvers';

export const LayerItem: FC<PropsWithChildren> = (props) => {
  const {
    id,
    depth,
    expanded,
    children,
    connectors: { layer, drag, layerHeader },
    actions: { toggleLayer },
  } = useLayer((x) => ({
    hovered: x.event.hovered,
    expanded: x.expanded,
  }));

  const [hovered, setHovered] = useState<boolean>(false);

  const { hidden, actions, selected, isLinkedNode, resolvedName } = useEditor((state, query) => {
    return {
      hidden: state.nodes[id] && state.nodes[id].data.hidden,
      selected: query.getEvent('selected').first() === id,
      isLinkedNode: query.node(id).isLinkedNode(),
      resolvedName: state.nodes[id].data.name,
    };
  });

  const Icon = SelectorResolver()[resolvedName].Icon;

  return (
    <div ref={(ref) => ref && layer(ref)} data-hidden={hidden} className={Style.container}>
      <div ref={(ref) => ref && !isLinkedNode && drag(ref)}>
        <div
          ref={(ref) => ref && layerHeader(ref)}
          style={{ paddingLeft: 12 + depth * 24 }}
          className={Style.itemRow}
          data-selected={selected}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div className={Style.itemContainer}>
            <div className={Style.arrowWrapper}>
              {children && children.length > 0 && (
                <Arrow className={Style.arrowIcon} data-rotated={!expanded} onMouseDown={toggleLayer} />
              )}
            </div>
            <Icon />
            <div className={Style.displayName}>{resolvedName}</div>
            {(hovered || hidden) && (
              <button className={Style.eyeButton} onClick={() => actions.setHidden(id, !hidden)}>
                {hidden ? <EyeClosed /> : <EyeOpened />}
              </button>
            )}
          </div>
        </div>
      </div>

      <div className={Style.content} data-selected={selected}>
        {props.children}
      </div>
    </div>
  );
};
