﻿import React, { FC, PropsWithChildren } from 'react';
import styles from './overview.module.scss';
import { FieldGroupLevel } from 'uikit/fields/fieldGroup/FieldGroup';
import clsx from 'clsx';

type OverviewFieldGroupProps = PropsWithChildren<{
  title: string;
  hideTitle?: boolean;
  /** @default second */
  level?: FieldGroupLevel;
  titleRef?: React.ForwardedRef<HTMLDivElement>;
}>;

export const OverviewFieldGroup: FC<OverviewFieldGroupProps> = ({
  title,
  hideTitle,
  level = 'second',
  titleRef,
  children,
}) => {
  return (
    <div
      className={clsx(styles.overviewFieldGroup, {
        [styles.first]: level === 'first',
        [styles.second]: level === 'second',
        [styles.third]: level === 'third',
      })}
    >
      {!hideTitle && (
        <div ref={titleRef} className={styles.title}>
          {title}
        </div>
      )}
      {children}
    </div>
  );
};
