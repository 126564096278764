import React, { useCallback, useContext, useMemo, useState, FC } from 'react';
import { QueryFactory } from '../../../services/api';
import Style from './notes.module.scss';
import { ExpandedRowHeader } from '../header/expandedRowHeader.component';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { ReactComponent as AddIcon } from '../../../assets/img/common/add.svg';
import { Note } from './Note';
import { PatientCardContext } from '../PatientCardContext';
import { useFormEditing } from '../../../features/forms/editing/useFormEditing';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import clsx from 'clsx';
import { Loading } from 'uikit/suspense/Loading';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { useNotesOverview } from './overview/useNotesOverview';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import { Permissions } from 'src/services/api/api-client';
import { useGetFormByIdQuery } from '../../../services/api/api-client/FormsQuery';

const NOTES_LOAD_LIMIT = 3;

export const NotesSection: FC<{ isColored?: boolean }> = (props: { isColored?: boolean }) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.NotesSection');
  const commonLocalizer = useCommonLocalization();

  const patientCardContext = useContext(PatientCardContext);
  const formConfigId = patientCardContext.group.formConfigIdForNotes;
  const noteDateDataKey = patientCardContext.group.noteDateDataKey;
  const isStudyFinished = patientCardContext.patient.isStudyFinished;
  const studyRouteProgress = patientCardContext.patient.studyRouteProgress;
  const patientId = patientCardContext.patient.id;
  const patientUniqId = patientCardContext.patient.uniqueId;

  const [isEndReached, setEndReached] = useState<boolean>(false);
  const notesOverview = useNotesOverview();
  const { hasPermission } = useHasPermissions();

  //#region Data fetching

  const formConfig = useGetFormByIdQuery(formConfigId!, {
    enabled: !!formConfigId,
    suspense: false,
  });
  const formResults = QueryFactory.FormsQuery.useGetFormResultsQuery(
    {
      patientId: patientId,
      formConfigId: formConfigId,
      orderByDataKey: noteDateDataKey,
      limit: NOTES_LOAD_LIMIT,
    },
    {
      enabled: !!formConfigId,
      suspense: false,
    },
  );

  const isInitialLoading = formConfig.isInitialLoading || formResults.isInitialLoading;

  //#endregion

  //#region Editing

  const formEditing = useFormEditing();

  const [startingEditing, setStartingEditing] = useState(false);
  const onAddNote = useCallback(() => {
    if (startingEditing) return;
    setStartingEditing(true);
    formEditing
      .startFormFilling({ patientId, routeProgress: studyRouteProgress, formId: formConfigId! })
      .finally(() => setStartingEditing(false));
  }, [formConfigId, formEditing, patientId, startingEditing, studyRouteProgress]);

  //#endregion

  const noteVms = useMemo(() => {
    if (!formConfig?.data || !formResults?.data) return [];

    return formResults?.data?.data?.map((x) => ({
      form: formConfig.data,
      formResult: x,
    }));
  }, [formConfig.data, formResults?.data]);

  const onScroll = useCallback(async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    setEndReached(scrollTop + clientHeight >= scrollHeight - 5);
  }, []);

  return (
    <>
      <div className={Style.notesSection} data-test-id={'notes-section'}>
        <ExpandedRowHeader
          text={
            <IssueMark
              issueContext={{
                subject: 'Patient',
                topic: 'Notes',
                linkedPatientUniqId: patientUniqId,
              }}
              countDescendants={false}
              containerClassName={Style.issueMarkContainer}
            >
              {t('Header')}
            </IssueMark>
          }
          rightHeader={
            !isStudyFinished && (
              <PermissionsCheck permissions={Permissions.PatientCreate}>
                <AppButton
                  onClick={onAddNote}
                  variant={'icon-link'}
                  colorSchema={'primary'}
                  text={t('AddNote')}
                  Icon={AddIcon}
                />
              </PermissionsCheck>
            )
          }
          testId={'notes-header'}
        />

        <div className={Style.notesSectionBody} onScroll={onScroll}>
          <Loading loading={isInitialLoading} containerClassName={Style.loading}>
            {noteVms.map(
              (x, index, array) =>
                patientCardContext.group.noteDateDataKey &&
                x.formResult && (
                  <React.Fragment key={x.formResult.id}>
                    <Note
                      testId={'note'}
                      formDto={x.form}
                      formResult={x.formResult}
                      patientUniqueId={patientCardContext.patient.uniqueId}
                      noteDateDataKey={patientCardContext.group.noteDateDataKey}
                      canEdit={hasPermission(Permissions.PatientCreate) && !patientCardContext.patient?.isStudyFinished}
                    />
                    {index + 1 < array.length && <hr className={Style.notesBreak} />}
                  </React.Fragment>
                ),
            )}
          </Loading>
        </div>
        {formResults.data &&
          formResults.data?.totalCount > NOTES_LOAD_LIMIT &&
          !isInitialLoading &&
          formConfigId &&
          patientCardContext.patient.id && (
            <div
              className={clsx(Style.seeAllFooter, {
                [Style.seeAllFooterBlur]: !isEndReached,
                [Style.seeAllFooterBlurColored]: props.isColored,
              })}
            >
              <AppButton
                onClick={() =>
                  notesOverview.open({
                    configId: formConfigId,
                    patientId: patientCardContext.patient.id,
                  })
                }
                variant={'icon-link'}
                colorSchema={'primary'}
                text={commonLocalizer('Common_SeeAll')}
                className={Style.seeAllBtn}
                testId={'see-all'}
              />
            </div>
          )}
      </div>

      {formEditing.element}
      {notesOverview.element}
    </>
  );
};
