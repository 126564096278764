import React from 'react';
import { useEditor } from '@craftjs/core';
import { getCloneTree } from '../../uiEditor/uiEditor-helper';
import { useNodeBuffer } from './useBuffer';
import { ControlSelectorItem } from '../components/controlSelectorItem';
import { SelectorResolver } from '../controlsResolvers';

export const BufferPanel = () => {
  const { buffer } = useNodeBuffer();
  const { query } = useEditor((state) => ({
    enabled: state.options.enabled,
    selected: state.events.selected,
  }));

  return (
    <>
      {buffer?.map((node) => (
        <ControlSelectorItem
          Icon={SelectorResolver()[node.resolvedName]?.Icon || SelectorResolver().Container.Icon}
          key={node.name}
          id={node.name}
          renderComponent={() => {
            const data = JSON.parse(node.nodeTree);
            const newNodes = JSON.parse(data.nodes);
            const nodePairs = Object.keys(newNodes).reduce(
              (acc, key) => ({
                ...acc,
                [key]: query.parseSerializedNode(newNodes[key]).toNode((n) => (n.id = key)),
              }),
              {},
            );

            const tree = {
              rootNodeId: data.rootNodeId,
              nodes: nodePairs,
            };

            return getCloneTree(query, tree);
          }}
          name={node.name}
        />
      ))}
    </>
  );
};
