import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { DialogModalProps, ModalFooterProps } from './dialogModal';
import Style from './dialogModal.module.scss';
import { AppModalContainer } from '../uikit/modal/modal.component';
import clsx from 'clsx';
import { AppInputError } from 'uikit/wrappers';
import { AppButton } from 'uikit/buttons';

export const DialogModal: FC<PropsWithChildren<DialogModalProps>> = ({ children, ...props }) => {
  const { footer, bodyClassName, ...rest } = props;
  return (
    <AppModalContainer footer={<ModalFooter {...footer} />} bodyClassName={clsx(Style.body, bodyClassName)} {...rest}>
      {children}
    </AppModalContainer>
  );
};

export const ModalFooter: FC<ModalFooterProps> = ({
  scoreSection,
  errors,
  legendSection,
  leftButton,
  rightButton,
  customRightButton,
  navigationButton,
  customButtonBlock,
}) => {
  const [isNarrowModal, setIsNarrowModal] = useState(false);
  const hasAnyButton = useMemo(
    () => navigationButton || leftButton || customRightButton || rightButton,
    [customRightButton, leftButton, navigationButton, rightButton],
  );

  const navigationButtonComponent = useMemo(
    () =>
      navigationButton && (
        // @ts-ignore Variant can't be predefined
        <AppButton
          variant={navigationButton?.variant ?? 'icon-link'}
          colorSchema={navigationButton?.colorSchema ?? 'secondary'}
          {...navigationButton}
        />
      ),
    [navigationButton],
  );

  const leftButtonComponent = useMemo(() => {
    if (!leftButton) return <div />;

    return (
      // @ts-ignore Variant can't be predefined
      <AppButton
        variant={leftButton?.variant ?? 'button'}
        colorSchema={leftButton?.colorSchema ?? 'secondary'}
        {...leftButton}
      />
    );
  }, [leftButton]);

  const rightButtonComponent = useMemo(() => {
    if (customRightButton) return customRightButton;
    if (!rightButton) return <div />;

    return (
      // @ts-ignore Variant can't be predefined
      <AppButton
        variant={rightButton?.variant ?? 'button'}
        colorSchema={rightButton?.colorSchema ?? 'primary'}
        {...rightButton}
      />
    );
  }, [customRightButton, rightButton]);

  return (
    <div className={Style.footer}>
      {scoreSection}
      {(legendSection || hasAnyButton || customButtonBlock) && (
        <div ref={(ref) => setIsNarrowModal((ref?.clientWidth ?? 1) > 540)} className={Style.footerContainer}>
          {legendSection && <div className={Style.legendWrapper}>{legendSection}</div>}
          {(customButtonBlock || hasAnyButton) && (
            <>
              <AppInputError errors={errors} hideBorder position={'top'} className={Style.errorSectionWrapper} />
              <div className={Style.buttonContainer}>
                {navigationButtonComponent}
                {customButtonBlock ?? (
                  <div
                    className={clsx({
                      [Style.buttonsFlex]: !isNarrowModal,
                      [Style.buttonsGrid]: isNarrowModal,
                    })}
                  >
                    {leftButtonComponent}
                    {rightButtonComponent}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
