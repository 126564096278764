﻿export function conditionalConcat<T>(...args: (T[] | '' | false | undefined | null)[]) {
  let result: T[] = [];

  for (const arg of args) {
    if (Array.isArray(arg)) {
      result = result.concat(arg);
    }
  }

  return result;
}

/**
 * @example
 * Object.keys(tags).reduce(toDictionary(x => x, x => [tags[x]]), {})
 */
export function toDictionary<TSource, TKey extends string | number | symbol, TValue>(
  keySelector: (value: TSource, index: number) => TKey,
  valueSelector: (value: TSource, index: number) => TValue,
): (dictionary: { [k in TKey]: TValue }, x: TSource, index: number) => { [k in TKey]: TValue } {
  return (dictionary, x, index) => {
    const key = keySelector(x, index);
    const value = valueSelector(x, index);
    dictionary[key] = value;
    return dictionary;
  };
}

/**
 * @example
 * Object.keys(keyValuePairs).reduce(groupBy(x => x.key, x => x.value), [])
 */
export function groupBy<TSource, TKey extends string | number | symbol, TValue>(
  keySelector: (value: TSource, index: number) => TKey,
  valueSelector: (value: TSource, index: number) => TValue,
): (dictionary: { key: TKey; values: TValue[] }[], x: TSource, index: number) => { key: TKey; values: TValue[] }[] {
  return (groups, x, index) => {
    const key = keySelector(x, index);
    const value = valueSelector(x, index);

    const group = groups.find((g) => g.key === key);
    if (!group) groups.push({ key, values: [value] });
    else group.values.push(value);

    return groups;
  };
}
