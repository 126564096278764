import _ from 'lodash';
import { useMemo } from 'react';
import { groupBy } from 'src/helpers/arrayHelpers';
import { DateFormats, localFormat } from 'src/helpers/date-helpers';
import { useStudy } from 'src/helpers/hooks/useStudy';
import {
  FormLifecycleState,
  IFormResultDto,
  IFormResultVersionDto,
  IRouteProgressDto,
} from 'src/services/api/api-client';
import { useGetFormResultsQuery } from 'src/services/api/api-client/FormsQuery';
import { useGetPatientRouteProgressQuery } from 'src/services/api/api-client/StudyRoutesQuery';

export type FormListType = {
  formType: string;
  formResults: {
    resultId: number;
    stepName: string | undefined;
    createdAt: string | undefined;
    versions: IFormResultVersionDto[];
  }[];
};

export const useGetPatientFormList = (
  patientId: string | undefined,
  patientFinishingFormId: number | null | undefined,
  options?: {
    approvedOnly?: boolean;
  },
): { isLoading: boolean; multipleForms: FormListType[]; mandatoryForms: FormListType[] } => {
  const { groups } = useStudy() ?? {};

  const patientRouteProgressQuery = useGetPatientRouteProgressQuery(
    { patientId: patientId || '' },
    { enabled: patientId !== undefined },
  );

  const formResultsQuery = useGetFormResultsQuery(
    { patientId: patientId || '', lifecycleStates: options?.approvedOnly ? [FormLifecycleState.Approved] : undefined },
    { enabled: patientId !== undefined },
  );

  const formConfigIdsForNotes = useMemo(
    () => groups?.filter((x) => x.formConfigIdForNotes).map((x) => x.formConfigIdForNotes as number) || [],
    [groups],
  );

  const { mandatoryForms, multipleForms } = useMemo(() => {
    if (!patientRouteProgressQuery.data || !formResultsQuery.data) return { mandatoryForms: [], multipleForms: [] };

    return getRecordResultListItemVms(
      patientFinishingFormId,
      formResultsQuery.data.data,
      formConfigIdsForNotes,
      patientRouteProgressQuery.data,
      options,
    );
  }, [formConfigIdsForNotes, formResultsQuery.data, options, patientFinishingFormId, patientRouteProgressQuery.data]);

  return {
    isLoading: patientRouteProgressQuery.isInitialLoading || formResultsQuery.isInitialLoading,
    mandatoryForms,
    multipleForms,
  };
};

export const getRecordResultListItemVms = (
  patientFinishingFormId: number | null | undefined,
  formResults: IFormResultDto[],
  formConfigIdsForNotes: number[],
  routeProgressDto: IRouteProgressDto,
  options?: {
    approvedOnly?: boolean;
  },
) => {
  //#region mandatoryForms

  const mandatoryForms = routeProgressDto.steps
    .flatMap((step) =>
      step.forms.map((form) => {
        const versions = formResults.find((result) => result.id === form.formResultId)?.versions;
        return {
          formType: form.formType,
          resultId: form.formResultId,
          stepName: step.name,
          formConfigId: form.formConfigId,
          createdAt: _.first(versions)?.createdAt,
          versions: versions,
        };
      }),
    )
    .filter(
      ({ formConfigId, versions }) =>
        formConfigId !== patientFinishingFormId && (!options?.approvedOnly || !!versions?.length),
    )
    .reduce(
      groupBy(
        (x) => x.formType,
        (x) => x,
      ),
      [],
    )
    .map<FormListType>(({ key, values }) => ({
      formType: key,
      formResults: values.map((x) => ({
        stepName: x.stepName,
        versions: x.versions || [],
        resultId: x.resultId!,
        createdAt: x.createdAt ? localFormat(x.createdAt, DateFormats.longDate) : undefined,
      })),
    }));

  //#endregion

  //#region multipleForms

  const multipleFormIds = routeProgressDto.multiInstanceForms
    .filter((x) => x.formResultIds.length > 0)
    .filter((x) => !formConfigIdsForNotes.includes(x.formConfigId))
    .map((form) => form.formConfigId);

  const multipleForms = formResults
    .filter((result) => multipleFormIds.includes(result.formConfigId))
    .reduce(
      groupBy(
        (x) => x.formConfigId,
        (x) => x,
      ),
      [],
    )
    .map<FormListType>(({ key, values }) => ({
      formType: routeProgressDto.multiInstanceForms.find((x) => x.formConfigId === key)?.formType || '',
      formResults: values.map((x) => {
        const firstVersionCreatedAt = _.first(x.versions)?.createdAt;
        return {
          stepName: undefined,
          createdAt: firstVersionCreatedAt ? localFormat(firstVersionCreatedAt, DateFormats.longDate) : undefined,
          resultId: x.id,
          versions: x.versions,
        };
      }),
    }));

  //#endregion

  return {
    multipleForms,
    mandatoryForms,
  };
};
