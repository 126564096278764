import React from 'react';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import { ToastBodyProps as ToastBodyProps } from './toastBody';
import Style from './toastBody.module.css';

export const ToastBody = (props: ToastBodyProps) => {
  const { Icon } = props;

  return (
    <div className={Style.container}>
      <div className={clsx(Style.headerText, TypographyStyles.paragraph14)}>
        {Icon && <Icon className={clsx(Style.icon)} />}
        {props.header}
      </div>
      <div className={clsx(TypographyStyles.paragraph14, Style.message)}>{props.message}</div>
    </div>
  );
};
