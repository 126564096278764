import React from 'react';
import Style from './appRadioInput.module.scss';
import clsx from 'clsx';

export type AppRadioInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  prefixComponent?: string | JSX.Element;
  caption?: string;
  className?: string;
};

export const AppRadioInput = React.forwardRef<HTMLInputElement, AppRadioInputProps>(
  ({ prefixComponent, caption, className, ...props }, ref) => {
    return (
      <label className={clsx(Style.radioContainer, className)} data-disabled={props.disabled}>
        <input {...props} type={'radio'} ref={ref} />

        <span className={Style.caption}>
          {prefixComponent && <span className={Style.prefix}>{prefixComponent}</span>}
          <span>{caption}</span>
        </span>
      </label>
    );
  },
);
