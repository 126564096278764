import React, { FC } from 'react';
import Style from './diffValue.module.scss';
import { diffValueControlsResolver } from './diffValueResolver';
import { FormFillContext } from 'src/components/formEditor/uiEditor/provider/formFill.context';
import { useContextSelector } from 'use-context-selector';
import { findNodeWithDataKey } from 'src/components/formEditor/uiEditor/uiEditor-helper';
import { TypographyStyles } from 'styles';

type DiffValueProps = {
  dataKey: string;
};

export const DiffValueComponent: FC<DiffValueProps> = ({ dataKey }) => {
  const { nodes } = useContextSelector(FormFillContext, (x) => x);
  const node = findNodeWithDataKey(nodes ?? {}, dataKey);

  const label =
    findNodeWithDataKey(nodes ?? {}, dataKey)?.props.label ??
    findNodeWithDataKey(nodes ?? {}, dataKey)?.props.text ??
    findNodeWithDataKey(nodes ?? {}, dataKey)?.props.dataKey ??
    ' ';

  // @ts-ignore
  const ResolvedComponent = diffValueControlsResolver[node?.type.resolvedName];

  return (
    <div className={Style.containerWithLabel}>
      {/* @ts-ignore */}
      <div className={TypographyStyles.heading2}>{node?.type.resolvedName === 'RecordResultList' ? <></> : label}</div>
      <div className={Style.diffValueContainer}>
        <ResolvedComponent {...node?.props} />
      </div>
    </div>
  );
};
