import React, { FC } from 'react';
import { QuestionnaireToCreate } from './ManageSurveysModal';
import { Trans, useTranslation } from 'react-i18next';
import { DialogModal } from '../../../dialogModal/dialogModal.component';
import Style from './manageSurveys.module.scss';
import { DateFormats, localFormat } from '../../../../helpers/date-helpers';

export const CreatingConfirmationDialog: FC<{
  onCancel: () => void;
  onApply: () => void;
  isLoading: boolean;
  questionnaireToCreate: QuestionnaireToCreate | null;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogModal
      visible={!!props.questionnaireToCreate}
      onHide={props.isLoading ? () => {} : props.onCancel}
      footer={{
        leftButton: {
          text: t('Common_no'),
          type: 'reset',
          onClick: props.onCancel,
          disabled: props.isLoading,
        },
        rightButton: {
          colorSchema: 'destroy',
          text: t('Common_yes'),
          onClick: props.onApply,
          disabled: props.isLoading,
          isLoading: props.isLoading,
          hasLoaded: props.isLoading,
        },
      }}
      title={t('Dashboard.PatientAccordion.SurveySection.SurveyManaging.Modal.CreatingConfirmationDialog.Header')}
    >
      <Trans
        i18nKey={'Dashboard.PatientAccordion.SurveySection.SurveyManaging.Modal.CreatingConfirmationDialog.MainText'}
        values={{
          surveyType: props.questionnaireToCreate?.survey.typeString,
          date: props.questionnaireToCreate
            ? localFormat(new Date(props.questionnaireToCreate.range.start), DateFormats.longDate)
            : '',
        }}
      >
        <strong className={Style.awfulText} />
      </Trans>
    </DialogModal>
  );
};
