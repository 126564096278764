import React, { FC } from 'react';
import { QuestionnaireVm } from './ManageSurveysModal';
import { DialogModal } from '../../../dialogModal/dialogModal.component';
import { DateFormats, localFormat } from '../../../../helpers/date-helpers';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../../application/localisation/useCommonLocalization';

export const RevokeConfirmationDialog: FC<{
  onCancel: () => void;
  onApply: () => void;
  isLoading: boolean;
  questionnaireToDelete: QuestionnaireVm | null;
}> = (props) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection.SurveyManaging.Modal');
  const commonLocalizer = useCommonLocalization();

  return (
    <DialogModal
      visible={!!props.questionnaireToDelete}
      onHide={props.isLoading ? () => {} : props.onCancel}
      footer={{
        leftButton: {
          disabled: props.isLoading,
          text: commonLocalizer('Common_no'),
          type: 'reset',
          onClick: props.onCancel,
        },
        rightButton: {
          colorSchema: 'destroy',
          disabled: props.isLoading,
          isLoading: props.isLoading,
          hasLoaded: props.isLoading,
          text: t('DeleteConfirmationDialog.Apply'),
          onClick: props.onApply,
        },
      }}
      title={t('DeleteConfirmationDialog.Header')}
    >
      <span>
        {t('DeleteConfirmationDialog.MainText', {
          surveyType: props.questionnaireToDelete?.surveyType,
          date: props.questionnaireToDelete ? localFormat(props.questionnaireToDelete.start, DateFormats.longDate) : '',
        })}
      </span>
    </DialogModal>
  );
};
