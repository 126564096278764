﻿import React, { FC, PropsWithChildren } from 'react';
import { RPDF } from '../../react-pdf';
import { ReportColors } from '../../report-colors';

export const RepeatableFormSection: FC<PropsWithChildren<{ title: string }>> = ({ children, title }) => {
  return (
    <RPDF.View style={{ marginBottom: 12 }}>
      <RPDF.Text
        style={{
          flexDirection: 'row',
          paddingVertical: 4,
          ...RPDF.typography.heading5,
          ...RPDF.tableStyles.divider,
          color: ReportColors.plain,
          fontWeight: 700,
        }}
      >
        {title}
      </RPDF.Text>
      {children}
    </RPDF.View>
  );
};
