import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useId, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { handleSubmitFormError } from 'src/application/error-handling/useErrorHandler';
import { useModal } from 'src/application/hooks/useModal';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
import { ValidationFormRules } from 'src/helpers/validation-helpers';
import { HookFormTextInput } from 'src/hookFormControls/hookFormTextInput';
import { navigateToRoute } from 'src/navigation/routes';
import {
  CheckPoint,
  CheckPointForm,
  CreateStudyRouteDto,
  FormActionObject,
  IFormDto,
  RouteProgressStateEnum,
  StepTimeLimitation,
} from 'src/services/api/api-client';
import { useGetFormsQuery } from 'src/services/api/api-client/FormsQuery';
import { getStudyListQueryKey } from 'src/services/api/api-client/StudyQuery';
import { createStudyRoute, getStudyRouteById } from 'src/services/api/api-client/StudyRoutesClient';
import { AppButton } from 'uikit/buttons';
import { AppDropdownField } from 'uikit/fields';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { enumToDropdownOptions } from 'uikit/inputs/dropdown/dropdown-helper';
import styles from './useStudyRouteCreating.module.scss';

type FormState = {
  scheme: CheckPoint[];
};

type StudyRouteCreatingModalProps = {
  visible: boolean;
  onHide: () => void;
  onSubmit: () => void;
  form: UseFormReturn<FormState>;
  forms: IFormDto[] | undefined;
  studyId: number;
};

export const useStudyRouteCreating = () => {
  const modal = useModal();
  const queryClient = useQueryClient();
  const [groupId, setGroupId] = useState<number>();
  const [studyId, setStudyId] = useState<number>();
  const { data: availableForms } = useGetFormsQuery({ studyId: studyId });
  const form = useForm<FormState>({ defaultValues: {} });

  const submit = useCallback(
    async (values: FormState) => {
      if (!groupId) return;

      try {
        await createStudyRoute(
          new CreateStudyRouteDto({
            groupId: groupId,
            scheme: values.scheme,
          }),
        );
        modal.closeModal();
      } catch (e) {
        handleSubmitFormError(e, form.setError);
        return;
      }

      await queryClient.invalidateQueries(getStudyListQueryKey());
    },
    [form.setError, groupId, modal, queryClient],
  );

  return {
    element: modal.visible && studyId && (
      <StudyRouteCreatingModal
        form={form}
        onHide={modal.closeModal}
        visible={modal.visible}
        onSubmit={form.handleSubmit(submit)}
        forms={availableForms}
        studyId={studyId}
      />
    ),
    start: async (sId: number, gId: number, routeId?: string | null) => {
      setGroupId(gId);
      setStudyId(sId);
      form.reset();

      if (routeId) {
        const route = await getStudyRouteById(routeId);
        form.setValue('scheme', route.schema);
      }

      modal.openModal();
    },
  };
};

const StudyRouteCreatingModal: FC<StudyRouteCreatingModalProps> = ({
  form,
  onHide,
  onSubmit,
  visible,
  forms,
  studyId,
}) => {
  const formId = useId();

  const { t } = useScopedTranslation('StudyRouteCreating', 'dev');
  const { t: commonLocalization } = useTranslation();

  const scheme = form.watch('scheme');
  const checkpointArray = useFieldArray({
    control: form.control,
    name: 'scheme',
  });

  const availableFormOptions: DropdownOption[] = useMemo(() => {
    return (
      forms?.map((x) => ({
        key: x.id,
        text: x.type,
      })) ?? []
    );
  }, [forms]);

  const initStateOptions = useMemo(() => {
    return enumToDropdownOptions(RouteProgressStateEnum).filter((x) =>
      [RouteProgressStateEnum.InProgress, RouteProgressStateEnum.Init].includes(RouteProgressStateEnum[x.key]),
    );
  }, []);

  return (
    <DialogModal
      visible={visible}
      onHide={onHide}
      title={t('Header')}
      bodyClassName={styles.modalBody}
      footer={{
        errors: !!form.formState.errors.root ? t('CommonError') : undefined,
        leftButton: {
          text: commonLocalization('Common_Cancel'),
          onClick: onHide,
          disabled: form.formState.isSubmitting,
        },
        rightButton: {
          text: commonLocalization('Common_Save'),
          type: 'submit',
          disabled: form.formState.isSubmitting,
          isLoading: form.formState.isSubmitting,
          form: formId,
        },
      }}
    >
      <form id={formId} onSubmit={onSubmit} autoComplete={'off'}>
        {checkpointArray.fields.map((step, index, array) => (
          <React.Fragment key={step.id}>
            <HookFormTextInput
              control={form.control}
              name={`scheme.${index}.name`}
              labelProps={{ text: t('Name.FieldLabel') }}
              placeholder={t('Name.FieldPlaceholder')}
              rules={ValidationFormRules().requiredRule}
            />
            <HookFormTextInput
              control={form.control}
              name={`scheme.${index}.shortName`}
              labelProps={{ text: t('ShortName.FieldLabel') }}
              placeholder={t('ShortName.FieldPlaceholder')}
              rules={ValidationFormRules().requiredRule}
            />
            {availableFormOptions && (
              <Controller
                control={form.control}
                name={`scheme.${index}.forms`}
                render={({ field: { onChange, value } }) => (
                  <AppDropdownField
                    isMultiple
                    labelProps={{
                      text: t('Forms.FieldLabel'),
                      rightSection: (
                        <Link to={navigateToRoute.studySettings(studyId)} target={'_blank'} className={styles.link}>
                          {t('CreateForm')}
                        </Link>
                      ),
                    }}
                    placeholder={t('Forms.FieldPlaceholder')}
                    options={availableFormOptions}
                    value={availableFormOptions.filter((x) => value.map((v) => v.formConfigId).includes(+x.key))}
                    onChange={(v) =>
                      onChange(
                        v?.map(
                          (x) =>
                            new CheckPointForm({
                              formConfigId: +x.key,
                              formConfigIdForInitialAnswers: null,
                            }),
                        ),
                      )
                    }
                  />
                )}
              />
            )}
            <Controller
              control={form.control}
              name={`scheme.${index}.initState`}
              rules={ValidationFormRules().requiredRule}
              render={({ field: { onChange, value } }) => (
                <AppDropdownField
                  isRequired
                  labelProps={{ text: t('InitialState.FieldLabel') }}
                  placeholder={t('InitialState.FieldPlaceholder')}
                  options={initStateOptions}
                  value={initStateOptions.find((o) => o.key === RouteProgressStateEnum[value])}
                  onChange={(v) => v?.key && onChange(RouteProgressStateEnum[v.key])}
                />
              )}
            />
            {scheme[index].initState === RouteProgressStateEnum.Init && (
              <>
                <Controller
                  control={form.control}
                  name={`scheme.${index}.limitation.startDateActionObject.formConfigId`}
                  rules={ValidationFormRules().requiredRule}
                  render={({ field: { onChange, value } }) => (
                    <AppDropdownField
                      isRequired
                      labelProps={{
                        text: t('FormWithInitialDataKey.FieldLabel'),
                        tooltip: t('FormWithInitialDataKey.Tooltip'),
                      }}
                      placeholder={t('FormWithInitialDataKey.FieldPlaceholder')}
                      options={availableFormOptions}
                      value={availableFormOptions.find((x) => x.key === value)}
                      onChange={(v) => onChange(v?.text)}
                    />
                  )}
                />
                <HookFormTextInput
                  control={form.control}
                  name={`scheme.${index}.limitation.startDateActionObject.dataKey`}
                  labelProps={{
                    text: t('InitialFieldDataKey.FieldLabel'),
                    tooltip: t('InitialFieldDataKey.Tooltip'),
                  }}
                  placeholder={t('InitialFieldDataKey.FieldPlaceholder')}
                  rules={ValidationFormRules().requiredRule}
                />
                <HookFormTextInput
                  control={form.control}
                  type={'number'}
                  name={`scheme.${index}.limitation.numberOfStartShiftPeriod`}
                  labelProps={{
                    text: t('StartShiftingPeriod.FieldLabel'),
                    tooltip: t('StartShiftingPeriod.Tooltip'),
                  }}
                  placeholder={t('StartShiftingPeriod.FieldPlaceholder')}
                  rules={ValidationFormRules().requiredRule}
                />
              </>
            )}

            <HookFormTextInput
              control={form.control}
              type={'number'}
              min={1}
              name={`scheme.${index}.limitation.numberOfEditingPeriod`}
              labelProps={{
                text: t('EditingPeriod.FieldLabel'),
                tooltip: t('EditingPeriod.Tooltip'),
              }}
              placeholder={t('EditingPeriod.FieldPlaceholder')}
              rules={ValidationFormRules().requiredRule}
            />
            <AppButton
              variant={'icon-link'}
              colorSchema={'primary'}
              text={t('RemoveStep')}
              onClick={() => checkpointArray.remove(index)}
              className={styles.removeButton}
            />
            {index + 1 < array.length && <hr className={styles.dataBreak} />}
          </React.Fragment>
        ))}

        <AppButton
          variant={'icon-link'}
          colorSchema={'primary'}
          text={t('AddNewStep')}
          onClick={() =>
            checkpointArray.append(
              new CheckPoint({
                name: 'First step',
                shortName: '1st.',
                forms: [],
                initState: RouteProgressStateEnum.InProgress,
                stateTransferObject: null,
                isFinalStep: false,
                limitation: new StepTimeLimitation({
                  // TODO: Change to enum
                  editingPeriodUnits: 'D',
                  numberOfEditingPeriod: 49,
                  // TODO: Change to enum
                  startPeriodUnits: 'D',
                  numberOfStartShiftPeriod: 0,
                  startDateActionObject: new FormActionObject({
                    dataKey: null,
                    formConfigId: null,
                    funcName: null,
                    value: null,
                  }),
                  startWhenStepWithNameDone: null,
                }),
              }),
            )
          }
        />
      </form>
    </DialogModal>
  );
};
