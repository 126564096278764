﻿import React, { FC } from 'react';
import Style from './AppToastContainer.module.scss';
import { ToastContainer } from 'react-toastify';
import { ReactComponent as CrossIcon } from 'src/assets/img/common/cross_24.svg';

export const AppToastContainer: FC = () => {
  return (
    <ToastContainer
      className={Style.toastContainer}
      bodyClassName={Style.toastBody}
      closeButton={({ closeToast }) => (
        <button className={Style.closeButton} onClick={closeToast}>
          <CrossIcon />
        </button>
      )}
      position={'bottom-left'}
      hideProgressBar={true}
      newestOnTop={true}
      draggable={false}
      autoClose={false}
      closeOnClick={false}
    />
  );
};
