import i18next from 'i18next';
import { formatScoreValue } from 'src/helpers/question-helper';
import { ISurveyIndicatorModel, QualityMetric } from 'src/services/api/api-client';
import { formatDateRange } from '../../helpers/date-helpers';

import styles from './baseIndicator.module.css';

export const getTooltipText = (survey: ISurveyIndicatorModel | null) => {
  let tooltipText = i18next.t('Common_NoData');
  let textStyle = '';

  if (survey && (survey.value || survey.value === 0)) {
    tooltipText = formatScoreValue(survey.value);
  } else if (survey?.hasSurveyAlreadyExpired || survey?.isSurveyCloseToExpired) {
    const shouldHideWarningSign =
      survey.daysBeforeExpiring < 0 &&
      survey.expiredStateDisplayDurationInDays > 0 &&
      Math.abs(survey.daysBeforeExpiring) >= survey.expiredStateDisplayDurationInDays;

    if (!shouldHideWarningSign) {
      tooltipText =
        survey.isSurveyCloseToExpired && !survey.hasSurveyAlreadyExpired
          ? i18next.t('Dashboard.PatientTable.SurveyTooltip.TooltipSurveyClosedToExpire_interval', {
              postProcess: 'interval',
              count: survey.daysBeforeExpiring,
            })
          : i18next.t('Dashboard.PatientTable.SurveyTooltip.TooltipSurveyExpired');
    }
  }

  if (survey && survey.isSurveyFilled && survey.qualityMetric === QualityMetric.NotCalculated) {
    tooltipText = i18next.t('Dashboard.PatientTable.SurveyTooltip.NotCalculated');
    textStyle = styles.textWithHeaderExtra;
  }

  const headerText =
    survey && survey.isSurveyFilled && survey.fillingDate
      ? formatDateRange(survey.fillingDate, survey.fillingDate)
      : undefined;

  return { tooltipText, headerText, textStyle };
};
