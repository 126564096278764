import React, { FunctionComponent, SVGProps } from 'react';
import { NodeTree, useEditor } from '@craftjs/core';
import { AppButton } from '../../../uikit/buttons/button/button.component';
import { Tooltip } from '../../../uikit/tooltip/tooltip.component';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/common/delete.svg';
import Style from './selectorItem.module.css';
import { useDispatch } from 'react-redux';
import { FaqEvents } from '../../../../features/faq/faq-reducer';

export interface ISelector {
  Icon?: FunctionComponent<SVGProps<any>>;
  component: React.ReactElement | (() => NodeTree | React.ReactElement);
  tooltipText?: string;
  name: string;
}

export type SelectorItemProps = ISelector & {
  onCreate?: (nodeTree: NodeTree) => void;
  id?: string;
};

export const SelectorItem = (props: SelectorItemProps) => {
  const { Icon, component, tooltipText, name, onCreate, id } = props;
  const dispatch = useDispatch();
  const {
    connectors: { create },
  } = useEditor();

  return (
    <div className={Style.container} ref={(ref) => ref && create(ref, component, { onCreate })}>
      <Tooltip text={tooltipText || ''} disabled={!tooltipText}>
        <div className={Style.content}>
          {Icon && <Icon />}
          {name}
          {id && (
            <AppButton
              className={Style.deleteButton}
              onClick={() => dispatch(FaqEvents.removeFromBuffer(id))}
              variant={'icon-link'}
              colorSchema={'decline'}
              Icon={DeleteIcon}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
};
