import React, { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as DragAndDrop } from 'assets/img/formBuilder/drag_and_drop_24.svg';
import { ReactComponent as Delete } from 'assets/img/formBuilder/delete_24.svg';
import Style from './controlSelectorItem.module.scss';
import { NodeTree, useEditor } from '@craftjs/core';
import { AppButton } from 'uikit/buttons';
import { useNodeBuffer } from '../bufferPanel/useBuffer';

export type ControlSelectorItem = {
  renderComponent: (() => React.ReactElement) | (() => NodeTree | React.ReactElement);
  name: string;
  Icon: FunctionComponent<SVGProps<any>>;
  id?: string;
};

export const ControlSelectorItem = ({ Icon, name, renderComponent, id }: ControlSelectorItem) => {
  const { removeFromBuffer } = useNodeBuffer();
  const {
    connectors: { create },
  } = useEditor();

  return (
    <div className={Style.container} ref={(ref) => ref && create(ref, renderComponent)}>
      <Icon className={Style.icon} />
      <div className={'truncate'}>{name}</div>
      <div className={Style.marginLeft}>
        {id && (
          <AppButton variant={'icon-link'} colorSchema={'basic'} Icon={Delete} onClick={() => removeFromBuffer(id)} />
        )}
        <AppButton variant={'icon-link'} colorSchema={'basic'} Icon={DragAndDrop} iconClassName={Style.cursorGrab} />
      </div>
    </div>
  );
};
