import { BooleanParam, NumberParam, StringParam, withDefault } from 'use-query-params';
import { PatientStudyStateEnum } from 'src/services/api/api-client';
import { PatientFilterParamsType } from 'src/components/patientTable/patientTable';
import { TagFiltersParam } from 'src/features/patientTags/TagFiltersParam';

export const patientFilterParams = (
  defaultGroup?: number | null,
  defaultStudyState?: PatientStudyStateEnum | null,
): PatientFilterParamsType => ({
  patientGroup: withDefault(NumberParam, defaultGroup),
  isClinic: BooleanParam,
  isControlled: BooleanParam,
  canBeArchived: BooleanParam,
  needChecking: BooleanParam,
  hasNoApp: BooleanParam,
  studyState: withDefault(NumberParam, defaultStudyState),
  searchQuery: StringParam,
  studyStartNotDefine: BooleanParam,
  tags: TagFiltersParam,
  issueId: NumberParam,
});

export function patientFilterToBackendRequest(query: {
  patientGroup?: number | null;
  isClinic?: boolean | null;
  isControlled?: boolean | null;
  canBeArchived?: boolean | null;
  hasNoApp?: boolean | null;
  needChecking?: boolean | null;
  studyState?: PatientStudyStateEnum | null;
  studyStartNotDefine?: boolean | null;
  tags?: Record<string, string[]>;
}): {
  patientGroup: number;
  isClinic?: boolean | null;
  isControlled?: boolean | null;
  canBeArchived?: boolean | null;
  hasNoApp?: boolean | null;
  needChecking?: boolean | null;
  studyState?: PatientStudyStateEnum | null;
  studyStartNotDefine?: boolean | null;
  tags?: Record<string, string[]>;
} {
  return {
    patientGroup: query.patientGroup ?? 0,
    isClinic: query.isClinic ?? undefined,
    isControlled: query.isControlled ?? undefined,
    canBeArchived: query.canBeArchived ?? undefined,
    hasNoApp: query.hasNoApp ?? undefined,
    needChecking: query.needChecking ?? undefined,
    studyState: query.studyState ?? undefined,
    studyStartNotDefine: query.studyStartNotDefine,
    tags: query.tags,
  };
}
