import React from 'react';
import clsx from 'clsx';
import Style from './viewport.module.css';
import { selectors } from '../../selectors/selectors';
import { SelectorItem } from '../../selectors/selectorItem/selectorItem';

export const Toolbox = () => {
  return (
    <div className={clsx(Style.toolbox)}>
      <div className={Style.toolboxContainer}>
        {selectors().map((s) => (
          <SelectorItem key={s.name} {...s} />
        ))}
      </div>
    </div>
  );
};
