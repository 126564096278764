﻿import { CellContext, ColumnDef } from '@tanstack/react-table';
import React, { FunctionComponent, MouseEvent, SVGProps } from 'react';
import buttonStyles from './ButtonColumn.module.scss';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { ButtonColumnColorSchema } from 'uikit/table/columns/ButtonColumn';

export interface ButtonColumnOptions<TRow> {
  id: string;
  to: (cell: CellContext<TRow, unknown>) => string;
  onClick?: (cell: CellContext<TRow, unknown>) => void;
  /** @default secondary */
  colorSchema?: ButtonColumnColorSchema;
  icon: FunctionComponent<SVGProps<any>>;
  visible?: (cell: CellContext<TRow, unknown>) => boolean;
  className?: string | ((cell: CellContext<TRow, unknown>) => string | undefined);
}

export function linkButtonColumn<TRow>(options: ButtonColumnOptions<TRow>): ColumnDef<TRow> {
  const Icon = options.icon;

  return {
    id: options.id,
    cell: (cell) => {
      if (typeof options.visible === 'function' && !options.visible(cell)) return <></>;
      const onClick = (e: MouseEvent<HTMLAnchorElement>) => options.onClick?.(cell);
      const className = typeof options.className === 'function' ? options.className(cell) : options.className;

      return (
        <div className={buttonStyles.buttonContainer}>
          <NavLink
            to={options.to(cell)}
            onClick={options.onClick && onClick}
            className={clsx(buttonStyles.button, className, {
              [buttonStyles.primary]: options.colorSchema === 'primary',
              [buttonStyles.secondary]: options.colorSchema === 'secondary' || !options.colorSchema,
            })}
          >
            <Icon className={buttonStyles.icon} />
          </NavLink>
        </div>
      );
    },
    header: '',
    meta: { cellClassName: buttonStyles.buttonCell, dontUseHeaderWidth: true },
  };
}
