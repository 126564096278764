import React from 'react';
import clsx from 'clsx';
import Style from './appSwitcherInput.module.scss';

export type AppSwitcherProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  testId?: string;
};

export const AppSwitcherInput = (props: AppSwitcherProps) => {
  const { label, className, testId, disabled, ...rest } = props;
  return (
    <label data-disabled={disabled} className={clsx(Style.switchContainer, className)} data-test-id={testId}>
      <input disabled={disabled} type="checkbox" data-test-id={testId} {...rest} />
      {label}
    </label>
  );
};
