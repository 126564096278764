﻿import React, { FC, PropsWithChildren } from 'react';
import { RPDF } from '../../react-pdf';
import { ReportColors } from '../../report-colors';

export const FieldGroup: FC<
  PropsWithChildren<{
    title: string;
    hideTitle?: boolean;
  }>
> = ({ children, title, hideTitle }) => {
  return (
    <RPDF.View style={{ marginVertical: 4 }}>
      {!hideTitle && (
        <RPDF.Text
          minPresenceAhead={40}
          style={[
            RPDF.typography.heading5b,
            RPDF.tableStyles.divider,
            { paddingVertical: 4, borderBottomColor: ReportColors.main300 },
          ]}
        >
          {title}
        </RPDF.Text>
      )}
      {children}
    </RPDF.View>
  );
};
