﻿import { FieldSkipReason } from 'src/services/api/api-client';
import { SerializedNodeWithId } from 'src/features/report/records/recordReport-helper';
import { createContext } from 'use-context-selector';

export type RecordContextType = {
  formResult: { [key: string]: any };
  skipReasons: { [key: string]: FieldSkipReason };
  nodes: SerializedNodeWithId[];
};

export const RecordContext = createContext<RecordContextType>(undefined as any);
