import React, { useEffect, useState } from 'react';
import Style from './addToBufferModal.module.scss';
import { useNodeBuffer } from './useBuffer';
import { useForm } from 'react-hook-form';
import { IFormControlsResolver } from 'src/features/forms/base/controls/IFormControlsResolver';
import { BufferItemType } from 'src/containers/formBuilder/provider/formBuilder.provider';
import { isValid } from 'date-fns';
import { useModal } from 'src/application/hooks/useModal';
import { HookFormTextInput } from 'src/hookFormControls/hookFormTextInput';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

export const useAddToBufferModal = () => {
  const { t } = useScopedTranslation('StudySettingsPage.FormBuilder.AddToBufferModal', 'dev');
  const commonLocalizer = useCommonLocalization();
  const modal = useModal('CLOSED');
  const [nodeTree, setNodeTree] = useState<string | undefined>();
  const [defaultName, setDefaultName] = useState<string>('');
  const [resolvedName, setResolvedName] = useState<keyof IFormControlsResolver | undefined>();
  const { buffer, addToBuffer } = useNodeBuffer();

  const { handleSubmit, reset, control, setValue } = useForm<{ name: string }>();

  useEffect(() => {
    reset();
    setValue('name', defaultName);
  }, [defaultName, reset, setValue, modal.visible]);

  const nameValidationRule = (templateName: string) => {
    const isExist = buffer.some((x) => x.name === templateName);
    return isExist ? t('TemplateNameExist', { templateName }) : undefined;
  };

  const onSubmit = handleSubmit((data) => {
    if (!nodeTree || !resolvedName) {
      return;
    }
    addToBuffer?.({ name: data.name, nodeTree, resolvedName });
    modal.closeModal();
  });

  return {
    start: (item: BufferItemType) => {
      modal.openModal();
      setNodeTree(item.nodeTree);
      setResolvedName(item.resolvedName);
      setDefaultName(item.name);

      reset();
    },
    modal: (
      <DialogModal
        onHide={modal.closeModal}
        visible={modal.visible}
        title={'Add to buffer'}
        containerClassName={Style.modal}
        bodyClassName={Style.modalBody}
        footer={{
          leftButton: {
            text: commonLocalizer('Common_Cancel'),
            type: 'reset',
            onClick: modal.closeModal,
          },
          rightButton: {
            text: commonLocalizer('Common_Save'),
            type: 'submit',
            disabled: !isValid,
            form: 'bufferModal',
          },
        }}
      >
        <form id={'bufferModal'} onSubmit={onSubmit}>
          <HookFormTextInput
            control={control}
            name={'name'}
            labelProps={{
              text: t('TemplateLabel'),
            }}
            placeholder={t('TemplateLabelPlaceholder')}
            rules={{
              validate: nameValidationRule,
            }}
          />
        </form>
      </DialogModal>
    ),
  };
};
