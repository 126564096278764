import React, { useMemo } from 'react';
import { FormFillContext } from 'src/components/formEditor/uiEditor/provider/formFill.context';
import { useContextSelector } from 'use-context-selector';
import { useGetFieldChanges } from '../../tagsModal/useGetFieldChanges';
import { FieldSkipReason } from 'src/services/api/api-client';
import { LocalizedResourceDictionaryKeys } from 'src/application/localisation/i18next';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';

export const useGetDiffValues = (dataKey: string) => {
  const { t } = useScopedTranslation('Forms.Controls.SkipQuestionReason');
  const { initialValues, initialSkippedFieldsData, values } = useContextSelector(FormFillContext, (x) => x);
  const { valueChanges, skipFieldChanges } = useGetFieldChanges();

  const oldSkipReason = useMemo(
    () =>
      initialSkippedFieldsData?.[dataKey] !== undefined
        ? t(`${FieldSkipReason[initialSkippedFieldsData[dataKey]]}` as LocalizedResourceDictionaryKeys)
        : undefined,
    [],
  );

  const newSkipReason = useMemo(
    () =>
      skipFieldChanges?.[dataKey] !== undefined
        ? t(`${FieldSkipReason[skipFieldChanges[dataKey]]}` as LocalizedResourceDictionaryKeys)
        : undefined,
    [],
  );

  return {
    oldValue: initialValues?.[dataKey],
    changes: valueChanges?.[dataKey],
    newValue: values?.[dataKey],
    oldSkipReason,
    newSkipReason,
  };
};
