import React from 'react';
import { Chips } from 'uikit/chips/chips.component';
import { DecodedValueMap, SetQuery } from 'use-query-params';
import { useTranslation } from 'react-i18next';

import Style from './../../../components/patientTable/patientFilters.module.css';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import TablePageLayout from '../../../components/tablePageLayout/TablePageLayout';
import { studyQueryParamsConfig } from './StudyList.component';

export const StudyFilters = (props: {
  filterFields: string[];
  localizationPrefix: string;
  testId?: string;
  queryParams: DecodedValueMap<typeof studyQueryParamsConfig>;
  setQueryParams: SetQuery<typeof studyQueryParamsConfig>;
}) => {
  const { t } = useTranslation('dev');
  const { setQueryParams, queryParams, filterFields, localizationPrefix } = props;

  return (
    <TablePageLayout.Header.Row>
      <div className={Style.filterGroup}>
        {filterFields.map((x, index) => {
          return (
            <Chips
              key={index}
              text={t(`${localizationPrefix}.${x}` as LocalizedResourceDictionaryKeys)}
              checked={queryParams[x] === true}
              onClick={() => {
                setQueryParams({
                  [x]: queryParams[x] === true ? undefined : true,
                  page: 1,
                });
              }}
            />
          );
        })}
      </div>
      <TablePageLayout.Header.Search
        value={queryParams.searchQuery || ''}
        type={'search'}
        placeholder={t(`${localizationPrefix}.Search` as LocalizedResourceDictionaryKeys)}
        onChange={(e) => {
          e.preventDefault();
          setQueryParams({ searchQuery: e.target.value });
        }}
      />
    </TablePageLayout.Header.Row>
  );
};
