﻿import React, { FC, memo, useMemo } from 'react';
import { issueSubjects, statusOptions } from '../../components/issue/bar/issueFilters-helper';
import { useTranslation } from 'react-i18next';
import { IssuesStateEnum } from '../../services/api/api-client';
import Style from '../../components/issue/bar/issueFilters.module.css';
import { DropDownMenuOption } from 'uikit/dropDownMenu/dropDownMenu';
import clsx from 'clsx';
import { LocalizedResourceDictionaryKeys } from '../../application/localisation/i18next';
import _ from 'lodash';
import { DropDownMenu } from 'uikit/dropDownMenu/dropDownMenu.component';
import { DirectionalHint } from '@fluentui/react';
import { AppButton } from 'uikit/buttons';
import { ReactComponent as Reset } from 'src/assets/img/issues/reset_16.svg';
import { ReactComponent as CheckboxUnchecked } from 'src/assets/img/issues/checkbox_unchecked_16.svg';
import { ReactComponent as CheckboxChecked } from 'src/assets/img/issues/checkbox_checked_16.svg';
import TablePageLayout from '../../components/tablePageLayout/TablePageLayout';
import { useMonitoringPageContext } from './MonitoringPage';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const MonitoringPageFilters: FC = () => {
  const { setQueryParams, queryParams } = useMonitoringPageContext();
  const { t } = useTranslation();

  const hasAnyFilter = queryParams.groupIds || queryParams.issueState || queryParams.subjects;

  return (
    <TablePageLayout.Header.Row>
      <IssueStateFilter />
      <SubjectFilter />
      <GroupsFilter />

      {hasAnyFilter && (
        <AppButton
          variant={'icon-link'}
          colorSchema={'decline'}
          Icon={Reset}
          onClick={() => setQueryParams({ subjects: undefined, issueState: undefined, groupIds: undefined })}
        />
      )}

      <TablePageLayout.Header.Search
        value={queryParams.patientUniqueId || ''}
        placeholder={t('Issues.Filters.SearchPlaceholder')}
        onChange={(e) => setQueryParams({ patientUniqueId: e.target.value })}
      />
    </TablePageLayout.Header.Row>
  );
};

const IssueStateFilter: FC = memo(() => {
  const { t } = useTranslation();
  const { setQueryParams, queryParams } = useMonitoringPageContext();
  const issueState = queryParams.issueState && IssuesStateEnum[queryParams.issueState];

  const options = useMemo(() => {
    return statusOptions.map((option) => {
      const { icon: Icon } = option;
      return {
        key: option.key,
        icon: Icon && <Icon />,
        text: t(option.localizationKey),
        action: () => setQueryParams({ issueState: option.key === 'All' ? undefined : option.key }),
        className: Style.commonOption,
      } as DropDownMenuOption;
    });
  }, [setQueryParams, t]);

  const statusFilterState = useMemo(() => {
    const anySelected = issueState !== undefined;
    return {
      anySelected,
      text: !anySelected
        ? t('Issues.Filters.Status')
        : `${t(`Issues.Status.${queryParams.issueState}` as LocalizedResourceDictionaryKeys)}`,
    };
  }, [issueState, queryParams.issueState, t]);

  return (
    <DropDownMenu
      colorSchema={statusFilterState.anySelected ? 'primary' : 'black'}
      text={statusFilterState.text}
      options={options}
      directionalHint={DirectionalHint.bottomLeftEdge}
      Icon={statusOptions.find((o) => IssuesStateEnum[o.key] === queryParams.issueState)?.icon}
      iconClassName={Style.statusFilterIcon}
      hasChevron={true}
    />
  );
});

const SubjectFilter: FC = memo(() => {
  const { t } = useTranslation();
  const { setQueryParams, queryParams } = useMonitoringPageContext();

  const filterState = useMemo(() => {
    const anySelected = queryParams.subjects && queryParams.subjects?.length > 0;
    return {
      anySelected,
      text: anySelected
        ? `${t(`Issues.Subject.${_.first(queryParams.subjects)}` as LocalizedResourceDictionaryKeys)}`
        : t('Issues.Filters.Subject'),
    };
  }, [queryParams.subjects, t]);

  const options = useMemo(() => {
    const paramsHasKey = (key: string) => queryParams.subjects?.includes(key);

    const action = (key: string) => {
      if (paramsHasKey(key)) {
        setQueryParams({ subjects: [...queryParams.subjects!.filter((x) => x !== key)] });
      } else {
        setQueryParams({ subjects: [...(queryParams.subjects ?? []), key] });
      }
    };

    const resultOptions = issueSubjects.map((subject) => {
      return {
        key: subject,
        icon: paramsHasKey(subject) ? <CheckboxChecked /> : <CheckboxUnchecked />,
        text: t(`Issues.Subject.${subject}`),
        action: () => action(subject),
        className: clsx(Style.commonOption, Style.fillNone),
      } as DropDownMenuOption;
    });

    resultOptions.push({
      key: 'reset',
      icon: <Reset />,
      text: t('Issues.Topic.Reset'),
      action: () => setQueryParams({ subjects: undefined }),
      className: clsx(Style.resetOption, Style.resetTopicOption),
    });

    return resultOptions;
  }, [setQueryParams, queryParams.subjects, t]);

  return (
    <DropDownMenu
      colorSchema={filterState.anySelected ? 'primary' : 'black'}
      text={filterState.text}
      counter={queryParams.subjects && queryParams.subjects?.length > 1 ? `(+${queryParams.subjects?.length - 1})` : ''}
      options={options}
      closeCalloutByClick={false}
      directionalHint={DirectionalHint.bottomLeftEdge}
      hasChevron={true}
    />
  );
});

const GroupsFilter: FC = memo(() => {
  const { t } = useTranslation();
  const { setQueryParams, queryParams } = useMonitoringPageContext();
  const study = useStudy();

  const filterState = useMemo(() => {
    const anySelected = queryParams.groupIds && queryParams.groupIds?.length > 0;
    return {
      anySelected,
      text: anySelected
        ? study?.groups.find((g) => g.id === _.first(queryParams.groupIds))?.title
        : t('Issues.Filters.Group'),
    };
  }, [queryParams.groupIds, study?.groups, t]);

  const groupOptions = useMemo(() => {
    const paramsHasKey = (key: number) => queryParams.groupIds?.includes(key);

    const action = (id: number) => {
      if (paramsHasKey(id)) {
        setQueryParams({ groupIds: [...queryParams.groupIds!.filter((x) => x !== id)] });
      } else {
        setQueryParams({ groupIds: [...(queryParams.groupIds ?? []), id] });
      }
    };

    const groupList = study?.groups.map((g) => {
      return {
        key: g.id,
        icon: paramsHasKey(g.id) ? <CheckboxChecked /> : <CheckboxUnchecked />,
        text: g.title,
        action: () => action(g.id),
        className: clsx(Style.commonOption, Style.fillNone),
      } as DropDownMenuOption;
    });

    groupList?.push({
      key: 'reset',
      icon: <Reset />,
      text: t('Common_Reset'),
      action: () => setQueryParams({ groupIds: undefined }),
      className: clsx(Style.resetOption, Style.resetTopicOption),
    });

    return groupList;
  }, [study?.groups, t, queryParams.groupIds, setQueryParams]);

  return (
    <DropDownMenu
      colorSchema={filterState.anySelected ? 'primary' : 'black'}
      text={filterState.text}
      counter={queryParams.groupIds && queryParams.groupIds?.length > 1 ? `(+${queryParams.groupIds?.length - 1})` : ''}
      options={groupOptions || []}
      closeCalloutByClick={false}
      directionalHint={DirectionalHint.bottomLeftEdge}
      hasChevron={true}
      className={Style.topicDropdown}
    />
  );
});
