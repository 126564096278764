import { Dispatch } from 'react';
import { createContext } from 'use-context-selector';

export type FormFillingNavigationContextType = {
  navBarIsOpen?: boolean;
  setNavBarIsOpen?: Dispatch<React.SetStateAction<boolean>>;
};

const defaultValues: Partial<FormFillingNavigationContextType> = {};

export const FormFillNavigationContext = createContext(defaultValues);
