import { UserComponent } from '@craftjs/core';
import {
  ApplicableRules,
  ValidationDefaultSettings,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { createId } from 'src/helpers/typeUtils';
import { SliderColorSchemeEnum } from 'uikit/inputs/slider/appSliderInput';
import { defaultToolSettings } from './base/baseControlSettings';
import { IFormFieldWithOptionsProps } from './base/FormFieldWithOptionsProps';

export type ISliderInputProps = IFormFieldWithOptionsProps & {
  isSkipped?: boolean;
  hasSkipCheckBox?: boolean;
  minValue: number;
  maxValue: number;
  captionStartText?: string;
  captionCenterText?: string;
  captionEndText?: string;
  colorScheme: SliderColorSchemeEnum;
  step?: number;
  hideMarks?: boolean;
  marksStep?: number;
  startPoint?: number;
  showNumberInput?: boolean;
};

export type ISliderInput = UserComponent<ISliderInputProps>;

export function sliderInputDefaultPropsFactory(): ISliderInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    validation: { ...ValidationDefaultSettings },
    minValue: 0,
    maxValue: 10,
    colorScheme: SliderColorSchemeEnum.SingleColor,
    options: [],
    step: 1,
    marksStep: 1,
  };
}

export const sliderInputApplicableValidationRules: ApplicableRules = {
  isRequired: true,
};
