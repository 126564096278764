import React, { FC, ReactElement, useMemo } from 'react';
import { EditReasonEnum } from 'src/services/api/api-client';
import Style from '../useFormOverview.module.scss';
import { editReasonsSelectOptions } from 'src/components/formEditor/modals/reasonModal/controlOptions';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import { DateFormats, localFormat } from 'src/helpers/date-helpers';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';

type EditReasonIconType = {
  editReason?: EditReasonEnum | null;
  updateReasonText?: string | null;
};

type EditReasonFormHeader = EditReasonIconType & {
  createdAt: Date;
  updatedAt?: Date | null;
  editorName?: string;
  testId?: string;
};

export const EditReasonFormHeader: FC<EditReasonFormHeader> = (props) => {
  const { createdAt, updatedAt, editorName, testId } = props;
  const { t } = useScopedTranslation('Forms.Overview');

  const overviewHeader: ReactElement = useMemo(() => {
    const isUpdated = updatedAt?.getTime() !== createdAt.getTime();

    if (!updatedAt) {
      return <></>;
    }

    return (
      <div className={Style.modalHeader}>
        <span
          className={clsx(Style.modalHeaderEditDate, TypographyStyles.plainText14)}
          data-test-id={testId ?? 'form-filling-details'}
        >
          {!!editorName
            ? t(isUpdated ? 'Update' : 'Filled', {
                date: localFormat(updatedAt, DateFormats.longDate),
                name: editorName,
              })
            : t('Updated', { date: localFormat(updatedAt, DateFormats.longDate) })}
        </span>
        <EditReasonIcon editReason={props.editReason} updateReasonText={props.updateReasonText} />
      </div>
    );
  }, [updatedAt, createdAt, testId, editorName, t, props]);

  return <>{overviewHeader}</>;
};

export const EditReasonIcon: FC<EditReasonIconType> = ({ editReason, updateReasonText }) => {
  const { t } = useScopedTranslation('Forms.Overview');
  const commonLocalizer = useCommonLocalization();

  const editTooltipContent = useMemo(() => {
    if (!editReason) {
      return <></>;
    }

    return (
      <div className={Style.reasonTooltipContainer}>
        <div className={Style.reasonTooltipBlock}>
          <span className={Style.reasonTooltipHeader}>{t('ReasonTooltipHeader')}</span>
          <p className={Style.reasonTooltipText}>{commonLocalizer(`Forms.EditReasonModal.${editReason}`).toString()}</p>
        </div>

        {updateReasonText && (
          <div className={Style.reasonTooltipBlock}>
            <span className={Style.reasonTooltipHeader}>{t('ReasonUpdateTooltipHeader')}</span>
            <p className={Style.reasonTooltipText}>{updateReasonText}</p>
          </div>
        )}
      </div>
    );
  }, [editReason, updateReasonText, t, commonLocalizer]);

  const editReasonIcon = editReasonsSelectOptions().find((option) => option.key === editReason)?.icon;

  if (!editReason) return null;

  return (
    <Tooltip tooltipContent={editTooltipContent} text={''} styles={Style.modalHeaderTooltipIcon}>
      <div className={Style.modalHeaderTooltipIcon}>{editReasonIcon}</div>
    </Tooltip>
  );
};
