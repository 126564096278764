import { IAdminStudyDto } from '../../../services/api/api-client';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../application/hooks/useModal';
import { EditStudyInfoModalContent } from './EditStudyInfoModalContent';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { GroupsSection } from './sections/GroupsSection';
import { StudyConfigurationSection } from './sections/StudyConfigurationSection';
import { StudySettingsSection } from './sections/StudySettingsSection';
import { StudyDetailsSection } from './sections/StudyDetailsSection';
import ExpandedRowSections from 'uikit/table/ExpandedRowSections';

export const StudyDetailsCard: FC<{ study: IAdminStudyDto }> = ({ study }) => {
  const { t } = useTranslation('dev');
  const studyModalTab = useRef(1);
  const detailsModalManager = useModal();

  return (
    <ExpandedRowSections>
      <ExpandedRowSections.Column>
        <StudyDetailsSection
          study={study}
          onEdit={() => {
            studyModalTab.current = 1;
            detailsModalManager.openModal();
          }}
        />
        <StudyConfigurationSection
          study={study}
          onEdit={() => {
            studyModalTab.current = 2;
            detailsModalManager.openModal();
          }}
        />
        <StudySettingsSection study={study} />
      </ExpandedRowSections.Column>
      <ExpandedRowSections.Column>
        <GroupsSection studyId={study.id} groups={study.groups} />
      </ExpandedRowSections.Column>
      <AppModalContainer
        visible={detailsModalManager.visible}
        onHide={detailsModalManager.closeModal}
        title={t('Admin.StudyList.Details.Modal.Header')}
      >
        <EditStudyInfoModalContent
          study={study}
          onClose={detailsModalManager.closeModal}
          initialTab={studyModalTab.current}
        />
      </AppModalContainer>
    </ExpandedRowSections>
  );
};
