import React, { FC } from 'react';
import { DialogModal } from '../../../components/dialogModal/dialogModal.component';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';

export const DeletingConfirmationDialog: FC<{
  onCancel: () => void;
  onApply: () => void;
  isLoading: boolean;
  isVisible: boolean;
}> = (props) => {
  const { t } = useScopedTranslation('StudySettingsPage.AnalyzesPreset.DeletingConfirmationDialog', 'dev');
  const commonLocalizer = useCommonLocalization();

  return (
    <DialogModal
      visible={props.isVisible}
      onHide={props.isLoading ? () => {} : props.onCancel}
      footer={{
        leftButton: {
          text: commonLocalizer('Common_Cancel'),
          type: 'reset',
          onClick: props.onCancel,
          disabled: props.isLoading,
        },
        rightButton: {
          colorSchema: 'destroy',
          text: commonLocalizer('Common_Delete'),
          onClick: props.onApply,
          disabled: props.isLoading,
          isLoading: props.isLoading,
          hasLoaded: props.isLoading,
        },
      }}
      title={t('Title')}
    >
      {t('MainText')}
    </DialogModal>
  );
};
