import { useLayer } from '@craftjs/layers';
import React, { FC, PropsWithChildren } from 'react';
import { DefaultLayerHeader } from './customLayerHeader';
import Style from './customLayer.module.css';
import clsx from 'clsx';

export const CustomLayer: FC<PropsWithChildren> = ({ children }) => {
  const {
    hovered,
    connectors: { layer },
  } = useLayer((l) => ({
    hovered: l.event.hovered,
  }));

  return (
    <div ref={(ref) => ref && layer(ref)}>
      <DefaultLayerHeader />
      {children ? <div className={clsx(Style.layer, { [Style.hovered]: hovered })}>{children}</div> : null}
    </div>
  );
};
