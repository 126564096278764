import { generatePath } from 'react-router';

export enum AppRoutes {
  Root = '/',
  AccessDenied = '/forbidden',
  NoPermission = '/no-permission',
  Impressum = '/impressum',

  // Common routes
  Studies = '/studies',
  Dashboard = '/studies/:id',
  StudyInfoPage = '/studies/:id/info',
  FAQPage = '/studies/:id/faq',

  Monitoring = '/studies/:id/monitoring',

  Participants = '/studies/:id/participants',

  // Study settings routes
  StudySettings = '/studies/:id/settings',
  FormBuilder = 'form-builder',
  SurveyBuilder = 'survey-builder',
  Analyzes = 'analyzes',
  PatientPipelines = 'patient-pipelines',

  // Admin routes
  Admin = '/admin',
  AdminStudies = 'studies',
  Users = 'users',
  Roles = 'roles',
}

export const navigateToRoute = {
  dashboard: (studyId: number) => generatePath(AppRoutes.Dashboard, { id: studyId.toString() }),
  studySettings: (studyId: number) => generatePath(AppRoutes.StudySettings, { id: studyId.toString() }),
  formBuilder: (studyId: number) => generatePath(AppRoutes.FormBuilder, { id: studyId.toString() }),
  surveyBuilder: (studyId: number) => generatePath(AppRoutes.SurveyBuilder, { id: studyId.toString() }),
  analyzes: (studyId: number) => generatePath(AppRoutes.Analyzes, { id: studyId.toString() }),
  patientPipelines: (studyId: number) => generatePath(AppRoutes.PatientPipelines, { id: studyId.toString() }),
  studyInfoPage: (studyId: number) => generatePath(AppRoutes.StudyInfoPage, { id: studyId.toString() }),
  faqPage: (studyId: number) => generatePath(AppRoutes.FAQPage, { id: studyId.toString() }),
  monitoring: (studyId: number) => generatePath(AppRoutes.Monitoring, { id: studyId.toString() }),
  participants: (studyId: number) => generatePath(AppRoutes.Participants, { id: studyId.toString() }),
};
