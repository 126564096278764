import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFactory } from '../../services/api';
import Style from './impressum.module.css';

export const Impressum = () => {
  const { t } = useTranslation();
  const { data: version } = QueryFactory.VersionQuery.useVersionQuery({ suspense: false });

  return (
    <div className={Style.container} data-test-id={'impressum-container'}>
      <div>
        <h1>{t('ImpressumPage.Impressum.Header')}</h1>
        <div className={Style.impressumContent}>{t('ImpressumPage.Impressum.Content')}</div>
        <div className={Style.impressumFooter}>
          <div>
            <h3>{t('ImpressumPage.Impressum.Footer.ChiefsHeader')}</h3>
            <p>{t('ImpressumPage.Impressum.Footer.ChiefsText')}</p>
          </div>
          <div>
            <h3>{t('ImpressumPage.Impressum.Footer.ContactHeader')}</h3>
            <p>
              {t('ImpressumPage.Impressum.Footer.ContactTel')}
              <br />
              {t('ImpressumPage.Impressum.Footer.ContactFax')}
              <br />
              {t('ImpressumPage.Impressum.Footer.ContactMailPrefix')}
              <br />
              <a href={`mailto:${t('ImpressumPage.Impressum.Footer.ContactMail')}`}>
                {t('ImpressumPage.Impressum.Footer.ContactMail')}
              </a>
            </p>
          </div>
          <div>
            <h3>{t('ImpressumPage.Impressum.Footer.CourtHeader')}</h3>
            <p>{t('ImpressumPage.Impressum.Footer.CourtText')}</p>
          </div>
          <div>
            <h3>{t('ImpressumPage.Impressum.Footer.VatidHeader')}</h3>
            <p>{t('ImpressumPage.Impressum.Footer.VatidText')}</p>
          </div>
        </div>
      </div>
      <div>
        <h1>{t('ImpressumPage.Disclaimer.Header')}</h1>
        <div className={Style.disclaimerContent}>
          <div>
            <h2>{t('ImpressumPage.Disclaimer.LiabilityHeader')}</h2>
            <p>{t('ImpressumPage.Disclaimer.LiabilityText')}</p>
          </div>
          <div>
            <h2>{t('ImpressumPage.Disclaimer.CopyrightHeader')}</h2>
            <p>{t('ImpressumPage.Disclaimer.CopyrightText')}</p>
          </div>
          <div>
            <h2>{t('ImpressumPage.Disclaimer.PatentsHeader')}</h2>
            <p>{t('ImpressumPage.Disclaimer.PatentsText')}</p>
          </div>
        </div>
      </div>
      <p className={Style.version}>{t('Common_ServerVersion', { version })}</p>
    </div>
  );
};
