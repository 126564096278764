import React from 'react';
import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import { AppRadioGroupField, AppRadioGroupFieldProps } from 'uikit/fields';

type HookFormType<TOption, TValue extends string | number, TFieldValues extends FieldValues = FieldValues> = Omit<
  AppRadioGroupFieldProps<TOption, TValue>,
  'onChange'
> & {
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  rules?: Exclude<RegisterOptions, 'valueAsDate' | 'setValueAs'>;
  onFocus?: () => void;
};

export function HookFormRadioGroup<
  TOption,
  TValue extends string | number = string | number,
  TFieldValues extends FieldValues = FieldValues,
>(props: HookFormType<TOption, TValue, TFieldValues>) {
  const { control, name, rules, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref }, fieldState }) => {
        return (
          <div tabIndex={0} ref={ref}>
            <AppRadioGroupField<TOption, TValue>
              {...rest}
              value={value}
              errorProps={{ errors: fieldState.error?.message }}
              onChange={(val) => {
                onChange(val[props.valueField]);
              }}
            />
          </div>
        );
      }}
    />
  );
}
