/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useMemo } from 'react';
import Style from './diffValue.module.scss';
import { IFormControlsResolver } from 'src/features/forms/base/controls/IFormControlsResolver';
import { IDateInput } from 'src/features/forms/base/controls/inputs/DateInput';
import { DateFormats, formatDateRange, localFormat, millsToTimeString, toDateOrNull } from 'src/helpers/date-helpers';
import { useGetDiffValues } from './useGetDiffValues';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { ITextInput } from 'src/features/forms/base/controls/inputs/TextInput';
import { ITimeInput } from 'src/features/forms/base/controls/inputs/TimeInput';
import { ICheckBoxInput } from 'src/features/forms/base/controls/inputs/CheckBoxInput';
import { LocalizedResourceDictionaryKeys } from 'src/application/localisation/i18next';
import { ICalculatedInput } from 'src/features/forms/base/controls/specials/CalculatedBlock';
import { IRadioInput } from 'src/features/forms/base/controls/inputs/RadioInput';
import { IPatientGroupInput } from 'src/features/forms/base/controls/specials/PatientGroupInput';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { ICheckBoxGroupInput } from 'src/features/forms/base/controls/inputs/CheckBoxGroupInput';
import _ from 'lodash';
import { IFilesInput } from 'src/features/forms/base/controls/inputs/Files';
import { ITagInput } from 'src/features/forms/base/controls/inputs/TagInputProps';
import clsx from 'clsx';
import { AppAttachmentValue } from 'uikit/fields/attachments/attachmentsField/appAttachmentsField';
import { ISelectInput } from 'src/features/forms/base/controls/inputs/SelectInput';
import { IDateRangeInput } from 'src/features/forms/base/controls/inputs/DateRangeInput';
import { AppInputLabel } from 'uikit/wrappers';
import { IRecordResultList, RecordResultListItemData } from 'src/features/forms/base/controls/inputs/RecordResultList';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from 'src/components/formEditor/uiEditor/provider/formFill.context';
import { useGetPatientFormList } from 'src/components/formEditor/controls/ConstantBlocks/RecordResultList/useGetFormList';
import { isNullOrEmpty } from 'src/helpers/string-helper';

const SingleValueComponent: FC<{
  oldValue: string | number | undefined;
  newValue: string | number | undefined;
}> = ({ oldValue, newValue }) => {
  const { t } = useScopedTranslation('Forms.EditFieldsReasonModal.DiffValue');

  if (!oldValue && !newValue) return <></>;

  return (
    <div className={Style.singleValueRow}>
      {oldValue && <div className={Style.crossed}>{oldValue}</div>}
      {isNullOrEmpty(newValue?.toString()) ? <span className={Style.greyItalic}>{t('AnswerRemoved')}</span> : newValue}
      {!oldValue && <div className={Style.greyItalic}>{t('AnswerAdded')}</div>}
    </div>
  );
};

const MultipleValueComponent: FC<{
  addedValues: string[] | undefined;
  removedValues: string[] | undefined;
  oldSkipReason: string | undefined;
  newSkipReason: string | undefined;
}> = ({ addedValues, removedValues, oldSkipReason, newSkipReason }) => {
  const { t } = useScopedTranslation('Forms.EditFieldsReasonModal.DiffValue');
  return (
    <div className={clsx(Style.multipleValueRow, { [Style.flexRow]: !!oldSkipReason && !!newSkipReason })}>
      {removedValues?.length ? (
        <div className={Style.diffValueContainer}>
          {removedValues.map((value, i, arr) => (
            <span key={value}>
              <span className={Style.crossed}>{value}</span>
              {i !== arr.length - 1 && <span>{', '}</span>}
            </span>
          ))}
          <span className={Style.greyItalic}>{t('AnswersRemoved')}</span>
        </div>
      ) : (
        oldSkipReason && <span className={Style.crossed}>{oldSkipReason}</span>
      )}
      {addedValues?.length ? (
        <div className={Style.diffValueContainer}>
          <span>{addedValues.join(', ')}</span>
          <span className={Style.greyItalic}>{t('AnswersAdded')}</span>
        </div>
      ) : (
        newSkipReason && <span>{newSkipReason}</span>
      )}
    </div>
  );
};

const DateField: IDateInput = (props) => {
  const { oldValue, changes, oldSkipReason, newSkipReason } = useGetDiffValues(props.dataKey);
  return (
    <SingleValueComponent
      oldValue={oldValue ? localFormat(toDateOrNull(oldValue as string)!, DateFormats.longDate) : oldSkipReason}
      newValue={changes ? localFormat(toDateOrNull(changes as string)!, DateFormats.longDate) : newSkipReason}
    />
  );
};

const DateRangeField: IDateRangeInput = (props) => {
  const {
    oldValue: oldValueFrom,
    newValue: newValueFrom,
    oldSkipReason,
    newSkipReason,
  } = useGetDiffValues(`${props.dataKey}_from`);
  const { oldValue: oldValueUntil, newValue: newValueUntil } = useGetDiffValues(`${props.dataKey}_until`);
  const renderDateRangeStr = useCallback((fromDate: string | undefined, untilDate: string | undefined) => {
    if (!fromDate && !untilDate) return undefined;
    return formatDateRange(toDateOrNull(fromDate), toDateOrNull(untilDate));
  }, []);

  return (
    <SingleValueComponent
      oldValue={renderDateRangeStr(oldValueFrom, oldValueUntil) ?? oldSkipReason}
      newValue={renderDateRangeStr(newValueFrom, newValueUntil) ?? newSkipReason}
    />
  );
};

const TimeField: ITimeInput = (props) => {
  const { oldValue, changes: newValue, oldSkipReason, newSkipReason } = useGetDiffValues(props.dataKey);
  return (
    <SingleValueComponent
      oldValue={oldValue ? millsToTimeString(+oldValue) : oldSkipReason}
      newValue={newValue ? millsToTimeString(+newValue) : newSkipReason}
    />
  );
};

const TextField: ITextInput = (props) => {
  const { oldValue, changes, oldSkipReason, newSkipReason } = useGetDiffValues(props.dataKey);
  return <SingleValueComponent oldValue={oldValue ?? oldSkipReason} newValue={changes ?? newSkipReason} />;
};

const CalculatedField: ICalculatedInput = (props) => {
  const { oldValue, changes, oldSkipReason, newSkipReason } = useGetDiffValues(props.dataKey);
  return <SingleValueComponent oldValue={oldValue ?? oldSkipReason} newValue={changes ?? newSkipReason} />;
};

const CheckBoxField: ICheckBoxInput = (props) => {
  const { t } = useScopedTranslation('Forms.Controls');
  const { oldValue, changes, oldSkipReason, newSkipReason } = useGetDiffValues(props.dataKey);

  return (
    <SingleValueComponent
      oldValue={oldValue ? t(`checkBox_${oldValue}` as LocalizedResourceDictionaryKeys) : oldSkipReason}
      newValue={changes ? t(`checkBox_${changes}` as LocalizedResourceDictionaryKeys) : newSkipReason}
    />
  );
};

const SingleInputField: IRadioInput = (props) => {
  const { oldValue, changes, oldSkipReason, newSkipReason } = useGetDiffValues(props.dataKey);

  const getOptionValue = useCallback(
    (value: string | undefined) => props.options.find((x) => x.value === value)?.displayValue,
    [props.options],
  );

  if ((oldValue && typeof oldValue !== 'string') || (changes && typeof changes !== 'string'))
    throw new Error(`Field with dataKey: ${props.dataKey} is of wrong type. Expected value of type string`);

  return (
    <SingleValueComponent
      oldValue={getOptionValue(oldValue) ?? oldSkipReason}
      newValue={getOptionValue(changes) ?? newSkipReason}
    />
  );
};

const PatientGroupField: IPatientGroupInput = (props) => {
  const { groups } = useStudy() ?? {};
  const { oldValue, changes, oldSkipReason, newSkipReason } = useGetDiffValues(props.dataKey);

  const getOptionValue = useCallback(
    (value: string | undefined) => (value ? groups?.find((x) => x.id === +value)?.title : undefined),
    [groups],
  );

  if ((oldValue && typeof oldValue !== 'string') || (changes && typeof changes !== 'string'))
    throw new Error(`Field with dataKey: ${props.dataKey} is of wrong type. Expected value of type string`);

  return (
    <SingleValueComponent
      oldValue={getOptionValue(oldValue) ?? oldSkipReason}
      newValue={getOptionValue(changes) ?? newSkipReason}
    />
  );
};

const MultipleInputField: ICheckBoxGroupInput = (props) => {
  const { oldValue, changes, newValue, oldSkipReason, newSkipReason } = useGetDiffValues(props.dataKey);

  if ((oldValue && !_.isArray(oldValue)) || (changes && !_.isArray(changes)))
    throw new Error(`Field with dataKey: ${props.dataKey} is of wrong type. Expected value of type string[]`);

  const getOptionValue = useCallback(
    (value: string | undefined) => props.options.find((x) => x.value === value)?.displayValue,
    [props.options],
  );

  const addedValues = useMemo(
    () => (changes?.length ? changes.map(getOptionValue) : undefined),
    [getOptionValue, changes],
  );
  const removedValues = useMemo(() => {
    return oldValue?.filter((x: string) => !newValue?.includes(x)).map(getOptionValue);
  }, [getOptionValue, newValue, oldValue]);

  return (
    <MultipleValueComponent
      addedValues={addedValues}
      removedValues={removedValues}
      oldSkipReason={oldSkipReason}
      newSkipReason={newSkipReason}
    />
  );
};

const TagField: ITagInput = (props) => {
  const { oldValue, changes, newValue, oldSkipReason, newSkipReason } = useGetDiffValues(props.dataKey);

  const addedValues = useMemo(() => (changes?.length ? changes : undefined), [changes]);
  const removedValues = useMemo(() => {
    return oldValue?.filter((x: string) => !newValue?.includes(x));
  }, [newValue, oldValue]);

  return (
    <MultipleValueComponent
      addedValues={addedValues}
      removedValues={removedValues}
      oldSkipReason={oldSkipReason}
      newSkipReason={newSkipReason}
    />
  );
};

const FilesField: IFilesInput = (props) => {
  const { oldValue, changes, newValue, oldSkipReason, newSkipReason } = useGetDiffValues(props.dataKey);

  const getFileName = useCallback((value: AppAttachmentValue | undefined) => value?.fileName, []);

  const addedValues = useMemo(() => (changes?.length ? changes.map(getFileName) : undefined), [changes]);
  const removedValues = useMemo(() => {
    return oldValue?.filter((x: string) => !newValue?.includes(x)).map(getFileName);
  }, []);

  return (
    <MultipleValueComponent
      addedValues={addedValues}
      removedValues={removedValues}
      oldSkipReason={oldSkipReason}
      newSkipReason={newSkipReason}
    />
  );
};

const SelectField: ISelectInput = (props) => {
  return props.isMulti ? <MultipleInputField {...props} /> : <SingleInputField {...props} />;
};

const RecordResultList: IRecordResultList = (props) => {
  const { t } = useScopedTranslation('Forms.EditFieldsReasonModal.DiffValue');
  const { oldValue, changes } = useGetDiffValues(props.dataKey);
  const study = useStudy();
  const patient = useContextSelector(FormFillContext, (v) => v.patient);
  const { mandatoryForms, multipleForms } = useGetPatientFormList(patient?.id, study?.patientFinishingFormId);
  const forms = [...mandatoryForms, ...multipleForms];

  const parsedOldValue: RecordResultListItemData[] = useMemo(() => oldValue.map((str: string) => JSON.parse(str)), []);
  const parsedChanges: RecordResultListItemData[] = useMemo(() => changes.map((str: string) => JSON.parse(str)), []);

  const changesComponent = useMemo(
    () =>
      (parsedChanges as RecordResultListItemData[]).map((x, i) => {
        const formType = forms.find((f) => f.formResults?.[0]?.resultId === x.resultId)?.formType;
        const oldComment = parsedOldValue.find((v) => v.resultId === x.resultId)?.comment;
        const oldVersion = parsedOldValue.find((v) => v.resultId === x.resultId)?.versionNumber;

        return (
          <>
            {oldVersion !== x.versionNumber && (
              <AppInputLabel key={i} isBold text={formType} className={Style.label}>
                {oldVersion !== x.versionNumber && (
                  <SingleValueComponent
                    oldValue={t('FormVersion', { version: oldVersion })}
                    newValue={t('FormVersion', { version: x.versionNumber })}
                  />
                )}
              </AppInputLabel>
            )}
            {oldComment !== x.comment && (
              <AppInputLabel
                key={i}
                isBold
                text={t('RecordResultLabel', { formType: formType ?? '' })}
                className={Style.label}
              >
                <SingleValueComponent oldValue={oldComment} newValue={x.comment} />
              </AppInputLabel>
            )}
          </>
        );
      }),
    [],
  );

  return <div className={Style.resultList}>{changesComponent}</div>;
};

export const StubContent = () => <></>;
export const diffValueControlsResolver: IFormControlsResolver = {
  EmptyBlock: StubContent,
  Container: StubContent,
  FieldGroupContainer: StubContent,
  Text: StubContent,
  AttentionText: StubContent,
  Title: StubContent,
  StepContainer: StubContent,
  TabsContainer: StubContent,
  ImageBlock: StubContent,

  DateInput: DateField,
  DateRangeInput: DateRangeField,
  TextInput: TextField,
  NumberInput: TextField,
  SliderInput: TextField,
  BmiInput: TextField,
  TimeInput: TimeField,
  CalculatedBlock: CalculatedField,
  CheckBoxInput: CheckBoxField,
  RadioInput: SingleInputField,
  GroupInput: PatientGroupField,
  SelectInput: SelectField,
  CheckBoxGroupInput: MultipleInputField,
  TagInput: TagField,
  Files: FilesField,
  RecordResultList: RecordResultList,
};
