﻿import React, { FC } from 'react';
import styles from './overview.module.scss';
import { TypographyStyles } from 'styles';
import clsx from 'clsx';

type OverviewTextProps = {
  bold?: boolean;
  text: string;
};

export const OverviewText: FC<OverviewTextProps> = (props) => {
  return (
    <div className={styles.overviewRow}>
      <div className={clsx(TypographyStyles.plainText14, styles.overviewText, { [styles.bold]: props.bold })}>
        {props.text}
      </div>
    </div>
  );
};
