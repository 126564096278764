import { DecodedValueMap, SetQuery } from 'use-query-params';
import { PatientFilterParamsType } from '../../components/patientTable/patientTable';
import { IGroupDto } from '../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import Style from './patientPipeline.module.scss';
import React, { useCallback, useMemo, useState } from 'react';
import { AppButton } from 'uikit/buttons';
import clsx from 'clsx';
import { TypographyStyles } from 'styles';
import { Chips } from 'uikit/chips/chips.component';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { ReactComponent as Filter } from '../../assets/img/common/filter_20.svg';
import { ReactComponent as Reset } from '../../assets/img/common/reset_24.svg';
import { patientQueryParamsConfig } from './PatientPipelineList';
import TablePageLayout from '../../components/tablePageLayout/TablePageLayout';

const dashboardFilters: (keyof PatientFilterParamsType)[] = ['patientGroup'];

const defaultFilterParamsObject: Pick<DecodedValueMap<typeof patientQueryParamsConfig>, 'patientGroup' | 'page'> = {
  patientGroup: undefined,
  page: 1,
};

export const PatientPipelineFilters = (props: {
  queryParams: DecodedValueMap<typeof patientQueryParamsConfig>;
  setQueryParams: SetQuery<typeof patientQueryParamsConfig>;
  studyId: number;
  groups: IGroupDto[];
}) => {
  const { t } = useTranslation();
  const { t: patientFiltersLocalizer } = useScopedTranslation('Dashboard.PatientFilters');

  const { setQueryParams, queryParams, groups } = props;

  const applyFilterCount = useMemo(() => {
    return Object.entries(queryParams).filter(
      ([key, v]) => dashboardFilters.includes(key as keyof PatientFilterParamsType) && v !== undefined && v !== null,
    ).length;
  }, [queryParams]);

  const [isHidden, setIsHidden] = useState<boolean>(!applyFilterCount);

  const resetFilter = useCallback(() => {
    setQueryParams(defaultFilterParamsObject);
  }, [setQueryParams]);

  return (
    <TablePageLayout.Header.Row>
      <div className={Style.filterGroupWrapper}>
        <div className={Style.filterButtonWrapper}>
          <AppButton
            testId={'filters-toggle'}
            variant={'icon-link'}
            colorSchema={'secondary'}
            Icon={Filter}
            onClick={() => setIsHidden((s) => !s)}
            text={isHidden ? patientFiltersLocalizer('ShowFilters') : patientFiltersLocalizer('HideFilters')}
          />

          {isHidden && applyFilterCount > 0 && (
            <div className={clsx(TypographyStyles.plainText14, Style.filterCount)}>
              {patientFiltersLocalizer('ApplyCount', { count: applyFilterCount })}
            </div>
          )}
        </div>

        {!isHidden && (
          <div className={Style.filterGroup} data-test-id={'patient-period-table-filters'}>
            {groups.map((x) => (
              <Chips
                key={x.id}
                text={x.title}
                checked={queryParams.patientGroup === x.id}
                onClick={() => {
                  setQueryParams({
                    patientGroup: queryParams.patientGroup === x.id ? undefined : x.id,
                    page: 1,
                  });
                }}
              />
            ))}

            {applyFilterCount > 0 && (
              <Tooltip text={t('Common_Reset')}>
                <AppButton
                  variant={'icon-link'}
                  colorSchema={'decline'}
                  Icon={Reset}
                  onClick={resetFilter}
                  disabled={!applyFilterCount}
                  data-test-id={'filters-reset'}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>

      <TablePageLayout.Header.Search
        value={queryParams.searchQuery || ''}
        placeholder={t('Dashboard.PatientTable.Search')}
        onChange={(e) => setQueryParams({ searchQuery: e.target.value, page: undefined })}
      />
    </TablePageLayout.Header.Row>
  );
};
