import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';
import { FetchLoginResponse } from './auth-data';
import { Base64 } from 'js-base64';
import { sessionAxiosInterceptor } from 'src/helpers/interceptors';

const clientId = 'web-client';
const clientKey = '';
const scopes = 'offline_access';
const backendUri = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

const instance = axios.create();
instance.interceptors.request.use(sessionAxiosInterceptor);

export const sendRefreshTokenRequest = (refreshToken: string): Promise<FetchLoginResponse> => {
  const requestBody = {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  };

  return fetchTokenEndpoint('/connect/token', requestBody);
};

interface AxiosAuthRefreshRequestConfig extends AxiosRequestConfig {
  skipAuthRefresh?: boolean;
}

export const fetchTokenEndpoint = async (urlPath: string, body: any): Promise<FetchLoginResponse> => {
  const bodyToSend = {
    ...body,
  };

  const response: AxiosResponse = await instance.post(`${backendUri}${urlPath}`, queryString.stringify(bodyToSend), {
    headers: {
      Authorization: `Basic ${Base64.btoa(`${clientId}:${clientKey}`)}`,
    },
    skipAuthRefresh: true, // taken from https://github.com/Flyrell/axios-auth-refresh/
  } as AxiosAuthRefreshRequestConfig);

  return {
    ...response.data,
  } as FetchLoginResponse;
};
