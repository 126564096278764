import React, { FC } from 'react';
import { useContextSelector } from 'use-context-selector';
import { IssuesStateEnum, SortOrder } from '../../../services/api/api-client';
import { IssuesContext } from 'src/components/issue/provider/issues.context';
import { useIssueTableColumns } from './issueTable.columns';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { AppTable } from 'uikit/table/AppTable';
import { Loading } from 'uikit/suspense/Loading';
import { ExpandedRowCard } from 'uikit/table/ExpandedRowCard';
import { useGetIssuesForStudyQuery } from '../../../services/api/api-client/IssueQuery';

export type IssueTableExpandedRowType = {
  patientUid: string | null;
  studyId: number;
  issueState: IssuesStateEnum | null | undefined;
  groupIds: number[] | null | undefined;
};

export const IssueTableExpandedRow: FC<IssueTableExpandedRowType> = ({ patientUid, studyId, issueState, groupIds }) => {
  const openIssueView = useContextSelector(IssuesContext, (x) => x.openIssueView)!;

  const issuesForStudy = useGetIssuesForStudyQuery(
    {
      studyId: studyId,
      patientUniqId: patientUid || undefined,
      state: issueState,
      subjectList: patientUid ? undefined : ['Study'],
      patientGroupIds: groupIds,
      sortBy: 'Id',
      sortOrder: SortOrder.Asc,
      limit: 9999,
    },
    { suspense: false },
  );

  const dataTable = useReactTable({
    data: issuesForStudy.data?.data || [],
    initialState: {
      sorting: [{ id: 'id', desc: false }],
    },
    enableSortingRemoval: false,
    getRowId: (issue) => `${issue.id}-issue`,
    columns: useIssueTableColumns({ openIssueView }),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <ExpandedRowCard testId={'issue'}>
      <Loading loading={issuesForStudy.isInitialLoading} renderChildren={dataTable.getRowModel().rows.length > 0}>
        <AppTable testId="issue-card" variant="no-borders" table={dataTable} placeholder={{ hide: true }} />
      </Loading>
    </ExpandedRowCard>
  );
};
