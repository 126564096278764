﻿import React, { FC, ReactNode } from 'react';
import { RPDF } from '../../react-pdf';
import { ReportColors } from '../../report-colors';

export const FormHeader: FC<{ title: string | undefined; subTitle?: string; formState: ReactNode }> = (props) => {
  const localStyles = RPDF.StyleSheet.create({
    container: {
      flexDirection: 'column',
      backgroundColor: ReportColors.ultraLightGrey,
      paddingHorizontal: 8,
      paddingTop: 4,
      paddingBottom: 4,
      gap: 4,
    },
    leftHeader: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: 4,
    },
  });

  return (
    <RPDF.View style={localStyles.container}>
      <RPDF.View style={localStyles.leftHeader}>
        {props.title && <RPDF.Text style={RPDF.typography.heading2}>{props.title}</RPDF.Text>}
        {props.subTitle && <RPDF.Text style={RPDF.typography.text14}>{props.subTitle}</RPDF.Text>}
      </RPDF.View>
      <RPDF.View style={[RPDF.typography.text10, { flexDirection: 'row', color: ReportColors.darkGrey }]}>
        {props.formState}
      </RPDF.View>
    </RPDF.View>
  );
};
