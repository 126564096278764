import React, { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { IPatientGroupInput } from '../../../../forms/base/controls/specials/PatientGroupInput';
import { RecordsReportContext } from '../../recordsReport.context';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const GroupInput: IPatientGroupInput = ({ label, text, dataKey }) => {
  const groups = useContextSelector(RecordsReportContext, (v) => v.studyGroups);
  const groupId = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const displayValue = useMemo(() => groups.find((x) => x.id === Number(groupId))?.title, [groups, groupId]);

  return <Field label={label ?? text ?? dataKey} value={displayValue} />;
};
