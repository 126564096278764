import React from 'react';
import Style from './navAppBar.module.css';
import { useTranslation } from 'react-i18next';
import { NavButton } from './navButton.component';
import { navigateToRoute } from '../../navigation/routes';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';
import { Permissions } from 'src/services/api/api-client';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const StudySettingsNavBar = () => {
  const { t } = useTranslation();
  const study = useStudy();

  if (!study) return <></>;

  return (
    <div className={Style.secondaryNavContainer}>
      <div className={Style.tabsContainer}>
        <NavButton
          title={t('NavMenu.Tabs.RecordsEditor')}
          route={navigateToRoute.formBuilder(study.id)}
          variant={'secondary'}
        />
        <NavButton
          title={t('NavMenu.Tabs.SurveyEditor')}
          route={navigateToRoute.surveyBuilder(study.id)}
          variant={'secondary'}
        />
        {study?.hasDbImport && (
          <NavButton
            title={t('NavMenu.Tabs.Analyzes')}
            route={navigateToRoute.analyzes(study.id)}
            variant={'secondary'}
          />
        )}
        <PermissionsCheck permissions={Permissions.PatientView}>
          <NavButton
            title={t('NavMenu.Tabs.PatientPipelines')}
            route={navigateToRoute.patientPipelines(study.id)}
            variant={'secondary'}
          />
        </PermissionsCheck>
      </div>
    </div>
  );
};
