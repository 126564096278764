import {
  BooleanParam,
  createEnumParam,
  NumberParam,
  QueryParamConfig,
  StringParam,
  withDefault,
} from 'react-router-url-params';
import { SortOrder } from '../services/api/api-client';

export function createSortingParams<
  TSortingKeys extends readonly string[] = readonly string[],
  DefaultSortBy extends TSortingKeys[number] | undefined = undefined,
  DefaultDesc extends boolean | undefined = undefined,
>(options: {
  defaultSortBy?: DefaultSortBy;
  sortingKeys?: TSortingKeys;
  /** If you use it with `tanstack/table`
   *  then you need to set
   *  {@link https://tanstack.com/table/v8/docs/api/features/sorting#enablesortingremoval enableSortingRemoval}
   *  to `false`
   * */
  defaultDesc?: DefaultDesc;
}): {
  sortBy: QueryParamConfig<
    string | null | undefined,
    DefaultSortBy extends undefined ? TSortingKeys[number] | null | undefined : TSortingKeys[number]
  >;
  desc: QueryParamConfig<
    boolean | null | undefined,
    DefaultDesc extends undefined ? boolean | null | undefined : boolean
  >;
} {
  const { defaultDesc, defaultSortBy, sortingKeys } = options;

  let sortByParam: any = sortingKeys ? createEnumParam(sortingKeys as any as string[]) : StringParam;

  if (defaultSortBy) sortByParam = withDefault(sortByParam, defaultSortBy);

  return {
    sortBy: sortByParam,
    desc: typeof defaultDesc === 'boolean' ? withDefault(BooleanParam, defaultDesc) : BooleanParam,
  } as any;
}

export function createPagingParams<
  TSortingKeys extends readonly string[] = readonly string[],
  DefaultSortBy extends TSortingKeys[number] | undefined = undefined,
  DefaultDesc extends boolean | undefined = undefined,
>(
  options: {
    defaultPerPage: number;
  } & Parameters<typeof createSortingParams<TSortingKeys, DefaultSortBy, DefaultDesc>>[0],
): ReturnType<typeof createSortingParams<TSortingKeys, DefaultSortBy, DefaultDesc>> & {
  page: QueryParamConfig<number | null | undefined, number>;
  perPage: QueryParamConfig<number | null | undefined, number>;
} {
  const { defaultPerPage } = options;

  return {
    ...createSortingParams(options),
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, defaultPerPage),
  } as any;
}

export function pagingSortingToBackendRequest<TSortBy extends string = string>(query: {
  sortBy?: TSortBy | null;
  desc?: boolean | null;
  page?: number;
  perPage?: number;
}): {
  offset?: number | null;
  limit?: number | null;
  sortBy?: TSortBy | null;
  sortOrder?: SortOrder | null;
} {
  return {
    offset: query.page && query.perPage && (query.page - 1) * query.perPage,
    limit: query.perPage,
    sortBy: query.sortBy ?? undefined,
    sortOrder: query.desc ? SortOrder.Desc : SortOrder.Asc,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { useQueryParams } from 'react-router-url-params';

/**
 * Wraps {@link setQueryParams} and reset **`page`** parameter
 * when the wrapped method is called.
 *
 * This can be used to avoid the situation when
 * a user paginates to a far page and changes filters so
 * that there are no longer results that can be displayed on that page.
 *
 * @param setQueryParams setter that is returned from {@link useQueryParams} hook or {@link useState} hook
 * @example
 * <Filters queryParams={queryParams} setQueryParams={pageResetWrapper(setQueryParams)}/>
 */
export function pageResetWrapper<
  TQueryParams extends {
    page: number | null | undefined;
  },
>(setQueryParams: (callback: (old: TQueryParams) => TQueryParams) => void) {
  return (callback: (old: TQueryParams) => TQueryParams) => {
    setQueryParams((old) => {
      const newParams = callback(old);
      newParams.page = 1;
      return newParams;
    });
  };
}
