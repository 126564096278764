﻿import { useModal } from '../../application/hooks/useModal';
import { IParticipantDto } from '../../services/api/api-client';
import React, { FC, useCallback, useRef } from 'react';
import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import { Deferred } from '../../helpers/Deferred';
import { Trans, useTranslation } from 'react-i18next';
import styles from './useParticipantDeleting.module.scss';
import { ValidationFormRules } from '../../helpers/validation-helpers';
import { deleteParticipant } from '../../services/api/api-client/ParticipantsClient';
import { handleSubmitFormError } from '../../application/error-handling/useErrorHandler';
import { getParticipantsQueryKey } from '../../services/api/api-client/ParticipantsQuery';
import { useQueryClient } from '@tanstack/react-query';
import { AppTextInput } from 'uikit/inputs';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

type FormState = {
  studyId: number;
  participantId: string;
  fullName: string;
  enteredFullName: string;
};

export const useParticipantDeleting = () => {
  const queryClient = useQueryClient();
  const modal = useModal('CLOSED');
  const deferred = useRef<Deferred>();

  const form = useForm<FormState>({ reValidateMode: 'onChange', mode: 'onSubmit' });

  const submit = form.handleSubmit(async (values) => {
    try {
      await deleteParticipant(values.participantId);
    } catch (e) {
      handleSubmitFormError(e, form.setError);
      return;
    }

    await queryClient.invalidateQueries(getParticipantsQueryKey(values.studyId));
    deferred.current?.resolve();
    modal.closeModal();
  });

  const onHide = useCallback(() => {
    modal.closeModal();
  }, [modal]);

  return {
    element: form.watch('participantId') && (
      <FormProvider {...form}>
        <ParticipantDeletingModal visible={modal.visible} onHide={onHide} onDelete={submit} />
      </FormProvider>
    ),
    start: (participant: IParticipantDto) => {
      deferred.current = new Deferred();
      form.reset({
        studyId: participant.studyId,
        participantId: participant.id,
        fullName: participant.fullName,
        enteredFullName: '',
      });
      modal.openModal();
    },
  };
};

const ParticipantDeletingModal: FC<{ visible: boolean; onHide: () => void; onDelete: () => void }> = (props) => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();

  return (
    <DialogModal
      title={t('Participants.Deleting.Modal.Header')}
      bodyClassName={styles.modalBody}
      visible={props.visible}
      onHide={props.onHide}
      footer={{
        errors: form.formState.errors.root
          ? form.formState.errors.root.message ?? t('Participants.Deleting.Modal.Error')
          : undefined,
        leftButton: {
          text: t('Participants.Deleting.Modal.Cancel'),
          onClick: props.onHide,
          disabled: form.formState.isSubmitting,
        },
        rightButton: {
          text: t('Participants.Deleting.Modal.Delete'),
          colorSchema: 'destroy',
          type: 'button',
          disabled: form.formState.isSubmitting || !form.formState.isValid,
          isLoading: form.formState.isSubmitting,
          onClick: props.onDelete,
        },
      }}
    >
      <p>
        <Trans
          t={t}
          i18nKey={'Participants.Deleting.Modal.Text'}
          values={{ fullName: form.watch('fullName') }}
          components={{
            Bold: <span className={styles.bold} />,
          }}
        />
      </p>

      <FullNameInput />
    </DialogModal>
  );
};

const FullNameInput: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field } = useController({
    control: form.control,
    name: 'enteredFullName',
    rules: {
      ...ValidationFormRules().requiredRule,
      validate: (value, formValues) => value === formValues.fullName || t('Participants.Deleting.Modal.FullName.Error'),
    },
  });

  return (
    <AppTextInput
      placeholder={t('Participants.Deleting.Modal.FullName.Placeholder')}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
    />
  );
};
