export const isNullOrEmpty = (str: string | null | undefined): boolean => {
  return str === null || str?.match(/^ *$/) !== null;
};

export const stringComparer = (a: string, b: string) => a.localeCompare(b);

export const camelCaseToSpacedString = (str: string) => {
  const regex = /([a-z])(?=[A-Z])/g;
  return str.replace(regex, '$1 ');
};

export const normalizeFileName = (fileName: string): string => fileName.replace(/[\\\/:*?"<>|.]/g, '_');
