/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import { TextInput } from './TextInput';
import clsx from 'clsx';
import { Container } from '../Containers/Container';
import { ConditionalFieldBehaviorEnum } from '../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import styles from '../controlsStyle.module.css';
import {
  IRadioInput,
  IRadioInputProps,
  numerationOrderValues,
  radioInputApplicableValidationRules,
  radioInputDefaultPropsFactory,
} from '../../../../features/forms/base/controls/inputs/RadioInput';
import {
  useDefaultValuesForConstructorMode,
  useFieldDataFromUiEditorContext,
  useRefForDataBlockNavigation,
  useFieldValidation,
  useSkipReasonText,
} from './base/hooks';
import { CommentDataKey, FormFillContext } from '../../uiEditor/provider/formFill.context';
import { useContextSelector } from 'use-context-selector';
import { getStatusIcon } from './statusIcons/statusIcons.constants';
import { textInputDefaultPropsFactory } from '../../../../features/forms/base/controls/inputs/TextInput';
import { AppInputLabel } from 'uikit/wrappers';
import { AppRadioGroupField } from 'uikit/fields';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import OptionsSettingsSection from '../../toolbox/settingsPanel/content/OptionsSettingsSection';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';

export const RadioInput: IRadioInput = (props) => {
  const { size, dataKey, hasSkipCheckBox, isDisabled, isDisableWhenEditing, skipCheckBoxText } = props;
  const { t } = useTranslation();

  const {
    connectors: { connect, drag },
    linkedNodes,
    actions: { setProp: setSelfProp },
    id,
  } = useNode((node) => ({
    linkedNodes: node.data.linkedNodes,
  }));

  const {
    isConstructorMode,
    actions: { setProp },
  } = useEditor((state) => ({
    isConstructorMode: state.options.enabled,
    allNodes: state.nodes,
  }));

  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const { singleDefaultValue } = useDefaultValuesForConstructorMode(props);

  const {
    isOnlyView,
    isEditMode,
    singleInputValue,
    commentValue,
    setDataBlockFieldValue,
    skipReason,
    onSkipReasonChange,
    fieldEditReason,
    commentFieldEditReason,
  } = useFieldDataFromUiEditorContext(dataKey);

  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const blockRef = useRefForDataBlockNavigation();
  const [initialValue] = useState(singleInputValue);
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const setIsDisablePropToChildren = useCallback(
    (selectedValue: string | undefined) => {
      if (isOnlyView) {
        return;
      }

      const containersIds = Object.values(linkedNodes);
      const selectedOption = props.options.find((x) => x.value === selectedValue);

      if (!containersIds) {
        return;
      }

      Object.entries(linkedNodes).forEach(([key, containerId]) => {
        if (key === 'comment') return;

        // Disable option
        setProp(
          containerId,
          (p) => (p.isDisabled = isDisabled || !selectedOption || key !== selectedOption.key.toString()),
        );

        // NOTE: Here was removing values of disabled nodes
        // but it caused that some values were not displayed
        // in editing mode,
        // because it deleted values of other fields,
        // if they had the same DataKey
      });
    },
    [isOnlyView, linkedNodes, props.options, setProp, isDisabled],
  );

  useEffect(() => {
    if (isConstructorMode) return;

    setIsDisablePropToChildren(singleInputValue);
  }, [singleInputValue, isDisabled]);

  const displayValue = useMemo(() => {
    return props.options
      .filter((x) => x.value === singleInputValue)
      .map((x) => ({
        key: x.key,
        icon: (props.hasStatusIcons && getStatusIcon(x.statusIconName)) || undefined,
        displayValue: x.displayValue,
      }))[0];
  }, [singleInputValue, props.options, props.hasStatusIcons]);

  const conditionalContainer = useMemo(() => {
    const options = props.options.filter((x) => x.withConditionalField);

    const finalArray = isConstructorMode
      ? options
      : options.filter(
          (x) =>
            x.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
            (x.conditionalFieldBehavior === ConditionalFieldBehaviorEnum.Visibility && x.value === singleInputValue),
        );

    return Array.from(finalArray, (option) => (
      <React.Fragment key={option.key}>
        {isConstructorMode && <div className={styles.tabLabel}>{option.displayValue ?? ''}</div>}
        <Element key={option.key} id={option.key.toString()} is={Container} canvas={true} flexBoxGap={12} />
      </React.Fragment>
    ));
  }, [props.options, isConstructorMode, singleInputValue]);

  const onChangeHandler = useCallback(
    (option: any) => {
      if (isConstructorMode) {
        setSelfProp((p: IRadioInputProps) => {
          p.defaultValue = option?.value;
        });
        return;
      }

      if (option && typeof option === 'string') {
        setDataBlockFieldValue?.(dataKey, option);
        setIsDisablePropToChildren(option);
        return;
      }

      setDataBlockFieldValue?.(dataKey, option?.value);
      setIsDisablePropToChildren(option?.value);
    },
    [dataKey, isConstructorMode, setDataBlockFieldValue, setIsDisablePropToChildren, setSelfProp],
  );

  type RadioOption = {
    displayValue: string;
    value: string;
    icon?: JSX.Element;
  };

  const radioOptions = useMemo<RadioOption[]>(
    () =>
      props.options.map((option) => ({
        displayValue: option.displayValue,
        value: option.value,
        icon: props.hasStatusIcons ? getStatusIcon(option.statusIconName) : undefined,
      })),
    [props.options, props.hasStatusIcons],
  );

  const editableComponent = (
    <div
      data-test-field-type={'radio-input'}
      className={styles.container}
      style={{ width: size }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef}>
        <div className={clsx({ [styles.validationError]: !!dataBlockValidation?.text })}>
          <AppRadioGroupField
            labelProps={{
              isBold: props.isBold,
              text: !props.hideLabel ? props.label || props.text || dataKey : undefined,
              tooltip: props.tooltip,
            }}
            disabled={
              // TODO: move it to the useInputIsDisabled hook in the future
              !props.isEditable || isDisabled || (isDisableWhenEditing && isEditMode && !!initialValue) || isSubmitting
            }
            labelField={'displayValue'}
            iconField={props.hasStatusIcons ? 'icon' : undefined}
            valueField={'value'}
            options={radioOptions || []}
            numerationOrder={props.numerationOrder}
            numerationStartIndex={props.numerationStartIndex}
            value={isConstructorMode ? singleDefaultValue : singleInputValue}
            isVertical={props.isVertical}
            onChange={onChangeHandler}
            errorProps={{
              errors: dataBlockValidation?.text,
            }}
            skipProps={{
              inputCanBeSkipped: hasSkipCheckBox,
              skipText: skipCheckBoxText,
              onSkipReasonChange: onSkipReasonChange(dataKey),
              skipReason: skipReason,
            }}
          />
        </div>

        {conditionalContainer}

        {props.withComment && (
          <Element
            id={'comment'}
            is={TextInput}
            {...textInputDefaultPropsFactory()}
            dataKey={CommentDataKey(dataKey)}
            isMultiline={true}
            withTrigger={true}
            isDisabled={!props.isEditable || isDisabled || !!isSubmitting}
            label={t('Forms.Controls.CommentFieldCaption')}
            triggerType={'Comment'}
            triggerState={commentValue ? 'Field' : 'Trigger'}
            size={size}
            hasSkipCheckBox={false}
          />
        )}
      </div>
    </div>
  );

  const viewComponent = (
    <>
      <IssueMark
        issueContext={{
          subject: 'Patient',
          topic: 'Records',
          topicAdditional: formConfig?.type,
          linkedPatientUniqId: patient?.patientId,
          fieldDescription: props.label ?? props.dataKey,
          resultId: formResultVersion?.formResultId,
          fieldId: id,
          stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
        }}
        ignoreFieldsForCount={['stepName']}
        position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
      >
        <OverviewField
          label={props.label ?? props.text ?? props.dataKey}
          skipped={skipReason !== undefined}
          skipReason={skipReasonText}
          value={displayValue}
          editReason={fieldEditReason}
        />
        {commentValue && (
          <OverviewField
            label={t('Forms.Controls.CommentFieldCaption')}
            value={commentValue}
            editReason={commentFieldEditReason}
          />
        )}
      </IssueMark>
      {conditionalContainer.length > 0 && conditionalContainer}
    </>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

const RadioInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as IRadioInputProps,
  }));

  const numerationOrderOptions: DropdownOption[] = numerationOrderValues.map((x) => {
    return { key: x, text: x } as DropdownOption;
  });
  const [numerationOrder, setNumerationOrder] = useState<string>(props.numerationOrder || 'NoNum');

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.isVertical')}
          isChecked={!!props.isVertical}
          setValue={(newValue) =>
            setProp((setProps: IRadioInputProps) => {
              setProps.isVertical = newValue ? newValue : undefined;
            })
          }
        />
      </ControlsGroup>

      <ControlsGroup flexible>
        <AppInputLabel text={t('StudySettingsPage.FormBuilder.settings.numerationOrder')}>
          <AppDropDownWithSuggestionInput
            isRequired
            options={numerationOrderOptions}
            value={numerationOrderOptions.find((x) => x.key === numerationOrder)}
            onChange={(x) => {
              setNumerationOrder(x?.key as string);
              if (x?.key === 'NoNum') {
                setProp((setProps: IRadioInputProps) => {
                  setProps.numerationOrder = undefined;
                  setProps.numerationStartIndex = undefined;
                });

                return;
              }

              // @ts-ignore
              setProp((setProps: IRadioInputProps) => (setProps.numerationOrder = x?.key as string));
            }}
          />
        </AppInputLabel>

        {!!props.numerationOrder && (
          <AppInputLabel text={t('StudySettingsPage.FormBuilder.settings.numerationStartIndex')}>
            <SettingsInput
              type={'number'}
              value={props.numerationStartIndex}
              onChange={(e) => {
                setProp((setProps: IRadioInputProps) => {
                  setProps.numerationStartIndex = Number(e.target?.value);
                });
              }}
            />
          </AppInputLabel>
        )}
      </ControlsGroup>

      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.withComment')}
          isChecked={!!props.withComment}
          setValue={(newValue) =>
            setProp((setProps: IRadioInputProps) => {
              setProps.withComment = newValue ? newValue : undefined;
            })
          }
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const RadioInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <RadioInputGeneralSettings />
      <OptionsSettingsSection hasVariable={false} />
      <LayoutSettings />
      <ValidationSettings applicableRules={radioInputApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

RadioInput.craft = {
  props: radioInputDefaultPropsFactory(),
  related: {
    settings: RadioInputSettings,
  },
};
