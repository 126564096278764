import { useNode } from '@craftjs/core';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { ISliderInput } from 'src/features/forms/base/controls/inputs/SliderInput';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { useContextSelector } from 'use-context-selector';
import { ConditionalFieldBehaviorEnum } from '../../../../forms/base/controls/inputs/base/baseControlSettings';
import { RPDF } from '../../../react-pdf';
import { renderNode } from '../../recordReport-helper';
import { recordReportFormControlsResolver } from '../../recordReport.resolver';
import { useSkipReasonText } from '../../../../../components/formEditor/controls/Inputs/base/hooks';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const SliderInput: ISliderInput = ({
  label,
  dataKey,
  skipCheckBoxText,
  captionStartText,
  captionEndText,
  minValue,
  maxValue,
  options,
}) => {
  const { id } = useNode();

  const nodes = useContextSelector(RecordContext, (v) => v.nodes);
  const linkedNodes = nodes.find((n) => n.id === id)?.linkedNodes;

  const inputValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  const skipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.[dataKey]);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const labelOnOverview = useMemo(() => {
    const leftText = isNullOrEmpty(captionStartText) ? undefined : `${minValue}: ${captionStartText}`;
    const rightText = isNullOrEmpty(captionEndText) ? undefined : `${maxValue}: ${captionEndText}`;
    const caption =
      [leftText, rightText].filter(Boolean).length > 0 ? ` (${[leftText, rightText].filter(Boolean).join(', ')})` : '';

    return `${label ?? dataKey}${caption}`;
  }, [captionEndText, captionStartText, dataKey, label, maxValue, minValue]);

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = options.filter((x) => x.withConditionalField);
    const currentValue = Number.parseFloat(singleInputValue);
    const finalArray = optionsWithConditionalField.filter(
      (option) =>
        option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
        (option.valueRangeCondition &&
          option.valueRangeCondition[0] <= currentValue &&
          currentValue <= option.valueRangeCondition[1]),
    );

    return Array.from(finalArray, (option) => {
      const childId = _.get(linkedNodes, option.key);
      if (!childId) {
        return <RPDF.View key={option.key} />;
      }

      return <RPDF.View key={option.key}>{renderNode(nodes, recordReportFormControlsResolver, childId)}</RPDF.View>;
    });
  }, [options, singleInputValue, linkedNodes, nodes]);

  return (
    <>
      <Field label={labelOnOverview} skipReason={skipReasonText} value={singleInputValue} />
      {conditionalContainer.length > 0 && conditionalContainer}
    </>
  );
};
