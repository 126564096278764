﻿import React, { FC, PropsWithChildren, useState } from 'react';
import styles from './overview.module.scss';
import { TypographyStyles } from 'styles';
import { ReactComponent as Arrow } from 'src/assets/img/formBuilder/arrow_small_24.svg';
import { AppButton } from 'uikit/buttons';
import { Collapse } from 'uikit/collapse/Collapse';
import clsx from 'clsx';

export const OverviewRepeatableFormSection: FC<PropsWithChildren<{ title: string }>> = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className={styles.overviewRepeatableFormSectionHeader}>
        <span className={TypographyStyles.heading2}>{props.title}</span>
        <AppButton
          className={clsx({ [styles.collapsed]: !expanded })}
          variant={'icon-link'}
          colorSchema={'primary'}
          Icon={Arrow}
          onClick={() => setExpanded((x) => !x)}
        />
      </div>
      <Collapse in={expanded} unmountOnExit>
        <div className={styles.overviewRepeatableFormSectionBody}>{props.children}</div>
      </Collapse>
    </>
  );
};
