import {
  areIntervalsOverlapping,
  compareAsc,
  endOfDay,
  isAfter,
  isBefore,
  isEqual,
  max,
  startOfDay,
  subMonths,
  toDate,
} from 'date-fns';

export const unionIntervals = (intervals: Interval[]) => {
  if (intervals.length === 0) return [];

  intervals = intervals.slice();
  intervals.sort((a, b) => compareAsc(a.start, b.start));

  const result: Interval[] = [];
  let prev = intervals[0];
  for (const interval of intervals) {
    if (areIntervalsOverlapping(prev, interval)) {
      // New date creating is needed to prevent side effects from
      // mutating operation on Date
      prev = { start: new Date(prev.start), end: new Date(max([interval.end, prev.end])) };
    } else {
      result.push(prev);
      prev = { start: new Date(interval.start), end: new Date(interval.end) };
    }
  }
  result.push(prev);
  return result;
};

export const intervalContains = (interval: Interval, dateTime: Date) => {
  return isAfterOrEqual(dateTime, interval.start) && isBefore(dateTime, interval.end);
};

export const isBeforeOrEqual = (dateTime: number | Date, dateTimeToCompare: number | Date) => {
  return isBefore(dateTime, dateTimeToCompare) || isEqual(dateTime, dateTimeToCompare);
};

export const isAfterOrEqual = (dateTime: number | Date, dateTimeToCompare: number | Date) => {
  return isAfter(dateTime, dateTimeToCompare) || isEqual(dateTime, dateTimeToCompare);
};

export const isIntervalBoundsEqual = (expectedInterval: Interval, actualInterval?: Interval): boolean => {
  return (
    !!actualInterval &&
    isEqual(expectedInterval.start, actualInterval.start) &&
    isEqual(expectedInterval.end, actualInterval.end)
  );
};

export const getSelectedIntervalAsPredefinedPeriod = (interval?: Interval): PredefinedIntervalType | null => {
  if (interval) {
    for (const period of periods) {
      const expectedRangeBounds = getPredefinedInterval(period);
      const isSelectedPeriod = isIntervalBoundsEqual(expectedRangeBounds, interval);
      if (isSelectedPeriod) return period;
    }
  }
  return null;
};

export const getPredefinedInterval = (intervalKind: PredefinedIntervalType): Interval => {
  const currentDate = toDate(Date.now());
  switch (intervalKind) {
    case 'past-half-year':
      return { start: subMonths(startOfDay(currentDate), 6), end: endOfDay(currentDate) };
    case 'past-quarter':
      return { start: subMonths(startOfDay(currentDate), 3), end: endOfDay(currentDate) };
    case 'past-month':
      return { start: subMonths(startOfDay(currentDate), 1), end: endOfDay(currentDate) };
    default:
      return { start: startOfDay(currentDate), end: endOfDay(currentDate) };
  }
};
const periods = ['past-month', 'past-quarter', 'past-half-year'] as const;
export type PredefinedIntervalType = (typeof periods)[number];
