import React from 'react';
import clsx from 'clsx';
import { CommonStyles, TypographyStyles } from 'src/styles';
import style from './LoginErrorPage.module.scss';
import AccessLockImage from 'src/assets/img/common/access_lock.svg';
import { AppButton } from 'uikit/buttons';
import { logOut } from '../../helpers/auth/auth-interceptor';
import i18n from 'i18next';

/** !!!IMPORTANT!!! do not use hooks here inside as that component is wrapped whole app and conditionally rendered **/
export const LoginErrorPage: React.FC<{
  error: string;
  error_description: string;
  error_uri: string;
  request_uri: string;
  error_state: string;
}> = (props) => {
  const { error, error_description, ...rest } = props;

  const detailsBlock = () => {
    const isEmpty = Object.values(rest).length === 0;
    if (isEmpty) return <></>;

    return (
      <div className={style.detailsContainer}>
        <span className={TypographyStyles.heading3}>{i18n.t('Login.Azure.Details')}</span>
        {Object.entries(rest).map((x) => {
          return (
            <div key={x[0]}>
              <span>{x[0]}:</span>
              <span>{x[1]}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={clsx(CommonStyles.simplePageContainer, style.container)}>
      <img src={AccessLockImage} alt={'Information page image'} className={style.img} />
      <div className={style.texContent}>
        <div className={TypographyStyles.informationPageHeader}>
          {i18n.t(`Login.Azure.Errors.${error}`, { defaultValue: i18n.t('Login.Azure.Errors.common_error') })}
        </div>
        <div className={TypographyStyles.paragraph14}>{error_description}</div>

        {detailsBlock()}

        <AppButton
          colorSchema={'primary'}
          text={i18n.t('Login.Azure.Button')}
          onClick={async () => {
            await logOut();
          }}
        />
      </div>
    </div>
  );
};
