export type AppSliderInputProps = {
  disabled?: boolean;
  caption?: {
    startText?: string;
    centerText?: string;
    endText?: string;
  };
  className?: string;
  showMarks?: boolean;
  /** @default SingleColor */
  colorScheme?: SliderColorSchemeEnum;
  startWith: number;
  endWith: number;
  startPoint?: number;
  value?: number;
  onChange?: (v: number | undefined) => void;
  step?: number;
  marksStep?: number;
  showNumberInput?: boolean;
};

export enum SliderColorSchemeEnum {
  SingleColor = 1,
  GreenToRed = 2,
  RedToGreen = 3,
}
