import { RPDF } from '../react-pdf';
import React from 'react';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { ReportColors } from '../report-colors';

export const ReportEmptyList: React.VoidFunctionComponent = () => {
  const { t } = useScopedTranslation('Reports');
  return (
    <RPDF.View style={RPDF.styles.emptyContainer}>
      <RPDF.View style={RPDF.styles.emptyTextContainer}>
        <RPDF.Text style={RPDF.typography.heading2}>{t('EmptyListTitle')}</RPDF.Text>
        <RPDF.Text style={[RPDF.typography.text10, { color: ReportColors.plain }]}>{t('EmptyListText')}</RPDF.Text>
      </RPDF.View>
    </RPDF.View>
  );
};
