import React, { useCallback, useState, FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UseModalProps } from '../../../application/hooks/useModal';
import { DialogModal } from '../../dialogModal/dialogModal.component';
import Style from './issueRevokeDialog.module.css';

export type IssueRevokeDialogParams = {
  issueId: number;
  callback: () => Promise<void>;
};

export const IssueRevokeDialog: FC<{
  modal: UseModalProps<IssueRevokeDialogParams>;
}> = ({ modal }) => {
  const { t } = useTranslation();
  const [isApplying, setIsApplying] = useState(false);

  const { issueId, callback } = modal.params;

  const onApplyHandler = useCallback(async () => {
    try {
      setIsApplying(true);
      await callback();
    } catch (error) {
    } finally {
      modal.closeModal();
      setIsApplying(false);
    }
  }, [callback, modal]);

  return (
    <DialogModal
      visible={modal.visible}
      onHide={isApplying ? () => {} : modal.closeModal}
      containerClassName={Style.modalContainer}
      footer={{
        leftButton: {
          disabled: isApplying,
          text: t('Common_Cancel'),
          type: 'reset',
          onClick: modal.closeModal,
        },
        rightButton: {
          colorSchema: 'destroy',
          text: t('Issues.RevokeDialog.RevokeButton'),
          onClick: onApplyHandler,
          disabled: isApplying,
          isLoading: isApplying,
          hasLoaded: isApplying,
        },
      }}
      title={t('Issues.RevokeDialog.Title')}
      testId={'revoke-issue-modal'}
    >
      <span>
        <Trans i18nKey={'Issues.RevokeDialog.MainText'} values={{ issueId }}>
          <strong />
          <strong className={Style.awfulText} />
        </Trans>
      </span>
    </DialogModal>
  );
};
