import React, { useCallback } from 'react';
import { AppButton } from 'uikit/buttons';
import { StartStudyComponentProps } from './startStudy';
import { ReactComponent as StartIcon } from '../../assets/img/patient/start_20.svg';
import { MetricActions } from '../../application/analitics/matomo/matomo-actions';
import { MetricCategories } from '../../application/analitics/matomo/matomo-categories';
import { trackEvent } from '../../application/analitics/matomo/matomo-tracker';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { useFormEditing } from '../../features/forms/editing/useFormEditing';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { getFormResults } from '../../services/api/api-client/FormsClient';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const StartStudyComponent = (props: StartStudyComponentProps) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.StartStudy');
  const study = useStudy();

  const formEditing = useFormEditing();

  const onShow = useCallback(async () => {
    if (!study) return;

    const results = await getFormResults(
      study.patientCreationFormBinding?.id,
      undefined,
      undefined,
      props.patientId,
      undefined,
      undefined,
      undefined,
      1,
    );

    await formEditing.startFormEditing({ formResult: results.data[0], stepName: undefined });

    trackEvent({ action: MetricActions.ButtonPressed, category: MetricCategories.Patient, name: 'StartStudyButton' });
  }, [formEditing, props.patientId, study]);

  return (
    <div>
      <Tooltip text={t('Tooltip')}>
        <AppButton
          testId="patient-card-start-study"
          variant={'icon-link'}
          colorSchema={'primary'}
          Icon={StartIcon}
          text={t('Button')}
          onClick={onShow}
        />
      </Tooltip>

      {formEditing.element}
    </div>
  );
};
