import React, { useCallback, useMemo } from 'react';
import { IIndicatorModel } from '../../../services/api/api-client';
import { formatDuration, intervalToDuration } from 'date-fns';
import { ReactComponent as ComplianceIcon } from '../../../assets/img/patient/indicators/compliance.svg';
import Style from '../baseIndicator.module.css';
import clsx from 'clsx';
import { WarningSignIndicator } from '../warning.component';
import { getIndicatorStyleColor } from 'src/helpers/color-helper';
import { TherapyTooltipComponent } from '../therapyTooltip/therapyTooltip.component';

const formatDistanceLocale: { [key: string]: string } = {
  xMinutes: '{{count}} min',
  xHours: '{{count}} h',
};

const shortEnLocale = {
  formatDistance: (token: any, count: number) => formatDistanceLocale[token].replace('{{count}}', String(count)),
};

function mapToInterval(value?: number | null): Duration {
  return intervalToDuration({
    start: 0,
    end: (value || 0) * 1000 * 60,
  });
}

export const ComplianceIndicator = (props: { indicator?: IIndicatorModel | null }) => {
  const durationAsMinutes = useMemo(() => {
    return mapToInterval(props?.indicator?.value);
  }, [props?.indicator?.value]);

  const tooltipText = useMemo(() => {
    return formatDuration(durationAsMinutes, {
      format: ['hours', 'minutes'],
      locale: shortEnLocale,
    });
  }, [durationAsMinutes]);

  const icon = useMemo(() => {
    if (props?.indicator?.therapyIsNotReceivedForAggregatedPeriod) {
      return <WarningSignIndicator />;
    }

    return (
      <ComplianceIcon
        className={clsx(getIndicatorStyleColor(props?.indicator?.qualityMetric), Style.base, Style.defaultCursor)}
      />
    );
  }, [props?.indicator?.therapyIsNotReceivedForAggregatedPeriod, props?.indicator?.qualityMetric]);

  const tableFormatFunction = useCallback((value: number) => {
    if (value === 0) {
      return '0 min';
    }
    return formatDuration(mapToInterval(value), {
      format: ['hours', 'minutes'],
      locale: shortEnLocale,
    });
  }, []);

  const durationText = useMemo(() => {
    if (
      props?.indicator?.deviceIsNotUsedForAggregatedPeriod &&
      !props?.indicator?.therapyIsNotReceivedForAggregatedPeriod
    ) {
      return '0 min';
    }

    return formatDuration(durationAsMinutes, {
      format: ['hours', 'minutes'],
      locale: shortEnLocale,
    });
  }, [
    durationAsMinutes,
    props?.indicator?.deviceIsNotUsedForAggregatedPeriod,
    props?.indicator?.therapyIsNotReceivedForAggregatedPeriod,
  ]);

  return (
    <TherapyTooltipComponent
      indicator={props?.indicator}
      tooltipValueText={tooltipText}
      testId={'compliance'}
      Icon={ComplianceIcon}
      values={props?.indicator?.therapyDayValues || []}
      valueFormatter={tableFormatFunction}
    >
      <div className={Style.indicator}>
        {icon}
        {durationText && <span className={Style.durationText}>{durationText}</span>}
      </div>
    </TherapyTooltipComponent>
  );
};
