import React, { FC } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { NavAppMenu } from '../containers/navigationMenu/navAppBar.component';
import Style from './index.module.css';
import { handle403Forbidden } from '../helpers/auth/auth-interceptor';
import axios from 'axios';
import * as Interceptors from 'src/helpers/interceptors';
import { useQueryClient } from '@tanstack/react-query';
import { AppToastContainer } from '../components/toast/AppToastContainer';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const Layout: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const study = useStudy();
  axios.interceptors.response.use(undefined, handle403Forbidden(navigate, queryClient));
  axios.interceptors.request.use(Interceptors.injectStudyId(study));

  return (
    <div className={Style.authContainer}>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <NavAppMenu />
        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto', flex: '1 1 auto' }}>
          <Outlet />
        </div>
        <AppToastContainer />
      </QueryParamProvider>
    </div>
  );
};
