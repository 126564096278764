﻿import { ColumnDef } from '@tanstack/react-table';
import { IParticipantDto } from '../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { expandRowButtonColumn } from 'uikit/table/columns/ExpandRowButtonColumn';
import { localFormat } from '../../helpers/date-helpers';

export const useParticipantsColumns = (): ColumnDef<IParticipantDto>[] => {
  const { t } = useTranslation();

  return useMemo<ColumnDef<IParticipantDto>[]>(
    () => [
      {
        accessorKey: 'fullName',
        header: t('Participants.Page.Table.FullName'),
        enableSorting: false,
        meta: {
          dontUseHeaderWidth: true,
        },
      },
      {
        accessorKey: 'email',
        header: t('Participants.Page.Table.Email'),
        enableSorting: false,
        meta: {
          dontUseHeaderWidth: true,
          isColored: true,
        },
      },
      {
        accessorKey: 'role',
        header: t('Participants.Page.Table.Role'),
        enableSorting: false,
        size: 300,
      },
      {
        accessorKey: 'onboardedOn',
        header: t('Participants.Page.Table.OnboardedOn'),
        enableSorting: false,
        size: 100,
        cell: ({ row }) => localFormat(row.original.onboardedOn),
        meta: {
          isColored: true,
          centered: true,
        },
      },
      {
        accessorKey: 'offboardedOn',
        header: t('Participants.Page.Table.OffboardedOn'),
        enableSorting: false,
        size: 100,
        cell: ({ row }) => row.original.offboardedOn && localFormat(row.original.offboardedOn),
        meta: {
          isColored: true,
          centered: true,
        },
      },
      expandRowButtonColumn({}),
    ],
    [t],
  );
};
