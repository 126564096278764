import React, { FC, useMemo, useState } from 'react';
import TablePageLayout from '../../components/tablePageLayout/TablePageLayout';
import { useTranslation } from 'react-i18next';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { IDoctorStudyDto } from '../../services/api/api-client';
import { AppTable } from 'uikit/table/AppTable';
import { useStudiesColumns } from './study.columns';
import { Tabs } from 'uikit/tabs/tabs';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { useStaffProfile } from 'src/helpers/hooks/useStudy';

type StudyState = 'Active' | 'Closed';

export const Studies = () => {
  const { t } = useTranslation();
  const { profile } = useStaffProfile();

  // TODO: Rewrite to query in the future
  const [studyState, setStudyState] = useState<StudyState>('Active');
  const [searchString, setSearchString] = useState('');

  const studies = useMemo(
    () =>
      profile?.studies
        .filter((x) => (studyState === 'Active' ? !x.closedAt : x.closedAt))
        .filter(
          (x) =>
            x.title.toLowerCase().includes(searchString.toLowerCase()) ||
            x.id.toString().padStart(3, '0').includes(searchString) ||
            x.studyNumber.toString().toLowerCase().includes(searchString.toLowerCase()),
        ) ?? [],
    [profile?.studies, searchString, studyState],
  );

  const rowDataTable = useReactTable<IDoctorStudyDto>({
    data: studies,
    columns: useStudiesColumns(),
    getCoreRowModel: getCoreRowModel(),
  });

  const counts = useMemo(() => {
    return {
      active: profile?.studies?.filter((s) => !s.closedAt).length,
      closed: profile?.studies?.filter((s) => s.closedAt).length,
      all: profile?.studies?.length,
    };
  }, [profile?.studies]);

  return (
    <TablePageLayout testId={'App'}>
      <TablePageLayout.Header>
        <TablePageLayout.Header.Row>
          <TablePageLayout.Header.Title text={t('StudiesList.Caption')} secondaryText={counts.all?.toString()} />
        </TablePageLayout.Header.Row>
        <StudyFilters
          counts={counts}
          onTabChange={setStudyState}
          currentTab={studyState}
          setSearchString={setSearchString}
          searchString={searchString}
        />
      </TablePageLayout.Header>
      <TablePageLayout.TableContainer>
        <AppTable table={rowDataTable} placeholder={{ text: t('StudiesList.NoData') }} />
      </TablePageLayout.TableContainer>
    </TablePageLayout>
  );
};

type StudyFiltersProps = {
  counts?: {
    active?: number;
    closed?: number;
  };
  onTabChange: (newTab: StudyState) => void;
  currentTab: StudyState;
  searchString?: string;
  setSearchString?: (str: string) => void;
};

const StudyFilters: FC<StudyFiltersProps> = ({ counts, onTabChange, currentTab, setSearchString, searchString }) => {
  const { t } = useScopedTranslation('StudiesList');

  const tabs = useMemo(
    () => [
      {
        text: t('Tabs.Active', { value: counts?.active || 0 }),
        isSelected: currentTab === 'Active',
        onClick: () => onTabChange('Active'),
        testId: 'Active',
      },
      {
        text: t('Tabs.Closed', { value: counts?.closed || 0 }),
        isSelected: currentTab === 'Closed',
        onClick: () => onTabChange('Closed'),
        testId: 'Closed',
      },
    ],
    [counts?.active, counts?.closed, currentTab, onTabChange, t],
  );

  return (
    <div style={{ display: 'contents' }} data-test-id={'patient-filters-container'}>
      <TablePageLayout.Header.Row>
        <Tabs items={tabs} />
      </TablePageLayout.Header.Row>
      <TablePageLayout.Header.Row>
        <TablePageLayout.Header.Search
          placeholder={t('SearchPlaceholder')}
          value={searchString}
          onChange={(e) => setSearchString?.(e.target.value)}
        />
      </TablePageLayout.Header.Row>
    </div>
  );
};
