import React, { FC, useCallback, useMemo, useState } from 'react';
import { ReactComponent as Tip } from 'assets/img/common/tip_20.svg';
import { Tooltip } from 'src/components/uikit/tooltip/tooltip.component';
import { ErrorScoreCalculation } from 'src/components/errorScoreCalculation/errorScoreCalculation.component';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { logger } from 'src/application/logging/logging';
import { QueryFactory } from 'src/services/api';
import { useQueryClient } from '@tanstack/react-query';
import {
  IFormResultVersionDto,
  IScoreSection,
  ScoreCalculationStatusEnum,
  ScoreGroupEnum,
} from 'src/services/api/api-client';
import { LocalizedResourceDictionaryKeys } from 'src/application/localisation/i18next';
import { TypographyStyles } from 'styles';
import Style from './FormScore.module.scss';

export type FormScoreComponentProps = {
  formResultVersion?: IFormResultVersionDto;
};

export const FormScoreComponent: FC<FormScoreComponentProps> = ({ formResultVersion }) => {
  const { t } = useScopedTranslation('Forms');
  const commonLocalizer = useCommonLocalization();
  const client = useQueryClient();

  const [calculating, setCalculating] = useState<boolean>(false);

  const recalculateFormScore = useCallback(async () => {
    if (!formResultVersion) return;

    setCalculating(true);
    try {
      await QueryFactory.FormsQuery.Client.recalculateScore(
        formResultVersion.formResultId,
        formResultVersion.versionNumber,
      );

      await client.invalidateQueries(QueryFactory.FormsQuery.getFormResultByIdQueryKey(formResultVersion.formResultId));
    } catch (error: any) {
      logger().error(error);
    } finally {
      setCalculating(false);
    }
  }, [client, formResultVersion]);

  const emptyScore = useMemo(() => {
    return (
      <div className={Style.emptyScore}>
        <Tooltip text={t('Score.EmptyScoreTooltip')}>
          <Tip />
        </Tooltip>
        {commonLocalizer('Common_dash')}
      </div>
    );
  }, [t, commonLocalizer]);

  if (
    formResultVersion?.scoreStatus === ScoreCalculationStatusEnum.InProcess ||
    formResultVersion?.scoreStatus === ScoreCalculationStatusEnum.Error
  ) {
    return (
      <div className={Style.scoreContainer}>
        <div className={Style.scoreSection}>
          <div>{t('Score.TotalScore')}</div>
          <ErrorScoreCalculation
            retryHandlerType={'RetryFormResult'}
            retryHandler={recalculateFormScore}
            canRetry={formResultVersion?.canRetryScoreCalculation}
            isInProcess={formResultVersion?.scoreStatus === ScoreCalculationStatusEnum.InProcess || calculating}
          />
        </div>
      </div>
    );
  }

  if (formResultVersion?.score) {
    return (
      <div className={Style.scoreContainer}>
        {formResultVersion?.score?.sectionScore && formResultVersion?.score.sectionScore.length > 0 && (
          <div className={Style.scoreSectionsContainer}>
            {formResultVersion?.score.sectionScore?.map((section: IScoreSection) => (
              <div className={Style.scoreSection} key={section.scoreGroup} data-test-id={'score-group'}>
                <div className={TypographyStyles.plainText14}>
                  {commonLocalizer(
                    `Forms.Score.ScoreGroup.${ScoreGroupEnum[section.scoreGroup]}` as LocalizedResourceDictionaryKeys,
                  )}
                </div>
                <div className={TypographyStyles.numeral}>
                  {section.score !== null ? section.score?.toFixed(formResultVersion?.score?.decimal ?? 0) : emptyScore}
                </div>
              </div>
            ))}
          </div>
        )}
        {formResultVersion?.score.hasTotalScore && (
          <div className={Style.scoreSection} data-test-id={'total-score'}>
            <div className={TypographyStyles.plainText14}>{t('Score.TotalScore')}</div>
            <div className={TypographyStyles.numeral}>
              {formResultVersion?.score.totalScore !== null
                ? formResultVersion?.score?.totalScore?.toFixed(formResultVersion?.score.decimal ?? 0)
                : emptyScore}
            </div>
          </div>
        )}
      </div>
    );
  }

  return <></>;
};
