import { useCallback, useMemo } from 'react';
import { IDoctorStudyDto } from '../../services/api/api-client';
import { AppRoutes, navigateToRoute } from '../../navigation/routes';
import { useHasPermissions } from '../../helpers/auth/auth-helper';
import { ColumnDef } from '@tanstack/react-table';
import { Permissions } from 'src/services/api/api-client';
import { linkButtonColumn } from 'uikit/table/columns/LinkButtonColumn';
import styles from './studies.module.scss';
import { ReactComponent as Chevron } from 'src/assets/img/patient/chevronPatientRow.svg';

export const useGoToStudyButton = (): ColumnDef<IDoctorStudyDto> => {
  const { hasPermission } = useHasPermissions();

  const navigationUrl = useCallback(
    (study: IDoctorStudyDto) => {
      if (hasPermission(Permissions.PatientView)) {
        return navigateToRoute.dashboard(study.id);
      }

      if (hasPermission(Permissions.StudyConfigure)) {
        return navigateToRoute.studySettings(study.id);
      }

      if (hasPermission(Permissions.ParticipantsView)) {
        return navigateToRoute.participants(study.id);
      }

      return AppRoutes.NoPermission;
    },
    [hasPermission],
  );

  return useMemo(
    () =>
      linkButtonColumn({
        id: 'go-to-study-button',
        icon: Chevron,
        to: ({ row }) => navigationUrl(row.original),
        className: styles.goToStudyButton,
      }),
    [navigationUrl],
  );
};
