import React, { FC, PropsWithChildren, useMemo } from 'react';
import { IndicatorBaseProps } from './baseIndicator';
import Style from './baseIndicator.module.css';
import { Tooltip } from '../uikit/tooltip/tooltip.component';
import clsx from 'clsx';

export const BaseIndicatorWrapper: FC<PropsWithChildren<IndicatorBaseProps>> = (props) => {
  const textStyles: string = useMemo(() => {
    if (!props.headerText) {
      return Style.onlyText as string;
    } else {
      return Style.textWithHeader as string;
    }
  }, [props.headerText]);

  return (
    <Tooltip
      text={props.tooltipText}
      headerText={props.headerText}
      textStyles={clsx(textStyles, props.textStyle)}
      styles={Style.base}
    >
      <div data-test-id={`indicator-${props.testId}`} className={clsx({ [Style.container]: true, [Style.base]: true })}>
        {props.children}
      </div>
    </Tooltip>
  );
};
