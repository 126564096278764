import React from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import { TextInput } from './TextInput';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import { useTranslation } from 'react-i18next';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import { FileTypes } from '../../../../helpers/fileValidation-helpers';
import styles from '../controlsStyle.module.css';
import {
  filesDefaultPropsFactory,
  filesApplicableValidationRules,
  IFilesProps,
  IFilesInput,
} from '../../../../features/forms/base/controls/inputs/Files';
import {
  useRefForDataBlockNavigation,
  useFieldDataFromUiEditorContext,
  useFieldValidation,
  useSkipReasonText,
} from './base/hooks';
import { CommentDataKey, FormFillContext } from '../../uiEditor/provider/formFill.context';
import { textInputDefaultPropsFactory } from '../../../../features/forms/base/controls/inputs/TextInput';
import { AppAttachmentsField } from 'uikit/fields';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';

export const Files: IFilesInput = (props) => {
  const { t } = useTranslation();
  const { connectors, id } = useNode();

  const { connect, drag } = connectors;

  const { size, dataKey, isDisabled } = props;

  const { isConstructorMode } = useEditor((state) => ({
    isConstructorMode: state.options.enabled,
  }));

  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const {
    isOnlyView,
    setDataBlockFieldValue,
    commentValue,
    skipReason,
    onSkipReasonChange,
    fieldEditReason,
    commentFieldEditReason,
  } = useFieldDataFromUiEditorContext(dataKey);

  const skipReasonText = useSkipReasonText(skipReason, props.skipCheckBoxText);

  const inputValue = useContextSelector(FormFillContext, (v) => {
    // @ts-ignore
    return v.values ? (v.values[dataKey] as AppAttachmentValue[]) : null;
  });

  const blockRef = useRefForDataBlockNavigation();
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const viewComponent = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResultVersion?.formResultId,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
    >
      <OverviewField
        label={props.label ?? props.dataKey}
        skipped={skipReason !== undefined}
        skipReason={skipReasonText}
        attachments={inputValue ?? undefined}
        editReason={fieldEditReason}
      />
      {commentValue && (
        <OverviewField
          label={t('Forms.Controls.CommentFieldCaption')}
          value={commentValue}
          editReason={commentFieldEditReason}
        />
      )}
    </IssueMark>
  );

  const editableComponent = (
    <div
      data-test-field-type={'file-input'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef}>
        <AppAttachmentsField
          onChange={(x) => {
            setDataBlockFieldValue?.(dataKey, x);
          }}
          value={inputValue}
          disabled={!props.isEditable || isDisabled || isSubmitting}
          error={!!dataBlockValidation?.text}
          errorText={dataBlockValidation?.text}
          label={props.hideLabel ? undefined : props.label || dataKey}
          tooltip={props.tooltip}
          isBold={props.isBold}
          fileTypes={FileTypes}
          multiple={true}
          skipProps={{
            inputCanBeSkipped: props.hasSkipCheckBox,
            skipText: props.skipCheckBoxText,
            onSkipReasonChange: onSkipReasonChange(dataKey),
            skipReason: skipReason,
          }}
        />

        {props.withComment && (inputValue?.length || isConstructorMode) && !dataBlockValidation?.text && (
          <Element
            is={TextInput}
            id={'comment'}
            {...textInputDefaultPropsFactory()}
            dataKey={CommentDataKey(dataKey)}
            isMultiline={true}
            withTrigger={true}
            isDisabled={!props.isEditable || isDisabled || !!isSubmitting}
            label={t('Forms.Controls.CommentFieldCaption')}
            triggerType={'Comment'}
            triggerState={commentValue ? 'Field' : 'Trigger'}
            size={size}
            hasSkipCheckBox={false}
          />
        )}
      </div>
    </div>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

const FilesGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as IFilesProps,
  }));

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.withComment')}
          isChecked={!!props.withComment}
          setValue={(newValue) =>
            setProp((setProps: IFilesProps) => {
              setProps.withComment = newValue ? newValue : undefined;
            })
          }
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const FilesSettings = () => {
  return (
    <>
      <TitleSettings />
      <FilesGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={filesApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

Files.craft = {
  props: filesDefaultPropsFactory(),
  related: {
    settings: FilesSettings,
  },
};
