import React, { FC } from 'react';
import { RPDF } from '../../../react-pdf';

export const DoubleCheckIcon: FC = () => {
  return (
    <RPDF.Svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        d="M8.6708 4.05411C8.90779 3.80314 8.89646 3.40758 8.6455 3.17059C8.39453 2.9336 7.99897 2.94493 7.76198 3.19589L3.03092 8.20595L1.0686 6.22885C0.825436 5.98385 0.429711 5.98237 0.184719 6.22553C-0.0602724 6.46869 -0.0617568 6.86442 0.181404 7.10941L2.5985 9.54471L3.05328 10.0029L3.49651 9.53353L8.6708 4.05411ZM11.8213 4.06711C12.0655 3.82313 12.0656 3.4274 11.8216 3.18323C11.5777 2.93906 11.1819 2.93891 10.9377 3.18289L5.05823 9.05789C4.81406 9.30187 4.8139 9.6976 5.05789 9.94177C5.30187 10.1859 5.6976 10.1861 5.94177 9.94211L11.8213 4.06711Z"
        fill="#667878"
      />
    </RPDF.Svg>
  );
};
