import { IFormControlsResolver } from 'src/features/forms/base/controls/IFormControlsResolver';
import { createContext } from 'use-context-selector';

export type BufferItemType = {
  name: string;
  resolvedName: keyof IFormControlsResolver;
  nodeTree: string;
};

export type FormBuilderContextType = {
  // Buffer with copied components
  buffer: BufferItemType[];
  addToBuffer?: (item: BufferItemType) => void;
  removeFromBuffer?: (name: string) => void;
};

const defaultValues: FormBuilderContextType = {
  buffer: [],
};

export const FormBuilderContext = createContext(defaultValues);
