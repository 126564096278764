import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import Style from './RecordResultList.module.scss';
import { FormListType } from './useGetFormList';
import { ReactComponent as ChevronIcon } from 'assets/img/common/chevron.svg';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FormSkipReason, IScoreSection, ScoreGroupEnum } from 'src/services/api/api-client';
import { AppInputLabel, AppInputTrigger } from 'uikit/wrappers';
import { AppTextInput } from 'uikit/inputs';
import { useFieldDataFromUiEditorContext } from '../../Inputs/base/hooks';
import { LocalizedResourceDictionaryKeys } from 'src/application/localisation/i18next';
import { TypographyStyles } from 'styles';
import _ from 'lodash';
import { RecordResultListItemData } from 'src/features/forms/base/controls/inputs/RecordResultList';
import { FormStateIcon, getFormState, getFormStateColor } from 'src/features/forms/form-helper';

export const RecordResultContainer: FC<
  PropsWithChildren<{
    header: string;
    isOnlyView: boolean;
    rightComponent?: JSX.Element;
  }>
> = ({ header, isOnlyView, children, rightComponent }) => (
  <div>
    <div className={Style.header}>
      <div className={clsx({ [Style.paddingHorizontal]: isOnlyView })}>{header}</div>
      {rightComponent}
    </div>
    {children}
  </div>
);

export const RecordResultItem: FC<
  FormListType & {
    isOnlyView: boolean;
    dataKey: string;
  }
> = ({ formType, formResults, isOnlyView, dataKey }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { multipleInputValue, setDataBlockFieldValue } = useFieldDataFromUiEditorContext(dataKey);
  const inputValueArr: RecordResultListItemData[] = useMemo(
    () => (multipleInputValue?.length ? multipleInputValue.map((str) => JSON.parse(str)) : []),
    [multipleInputValue],
  );

  const changeComment = useCallback(
    (resultId: number, versionNumber: number | undefined, value: string | undefined) => {
      const index = inputValueArr.findIndex((x) => x.resultId === resultId && x.versionNumber === versionNumber);
      const result = [...inputValueArr];
      result[index] = { ...result[index], comment: value };

      setDataBlockFieldValue?.(
        dataKey,
        result.map((x) => JSON.stringify(x)),
      );
    },
    [dataKey, inputValueArr, setDataBlockFieldValue],
  );

  const getResultIdComment = useCallback(
    (resultId: number) => inputValueArr.find((x) => x.resultId === resultId)?.comment,
    [inputValueArr],
  );

  const resultsToView = useMemo(() => {
    if (isOnlyView) {
      return formResults.map((x) => {
        const savedVersionNumber = inputValueArr.find((a) => a.resultId === x.resultId)?.versionNumber;
        return {
          resultId: x.resultId,
          stepName: x.stepName,
          createdAt: x.createdAt,
          currentVersion: x.versions.find((v) => v.versionNumber === savedVersionNumber),
        };
      });
    }

    return formResults.map((x) => ({
      resultId: x.resultId,
      stepName: x.stepName,
      createdAt: x.createdAt,
      currentVersion: _.last(x.versions),
    }));
  }, [formResults, inputValueArr, isOnlyView]);

  return (
    <div className={clsx(Style.itemContainer, { [Style.paddingHorizontal]: isOnlyView })}>
      <button className={Style.itemHeader} onClick={() => setIsExpanded((x) => !x)}>
        <div>{formType}</div>
        <div className={Style.count}>{`(${formResults.length})`}</div>
        <ChevronIcon className={clsx(Style.expandButton, { [Style.rotated]: !isExpanded })} />
      </button>
      <div className={clsx(Style.itemList, { [Style.isExpanded]: isExpanded })}>
        {resultsToView.map((result, i) => {
          const comment = getResultIdComment(result.resultId);
          const formState = getFormState(
            result.resultId,
            result.currentVersion?.lifecycleState,
            result.currentVersion?.isSkipped,
            result.currentVersion?.fillingProgress,
            null,
            true,
          );
          const translatedSkipReason =
            result.currentVersion?.skipReason === FormSkipReason.NotApplicable
              ? t('Forms.FormSkipReason.NotApplicable')
              : result.currentVersion?.skipReason === FormSkipReason.Missed
              ? t('Forms.FormSkipReason.Missed')
              : undefined;

          return (
            <div key={i} className={Style.resultContainer}>
              <div className={Style.resultHeader}>
                <div className={clsx(Style.formType, getFormStateColor(formState, 'PatientCard'))}>
                  <FormStateIcon variant={'PatientCard'} state={formState} />
                  {formType}
                </div>
                <span className={Style.stepName}>{result.stepName ? result.stepName : result.createdAt}</span>
              </div>
              {result.currentVersion?.score && (
                <div className={clsx(Style.scoreSectionsContainer, { [Style.overviewTemplate]: isOnlyView })}>
                  {result.currentVersion?.score.sectionScore.map((section: IScoreSection) => (
                    <div className={Style.scoreSection} key={section.scoreGroup}>
                      <div className={TypographyStyles.plainText14}>
                        {t(
                          `Forms.Score.ScoreGroup.${
                            ScoreGroupEnum[section.scoreGroup]
                          }` as LocalizedResourceDictionaryKeys,
                        )}
                      </div>
                      <div className={TypographyStyles.heading2}>
                        {section.score !== null
                          ? section.score?.toFixed(result.currentVersion?.score?.decimal ?? 0)
                          : t('Common_dash')}
                      </div>
                    </div>
                  ))}
                  {result.currentVersion?.score?.hasTotalScore && (
                    <div className={Style.scoreSection}>
                      <div className={TypographyStyles.plainText14}>{t('Forms.Score.TotalScore')}</div>
                      <div className={TypographyStyles.heading2}>
                        {result.currentVersion?.score?.totalScore !== null
                          ? result.currentVersion?.score?.totalScore?.toFixed(
                              result.currentVersion?.score?.decimal ?? 0,
                            )
                          : t('Common_dash')}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {translatedSkipReason && (
                <div className={Style.comment}>
                  <span>{`${t('Forms.Controls.RecordResultList.SkipFormReason')}:`}</span>
                  <span>{translatedSkipReason}</span>
                </div>
              )}
              {result.currentVersion?.skipReasonDetails && (
                <div className={Style.comment}>
                  <span>{`${t('Forms.Controls.RecordResultList.SkipFormReasonDetails')}:`}</span>
                  <span>{result.currentVersion?.skipReasonDetails}</span>
                </div>
              )}
              {isOnlyView ? (
                comment && (
                  <div className={Style.comment}>
                    <span>{`${t('Forms.Controls.RecordResultList.CommentLabel')}:`}</span>
                    <span>{comment}</span>
                  </div>
                )
              ) : (
                // We can't use Element as TextInput because we store all comments as an object
                // [dataKey]: {
                //   comment_1: "Some text",
                //   comment_2: "Some text",
                // }
                // But Element store one comment per element with custom dataKey
                // [dataKey_comment_1]: "Some text"
                // [dataKey_comment_2]: "Some text"
                <AppInputLabel text={t('Forms.Controls.RecordResultList.CommentLabel')}>
                  <AppInputTrigger
                    onHide={() => changeComment(result.resultId, result.currentVersion?.versionNumber, undefined)}
                    type={'Comment'}
                    initialState={comment ? 'Field' : 'Trigger'}
                  >
                    <AppTextInput
                      value={comment ?? ''}
                      type={'text-area'}
                      placeholder={t('Forms.Controls.RecordResultList.CommentPlaceholder')}
                      onChange={(e) =>
                        changeComment(result.resultId, result.currentVersion?.versionNumber, e.target.value)
                      }
                    />
                  </AppInputTrigger>
                </AppInputLabel>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
