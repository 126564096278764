/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as Settings } from '../../assets/img/common/settings_24.svg';
import { ReactComponent as Import } from '../../assets/img/common/import_20.svg';
import { ReactComponent as Export } from '../../assets/img/common/export.svg';
import { IPagedResultOfPatientListDto, PatientStudyStateEnum, SurveyType } from '../../services/api/api-client';
import { ExpandedPatientRow } from '../expandedRow';
import { ReactComponent as WarningSign } from './../../assets/img/patient/indicators/warning_mark.svg';
import { PatientTableProps } from './patientTable';
import { usePatientTableColumns } from './patientTable.constants';
import Style from './patientTable.module.scss';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import TabsStyles from '../../components/tabs/tabs.module.css';
import { useModal } from 'src/application/hooks/useModal';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { TypographyStyles } from '../../styles';
import { createPagingParams, pagingSortingToBackendRequest } from '../../helpers/pagination-helper';
import { NumberParam, useQueryParams } from 'use-query-params';
import { trackEvent } from '../../application/analitics/matomo/matomo-tracker';
import { MetricActions } from '../../application/analitics/matomo/matomo-actions';
import { MetricCategories } from '../../application/analitics/matomo/matomo-categories';
import { QueryFactory } from '../../services/api';
import { SurveyAnswers } from 'src/containers/surveyAnswers/surveyAnswers.component';
import _ from 'lodash';
import { PatientFilters } from './patientFilters.component';
import { patientFilterParams, patientFilterToBackendRequest } from './patientFilters.helpers';
import { DropDownMenu } from 'uikit/dropDownMenu/dropDownMenu.component';
import { useContextSelector } from 'use-context-selector';
import { AccessDbContext } from '../../providers/accessDbProvider/accessDb.context';
import { LogExportModal } from '../expandedRow/exportPatientLog/logExportModal.component';
import { useHasPermissions } from '../../helpers/auth/auth-helper';
import { Permissions } from 'src/services/api/api-client';
import { DirectionalHint } from '@fluentui/react';
import { useQueryClient } from '@tanstack/react-query';
import { PatientTableContext, patientTableContextDefaultValue } from './PatientTableContext';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { AppTable } from 'uikit/table/AppTable';
import TablePageLayout from '../tablePageLayout/TablePageLayout';
import { useSortBy } from 'uikit/table/updateSortByInUrl';
import { AddPatientButton } from '../addPatientButton/addPatientButton';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';
import { IssuesContext } from '../issue/provider/issues.context';
import { IssueMark } from '../issue/issueTarget/issueMark.component';
import { useGetFormByIdQuery } from '../../services/api/api-client/FormsQuery';
import { StudyNameComponent } from '../studyName/studyName.component';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { conditionalConcat } from 'src/helpers/arrayHelpers';
import { DropDownMenuOption } from 'uikit/dropDownMenu/dropDownMenu';
import { useTranslation } from 'react-i18next';

export const patientTableQueryParamsConfig = (
  defaultGroup: number | null | undefined,
  defaultStudyState: PatientStudyStateEnum | null | undefined,
) => ({
  ...createPagingParams({
    defaultSortBy: 'category',
    sortingKeys: ['category', 'uniqueId', 'changedAt'] as const,
    defaultDesc: true,
    defaultPerPage: 30,
  }),
  studyId: NumberParam,
  ...patientFilterParams(defaultGroup, defaultStudyState),
});

export const PatientTable = (props: PatientTableProps) => {
  const { t } = useTranslation();
  const { t: settingsLocalizer } = useScopedTranslation('Dashboard.Settings');
  const { t: filtersLocalizer } = useScopedTranslation('Dashboard.PatientFilters');
  const { t: tableLocalizer } = useScopedTranslation('Dashboard.PatientTable');
  const { hasPermission } = useHasPermissions();
  const { hasDbImport, closedAt, hasEcrf } = useStudy() ?? {};

  const statisticsModal = useModal();
  const logExportModal = useModal();

  const accessDbFileName = useContextSelector(AccessDbContext, (x) => x.fileName);
  const importAction = useContextSelector(AccessDbContext, (x) => x.importAction)!;

  const setLoadedPatientsUid = useContextSelector(IssuesContext, (x) => x.setLoadedPatientsUid)!;

  const studySettingsMenuOptions = useMemo(
    () =>
      conditionalConcat(
        (hasPermission(Permissions.ExportForm) ||
          hasPermission(Permissions.ExportAuditLog) ||
          (hasEcrf && hasPermission(Permissions.ExportPatientEcrfReport))) && [
          {
            key: 1,
            icon: <Export />,
            text: settingsLocalizer('ExportAuditLogs'),
            action: logExportModal.openModal,
          },
        ],
        hasDbImport &&
          hasPermission(Permissions.AnalysisManage) && [
            {
              key: 0,
              icon: <Import />,
              text: settingsLocalizer('ImportDb'),
              action: importAction,
            },
          ],
      ) satisfies DropDownMenuOption[],
    [hasEcrf, hasDbImport, hasPermission, importAction, logExportModal.openModal, settingsLocalizer],
  );

  const [surveyType, setSurveyType] = useState<SurveyType | null>();
  const [answerId, setAnswerId] = useState<number | null>();
  const [patientId, setPatientId] = useState('');
  const [statisticsHeaderTitle, setStatisticsHeaderTitle] = useState('');

  const groups = props.groups;

  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useQueryParams(
    patientTableQueryParamsConfig(
      _.first(groups)?.id,
      closedAt ? PatientStudyStateEnum.Archived : PatientStudyStateEnum.InProgress,
    ),
  );

  const currentGroup = groups.find((x) => x.id === queryParams.patientGroup);

  const patients = QueryFactory.PatientQuery.useGetPatientsQuery(
    {
      searchQuery: queryParams.searchQuery,
      ...pagingSortingToBackendRequest(queryParams),

      studyId: props.studyId,
      ...patientFilterToBackendRequest(queryParams),
    },
    {
      suspense: false,
    },
  );

  useEffect(() => {
    setLoadedPatientsUid(patients.data?.data.map((p) => p.uniqueId));
  }, [patients.data?.data, setLoadedPatientsUid]);

  const patientCounters = QueryFactory.PatientQuery.useGetPatientStudyCountersQuery(props.studyId);

  const formConfigIdFormNotes = currentGroup?.formConfigIdForNotes ?? 0;
  const formConfigForNotes = useGetFormByIdQuery(formConfigIdFormNotes, {
    enabled: formConfigIdFormNotes !== 0,
  });

  const handleSurveyStatisticsClick = useCallback(
    (survey: SurveyType, patient: string, headerTitle: string, answId?: number | null) => {
      setSurveyType(survey);
      setPatientId(patient);
      setStatisticsHeaderTitle(headerTitle);
      setAnswerId(answId);
      statisticsModal.openModal();
    },
    [statisticsModal.openModal],
  );

  const handleCloseStatsModal = () => {
    statisticsModal.closeModal();
    setSurveyType(null);
  };

  const onTabChange = useCallback(
    (tabIndex: number) => {
      trackEvent({
        action: MetricActions.TabChanged,
        category: MetricCategories.Patient,
        name: groups[tabIndex].title,
      });

      queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientStudyCountersQueryKey(props.studyId));
      setQueryParams({ patientGroup: groups[tabIndex].id, page: 1 });
    },
    [groups, props.studyId, queryClient, setQueryParams],
  );

  const prevRenderTableVm = useRef({
    rows: { data: [], totalCount: 0 } as IPagedResultOfPatientListDto,
    tableContextValue: patientTableContextDefaultValue,
    group: groups.find((x) => x.id === queryParams.patientGroup) ?? null,
  });
  const tableVm = useMemo(() => {
    if (!patients.isInitialLoading && patients.data && !formConfigForNotes.isInitialLoading) {
      prevRenderTableVm.current = {
        rows: patients.data,
        tableContextValue: {
          formConfigForNotes: formConfigForNotes?.data ?? null,
          mainNoteFieldDataKey: currentGroup?.mainNoteFieldDataKey ?? null,
          noteDateDataKey: currentGroup?.noteDateDataKey ?? null,
        },
        group: groups.find((x) => x.id === queryParams.patientGroup) ?? null,
      };
    }
    return prevRenderTableVm.current;
  }, [
    currentGroup,
    formConfigForNotes?.data,
    formConfigForNotes.isInitialLoading,
    groups,
    patients.data,
    patients.isInitialLoading,
    queryParams.patientGroup,
  ]);

  const filters = patientFilterToBackendRequest(queryParams);
  const appliedFilters = useMemo(() => {
    const result: string[] = [];

    if (filters.needChecking !== undefined) {
      result.push(filtersLocalizer('NeedChecking'));
    }

    if (filters.isControlled !== undefined) {
      result.push(filtersLocalizer('IsControlled'));
    }

    if (filters.hasNoApp !== undefined) {
      result.push(filtersLocalizer('NoApp'));
    }

    if (filters.isClinic !== undefined) {
      result.push(filtersLocalizer('IsClinic'));
    }

    if (filters.studyStartNotDefine !== undefined) {
      result.push(filtersLocalizer('StudyStartNotDefine'));
    }

    return result;
  }, [filters, filtersLocalizer]);

  const sortBy = useSortBy(queryParams, setQueryParams);

  const concatenatedFilters = `${queryParams.searchQuery || ''}${
    appliedFilters.length > 0 ? `${queryParams.searchQuery ? ', ' : ''}${appliedFilters.join(', ')}` : ''
  }`;
  const placeholderText = concatenatedFilters
    ? tableLocalizer('EmptySearch.WithFilters', {
        params: concatenatedFilters,
      })
    : tableLocalizer('EmptySearch.WithoutFilters');

  const dataTable = useReactTable({
    data: tableVm.rows.data,
    manualSorting: true,
    enableSortingRemoval: false,
    onSortingChange: sortBy.onSortingChange,
    state: {
      sorting: sortBy.sortingState,
    },
    getRowId: (patient) => `${patient.uniqueId}-patient`,
    columns: usePatientTableColumns({
      onClick: handleSurveyStatisticsClick,
      tableColumns: tableVm.group?.tableColumns ?? 0,
      surveys: useMemo(
        () =>
          tableVm.group?.surveyColumns?.map((c) => props.surveys.find((s) => s.typeString === c)!).filter(Boolean) ??
          [],
        [props.surveys, tableVm.group?.surveyColumns],
      ),
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  // This effect expends the patient row which include the selected issue
  useEffect(() => {
    if (!queryParams.issueId) return;

    dataTable?.getRowModel()?.rows[0]?.toggleExpanded(true);
  }, [dataTable, queryParams.issueId]);

  const selectedIndex = useMemo(
    () => groups.findIndex((x) => x.id === queryParams.patientGroup),
    [groups, queryParams.patientGroup],
  );

  return (
    <TablePageLayout>
      <Tabs selectedIndex={selectedIndex} style={{ display: 'contents' }} onSelect={onTabChange}>
        <TablePageLayout.Header testId={'patient-table-header'}>
          <TablePageLayout.Header.Row className={Style.headerTop}>
            <div className={Style.titleText}>
              <IssueMark issueContext={{ subject: 'Study' }}>
                <div className={Style.titleWithOptions}>
                  <StudyNameComponent />
                  {props.hasTherapy && props.isSyncActive === false && (
                    <Tooltip
                      text={tableLocalizer('SyncFailed')}
                      hostStyles={Style.tooltipHost}
                      styles={Style.tooltipContent}
                    >
                      <WarningSign />
                    </Tooltip>
                  )}
                  <div className={Style.dbSettingsMenu}>
                    {!!studySettingsMenuOptions.length && (
                      <DropDownMenu
                        Icon={Settings}
                        options={studySettingsMenuOptions}
                        colorSchema={'primary'}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        testId={'study-settings-menu'}
                      />
                    )}
                    {accessDbFileName && (
                      <Tooltip text={accessDbFileName ?? ''} hostStyles={Style.dbConnectedTooltip}>
                        <div className={clsx(TypographyStyles.plainText12, Style.dbConnected)}>
                          {settingsLocalizer('DbIsConnected')}
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <div className={Style.dummyForIssueMark} />
                </div>
              </IssueMark>
            </div>

            {groups.length > 1 && (
              <TabList className={TabsStyles.tabList} data-test-id={'patient-table-tab-list'}>
                {groups.map((x) => {
                  return (
                    <Tab
                      key={x.id}
                      className={TabsStyles.tab}
                      selectedClassName={TabsStyles.tab_selected}
                      disabledClassName={TabsStyles.tab_disabled}
                    >
                      {x.title}
                    </Tab>
                  );
                })}
              </TabList>
            )}
          </TablePageLayout.Header.Row>

          <PatientFilters
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            hasSurveys={props.surveys.length > 0}
            hasTherapy={props.hasTherapy}
            patientCounters={patientCounters.data || []}
            studyId={props.studyId}
          />
        </TablePageLayout.Header>

        {/* Tabs component requires that count of tab panels is equal to count of tabs */}
        {groups.map((group) => (
          <TabPanel key={group.id} style={{ display: 'contents' }} />
        ))}

        <TablePageLayout.TableContainer loading={patients.isLoading}>
          {tableVm.group && (
            <PatientTableContext.Provider value={tableVm.tableContextValue}>
              <AppTable
                testId={'patients'}
                table={dataTable}
                expandedRow={(row) => <ExpandedPatientRow {...row.original} rowIndex={row.index} />}
                placeholder={{
                  hide: patients.isLoading,
                  text: placeholderText,
                }}
              />

              <TablePageLayout.TableContainer.Pagination
                page={queryParams.page}
                perPage={queryParams.perPage}
                totalCount={tableVm.rows.totalCount}
                changePagination={setQueryParams}
              />
              <PermissionsCheck permissions={Permissions.PatientCreate}>
                <AddPatientButton />
              </PermissionsCheck>
            </PatientTableContext.Provider>
          )}
        </TablePageLayout.TableContainer>
      </Tabs>

      <LogExportModal {...props} modal={logExportModal} modalTitle={t('LogExport.StudyExportModalTitle')} />

      {/* Following condition takes into account that 0 value of enum is interpreted as false by the js engine */}
      {(surveyType === SurveyType.MonthlySurvey || surveyType) && (
        <SurveyAnswers
          answerId={answerId}
          title={statisticsHeaderTitle}
          isVisible={statisticsModal.visible}
          onClose={handleCloseStatsModal}
          patientId={patientId}
        />
      )}
    </TablePageLayout>
  );
};
