import { useEditor, useNode } from '@craftjs/core';
import React, { useCallback, useMemo, useState } from 'react';
import {
  INumberInput,
  INumberInputProps,
  numberApplicableValidationRules,
  numberInputDefaultPropsFactory,
} from 'src/features/forms/base/controls/inputs/INumberInput';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { useContextSelector } from 'use-context-selector';
import {
  useFieldDataFromUiEditorContext,
  useRefForDataBlockNavigation,
  useFieldValidation,
  useSkipReasonText,
} from './base/hooks';
import { AppTextField } from 'uikit/fields';
import styles from '../controlsStyle.module.css';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import { useTranslation } from 'react-i18next';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';

export const NumberInput: INumberInput = (props) => {
  const {
    connectors,
    actions: { setProp: setProps },
    id,
  } = useNode();

  const { connect, drag } = connectors;
  const { size, dataKey, hasSkipCheckBox, isDisableWhenEditing, isDisabled, skipCheckBoxText } = props;

  const { enabled: isConstructorMode } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const {
    isOnlyView,
    isEditMode,
    singleInputValue,
    setDataBlockFieldValue,
    skipReason,
    onSkipReasonChange,
    fieldEditReason,
  } = useFieldDataFromUiEditorContext(dataKey);

  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const blockRef = useRefForDataBlockNavigation();
  const [initialValue] = useState(singleInputValue);
  const { dataBlockValidation, triggerFieldValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const isDisabledComponent = useMemo(
    () => !props.isEditable || (isDisableWhenEditing && isEditMode && !!initialValue) || isDisabled || isSubmitting,
    [initialValue, isDisableWhenEditing, isDisabled, isEditMode, isSubmitting, props.isEditable],
  );

  const valueChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (isConstructorMode) {
        setProps((p: INumberInputProps) => {
          p.defaultValue = e.target?.value;
        }, 500);
      } else {
        setDataBlockFieldValue?.(dataKey, e.target?.value);
      }
    },
    [dataKey, isConstructorMode, setDataBlockFieldValue, setProps],
  );

  const blurHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (isConstructorMode) {
        setProps((p: INumberInputProps) => {
          p.defaultValue =
            !!e.target?.value && props.decimal !== undefined
              ? Number(e.target?.value).toFixed(props.decimal)
              : e.target?.value;
        }, 500);
      } else {
        setDataBlockFieldValue?.(
          dataKey,
          !!e.target?.value && props.decimal !== undefined
            ? Number(e.target?.value).toFixed(props.decimal)
            : e.target?.value,
        );
        setTimeout(() => triggerFieldValidation(), 0);
      }
    },
    [dataKey, isConstructorMode, props.decimal, setDataBlockFieldValue, setProps, triggerFieldValidation],
  );

  const editableComponent = (
    <div
      data-test-field-type={'number'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef}>
        <AppTextField
          type={'number'}
          disabled={isDisabledComponent}
          placeholder={props.placeholder}
          maxLength={props.validation?.maxLength || undefined}
          value={isConstructorMode ? props.defaultValue || '' : singleInputValue || ''}
          onChange={valueChangeHandler}
          onBlur={blurHandler}
          units={props.units}
          labelProps={{
            text: !props.hideLabel ? props.label || dataKey : undefined,
            isBold: props.isBold,
            tooltip: props.tooltip,
          }}
          errorProps={{
            errors: dataBlockValidation?.text,
          }}
          skipProps={{
            inputCanBeSkipped: hasSkipCheckBox,
            skipText: skipCheckBoxText,
            onSkipReasonChange: onSkipReasonChange(dataKey),
            skipReason: skipReason,
          }}
        />
      </div>
    </div>
  );

  const notEditableComponent = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResultVersion?.formResultId,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
    >
      <OverviewField
        label={props.label ?? props.dataKey}
        skipped={!singleInputValue && skipReason !== undefined}
        skipReason={skipReasonText}
        value={
          singleInputValue
            ? `${props.decimal !== undefined ? Number(singleInputValue).toFixed(props.decimal) : singleInputValue} ${
                props.units ?? ''
              }`
            : ''
        }
        editReason={fieldEditReason}
      />
    </IssueMark>
  );

  return <>{!isOnlyView ? editableComponent : notEditableComponent}</>;
};

const NumberGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as INumberInputProps,
  }));

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.placeholder')}
        expanded={!!props.placeholder}
        collapsible
        flexible
        onOpen={() => {
          setProp((prop: INumberInputProps) => {
            prop.placeholder = 'Placeholder';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: INumberInputProps) => {
            prop.placeholder = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          multiple
          value={props.placeholder || ''}
          onChange={(e) => {
            setProp((setProps: INumberInputProps) => {
              setProps.placeholder = e.target?.value ? e.target.value : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>

      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.hasDecimal')}
        expanded={props.decimal !== undefined}
        collapsible
        flexible
        onOpen={() => {
          setProp((prop: INumberInputProps) => {
            prop.decimal = 0;
          }, 500);
        }}
        onClose={() => {
          setProp((prop: INumberInputProps) => {
            prop.decimal = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.decimal || ''}
          onChange={(e) => {
            setProp((setProps: INumberInputProps) => {
              setProps.decimal = e.target?.value ? Number(e.target.value) : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>

      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.units')}
        expanded={!!props.units}
        collapsible
        flexible
        onOpen={() => {
          setProp((prop: INumberInputProps) => {
            prop.units = 'units';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: INumberInputProps) => {
            prop.units = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          value={props.units || ''}
          onChange={(e) => {
            setProp((setProps: INumberInputProps) => {
              setProps.units = e.target?.value ? e.target.value : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const NumberInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <NumberGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={numberApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

NumberInput.craft = {
  defaultProps: numberInputDefaultPropsFactory(),
  related: {
    settings: NumberInputSettings,
  },
};
