import { IGroupDto, IPatientListDto } from 'src/services/api/api-client';
import { ColumnDef } from '@tanstack/react-table';
import { useStageColumn } from 'src/components/patientTable/columns/columns.constants';
import { useTranslation } from 'react-i18next';
import { buttonColumn } from 'uikit/table/columns/ButtonColumn';
import { ReactComponent as Chevron } from 'src/assets/img/patient/chevronPatientRow.svg';
import TableCommonStyles from 'uikit/table/appTableCommonStyles.module.scss';
import { dummyColumn } from 'uikit/table/columns/DummyColumn';

export const usePatientPipelinesColumns = (
  groups: IGroupDto[],
  handleRowClick: (patient: IPatientListDto) => void,
): ColumnDef<IPatientListDto>[] => {
  const { t } = useTranslation();
  const stageColumn = useStageColumn();

  return [
    {
      accessorKey: 'uniqueId',
      header: t('Dashboard.PatientTable.Column.Id'),
      meta: {
        headerTooltip: t('Dashboard.PatientTable.ColumnTooltip.Id'),
        cellClassName: TableCommonStyles.width120,
        centered: true,
        dontUseHeaderWidth: true,
      },
    },
    {
      id: 'group',
      size: 400,
      header: t('StudySettingsPage.Patients.Table.GroupHeader', { ns: 'dev' }),
      cell: ({ row }) => groups.find((x) => x.id === row.original.group)?.title || '',
      meta: {
        isColored: true,
      },
    },
    stageColumn!,
    dummyColumn(),
    buttonColumn({
      id: 'button',
      icon: Chevron,
      onClick: ({ row }) => handleRowClick(row.original),
    }),
  ];
};
