import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Style from './analysisView.module.css';
import _ from 'lodash';
import { useModal } from '../../../application/hooks/useModal';
import { IAnalysisDto } from '../../../services/api/api-client';
import { TypographyStyles } from '../../../styles';
import { AppButton } from 'uikit/buttons';
import { localFormat } from '../../../helpers/date-helpers';
import { ReactComponent as Delete } from '../../../assets/img/common/delete.svg';
import { useQueryClient } from '@tanstack/react-query';
import { logger } from '../../../application/logging/logging';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import i18n from 'i18next';
import { AppModalContainer } from 'uikit/modal/modal.component';
import {
  useGetAllPatientAnalyzesQuery,
  Client,
  getAllPatientAnalyzesQueryKey,
} from 'src/services/api/api-client/AnalyzesQuery';
import { ConfirmResult, useConfirmationModal } from 'src/components/dialogModal/useConfirmationModal';
import { OverviewNavbar, OverviewNavbarItemProps } from 'uikit/fields/overview/OverviewNavbar';
import { PermissionsCheck } from 'src/helpers/components/PermissionCheck/PermissionCheck.component';
import { Permissions } from 'src/services/api/api-client';

export type AnalysisViewProps = {
  title: string;
  patientId: string;
  presetId: number;
};

export const AnalysisViewComponent = (props: AnalysisViewProps) => {
  const { t } = useScopedTranslation('ExternalAnalysis.Presets');
  const commonLocalizer = useCommonLocalization();
  const queryClient = useQueryClient();
  const confirmationModal = useConfirmationModal();
  const modal = useModal();
  const { title, patientId, presetId } = props;
  const [currentDto, setCurrentDto] = useState<IAnalysisDto | undefined>();
  const [isRemoving, setIsRemoving] = useState(false);

  const { data: analyzes } = useGetAllPatientAnalyzesQuery(patientId, presetId);
  // This effect sets first analyzes DTO to view.
  useEffect(() => {
    setCurrentDto(_.first(analyzes));
  }, [analyzes]);

  const deleteHandler = useCallback(async () => {
    if (!currentDto?.id) {
      return;
    }

    const result = await confirmationModal.open({
      title: t('RemoveAnalysisModalHeader'),
      text: t('RemoveAnalysisModalText'),
      cancelButtonText: commonLocalizer('Common_Cancel'),
      okButtonText: commonLocalizer('Common_Delete'),
    });

    if (result !== ConfirmResult.Confirm) return;

    try {
      setIsRemoving(true);
      await Client.deleteAnalysis(currentDto?.id);
      await queryClient.invalidateQueries(getAllPatientAnalyzesQueryKey(patientId, presetId));

      setCurrentDto(_.first(analyzes));
      modal.closeModal();
    } catch (ex: any) {
      logger().error(`Remove analysis with id: ${currentDto?.id} is failed`, ex);
    } finally {
      setIsRemoving(false);
    }
  }, [analyzes, commonLocalizer, confirmationModal, currentDto?.id, modal, patientId, presetId, queryClient, t]);

  const navbarItems: OverviewNavbarItemProps[] = useMemo(() => {
    if (!currentDto || !analyzes || analyzes.length < 2) return [];

    return analyzes.map((analysis, i, arr) => ({
      key: analysis.id,
      selected: analysis.id === currentDto?.id,
      disabled: arr.length === 1,
      text: localFormat(analysis.createdAt),
      onClick: () => setCurrentDto(analysis),
    }));
  }, [analyzes, currentDto]);

  const header = useMemo(() => {
    return (
      <div className={Style.navContainer}>
        <OverviewNavbar items={navbarItems} />
        <div className={Style.createdByContainer}>
          <span className={clsx(Style.createdBy, TypographyStyles.plainText14)}>
            {t('Uploaded', {
              date: currentDto && localFormat(currentDto.createdAt),
              name: currentDto?.createdBy,
            })}
          </span>
          {currentDto?.id && (
            <PermissionsCheck permissions={Permissions.AnalysisManage}>
              <AppButton
                variant={'icon-link'}
                colorSchema={'decline'}
                text={commonLocalizer('Common_Delete')}
                loadingText={commonLocalizer('Common_Delete')}
                Icon={Delete}
                onClick={deleteHandler}
                disabled={isRemoving}
                isLoading={isRemoving}
              />
            </PermissionsCheck>
          )}
        </div>
      </div>
    );
  }, [navbarItems, t, currentDto, commonLocalizer, deleteHandler, isRemoving]);

  return (
    <div>
      {analyzes && analyzes?.length > 0 && (
        <AppButton
          onClick={() => modal.openModal()}
          variant={'icon-link'}
          colorSchema={'primary'}
          testId={'form-button'}
          text={t('View')}
        />
      )}

      <AppModalContainer
        title={title}
        visible={modal.visible}
        onHide={modal.closeModal}
        bodyClassName={Style.modalBody}
        isDisabled={isRemoving}
      >
        {header}
        <AnalysisDtoComponent dto={currentDto} />
      </AppModalContainer>
      {confirmationModal.modal}
    </div>
  );
};

const AnalysisDtoComponent: FC<{ dto: IAnalysisDto | undefined }> = ({ dto }) => {
  if (!dto) return null;

  return (
    <div>
      {dto.data &&
        Object.entries(dto.data).map(([key, value]) => (
          <div key={key} className={Style.previewRow}>
            <span className={TypographyStyles.heading2}>{key}</span>
            <span className={TypographyStyles.plainText14}>{value?.toString() ?? i18n.t('Common_dash')}</span>
          </div>
        ))}
    </div>
  );
};
