import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import { defaultToolSettings } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { createId } from 'src/helpers/typeUtils';
import {
  ApplicableRules,
  ValidationDefaultSettings,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { UserComponent } from '@craftjs/core';
import { TriggerComponentState } from '../../../../../components/uikit/wrappers/trigger/appInputTrigger.component';

export type ITextInputProps = IFormFieldProps & {
  /** @deprecated This prop was used as label text before and replaced by {@link IFormFieldProps.label label}.
   *  The prop was left for backward compatibility. */
  text?: string;
  /** @deprecated Use "Number field" instead */
  isNumberOnly?: boolean;
  /** @deprecated Use "Number field" instead */
  units?: string;
  isMultiline?: boolean;
  placeholder?: string;
  suggestions?: string[];
  isSkipped?: boolean;
  hasSkipCheckBox?: boolean;
} & (
    | {
        withTrigger: true;
        triggerControl?: string;
        triggerState?: TriggerComponentState;
        triggerType?: 'Medicine' | 'Other' | 'Comment' | 'Custom';
      }
    | {
        withTrigger?: false;
        triggerControl?: never;
        triggerState?: never;
        triggerType?: never;
      }
  );

export type ITextInput = UserComponent<ITextInputProps>;

export function textInputDefaultPropsFactory(): ITextInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    validation: { ...ValidationDefaultSettings },
  };
}

export const textApplicableValidationRules: ApplicableRules = {
  isRequired: true,
  maxLength: true,
  pattern: true,
};

export const numberApplicableValidationRules: ApplicableRules = {
  isRequired: true,
  maxValue: true,
  minValue: true,
  pattern: true,
};
