/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useCallback, useEffect, useMemo } from 'react';
import Style from './renderNode.module.scss';
import ReactDOM from 'react-dom';
import { useNode, useEditor } from '@craftjs/core';
import { ReactComponent as DragAndDrop } from 'assets/img/formBuilder/drag_and_drop_24.svg';
import { ReactComponent as Delete } from 'assets/img/formBuilder/delete_24.svg';
import { ReactComponent as Eye } from 'assets/img/formBuilder/eye_opened_24.svg';
import { ReactComponent as Save } from 'assets/img/formBuilder/save_24.svg';
import { ReactComponent as Copy } from 'assets/img/formBuilder/copy_24.svg';
import { getCloneTree } from '../../uiEditor/uiEditor-helper';
import { useAddToBufferModal } from '../bufferPanel/useAddToBufferModal';
import { AppButton } from 'uikit/buttons';
import { useTranslation } from 'react-i18next';

export const RenderNode = ({ render }: { render: any }) => {
  const addToBuffer = useAddToBufferModal();
  const { t } = useTranslation('dev');
  const { actions, query, isEditorMode, droppedNode } = useEditor((state) => ({
    nodes: state.nodes,
    isEditorMode: state.options.enabled,
    droppedNode: state.indicator?.placement?.parent.id,
  }));

  const {
    isActive,
    isHover,
    isDragged,
    dom,
    deletable,
    moveable,
    connectors: { drag },
    nodeId,
    parent,
    isCanvas,
    element,
  } = useNode((node) => ({
    isHover: node.events.hovered,
    isActive: node.events.selected,
    isDragged: node.events.dragged,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent!,
    dataKey: node.data.props.dataKey,
    nodeId: node.id,
    isCanvas: node.data.isCanvas,
    props: node.data.props,
    element: node.dom,
  }));

  useEffect(() => {
    if (!dom) {
      return;
    }

    if (droppedNode === nodeId) {
      dom.classList.add(Style.componentDropped);
    } else {
      dom.classList.remove(Style.componentDropped);
    }

    if (droppedNode && isCanvas) {
      dom.classList.add(Style.componentDroppable);
    } else {
      dom.classList.remove(Style.componentDroppable);
    }

    if (isActive) {
      dom.classList.add(Style.componentSelected);
    } else {
      dom.classList.remove(Style.componentSelected);
    }

    if (isHover) {
      dom.classList.add(Style.componentHovered);
    } else {
      dom.classList.remove(Style.componentHovered);
    }

    if (isDragged) {
      dom.classList.add(Style.componentDragged);
    } else {
      dom.classList.remove(Style.componentDragged);
    }
  }, [dom, isActive, isHover, isEditorMode, nodeId, isDragged, query, droppedNode, isCanvas]);

  const addToBufferHandler = useCallback(() => {
    const tree = query.node(nodeId).toNodeTree();
    const node = query.node(nodeId).get().data;
    const nodePairs = Object.keys(tree.nodes).reduce(
      (acc, key) => ({
        ...acc,
        [key]: query.node(key).toSerializedNode(),
      }),
      {},
    );

    const serializedNodesJSON = JSON.stringify(nodePairs);
    const saveData = {
      rootNodeId: tree.rootNodeId,
      nodes: serializedNodesJSON,
    };

    addToBuffer.start({
      name: node.name,
      nodeTree: JSON.stringify(saveData),
      resolvedName: node.name,
    });
  }, [addToBuffer, nodeId, query]);

  const handleClone = useCallback(
    (i: string) => () => {
      const parentNode = query.node(parent).get();
      const indexToAdd = parentNode.data.nodes.indexOf(i);
      const tree = getCloneTree(query, i);

      actions.addNodeTree(tree, parentNode.id, indexToAdd + 1);
    },
    [actions, parent, query],
  );

  const tools = useMemo(() => {
    if ((isHover || isActive) && deletable && moveable) {
      return ReactDOM.createPortal(
        <div className={Style.toolsContainer}>
          <div className={Style.buttonsWrapper}>
            <AppButton
              variant={'icon-link'}
              colorSchema={'basic'}
              Icon={Delete}
              onClick={() => actions.delete(nodeId)}
            />
            <AppButton variant={'icon-link'} colorSchema={'basic'} Icon={Save} onClick={addToBufferHandler} />
            <AppButton variant={'icon-link'} colorSchema={'basic'} Icon={Copy} onClick={handleClone(nodeId)} />

            <AppButton
              variant={'icon-link'}
              colorSchema={'basic'}
              Icon={Eye}
              onClick={() => actions.setHidden(nodeId, true)}
            />
            <AppButton
              // @ts-ignore
              ref={drag}
              variant={'icon-link'}
              colorSchema={'basic'}
              Icon={DragAndDrop}
              iconClassName={Style.cursorGrab}
            />
          </div>
        </div>,
        element!,
      );
    }

    return <></>;
  }, [actions, addToBufferHandler, deletable, drag, element, handleClone, isActive, isHover, moveable, nodeId]);

  return (
    <>
      {tools}
      {render}
      {addToBuffer.modal}
    </>
  );
};
