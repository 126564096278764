import React, { useState } from 'react';
import { Tooltip } from '../../tooltip/tooltip.component';
import { DirectionalHint } from '@fluentui/react';
import Style from './appDropDownWithSuggestion.module.scss';
import { DropdownOption } from './appDropdownInput';

const isEllipsisActive = (e: HTMLElement | null) => {
  return e ? e.offsetWidth < e.scrollWidth : false;
};

type DropdownOptionComponentProps<D extends DropdownOption> = {
  item: D;
};

export const DropdownOptionComponent = <D extends DropdownOption>({ item }: DropdownOptionComponentProps<D>) => {
  const [isEllipsis, setIsEllipsis] = useState(false);

  return (
    <Tooltip
      text={item.text || ''}
      styles={'d-contents'}
      calloutContainerStyles={Style.tooltip}
      textStyles={Style.tooltipText}
      disabled={!isEllipsis}
      hostStyles={Style.tooltipHost}
      directionalHint={DirectionalHint.rightCenter}
    >
      <div key={item.key} className={Style.optionContent}>
        {item.icon}
        <p ref={(x) => setIsEllipsis(isEllipsisActive(x))}>{item.text}</p>
      </div>
    </Tooltip>
  );
};
