import React from 'react';
import { IFieldGroupContainer } from '../../../../forms/base/controls/containers/FieldGroupContainer';
import { FieldGroup } from '../../components/FieldGroup';

export const FieldGroupContainer: IFieldGroupContainer = (props) => {
  return (
    <FieldGroup title={props.title ?? ''} hideTitle={props.hideTitle}>
      {props.children}
    </FieldGroup>
  );
};
