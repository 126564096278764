import React, { useMemo } from 'react';
import { useSkipReasonText } from 'src/components/formEditor/controls/Inputs/base/hooks';
import { useContextSelector } from 'use-context-selector';
import { ITextInput } from '../../../../forms/base/controls/inputs/TextInput';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const TextInput: ITextInput = ({ label, text, dataKey, units, skipCheckBoxText }) => {
  const inputValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  const skipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.[dataKey]);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const answerValue = useMemo(() => {
    if (skipReason !== undefined) {
      return skipReasonText;
    }

    return singleInputValue ? `${singleInputValue} ${units ?? ''}` : null;
  }, [singleInputValue, skipReason, skipReasonText, units]);

  return <Field label={label ?? text ?? dataKey} value={answerValue} />;
};
