import clsx from 'clsx';
import { QualityMetric } from '../services/api/api-client';
import Style from '../components/indicators/baseIndicator.module.css';
import QualityScoreStyle from '../styles/qualityScoreStyles.module.css';

export const getIndicatorStyleColor = (qualityMetric?: QualityMetric | null) => {
  return clsx({
    [Style.empty]: qualityMetric === QualityMetric.Empty || !qualityMetric,
    [Style.awful]: qualityMetric === QualityMetric.Awful,
    [Style.bad]: qualityMetric === QualityMetric.Bad,
    [Style.normal]: qualityMetric === QualityMetric.Normal,
    [Style.good]: qualityMetric === QualityMetric.Good,
    [Style.awesome]: qualityMetric === QualityMetric.Awesome,
    [Style.notCalculated]: qualityMetric === QualityMetric.NotCalculated,
  });
};

export const getScoreStyleColor = (qualityMetric?: QualityMetric | null) => {
  return clsx({
    [QualityScoreStyle.empty]: qualityMetric === QualityMetric.Empty || !qualityMetric,
    [QualityScoreStyle.awful]: qualityMetric === QualityMetric.Awful,
    [QualityScoreStyle.bad]: qualityMetric === QualityMetric.Bad,
    [QualityScoreStyle.normal]: qualityMetric === QualityMetric.Normal,
    [QualityScoreStyle.good]: qualityMetric === QualityMetric.Good,
    [QualityScoreStyle.awesome]: qualityMetric === QualityMetric.Awesome,
  });
};
