import clsx from 'clsx';
import React, { FC, PropsWithChildren, useState } from 'react';
import Style from './recordRow.module.scss';
import { AppButton } from 'uikit/buttons';

import { ReactComponent as Chevron } from 'src/assets/img/common/chevron.svg';
import { Collapse } from 'uikit/collapse/Collapse';

type RecordsGroup = {
  title: string;
  isExpandDefault: boolean;
};

export const RecordsGroup: FC<PropsWithChildren<RecordsGroup>> = ({ isExpandDefault, title, children }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isExpandDefault);

  return (
    <div className={Style.container}>
      <div className={Style.titleContainer}>
        <p>{title}</p>
        <AppButton
          colorSchema={'primary'}
          variant={'icon-link'}
          iconClassName={clsx({ [Style.chevronExpanded]: !isExpanded })}
          Icon={Chevron}
          onClick={() => setIsExpanded((state) => !state)}
        />
      </div>
      <Collapse in={isExpanded} unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};
