import { defaultToolSettings } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { createId } from 'src/helpers/typeUtils';
import { IFormFieldWithOptionsProps } from 'src/features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import {
  ApplicableRules,
  ValidationDefaultSettings,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { UserComponent } from '@craftjs/core';

export interface ICheckBoxGroupInputProps extends IFormFieldWithOptionsProps {}

export type ICheckBoxGroupInput = UserComponent<ICheckBoxGroupInputProps>;

export function checkBoxGroupInputDefaultPropsFactory(): ICheckBoxGroupInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    options: [
      { displayValue: 'Option 1', value: createId(), key: 0 },
      { displayValue: 'Option 2', value: createId(), key: 1 },
    ],
    validation: { ...ValidationDefaultSettings },
  };
}

export const checkBoxGroupApplicableValidationRules: ApplicableRules = {
  isRequired: true,
};
