import React, { useState } from 'react';
import { TypographyStyles } from '../../../styles';
import Style from './expandedComponent.module.css';
import { ReactComponent as Chevron } from '../../../assets/img/common/chevron.svg';
import clsx, { ClassValue } from 'clsx';

export const ExpandedComponent = (props: {
  isExpandedAtStart?: boolean;
  children: React.ReactNode;
  header: string;
  headerStyles?: ClassValue;
  containerStyles?: string;
  rightHeaderComponent?: React.ReactNode;
}) => {
  const { children, header, headerStyles, rightHeaderComponent } = props;
  const [isExpanded, setExpand] = useState<boolean>(props.isExpandedAtStart ?? true);

  return (
    <div>
      <div className={Style.header}>
        <div className={clsx(TypographyStyles.buttonText, headerStyles)}>{header}</div>
        <div className={Style.chevron} onClick={() => setExpand((x) => !x)}>
          <Chevron className={clsx({ [Style.isExpanded]: !isExpanded })} />
        </div>
        <div className={Style.rightHeaderComponent}>{rightHeaderComponent}</div>
      </div>
      <div className={clsx(Style.expandedContainer, props.containerStyles)} data-expand={isExpanded}>
        {children}
      </div>
    </div>
  );
};
