import React from 'react';
import { createReport } from './report-helpers';
import { IssueReportOptions } from './issues/issueReport';
import { IssueReportTemplate } from './issues/issueReport.layout';
import { MonitoringFormsReportOptions } from './monitoringForms/monitoringFormsReport.options';
import { MonitoringFormsReportLayout } from './monitoringForms/monitoringFormsReport.layout';

export const generateIssueReport = (options: IssueReportOptions): Promise<Blob> => {
  return createReport(<IssueReportTemplate {...options} />);
};

export const generateMonitoringFormsReport = (options: MonitoringFormsReportOptions) => {
  return createReport(<MonitoringFormsReportLayout {...options} />);
};
