/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import clsx from 'clsx';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import { useTranslation } from 'react-i18next';
import styles from '../controlsStyle.module.css';
import { LocalizedResourceDictionaryKeys } from '../../../../application/localisation/i18next';
import {
  checkBoxInputDefaultPropsFactory,
  checkBoxApplicableValidationRules,
  ICheckBoxInputProps,
  ICheckBoxInput,
} from '../../../../features/forms/base/controls/inputs/CheckBoxInput';
import {
  useRefForDataBlockNavigation,
  useDefaultValuesForConstructorMode,
  useFieldDataFromUiEditorContext,
  useFieldValidation,
} from './base/hooks';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { AppInputError } from 'uikit/wrappers';
import { AppCheckboxInput } from 'uikit/inputs';
import { AppSwitcherInput } from 'uikit/inputs';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';

export const CheckBoxInput: ICheckBoxInput = (props) => {
  const {
    connectors: { connect, drag },
    id,
    actions: { setProp },
  } = useNode();
  const { t } = useTranslation();
  const { size, dataKey, isDisabled } = props;
  const { singleDefaultValue } = useDefaultValuesForConstructorMode(props);

  const { enabled: isConstructorMode } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const { isOnlyView, inputValue, singleInputValue, setDataBlockFieldValue, fieldEditReason } =
    useFieldDataFromUiEditorContext(dataKey);

  const blockRef = useRefForDataBlockNavigation();
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const setValue = useCallback(
    (value: boolean) => {
      setProp((setProps: ICheckBoxInputProps) => {
        if (isConstructorMode) {
          setProps.defaultValue = value.toString();
        } else {
          setDataBlockFieldValue?.(dataKey, value.toString());
        }
      });
    },
    [setProp, isConstructorMode, setDataBlockFieldValue],
  );

  const editableComponent = (
    <div
      data-test-field-type={'checkbox-input'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef} className={clsx({ [styles.validationError]: !!dataBlockValidation?.text })}>
        <AppInputError errors={dataBlockValidation?.text}>
          {props.asSwitcher ? (
            <AppSwitcherInput
              className={styles.switcher}
              value={singleInputValue}
              checked={isConstructorMode ? singleDefaultValue === 'true' : singleInputValue === 'true'}
              label={props.label ?? dataKey}
              onChange={(e) => setValue(e.target.checked)}
              disabled={!props.isEditable || isOnlyView || isDisabled || isSubmitting}
            />
          ) : (
            <AppCheckboxInput
              value={inputValue}
              checked={isConstructorMode ? singleDefaultValue === 'true' : singleInputValue === 'true'}
              label={props.label ?? dataKey}
              disabled={!props.isEditable || isOnlyView || isDisabled || isSubmitting}
              onChange={(e) => setValue(e.target.checked)}
            />
          )}
        </AppInputError>
      </div>
    </div>
  );

  const viewComponent = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResultVersion?.formResultId,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
    >
      <OverviewField
        label={props.label ?? props.dataKey}
        value={t(`Forms.Controls.checkBox_${inputValue}` as LocalizedResourceDictionaryKeys)}
        editReason={fieldEditReason}
      />
    </IssueMark>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

const CheckBoxInputGeneralSettings = () => {
  const { actions, props } = useNode((node) => ({
    props: node.data.props as ICheckBoxInputProps,
  }));
  const { setProp } = actions;
  const { t } = useTranslation('dev');

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.label')}
        expanded={!!props.label}
        collapsible
        flexible
        onOpen={() => {
          setProp((prop: ICheckBoxInputProps) => {
            prop.label = prop.dataKey;
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ICheckBoxInputProps) => {
            prop.label = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          value={props.label || ''}
          onChange={(e) => {
            setProp((setProps: ICheckBoxInputProps) => {
              setProps.label = e.target?.value ? e.target.value : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.asSwitcher')}
          isChecked={!!props.asSwitcher}
          setValue={(newValue) =>
            setProp((setProps: ICheckBoxInputProps) => {
              setProps.asSwitcher = newValue ? newValue : undefined;
            })
          }
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const CheckBoxInputSettings = () => {
  return (
    <>
      <CheckBoxInputGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={checkBoxApplicableValidationRules} />
      <CommonFieldSettings labelRequired />
    </>
  );
};

CheckBoxInput.craft = {
  props: checkBoxInputDefaultPropsFactory(),
  related: {
    settings: CheckBoxInputSettings,
  },
};
