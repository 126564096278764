﻿import { IFormResultVersionDto, IRouteProgressDto } from 'src/services/api/api-client';
import { getPatientRouteProgress } from 'src/services/api/api-client/StudyRoutesClient';
import _ from 'lodash';
import { DataType } from 'src/components/formEditor/uiEditor/uiEditor';
import { getFormResults } from 'src/services/api/api-client/FormsClient';

export type InitialAnswersType = Pick<IFormResultVersionDto, 'value' | 'fieldSkipReasons'>;

const getAnswersFromPrevFormResult = async (
  stepName: string,
  formConfigIdToFill: number,
  routeProgress: IRouteProgressDto,
  patientId: string,
): Promise<DataType | undefined | null> => {
  routeProgress ??= await getPatientRouteProgress(patientId);

  const forms = routeProgress.steps
    .find((x) => x.name === stepName)
    ?.forms?.find((f) => f.formConfigId === formConfigIdToFill);
  const formConfigIdForInitialAnswers = forms?.formConfigIdForInitialAnswers;

  if (formConfigIdForInitialAnswers) {
    const { data } = await getFormResults(formConfigIdForInitialAnswers, undefined, undefined, patientId);
    // Exclude own results if record is already filled and revoked
    const results = data.filter((x) => x.id !== forms.formResultId);

    if (results.length) {
      // TODO: think about how to avoid. Initials answers does not mean that it is real answer.
      const lastResultVersion = _.last(results[0].versions)!;
      return lastResultVersion.value;
    }

    // NOTE: If there are no answers, check the previous step.
    const currentStepIndex = routeProgress.steps.findIndex((x) => x.name === stepName);
    const prevStepName = routeProgress.steps[currentStepIndex - 1].name;
    return getAnswersFromPrevFormResult(prevStepName, formConfigIdForInitialAnswers, routeProgress, patientId);
  }

  return undefined;
};

export default getAnswersFromPrevFormResult;
