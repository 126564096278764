import { IFormControlsResolver } from 'src/features/forms/base/controls/IFormControlsResolver';
import { FunctionComponent, SVGProps } from 'react';
import i18next from 'i18next';
import { ReactComponent as ContainerIcon } from 'assets/img/formBuilder/container_24.svg';
import { ReactComponent as TextIcon } from 'assets/img/formBuilder/text_24.svg';
import { ReactComponent as TextInputIcon } from 'assets/img/formBuilder/multiple_line_24.svg';
import { ReactComponent as NumberInputIcon } from 'assets/img/formBuilder/number_24.svg';
import { ReactComponent as SliderInputIcon } from 'assets/img/formBuilder/slider_24.svg';
import { ReactComponent as DateInputIcon } from 'assets/img/formBuilder/calendar_24.svg';
import { ReactComponent as DateRangeInputIcon } from 'assets/img/formBuilder/calendar_range_24.svg';
import { ReactComponent as TimeInputIcon } from 'assets/img/formBuilder/clock_24.svg';
import { ReactComponent as CheckBoxInputIcon } from 'assets/img/formBuilder/checkbox_24.svg';
import { ReactComponent as RadioInputIcon } from 'assets/img/formBuilder/radio_24.svg';
import { ReactComponent as DropdownInputIcon } from 'assets/img/formBuilder/dropdown_24.svg';
import { ReactComponent as FileInputIcon } from 'assets/img/formBuilder/file_24.svg';
import { ReactComponent as ImageInputIcon } from 'assets/img/formBuilder/pic_24.svg';
import { ReactComponent as TagInputIcon } from 'assets/img/formBuilder/tag_box_24.svg';
import { ReactComponent as FormulaIcon } from 'assets/img/formBuilder/formula_24.svg';
import { ReactComponent as EmptyBlockIcon } from 'assets/img/formBuilder/empty_block_24.svg';
import { ReactComponent as AttentionTextIcon } from 'assets/img/formBuilder/attention_text_24.svg';
import { ReactComponent as StepIcon } from 'assets/img/formBuilder/step_24.svg';
import { ReactComponent as TabsIcon } from 'assets/img/formBuilder/tabs_24.svg';

type SelectorResolverType = {
  [K in keyof IFormControlsResolver]: {
    Icon: FunctionComponent<SVGProps<any>>;
    name: string;
  };
};

export const SelectorResolver = (): SelectorResolverType => {
  return {
    EmptyBlock: {
      Icon: EmptyBlockIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.empty'),
    },
    FieldGroupContainer: {
      Icon: ContainerIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fieldGroupContainer'),
    },
    Container: {
      Icon: ContainerIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.container'),
    },
    Text: {
      Icon: TextIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.contentBlocks.text.label'),
    },
    AttentionText: {
      Icon: AttentionTextIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.contentBlocks.attentionText.label'),
    },
    TextInput: {
      Icon: TextInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.text.label'),
    },
    NumberInput: {
      Icon: NumberInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.number.label'),
    },
    SliderInput: {
      Icon: SliderInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.slider.label'),
    },
    DateInput: {
      Icon: DateInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.date.label'),
    },
    TimeInput: {
      Icon: TimeInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.time.label'),
    },
    CheckBoxInput: {
      Icon: CheckBoxInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.checkBox.label'),
    },
    CheckBoxGroupInput: {
      Icon: CheckBoxInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.checkBoxGroup.label'),
    },
    RadioInput: {
      Icon: RadioInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.radioGroup.label'),
    },
    SelectInput: {
      Icon: DropdownInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.dropDown.label'),
    },
    Files: {
      Icon: FileInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.fileField.label'),
    },
    StepContainer: {
      Icon: StepIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.stepContainer'),
    },
    TabsContainer: {
      Icon: TabsIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.tabsContainer'),
    },
    DateRangeInput: {
      Icon: DateRangeInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.dateRange.label'),
    },
    BmiInput: {
      Icon: FormulaIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.constFields.bmi.label'),
    },
    CalculatedBlock: {
      Icon: ContainerIcon,
      name: '',
    },
    GroupInput: {
      Icon: ContainerIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.constFields.group.label'),
    },
    ImageBlock: {
      Icon: ImageInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.contentBlocks.image.label'),
    },
    TagInput: {
      Icon: TagInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.fields.tagInput.label'),
    },
    RecordResultList: {
      Icon: TagInputIcon,
      name: i18next.t('dev:StudySettingsPage.FormBuilder.blocks.constFields.recordResultList.label'),
    },
  };
};
