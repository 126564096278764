﻿import React, { FC, useMemo } from 'react';
import { FieldSkipReason, IMonitoringFormDto } from '../../../services/api/api-client';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { RPDF } from '../react-pdf';
import i18n from 'i18next';
import { ReportFirstPage } from '../components/ReportFirstPage';
import { InfoField, InfoFieldsColumn } from '../components/reportFields';
import { DateFormats, localFormat, toDateOrNull } from '../../../helpers/date-helpers';
import { ReportHeader } from '../components/ReportHeader';
import { FormHeader } from '../records/components/FormHeader';
import { ReportPagination } from '../components/ReportPagination';
import { MonitoringFormsReportOptions } from './monitoringFormsReport.options';
import {
  useConsentFormsReviewedOptions,
  useMonitoringFormTypeOptions,
  useSdvOptions,
  useStudyDocumentsOptions,
  useYesNoOptions,
} from '../../monitoring/monitoringForms/fieldOptions';
import { MonitoringFormValue } from '../../monitoring/monitoringForms/MonitoringFormValue';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { Field } from '../records/components/Field';
import { FieldGroup } from '../records/components/FieldGroup';
import { ReportColors } from '../report-colors';
import { RepeatableFormSection } from '../records/components/RepeatableFormSection';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';

export const MonitoringFormsReportLayout: FC<MonitoringFormsReportOptions> = (props) => {
  const { t } = useScopedTranslation('Reports.MonitoringReport');

  const orderedMonitoringForms = useMemo(() => {
    const arrayCopy = [...props.forms];
    arrayCopy.reverse();

    return arrayCopy;
  }, [props.forms]);

  const singleFormType = props.forms.every((x) => x.type === props.forms[0].type);
  const pagesTitle = singleFormType ? props.forms[0].type : t('DocumentTitle');

  return (
    <RPDF.Document
      language={i18n.language}
      producer={'LM Study'}
      title={pagesTitle}
      author={props.authorFullName}
      subject={props.studyNumber}
    >
      <FirstPage
        title={pagesTitle}
        formsTotal={props.forms.length}
        studyName={props.studyName}
        studyNumber={props.studyNumber}
        authorFullName={props.authorFullName}
      />
      {orderedMonitoringForms.map((x, i) => (
        <FormPage
          key={x.id}
          form={x}
          index={i}
          title={pagesTitle}
          singleFormType={singleFormType}
          participants={props.participants}
        />
      ))}
    </RPDF.Document>
  );
};

const FirstPage: FC<{
  title: string;
  formsTotal: number;
  studyNumber: string;
  studyName: string;
  authorFullName: string;
}> = (props) => {
  const { t } = useScopedTranslation('Reports');

  return (
    <ReportFirstPage
      headerText={props.title}
      patientUid={undefined}
      HeaderComponent={(pages) => (
        <>
          <InfoFieldsColumn>
            <InfoField
              header={t('Records.FirstPage.CreatedAt')}
              value={localFormat(new Date(), DateFormats.dateTime)}
            />
            <InfoField header={t('Records.FirstPage.CreatedBy')} value={props.authorFullName} />
            <InfoField header={t('Records.FirstPage.Study')} value={`${props.studyName} (${props.studyNumber})`} />
          </InfoFieldsColumn>
          <InfoFieldsColumn>
            <InfoField header={t('Records.FirstPage.PageCount')} value={pages} />
            <InfoField header={t('MonitoringReport.FirstPage.FormsCount')} value={props.formsTotal.toString()} />
          </InfoFieldsColumn>
        </>
      )}
    />
  );
};

const FormPage: FC<{
  title: string;
  singleFormType: boolean;
  form: IMonitoringFormDto;
  index: number;
  participants: DropdownOption<string>[];
}> = ({ form, title, singleFormType, index, participants }) => {
  const { t } = useScopedTranslation('Reports');

  const reportWasEdited = form.createdAt.getTime() !== form.updatedAt.getTime();
  const lastEventText = reportWasEdited
    ? t('Records.Updated', {
        date: localFormat(form.updatedAt, DateFormats.longDate),
        name: form.filledBy.fullName,
      })
    : t('Records.Filled', {
        date: localFormat(form.createdAt, DateFormats.longDate),
        name: form.filledBy.fullName,
      });

  const gap = ' ';

  return (
    <RPDF.Page size={'A4'} style={RPDF.styles.page}>
      <ReportHeader titleText={title} />
      <FormHeader
        title={singleFormType ? undefined : form.type}
        subTitle={`#${index + 1}`}
        formState={<RPDF.Text>{lastEventText}</RPDF.Text>}
      />
      <RPDF.Text
        fixed={true}
        style={{ marginBottom: 12, color: ReportColors.plain }}
        render={({ subPageNumber }) =>
          subPageNumber > 1 && (
            <>
              <RPDF.Text style={RPDF.typography.heading4}>{form.type}</RPDF.Text>
              {gap}
              <RPDF.Text style={RPDF.typography.text10}>{lastEventText}</RPDF.Text>
            </>
          )
        }
      />
      <FormBody form={form} participants={participants} />
      <ReportPagination />
    </RPDF.Page>
  );
};

const FormBody: FC<{ form: IMonitoringFormDto; participants: DropdownOption<string>[] }> = ({ form, participants }) => {
  const { t } = useScopedTranslation('MonitoringForms');

  const currentReportValues = form.value as MonitoringFormValue;

  const yesNoOptions = useYesNoOptions();
  const studyDocumentsOptions = useStudyDocumentsOptions();
  const monitoringFormTypeOptions = useMonitoringFormTypeOptions();
  const sdvOptions = useSdvOptions();
  const consentFormsReviewedOptions = useConsentFormsReviewedOptions();

  const getYesNoLabel = (value: string | undefined) => yesNoOptions.find((x) => x.value === value)?.label;
  const getStudyDocumentsLabel = (key: string | undefined) => studyDocumentsOptions.find((x) => x.key === key)?.text;
  const getPersonNameLabel = (key: string | undefined) => participants.find((x) => key?.includes(x.key))?.text ?? '';
  const getMonitoringFormTypeLabel = (value: string | undefined) =>
    monitoringFormTypeOptions.find((x) => x.value === value)?.label;
  const getSdvOptionsLabel = (value: string | undefined) => sdvOptions.find((x) => x.value === value)?.label;
  const getConsentFormsReviewedLabel = (key: string | undefined) =>
    consentFormsReviewedOptions.find((x) => x.key === key)?.text;

  const { t: controlsTranslation } = useScopedTranslation('Forms.Controls');
  const getSkipReasonProps = (path: string) => {
    const reason = form.fieldSkipReasons[path];

    if (reason === undefined) return undefined;

    const translation =
      reason === FieldSkipReason.NoReason
        ? controlsTranslation('SkipQuestionCheckBox')
        : controlsTranslation(`SkipQuestionReason.${FieldSkipReason[reason!]}` as LocalizedResourceDictionaryKeys);

    return { skipReason: translation as string };
  };

  return (
    <>
      <FieldGroup title={t('Fields.GeneralInformation.Title')}>
        <Field label={t('Fields.GeneralInformation.TestingCenter.Label')} value={currentReportValues.testingCenter} />
        <Field
          label={t('Fields.GeneralInformation.DateOfVisit.Label')}
          value={toDateOrNull(currentReportValues.dateOfVisit)}
        />
        <Field
          label={t('Fields.GeneralInformation.PersonNames.Label')}
          value={currentReportValues.personNames?.map((x) => getPersonNameLabel(x))}
        />
        <Field
          label={t('Fields.GeneralInformation.EnoughTimeToHoldMeeting.Label')}
          value={getYesNoLabel(currentReportValues.enoughTimeToHoldMeeting)}
        />
        <Field
          label={t('Fields.GeneralInformation.GeneralComment.Label')}
          value={currentReportValues.generalComment}
          {...getSkipReasonProps('generalComment')}
        />
      </FieldGroup>
      <FieldGroup title={t('Fields.StudyStaff.Title')}>
        <Field
          label={t('Fields.StudyStaff.DelegationLogFilledInOrSupplemented.Label')}
          value={getYesNoLabel(currentReportValues.delegationLogFilledInOrSupplemented)}
          {...getSkipReasonProps('delegationLogFilledInOrSupplemented')}
        />
        {currentReportValues.delegationLogFilledInOrSupplemented_comment && (
          <Field
            label={t('Fields.Comment.Label')}
            value={currentReportValues.delegationLogFilledInOrSupplemented_comment}
          />
        )}
      </FieldGroup>
      <FieldGroup title={t('Fields.TypeOfMonitoringVisit.Label')}>
        <Field
          label={t('Fields.TypeOfMonitoringVisit.Label')}
          value={getMonitoringFormTypeLabel(currentReportValues.typeOfMonitoringVisit)}
        />
      </FieldGroup>
      {currentReportValues.typeOfMonitoringVisit !== undefined && (
        <>
          <FieldGroup title={t('Fields.Infrastructure.Title')}>
            {(currentReportValues.typeOfMonitoringVisit === 'Routine' ||
              currentReportValues.typeOfMonitoringVisit === 'Final') && (
              <>
                <Field
                  label={t('Fields.SpatialChangesAtTestCenter.Label')}
                  value={getYesNoLabel(currentReportValues.spatialChangesAtTestCenter)}
                  {...getSkipReasonProps('spatialChangesAtTestCenter')}
                />
                {currentReportValues.spatialChangesAtTestCenter_comment && (
                  <Field
                    label={t('Fields.Comment.Label')}
                    value={currentReportValues.spatialChangesAtTestCenter_comment}
                  />
                )}
              </>
            )}
            <Field
              label={t('Fields.Infrastructure.TechnicalProblemsAtFacilityAffectingStudy.Label')}
              value={getYesNoLabel(currentReportValues.technicalProblemsAtFacilityAffectingStudy)}
              {...getSkipReasonProps('technicalProblemsAtFacilityAffectingStudy')}
            />
            {currentReportValues.technicalProblemsAtFacilityAffectingStudy_comment && (
              <Field
                label={t('Fields.Comment.Label')}
                value={currentReportValues.technicalProblemsAtFacilityAffectingStudy_comment}
              />
            )}
            <Field
              label={t('Fields.Infrastructure.ProperStorageOfStudyDocuments.Label')}
              value={getYesNoLabel(currentReportValues.properStorageOfStudyDocuments)}
              {...getSkipReasonProps('properStorageOfStudyDocuments')}
            />
            {currentReportValues.properStorageOfStudyDocuments_comment && (
              <Field
                label={t('Fields.Comment.Label')}
                value={currentReportValues.properStorageOfStudyDocuments_comment}
              />
            )}
          </FieldGroup>

          <FieldGroup title={t('Fields.StudyDocumentation.Title')}>
            <Field
              label={
                currentReportValues.typeOfMonitoringVisit === 'Initiate'
                  ? t('Fields.StudyDocumentation.GivenStudyDocuments.Label')
                  : t('Fields.StudyDocumentation.ChangingStudyDocuments.Label')
              }
              value={getYesNoLabel(currentReportValues.givenStudyDocuments)}
              {...getSkipReasonProps('givenStudyDocuments')}
            />
            {currentReportValues.givenStudyDocuments_comment && (
              <Field label={t('Fields.Comment.Label')} value={currentReportValues.givenStudyDocuments_comment} />
            )}
            {currentReportValues.givenStudyDocuments === 'true' && (
              <>
                <Field
                  label={t('Fields.StudyDocumentation.StudyDocuments.Label')}
                  value={currentReportValues.studyDocuments?.map((x) => getStudyDocumentsLabel(x)!).filter((x) => x)}
                />
                {currentReportValues.studyDocuments?.includes('TestPlan') && (
                  <Field
                    label={t('Fields.StudyDocumentation.TestPlanVersion.Label')}
                    value={currentReportValues.testPlanVersion}
                  />
                )}
                {currentReportValues.studyDocuments?.includes('PatientInformationAndConsent') && (
                  <Field
                    label={t('Fields.StudyDocumentation.PatientInformationAndConsentVersion.Label')}
                    value={currentReportValues.patientInformationAndConsentVersion}
                  />
                )}
                {currentReportValues.studyDocuments?.includes('InsuranceDocuments') && (
                  <Field
                    label={t('Fields.StudyDocumentation.InsuranceDocuments.Label')}
                    value={toDateOrNull(currentReportValues.insuranceDocuments)}
                  />
                )}
                {currentReportValues.studyDocuments?.includes('CommunicationWithEc') && (
                  <Field
                    label={t('Fields.StudyDocumentation.CommunicationWithEcVersion.Label')}
                    value={toDateOrNull(currentReportValues.communicationWithEc)}
                  />
                )}

                {currentReportValues.studyDocuments?.includes('SupportingDocuments') && (
                  <Field
                    label={t('Fields.StudyDocumentation.SupportingDocuments.Label')}
                    value={currentReportValues.supportingDocuments}
                  />
                )}
                {currentReportValues.studyDocuments?.includes('RandomizationList') && (
                  <Field
                    label={t('Fields.StudyDocumentation.RandomizationList.Label')}
                    value={toDateOrNull(currentReportValues.randomizationList)}
                  />
                )}
                {currentReportValues.studyDocuments?.includes('Other') && (
                  <Field
                    label={t('Fields.StudyDocumentation.OtherStudyDocuments.Label')}
                    value={currentReportValues.otherStudyDocuments}
                  />
                )}
              </>
            )}
            {(currentReportValues.typeOfMonitoringVisit === 'Routine' ||
              currentReportValues.typeOfMonitoringVisit === 'Final') && (
              <>
                <Field
                  label={t('Fields.PatientDocumentation.PersonalIdentificationListIsComplete.Label')}
                  value={getYesNoLabel(currentReportValues.personalIdentificationListIsComplete)}
                  {...getSkipReasonProps('personalIdentificationListIsComplete')}
                />
                {currentReportValues.personalIdentificationListIsComplete_comment && (
                  <Field
                    label={t('Fields.Comment.Label')}
                    value={currentReportValues.personalIdentificationListIsComplete_comment}
                  />
                )}
                <Field
                  label={t('Fields.PatientDocumentation.PatientIdentificationListIsComplete.Label')}
                  value={getYesNoLabel(currentReportValues.patientIdentificationListIsComplete)}
                  {...getSkipReasonProps('patientIdentificationListIsComplete')}
                />
                {currentReportValues.patientIdentificationListIsComplete_comment && (
                  <Field
                    label={t('Fields.Comment.Label')}
                    value={currentReportValues.patientIdentificationListIsComplete_comment}
                  />
                )}
              </>
            )}
          </FieldGroup>
        </>
      )}
      {(currentReportValues.typeOfMonitoringVisit === 'Routine' ||
        currentReportValues.typeOfMonitoringVisit === 'Final') && (
        <>
          <FieldGroup title={t('Fields.DeclarationsOfConsent.Title')}>
            <Field
              label={t('Fields.DeclarationsOfConsent.ConsentFormsReviewed.Label')}
              value={getConsentFormsReviewedLabel(currentReportValues.consentFormsReviewed)}
              {...getSkipReasonProps('consentFormsReviewed')}
            />
            <Field
              label={t('Fields.DeclarationsOfConsent.DeclarationsOfConsentIsComplete.Label')}
              value={getYesNoLabel(currentReportValues.declarationsOfConsentIsComplete)}
              {...getSkipReasonProps('declarationsOfConsentIsComplete')}
            />
            {currentReportValues.declarationsOfConsentIsComplete_comment && (
              <Field
                label={t('Fields.Comment.Label')}
                value={currentReportValues.declarationsOfConsentIsComplete_comment}
              />
            )}
          </FieldGroup>
        </>
      )}

      {currentReportValues.typeOfMonitoringVisit === 'Final' && (
        <>
          <FieldGroup title={t('Fields.QueryManagement.Title')}>
            <Field
              label={t('Fields.QueryManagement.OpenQueriesDiscussed.Label')}
              value={getYesNoLabel(currentReportValues.openQueriesDiscussed)}
              {...getSkipReasonProps('openQueriesDiscussed')}
            />
            {currentReportValues.openQueriesDiscussed_comment && (
              <Field label={t('Fields.Comment.Label')} value={currentReportValues.openQueriesDiscussed_comment} />
            )}
          </FieldGroup>
          <FieldGroup title={t('Fields.TestProducts.Title')}>
            <Field
              label={t('Fields.TestProducts.ResetOfDevices.Label')}
              value={getYesNoLabel(currentReportValues.resetOfDevices)}
              {...getSkipReasonProps('resetOfDevices')}
            />
            {currentReportValues.resetOfDevices_comment && (
              <Field label={t('Fields.Comment.Label')} value={currentReportValues.resetOfDevices_comment} />
            )}

            <Field
              label={t('Fields.TestProducts.StudyEquipmentPool.Label')}
              value={getYesNoLabel(currentReportValues.studyEquipmentPool)}
              {...getSkipReasonProps('studyEquipmentPool')}
            />
            {currentReportValues.studyEquipmentPool_comment && (
              <Field label={t('Fields.Comment.Label')} value={currentReportValues.studyEquipmentPool_comment} />
            )}
          </FieldGroup>
          <FieldGroup title={t('Fields.StudyGraduation.Title')}>
            <Field
              label={t('Fields.StudyGraduation.ReferenceToStudyDocuments.Label')}
              value={getYesNoLabel(currentReportValues.referenceToStudyDocuments)}
              {...getSkipReasonProps('referenceToStudyDocuments')}
            />
            {currentReportValues.referenceToStudyDocuments_comment && (
              <Field label={t('Fields.Comment.Label')} value={currentReportValues.referenceToStudyDocuments_comment} />
            )}
            <Field
              label={t('Fields.StudyGraduation.ReferenceToAuthority.Label')}
              value={getYesNoLabel(currentReportValues.referenceToAuthority)}
              {...getSkipReasonProps('referenceToAuthority')}
            />
            {currentReportValues.referenceToAuthority_comment && (
              <Field label={t('Fields.Comment.Label')} value={currentReportValues.referenceToAuthority_comment} />
            )}
            <Field
              label={t('Fields.StudyGraduation.ClarificationOfPublication.Label')}
              value={getYesNoLabel(currentReportValues.clarificationOfPublication)}
              {...getSkipReasonProps('clarificationOfPublication')}
            />
            {currentReportValues.clarificationOfPublication_comment && (
              <Field label={t('Fields.Comment.Label')} value={currentReportValues.clarificationOfPublication_comment} />
            )}
          </FieldGroup>
        </>
      )}

      {(currentReportValues.typeOfMonitoringVisit === 'Routine' ||
        currentReportValues.typeOfMonitoringVisit === 'Final') &&
        (currentReportValues.patients?.length ?? 0) > 0 && (
          <>
            <FieldGroup title={t('Fields.PatientMonitoring.Title')}>
              <Field
                label={t('Fields.PatientMonitoring.PatientRange.Label')}
                value={currentReportValues.patientRange}
              />
              {currentReportValues.patients?.map((patient, i) => (
                <RepeatableFormSection
                  key={i}
                  title={t('Fields.PatientMonitoring.RepeatableSectionTitle', { index: i + 1 })}
                >
                  <Field label={t('Fields.PatientMonitoring.PatientUniqueId.Label')} value={patient.patientUniqueId} />
                  <Field label={t('Fields.PatientMonitoring.Sdv.Label')} value={getSdvOptionsLabel(patient.sdv)} />
                  {patient.sdv === 'FixedRecord' && (
                    <Field label={t('Fields.PatientMonitoring.SdvFixedRecord.Label')} value={patient.sdvFixedRecord} />
                  )}
                  <Field
                    label={t('Fields.PatientMonitoring.ConsentIsCorrectlyObtained.Label')}
                    value={getYesNoLabel(patient.consentIsCorrectlyObtained)}
                    {...getSkipReasonProps(`patients.${i}.consentIsCorrectlyObtained`)}
                  />
                  {patient.consentIsCorrectlyObtained_comment && (
                    <Field label={t('Fields.Comment.Label')} value={patient.consentIsCorrectlyObtained_comment} />
                  )}
                  <Field
                    label={t('Fields.PatientMonitoring.VerifiableInclusionAndExclusionCriteriaAreCorrect.Label')}
                    value={getYesNoLabel(patient.verifiableInclusionAndExclusionCriteriaAreCorrect)}
                    {...getSkipReasonProps(`patients.${i}.verifiableInclusionAndExclusionCriteriaAreCorrect`)}
                  />
                  {patient.verifiableInclusionAndExclusionCriteriaAreCorrect_comment && (
                    <Field
                      label={t('Fields.Comment.Label')}
                      value={patient.verifiableInclusionAndExclusionCriteriaAreCorrect_comment}
                    />
                  )}
                  <Field
                    label={t('Fields.PatientMonitoring.AccordingToStudySchedule.Label')}
                    value={getYesNoLabel(patient.accordingToStudySchedule)}
                    {...getSkipReasonProps(`patients.${i}.accordingToStudySchedule`)}
                  />
                  {patient.accordingToStudySchedule_comment && (
                    <Field label={t('Fields.Comment.Label')} value={patient.accordingToStudySchedule_comment} />
                  )}
                  <Field
                    label={t('Fields.PatientMonitoring.AccordingToTestPlan.Label')}
                    value={getYesNoLabel(patient.accordingToTestPlan)}
                    {...getSkipReasonProps(`patients.${i}.accordingToTestPlan`)}
                  />
                  {patient.accordingToTestPlan_comment && (
                    <Field label={t('Fields.Comment.Label')} value={patient.accordingToTestPlan_comment} />
                  )}
                  <Field
                    label={t('Fields.PatientMonitoring.NewInformationDiscussed.Label')}
                    value={getYesNoLabel(patient.newInformationDiscussed)}
                    {...getSkipReasonProps(`patients.${i}.newInformationDiscussed`)}
                  />
                  {patient.newInformationDiscussed_comment && (
                    <Field label={t('Fields.Comment.Label')} value={patient.newInformationDiscussed_comment} />
                  )}
                  <Field
                    label={t('Fields.PatientMonitoring.SourceDataDocumentationAvailable.Label')}
                    value={getYesNoLabel(patient.sourceDataDocumentationAvailable)}
                    {...getSkipReasonProps(`patients.${i}.sourceDataDocumentationAvailable`)}
                  />
                  {patient.sourceDataDocumentationAvailable_comment && (
                    <Field label={t('Fields.Comment.Label')} value={patient.sourceDataDocumentationAvailable_comment} />
                  )}
                </RepeatableFormSection>
              ))}
            </FieldGroup>
          </>
        )}

      <Field label={t('Fields.Confirmation.Label')} value={currentReportValues.confirmation} />
    </>
  );
};
