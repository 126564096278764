import { Cell, Column, flexRender, Header, Row, Table as TanTable } from '@tanstack/react-table';
import React, { FC, FunctionComponent, SVGProps, useState } from 'react';
import styles from './AppTable.module.scss';
import { ReactComponent as Up } from 'src/assets/img/table/up_16.svg';
import { ReactComponent as Down } from 'src/assets/img/table/down_16.svg';
import { ReactComponent as UpDown } from 'src/assets/img/table/up_down_16.svg';
import { ReactComponent as NotFoundImage } from 'src/assets/img/common/not_found.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/img/common/arrow_24.svg';
import clsx from 'clsx';
import { Collapse } from 'uikit/collapse/Collapse';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { buildTestId } from '../../../helpers/test-helpers';

export function AppTable<D extends object = object>(props: {
  className?: string;
  /** @default main */
  variant?: 'main' | 'no-borders';
  testId?: string;
  table: TanTable<D>;
  expandedRow?: FC<Row<D>>;
  onRowClick?: (row: Row<D>) => void;
  placeholder?: {
    hide?: boolean;
    Icon?: FunctionComponent<SVGProps<any>>;
    text?: string;
  };
}) {
  const { table, expandedRow, onRowClick, placeholder, variant = 'main' } = props;
  const { t } = useTranslation();

  const rowCount = table.getRowModel().rows.length;

  if (rowCount === 0) {
    return (
      <div className={styles.placeholderContainer}>
        {!placeholder?.hide && (
          <>
            {placeholder?.Icon ? <placeholder.Icon /> : <NotFoundImage />}
            <span>{placeholder?.text ?? t('Common_NoData')}</span>
          </>
        )}
      </div>
    );
  }

  return (
    <table
      data-test-id={buildTestId(props.testId, 'app-table')}
      className={clsx(
        styles.table,
        { [styles.main]: variant === 'main', ['']: variant === 'no-borders' },
        props.className,
      )}
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className={styles.row}>
            {headerGroup.headers.map((header) => (
              <AppTableHeader key={header.id} header={header} />
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={styles.tableBody}>
        {table.getRowModel().rows.map((row, i, arr) => (
          <AppTableRow key={row.id} expandedRow={expandedRow} onClick={onRowClick} row={row} rows={arr} />
        ))}
      </tbody>
    </table>
  );
}

const SortOrderIcon: FC<{ column: Column<any> }> = ({ column }) => {
  return column.getCanSort()
    ? {
        asc: <Up />,
        desc: <Down />,
      }[column.getIsSorted() as string] || <UpDown />
    : null;
};

const AppTableHeader = <D extends object = object>(props: { header: Header<D, any> }) => {
  const header = props.header;
  const meta = header.column.columnDef.meta;
  const headerClassName =
    typeof meta?.headerClassName === 'function' ? meta.headerClassName(header) : meta?.headerClassName;

  return (
    <th
      data-test-id={buildTestId(header.column.id, 'app-table-header')}
      className={clsx(styles.header, headerClassName, {
        [styles.sortableColumnHeader]: header.column.getCanSort(),
        [styles.center]: header.column.columnDef.meta?.centered,
      })}
      onClick={header.column.getToggleSortingHandler()}
      style={{
        width: header.column.columnDef.meta?.dontUseHeaderWidth ? undefined : header.column.getSize(),
      }}
    >
      <Tooltip
        hostStyles={styles.tooltip}
        text={header.column.columnDef.meta?.headerTooltip ?? ''}
        disabled={!header.column.columnDef.meta?.headerTooltip}
      >
        <div className={styles.title}>
          {flexRender(header.column.columnDef.header, header.getContext())}
          <SortOrderIcon column={header.column} />
        </div>
      </Tooltip>
    </th>
  );
};

const AppTableRow = <D extends object = object>(props: {
  expandedRow?: FC<Row<D>>;
  onClick?: (row: Row<D>) => void;
  row: Row<D>;
  rows: Row<D>[];
  isSubRow?: boolean;
}) => {
  const { row, rows, expandedRow, onClick, isSubRow } = props;

  if (row.getIsGrouped()) return <AppTableGroupedRow row={row} />;

  return (
    <>
      <tr
        data-test-id={buildTestId(row.id, 'app-table-row')}
        data-clickable={!!onClick}
        onClick={() => onClick?.(row)}
        className={clsx(styles.row, {
          // To propagate border-radius to child cells when expanded rows are used
          [styles.lastCollapsedRow]: !row.getIsExpanded() && rows.at(-1) === row,
          [styles.subRow]: isSubRow,
        })}
      >
        {row.getVisibleCells().map((cell) => (
          <AppTableCell key={cell.id} cell={cell} />
        ))}
      </tr>
      {expandedRow && (
        <tr data-test-id={buildTestId(row.id, 'app-table-expanded-row')}>
          <td colSpan={row.getVisibleCells().length} className={styles.expandedRow} data-expanded={row.getIsExpanded()}>
            <Collapse in={row.getIsExpanded()} unmountOnExit>
              {expandedRow(row)}
            </Collapse>
          </td>
        </tr>
      )}
    </>
  );
};

const AppTableCell = <D extends object = object>(props: { cell: Cell<D, any> }) => {
  const cell = props.cell;
  const meta = cell.column.columnDef.meta;
  const cellClassName = typeof meta?.cellClassName === 'function' ? meta.cellClassName(cell) : meta?.cellClassName;

  return (
    <td
      data-test-id={buildTestId(cell.column.id, 'app-table-cell')}
      data-colored={meta?.isColored}
      className={clsx(styles.cell, cellClassName, {
        [styles.center]: meta?.centered,
      })}
      style={{ width: meta?.cellWidth }}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

const AppTableGroupedRow = <D extends object = object>(props: { row: Row<D> }) => {
  const { row } = props;
  const value = row.groupingValue as string;
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <tr className={clsx(styles.row, styles.groupedRow)}>
        <td colSpan={row.getVisibleCells().length} className={styles.groupedRowContent}>
          <button className={styles.button} onClick={() => setIsExpanded((x) => !x)}>
            {value}
            <ArrowIcon className={clsx(styles.icon, { [styles.expendedIcon]: isExpanded })} />
          </button>
        </td>
      </tr>
      {isExpanded &&
        row.subRows.map((subRow, i, arr) => <AppTableRow key={subRow.id} row={subRow} rows={arr} isSubRow={true} />)}
    </>
  );
};
