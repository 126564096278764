/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { IStaffDto, PatchUserDto } from '../../../services/api/api-client';
import Style from './userlist.module.scss';
import { AppButton } from 'uikit/buttons';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TypographyStyles } from '../../../styles';
import { useUserId } from '../../../helpers/auth/auth-helper';
import { QueryFactory } from '../../../services/api';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppInputLabel } from 'uikit/wrappers';
import { AppCheckboxInput } from 'uikit/inputs';
import { AppTextField } from 'uikit/fields';
import { AppInputError } from 'uikit/wrappers';
import { useGetStudyListQuery } from 'src/services/api/api-client/StudyQuery';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';
import { useGetRolesQuery } from 'src/services/api/api-client/UserRolesQuery';

export const EditUserModalContent: FC<IStaffDto & { onClose: () => void }> = (props) => {
  const currentUserId = useUserId();
  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const client = useQueryClient();

  const { data: roleList } = useGetRolesQuery();
  const { data: studies } = useGetStudyListQuery({
    // TODO: This hack must be removed when we will have a dropdown with virtualization
    limit: 99999999,
  });

  const [error, setError] = useState<string>('');
  const [email, setEmail] = useState<string>(props.email || '');
  const [inProgress, setProgress] = useState<boolean>(false);

  const [selectedStudies, setSelectedStudies] = useState<DropdownOption[] | undefined>(
    props.studies!.map((x) => {
      return { key: x.id, text: `${x.id}: ${x.studyNumber}` };
    }) || [],
  );
  const [roles, setRoles] = useState(props.roles!);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, role: string) => {
      const value = e.target.checked;
      let checkedOptions = roles ? [...roles] : [];
      if (value) {
        checkedOptions.push(role);
      } else {
        checkedOptions = checkedOptions.filter((v) => v !== role);
      }

      setRoles(checkedOptions);
    },
    [roles],
  );

  const onSave = useCallback(async () => {
    try {
      setError('');
      setProgress(true);
      await QueryFactory.UsersQuery.Client.patchUser(
        props.id,
        new PatchUserDto({
          roles,
          studyIds: selectedStudies?.map((x) => +x.key),
          email: email,
        }),
      );

      await Promise.all([
        client.invalidateQueries(QueryFactory.UsersQuery.getAllUsersQueryKey()),
        client.invalidateQueries(QueryFactory.ProfileQuery.getDoctorProfileInfoQueryKey()),
      ]);

      props.onClose();
    } catch (e: any) {
      setError(e?.errors?.Email || t('Admin.UserList.Details.EditModal.Error'));
    } finally {
      setProgress(false);
    }
  }, [props, roles, selectedStudies, email, client, t]);

  const options: DropdownOption[] = useMemo(
    () =>
      studies?.data.map((x) => {
        return { key: x.id, text: `${x.id}: ${x.studyNumber}` };
      }) || [],
    [studies?.data],
  );

  return (
    <>
      <AppTextField
        labelProps={{
          text: t('Admin.UserList.Details.Login'),
        }}
        value={props.userName}
        disabled={true}
      />

      <AppTextField
        labelProps={{
          text: t('Admin.UserList.Predefined.Email.Label'),
        }}
        placeholder={t('Admin.UserList.Predefined.Email.Placeholder')}
        value={email}
        onChange={(ev) => setEmail(ev.target.value)}
        disabled={!props.predefinedUser}
      />

      <AppInputLabel text={t('Admin.UserList.Details.EditModal.StudyIds')}>
        <AppDropDownWithSuggestionInput
          isMultiple={true}
          suggestion={true}
          options={options}
          value={selectedStudies}
          onChange={setSelectedStudies}
        />
      </AppInputLabel>

      <span className={clsx(TypographyStyles.paragraph14, Style.roleModalLabel)}>
        {t('Admin.UserList.Details.EditModal.Role')}
      </span>

      <div className={Style.roleModalCheckboxGroup}>
        {roleList?.map((role, index) => {
          return (
            <AppCheckboxInput
              key={index}
              checked={roles?.includes(role.name)}
              label={role.name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, role.name)}
              disabled={role.name === 'SuperAdmin' && props.id === currentUserId}
            />
          );
        })}
      </div>

      <AppInputError errors={error} hideBorder position={'top'}>
        <div className={Style.modalButtonGroup}>
          <AppButton
            variant={'button'}
            colorSchema={'secondary'}
            text={commonLocalizer('Common_Cancel')}
            onClick={props.onClose}
          />
          <AppButton
            variant={'button'}
            colorSchema={'primary'}
            text={commonLocalizer('Common_Save')}
            onClick={onSave}
            isLoading={inProgress}
            disabled={inProgress}
          />
        </div>
      </AppInputError>
    </>
  );
};
