import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { AppTextField, AppTextFieldProps } from 'uikit/fields';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export type HookFormInputProps<TFieldValues extends FieldValues> = Pick<
  ControllerProps<TFieldValues>,
  'control' | 'name' | 'rules'
> & {
  testId?: string;
  hideErrorBorder?: boolean;
} & Omit<AppTextFieldProps, 'errorProps'>;

export const HookFormTextInput = <TFieldValues extends FieldValues>(props: HookFormInputProps<TFieldValues>) => {
  const { control, name, rules, testId, hideErrorBorder, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
        <AppTextField
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          data-test-id={testId}
          errorProps={{ errors: error?.message, hideBorder: hideErrorBorder }}
          {...rest}
        />
      )}
    />
  );
};
