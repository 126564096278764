import React from 'react';
import Style from './survey.module.scss';
import clsx from 'clsx';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { TypographyStyles } from '../../../styles';
import { DateFormats, formatDateRange, localFormat } from 'src/helpers/date-helpers';
import { getIndicatorStyleColor } from 'src/helpers/color-helper';
import { formatScoreValue } from 'src/helpers/question-helper';
import { useSurveyIcon } from '../../../application/hooks/useSurveyIcon';
import {
  IStatisticsItemDto,
  QualityMetric,
  ScoreCalculationStatusEnum,
  SurveyIcon,
} from '../../../services/api/api-client';
import { ErrorScoreCalculation } from '../../errorScoreCalculation/errorScoreCalculation.component';
import { useTranslation } from 'react-i18next';

export type SurveyStatisticsIndicatorProps = {
  surveyIcon: SurveyIcon;
  statisticItem: IStatisticsItemDto;
  onClick?: () => void;
};

export const SurveyStatisticsIndicator = (props: SurveyStatisticsIndicatorProps) => {
  const { statisticItem, surveyIcon, onClick } = props;
  const { t } = useTranslation();
  const { SvgIcon: StatIcon } = useSurveyIcon(
    surveyIcon,
    'large',
    statisticItem.qualityMetric === QualityMetric.NotCalculated ? 'dashed' : 'solid',
    statisticItem.qualityMetric,
  );

  if (
    statisticItem.scoreStatus === ScoreCalculationStatusEnum.InProcess ||
    statisticItem.scoreStatus === ScoreCalculationStatusEnum.Error
  ) {
    return (
      <div>
        <div className={Style.errorIconContainer}>
          <ErrorScoreCalculation
            retryHandlerType={'RetrySurvey'}
            answerId={statisticItem.answerId ?? null}
            onClick={onClick}
            canRetry={statisticItem.canRetryScoreCalculation && (statisticItem.answerId ?? null) !== null}
            isInProcess={statisticItem.scoreStatus === ScoreCalculationStatusEnum.InProcess}
          />
        </div>
        <p className={clsx(Style.errorText, TypographyStyles.plainText14)}>{t('Common_Error')}</p>
      </div>
    );
  }

  return (
    <div key={statisticItem.startDate?.toDateString() + (statisticItem.score ?? '').toString()}>
      <Tooltip
        text={
          !!statisticItem.endDate
            ? formatDateRange(statisticItem.startDate!, statisticItem.endDate)
            : localFormat(statisticItem.startDate!, DateFormats.longDate)
        }
      >
        <StatIcon
          className={clsx(getIndicatorStyleColor(statisticItem.qualityMetric), {
            [Style.cursorDefault]: !props.onClick,
          })}
          onClick={onClick}
        />
      </Tooltip>
      <p className={clsx(Style.iconsContainerSign, TypographyStyles.plainText14)}>
        {formatScoreValue(statisticItem.score, true)}
      </p>
    </div>
  );
};
