﻿export const participantTasks = [
  'Screening',
  'Patientenaufklärung und Einwilligung',
  'Bedienung Therapiegerät',
  'Medizinische Untersuchung und Betreuung',
  'Abfrage von Nebenwirkungen',
  'Patientenbefragung',
  'Terminvergabe',
  'Doku/Ablage von Studienunterlagen',
  'eCRF-Eintragung',
  'Patientenfreigabe (=eCRF-Signatur)',
  'Schulung LM Study',
  'Schulung Prüfplan',
  'Queries bearbeiten',
  'Studienteammitglieder anlegen/verwalten',
  '(S)UE-Erfassung und ggf. Meldung',
  'Qualitätskontrolle/Monitoring',
  'Sonstiges',
];

export const participantFunctions = [
  'Prüfer',
  'Facharzt',
  'Medizinisches Fachpersonal',
  'Technisches Fachpersonal',
  'Wissenschaftliches Fachpersonal',
  'Monitor',
  'Sonstiges',
];
