import React from 'react';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { InformationPageContainer } from '../../components/informationPageContainer/InformationPage.Container';
import noRoleImage from 'src/assets/img/common/no_role.svg';

export const NoPermission = () => {
  const { t } = useScopedTranslation('ProfileProvider');

  return <InformationPageContainer header={t('NoRolesTitle')} text={t('NoRolesText')} img={noRoleImage} />;
};
