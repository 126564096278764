import React, { useCallback } from 'react';
import { FormDto, IFormResultVersionDto } from '../../../../services/api/api-client';
import { DateFormats, localFormat, toDateOrNull } from '../../../../helpers/date-helpers';
import Style from './noteListItem.module.scss';
import clsx from 'clsx';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';

export const NoteListItem = (props: {
  formResult: IFormResultVersionDto;
  formConfig: FormDto;
  onSelect: (formResultId: number) => void;
  isSelected: boolean;
  noteDateDataKey: string;
  mainNoteFieldDataKey: string;
  patientUniqId: string;
}) => {
  const { onSelect, formResult, isSelected, patientUniqId } = props;

  const dateValue = () => {
    const dataKey = props.noteDateDataKey;

    if (!dataKey) return;

    const inputValue = formResult.value?.[dataKey] as string | undefined | null;
    const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;
    const parsedDate = toDateOrNull(singleInputValue);

    return parsedDate && localFormat(parsedDate, DateFormats.longDate);
  };

  const mainTextValue = () => {
    const dataKey = props.mainNoteFieldDataKey;

    if (!dataKey) return;

    const inputValue = formResult.value?.[dataKey] as string | undefined | null;
    return Array.isArray(inputValue) ? inputValue.join('\n') : inputValue;
  };

  const onSelectHandler = useCallback(() => {
    return onSelect(formResult.formResultId);
  }, [onSelect, formResult]);

  return (
    <div className={Style.container} data-test-id={'note-item'}>
      <div className={Style.headerText}>{dateValue()}</div>
      <IssueMark
        issueContext={{
          subject: 'Patient',
          linkedPatientUniqId: patientUniqId,
          topic: 'Notes',
          resultId: formResult.formResultId || undefined,
        }}
        position={{ top: 12, right: 12 }}
      >
        <div className={clsx(Style.itemBody, { [Style.active]: isSelected })} onClick={onSelectHandler}>
          {mainTextValue()}
        </div>
      </IssueMark>
    </div>
  );
};
