﻿import React, { FC, memo, useCallback, useMemo } from 'react';
import TablePageLayout from '../../../components/tablePageLayout/TablePageLayout';
import { ExpandedRowHeader } from '../../../components/expandedRow/header/expandedRowHeader.component';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { AppButton } from 'uikit/buttons';
import { ReactComponent as Edit } from 'src/assets/img/records/edit_24.svg';
import { ReactComponent as Add } from 'src/assets/img/records/add_24.svg';
import { ReactComponent as Eye } from 'src/assets/img/common/eye_24.svg';
import { ReactComponent as PatientIcon } from 'src/assets/img/formBuilder/statusIcons/patient_filled_16.svg';
import styles from './MonitoringFormsSection.module.scss';
import { useMonitoringFormsEditing } from '../monitoringForms/useMonitoringFormsEditing';
import { useGetMonitoringFormsQuery } from '../../../services/api/api-client/MonitoringFormsQuery';
import { useTranslation } from 'react-i18next';
import { MonitoringFormType, MonitoringFormValue } from '../monitoringForms/MonitoringFormValue';
import { useMonitoringFormsOverview } from '../monitoringForms/useMonitoringFormsOverview';
import { IMonitoringFormDto, Permissions } from '../../../services/api/api-client';
import clsx from 'clsx';
import { TypographyStyles } from 'styles';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';

export const MonitoringFormsSection: FC = memo(() => {
  const { t } = useTranslation();
  const monitoringFormsOverview = useMonitoringFormsOverview();
  const monitoringFormEditing = useMonitoringFormsEditing();
  const { hasPermission } = useHasPermissions();

  const { title, id: studyId, closingApprovedAt } = useStudy() ?? {};

  const reportsQuery = useGetMonitoringFormsQuery(studyId!, {
    enabled: !!studyId,
  });

  const onFill = useCallback(() => {
    monitoringFormEditing.startFilling({ studyTitle: title!, studyId: studyId! });
  }, [monitoringFormEditing, studyId, title]);

  const onEdit = useCallback(
    (report: IMonitoringFormDto) => {
      monitoringFormEditing.startEditing({
        studyId: studyId!,
        studyTitle: title!,
        monitoringForm: report,
      });
    },
    [monitoringFormEditing, studyId, title],
  );

  const itemVms = useMemo<FormListItemVm[]>(() => {
    if (!reportsQuery.data) {
      return [];
    }

    return reportsQuery.data.map<FormListItemVm>((x, i, arr) => ({
      formType: t(`MonitoringForms.MonitoringFormTypes.${x.type as MonitoringFormType}`),
      index: arr.length - i,
      filledAt: x.createdAt,
      patientRange: (x.value as MonitoringFormValue).patientRange,
      onEdit: () => onEdit(x),
      onView: () => monitoringFormsOverview.open({ reportId: x.id }),
    }));
  }, [reportsQuery.data, t, onEdit, monitoringFormsOverview]);

  return (
    <TablePageLayout.RightBlock>
      <ExpandedRowHeader
        text={t('Monitoring.Page.MonitoringFormsSection.Title')}
        rightHeader={
          closingApprovedAt || !hasPermission(Permissions.MonitoringReportCreate) ? undefined : (
            <AppButton
              iconClassName={styles.addButton}
              variant={'icon-link'}
              Icon={Add}
              colorSchema={'primary'}
              onClick={onFill}
            />
          )
        }
      />
      <div className={styles.formsList}>
        {itemVms.map((vm) => (
          <FormsListItem key={vm.index} vm={vm} />
        ))}
      </div>
      {monitoringFormEditing.element}
      {monitoringFormsOverview.element}
    </TablePageLayout.RightBlock>
  );
});

type FormListItemVm = {
  formType: string;
  index: number;
  filledAt: Date;
  patientRange: string | undefined;
  onEdit: () => void;
  onView: () => void;
};

const FormsListItem: FC<{ vm: FormListItemVm }> = memo(({ vm }) => {
  const { formType, index, filledAt, patientRange, onEdit, onView } = vm;
  const { hasPermission } = useHasPermissions();

  return (
    <div className={styles.formsListItem}>
      <div className={styles.row}>
        <div className={clsx(styles.rowLabel, TypographyStyles.heading2)}>
          <span>{formType}</span>
          <span className={styles.formIndex}>#{index}</span>
        </div>
        <span className={clsx(TypographyStyles.plainText14, styles.date)}>
          {localFormat(filledAt, DateFormats.longDate)}
        </span>
        {hasPermission(Permissions.MonitoringReportCreate) && (
          <AppButton variant={'icon-link'} colorSchema={'primary'} Icon={Edit} onClick={onEdit} />
        )}
        <AppButton variant={'icon-link'} colorSchema={'primary'} Icon={Eye} onClick={onView} />
      </div>
      {patientRange && (
        <div className={styles.patientsRange}>
          <PatientIcon className={styles.icon} />
          <div className={styles.value}>{patientRange}</div>
        </div>
      )}
    </div>
  );
});
