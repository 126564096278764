import clsx from 'clsx';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import Style from './appInputError.module.scss';
import { TypographyStyles } from '../../../../styles';
import { ReactComponent as WarningIcon } from '../../../../assets/img/common/warning_16.svg';

export type AppInputErrorProps = {
  errors?: string | string[];
  className?: string;
  hideBorder?: boolean;
  position?: 'top' | 'bottom';
};

export const AppInputError: FC<PropsWithChildren<AppInputErrorProps>> = ({
  errors,
  className,
  children,
  hideBorder,
  position = 'bottom',
}) => {
  const errorContent = useMemo(() => {
    if (!errors) {
      return <p className={Style.placeholder}>{'-'}</p>;
    }

    if (Array.isArray(errors)) {
      return errors.map((error, i) => <p key={i}>{error}</p>);
    }

    return <p>{errors}</p>;
  }, [errors]);

  return (
    <div
      className={clsx(Style.errorContainer, className, {
        [Style.leftBorder]: (errors || errors?.length) && !hideBorder,
        [Style.reverse]: position === 'top',
      })}
    >
      {children}
      <div className={clsx(TypographyStyles.heading3, Style.errors)}>{errorContent}</div>
    </div>
  );
};

export const ErrorSign = () => {
  return <WarningIcon className={Style.errorIcon} />;
};
