import { defaultToolSettings } from './base/baseControlSettings';
import { createId } from 'src/helpers/typeUtils';
import {
  ApplicableRules,
  ValidationDefaultSettings,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { IDateInputProps } from './DateInput';
import { UserComponent } from '@craftjs/core';

export interface ITimeInputProps extends IDateInputProps {
  stepInMinutes?: number;
}

export type ITimeInput = UserComponent<ITimeInputProps>;

export function timeInputDefaultPropsFactory(): ITimeInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    stepInMinutes: 30,
    validation: { ...ValidationDefaultSettings },
  };
}

export const timeInputApplicableValidationRules: ApplicableRules = {
  isRequired: true,
};
