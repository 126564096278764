﻿import React, { FC } from 'react';
import styles from './BulletPoints.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export const BulletPoints: FC<{
  values: string[];
  maxItemsCount?: number;
  /** @default true */
  trim?: boolean;
}> = (props) => {
  const { t } = useTranslation();

  const maxCountIsReached = !!props.maxItemsCount && props.values.length > props.maxItemsCount;
  const values = maxCountIsReached ? props.values.slice(0, props.maxItemsCount) : props.values;

  return (
    <ul className={clsx(styles.bulletPoints, { [styles.trim]: props.trim ?? true })}>
      {values.map((x, i) => (
        <li key={i}>{x}</li>
      ))}
      {maxCountIsReached && t('uikit.BulletPoints.Etc')}
    </ul>
  );
};
