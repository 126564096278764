import React, { FC } from 'react';
import { RPDF } from '../../../react-pdf';

export const CrossIcon: FC = () => {
  return (
    <RPDF.Svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        d="M9.81331 3.06687C10.0573 2.82275 10.0573 2.42702 9.81316 2.18298C9.56904 1.93895 9.17331 1.93901 8.92927 2.18313L5.99815 5.11527L3.06702 2.18313C2.82298 1.93901 2.42726 1.93895 2.18314 2.18298C1.93902 2.42702 1.93895 2.82275 2.18298 3.06687L5.11441 5.9993L2.18298 8.93174C1.93895 9.17586 1.93901 9.57159 2.18313 9.81563C2.42725 10.0597 2.82298 10.0596 3.06702 9.81547L5.99815 6.88334L8.92927 9.81547C9.17331 10.0596 9.56904 10.0597 9.81316 9.81563C10.0573 9.57159 10.0573 9.17586 9.81331 8.93174L6.88188 5.9993L9.81331 3.06687Z"
        fill="#667878"
      />
    </RPDF.Svg>
  );
};
