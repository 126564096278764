import React, { FC } from 'react';
import { RPDF } from '../react-pdf';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { Style } from '@react-pdf/types/style';

type SignAndTitleProps = {
  renderDateField?: boolean;
  title?: string | undefined | null;
  customText?: string | undefined | null;
} & ({ title: string } | { customText: string });

export const ReportSignAndTitleField: FC<SignAndTitleProps> = (props: SignAndTitleProps) => {
  const { renderDateField, title, customText } = props;
  const { t } = useScopedTranslation('Reports.Controls.SignField');

  const localStyles = RPDF.StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      gap: 12,
    },
    flex05: {
      flex: 0.5,
    },
    flex1: {
      flex: 1,
    },
    flex2: {
      flex: 2,
    },
    customText: {
      flex: 3,
      marginBottom: RPDF.tableStyles.divider.borderBottom,
    },
    underlineText: {
      ...RPDF.typography.text8,
      marginTop: 2,
    },
    emptyBlock: {
      height: 28,
      justifyContent: 'flex-end',
    },
  });

  const fillInBlock = (text: string, style?: Style | Style[]) => {
    return (
      <RPDF.View style={style}>
        <RPDF.View style={[RPDF.tableStyles.divider, localStyles.emptyBlock]} />
        <RPDF.Text style={localStyles.underlineText}>{text}</RPDF.Text>
      </RPDF.View>
    );
  };

  const textBlock = () => {
    if (customText) {
      return (
        <RPDF.View style={localStyles.customText}>
          <RPDF.Text style={RPDF.typography.text10}>{customText}</RPDF.Text>
          <RPDF.Text style={localStyles.underlineText}> </RPDF.Text>
        </RPDF.View>
      );
    }

    return (
      <RPDF.View style={localStyles.flex2}>
        <RPDF.Text style={RPDF.typography.heading4}>{title}</RPDF.Text>
        {fillInBlock(t('Name'))}
      </RPDF.View>
    );
  };

  return (
    <RPDF.View style={localStyles.container}>
      {textBlock()}
      {renderDateField && fillInBlock(t('Date'), localStyles.flex1)}
      {fillInBlock(t('Sign'), localStyles.flex1)}
    </RPDF.View>
  );
};
