import React from 'react';
import { VideoProps } from '.';
import { ToolbarItem } from '../../editor/toolbar/toolbar.item';
import { ToolbarSection } from '../../editor/toolbar/toolbar.section';

export const VideoSettings = () => {
  return (
    <>
      <ToolbarSection<VideoProps>
        title={'Dimensions'}
        props={['width', 'height']}
        summary={({ width, height }) => {
          return `${width || 'auto'} x ${height || 0}`;
        }}
      >
        <ToolbarItem<VideoProps> zeroValue={160} propKey={'width'} type="number" label="Width" />
        <ToolbarItem<VideoProps> zeroValue={90} propKey={'height'} type="number" label="Height" />
      </ToolbarSection>

      <ToolbarSection<VideoProps>
        title="Margin"
        props={['margin']}
        summary={({ margin }: any) => {
          return `${margin[0] || 0}px ${margin[1] || 0}px ${margin[2] || 0}px ${margin[3] || 0}px`;
        }}
      >
        <ToolbarItem<VideoProps> propKey="margin" zeroValue={0} index={0} type="number" label="Top" />
        <ToolbarItem<VideoProps> propKey="margin" zeroValue={0} index={1} type="number" label="Right" />
        <ToolbarItem<VideoProps> propKey="margin" zeroValue={0} index={2} type="number" label="Bottom" />
        <ToolbarItem<VideoProps> propKey="margin" zeroValue={0} index={3} type="number" label="Left" />
      </ToolbarSection>

      <ToolbarSection<VideoProps> title="Youtube">
        <ToolbarItem<VideoProps> propKey="videoId" type="text" label="Video ID" />
      </ToolbarSection>
    </>
  );
};
