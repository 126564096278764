import React, { FC, useEffect } from 'react';
import { useNode } from '@craftjs/core';
import { ControlsGroup } from '../../components/controlsGroup';
import { PanelContainer } from '../../components/panelContainer';
import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import { useTranslation } from 'react-i18next';
import { CheckboxSettings } from '../settingsInputs/CheckboxSettings';
import { SettingsInput } from '../settingsInputs/settingsInput';

export const TitleSettings: FC<{ labelRequired?: boolean }> = ({ labelRequired }) => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));

  useEffect(() => {
    if (labelRequired) setProp((setProps: IFormFieldProps) => (setProps.hideLabel = false));
  }, [setProp, labelRequired]);

  return (
    <PanelContainer header={'Title'}>
      <ControlsGroup flexible>
        {!labelRequired && (
          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.settings.hideLabel')}
            isChecked={props.hideLabel}
            setValue={(newValue) =>
              setProp((setProps: IFormFieldProps) => {
                setProps.hideLabel = newValue ? newValue : undefined;
              }, 500)
            }
          />
        )}
        {!props.hideLabel && (
          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.settings.isBold')}
            isChecked={props.isBold}
            setValue={(newValue) =>
              setProp((setProps: IFormFieldProps) => {
                setProps.isBold = newValue ? newValue : undefined;
              }, 500)
            }
          />
        )}
      </ControlsGroup>

      {!props.hideLabel && (
        <>
          <ControlsGroup
            header={t('StudySettingsPage.FormBuilder.settings.label')}
            expanded={!!props.label}
            collapsible
            flexible
            onOpen={() => {
              setProp((prop: IFormFieldProps) => {
                prop.label = prop.dataKey;
              }, 500);
            }}
            onClose={() => {
              setProp((prop: IFormFieldProps) => {
                prop.label = undefined;
              }, 500);
            }}
          >
            <SettingsInput
              multiple
              value={props.label || ''}
              onChange={(e) => {
                setProp((setProps: IFormFieldProps) => {
                  setProps.label = e.target?.value ? e.target.value : undefined;
                }, 500);
              }}
            />
          </ControlsGroup>

          <ControlsGroup
            collapsible
            expanded={!!props.tooltip}
            header={t('StudySettingsPage.FormBuilder.settings.tooltip')}
            flexible
            onOpen={() => {
              setProp((prop: IFormFieldProps) => {
                prop.tooltip = 'Tooltip';
              }, 500);
            }}
            onClose={() => {
              setProp((prop: IFormFieldProps) => {
                prop.tooltip = undefined;
              }, 500);
            }}
          >
            <SettingsInput
              multiple
              value={props.tooltip || ''}
              onChange={(e) => {
                setProp((setProps: IFormFieldProps) => {
                  setProps.tooltip = e.target?.value ? e.target.value : undefined;
                }, 500);
              }}
            />
          </ControlsGroup>
        </>
      )}
    </PanelContainer>
  );
};
