﻿import React, { FC, useContext, useMemo } from 'react';
import { RouteProgressStateEnum } from 'src/services/api/api-client';
import { ExpandedRowHeader } from '../header/expandedRowHeader.component';
import { RecordRow } from './recordRow.component';
import Style from './recordRow.module.scss';
import { PatientCardContext } from '../PatientCardContext';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { RecordsGroup } from './recordsGroup.component';
import { IssueMark } from '../../issue/issueTarget/issueMark.component';
import { RecordRowVm } from './recordRow';
import { MultiInstanceFormRows } from './MultiInstanceFormRows';
import { getFormState } from '../../../features/forms/form-helper';
import { stepIsStarted } from '../../../features/studyRoutes/studyRoute-helpers';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const RecordsGroupedByStep: FC = () => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.Records');

  const {
    patient: { uniqueId },
  } = useContext(PatientCardContext);

  return (
    <div data-test-id={'patient-card-records'}>
      <ExpandedRowHeader
        text={
          <IssueMark
            issueContext={{
              subject: 'Patient',
              linkedPatientUniqId: uniqueId,
              topic: 'Records',
              stepName: null,
            }}
            containerClassName={Style.issueMarkContainer}
          >
            {t('Caption')}
          </IssueMark>
        }
      />

      <div className={Style.recordsGroupContainer}>
        <StepGroups />
        <MultiInstanceFormRows />
      </div>
    </div>
  );
};

const StepGroups: FC = () => {
  const {
    patient: { studyRouteProgress },
  } = useContext(PatientCardContext);
  const study = useStudy();

  const stepVms = useMemo(() => {
    return studyRouteProgress.steps
      .filter((step) => step.state !== RouteProgressStateEnum.None)
      .filter((step) => step.forms.length > 0)
      .map((step) => {
        const isActive =
          step.state === RouteProgressStateEnum.InProgress || step.state === RouteProgressStateEnum.InProgressManually;

        return {
          name: step.name,
          rows:
            step.forms.map<RecordRowVm>((form) => {
              const fillingProgress = form.formResultId
                ? form.fillingProgress
                : stepIsStarted(step.state) && form.initialTotalFieldsCount
                ? { filled: 0, total: form.initialTotalFieldsCount, formLifeCycle: form.lifecycleState }
                : null;

              return {
                formConfigId: form.formConfigId,
                formType: form.formType,
                state: getFormState(
                  form.formResultId,
                  form.lifecycleState,
                  form.isSkipped,
                  fillingProgress,
                  step.state,
                  !!study?.hasEcrf,
                ),
                isSkipped: form.isSkipped ?? undefined,
                fillingProgress: fillingProgress,
                formLifeCycle: form.lifecycleState,
                formResults: [
                  {
                    formResultId: form.formResultId ?? undefined,
                    stepIsActive: isActive,
                    stepName: step.name,
                    isEditable: form.lastVersionIsEditable,
                    lastFormResultVersion: form.lastVersionNumber,
                  },
                ],
                isMultiInstance: false,
              } satisfies RecordRowVm;
            }) ?? [],
        };
      });
  }, [study?.hasEcrf, studyRouteProgress.steps]);

  return (
    <>
      {stepVms.map((step) => (
        <RecordsGroup key={step.name} title={step.name} isExpandDefault={true}>
          {step.rows?.map((row) => (
            <RecordRow key={row.formConfigId} type={'groupedByStep'} vm={row} withEditButton withVersion />
          ))}
        </RecordsGroup>
      ))}
    </>
  );
};
