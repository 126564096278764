import { SerializedNode, SerializedNodes } from '@craftjs/core';
import { IFieldGroupContainerProps } from 'src/features/forms/base/controls/containers/FieldGroupContainer';
import { ConditionalFieldBehaviorEnum } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../provider/formFill.context';
import { isFieldWithOptions } from '../../uiEditor-helper';
import { DataType } from '../../uiEditor';

export type NavItem = {
  nodeId: string;
  text: string;
  children: NavItem[];
};

export const useGetNavigationTree = () => {
  const nodes = useContextSelector(FormFillContext, (x) => x.nodes);
  const values = useContextSelector(FormFillContext, (x) => x.values);
  if (!nodes) return [];
  return recursiveFunc('ROOT', nodes, values);
};

export function isRefContainer(
  node: SerializedNode,
): node is Omit<SerializedNode, 'props'> & { props: IFieldGroupContainerProps } {
  // @ts-ignore
  return node.type.resolvedName === 'FieldGroupContainer';
}

const recursiveFunc = (startNodeId: string, nodes: SerializedNodes, values: DataType | undefined): NavItem[] => {
  const linkedNodes = nodes[startNodeId].linkedNodes;
  const children = Object.entries(linkedNodes || {})
    .filter(([optionId]) => {
      const p = nodes[startNodeId].props;
      const withOptions = isFieldWithOptions(p);
      if (!withOptions) return true;

      const option = p.options.find((o) => o.key === +optionId && o.withConditionalField);
      const value = values?.[p.dataKey];
      const optionIsSelected = Array.isArray(value) ? value.includes(option?.value) : value === option?.value;
      return option?.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility || optionIsSelected;
    })
    .flatMap(([, nodeId]) => recursiveFunc(nodeId, nodes, values));

  if (children.length > 0) return children;

  return nodes[startNodeId].nodes.reduce((arr: NavItem[], nodeId: string) => {
    const node = nodes[nodeId];
    if (isRefContainer(node) && !!node.props.useForNavigation) {
      const newNavItem: NavItem = {
        nodeId: nodeId,
        text: node.props.title ?? nodeId,
        children: recursiveFunc(nodeId, nodes, values),
      };

      return [...arr, newNavItem];
    }

    return [...arr, ...recursiveFunc(nodeId, nodes, values)];
  }, []);
};
