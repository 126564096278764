import React from 'react';
import clsx from 'clsx';
import { IAnalyzesPresetRowVm } from './Analyzes.component';
import { ColumnDef } from '@tanstack/react-table';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { buttonColumn } from 'uikit/table/columns/ButtonColumn';
import { ReactComponent as Chevron } from '../../assets/img/patient/chevronPatientRow.svg';
import TableCommonStyles from 'uikit/table/appTableCommonStyles.module.scss';
import { BulletPoints } from 'uikit/BulletPoints';

export const usePresetColumns = (): ColumnDef<IAnalyzesPresetRowVm>[] => {
  const { t } = useScopedTranslation('StudySettingsPage.AnalyzesPreset', 'dev');

  return [
    {
      accessorKey: 'name',
      header: t('PresetNameLabel'),
      enableSorting: false,
      meta: {
        cellClassName: 'break-words',
      },
    },
    {
      accessorKey: 'databaseFilename',
      header: t('DbFileNameLabel'),
      enableSorting: false,
      meta: {
        cellClassName: 'break-words',
        isColored: true,
      },
    },
    {
      accessorKey: 'tableName',
      header: t('TableNameLabel'),
      enableSorting: false,
      meta: {
        cellClassName: 'break-words',
      },
    },
    {
      accessorKey: 'keyColumn',
      header: t('KeyColumnLabel'),
      enableSorting: false,
      meta: {
        cellClassName: 'break-words',
        isColored: true,
        dontUseHeaderWidth: true,
      },
    },
    {
      id: 'columnsWithData',
      header: t('ColumnsWithDataLabel'),
      cell: ({ row }) => <BulletPoints values={row.original.columnsWithData} maxItemsCount={2} />,
      meta: {
        dontUseHeaderWidth: true,
      },
    },
    {
      accessorKey: 'analysisQuantityLimit',
      header: t('MaxQuantity'),
      enableSorting: false,
      size: 20,
      meta: {
        headerClassName: clsx(TableCommonStyles.wrap),
        isColored: true,
        centered: true,
      },
    },
    buttonColumn({
      id: 'button',
      icon: Chevron,
      onClick: ({ row }) => row.original.onButtonClick(),
    }),
  ];
};
