﻿import React, { FC } from 'react';
import { FormLifecycleState, IFillingProgress, RouteProgressStateEnum } from 'src/services/api/api-client';
import Style from './form-helper.module.scss';

import { ReactComponent as CrossIcon16 } from 'src/assets/img/formState/cross_16.svg';
import { ReactComponent as DoubleCrossIcon16 } from 'src/assets/img/formState/double_cross_16.svg';
import { ReactComponent as DotIcon16 } from 'src/assets/img/formState/dot_16.svg';
import { ReactComponent as CheckIcon16 } from 'src/assets/img/formState/check_16.svg';
import { ReactComponent as DoubleCheckIcon16 } from 'src/assets/img/formState/double_check_16.svg';
import { ReactComponent as Revoked16 } from 'src/assets/img/formState/revoked_16.svg';

import { ReactComponent as EditIcon20 } from 'src/assets/img/common/edit_20.svg';
import { ReactComponent as CrossIcon20 } from 'src/assets/img/formState/cross_20.svg';
import { ReactComponent as ResetIcon20 } from 'src/assets/img/common/reset_20.svg';
import { ReactComponent as CheckIcon20 } from 'src/assets/img/formState/check_20.svg';
import { ReactComponent as DoubleCheckIcon20 } from 'src/assets/img/formState/double_check_20.svg';
import { ReactComponent as DoubleCrossIcon20 } from 'src/assets/img/formState/double_cross_20.svg';
import clsx from 'clsx';

export type FormStateType = 'filled' | 'filledAndApproved' | 'missed' | 'missedAndApproved' | 'none' | 'recalled';

export const getFormState = (
  formResultId: number | undefined | null,
  formLifecycleState: FormLifecycleState | undefined | null,
  isSkipped: boolean | undefined | null,
  fillingProgress: IFillingProgress | null | undefined,
  stepState: RouteProgressStateEnum | null,
  studyHasEcrf: boolean,
): FormStateType => {
  if (studyHasEcrf) {
    const isPassed = stepState === RouteProgressStateEnum.Done || stepState === RouteProgressStateEnum.Missed;
    const isSubmitted = formLifecycleState === FormLifecycleState.Submitted;
    const isApproved = formLifecycleState === FormLifecycleState.Approved;
    const isRecalled = formLifecycleState === FormLifecycleState.Recalled;

    switch (true) {
      case isRecalled:
        return 'recalled';
      case isApproved && isSkipped:
        return 'missedAndApproved';
      case isSubmitted && isSkipped:
        return 'missed';
      case isApproved:
        return 'filledAndApproved';
      case isSubmitted:
        return 'filled';
      case !!formResultId:
        return 'none';
      case isPassed:
        return 'missed';
      default:
        return 'none';
    }
  } else {
    const isPassed = stepState === RouteProgressStateEnum.Done || stepState === RouteProgressStateEnum.Missed;

    switch (true) {
      case formResultId && fillingProgress && fillingProgress.filled === fillingProgress.total:
        return 'filled';
      case !!formResultId:
        return 'none';
      case isPassed:
        return 'missed';
      default:
        return 'none';
    }
  }
};

export const getFormStateMultiInstance = (
  studyHasEcrf: boolean,
  aggregatedLifecycleState: FormLifecycleState | null,
): FormStateType | undefined => {
  if (!studyHasEcrf || aggregatedLifecycleState === null) return undefined;

  switch (true) {
    case aggregatedLifecycleState === FormLifecycleState.Recalled:
      return 'recalled';
    case aggregatedLifecycleState === FormLifecycleState.Approved:
      return 'filledAndApproved';
    case aggregatedLifecycleState === FormLifecycleState.Draft:
      return 'none';
    default:
      return 'filled';
  }
};

export const FormStateIcon: FC<{
  state: FormStateType | undefined;
  variant: 'PatientCard' | 'FormOverview';
  className?: string;
}> = ({ state, variant, className }) => {
  if (!state) return <></>;

  const CardIcon = {
    filledAndApproved: DoubleCheckIcon16,
    filled: CheckIcon16,
    missedAndApproved: DoubleCrossIcon16,
    missed: CrossIcon16,
    none: DotIcon16,
    recalled: Revoked16,
  }[state];

  const OverviewIcon = {
    filledAndApproved: DoubleCheckIcon20,
    filled: CheckIcon20,
    missedAndApproved: DoubleCrossIcon20,
    missed: CrossIcon20,
    none: EditIcon20,
    recalled: ResetIcon20,
  }[state];

  return variant === 'PatientCard' ? (
    <CardIcon className={clsx(Style.icon, className)} />
  ) : (
    <OverviewIcon className={clsx(Style.icon, className)} />
  );
};

export const getFormStateColor = (state: FormStateType | undefined, variant: 'PatientCard' | 'FormOverview') => {
  if (!state) return undefined;

  return {
    filledAndApproved: Style.green,
    filled: Style.grey,
    missedAndApproved: Style.green,
    missed: Style.grey,
    none: variant === 'PatientCard' ? Style.black : Style.grey,
    recalled: Style.red,
  }[state];
};
