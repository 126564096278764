import React from 'react';
import Lottie from 'react-lottie-player';
import LoadingAnimation from 'src/assets/animations/save.json';
import { Props } from './loader';
import clsx from 'clsx';

import Styles from './loader.module.css';
import { buildTestId } from '../../../helpers/test-helpers';

export const Loader = (props: Props) => (
  <div className={clsx(Styles.base, props.styles)} data-test-id={buildTestId(props.testId, 'loader')}>
    <Lottie
      animationData={LoadingAnimation}
      style={{ height: '100%', width: '100%' }}
      play={true}
      loop
      segments={[1, 114]}
    />
  </div>
);
