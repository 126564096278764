import { useEditor } from '@craftjs/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UndoIcon } from 'assets/img/formBuilder/undo.svg';
import clsx from 'clsx';
import styles from './UIEditorSaveResetControls.module.scss';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { AppButton } from 'uikit/buttons';
import { AppButtonWithMenu } from 'uikit/buttons/buttonWithMenu/buttonWithMenu.component';
import { DropDownMenuOption } from 'uikit/dropDownMenu/dropDownMenu';

type Props = {
  disabled: boolean;
  isShowControls: boolean;
  save: () => void;
  saveAs: () => void;
  canDelete?: boolean;
  delete?: () => void;
  resetToDefault: () => void;
  onPreviewOpen?: () => void;
};

export const UIEditorSaveResetControls: React.FC<Props> = (props) => {
  const { actions, query, canUndo, canRedo } = useEditor((state, queryParam) => ({
    enabled: state.options.enabled,
    canUndo: queryParam.history.canUndo(),
    canRedo: queryParam.history.canRedo(),
  }));

  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();

  const options = useMemo(
    () =>
      [
        {
          key: 0,
          text: commonLocalizer('Common_SaveAs'),
          action: props.saveAs,
        },
        {
          key: 1,
          text: t('StudySettingsPage.FormBuilder.copy_to_clipboard'),
          action: () => {
            const json = query.serialize();
            navigator.clipboard.writeText(json);
          },
        },
      ] satisfies DropDownMenuOption[],
    [commonLocalizer, props, query, t],
  );

  if (!props.isShowControls) {
    return null;
  }

  return (
    <div className={styles.controls}>
      <AppButton
        variant={'icon-link'}
        colorSchema={'primary'}
        onClick={() => actions.history.undo()}
        disabled={props.disabled || !canUndo}
        Icon={UndoIcon}
        className={styles.button}
      />
      <AppButton
        variant={'icon-link'}
        colorSchema={'primary'}
        onClick={() => actions.history.redo()}
        disabled={props.disabled || !canRedo}
        Icon={UndoIcon}
        className={clsx(styles.button, styles.redo)}
      />
      <AppButton
        variant={'icon-link'}
        colorSchema={'primary'}
        onClick={props.onPreviewOpen}
        text={t('StudySettingsPage.FormBuilder.preview')}
        className={styles.button}
        disabled={props.disabled}
      />
      <AppButtonWithMenu
        options={options}
        buttonText={commonLocalizer('Common_Save')}
        onClick={props.save}
        className={styles.saveButton}
        disabled={props.disabled}
        isLoading={props.disabled}
      />
      <AppButton
        variant={'button'}
        colorSchema={'destroy'}
        text={commonLocalizer('Common_Delete')}
        onClick={props.delete}
        disabled={props.disabled || !props.canDelete}
      />
    </div>
  );
};
