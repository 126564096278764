import React from 'react';
import { Layers } from '@craftjs/layers';
import { CustomLayer } from '../../customLayer/customLayer';
import Style from './viewport.module.css';
import { useEditor } from '@craftjs/core';
import clsx from 'clsx';

export const LayersPanel = () => {
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <div className={clsx(Style.layersPanel, { [Style.visibleNone]: !enabled })}>
      <div>Layers</div>
      <Layers expandRootOnLoad={true} renderLayer={CustomLayer} />
    </div>
  );
};
