import React from 'react';
import { RPDF } from '../../../react-pdf';

export const WarningScoreIcon: React.FC = () => {
  return (
    <RPDF.Svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        fillRule="evenodd"
        d="M4.63834 1.49601C5.02424 0.834667 5.97984 0.83466 6.36575 1.496L9.8664 7.4951C10.2554 8.16176 9.77455 8.9991 9.0027 8.9991H2.00151C1.22966 8.9991 0.748793 8.16177 1.13779 7.49511L4.63834 1.49601ZM5.91246 5.60935C5.90515 5.82718 5.72644 6 5.50848 6C5.29153 6 5.11327 5.82874 5.10459 5.61196L5.02007 3.50122C5.00912 3.22771 5.22789 3 5.50162 3C5.77412 3 5.99243 3.22575 5.98329 3.4981L5.91246 5.60935ZM5.49845 7C5.64912 7 5.76987 7.04699 5.86068 7.14098C5.95356 7.23497 6 7.35519 6 7.50164C6 7.6459 5.95356 7.76503 5.86068 7.85902C5.76987 7.95301 5.64912 8 5.49845 8C5.34985 8 5.2291 7.95301 5.13622 7.85902C5.04541 7.76503 5 7.6459 5 7.50164C5 7.35738 5.04541 7.23825 5.13622 7.14426C5.2291 7.04809 5.34985 7 5.49845 7Z"
        fill="#E75223"
      />
    </RPDF.Svg>
  );
};
