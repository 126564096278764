import React, { FC, PropsWithChildren, ReactText, useCallback, useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as PrismaCheck } from '../assets/img/notification/prisma_check_20.svg';
import { ReactComponent as ReportFilled } from '../assets/img/notification/report_filled_20.svg';
import { ReactComponent as NewComment } from '../assets/img/notification/new_comment_20.svg';
import { ReactComponent as IssueDeadline } from '../assets/img/notification/issue_notification_20.svg';
import { ReactComponent as FormDeadline } from '../assets/img/notification/form_notification_20.svg';
import { NotificationBell } from 'src/components/notifications/bell/NotificationBell';
import { NotificationType } from 'src/services/api/api-client';
import { useTranslation } from 'react-i18next';
import { LocalizedResourceDictionaryKeys } from '../application/localisation/i18next';
import { showNotificationToast } from '../components/toast/toast-helper';
import {
  getNotificationCountsQueryKey,
  useGetNotificationBatchesQuery,
} from '../services/api/api-client/NotificationQuery';
import { NotificationBatchToastMessage } from './NotificationBatchToastMessage';
import { useFormEditingAndOverview } from '../features/forms/useFormEditingAndOverview';
import { getPatientRouteProgress } from '../services/api/api-client/StudyRoutesClient';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { useContextSelector } from 'use-context-selector';
import { IssuesContext } from 'src/components/issue/provider/issues.context';
import { readNotification } from 'src/services/api/api-client/NotificationClient';
import { useQueryClient } from '@tanstack/react-query';

const icon: { [key in NotificationType]: any } = {
  [NotificationType.Other]: undefined,
  [NotificationType.PrismaCloudCheck]: ReportFilled,
  [NotificationType.PrismaCheck]: PrismaCheck,
  [NotificationType.NewSurveyAssigned]: undefined,
  [NotificationType.SurveyIsExpired]: undefined,
  [NotificationType.NewIssueComment]: NewComment,
  [NotificationType.IssueDeadline]: IssueDeadline,
  [NotificationType.FormDeadline]: FormDeadline,
};

export type OpenFormArgsType = {
  patientId: string;
  patientUniqueId: string;
  formId: number;
  formType: string;
  stepName: string;
};

type NotificationsContextValue = {
  openForm: (args: OpenFormArgsType) => Promise<void>;
  dismissAllToasts: () => void;
};

export const NotificationsContext = React.createContext<NotificationsContextValue>({
  openForm: async () => {},
  dismissAllToasts: () => {},
});

const setsAreEqual = (set1: Set<number>, set2: Set<number>) => {
  for (const item1 of set1) {
    if (!set2.has(item1)) return false;
  }
  return true;
};

export const NotificationProvider: FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const study = useStudy();
  const client = useQueryClient();

  const batchesQuery = useGetNotificationBatchesQuery(
    {
      studyIds: [study?.id ?? 0],
      isRead: false,
    },
    {
      suspense: false,
      enabled: !!study?.id,
    },
  );

  const displayedToasts = useRef<{ toastId: React.ReactText; sourceNotificationIds: Set<number> }[]>([]);

  //#region Context value

  const formEditingAndFilling = useFormEditingAndOverview();

  const openForm = useCallback(
    async (args: OpenFormArgsType) => {
      const routeProgress = await getPatientRouteProgress(args.patientId);
      const formResultId = routeProgress.steps
        .find((step) => step.name === args.stepName)!
        .forms?.find((x) => x.formType === args.formType)?.formResultId;

      if (formResultId) {
        formEditingAndFilling.openOverview({
          patientId: args.patientId,
          formResultId: formResultId,
        });
      } else {
        formEditingAndFilling.startFilling({
          routeProgress,
          formId: args.formId,
          patientId: args.patientId,
          patientUniqueId: args.patientUniqueId,
          stepName: args.stepName,
        });
      }
    },
    [formEditingAndFilling],
  );

  const dismissAllToasts = useCallback(() => {
    for (const toastToDismiss of displayedToasts.current) {
      toast.dismiss(toastToDismiss.toastId);
    }
  }, []);

  const dismissToastById = useCallback((toastId: ReactText) => {
    toast.dismiss(toastId);
  }, []);

  useEffect(() => {
    return dismissAllToasts;
  }, [dismissAllToasts]);

  //#endregion

  const openIssueView = useContextSelector(IssuesContext, (x) => x.openIssueView);

  const sourceIdSets = useMemo(
    () =>
      batchesQuery.data?.map((x, i) => ({
        batchIndex: i,
        set: new Set(x.sourceNotificationIds),
      })) ?? [],
    [batchesQuery.data],
  );

  // This useEffect displays toasts
  useEffect(() => {
    const batches = batchesQuery.data ?? [];
    const newBatches = sourceIdSets
      .filter((x) => !displayedToasts.current.some((y) => setsAreEqual(x.set, y.sourceNotificationIds)))
      .map((x) => ({ index: x.batchIndex, batch: batches[x.batchIndex] }));

    for (const item of newBatches) {
      const batch = item.batch;
      const batchIndex = item.index;
      const type = NotificationType[batch.type] as keyof typeof NotificationType;

      const openIssueViewHandler = (x: number): void => {
        openIssueView?.(x);
        dismissToastById(toastId);
      };

      const openFormHandler = async (x: OpenFormArgsType): Promise<void> => {
        await openForm(x);
        await readNotification(batch.sourceNotificationIds[0]);
        await client.invalidateQueries(getNotificationCountsQueryKey());
        dismissToastById(toastId);
      };

      const toastId = showNotificationToast(
        <NotificationBatchToastMessage batch={batch} openIssueView={openIssueViewHandler} openForm={openFormHandler} />,
        t(`Notifications.Title.${type}` as LocalizedResourceDictionaryKeys, {
          count: batch.sourceNotificationIds.length,
        }),
        icon[batch.type],
      );

      displayedToasts.current.push({ toastId: toastId, sourceNotificationIds: sourceIdSets[batchIndex].set });
    }
  }, [
    t,
    batchesQuery.data,
    formEditingAndFilling.startFilling,
    openIssueView,
    sourceIdSets,
    openForm,
    dismissToastById,
    client,
    study,
  ]);

  return (
    <NotificationsContext.Provider value={{ openForm, dismissAllToasts }}>
      <NotificationBell />
      {props.children}
      {formEditingAndFilling.element}
    </NotificationsContext.Provider>
  );
};
