import { SerializedNode, SerializedNodes } from '@craftjs/core';
import { createContext } from 'use-context-selector';
import {
  IFormDto,
  IFormResultVersionDto,
  PatientDto,
  FieldSkipReason,
  FieldEditReasonWithStaff,
} from '../../../../services/api/api-client';
import { ValidationOptions } from '../../toolbox/settingsPanel/content/Validation.component';
import { DataType, SkippedFieldsDataType } from '../uiEditor';

export type DataBlockValidation = {
  [nodeId: string]: { validationState: 'Ok' | 'Fail'; text: string };
};

export type Page = {
  nodeId: string;
  pageNumber: number;
};

export type FormMode = 'Create' | 'Edit' | 'Overview' | 'BuildingPreview';

export type PageStack = Page[];

export interface FormFillContextInterface {
  mode: FormMode;
  formConfig: IFormDto;
  formResultVersion: IFormResultVersionDto;
  stepName?: string;
  resetState: () => void;

  patient: PatientDto | undefined;

  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;

  initialValues: DataType;
  values: DataType;
  notEditableValues: SerializedNode[];
  setValue: (key: string, value: string | string[] | undefined, options?: { dontTouchField?: boolean }) => void;
  removeValue: (key: string) => void;

  fieldEditReasons: { [key: string]: FieldEditReasonWithStaff };

  initialSkippedFieldsData: SkippedFieldsDataType;
  skippedFieldsData: SkippedFieldsDataType;
  markFieldAsSkipped: (dataKey: string, skipReason: FieldSkipReason) => void;
  unmarkSkippedField: (dataKey: string) => void;

  touchedFields: { [nodeId: string]: boolean };

  nodes: SerializedNodes;
  setNodes: (nodes: SerializedNodes) => void;

  dataBlockValidation: DataBlockValidation;
  setDataBlockValidation: (data: DataBlockValidation) => void;
  removeDataBlockValidation: (nodeId: string) => void;
  triggerValidation: (options: ValidationOptions) => DataBlockValidation;

  currentPageStack: PageStack | undefined;
  pageStacks: PageStack[];
  goToPage: (page: Page) => void;
  goToNextPage: () => void;
  goToPrevPage: () => void;

  addRefToDataBlockNavigation: (id: string, ref: any) => void;
  navigateToBlock: (dataKey: string | undefined) => void;
}

const defaultValues: Partial<FormFillContextInterface> = {
  mode: 'Create',
  values: {},
  notEditableValues: [],
  touchedFields: {},
  dataBlockValidation: {},
  currentPageStack: undefined,
};

export const FormFillContext = createContext(defaultValues);

export const CommentDataKeyRegex = /_comment$/;
export const CommentDataKey = (dataKey: string) => `${dataKey}_comment`;
