import React from 'react';
import { createContext } from 'use-context-selector';
import { SerializedNodes } from '@craftjs/core';

export interface faqEditorContextInterface {
  onSave?: (layout: SerializedNodes) => void;
  setDesignerMode?: (state: boolean) => void;

  // Node refs object for navigation to block
  nodeRefs: { [nodeId: string]: React.RefObject<HTMLElement> };
  addToNodeRefs?: (nodeId: string, ref: React.RefObject<HTMLElement>) => void;
  removeFromNodeRefs?: (nodeId: string) => void;
}

const defaultValues: faqEditorContextInterface = {
  nodeRefs: {},
};

export const FaqEditorContext = createContext(defaultValues);
