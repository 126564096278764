import React, { useMemo } from 'react';
import { IDoctorStudyDto } from '../../services/api/api-client';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import TableCommonStyles from 'uikit/table/appTableCommonStyles.module.scss';
import styles from './studies.module.scss';
import clsx from 'clsx';
import { useGoToStudyButton } from './goToStudyButton.component';
import { BulletPoints } from 'uikit/BulletPoints';

export const useStudiesColumns = (): ColumnDef<IDoctorStudyDto>[] => {
  const { t } = useTranslation();

  const goToStudyButton = useGoToStudyButton();

  return useMemo<ColumnDef<IDoctorStudyDto>[]>(
    () => [
      {
        id: 'id',
        header: t('StudiesList.Columns.Id'),
        cell: ({ row }) => row.original.id.toString().padStart(3, '0'),
        size: 20,
        meta: {
          centered: true,
        },
      },
      {
        accessorKey: 'studyNumber',
        enableSorting: false,
        size: 20,
        header: t('StudiesList.Columns.Number'),
        meta: { isColored: true, centered: true },
      },
      {
        accessorKey: 'title',
        enableSorting: false,
        header: t('StudiesList.Columns.Title'),
        size: 200,
        meta: {},
      },
      {
        accessorKey: 'duration',
        enableSorting: false,
        size: 110,
        header: t('StudiesList.Columns.Duration'),
        meta: {
          centered: true,
          isColored: true,
          headerClassName: TableCommonStyles.wrap,
        },
      },
      {
        id: 'groups',
        header: t('StudiesList.Columns.Groups'),
        meta: { cellClassName: clsx(TableCommonStyles.nowrap) },
        size: 100,
        cell: ({ row }) => <BulletPoints values={row.original.groups.map((x) => x.title)} />,
      },
      {
        id: 'description',
        header: t('StudiesList.Columns.Description'),
        meta: {
          isColored: true,
          cellClassName: styles.description,
          dontUseHeaderWidth: true,
        },
        cell: ({ row }) => row.original.description,
      },
      goToStudyButton,
    ],
    [goToStudyButton, t],
  );
};
