import React, { FC } from 'react';
import Style from '../useFormOverview.module.scss';
import { IFormResultDto, IFormResultVersionDto } from 'src/services/api/api-client';
import { AppButton } from 'uikit/buttons';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { EditReasonFormHeader } from './EditReasonFormHeader';
import { ReactComponent as EditIcon } from 'src/assets/img/common/edit_20.svg';
import { PermissionsCheck } from 'src/helpers/components/PermissionCheck/PermissionCheck.component';
import { Permissions } from 'src/services/api/api-client';
import { UE_FORM_TYPE } from 'src/components/expandedRow/records/recordRow';
import { useStudy } from 'src/helpers/hooks/useStudy';

/**
 *  @deprecated DEPRECATED - we use it only with old study without eCRF feature
 * */
export const OverviewHeader: FC<{
  formType: string;
  currentFormResultVersion: IFormResultVersionDto;
  currentFormResult: IFormResultDto;
  onEdit: () => void;
}> = ({ formType, currentFormResultVersion, currentFormResult, onEdit }) => {
  const { t } = useScopedTranslation('Forms.Overview');
  const study = useStudy();

  return (
    <div className={Style.modalHeaderContainer}>
      <EditReasonFormHeader
        createdAt={currentFormResultVersion?.createdAt}
        updatedAt={currentFormResultVersion?.updatedAt}
        editReason={currentFormResultVersion?.editReason}
        updateReasonText={currentFormResultVersion?.updateReasonText}
        editorName={currentFormResultVersion?.doctor?.fullName}
      />

      <div className={Style.modalHeaderRightPart}>
        {currentFormResultVersion?.fillingProgress && (
          <>
            <span>{t('FillingProgress')}</span>
            <span>
              {currentFormResultVersion.fillingProgress.filled}/{currentFormResultVersion.fillingProgress.total}
            </span>
          </>
        )}
        {currentFormResultVersion.isEditable && (
          <PermissionsCheck
            permissions={
              formType === UE_FORM_TYPE
                ? Permissions.UeFormEdit
                : currentFormResult.formConfigId === study?.patientFinishingFormId
                ? Permissions.PatientRelease
                : Permissions.PatientCreate
            }
          >
            <AppButton
              testId={'edit-button'}
              text={t('Edit')}
              Icon={EditIcon}
              variant={'icon-link'}
              colorSchema={'secondary'}
              onClick={() => onEdit()}
            />
          </PermissionsCheck>
        )}
      </div>
    </div>
  );
};
