import React, { useMemo } from 'react';
import { RouterProvider, createBrowserRouter, RouteObject, Outlet, Navigate } from 'react-router-dom';
import { PageLoading } from 'uikit/suspense/PageLoading';
import { QuerySuspenseErrorWrapper } from 'src/helpers/retry-helper';
import { Route404ErrorBoundary } from './reactRouterErrorBoundary';
import { AppRoutes } from './routes';
import { LoginPage } from 'src/containers/login/login.container';
import { conditionalConcat } from 'src/helpers/arrayHelpers';
import { useIsAuthorized } from 'src/helpers/auth/auth-interceptor';
import { StudyList } from 'src/containers/adminDashboard/StudyList/StudyList.component';
import { RoleList as RolesPage } from 'src/containers/adminDashboard/RoleList/roleList.component';
import { Users as UsersPage } from './pages/AdminPages';
import { NavAdminBar } from 'src/containers/navigationMenu/navAdminBar.component';
import { Dashboard as DashboardPage } from 'src/containers/dashboard/dashboard.container';
import { Studies as StudiesPage } from 'src/containers/studies/studies.component';
import { StudyInfoPage } from 'src/features/studyInfo/StudyInfoPage';
import { Analyzes as AnalyzesPage } from 'src/containers/analyzes/Analyzes.component';
import { FormBuilder as FormBuilderPage } from 'src/containers/formBuilder/formBuilder';
import { PatientPipelineList as PatientPipelinesPage } from 'src/containers/pipelineEditor/PatientPipelineList';
import { QuestionSchemeBuilder as SurveyBuilderPage } from 'src/containers/questionSchemeBuilder/questionSchemeBuilder';
import { Layout } from './Layout';
import { StudySettingsNavBar } from 'src/containers/navigationMenu/navStudyBar.component';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import { Permissions } from 'src/services/api/api-client';
import { MonitoringPage } from 'src/features/monitoring/MonitoringPage';
import { ParticipantsPage } from 'src/features/participants/ParticipantsPage';
import { FAQ as FAQPage } from 'src/containers/faq/faqPage.component';
import { Impressum as ImpressumPage } from 'src/containers/impressum/impressum.component';
import { FirstPageResolver } from 'src/providers/FirstPageResolver';
import { AccessDenied as AccessDeniedPage } from 'src/containers/accessDenied/AccessDenied.component';
import { useStaffProfile } from 'src/helpers/hooks/useStudy';
import { IssuesProvider } from 'src/components/issue/provider/issues.provider';
import { NotificationProvider } from 'src/providers/notificationProvider';
import { AccessDbProvider } from 'src/providers/accessDbProvider/accessDbProvider';
import { NoPermission as NoPermissionPage } from 'src/containers/noPermission/NoPermission.component';
import { StudyIdChecker } from 'src/helpers/components/StudyCheck.components';

export const AppRouter = () => {
  const isAuth = useIsAuthorized();
  const { hasPermission, hasOneOfPermissions } = useHasPermissions();
  const { profile } = useStaffProfile({ enabled: isAuth, suspense: true });

  const router = useMemo(() => {
    const commonRoutes: RouteObject[] = conditionalConcat(
      [
        {
          index: true,
          element: <FirstPageResolver />,
        },
        {
          path: AppRoutes.Impressum,
          element: <ImpressumPage />,
          async lazy() {
            const { Impressum } = await import('./pages/CommonPages');
            return { Component: Impressum };
          },
        },
        {
          path: AppRoutes.AccessDenied,
          element: <AccessDeniedPage />,
          async lazy() {
            const { AccessDenied } = await import('./pages/CommonPages');
            return { Component: AccessDenied };
          },
        },
        {
          path: AppRoutes.NoPermission,
          element: <NoPermissionPage />,
          async lazy() {
            const { NoPermission } = await import('./pages/CommonPages');
            return { Component: NoPermission };
          },
        },
      ],
      ((profile?.studies.length ?? 0) > 1 ||
        (hasOneOfPermissions(Permissions.StudyManage, Permissions.UserManage, Permissions.RoleAssign) &&
          !!profile?.studies.length)) && [
        {
          path: AppRoutes.Studies,
          element: <StudiesPage />,
          async lazy() {
            const { Studies } = await import('./pages/CommonPages');
            return { Component: Studies };
          },
        },
      ],
    );

    const patientViewRoutes: RouteObject[] = [
      {
        path: AppRoutes.Dashboard,
        element: <DashboardPage />,
        async lazy() {
          const { Dashboard } = await import('./pages/CommonPages');
          return { Component: Dashboard };
        },
      },
      {
        path: AppRoutes.StudyInfoPage,
        element: <StudyInfoPage />,
        async lazy() {
          const { StudyInfo } = await import('./pages/CommonPages');
          return { Component: StudyInfo };
        },
      },
    ];

    const adminRoutes: RouteObject[] = [
      {
        path: AppRoutes.Admin,
        element: (
          <>
            <NavAdminBar />
            <Outlet />
          </>
        ),
        children: conditionalConcat(
          hasPermission(Permissions.StudyManage) && [
            {
              index: true,
              element: <Navigate to={AppRoutes.AdminStudies} />,
            },
            {
              path: AppRoutes.AdminStudies,
              element: <StudyList />,
              async lazy() {
                const { AdminStudies } = await import('./pages/AdminPages');
                return { Component: AdminStudies };
              },
            },
          ],
          hasPermission(Permissions.UserManage) && [
            {
              index: true,
              element: <Navigate to={AppRoutes.Users} />,
            },
            {
              path: AppRoutes.Users,
              element: <UsersPage />,
              async lazy() {
                const { Users } = await import('./pages/AdminPages');
                return { Component: Users };
              },
            },
          ],
          hasPermission(Permissions.RoleAssign) && [
            {
              index: true,
              element: <Navigate to={AppRoutes.Roles} />,
            },
            {
              path: AppRoutes.Roles,
              element: <RolesPage />,
              async lazy() {
                const { Roles } = await import('./pages/AdminPages');
                return { Component: Roles };
              },
            },
          ],
        ),
      },
    ];

    const studySettingsRoutes: RouteObject[] = [
      {
        path: AppRoutes.StudySettings,
        element: (
          <>
            <StudySettingsNavBar />
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <Navigate to={AppRoutes.FormBuilder} />,
          },
          {
            path: AppRoutes.FormBuilder,
            element: <FormBuilderPage />,
            async lazy() {
              const { FormBuilder } = await import('./pages/StudySettingPages');
              return { Component: FormBuilder };
            },
          },
          {
            path: AppRoutes.SurveyBuilder,
            element: <SurveyBuilderPage />,
            async lazy() {
              const { SurveyBuilder } = await import('./pages/StudySettingPages');
              return { Component: SurveyBuilder };
            },
          },
          {
            path: AppRoutes.Analyzes,
            element: <AnalyzesPage />,
            async lazy() {
              const { Analyzes } = await import('./pages/StudySettingPages');
              return { Component: Analyzes };
            },
          },
          {
            path: AppRoutes.PatientPipelines,
            element: <PatientPipelinesPage />,
            async lazy() {
              const { PatientPipelines } = await import('./pages/StudySettingPages');
              return { Component: PatientPipelines };
            },
          },
        ],
      },
    ];

    const monitoringRoutes: RouteObject[] = [
      {
        path: AppRoutes.Monitoring,
        element: <MonitoringPage />,
        async lazy() {
          const { Monitoring } = await import('./pages/MonitoringPages');
          return { Component: Monitoring };
        },
      },
    ];

    const participantsRoutes: RouteObject[] = [
      {
        path: AppRoutes.Participants,
        element: <ParticipantsPage />,
        async lazy() {
          const { Participants } = await import('./pages/ParticipantPages');
          return { Component: Participants };
        },
      },
    ];

    const particularStudyRoute: RouteObject[] = [
      {
        element: (
          <AccessDbProvider>
            <IssuesProvider>
              <NotificationProvider>
                <Outlet />
              </NotificationProvider>
            </IssuesProvider>
          </AccessDbProvider>
        ),
        children: [
          {
            element: <StudyIdChecker />,
            children: [
              {
                path: AppRoutes.FAQPage,
                element: <FAQPage />,
                async lazy() {
                  const { FAQ } = await import('./pages/CommonPages');
                  return { Component: FAQ };
                },
              },
              ...conditionalConcat(
                hasPermission(Permissions.PatientView) && patientViewRoutes,
                hasPermission(Permissions.StudyConfigure) && studySettingsRoutes,
                hasPermission(Permissions.IssueView) && monitoringRoutes,
                hasPermission(Permissions.ParticipantsView) && participantsRoutes,
              ),
            ],
          },
        ],
      },
    ];

    return createBrowserRouter([
      {
        element: <Layout />,
        children: conditionalConcat(
          commonRoutes,
          particularStudyRoute,
          hasOneOfPermissions(Permissions.StudyManage, Permissions.RoleAssign, Permissions.UserManage) && adminRoutes,
        ),
        errorElement: (
          <QuerySuspenseErrorWrapper loadingFallback={<PageLoading loading />}>
            <Route404ErrorBoundary element={<Navigate to={AppRoutes.Root} />} />
          </QuerySuspenseErrorWrapper>
        ),
      },
    ]);
  }, [hasOneOfPermissions, hasPermission, profile.studies.length]);

  const unauthorizedRouter = useMemo(() => {
    return createBrowserRouter([
      {
        children: [
          {
            path: AppRoutes.Root,
            element: <LoginPage />,
            async lazy() {
              const { Login } = await import('./pages/UnauthorizedPages');
              return { Component: Login };
            },
          },
        ],
        errorElement: <Navigate to={AppRoutes.Root} />,
      },
    ]);
  }, []);

  return useMemo(
    () => <RouterProvider router={isAuth ? router : unauthorizedRouter} fallbackElement={<PageLoading loading />} />,
    [isAuth, router, unauthorizedRouter],
  );
};
