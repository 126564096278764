import React from 'react';
import { RPDF } from '../../../react-pdf';

export const FilterIcon: React.FC = () => {
  return (
    <RPDF.Svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        d="M2.11089 1.99976C2.08142 1.99976 2.05315 2.01146 2.03231 2.03231C2.01146 2.05315 1.99976 2.08142 1.99976 2.11089V3.04474C1.99976 3.04476 1.99976 3.04478 1.99976 3.04479C1.99977 3.07132 2.00928 3.09687 2.02639 3.1169L4.30265 4.98584C4.30895 4.99102 4.31499 4.99649 4.32076 5.00226C4.43538 5.11684 4.49979 5.27226 4.49982 5.43433V5.43438V7.80143L5.49986 7.18233V5.43438V5.43433C5.49989 5.27226 5.5643 5.11684 5.67892 5.00226C5.68469 4.99649 5.69074 4.99101 5.69704 4.98583L7.9736 3.11689C7.99071 3.09686 8.00021 3.07132 8.00023 3.04479C8.00023 3.04478 8.00023 3.04476 8.00023 3.04474V2.11089C8.00023 2.08142 7.98852 2.05315 7.96768 2.03231C7.94683 2.01146 7.91857 1.99976 7.88909 1.99976H2.11089ZM1.67875 1.67875C1.79336 1.56414 1.94881 1.49976 2.11089 1.49976H7.88909C8.05118 1.49976 8.20662 1.56414 8.32123 1.67875C8.43584 1.79336 8.50023 1.94881 8.50023 2.11089V3.04479V3.04485C8.50019 3.20692 8.43578 3.36234 8.32117 3.47692C8.3154 3.48269 8.30935 3.48817 8.30304 3.49335L6.02648 5.36229C6.00938 5.38231 5.99988 5.40786 5.99986 5.43438C5.99986 5.4344 5.99986 5.43442 5.99986 5.43444V7.32159C5.99986 7.40817 5.95506 7.48858 5.88145 7.53415L4.38141 8.46279C4.3043 8.51053 4.20739 8.51276 4.12816 8.46863C4.04893 8.4245 3.99982 8.34092 3.99982 8.25023V5.43444C3.99982 5.43442 3.99982 5.4344 3.99982 5.43438C3.9998 5.40786 3.9903 5.38231 3.97319 5.36228L1.69693 3.49334C1.69063 3.48816 1.68458 3.48268 1.67882 3.47692C1.5642 3.36234 1.49979 3.20692 1.49976 3.04485V3.04479V2.11089C1.49976 1.94881 1.56414 1.79336 1.67875 1.67875Z"
        fill="#4E5757"
      />
    </RPDF.Svg>
  );
};
