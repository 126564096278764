import React, { FC } from 'react';
import { QuestionnaireVm } from './ManageSurveysModal';
import { startOfToday } from 'date-fns';
import { useSurveyIcon } from '../../../../application/hooks/useSurveyIcon';
import Style from './manageSurveys.module.scss';
import { AppButton } from '../../../uikit/buttons/button/button.component';
import { ReactComponent as DeleteIcon } from '../../../../assets/img/common/delete.svg';
import { subTimeUnitFromDate, formatDateRange } from '../../../../helpers/date-helpers';
import { Permissions } from 'src/services/api/api-client';
import { PermissionsCheck } from '../../../../helpers/components/PermissionCheck/PermissionCheck.component';

export const Row: FC<{
  questionnaire: QuestionnaireVm;
  onDelete: (questionnaire: QuestionnaireVm) => void;
}> = (props) => {
  const today = startOfToday();
  const { onDelete, questionnaire } = props;
  const canBeDeleted = questionnaire.start > today && questionnaire.id !== null && questionnaire.triggeredByDoctor;

  const surveyIcon = useSurveyIcon(questionnaire.surveyIcon, 'small', 'filled');

  const formattedPeriod = formatDateRange(questionnaire.start, subTimeUnitFromDate(questionnaire.end, 'days'));

  return (
    <div className={Style.row}>
      <div className={Style.typeColumn}>
        <surveyIcon.SvgIcon />
        <span>{questionnaire.surveyType}</span>
      </div>
      <div>{formattedPeriod}</div>
      {canBeDeleted && (
        <PermissionsCheck permissions={Permissions.PatientCreate}>
          <AppButton
            className={Style.actionsColumn}
            variant={'icon-link'}
            colorSchema={'decline'}
            Icon={DeleteIcon}
            onClick={() => onDelete(questionnaire)}
          />
        </PermissionsCheck>
      )}
    </div>
  );
};
