import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import {
  handleAuthenticationSignInCallback,
  handleAuthenticationSignOutCallback,
  SignInRedirectHandler,
  SignOutRedirectHandler,
} from './openid-manager';
import { signInCallbackPath, signOutCallbackPath } from './openid-settings';
import { logger } from '../../../application/logging/logging';

type RemoteError = {
  error: string;
  error_description: string;
  error_uri: string;
  request_uri: string;
  error_state: string;
};

const OutdatedAuthStateMessage = 'No matching state found in storage';

export const OpenIdCallback: React.FC<
  PropsWithChildren<{
    signInRedirectHandler: SignInRedirectHandler;
    signOutRedirectHandler: SignOutRedirectHandler;
    loading?: React.ReactNode | undefined;
    error?: ((props: RemoteError) => React.ReactElement | null) | undefined;
  }>
> = (props) => {
  const url = window.location.pathname;
  const searchParams = new URLSearchParams(window.location.search);

  const internalError = useRef<RemoteError | null>(null);

  const error = internalError?.current?.error ?? searchParams.get('error');
  const error_description = internalError?.current?.error_description ?? searchParams.get('error_description');
  const error_uri = internalError?.current?.error_uri ?? searchParams.get('error_uri');
  const request_uri = internalError?.current?.request_uri ?? searchParams.get('iss');
  const error_state = internalError?.current?.error_state ?? searchParams.get('state');

  const isAuthCallback = url.startsWith(signInCallbackPath);
  const isSignOutCallback = url.startsWith(signOutCallbackPath);
  const [_, setRerender] = useState(0);
  const rerenderWhenRedirectCompletes = useCallback(() => {
    setTimeout(() => setRerender(new Date().getTime()));
  }, []);

  const isOpenIdHandled = useRef(false);
  useEffect(() => {
    if (isOpenIdHandled.current) return;
    if (isAuthCallback) {
      isOpenIdHandled.current = true;

      handleAuthenticationSignInCallback(
        (user) => {
          props.signInRedirectHandler(user);
          rerenderWhenRedirectCompletes();
        },
        (e: any) => {
          logger().error('Auth failed OpenidDict callback error handler', e);
          const errorHeader = e?.message?.includes(OutdatedAuthStateMessage) ? 'wrong_access_schema' : 'common_error';
          internalError.current = {
            error: error ?? errorHeader,
            error_description: error_description || e?.error_description || e?.error || e?.message || e?.toString(),
            error_uri: error_uri ?? '',
            error_state: (error_state || e?.message || e?.toString()) ?? '',
            request_uri: request_uri ?? '',
          };
          rerenderWhenRedirectCompletes();
        },
      );
    }
    if (isSignOutCallback) {
      isOpenIdHandled.current = true;
      handleAuthenticationSignOutCallback(() => {
        props.signOutRedirectHandler();
        rerenderWhenRedirectCompletes();
      });
    }
  }, [isAuthCallback, isSignOutCallback]);

  if (error) {
    return (
      props.error?.({
        error: error,
        error_description: error_description ?? '',
        error_uri: error_uri ?? '',
        request_uri: request_uri ?? '',
        error_state: error_state ?? '',
      }) ?? <>{`ERROR: ${error}. ${error_description}`}</>
    );
  }

  if (isAuthCallback || isSignOutCallback)
    return props.loading ? <div style={{ flex: 1, display: 'flex' }}>{props.loading}</div> : null;

  return <>{props.children}</>;
};
