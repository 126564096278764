export const ReportColors = {
  black: '#001311',
  darkGrey: '#4E5757',
  ultraLightGrey: '#dfe2e2',
  extraLightGrey: '#f7f7f7',
  lightGrey: '#BFC5C5',
  plain: '#7E8B8B',
  lightBlue: '#f1f3fc',
  primary: '#4D69D5',
  aweful: '#D84D21',

  main300: '#9BA7E6',
  red500: '#F55828',
  green500: '#09A07C',
};
