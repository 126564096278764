import React, { useEffect, useState } from 'react';
import { AppSwiperProps } from './appSwiper';
import { Swiper } from 'swiper/react';

export const AppSwiper = (props: AppSwiperProps) => {
  const [swipe, setSwipe] = useState<any>();

  useEffect(() => {
    if (typeof props.swipeIndex === 'number') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      swipe?.slideTo(props.swipeIndex, 2);
    }
  }, [props.swipeIndex, swipe]);

  const swiperWidth = props.slidesPerView * props.slideWidth;

  return (
    <Swiper
      style={{ maxWidth: swiperWidth }}
      initialSlide={0}
      onBeforeInit={setSwipe}
      className={props.containerStyles}
      allowTouchMove={false}
      slidesPerView={props.slidesPerView}
    >
      {props.children}
    </Swiper>
  );
};
