import { SortingState, Updater } from '@tanstack/table-core';
import { OnChangeFn } from '@tanstack/react-table';
import { useCallback, useMemo, useRef } from 'react';

export const useSortBy = <
  TSortBy extends string,
  TQueryParams extends { sortBy?: string | null; desc?: boolean | null },
>(
  queryParams: { sortBy?: TSortBy | null; desc?: boolean | null },
  setQuery: (callback: (old: TQueryParams) => TQueryParams) => void,
  options?: {
    default?: { sortBy: TSortBy; desc: boolean };
  },
): {
  onSortingChange: OnChangeFn<SortingState>;
  sortingState: SortingState;
} => {
  const { sortBy, desc } = queryParams;

  const sortingState: SortingState = useMemo(() => {
    if (sortBy && typeof desc === 'boolean') return [{ id: sortBy, desc: desc }];
    if (options?.default) return [{ id: options.default.sortBy, desc: options.default.desc }];
    return [];
  }, [sortBy, desc, options?.default]);

  const sortingStateRef = useRef(sortingState);
  sortingStateRef.current = sortingState;

  const onSortingChange = useCallback(
    (updaterOrValue: Updater<SortingState>) => {
      const newState = typeof updaterOrValue === 'function' ? updaterOrValue(sortingStateRef.current) : updaterOrValue;

      const sortByArray = newState;
      if (sortByArray.length === 0) {
        setQuery((old: TQueryParams) => ({
          ...old,
          sortBy: undefined,
          desc: undefined,
        }));
        return;
      }
      const sortByValue = sortByArray[0];
      setQuery((old) => ({
        ...old,
        sortBy: sortByValue.id as TSortBy,
        desc: sortByValue.desc,
      }));
    },
    [setQuery],
  );

  return {
    sortingState: sortingState,
    onSortingChange: onSortingChange,
  };
};
