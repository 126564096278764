import React from 'react';
import YouTube from 'react-youtube';
import { useEditor, useNode } from '@craftjs/core';
import { VideoSettings } from './videoSettings';
import Style from './video.module.css';
import clsx from 'clsx';

export type VideoProps = {
  videoId: string;
  height: number;
  width: number;
  margin: number[];
};

const defaultProps = {
  videoId: 'nbw4rXSwjoc',
  width: 640,
  height: 360,
  margin: [0, 0, 0, 0],
};

export const Video = (props: Partial<VideoProps>) => {
  props = {
    ...defaultProps,
    ...props,
  };

  const { enabled: isEditorMode } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));
  const {
    connectors: { connect, drag },
  } = useNode((node) => ({
    selected: node.events.selected,
  }));

  const { videoId, width, height, margin } = props;

  return (
    <div
      style={{
        margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : '',
      }}
      ref={(ref: HTMLDivElement) => connect(drag(ref))}
    >
      <div className={clsx({ [Style.disabled]: isEditorMode })}>
        <YouTube
          videoId={videoId}
          opts={{
            width: width,
            height: height,
          }}
        />
      </div>
    </div>
  );
};

Video.craft = {
  displayName: 'Video',
  props: defaultProps,
  related: {
    toolbar: VideoSettings,
  },
};
