import React, { FC, PropsWithChildren } from 'react';
import { RPDF } from '../react-pdf';

export type FieldType = {
  header: string;
  value?: string | JSX.Element;
};

export const InfoField: React.FC<FieldType> = ({ header, value }) => {
  const localStyles = RPDF.StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: 8,
    },
  });

  const valueIsString = typeof value === 'string';

  return (
    <RPDF.View style={[RPDF.tableStyles.divider, localStyles.container]}>
      <RPDF.Text style={RPDF.typography.heading4}>{header}</RPDF.Text>
      {valueIsString ? <RPDF.Text style={RPDF.typography.text10}>{value}</RPDF.Text> : value}
    </RPDF.View>
  );
};

export const MainField: React.FC<FieldType> = ({ header, value }) => {
  const localStyles = RPDF.StyleSheet.create({
    container: {
      gap: 4,
    },
  });

  return (
    <RPDF.View style={localStyles.container}>
      <RPDF.Text style={RPDF.typography.heading4}>{header}</RPDF.Text>
      <RPDF.Text style={RPDF.typography.text10}>{value}</RPDF.Text>
    </RPDF.View>
  );
};

export const InfoFieldsColumn: FC<PropsWithChildren> = ({ children }) => {
  const localStyles = RPDF.StyleSheet.create({
    container: {
      flex: 1,
    },
  });

  return <RPDF.View style={localStyles.container}>{children}</RPDF.View>;
};
