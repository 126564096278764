import React from 'react';
import { UiEditorComponent } from '../../components/formEditor/uiEditor/uiEditor.component';
import { FormBuilderContextProvider } from './provider/formBuilder.context';

export const FormBuilder = () => {
  return (
    <FormBuilderContextProvider>
      <UiEditorComponent isInDesignerMode={true} />
    </FormBuilderContextProvider>
  );
};
