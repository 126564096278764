import clsx from 'clsx';
import React, { FC } from 'react';
import Style from './tab.module.scss';

export type TabItemProps = {
  text: string;
  className?: string;
  isSelected?: boolean;
  testId?: string;
  onClick?: () => void;
};

export type TabsProps = {
  /**
   * 'Second by default'
   */
  // TODO: Add styles for 'First' and 'Third' tabs variant
  variant?: 'First' | 'Second' | 'Third';
  items: TabItemProps[];
  className?: string;
  testId?: string;
};

export const Tabs: FC<TabsProps> = ({ items, className, testId, variant = 'Second' }) => (
  <div data-test-id={testId} className={clsx(Style.tabContainer, className)}>
    {items.map((x, i) => (
      <div
        key={i}
        className={clsx({ [Style.variantSecond]: variant === 'Second' })}
        data-test-id={x.testId}
        data-is-active={x.isSelected}
        onClick={x.onClick}
      >
        {x.text}
      </div>
    ))}
  </div>
);
