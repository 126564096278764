import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';
import { EditableFieldProps } from './editableField';
import { ReactComponent as Edit } from '../../../../assets/img/common/edit_20.svg';
import { ReactComponent as Cross } from '../../../../assets/img/common/control_cross.svg';
import { ReactComponent as Check } from '../../../../assets/img/common/check_24.svg';
import Style from './editableField.module.css';
import { TypographyStyles } from 'src/styles';
import clsx from 'clsx';
import { AppTextInput } from '../../../uikit/inputs/text/appTextInput.component';

export function changeField<K>(prevState: any, key: keyof K, newValue: K[keyof K]): K {
  return Object.assign(Object.create(Object.getPrototypeOf(prevState)), { ...prevState, [key]: newValue }) as K;
}

export const isString = (key: any): boolean => {
  return typeof key === 'string';
};

export const isNumber = (key: any): boolean => {
  return typeof key === 'number';
};

export const EditableField = (props: EditableFieldProps) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [value, setValue] = useState<any>();

  useEffect(() => {
    setValue(props.fieldValue);
  }, [props.fieldValue]);

  const editHandler = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const applyHandler = useCallback(() => {
    props.onApply(value);
    setIsEditMode(false);
  }, [props, value]);

  const cancelHandler = useCallback(() => {
    setIsEditMode(false);
  }, []);

  const editComponent = useMemo(() => {
    if (isString(props.fieldValue)) {
      return (
        <AppTextInput defaultValue={props.fieldValue} type={'text-area'} onChange={(e) => setValue(e.target.value)} />
      );
    }

    if (isNumber(props.fieldValue)) {
      return (
        <AppTextInput type={'number'} defaultValue={props.fieldValue} onChange={(e) => setValue(e.target.value)} />
      );
    }

    return (
      <AppTextInput defaultValue={props.fieldValue} type={'text-area'} onChange={(e) => setValue(e.target.value)} />
    );
  }, [props.fieldValue]);

  if (!isString(props.fieldValue) && !isNumber(props.fieldValue)) {
    return <></>;
  }

  return (
    <div className={Style.container}>
      <div className={Style.header}>
        <div className={Style.fieldName}>{props.fieldName}</div>
        <div>
          <div className={Style.buttons}>
            {isEditMode ? (
              <>
                <AppButton
                  disabled={props.fieldValue === value}
                  variant={'icon-link'}
                  colorSchema={'confirm'}
                  Icon={Check}
                  onClick={applyHandler}
                />
                <AppButton
                  disabled={false}
                  variant={'icon-link'}
                  colorSchema={'decline'}
                  Icon={Cross}
                  onClick={cancelHandler}
                />
              </>
            ) : (
              <>
                <AppButton
                  disabled={false}
                  variant={'icon-link'}
                  colorSchema={'secondary'}
                  Icon={Edit}
                  onClick={editHandler}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {isEditMode ? (
        editComponent
      ) : (
        <div className={clsx(TypographyStyles.plainText14, Style.fieldValue)}>{props.fieldValue as string}</div>
      )}
    </div>
  );
};
