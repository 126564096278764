import React from 'react';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';
import { useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import { UIEditorBlockWidth } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { ControlsGroup } from '../../components/controlsGroup';
import { PanelContainer } from '../../components/panelContainer';

const blockWidths: DropdownOption[] = [
  {
    key: UIEditorBlockWidth.Sixth,
    text: '1/6',
  },
  {
    key: UIEditorBlockWidth.Fifth,
    text: '1/5',
  },
  {
    key: UIEditorBlockWidth.Quarter,
    text: '1/4',
  },
  {
    key: UIEditorBlockWidth.Third,
    text: '1/3',
  },
  {
    key: UIEditorBlockWidth.Half,
    text: '1/2',
  },
  {
    key: UIEditorBlockWidth.ThreeQuarters,
    text: '3/4',
  },
  {
    key: UIEditorBlockWidth.Stretch,
    text: 'Full width',
  },
];

type BlockProps = {
  size?: string;
};

export type Props = {
  setState?: (props: BlockProps) => void;
};

export const LayoutSettings: React.FC<Props> = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));

  return (
    <PanelContainer header={'Layout'} expanded={false}>
      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.width')} flexible>
        <AppDropDownWithSuggestionInput
          isRequired
          options={blockWidths}
          value={blockWidths.find((x) => x.key === props.size)}
          onChange={(option) => {
            setProp((itemProps: BlockProps) => {
              itemProps.size = option?.key !== undefined ? option.key.toString() : UIEditorBlockWidth.Stretch;
            });
          }}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};
