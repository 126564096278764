import clsx from 'clsx';
import React, { CSSProperties, FC, PropsWithChildren, ReactElement } from 'react';
import styles from './Loading.module.scss';
import Lottie from 'react-lottie-player';
import defaultAnimationData from '../../../assets/animations/save.json';
import { buildTestId } from '../../../helpers/test-helpers';

export type AnimationProps = {
  /** Width and height of animation in px */
  size: number;
  element: ReactElement;
};

export type LoadingProps = PropsWithChildren<{
  loading: boolean;
  /** Render children when loading
   * @default true
   * */
  renderChildren?: boolean;
  testId?: string;
  animation?: AnimationProps;
  loaderClassName?: string;
  containerClassName?: string;
  containerStyle?: CSSProperties;
}>;

const defaultAnimation: AnimationProps = {
  size: 40,
  element: (
    <Lottie
      animationData={defaultAnimationData}
      className={styles.defaultAnimation}
      style={{ width: 40, height: 40 }}
      play={true}
      loop={true}
      segments={[1, 114]}
    />
  ),
};

export const Loading: FC<LoadingProps> = (props) => {
  // TODO: add debouncing function to minimize flashing of fast requests

  const animation = props.animation ?? defaultAnimation;
  const renderChildren = props.renderChildren === undefined || props.renderChildren || !props.loading;

  return (
    <div
      className={clsx(styles.childrenContainer, props.loading && styles.loading, props.containerClassName)}
      style={{ ...props.containerStyle, '--animation-size': `${animation.size}px` } as CSSProperties}
      data-test-id={buildTestId(props.testId, 'loading-container')}
    >
      {renderChildren && props.children}
      {props.loading && (
        <div
          className={clsx(styles.loaderContainer, props.loaderClassName)}
          data-test-id={buildTestId(props.testId, 'loading')}
        >
          {animation.element}
        </div>
      )}
    </div>
  );
};
