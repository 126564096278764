import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useModal } from 'src/application/hooks/useModal';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
import { pascalToCamelCase } from 'src/helpers/error-helpers';
import { camelCaseToSpacedString } from 'src/helpers/string-helper';
import { HookFormTextInput } from 'src/hookFormControls/hookFormTextInput';
import { QueryFactory } from 'src/services/api';
import { IRoleCreationDto, Permissions, RoleCreationDto } from 'src/services/api/api-client';
import { createRole } from 'src/services/api/api-client/UserRolesClient';
import { AppDropdownField } from 'uikit/fields';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { logger } from '../../../application/logging/logging';
import Style from './roleList.module.scss';

export const useRoleCreationModal = () => {
  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const modal = useModal('CLOSED');
  const queryClient = useQueryClient();
  const { handleSubmit, reset, control, setError, formState } = useForm<IRoleCreationDto>();

  const onSubmit = async (data: IRoleCreationDto) => {
    try {
      await createRole(new RoleCreationDto({ name: data.name, permissions: data.permissions }));
      await queryClient.invalidateQueries(QueryFactory.UserRolesQuery.getRolesQueryKey());
      modal.closeModal();
    } catch (ex: any) {
      if (ex.errors && Object.keys(ex.errors).length > 0) {
        for (const errorKey of Object.keys(ex.errors)) {
          const formKey = pascalToCamelCase(errorKey) as keyof IRoleCreationDto;
          setError(formKey, {
            type: 'server',
            message: ex.errors[errorKey][0],
          });
        }
      } else {
        logger().error(ex);
      }
    }
  };

  const permissionOptions: DropdownOption[] = useMemo(
    () =>
      Object.keys(Permissions)
        .filter((x) => !Number(x) && Number(x) !== 0)
        .map((x) => {
          return { key: x, text: camelCaseToSpacedString(x).toString() };
        }),
    [],
  );

  return {
    start: () => {
      modal.openModal();
      reset();
    },
    modal: (
      <DialogModal
        onHide={modal.closeModal}
        visible={modal.visible}
        title={t('Admin.UserRoles.CreateRoleModal.Title')}
        containerClassName={Style.modal}
        bodyClassName={Style.modalBody}
        footer={{
          leftButton: {
            text: commonLocalizer('Common_Cancel'),
            type: 'reset',
            onClick: modal.closeModal,
            disabled: formState.isSubmitting,
          },
          rightButton: {
            text: commonLocalizer('Common_Save'),
            type: 'submit',
            form: 'createRoleModal',
            disabled: formState.isSubmitting,
            isLoading: formState.isSubmitting,
          },
        }}
      >
        <form id={'createRoleModal'} onSubmit={handleSubmit(onSubmit)}>
          <HookFormTextInput
            disabled={formState.isSubmitting}
            control={control}
            name={'name'}
            labelProps={{ text: t('Admin.UserRoles.CreateRoleModal.NameFieldLabel') }}
            placeholder={t('Admin.UserRoles.CreateRoleModal.NameFieldPlaceholder')}
          />
          <Controller
            control={control}
            name={'permissions'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <AppDropdownField
                disabled={formState.isSubmitting}
                isMultiple={true}
                suggestion={true}
                labelProps={{ text: t('Admin.UserRoles.CreateRoleModal.PermissionFieldLabel') }}
                placeholder={t('Admin.UserRoles.CreateRoleModal.PermissionFieldPlaceholder')}
                options={permissionOptions}
                value={permissionOptions.filter((x) => value?.includes(Permissions[x.key]))}
                onChange={(newArr) => onChange(newArr?.map((x) => Permissions[x.key]))}
                errorProps={{ errors: error?.message }}
              />
            )}
          />
        </form>
      </DialogModal>
    ),
  };
};
