﻿import React, { FC, useContext } from 'react';
import clsx from 'clsx';
import { TypographyStyles } from '../../../styles';
import Style from './notes.module.scss';
import { AppAttachmentsView } from '../../uikit/fields/attachments/attachmentsView/appAttachmentsView.component';
import { AppAttachmentValue } from '../../uikit/fields/attachments/attachmentsField/appAttachmentsField';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { useNode } from '@craftjs/core';
import { NoteContext } from './NoteContext';

type NoteFieldProps = {
  label: string;
  value?: string;
  values?: { displayValue: string; key: string; icon?: JSX.Element }[];
} & (
  | {
      attachments: AppAttachmentValue[];
      withoutCollapsible: boolean;
    }
  | { attachments?: undefined }
);

export const NoteField: FC<NoteFieldProps> = (props) => {
  const { id } = useNode();
  const noteContext = useContext(NoteContext);

  return (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Notes',
        linkedPatientUniqId: noteContext?.patientUniqueId,
        fieldDescription: props.label,
        resultId: noteContext.formResult.id || undefined,
        fieldId: id,
      }}
    >
      <div data-test-id={'note-field'} className={Style.noteField}>
        <label className={clsx(TypographyStyles.plainText14, Style.noteFieldLabel)}>{props.label}</label>
        {props.value && <span className={clsx(TypographyStyles.paragraph14, Style.noteFieldValue)}>{props.value}</span>}
        {props.values &&
          props.values.map((x) => (
            <span key={x.key} className={clsx(TypographyStyles.paragraph14, Style.noteFieldValue)}>
              {x.icon}
              {x.displayValue}
            </span>
          ))}
        {props.attachments && (
          <AppAttachmentsView files={props.attachments} withoutCollapsible={props.withoutCollapsible} />
        )}
      </div>
    </IssueMark>
  );
};
