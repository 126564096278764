import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import clsx from 'clsx';
import { ValidateCustomForm } from '../../../../helpers/validation-helpers';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import styles from '../controlsStyle.module.css';
import { isNullOrEmpty } from '../../../../helpers/string-helper';
import {
  tagInputDefaultPropsFactory,
  tagApplicableValidationRules,
  ITagInput,
  ITagInputProps,
} from '../../../../features/forms/base/controls/inputs/TagInputProps';
import {
  useRefForDataBlockNavigation,
  useDefaultValuesForConstructorMode,
  useFieldDataFromUiEditorContext,
  useFieldValidation,
  useSkipReasonText,
} from './base/hooks';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { AppTagInputField } from 'uikit/fields';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';

export const TagInput: ITagInput = (props) => {
  const { size, dataKey, validation, isDisabled, skipCheckBoxText, hasSkipCheckBox, isEditable, isDisableWhenEditing } =
    props;
  const { t } = useTranslation();
  const study = useStudy();

  const { enabled: isConstructorMode } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    connectors: { connect, drag },
    id,
    actions: { setProp },
  } = useNode((state) => ({
    linkedNodes: state.data.linkedNodes,
  }));

  const [tagInputValue, setTagInputValue] = useState<string>();
  const { multipleDefaultValue } = useDefaultValuesForConstructorMode(props);

  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const {
    isOnlyView,
    multipleInputValue,
    setDataBlockFieldValue,
    onSkipReasonChange,
    skipReason,
    isEditMode,
    fieldEditReason,
  } = useFieldDataFromUiEditorContext(dataKey);

  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const blockRef = useRefForDataBlockNavigation();
  // This is a validation of the entered tags
  const { dataBlockValidation, setDataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  // This is a validation of the tag that is being entered now
  const isValid = useCallback(
    (tag: string) => {
      if (isOnlyView) {
        return true;
      }

      const validationResult = ValidateCustomForm({
        validationSettings: { ...validation },
        value: tag,
        skipReason: skipReason,
        options: {
          disable: isDisabled,
          eCRF: study?.hasEcrf,
        },
      });

      setDataBlockValidation?.({
        [id]: {
          validationState: validationResult.length > 0 ? 'Fail' : 'Ok',
          text: validationResult.join('\n'),
        },
      });

      return validationResult.length === 0;
    },
    [id, isDisabled, isOnlyView, setDataBlockValidation, skipReason, study?.hasEcrf, validation],
  );

  const resetValidationResult = useCallback(() => {
    setDataBlockValidation?.({
      [id]: {
        validationState: 'Ok',
        text: '',
      },
    });
  }, [id, setDataBlockValidation]);

  // This effect reset validation on input value changes
  useEffect(() => {
    resetValidationResult();
  }, [resetValidationResult, tagInputValue]);

  const onAddTagHandler = useCallback(
    (tag: string) => {
      if (isNullOrEmpty(tag)) return;

      if (props.toUpperCase) {
        tag = tag.toUpperCase();
      }

      if (isConstructorMode) {
        setProp((setProps: ITagInputProps) => {
          if (!multipleDefaultValue) {
            setProps.defaultValue = [tag];
            return;
          }

          if (multipleDefaultValue?.includes(tag)) return;

          setProps.defaultValue = [...multipleDefaultValue, tag];
        });
      } else {
        if (!isValid(tag)) {
          throw new Error(t('LocalErrors.InvalidPattern'));
        }

        if (!multipleInputValue) {
          setDataBlockFieldValue?.(dataKey, [tag]);
          return;
        }

        if (multipleInputValue?.includes(tag)) {
          return;
        }

        setDataBlockFieldValue?.(dataKey, [...multipleInputValue, tag]);
      }
    },
    [
      props.toUpperCase,
      isConstructorMode,
      setProp,
      multipleDefaultValue,
      isValid,
      multipleInputValue,
      setDataBlockFieldValue,
      dataKey,
      t,
    ],
  );

  const onDeleteTagHandler = useCallback(
    (index: number) => {
      if (isConstructorMode) {
        setProp((setProps: ITagInputProps) => {
          const newArray = multipleDefaultValue?.slice() ?? [];
          newArray.splice(index, 1);
          setProps.defaultValue = newArray;
        });
      } else {
        const newArray = multipleInputValue?.slice() ?? [];
        newArray.splice(index, 1);
        setDataBlockFieldValue?.(dataKey, newArray);
      }
    },
    [dataKey, isConstructorMode, setProp, multipleDefaultValue, multipleInputValue, setDataBlockFieldValue],
  );

  const disabled = useMemo(
    () => !isEditable || isDisabled || isSubmitting || (isDisableWhenEditing && isEditMode),
    [isDisableWhenEditing, isDisabled, isEditMode, isEditable, isSubmitting],
  );

  const editableComponent = (
    <div
      data-test-field-type={'tag-input'}
      className={styles.container}
      style={{ width: size }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef} className={clsx({ [styles.validationError]: !!dataBlockValidation?.text })}>
        <AppTagInputField
          labelProps={{
            text: !props.hideLabel ? props.label || dataKey : undefined,
            isBold: props.isBold,
            tooltip: props.tooltip,
          }}
          inputValue={tagInputValue}
          onInputChanged={setTagInputValue}
          placeholder={props.placeholder}
          values={isConstructorMode ? multipleDefaultValue ?? [] : multipleInputValue ?? []}
          onDeleteTag={onDeleteTagHandler}
          onAddTag={onAddTagHandler}
          disabled={disabled}
          errorProps={{
            errors: dataBlockValidation?.text,
          }}
          skipProps={{
            inputCanBeSkipped: hasSkipCheckBox,
            skipText: skipCheckBoxText,
            onSkipReasonChange: (x) => {
              setTagInputValue(undefined);
              onSkipReasonChange(dataKey)(x);
            },
            skipReason: skipReason,
          }}
        />
      </div>
    </div>
  );

  const viewComponent = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResultVersion?.formResultId,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
    >
      <OverviewField
        label={props.label ?? props.dataKey}
        skipped={!multipleInputValue && skipReason !== undefined}
        skipReason={skipReasonText}
        value={multipleInputValue?.join(', ') || ''}
        editReason={fieldEditReason}
      />
    </IssueMark>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

const TagInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as ITagInputProps,
  }));
  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.placeholder')}
        expanded={!!props.placeholder}
        collapsible
        flexible
        onOpen={() => {
          setProp((prop: ITagInputProps) => {
            prop.placeholder = 'Placeholder';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ITagInputProps) => {
            prop.placeholder = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          multiple
          value={props.placeholder || ''}
          onChange={(e) => {
            setProp((setProps: ITagInputProps) => {
              setProps.placeholder = e.target?.value ? e.target.value : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.toUpperCase')}
          isChecked={!!props.toUpperCase}
          setValue={(newValue) =>
            setProp((setProps: ITagInputProps) => {
              setProps.toUpperCase = newValue ? newValue : undefined;
            }, 500)
          }
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const TagInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <TagInputGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={tagApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

TagInput.craft = {
  props: tagInputDefaultPropsFactory(),
  related: {
    settings: TagInputSettings,
  },
};
