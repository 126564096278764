import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { FormFillNavigationContext } from '../FormFillingNavigationContext';
import { useGetNavigationTree, NavItem } from './useGetNavigationTree';
import Style from './FormFillingNavigationBar.module.scss';
import { useEditor } from '@craftjs/core';
import _ from 'lodash';

export const FormFillingNavigationBar: FC<{ test?: boolean }> = () => {
  const { navBarIsOpen } = useContextSelector(FormFillNavigationContext, (x) => x);
  const navigationTree = useGetNavigationTree();

  const [selectedNodesObj, setSelectedNodesObj] = useState<{ [key: string]: number | undefined }>({});
  const onTopIndentChangeHandler = useCallback((nodeId: string, topIndent: number | undefined) => {
    setSelectedNodesObj((x) => ({ ...x, [nodeId]: topIndent }));
  }, []);

  const selectedNodeId = useMemo(() => {
    const filteredArr = Object.entries(selectedNodesObj).filter(([, value]) => value !== undefined && value >= 0);
    const selectedNode = _.minBy(filteredArr, ([, value]) => value);
    return selectedNode?.[0];
  }, [selectedNodesObj]);

  const renderTree = useCallback(
    (item: NavItem) => (
      <NavigationItem
        key={item.nodeId}
        text={item.text}
        nodeId={item.nodeId}
        onTopIndentChange={onTopIndentChangeHandler}
        selected={item.nodeId === selectedNodeId}
      >
        {item.children.map((x) => renderTree(x))}
      </NavigationItem>
    ),
    [onTopIndentChangeHandler, selectedNodeId],
  );

  return navBarIsOpen ? <div className={Style.container}>{navigationTree.map((item) => renderTree(item))}</div> : <></>;
};

const NavigationItem: FC<
  PropsWithChildren<{
    text: string;
    nodeId: string;
    selected?: boolean;
    onTopIndentChange: (nodeId: string, offsetMiddle: number | undefined) => void;
  }>
> = ({ text, children, nodeId, selected, onTopIndentChange }) => {
  const { dom } = useEditor((__, query) => ({
    dom: query.node(nodeId).get()?.dom,
  }));

  const topIndent = useGetTopIndent(dom);

  // This effect call onTopIndentChange() callback
  useEffect(() => {
    onTopIndentChange(nodeId, topIndent);
  }, [nodeId, onTopIndentChange, topIndent]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      dom?.scrollIntoView({ behavior: 'smooth' });
    },
    [dom],
  );

  return (
    <div className={Style.item}>
      <button className={Style.text} onClick={onClick} data-selected={!!selected}>
        {text}
      </button>
      {children && <div className={Style.children}>{children}</div>}
    </div>
  );
};

const useGetTopIndent = (ref: HTMLElement | null) => {
  const [topIndent, setTopIndent] = useState<number>();
  const parent = document.getElementById('ui-editor-container');

  const setTop = useCallback((parentRef: HTMLElement | null, targetRef: HTMLElement | null) => {
    if (parentRef && targetRef) {
      // Debounce 20px. When the ref hits this area from the top
      setTopIndent(20 - targetRef.getBoundingClientRect().top + (_.first(parentRef.getClientRects())?.y ?? 0));
    } else {
      setTopIndent(undefined);
    }
  }, []);

  useEffect(() => {
    setTop(parent, ref);
    const onScroll = () => setTop(parent, ref);

    parent?.removeEventListener('scroll', onScroll);
    parent?.addEventListener('scroll', onScroll, { passive: true });
    return () => parent?.removeEventListener('scroll', onScroll);
  }, [parent, ref, ref?.firstChild, ref?.offsetTop, setTop]);

  return topIndent;
};
