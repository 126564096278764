import React from 'react';
import { useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import { useScopedTranslation } from '../../../../../application/localisation/useScopedTranslation';
import { IStepContainer } from '../../../../forms/base/controls/containers/StepContainer';
import { RPDF } from '../../../react-pdf';
import { renderNode } from '../../recordReport-helper';
import { recordReportFormControlsResolver } from '../../recordReport.resolver';
import { RecordContext } from '../../RecordContext';

export const StepContainer: IStepContainer = () => {
  const { t } = useScopedTranslation('Reports.Records.Controls');
  const { id } = useNode();
  const nodes = useContextSelector(RecordContext, (v) => v.nodes);
  const linkedNodes = nodes.find((n) => n.id === id)?.linkedNodes;

  return (
    <RPDF.View>
      {linkedNodes &&
        Object.entries(linkedNodes).map(([key, childId]) => {
          // Steps start from 1
          const stepNumber = Number(key) + 1;
          return (
            <RPDF.View key={stepNumber} style={{ marginVertical: 4 }}>
              <RPDF.Text minPresenceAhead={40} style={[RPDF.typography.heading5b, { paddingVertical: 4 }]}>
                {t('StepPrefix', { stepNumber })}
              </RPDF.Text>
              {renderNode(nodes, recordReportFormControlsResolver, childId)}
            </RPDF.View>
          );
        })}
    </RPDF.View>
  );
};
