import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IAdminStudyDto, IUpdatePrismaClientDto, UpdatePrismaClientDto } from '../../../services/api/api-client';
import Style from './editStudy.module.scss';
import { ValidationFormRules } from '../../../helpers/validation-helpers';
import { AppButton } from 'uikit/buttons';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { QueryFactory } from '../../../services/api';
import { pascalToCamelCase } from '../../../helpers/error-helpers';
import { logger } from '../../../application/logging/logging';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppInputError } from 'uikit/wrappers';
import { HookFormTextInput } from '../../../hookFormControls/hookFormTextInput';
import { ReactComponent as WarningIcon } from 'src/assets/img/common/warning.svg';
import { Tooltip } from 'uikit/tooltip/tooltip.component';

export const EditStudySyncSettingsModalContent = (props: IAdminStudyDto & { onClose: () => void }) => {
  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const [internalError, setInternalError] = useState(false);
  const client = useQueryClient();
  const {
    handleSubmit,
    formState: { isValid, isSubmitting, isSubmitSuccessful, dirtyFields },
    clearErrors,
    setError,
    control,
  } = useForm<IUpdatePrismaClientDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      prismaCloudToken: props.isTokenSet ? 'REDACTED' : undefined,
      prismaCloudUrl: props.prismaUrl ?? '',
    },
  });

  const onSubmit = handleSubmit(async (data: IUpdatePrismaClientDto) => {
    try {
      setInternalError(false);
      clearErrors();
      const payload = !dirtyFields.prismaCloudToken
        ? new UpdatePrismaClientDto({ prismaCloudUrl: data.prismaCloudUrl })
        : new UpdatePrismaClientDto(data);
      await QueryFactory.StudyQuery.Client.updatePrismaClientSettings(props.id, payload);
      await client.invalidateQueries(QueryFactory.StudyQuery.getStudyListQueryKey());
      props.onClose();
    } catch (ex: any) {
      if (ex.errors && Object.keys(ex.errors).length > 0) {
        for (const errorKey of Object.keys(ex.errors)) {
          const formKey = pascalToCamelCase(errorKey) as keyof IUpdatePrismaClientDto;
          setError(formKey, {
            type: 'server',
            message: t(`ServerErrors.${ex.errors[errorKey][0]}` as LocalizedResourceDictionaryKeys) as string,
          });
        }
      } else {
        logger().error(ex);
        setInternalError(true);
      }
    }
  });

  return (
    <>
      <form onSubmit={onSubmit} className={Style.container} autoComplete={'off'}>
        <div className={Style.content}>
          <HookFormTextInput
            control={control}
            name={'prismaCloudUrl'}
            labelProps={{ text: t('Admin.StudyList.Settings.Modal.PrismaUrl.FieldLabel') }}
            placeholder={t('Admin.StudyList.Settings.Modal.PrismaUrl.FieldPlaceholder')}
            rules={ValidationFormRules().requiredRule}
          />

          <HookFormTextInput
            control={control}
            name={'prismaCloudToken'}
            labelProps={{
              text: t('Admin.StudyList.Settings.Modal.PrismaToken.FieldLabel'),
              rightSection: dirtyFields.prismaCloudToken ? (
                <Tooltip text={t('Admin.StudyList.Settings.Modal.PrismaToken.Tooltip')}>
                  <WarningIcon className={Style.attentionTooltipIcon} />
                </Tooltip>
              ) : undefined,
            }}
            placeholder={t('Admin.StudyList.Settings.Modal.PrismaToken.FieldPlaceholder')}
            rules={ValidationFormRules().requiredRule}
            type={props.isTokenSet ? 'password' : 'text'}
          />

          <AppInputError
            errors={internalError ? t('Admin.StudyList.Settings.Modal.Error') : undefined}
            hideBorder
            position={'top'}
          >
            <div className={Style.buttonsGroup}>
              <AppButton
                variant={'button'}
                colorSchema={'secondary'}
                text={commonLocalizer('Common_Cancel')}
                type={'reset'}
                onClick={props.onClose}
                className={Style.button}
              />
              <AppButton
                variant={'button'}
                colorSchema={'primary'}
                text={commonLocalizer('Common_Save')}
                type={'submit'}
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting && !internalError}
                hasLoaded={isSubmitSuccessful && !internalError}
                className={Style.button}
              />
            </div>
          </AppInputError>
        </div>
      </form>
    </>
  );
};
