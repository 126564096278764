import React, { FC } from 'react';
import { Outlet, useParams } from 'react-router';
import { StudyNotFoundError } from '../customErrors/customErrors';
import { useStaffProfile } from '../hooks/useStudy';

export const StudyIdChecker: FC = () => {
  const { id } = useParams();
  const { profile } = useStaffProfile();

  if (profile.studies.every((study) => study.id.toString() !== id)) throw new StudyNotFoundError();
  return <Outlet />;
};
