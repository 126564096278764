import React from 'react';
import clsx from 'clsx';
import Style from './appFile.module.css';
import { ReactComponent as FileIcon } from 'src/assets/img/comment/file.svg';
import { ReactComponent as CrossIcon } from 'src/assets/img/formBuilder/statusIcons/cross_16.svg';
import { TypographyStyles } from 'src/styles';
import { AppButton } from '../../../buttons';
import { Tooltip } from '../../../tooltip/tooltip.component';
import { AppAttachmentValue } from '../attachmentsField/appAttachmentsField';

export type FileProps = {
  file: AppAttachmentValue;
  disabled?: boolean;
  onClick?: (f: AppAttachmentValue) => void;
};

export const AppFileComponent = (props: FileProps) => {
  return (
    <div className={Style.container}>
      <FileIcon className={Style.fileIcon} />
      <Tooltip
        text={props.file.fileName}
        styles={Style.tooltip}
        textStyles={TypographyStyles.paragraph12}
        disabled={props.disabled}
      >
        <p className={clsx([TypographyStyles.paragraph14, Style.fileName])}>{props.file.fileName}</p>
      </Tooltip>
      <AppButton
        onClick={() => props.onClick && props.onClick(props.file)}
        variant={'icon-link'}
        colorSchema={'basic'}
        Icon={CrossIcon}
        disabled={props.disabled}
      />
    </div>
  );
};
