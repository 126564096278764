import React, { FC, useMemo } from 'react';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { ReportHeader } from '../components/ReportHeader';
import { ReportPagination } from '../components/ReportPagination';
import { RPDF } from '../react-pdf';
import i18n from 'i18next';
import { FirstPage } from './recordsFirstPage';
import { MissedRecord, RecordsReportOptions } from './recordsReport';
import { RecordsReportContext } from './recordsReport.context';
import { recordReportFormControlsResolver } from './recordReport.resolver';
import { deserializeNodes, renderNode } from './recordReport-helper';
import {
  FormLifecycleState,
  FormResultVersionDto,
  FormScore,
  FormSkipReason,
  IFormDto,
  IFormResultDto,
  IFormResultVersionDto,
  IFormScore,
  ScoreCalculationStatusEnum,
  ScoreGroupEnum,
} from '../../../services/api/api-client';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { ReportColors } from '../report-colors';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { useTranslation } from 'react-i18next';
import { Editor } from '@craftjs/core';
import { WarningScoreIcon } from '../components/svg/10px/WarningScoreIcon';
import { ScoreProblemLegendSection } from '../components/ReportScoreProblemLegend';
import { RecordNoScoreIcon } from '../components/svg/10px/RecordNoScoreIcon';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { RevokeIcon } from '../components/svg/12px/RevokeIcon';
import { DoubleCrossIcon } from '../components/svg/12px/DoubleCrossIcon';
import { DoubleCheckIcon } from '../components/svg/12px/DoubleCheckIcon';
import { CrossIcon } from '../components/svg/12px/CrossIcon';
import { SubmitIcon } from '../components/svg/12px/SubmitIcon';
import { EditIcon } from '../components/svg/12px/EditIcon';
import { FormHeader } from './components/FormHeader';
import { Field } from './components/Field';
import { RecordContext } from './RecordContext';
import { useContextSelector } from 'use-context-selector';

export const RecordsReportTemplate: FC<RecordsReportOptions> = ({
  filledRecords,
  studyNumber,
  studyName,
  patientUid,
  profileFullName,
  missedRecords,
  groups,
  patientTags,
  patientId,
  mandatoryForms,
  multipleForms,
  studyHasEcrf,
}) => {
  const { t } = useScopedTranslation('Reports.Records');

  const isSingleTypeForms = useMemo(
    () => [...new Set(filledRecords.map((x) => x.form.type))].length === 1,
    [filledRecords],
  );

  const headerText = useMemo(() => {
    return isSingleTypeForms ? filledRecords[0].form.type : t('DocumentHeader');
  }, [filledRecords, isSingleTypeForms, t]);

  const approvedRecordsCount = useMemo(
    () =>
      filledRecords
        .flatMap((x) => x.resultDto)
        .filter((x) => x.versions.some((v) => v.lifecycleState === FormLifecycleState.Approved)).length,
    [filledRecords],
  );

  return (
    <RPDF.Document
      language={i18n.language}
      producer={'LM Study'}
      title={headerText}
      author={profileFullName || 'LM User'}
      subject={studyNumber}
    >
      <RecordsReportContext.Provider
        value={{ studyGroups: groups, multipleForms, mandatoryForms, patientId, studyHasEcrf }}
      >
        {!studyHasEcrf && (
          <FirstPage
            data={{
              headerText,
              patientUid,
              filledRecordsCount: filledRecords.length,
              missedRecordsCount: missedRecords.length,
              approvedRecordsCount: approvedRecordsCount,
              studyNumber,
              studyName,
              profileFullName,
              patientTags: patientTags ?? {},
              studyHasEcrf,
            }}
          />
        )}
        {filledRecords.map((record) => (
          <RecordPage
            key={record.resultDto.id}
            title={headerText}
            patientUniqueId={patientUid}
            formResult={record.resultDto}
            isSingleTypeForms={isSingleTypeForms}
            formConfig={record.form}
            stepName={record.stepName}
          />
        ))}
        {missedRecords.length > 0 && (
          <MissedRecordsPage missedRecords={missedRecords} headerText={headerText} patientUniqueId={patientUid} />
        )}
      </RecordsReportContext.Provider>
    </RPDF.Document>
  );
};

export const RecordPage: FC<{
  title: string;
  patientUniqueId: string;
  formResult: IFormResultDto;
  isSingleTypeForms: boolean;
  formConfig: IFormDto;
  stepName: string;
}> = (props) => {
  const { t } = useScopedTranslation('Reports');
  const studyHasEcrf = useContextSelector(RecordsReportContext, (x) => x.studyHasEcrf);

  return (
    <>
      {props.formResult.versions.map((version) => (
        <RPDF.Page key={version.versionNumber} size="A4" style={RPDF.styles.page}>
          <ReportHeader
            titleText={props.title}
            subTitleText={t('PatientUidToHeader', { uniqueId: props.patientUniqueId })}
          />
          <RPDF.View
            fixed={true}
            render={({ subPageNumber }) => {
              return subPageNumber > 1 ? (
                <RecordTypeWithState
                  formType={props.formConfig.type}
                  isSingleTypeForms={props.isSingleTypeForms}
                  version={version}
                  stepName={props.stepName}
                  options={{ forSubPages: true }}
                  studyHasEcrf={studyHasEcrf}
                />
              ) : undefined;
            }}
          />
          <RPDF.View style={{ flexDirection: 'column' }}>
            <RecordTypeWithState
              formType={props.formConfig.type}
              isSingleTypeForms={props.isSingleTypeForms}
              version={version}
              stepName={props.stepName}
              studyHasEcrf={studyHasEcrf}
            />
            <ScoreSection formScore={version.score} scoreStatus={version.scoreStatus} />
            <LegendSection legend={props.formConfig.legend} score={version.score} scoreStatus={version.scoreStatus} />
            <RecallReasonSection reasonText={version.approvalRecallReason} />
          </RPDF.View>
          <RecordContent formResultVersion={version} formResult={props.formResult} formConfig={props.formConfig} />
          <ReportPagination />
        </RPDF.Page>
      ))}
    </>
  );
};

const RecordContent: FC<{
  formResultVersion: IFormResultVersionDto;
  formResult: IFormResultDto;
  formConfig: IFormDto;
}> = (props) => {
  const { t } = useTranslation();

  if (props.formResultVersion.isSkipped)
    return (
      <RPDF.View>
        <Field
          label={t('Forms.Overview.SkippedFormBody.SkipReason')}
          value={
            props.formResultVersion.skipReason === FormSkipReason.NotApplicable
              ? t('Forms.FormSkipReason.NotApplicable')
              : props.formResultVersion.skipReason === FormSkipReason.Missed
              ? t('Forms.FormSkipReason.Missed')
              : undefined
          }
        />
        <Field
          label={t('Forms.Overview.SkippedFormBody.SkipReasonDetails')}
          value={props.formResultVersion.skipReasonDetails}
        />
      </RPDF.View>
    );

  const nodes = deserializeNodes(props.formConfig.layoutSchema);
  return (
    <RecordContext.Provider
      value={{
        formResult: props.formResultVersion.value ?? {},
        skipReasons: props.formResultVersion.fieldSkipReasons,
        nodes,
      }}
    >
      <Editor>{renderNode(nodes, recordReportFormControlsResolver, 'ROOT')}</Editor>
    </RecordContext.Provider>
  );
};

const RecordTypeWithState: FC<{
  formType: string;
  isSingleTypeForms: boolean;
  version: FormResultVersionDto;
  stepName: string;
  options?: { forSubPages?: boolean };
  studyHasEcrf: boolean;
}> = ({ formType, isSingleTypeForms, version, stepName, options, studyHasEcrf }) => {
  if (options?.forSubPages)
    return (
      <RPDF.Text style={[RPDF.typography.heading4, { color: ReportColors.plain, marginBottom: 12 }]}>
        {`${formType} (${stepName}) `}
        <RecordState version={version} options={options} studyHasEcrf={studyHasEcrf} />
      </RPDF.Text>
    );

  return (
    <FormHeader
      title={isSingleTypeForms ? undefined : formType}
      subTitle={stepName}
      formState={<RecordState version={version} studyHasEcrf={studyHasEcrf} />}
    />
  );
};

const RecordState: FC<{
  version: FormResultVersionDto;
  options?: { forSubPages?: boolean };
  studyHasEcrf: boolean;
}> = ({ version, options, studyHasEcrf }) => {
  const t = i18n.t;
  const type = version.createdAt.getTime() !== version.updatedAt.getTime() ? 'Updated' : 'Filled';

  if (!studyHasEcrf)
    return (
      <RPDF.Text>
        {t(`Reports.Records.${type}`, {
          date: localFormat(version.updatedAt, DateFormats.longDate),
          name: version.doctor.fullName,
        })}
      </RPDF.Text>
    );

  const vm: {
    [key in FormLifecycleState]: {
      icon: JSX.Element;
      date: Date | null;
      fullName: string | undefined;
      localizationKey: Parameters<typeof t>[0];
    };
  } = {
    [FormLifecycleState.Recalled]: {
      date: version.approvalIsRecalledAt,
      fullName: version.approvalIsRecalledBy?.fullName,
      icon: <RevokeIcon />,
      localizationKey: 'SubHeader.Recalled',
    },
    [FormLifecycleState.Approved]: {
      date: version.approvedAt,
      fullName: version.approvedBy?.fullName,
      icon: version.isSkipped ? <DoubleCrossIcon /> : <DoubleCheckIcon />,
      localizationKey: 'SubHeader.Approved',
    },
    [FormLifecycleState.Submitted]: {
      date: version.submittedAt,
      fullName: version.submittedBy?.fullName,
      icon: version.isSkipped ? <CrossIcon /> : <SubmitIcon />,
      localizationKey: version.isSkipped ? 'SubHeader.Skipped' : 'SubHeader.Submitted',
    },
    [FormLifecycleState.Draft]: {
      date: version.updatedAt || version.createdAt,
      fullName: version.doctor.fullName,
      icon: <EditIcon />,
      localizationKey:
        version.createdAt.getTime() !== version.updatedAt.getTime() ? 'SubHeader.Edited' : 'SubHeader.Draft',
    },
  };

  const versionStr = t('Reports.Records.Version', { version: version.versionNumber });
  const stateStr = t(`Reports.Records.${vm[version.lifecycleState].localizationKey}` as any, {
    date: localFormat(vm[version.lifecycleState].date!, DateFormats.longDate),
    name: vm[version.lifecycleState].fullName,
  });

  if (options?.forSubPages)
    return (
      <RPDF.Text style={[RPDF.typography.text10, { color: ReportColors.plain }]}>
        {`${versionStr}, ${stateStr}`}
      </RPDF.Text>
    );

  return (
    <>
      <RPDF.Text style={[RPDF.typography.heading4, { marginRight: 4 }]}>{versionStr}</RPDF.Text>
      {vm[version.lifecycleState].icon}
      <RPDF.Text style={{ marginLeft: 2 }}>{stateStr}</RPDF.Text>
    </>
  );
};

const ScoreSection: FC<{ formScore: IFormScore | null; scoreStatus: ScoreCalculationStatusEnum | null }> = ({
  formScore,
  scoreStatus,
}) => {
  const { t } = useTranslation();

  if (!formScore && scoreStatus === ScoreCalculationStatusEnum.Done) {
    return <RPDF.View />;
  }

  const localStyles = RPDF.StyleSheet.create({
    container: {
      backgroundColor: ReportColors.extraLightGrey,
      paddingHorizontal: 8,
    },
    scoreSection: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: 20,
      justifyContent: 'space-between',
    },
    singleSection: {
      flex: 1,
      minWidth: '30%',
      height: 24,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    totalScoreSection: {
      height: 24,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  });

  const renderScoreContent = (score?: number | null) => {
    if (scoreStatus !== ScoreCalculationStatusEnum.Done) {
      return <WarningScoreIcon />;
    }

    if (score === null) {
      return (
        <RPDF.View style={{ gap: 4, flexDirection: 'row' }}>
          <RPDF.Text style={RPDF.typography.text8}>{t('Common_dash')}</RPDF.Text>
          <RecordNoScoreIcon />
        </RPDF.View>
      );
    }

    return <RPDF.Text style={RPDF.typography.text8}>{score?.toFixed(formScore?.decimal ?? 0)}</RPDF.Text>;
  };

  return (
    <RPDF.View style={localStyles.container}>
      <RPDF.View style={localStyles.scoreSection}>
        {formScore?.sectionScore.map((section) => {
          const localScoreStyle = [localStyles.singleSection, {}];
          if (formScore?.hasTotalScore) {
            localScoreStyle.push(RPDF.tableStyles.divider);
          }

          return (
            <RPDF.View key={section.scoreGroup} style={localScoreStyle}>
              <RPDF.Text style={RPDF.typography.heading5}>
                {t(`Forms.Score.ScoreGroup.${ScoreGroupEnum[section.scoreGroup]}` as LocalizedResourceDictionaryKeys)}
              </RPDF.Text>
              {renderScoreContent(section?.score)}
            </RPDF.View>
          );
        })}
      </RPDF.View>
      {(formScore?.hasTotalScore || scoreStatus !== ScoreCalculationStatusEnum.Done) && (
        <RPDF.View style={localStyles.totalScoreSection}>
          <RPDF.Text style={RPDF.typography.heading5}>{t('Forms.Score.TotalScore')}</RPDF.Text>
          {renderScoreContent(formScore?.totalScore)}
        </RPDF.View>
      )}
    </RPDF.View>
  );
};

const RecallReasonSection: FC<{ reasonText: string | null }> = ({ reasonText }) => {
  const { t } = useTranslation();

  if (isNullOrEmpty(reasonText)) return <></>;
  const gap = ' ';

  return (
    <RPDF.Text style={{ flexDirection: 'row', marginTop: 12 }}>
      <RPDF.Text style={RPDF.typography.heading5}>{t('Reports.Records.RecallReasonHeader')}</RPDF.Text>
      <RPDF.Text style={RPDF.typography.heading5}>{gap}</RPDF.Text>
      <RPDF.Text style={RPDF.typography.text8}>{reasonText}</RPDF.Text>
    </RPDF.Text>
  );
};

const LegendSection: FC<{
  legend: string[] | null;
  scoreStatus: ScoreCalculationStatusEnum | null;
  score: FormScore | null;
}> = ({ legend, scoreStatus, score }) => {
  const isAnyLowScore =
    score?.sectionScore.some((x) => x.score === null) || (score?.hasTotalScore && score?.totalScore === null);
  const isCalculationError = scoreStatus !== ScoreCalculationStatusEnum.Done;

  if (!legend && !isAnyLowScore && !isCalculationError) {
    return <RPDF.View />;
  }

  const localStyles = RPDF.StyleSheet.create({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: 12,
      rowGap: 4,
    },
  });

  return (
    <RPDF.View style={{ flexDirection: 'column', marginTop: 12 }}>
      {legend && (
        <RPDF.View style={localStyles.container}>
          {legend.map((text) => (
            <RPDF.Text key={text} style={[RPDF.typography.text8, { color: ReportColors.plain }]}>
              {text}
            </RPDF.Text>
          ))}
        </RPDF.View>
      )}
      <ScoreProblemLegendSection
        scoreStatus={scoreStatus}
        isLowScore={isAnyLowScore}
        view={'records'}
        style={!!legend ? { marginTop: 4 } : {}}
      />
    </RPDF.View>
  );
};

export const MissedRecordsPage: FC<{ headerText: string; patientUniqueId: string; missedRecords: MissedRecord[] }> = ({
  missedRecords,
  headerText,
  patientUniqueId,
}) => {
  const { t } = useTranslation();

  const localStyles = RPDF.StyleSheet.create({
    container: {
      height: 24,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: ReportColors.ultraLightGrey,
      justifyContent: 'space-between',
      paddingHorizontal: 8,
      marginBottom: 12,
    },
    leftHeader: {
      flexDirection: 'row',
      gap: 4,
    },
  });

  return (
    <RPDF.Page size="A4" style={RPDF.styles.page}>
      <ReportHeader
        titleText={headerText}
        subTitleText={t('Reports.PatientUidToHeader', { uniqueId: patientUniqueId })}
      />
      <RPDF.View style={localStyles.container}>
        <RPDF.View style={localStyles.leftHeader}>
          <RPDF.Text style={RPDF.typography.heading2}>{t('Reports.Records.MissedRecords')}</RPDF.Text>
        </RPDF.View>
        <RPDF.Text style={[RPDF.typography.text10, { color: ReportColors.darkGrey }]}>{missedRecords.length}</RPDF.Text>
      </RPDF.View>
      {missedRecords.map((mr, i) => (
        <RPDF.View key={i}>
          <Field label={mr.type} value={mr.stepName} />
        </RPDF.View>
      ))}
      <ReportPagination />
    </RPDF.Page>
  );
};
