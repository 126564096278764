import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'use-query-params';
import { createPagingParams, pagingSortingToBackendRequest } from '../../../helpers/pagination-helper';
import { studyFilterParams, studyFilterToBackendRequest } from './study-helpers';
import { StudyFilters } from './StudyFilters.component';
import { useStudyColumns } from './study.columns';
import { IAdminStudyDto } from '../../../services/api/api-client';
import { QueryFactory } from '../../../services/api';
import { StudyDetailsCard } from './StudyDetails.component';
import TablePageLayout from '../../../components/tablePageLayout/TablePageLayout';
import { AppTable } from 'uikit/table/AppTable';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useSortBy } from 'uikit/table/updateSortByInUrl';
import { ReactComponent as Plus } from 'src/assets/img/common/plus_40.svg';
import { useStudyCreating } from '../../../features/study/useStudyCreating';

export const studyQueryParamsConfig = {
  ...createPagingParams({
    defaultPerPage: 30,
    defaultSortBy: 'id',
    sortingKeys: ['id', 'isTokenActive'] as const,
    defaultDesc: false,
  }),
  ...studyFilterParams(),
};

export const StudyList = () => {
  const { t } = useTranslation('dev');

  const [queryParams, setQueryParams] = useQueryParams(studyQueryParamsConfig);

  const studyList = QueryFactory.StudyQuery.useGetStudyListQuery(
    {
      ...pagingSortingToBackendRequest(queryParams),
      ...studyFilterToBackendRequest(queryParams),
      searchQuery: queryParams.searchQuery,
    },
    {
      suspense: false,
      keepPreviousData: true,
    },
  );

  const sortBy = useSortBy(queryParams, setQueryParams);

  const dataTable = useReactTable<IAdminStudyDto>({
    data: studyList?.data?.data || [],
    columns: useStudyColumns(),
    manualSorting: true,
    enableSortingRemoval: false,
    onSortingChange: sortBy.onSortingChange,
    state: {
      sorting: sortBy.sortingState,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const studyCreating = useStudyCreating();

  return (
    <TablePageLayout>
      <TablePageLayout.Header>
        <TablePageLayout.Header.Row>
          <TablePageLayout.Header.Title
            text={t('Admin.StudyList.Caption')}
            secondaryText={studyList.data?.totalCount.toString()}
          />
        </TablePageLayout.Header.Row>
        <StudyFilters
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          filterFields={['withFailedToken']}
          localizationPrefix={'Admin.StudyList.Filters'}
        />
      </TablePageLayout.Header>
      <TablePageLayout.TableContainer loading={studyList.isPreviousData || studyList.isLoading}>
        <AppTable
          table={dataTable}
          placeholder={{ hide: studyList.isPreviousData || studyList.isLoading, text: t('Admin.StudyList.No_Data') }}
          expandedRow={(row) => <StudyDetailsCard key={row.index} study={row.original} />}
        />
        <TablePageLayout.TableContainer.Pagination
          page={queryParams.page}
          perPage={queryParams.perPage}
          totalCount={studyList?.data?.totalCount || 0}
          changePagination={setQueryParams}
        />
        <TablePageLayout.TableContainer.FloatingButton Icon={Plus} onClick={studyCreating.start} />
      </TablePageLayout.TableContainer>
      {studyCreating.element}
    </TablePageLayout>
  );
};
