import React, { useMemo } from 'react';
import { useSkipReasonText } from 'src/components/formEditor/controls/Inputs/base/hooks';
import { useContextSelector } from 'use-context-selector';
import { ITagInput } from '../../../../forms/base/controls/inputs/TagInputProps';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const TagInput: ITagInput = ({ label, dataKey, skipCheckBoxText }) => {
  const inputValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  const skipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.[dataKey]);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  return <Field label={label ?? dataKey} skipReason={skipReasonText} value={multipleInputValue} />;
};
