import React, { FC } from 'react';
import { FloatingButtonProps } from './floatingButton';
import Style from './floatingButton.module.scss';
import { Tooltip } from '../../tooltip/tooltip.component';
import clsx from 'clsx';

export const FloatingButton: FC<FloatingButtonProps> = (props) => {
  const { Icon, className, tooltip, testId, ...rest } = props;

  return (
    <Tooltip text={tooltip || ''} disabled={!tooltip} styles={Style.tooltip} hostStyles={className} gapSpace={4}>
      <button {...rest} className={clsx(Style.floatingButton, { [className || '']: !tooltip })} data-test-id={testId}>
        <Icon />
      </button>
    </Tooltip>
  );
};
