import React from 'react';
import { useContextSelector } from 'use-context-selector';
import { LocalizedResourceDictionaryKeys } from '../../../../../application/localisation/i18next';
import { useScopedTranslation } from '../../../../../application/localisation/useScopedTranslation';
import { ICheckBoxInput } from '../../../../forms/base/controls/inputs/CheckBoxInput';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const CheckBoxInput: ICheckBoxInput = ({ label, dataKey }) => {
  const { t } = useScopedTranslation('Forms.Controls');

  const inputValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);

  return <Field label={label ?? dataKey} value={t(`checkBox_${inputValue}` as LocalizedResourceDictionaryKeys)} />;
};
