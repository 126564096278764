import React, { useMemo, FC } from 'react';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { IIssueCommentDto } from '../../../services/api/api-client';
import { ReactComponent as Dots } from '../../../assets/img/common/dots_24.svg';
import { ReactComponent as Delete } from '../../../assets/img/common/delete_16.svg';
import Style from './issueComment.module.scss';
import { DropDownMenu } from '../../uikit/dropDownMenu/dropDownMenu.component';
import { useTranslation } from 'react-i18next';
import { DropDownMenuOption } from '../../uikit/dropDownMenu/dropDownMenu';

export const IssueComment: FC<IIssueCommentDto & { onDelete: () => void; hasMenu: boolean; hasNewMark: boolean }> = (
  comment,
) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const optionList: DropDownMenuOption[] = [];

    if (comment.canDelete) {
      optionList.push({
        key: 'Delete',
        icon: <Delete />,
        text: t('Common_Delete'),
        action: comment.onDelete,
        className: Style.deleteOption,
      });
    }

    return optionList;
  }, [comment.canDelete, comment.onDelete, t]);

  return (
    <div className={Style.container} data-test-id={'comment-item'}>
      <div className={Style.header}>
        <div className={Style.leftHeader}>
          <div className={Style.userName}>
            {comment.hasNewMark && <div className={Style.newMark} data-test-id={'comment-item-new-mark'} />}
            <div data-test-id={'comment-item-username'}>{comment.createdByUser}</div>
          </div>
          <div className={Style.date} data-test-id={'comment-item-date'}>
            {localFormat(comment.createdAt, DateFormats.dateTime)}
          </div>
        </div>
        {comment.hasMenu && options.length > 0 && (
          <DropDownMenu
            Icon={Dots}
            options={options}
            colorSchema={'basic'}
            className={Style.visibility}
            testId={'comment-item-menu'}
          />
        )}
      </div>
      <div className={Style.body}>
        <div className={Style.text} data-test-id={'comment-item-message'}>
          {comment.message}
        </div>
      </div>
    </div>
  );
};
