import { DropdownOption } from './appDropdownInput';

export const enumToDropdownOptions = (r: any): DropdownOption[] => {
  return Object.keys(r)
    .filter((x) => !Number(x) && Number(x) !== 0)
    .map((t) => {
      const option: DropdownOption = { key: t, text: t.toString() };
      return option;
    });
};

export const strArrToDropdownOptions = (arr: string[] | undefined) => {
  return arr && arr.length > 0
    ? arr.map((x) => {
        return { key: x, text: x } as DropdownOption<string>;
      })
    : [];
};
