import React from 'react';
import { SettingsPanel } from './settingsPanel/SettingsPanel';
import styles from './barStyles.module.scss';

export const RightBar = () => {
  return (
    <div className={styles.toolboxContainer}>
      <SettingsPanel />
    </div>
  );
};
