import React, { useMemo } from 'react';
import clsx from 'clsx';
import Style from './toolbar.module.css';
import { useNode } from '@craftjs/core';
import { TypographyStyles } from '../../../../styles';
import { ExpandedComponent } from '../../../formEditor/expandedComponent/expandedComponent.control';

export type ToolbarSectionType<T> = {
  title: string;
  props?: (keyof T)[];
  summary?: (props: Record<keyof T, string>) => JSX.Element | string;
  children?: React.ReactChild | React.ReactChild[];
};

export const ToolbarSection = <T,>({ title, props, summary, children }: ToolbarSectionType<T>) => {
  const { nodeProps } = useNode((node) => ({
    nodeProps: props?.reduce((res: any, key: keyof T) => {
      res[key] = (node.data.props as T)[key] || null;
      return res;
    }, {}),
  }));

  const summaryComponent = useMemo(() => {
    return (
      <div className={clsx(TypographyStyles.plainText14, Style.toolbarSectionSummary)}>
        {summary?.(
          props?.reduce((acc: any, key: keyof T) => {
            acc[key] = nodeProps[key];
            return acc;
          }, {}),
        )}
      </div>
    );
  }, [nodeProps, props, summary]);

  return (
    <ExpandedComponent isExpandedAtStart={false} rightHeaderComponent={summaryComponent} header={title}>
      <div className={Style.toolbarSectionContainer}>{children}</div>
    </ExpandedComponent>
  );
};
