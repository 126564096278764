import React, { FunctionComponent, SVGProps } from 'react';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { ToastBody } from './toastBody.component';
import { ReactComponent as Error } from '../../assets/img/common/error_20.svg';
import Style from './toastBody.module.css';
import clsx from 'clsx';
import { ValidationProblemDetails } from '../../services/api/api-client';

export const showNotificationToast = (
  message: string | JSX.Element,
  header: string,
  icon: FunctionComponent<SVGProps<any>>,
) => {
  return toast(<ToastBody Icon={icon} header={header} message={message} />, {
    className: clsx(Style.toast, Style.greyBackground),
  });
};

export const showErrorToast = (error: any) => {
  let message;
  if (error instanceof ValidationProblemDetails) {
    message = error.detail || Object.values(error.errors).join('\n');
  }

  toast(
    <ToastBody
      Icon={Error}
      header={i18n.t('Toast.Headers.ServerError')}
      message={message || i18n.t('ServerErrors.common_error')}
    />,
    {
      className: clsx(Style.toast, Style.redBackground),
    },
  );
};
