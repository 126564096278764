import React, { useCallback, useState, FC } from 'react';
import Style from './PresetInfoModal.module.scss';
import { IAnalysisPresetDto } from '../../../services/api/api-client';
import { ReactComponent as EditIcon } from '../../../assets/img/common/edit_20.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/img/common/delete_20.svg';
import { AppButton } from 'uikit/buttons';
import { DeletingConfirmationDialog } from './DeletingConfirmationDialog';
import { useModal } from '../../../application/hooks/useModal';
import { QueryFactory } from '../../../services/api';
import { useQueryClient } from '@tanstack/react-query';
import { useContextSelector } from 'use-context-selector';
import { AccessDbContext } from '../../../providers/accessDbProvider/accessDb.context';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import PropertiesList from 'uikit/propertiesList/PropertiesList';
import { BulletPoints } from 'uikit/BulletPoints';

export const PresetInfoModal: FC<{
  preset: IAnalysisPresetDto | null;
  onEdit: () => void;
  onHide: () => void;
}> = (props) => {
  const { t } = useScopedTranslation('StudySettingsPage.AnalyzesPreset', 'dev');
  return (
    <AppModalContainer
      visible={!!props.preset}
      onHide={props.onHide}
      title={t('PresetInfoModal.Title')}
      bodyClassName={Style.presetInfoModal}
    >
      {props.preset && <ModalContent preset={props.preset} onHide={props.onHide} onEdit={props.onEdit} />}
    </AppModalContainer>
  );
};

const ModalContent: FC<{ preset: IAnalysisPresetDto; onHide: () => void; onEdit: () => void }> = (props) => {
  const { t } = useScopedTranslation('StudySettingsPage.AnalyzesPreset', 'dev');
  const queryClient = useQueryClient();
  const databaseFilename = useContextSelector(AccessDbContext, (x) => x.fileName);

  const vm = {
    name: props.preset.name,
    databaseFilename: props.preset.databaseFilename,
    tableName: props.preset.scheme![0].tableName,
    keyColumn: Object.keys(props.preset.scheme![0].keyColumnsWithValue!)[0],
    dataColumns: props.preset.scheme![0].dataColumns!,
    analysisQuantityLimit: props.preset.analysisQuantityLimit,
  };

  const deletingConfirmationModal = useModal();
  const [isDeleting, setIsDeleting] = useState(false);

  const onCancelDeleting = useCallback(() => {
    deletingConfirmationModal.closeModal();
  }, [deletingConfirmationModal]);

  const onApplyDeleting = useCallback(async () => {
    setIsDeleting(true);
    try {
      await QueryFactory.AnalyzesQuery.Client.deleteAnalysisPreset(props.preset.id);
    } catch (e: any) {
      alert(e.detail);
    } finally {
      setIsDeleting(false);
      await queryClient.invalidateQueries(QueryFactory.AnalyzesQuery.getAllAnalysisPresetsForStudyQueryKey());
      deletingConfirmationModal.closeModal();
      props.onHide();
    }
  }, [deletingConfirmationModal, props, queryClient]);

  return (
    <>
      <div className={Style.actions}>
        <Tooltip text={databaseFilename !== vm.databaseFilename ? t('PresetInfoModal.DbConnectionRequirement') : ''}>
          <AppButton
            disabled={databaseFilename !== vm.databaseFilename}
            onClick={props.onEdit}
            variant={'icon-link'}
            colorSchema={'secondary'}
            Icon={EditIcon}
          />
        </Tooltip>

        <AppButton
          onClick={() => deletingConfirmationModal.openModal()}
          variant={'icon-link'}
          colorSchema={'decline'}
          Icon={DeleteIcon}
        />
      </div>
      <PropertiesList className={Style.propertiesList} colored>
        <PropertiesList.Property nameClassName={Style.propertyName} name={t('PresetNameLabel')} value={vm.name} />
        <PropertiesList.Property
          nameClassName={Style.propertyName}
          name={t('DbFileNameLabel')}
          value={vm.databaseFilename}
        />
        <PropertiesList.Property nameClassName={Style.propertyName} name={t('TableNameLabel')} value={vm.tableName} />
        <PropertiesList.Property nameClassName={Style.propertyName} name={t('KeyColumnLabel')} value={vm.keyColumn} />
        <PropertiesList.Property
          valueClassName={'overflow-hidden'}
          nameClassName={Style.propertyName}
          name={t('ColumnsWithDataLabel')}
          value={<BulletPoints values={vm.dataColumns} />}
        />
        <PropertiesList.Property
          nameClassName={Style.propertyName}
          name={t('PresetQuantityLimitLabel')}
          value={vm.analysisQuantityLimit}
        />
      </PropertiesList>

      <DeletingConfirmationDialog
        onCancel={onCancelDeleting}
        onApply={onApplyDeleting}
        isLoading={isDeleting}
        isVisible={deletingConfirmationModal.visible}
      />
    </>
  );
};
