import { FormState } from './MonitoringFormValue';

export const ClearAnswers = (fields: FormState['fields']) => {
  return {
    testingCenter: fields.testingCenter,
    dateOfVisit: fields.dateOfVisit,
    personNames: fields.personNames,
    enoughTimeToHoldMeeting: fields.enoughTimeToHoldMeeting,
    enoughTimeToHoldMeeting_comment: fields.enoughTimeToHoldMeeting_comment,
    generalComment: fields.generalComment,
    delegationLogFilledInOrSupplemented: fields.delegationLogFilledInOrSupplemented,
    delegationLogFilledInOrSupplemented_comment: fields.delegationLogFilledInOrSupplemented_comment,
    technicalProblemsAtFacilityAffectingStudy: fields.technicalProblemsAtFacilityAffectingStudy,
    technicalProblemsAtFacilityAffectingStudy_comment: fields.technicalProblemsAtFacilityAffectingStudy_comment,
    properStorageOfStudyDocuments: fields.properStorageOfStudyDocuments,
    properStorageOfStudyDocuments_comment: fields.properStorageOfStudyDocuments_comment,
    givenStudyDocuments: fields.givenStudyDocuments,
    givenStudyDocuments_comment: fields.givenStudyDocuments_comment,
    ...(fields.givenStudyDocuments?.value === 'true'
      ? {
          studyDocuments: fields.studyDocuments,
          testPlanVersion: fields.studyDocuments?.some((x) => x.key === 'TestPlan')
            ? fields.testPlanVersion
            : undefined,
          patientInformationAndConsentVersion: fields.studyDocuments?.some(
            (x) => x.key === 'PatientInformationAndConsent',
          )
            ? fields.patientInformationAndConsentVersion
            : undefined,
          insuranceDocuments: fields.studyDocuments?.some((x) => x.key === 'InsuranceDocuments')
            ? fields.insuranceDocuments
            : undefined,
          communicationWithEc: fields.studyDocuments?.some((x) => x.key === 'CommunicationWithEc')
            ? fields.communicationWithEc
            : undefined,
          supportingDocuments: fields.studyDocuments?.some((x) => x.key === 'SupportingDocuments')
            ? fields.supportingDocuments
            : undefined,
          randomizationList: fields.studyDocuments?.some((x) => x.key === 'RandomizationList')
            ? fields.randomizationList
            : undefined,
          otherStudyDocuments: fields.studyDocuments?.some((x) => x.key === 'Other')
            ? fields.otherStudyDocuments
            : undefined,
        }
      : {}),
    typeOfMonitoringVisit: fields.typeOfMonitoringVisit,
    typeOfMonitoringVisit_comment: fields.typeOfMonitoringVisit_comment,
    ...(fields.typeOfMonitoringVisit === 'Routine' || fields.typeOfMonitoringVisit === 'Final'
      ? {
          spatialChangesAtTestCenter: fields.spatialChangesAtTestCenter,
          spatialChangesAtTestCenter_comment: fields.spatialChangesAtTestCenter_comment,
          studyDocumentExchanged: fields.studyDocumentExchanged,
          studyDocumentExchanged_comment: fields.studyDocumentExchanged_comment,
          exchangedDocumentNameAndReason:
            fields.studyDocumentExchanged?.value === 'true' ? fields.exchangedDocumentNameAndReason : undefined,
          personalIdentificationListIsComplete: fields.personalIdentificationListIsComplete,
          personalIdentificationListIsComplete_comment: fields.personalIdentificationListIsComplete_comment,
          patientIdentificationListIsComplete: fields.patientIdentificationListIsComplete,
          patientIdentificationListIsComplete_comment: fields.patientIdentificationListIsComplete_comment,

          consentFormsReviewed: fields.consentFormsReviewed,
          declarationsOfConsentIsComplete: fields.declarationsOfConsentIsComplete,
          declarationsOfConsentIsComplete_comment: fields.declarationsOfConsentIsComplete_comment,
          openQueriesDiscussed: fields.openQueriesDiscussed,
          openQueriesDiscussed_comment: fields.openQueriesDiscussed_comment,
          resetOfDevices: fields.resetOfDevices,
          resetOfDevices_comment: fields.resetOfDevices_comment,
          studyEquipmentPool: fields.studyEquipmentPool,
          studyEquipmentPool_comment: fields.studyEquipmentPool_comment,
          referenceToStudyDocuments: fields.referenceToStudyDocuments,
          referenceToStudyDocuments_comment: fields.referenceToStudyDocuments_comment,
          referenceToAuthority: fields.referenceToAuthority,
          referenceToAuthority_comment: fields.referenceToAuthority_comment,
          clarificationOfPublication: fields.clarificationOfPublication,
          clarificationOfPublication_comment: fields.clarificationOfPublication_comment,

          patientRange: fields.patientRange,
          patients: fields.patients?.map((x) => ({
            patientUniqueId: x.patientUniqueId,
            sdv: x.sdv,
            sdvFixedRecord: x.sdv === 'FixedRecord' ? x.sdvFixedRecord : undefined,
            consentIsCorrectlyObtained: x.consentIsCorrectlyObtained,
            consentIsCorrectlyObtained_comment: x.consentIsCorrectlyObtained_comment,
            verifiableInclusionAndExclusionCriteriaAreCorrect: x.verifiableInclusionAndExclusionCriteriaAreCorrect,
            verifiableInclusionAndExclusionCriteriaAreCorrect_comment:
              x.verifiableInclusionAndExclusionCriteriaAreCorrect_comment,
            accordingToStudySchedule: x.accordingToStudySchedule,
            accordingToStudySchedule_comment: x.accordingToStudySchedule_comment,
            accordingToTestPlan: x.accordingToTestPlan,
            accordingToTestPlan_comment: x.accordingToTestPlan_comment,
            newInformationDiscussed: x.newInformationDiscussed,
            newInformationDiscussed_comment: x.newInformationDiscussed_comment,
            sourceDataDocumentationAvailable: x.sourceDataDocumentationAvailable,
            sourceDataDocumentationAvailable_comment: x.sourceDataDocumentationAvailable_comment,
          })),
        }
      : {}),

    confirmation: fields.confirmation,
  };
};
