import { useNode, useEditor } from '@craftjs/core';
import { ROOT_NODE } from '@craftjs/utils';
import React, { useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Style from './renderNode.module.css';
import './globalFaqEditor.styles.css';
import { ReactComponent as ArrowUp } from '../../../assets/img/faqBuilder/arrow-up.svg';
import { ReactComponent as Delete } from '../../../assets/img/faqBuilder/delete.svg';
import { ReactComponent as Move } from '../../../assets/img/faqBuilder/move.svg';
import { TypographyStyles } from '../../../styles';
import { getCloneTree } from '../../formEditor/uiEditor/uiEditor-helper';
import { useTranslation } from 'react-i18next';

export const RenderNode = ({ render }: { render: any }) => {
  const { t } = useTranslation();
  const { id } = useNode();
  const { actions, query, isActive, isEditorMode } = useEditor((state, q) => ({
    isActive: q.getEvent('selected').contains(id),
    nodes: state.nodes,
    isEditorMode: state.options.enabled,
  }));

  const {
    isHover,
    dom,
    name,
    deletable,
    parent,
    moveable,
    nodeId,
    connectors: { drag },
  } = useNode((node) => ({
    isHover: node.events.hovered,
    dom: node.dom,
    name: node.data.custom.displayName || node.data.displayName,
    moveable: query.node(node.id).isDraggable(),
    deletable: query.node(node.id).isDeletable(),
    parent: node.data.parent,
    props: node.data.props,
    nodeId: node.id,
  }));

  const currentRef = useRef<HTMLDivElement>();

  const getPos = useCallback((d: HTMLElement | null) => {
    const { top, left, bottom } = d ? d.getBoundingClientRect() : { top: 0, left: 0, bottom: 0 };

    return {
      top: `${top > 0 ? top - 32 : bottom}px`,
      left: `${left}px`,
    };
  }, []);

  const scroll = useCallback(() => {
    const { current: currentDOM } = currentRef;

    if (!currentDOM) return;
    const { top, left } = getPos(dom);
    currentDOM.style.top = top;
    currentDOM.style.left = left;
  }, [dom, getPos]);

  useEffect(() => {
    document.querySelector('#craftjs-renderer')?.addEventListener('scroll', scroll);

    return () => {
      document.querySelector('#craftjs-renderer')?.removeEventListener('scroll', scroll);
    };
  }, [scroll]);

  useEffect(() => {
    if (!dom) {
      return;
    }

    dom.classList.remove('component-border');
    dom.classList.remove('component-selected');
    dom.classList.remove('component-hovered');

    if (isActive) {
      dom.classList.add('component-selected');
      return;
    }

    if (isHover) {
      dom.classList.add('component-hovered');
      return;
    }

    if (isEditorMode) {
      dom.classList.add('component-border');
      return;
    }
  }, [dom, isActive, isHover, isEditorMode]);

  const handleClone = (i: string) => {
    const parentNode = query.node(parent).get();
    const indexToAdd = parentNode.data.nodes.indexOf(i);
    const tree = getCloneTree(query, i);
    actions.addNodeTree(tree, parentNode.id, indexToAdd + 1);
  };

  return (
    <>
      {isActive
        ? ReactDOM.createPortal(
            <div
              // @ts-ignore
              ref={currentRef}
              className={Style.container}
              style={{
                left: getPos(dom).left,
                top: getPos(dom).top,
                zIndex: 0,
              }}
            >
              <div className={TypographyStyles.plainText12}>{name}</div>
              {moveable && (
                // @ts-ignore
                <div className={Style.cursorPointer} ref={drag}>
                  <Move style={{ width: 16, height: 16 }} />
                </div>
              )}
              {id !== ROOT_NODE && (
                <div
                  className={Style.cursorPointer}
                  onClick={() => {
                    actions.selectNode(parent);
                  }}
                >
                  <ArrowUp style={{ width: 16, height: 16 }} />
                </div>
              )}
              {moveable && (
                <div className={Style.cursorPointer} onClick={() => handleClone(nodeId)}>
                  {t('FaqEditor.Toolbar.Copy')}
                </div>
              )}
              {deletable && (
                <div
                  className={Style.cursorPointer}
                  onMouseDown={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    actions.delete(id);
                  }}
                >
                  <Delete style={{ width: 16, height: 16 }} />
                </div>
              )}
            </div>,
            document.querySelector('#page-container') as HTMLElement,
          )
        : null}
      {render}
    </>
  );
};
