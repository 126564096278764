import React, { useMemo } from 'react';
import Style from './panelStyles.module.scss';
import { CheckboxSettings } from '../settingsInputs/CheckboxSettings';
import { IFormFieldProps } from '../../../../../features/forms/base/controls/inputs/base/FormFieldProps';
import { ScoreGroupEnum } from '../../../../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import { DropdownOption } from '../../../../uikit/inputs/dropdown/appDropdownInput';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';
import { PanelContainer } from '../../components/panelContainer';
import { ControlsGroup } from '../../components/controlsGroup';
import { SettingsInput } from '../settingsInputs/settingsInput';
import { useNode } from '@craftjs/core';
import { BaseControlSettings } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { isFieldWithOptions } from 'src/components/formEditor/uiEditor/uiEditor-helper';

export const CommonFieldSettings: React.FC<{
  labelRequired?: boolean;
}> = () => {
  const { t } = useTranslation('dev');
  const commonLocale = useCommonLocalization();
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as IFormFieldProps,
  }));

  const defaultValueComponent = useMemo(() => {
    if (isFieldWithOptions(props)) {
      return props.options
        .filter((x) => props.defaultValue?.includes(x.value))
        .map((o) => <div key={o.key}>{o.displayValue}</div>);
    }

    if (Array.isArray(props.defaultValue)) {
      return props.defaultValue.map((value) => <div key={value}>{value}</div>);
    }

    return props.defaultValue;
  }, [props]);

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.otherSettingsHeader')} expanded={false}>
      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.variable')}
        tooltip={t('StudySettingsPage.FormBuilder.settings.variableTooltip')}
        flexible
      >
        <SettingsInput
          value={props.variable}
          onChange={(e) =>
            setProp((p: IFormFieldProps) => {
              p.variable = e.target?.value;
            }, 500)
          }
        />
      </ControlsGroup>
      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.dataKey')}
        tooltip={t('StudySettingsPage.FormBuilder.settings.dataKeyTooltip')}
        flexible
      >
        <SettingsInput
          value={props.dataKey}
          disabled={true}
          onChange={(e) =>
            setProp((p: IFormFieldProps) => {
              p.dataKey = e.target?.value;
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.editable')} flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.editable')}
          isChecked={!!props.isEditable}
          setValue={(newValue) =>
            setProp((setProps: IFormFieldProps) => {
              setProps.isEditable = newValue;
            })
          }
        />

        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.isDisableWhenEditing')}
          isChecked={!!props.isDisableWhenEditing}
          setValue={(newValue) =>
            setProp((setProps: IFormFieldProps) => {
              setProps.isDisableWhenEditing = newValue ? newValue : undefined;
            })
          }
        />

        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.ignoreValuesFromPrevForm')}
          isChecked={!!props.ignoreInitialValuesFromPreviousForm}
          setValue={(newValue) =>
            setProp((setProps: IFormFieldProps) => {
              setProps.ignoreInitialValuesFromPreviousForm = newValue ? newValue : undefined;
            })
          }
        />

        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.ignoreInFillingProgress')}
          isChecked={!!props.ignoreInFillingProgress}
          setValue={(newValue) =>
            setProp((setProps: IFormFieldProps) => {
              setProps.ignoreInFillingProgress = newValue ? newValue : undefined;
            })
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible={true}
        header={t('StudySettingsPage.FormBuilder.settings.scoreGroup')}
        flexible
        expanded={!!props.scoreGroup}
        onOpen={() => {
          setProp((prop: IFormFieldProps) => {
            prop.scoreGroup = scoreGroupOptions[0].key;
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IFormFieldProps) => {
            prop.scoreGroup = undefined;
          }, 500);
        }}
      >
        <AppDropDownWithSuggestionInput
          isRequired={false}
          placeholder={t('StudySettingsPage.FormBuilder.settings.scoreGroupPlaceholder')}
          options={scoreGroupOptions}
          value={scoreGroupOptions.find((x) => x.key === props.scoreGroup)}
          onChange={(x) => {
            setProp((setProps: IFormFieldProps) => {
              setProps.scoreGroup = x?.key ?? undefined;
            });
          }}
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={t('StudySettingsPage.FormBuilder.settings.skipCheckBoxText')}
        expanded={!!props.hasSkipCheckBox}
        onOpen={() => {
          setProp((prop: BaseControlSettings) => {
            prop.hasSkipCheckBox = true;
          }, 500);
        }}
        onClose={() => {
          setProp((prop: BaseControlSettings) => {
            prop.hasSkipCheckBox = false;
          }, 500);
        }}
      >
        <SettingsInput
          placeholder={commonLocale('Forms.Controls.SkipQuestionCheckBox')}
          value={props.skipCheckBoxText}
          onChange={(e) =>
            setProp((p: BaseControlSettings) => {
              p.skipCheckBoxText = e.target?.value ? e.target.value : undefined;
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup
        flexible
        header={t('StudySettingsPage.FormBuilder.settings.defaultValue')}
        collapsible={true}
        tooltip={t('StudySettingsPage.FormBuilder.settings.defaultValueTooltip')}
        expanded={props.defaultValue !== undefined && props.defaultValue !== null}
        onClose={() => {
          setProp((p: IFormFieldProps) => {
            p.defaultValue = undefined;
          }, 500);
        }}
      >
        <div className={Style.defaultValueContainer}>{defaultValueComponent}</div>
      </ControlsGroup>
    </PanelContainer>
  );
};

const scoreGroupOptions = Object.entries(ScoreGroupEnum)
  .filter(([, value]) => typeof value === 'string')
  .map(([, value]) => {
    return { key: value, text: value } as DropdownOption<keyof typeof ScoreGroupEnum>;
  });
