import React from 'react';
import Style from './smallButton.module.css';
import { SmallButtonProps } from './smallButton';

export const SmallButton = (props: SmallButtonProps) => {
  const { Icon, testId } = props;
  return (
    <button className={Style.base} disabled={props.disabled} onClick={props.onPress} data-test-id={testId}>
      <Icon className={Style.icon} />
    </button>
  );
};
