import React from 'react';
import Style from './settingsInput.module.scss';
import clsx from 'clsx';
import { AppTextInputProps } from 'uikit/inputs';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { ReactComponent as TooltipIcon } from 'assets/img/formBuilder/tip_16.svg';

type SettingsInputProps = AppTextInputProps & {
  badge?: JSX.Element;
  regex?: string;
  units?: string;
  inputClassname?: string;
  tooltip?: string | JSX.Element;
  label?: string;
};

export const SettingsInput = React.forwardRef<HTMLInputElement, SettingsInputProps>(function SettingsInput(
  { badge, className, units, type, multiple, disabled, tooltip, label, ...rest },
  ref,
) {
  return (
    <div>
      {label && (
        <div className={Style.label}>
          {label}
          {tooltip && (
            <Tooltip text={''} tooltipContent={tooltip} calloutContainerStyles={Style.tooltip}>
              <TooltipIcon />
            </Tooltip>
          )}
        </div>
      )}
      <div className={clsx(Style.container, className)}>
        {badge}
        {type === 'color' && <input type={'color'} className={Style.colorIndicator} disabled={disabled} {...rest} />}
        {multiple ? (
          <textarea
            // @ts-ignore
            ref={ref}
            className={Style.textArea}
            disabled={disabled}
            {...rest}
          />
        ) : (
          <input
            ref={ref}
            type={type === 'color' ? undefined : type}
            className={Style.input}
            disabled={disabled || type === 'color'}
            onFocus={(e) => e.target.addEventListener('wheel', (x) => x.preventDefault(), { passive: false })}
            {...rest}
          />
        )}
        {units && <span>{units}</span>}
      </div>
    </div>
  );
});
