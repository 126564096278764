import React from 'react';
import { ISelector } from './selectorItem/selectorItem';
import { Element } from '@craftjs/core';
import { Container } from './container';
import { Text } from './text';
import { Collapsible } from './collapsible';
import { ImageBlock } from '../../formEditor/controls/content/ImageBlock';
import { MathBlock } from './mathBlock';
import { Video } from './video';
import i18next from 'i18next';

import { ReactComponent as SquareSvg } from '../../../assets/img/faqBuilder/toolbox/container.svg';
import { ReactComponent as TextSvg } from '../../../assets/img/faqBuilder/toolbox/text.svg';
import { ReactComponent as CollapsibleSvg } from '../../../assets/img/faqBuilder/toolbox/collapsible.svg';
import { ReactComponent as ImageSvg } from '../../../assets/img/faqBuilder/toolbox/image.svg';
import { ReactComponent as FormulaSvg } from '../../../assets/img/faqBuilder/toolbox/formula.svg';
import { ReactComponent as VideoSvg } from '../../../assets/img/faqBuilder/toolbox/video-line.svg';

export const selectors = (): ISelector[] => [
  {
    component: <Element canvas is={Container} />,
    name: i18next.t('FaqEditor.Toolbox.Components.Container'),
    Icon: SquareSvg,
  },
  {
    component: <Text />,
    name: i18next.t('FaqEditor.Toolbox.Components.Text'),
    Icon: TextSvg,
  },
  {
    component: <Collapsible />,
    name: i18next.t('FaqEditor.Toolbox.Components.Collapsible'),
    Icon: CollapsibleSvg,
  },
  {
    component: <ImageBlock isHiddenOnOverview={false} fileId={null} isFill={true} size={''} />,
    name: i18next.t('FaqEditor.Toolbox.Components.Image'),
    Icon: ImageSvg,
  },
  {
    component: <MathBlock />,
    name: i18next.t('FaqEditor.Toolbox.Components.MathBlock'),
    Icon: FormulaSvg,
  },
  {
    component: <Video />,
    name: i18next.t('FaqEditor.Toolbox.Components.Video'),
    Icon: VideoSvg,
  },
];
