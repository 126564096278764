/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC, useMemo, useState } from 'react';
import { IStaffDto, TagFilters } from '../../../services/api/api-client';
import Style from './userlist.module.scss';
import { AppButton } from 'uikit/buttons';
import { QueryFunctionContext, useQueries, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppInputError, AppInputLabel } from 'uikit/wrappers';
import { AppAutoCompleteField, AppTextField } from 'uikit/fields';
import { ReactComponent as AddIcon } from '../../../assets/img/common/add.svg';
import { ReactComponent as CrossIcon } from '../../../assets/img/cross.svg';
import { QueryFactory } from '../../../services/api';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { ValidationFormRules } from '../../../helpers/validation-helpers';
import { getTagKeys } from '../../../features/patientTags/tagsHelper';
import { groupBy, toDictionary } from '../../../helpers/arrayHelpers';
import { StringKeyValueType } from '../../../helpers/typeUtils';
import { useId } from '@fluentui/react-hooks';

export const TagsUserModalContent: FC<IStaffDto & { onClose: () => void }> = (props) => {
  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const client = useQueryClient();

  const tagQueries = useQueries({
    queries: props.studies
      .map((study) => study.id)
      .map((id) => ({
        queryKey: QueryFactory.StudyQuery.getTagsQueryKey(id),
        // TODO: Improve support of useQueries in react-query-swagger
        // and replace this hack with new feature
        queryFn: (queryContext: QueryFunctionContext) =>
          QueryFactory.StudyClient.getTags(queryContext.queryKey[2] as number),
      })),
  });

  const usedInStudiesTags = useMemo(() => {
    const uniqueTags = tagQueries
      .map((query) => query.data ?? {})
      .map((tagFilters) => getTagKeys(tagFilters).map((key) => ({ key, values: tagFilters[key] })))
      .flat()
      .reduce(
        groupBy(
          (x) => x.key,
          (x) => x.values,
        ),
        [],
      )
      .map((x) => ({
        key: x.key,
        values: x.values.flat().filter((value, index, arr) => arr.indexOf(value) === index),
      }));

    return {
      areLoading: tagQueries.some((x) => x.isLoading),
      keys: uniqueTags.map((x) => x.key),
      dictionary: uniqueTags.reduce(
        toDictionary(
          (x) => x.key,
          (x) => x.values,
        ),
        {},
      ),
    };
  }, [tagQueries]);

  const remoteTags = useMemo(() => {
    return props.tagFilters
      ? Object.entries(props.tagFilters)
          .map((e) => {
            return Array.isArray(e[1])
              ? e[1].map((x) => {
                  return { key: e[0], value: x };
                })
              : { key: e[0], value: JSON.stringify(e[1]) };
          })
          .flatMap((x) => x)
      : [];
  }, [props.tagFilters]);

  const {
    formState: { errors, isSubmitting, isSubmitSuccessful },
    control,
    watch,
    handleSubmit,
  } = useForm<{ tags: StringKeyValueType[]; id: string }>({
    defaultValues: {
      tags: remoteTags,
      id: props.id,
    },
  });
  const tagsFieldArray = useFieldArray({
    control,
    name: 'tags',
  });

  const [error, setError] = useState<string>('');

  const onSave = handleSubmit(async (data) => {
    try {
      setError('');
      await QueryFactory.UsersQuery.Client.changeUserTagFilters(data.id, convertFlatTagsToGrouped(data.tags));
      await client.invalidateQueries(QueryFactory.UsersQuery.getAllUsersQueryKey());
      setTimeout(props.onClose, 1500);
    } catch (e: any) {
      setError(t('Admin.UserList.Tags.EditModal.Error'));
      throw e;
    }
  });

  const formId = useId();

  return (
    <>
      <form id={formId} onSubmit={onSave}>
        <AppTextField
          labelProps={{
            text: 'Full Name',
          }}
          value={props.fullName}
          contentEditable={false}
          disabled={true}
        />

        <div className={Style.tagsContainer}>
          <AppInputError
            errors={
              Array.isArray(errors.tags) && errors.tags?.length > 0
                ? t('Admin.UserList.Tags.EditModal.CommonError')
                : ''
            }
          >
            <AppInputLabel
              text={t('Admin.UserList.Tags.EditModal.Tags.Label')}
              tooltip={t('Admin.UserList.Tags.EditModal.Tags.TooltipText')}
            >
              {tagsFieldArray.fields.map((e, i) => {
                return (
                  <div key={e.id} className={Style.tagContainer}>
                    <Controller
                      control={control}
                      name={`tags.${i}.key`}
                      rules={ValidationFormRules().requiredRule}
                      render={({ field }) => (
                        <AppAutoCompleteField
                          labelProps={{ text: i === 0 ? t('Admin.UserList.Tags.EditModal.KeyHeader') : '' }}
                          suggestions={usedInStudiesTags.keys}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`tags.${i}.value`}
                      rules={ValidationFormRules().requiredRule}
                      render={({ field }) => (
                        <AppAutoCompleteField
                          labelProps={{ text: i === 0 ? t('Admin.UserList.Tags.EditModal.ValueHeader') : '' }}
                          suggestions={usedInStudiesTags.dictionary[watch(`tags.${i}.key`)] ?? []}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />

                    <div className={Style.deleteTag}>
                      <AppButton
                        variant={'icon-link'}
                        colorSchema={'decline'}
                        Icon={CrossIcon}
                        onClick={() => tagsFieldArray.remove(i)}
                      />
                    </div>
                  </div>
                );
              })}
              <AppButton
                variant={'icon-link'}
                colorSchema={'primary'}
                text={t('Admin.UserList.Tags.EditModal.Add')}
                Icon={AddIcon}
                onClick={() => tagsFieldArray.append({ key: '', value: '' })}
              />
            </AppInputLabel>
          </AppInputError>
        </div>
      </form>

      <AppInputError errors={error} hideBorder position={'top'}>
        <div className={Style.modalButtonGroup}>
          <AppButton
            variant={'button'}
            colorSchema={'secondary'}
            text={commonLocalizer('Common_Cancel')}
            onClick={props.onClose}
          />
          <AppButton
            variant={'button'}
            colorSchema={'primary'}
            text={commonLocalizer('Common_Save')}
            isLoading={isSubmitting}
            hasLoaded={isSubmitSuccessful}
            disabled={isSubmitting || isSubmitSuccessful}
            type={'submit'}
            form={formId}
          />
        </div>
      </AppInputError>
    </>
  );
};

function convertFlatTagsToGrouped(tags: StringKeyValueType[]): TagFilters {
  const mapObject = new Map();
  tags
    .filter((x) => !!x.key && !!x.value)
    .forEach((item) => {
      mapObject.has(item.key) ? mapObject.get(item.key).push(item.value) : mapObject.set(item.key, [item.value]);
    });

  return new TagFilters(Object.fromEntries(mapObject));
}
