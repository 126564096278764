import { initReactPdf, RPDF } from './react-pdf';
import React from 'react';

export async function createReport(layout: React.ReactElement): Promise<Blob> {
  await initReactPdf();
  const content = RPDF.pdf(layout);
  return content.toBlob();
}

/*
   This functions triggers standard browser 'Download file' dialog.
   In Chrome it just saves the file to Downloads folder and shows the file in bottom toolbar.
 */
export function triggerDownloadFileDialog(blob: Blob, fileName: string) {
  const data = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}
