﻿import React, { useCallback } from 'react';
import { IRouteProgressDto } from 'src/services/api/api-client';
import { useFormEditing } from 'src/features/forms/editing/useFormEditing';
import { useFormOverview } from 'src/features/forms/overview/useFormOverview';
import { getFormResultById } from '../../services/api/api-client/FormsClient';
import { getPatientRouteProgress } from '../../services/api/api-client/StudyRoutesClient';

export const useFormEditingAndOverview = () => {
  const formEditing = useFormEditing();
  const formOverview = useFormOverview({ onEdit: formEditing.startFormEditing });

  const startFilling = useCallback(
    async (args: {
      formId: number;
      patientId: string;
      patientUniqueId: string;
      routeProgress?: IRouteProgressDto;
      stepName?: string;
    }) => {
      const _routeProgress = args.stepName ? args.routeProgress ?? getPatientRouteProgress(args.patientId) : undefined;
      await formEditing.startFormFilling({
        formId: args.formId,
        patientId: args.patientId,
        routeProgress: _routeProgress,
        stepName: args.stepName,
      });
    },
    [formEditing],
  );

  const startEditing = useCallback(
    async (args: {
      formResultId: number;
      patientUniqueId: string;
      stepName: string | undefined;
      routeProgress?: IRouteProgressDto;
    }) => {
      const formResult = getFormResultById(args.formResultId);
      await formEditing.startFormEditing({ formResult: formResult, stepName: args.stepName });
    },
    [formEditing],
  );

  return {
    startFilling: startFilling,
    startEditing: startEditing,
    openOverview: formOverview.open,
    formEditIsLoading: formEditing.isLoading,
    formOverviewIsLoading: formOverview.isLoading,
    element: (
      <>
        {formEditing.element}
        {formOverview.element}
      </>
    ),
  };
};
