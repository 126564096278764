import { QueryFactory } from 'src/services/api';
import { FileDto, FileParameter } from 'src/services/api/api-client';
import { AppAttachmentValue } from '../components/uikit/fields/attachments/attachmentsField/appAttachmentsField';

export const FileToFileParameter = (file: File): FileParameter => {
  // @ts-ignore
  return { data: file, fileName: file.name };
};

export const AppAttachmentValueToFileParameter = (file: AppAttachmentValue): FileParameter => {
  return { data: file.file, fileName: file.fileName };
};

export const FileToAppAttachmentValue = (file: File): AppAttachmentValue => {
  return { id: null, fileName: file.name, file: file };
};

export const FileDtoToAppAttachmentValue = (file: FileDto): AppAttachmentValue => {
  return { id: file.fileId, fileName: file.fileName, file: null };
};

export const downloadFile = (fileResponse: QueryFactory.FileResponse): void => {
  const newBlob = new Blob([fileResponse.data], { type: fileResponse.data.type });
  const url = window.URL.createObjectURL(new Blob([newBlob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileResponse.fileName ?? '');
  link.click();
};

export const downloadJsonFile = (data: string): void => {
  const newBlob = new Blob([data], { type: 'text/plain' });
  const url = window.URL.createObjectURL(new Blob([newBlob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Question_scheme.json');
  link.click();
};

export const mapUploadedFilesIntoServerFormat = (data?: AppAttachmentValue[] | null): FileParameter[] | null => {
  let collection: FileParameter[] | null = null;
  if (data && data.filter((f) => f.file !== null).length > 0) {
    collection = data.filter((f) => f.file !== null).map(AppAttachmentValueToFileParameter);
  }

  return collection;
};

export const mapServerFilesIntoFormFormat = (data?: FileDto[] | null): AppAttachmentValue[] => {
  return data ? data?.map(FileDtoToAppAttachmentValue) : [];
};

export const getFileIdsToDelete = (data?: AppAttachmentValue[] | null, uploadedFiles?: FileDto[] | null) => {
  if (data && uploadedFiles) {
    const oldUploadedFilesIds = data.filter((x) => !!x.id).map((x) => x.id);
    const difference = uploadedFiles.filter((x) => !oldUploadedFilesIds.includes(x.fileId));

    if (difference.length > 0) {
      return difference.map((x) => x.fileId);
    }
  }

  return [];
};
