import React, { useCallback, useState } from 'react';
import { useModal } from 'src/application/hooks/useModal';
import { FormModalWithContext } from 'src/components/formEditor/modals/formModalWithContext';
import { IFormDto } from 'src/services/api/api-client';

export const useFormBuildingPreview = () => {
  const modal = useModal();
  const [formConfig, setFormConfig] = useState<IFormDto>();

  const startFormPreview = useCallback(
    (schema: { [key: string]: any }, type: string) => {
      setFormConfig({
        id: 0,
        layoutSchema: schema,
        type: type,
      } as IFormDto);
      modal.openModal();
    },
    [modal],
  );

  return {
    startFormPreview,
    element: formConfig && (
      <FormModalWithContext
        mode={'BuildingPreview'}
        formConfig={formConfig}
        prevAnswersValue={undefined}
        formResultVersion={undefined}
        modal={{
          title: formConfig.type,
          visible: modal.visible,
          onHide: modal.closeModal,
        }}
      />
    ),
  };
};
