import React from 'react';
import styles from './useMonitoringFormsEditing.module.scss';
import { FieldValues, Control, Path, Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HookFormTextInput } from 'src/hookFormControls/hookFormTextInput';
import { FieldSkipReason } from 'src/services/api/api-client';
import {
  AppDropdownFieldProps,
  AppDropdownField,
  AppRadioGroupField,
  AppTextField,
  AppTagInputField,
} from 'uikit/fields';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { AppInputLabelProps } from 'uikit/wrappers';
import { MonitoringFormOption } from './fieldOptions';

export type SkippableField<TValue = string> = {
  value: TValue | undefined;
  fieldSkipReason: FieldSkipReason | undefined;
};

export type DropDownWithSkipCheckboxProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
} & AppDropdownFieldProps<DropdownOption<string>>;

export const DropDownWithSkipCheckbox = <TFieldValues extends FieldValues>(
  props: DropDownWithSkipCheckboxProps<TFieldValues>,
) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{
        validate: {
          requiredOrSkipped: (fieldValue: SkippableField<DropdownOption<string>[]> | undefined) => {
            if (props.isMultiple ? fieldValue?.value?.length : fieldValue?.value) return true;

            if (fieldValue?.fieldSkipReason === undefined) return t('LocalErrors.FieldRequired');
            if (fieldValue.fieldSkipReason === FieldSkipReason.NoReason) return t('LocalErrors.ChoseSkipReason');

            return true;
          },
        },
      }}
      render={({ field: { onChange, value, ref }, fieldState }) => {
        const typedValue = value as SkippableField<DropdownOption<string>[]> | undefined;

        return (
          <div tabIndex={0} ref={ref}>
            <AppDropdownField<DropdownOption>
              value={typedValue?.value}
              // @ts-ignore
              onChange={(options) => {
                onChange({ ...typedValue, value: options });
              }}
              errorProps={{ errors: fieldState.error?.message }}
              skipProps={{
                showReasons: true,
                skipReason: value?.fieldSkipReason,
                onSkipReasonChange: (reason) => onChange({ fieldSkipReason: reason }),
              }}
              {...props}
            />
          </div>
        );
      }}
    />
  );
};

export type RadioGroupWithSkipCheckboxProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  options: MonitoringFormOption[];
  labelProps: AppInputLabelProps;
};

export const RadioGroupWithSkipCheckbox = <TFieldValues extends FieldValues>(
  props: RadioGroupWithSkipCheckboxProps<TFieldValues>,
) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{
        validate: {
          requiredOrSkipped: (fieldValue: SkippableField | undefined) => {
            if (fieldValue?.value) return true;

            if (fieldValue?.fieldSkipReason === undefined) return t('LocalErrors.FieldRequired');
            if (fieldValue.fieldSkipReason === FieldSkipReason.NoReason) return t('LocalErrors.ChoseSkipReason');

            return true;
          },
        },
      }}
      render={({ field: { onChange, value, ref }, fieldState }) => {
        const typedValue = value as SkippableField | undefined;

        return (
          <div tabIndex={0} ref={ref}>
            <AppRadioGroupField
              valueField={'value'}
              options={props.options}
              value={typedValue?.value}
              labelField={'label'}
              labelProps={props.labelProps}
              errorProps={{ errors: fieldState.error?.message }}
              onChange={(option) => {
                onChange({ ...typedValue, value: option?.value });
              }}
              skipProps={{
                showReasons: true,
                skipReason: value?.fieldSkipReason,
                onSkipReasonChange: (reason) => onChange({ fieldSkipReason: reason }),
              }}
            />
          </div>
        );
      }}
    />
  );
};

export type TextInputWithSkipCheckboxProps<TFieldValues extends FieldValues> = {
  type?: 'text' | 'date' | 'text-area';
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  labelProps: AppInputLabelProps;
  placeholder?: string;
};

export const TextInputWithSkipCheckbox = <TFieldValues extends FieldValues>(
  props: TextInputWithSkipCheckboxProps<TFieldValues>,
) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{
        validate: {
          requiredOrSkipped: (fieldValue: SkippableField | undefined) => {
            if (fieldValue?.value) return true;

            if (fieldValue?.fieldSkipReason === undefined) return t('LocalErrors.FieldRequired');
            if (fieldValue.fieldSkipReason === FieldSkipReason.NoReason) return t('LocalErrors.ChoseSkipReason');

            return true;
          },
        },
      }}
      render={({ field: { onChange, value, ref }, fieldState }) => {
        const typedValue = value as SkippableField | undefined;

        return (
          <div tabIndex={0} ref={ref}>
            <AppTextField
              type={props.type}
              labelProps={props.labelProps}
              value={typedValue?.value}
              placeholder={props.placeholder}
              onChange={(e) => {
                onChange({ value: e.target.value });
              }}
              errorProps={{ errors: fieldState.error?.message }}
              skipProps={{
                showReasons: typedValue?.fieldSkipReason !== undefined,
                skipReason: value?.fieldSkipReason,
                onSkipReasonChange: (reason: FieldSkipReason | undefined) => {
                  onChange({ fieldSkipReason: reason });
                },
              }}
            />
          </div>
        );
      }}
    />
  );
};

export type TagInputWithSkipCheckboxProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  labelProps: AppInputLabelProps;
  placeholder?: string;
};

export const TagInputWithSkipCheckbox = <TFieldValues extends FieldValues>(
  props: TagInputWithSkipCheckboxProps<TFieldValues>,
) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={{
        validate: {
          requiredOrSkipped: (fieldValue: SkippableField | undefined) => {
            if (fieldValue?.value) return true;

            if (fieldValue?.fieldSkipReason === undefined) return t('LocalErrors.FieldRequired');
            if (fieldValue.fieldSkipReason === FieldSkipReason.NoReason) return t('LocalErrors.ChoseSkipReason');

            return true;
          },
        },
      }}
      render={({ field: { value, onChange, ref }, fieldState }) => {
        const typedValue = value as SkippableField<string[]> | undefined;

        return (
          <AppTagInputField
            ref={ref}
            values={value}
            labelProps={props.labelProps}
            errorProps={{ errors: fieldState.error?.message }}
            onDeleteTag={(index: number) => {
              if (index < 0) return;

              value?.splice(index, 1);
              onChange(value.length ? value : null);
            }}
            onAddTag={(newTag: string) => {
              if (value?.includes(newTag)) return;

              onChange([...(value || []), newTag]);
            }}
            skipProps={{
              showReasons: typedValue?.fieldSkipReason !== undefined,
              skipReason: value?.fieldSkipReason,
              onSkipReasonChange: (reason: FieldSkipReason | undefined) => {
                onChange({ fieldSkipReason: reason });
              },
            }}
          />
        );
      }}
    />
  );
};

export type CommentFieldProps<TFieldValues extends FieldValues> = {
  form: UseFormReturn<TFieldValues>;
  name: Path<TFieldValues>;
};

export const CommentField = <TFieldValues extends FieldValues>(props: CommentFieldProps<TFieldValues>) => {
  const { t } = useTranslation();

  return (
    <HookFormTextInput
      type={'text-area'}
      control={props.form.control}
      name={props.name}
      placeholder={t('MonitoringForms.Fields.Comment.Placeholder')}
      labelProps={{ text: t('MonitoringForms.Fields.Comment.Label'), isBold: true }}
      triggerProps={{
        type: 'Comment',
        className: styles.commentTrigger,
        initialState: props.form.watch(props.name) ? 'Field' : 'Trigger',
      }}
    />
  );
};
