import React from 'react';
import { ExpandedRowHeaderProps } from './expandedRowHeader';
import Style from './expandedRowHeader.module.css';
import clsx from 'clsx';
import { TypographyStyles } from '../../../styles';

export const ExpandedRowHeader = (props: ExpandedRowHeaderProps) => {
  return (
    <div className={clsx(Style.header, props.styles)} data-test-id={props.testId}>
      <div className={clsx(TypographyStyles.heading2, Style.columnHeader)}>{props.text}</div>
      <div>{props.rightHeader}</div>
    </div>
  );
};
