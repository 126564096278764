import React from 'react';
import { useSkipReasonText } from 'src/components/formEditor/controls/Inputs/base/hooks';
import { useContextSelector } from 'use-context-selector';
import { useScopedTranslation } from '../../../../../application/localisation/useScopedTranslation';
import { IBmiInput } from '../../../../forms/base/controls/specials/BmiInput';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const BmiInput: IBmiInput = ({ dataKey }) => {
  const { t } = useScopedTranslation('Forms.Controls.BMI');
  const bmiValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const weightValue = useContextSelector(RecordContext, (v) => v.formResult?.Weight);
  const heightValue = useContextSelector(RecordContext, (v) => v.formResult?.Height);

  const weightSkipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.Weight);
  const heightSkipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.Height);
  const weightSkipReasonText = useSkipReasonText(weightSkipReason);
  const heightSkipReasonText = useSkipReasonText(heightSkipReason);

  return (
    <>
      <Field label={t('Weight')} skipReason={weightSkipReasonText} value={weightValue ? `${weightValue} kg` : ''} />
      <Field label={t('Height')} skipReason={heightSkipReasonText} value={heightValue ? `${heightValue} cm` : ''} />
      <Field label={'BMI'} value={bmiValue} />
    </>
  );
};
