﻿import React, { FC } from 'react';
import { FormLifecycleState, IFillingProgress } from '../../../services/api/api-client';
import clsx from 'clsx';
import { TypographyStyles } from 'styles';
import Style from './FillingProgress.module.scss';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const FillingProgress: FC<{
  value: IFillingProgress;
  formLifeCycle: FormLifecycleState | null | undefined;
  isSkipped: boolean;
}> = ({ value, formLifeCycle, isSkipped }) => {
  const study = useStudy();

  const needToDisplay = () => {
    const isFullyFilled = value.filled === value.total;

    if (study?.hasEcrf) {
      const approvalIsRecalled = formLifeCycle === FormLifecycleState.Recalled;
      const isDraft = formLifeCycle === FormLifecycleState.Draft;

      switch (true) {
        case isDraft:
          return true;
        case approvalIsRecalled:
          return true;
        case isSkipped:
          return false;
        default:
          return !isFullyFilled;
      }
    } else {
      return !isFullyFilled;
    }
  };

  if (needToDisplay())
    return <div className={clsx(TypographyStyles.plainText14, Style.progress)}>{`${value.filled}/${value.total}`}</div>;

  return null;
};
