import clsx from 'clsx';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { MetricActions } from '../../../application/analitics/matomo/matomo-actions';
import { MetricCategories } from '../../../application/analitics/matomo/matomo-categories';
import { trackEvent } from '../../../application/analitics/matomo/matomo-tracker';
import { logger } from '../../../application/logging/logging';
import { downloadFile } from '../../../helpers/file-helper';
import { QueryFactory } from '../../../services/api';
import { AppButton } from 'uikit/buttons';
import Style from './logExportModal.module.css';
import { ExportPatientLogProps, FileType, LogType } from './logExportModal';
import { FormLifecycleState, RouteProgressStateEnum } from '../../../services/api/api-client';
import { AppCheckboxInput, AppRadioGroupInput, AppSwitcherInput } from 'uikit/inputs';
import { AppInputLabel } from 'uikit/wrappers';
import { HardcodedSurveys } from '../../../application/constants/hardoced-entities';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { Permissions } from 'src/services/api/api-client';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { Loading } from 'uikit/suspense/Loading';
import { useGetFormByIdQuery } from '../../../services/api/api-client/FormsQuery';
import { getStatistics } from '../../../services/api/api-client/ExportLogClient';
import { useTranslation } from 'react-i18next';
import { conditionalConcat } from '../../../helpers/arrayHelpers';
import { generateEcrfReport } from '../../../features/report/ecrf/generateEcrfReport';
import { generateRecordsReport } from '../../../features/report/records/generateRecordsReport';
import { useGetStatisticTypesToExportQuery } from '../../../services/api/api-client/ExportLogQuery';
import { generateSurveyReport } from '../../../features/report/surveys/generateSurveyReport';
import { useStaffProfile, useStudy } from 'src/helpers/hooks/useStudy';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';

export const LogExportModal = (props: ExportPatientLogProps) => {
  const t = useCommonLocalization();
  const { hasPermission } = useHasPermissions();
  const { modal, studyId, patient, modalTitle } = props;
  const { profile } = useStaffProfile();
  const study = useStudy();

  const formConfigIdForNotes = study?.groups.find((x) => x.id === patient?.groupId)?.formConfigIdForNotes;

  const { data: notesForm } = useGetFormByIdQuery(formConfigIdForNotes!, {
    enabled: formConfigIdForNotes !== undefined && formConfigIdForNotes !== null,
  });

  const [error, setError] = useState('');
  const closeModal = useCallback(() => {
    setError('');
    modal.closeModal();
  }, [modal]);

  const hasOnlyExportEcrfPermission =
    hasPermission(Permissions.ExportPatientEcrfReport) && !hasPermission(Permissions.ExportForm);

  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [logType, setLogType] = useState<LogType['key']>(hasOnlyExportEcrfPermission ? 'ecrf' : 'auditlog');
  const [fileType, setFileType] = useState<FileType['key']>('csv');
  const [selectedSurveys, setSelectedSurveys] = useState<string[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [signPdf, setSignPdf] = useState(false);
  const [withMissedSurvey, setWithMissedSurveys] = useState(false);
  const [exportAllResultVersions, setExportAllResultVersions] = useState<boolean>(false);

  useEffect(() => {
    setError('');
  }, [logType, fileType, selectedRecords, selectedSurveys, signPdf, withMissedSurvey]);

  const { isInitialLoading, data } = useGetStatisticTypesToExportQuery(
    studyId,
    patient?.uniqueId,
    fileType === 'pdf' && withMissedSurvey,
    {
      enabled: modal.visible && hasPermission(Permissions.ExportForm),
      suspense: false,
    },
  );
  const { surveyTypes: incomingSurveys } = data || {};
  const surveyTypes = incomingSurveys?.filter((x) => x !== HardcodedSurveys.prismaCheck);

  /**
   * This hook excludes not actualized survey types.
   *
   * e.g. When we toggle  "missing surveys" we could memorize "missing survey" in selectedSurveys (local state),
   * so we make extra check in this hook
   */
  useLayoutEffect(() => {
    if (!!surveyTypes && !selectedSurveys.every((x) => surveyTypes.includes(x))) {
      setSelectedSurveys(selectedSurveys.filter((x) => surveyTypes.includes(x)));
    }
  }, [surveyTypes, selectedSurveys]);

  const formTypesToExport = useMemo(
    () => data?.formTypes.filter((ft) => ft !== notesForm?.type),
    [data?.formTypes, notesForm?.type],
  );

  const formTypes = useMemo(() => {
    if (fileType === 'csv') {
      return formTypesToExport;
    }

    const missedTypes = patient?.routeProgress.steps
      ?.filter((step) => step.state === RouteProgressStateEnum.Missed)
      .flatMap((step) => step.forms.filter((x) => x.formResultId === null).map((x) => x.formType) ?? []);

    return [...new Set([...(formTypesToExport ?? []), ...(missedTypes ?? [])])];
  }, [formTypesToExport, fileType, patient?.routeProgress]);

  const isAllSurveysSelected = useMemo(() => {
    return surveyTypes?.every((x) => selectedSurveys?.includes(x));
  }, [selectedSurveys, surveyTypes]);

  const isAllRecordsSelected = useMemo(() => {
    return formTypes?.every((x) => selectedRecords?.includes(x));
  }, [formTypes, selectedRecords]);

  const LogTypeOptions = useLogTypeOptions(patient ? 'patient' : 'study');
  const FileTypeOptions = useFileTypeOptions();

  const generateRecordsPdfReport = useCallback(async () => {
    if (selectedRecords.length === 0 || !patient || !studyId || !study?.title || !study?.studyNumber || !profile)
      return;

    await generateRecordsReport(
      { id: patient.id, uniqueId: patient.uniqueId, tags: patient.tags, routeProgress: patient.routeProgress },
      {
        id: studyId,
        title: study?.title,
        studyNumber: study?.studyNumber,
        groups: study?.groups,
        hasEcrf: study?.hasEcrf,
        patientFinishingFormId: study?.patientFinishingFormId,
      },
      profile.fullName,
      selectedRecords,
      exportAllResultVersions,
      signPdf,
    );

    closeModal();
  }, [
    selectedRecords,
    patient,
    studyId,
    study?.title,
    study?.studyNumber,
    study?.groups,
    study?.hasEcrf,
    study?.patientFinishingFormId,
    profile,
    exportAllResultVersions,
    signPdf,
    closeModal,
  ]);

  const generateSurveyPdfReport = useCallback(async () => {
    if (selectedSurveys.length === 0 || !patient || !study?.title || !study?.studyNumber || !profile) {
      return;
    }

    await generateSurveyReport(
      patient,
      selectedSurveys,
      {
        studyNumber: study.studyNumber,
        title: study?.title,
      },
      profile.fullName,
      withMissedSurvey,
      signPdf,
    );

    closeModal();
    return;
  }, [selectedSurveys, patient, study?.title, study?.studyNumber, profile, withMissedSurvey, signPdf, closeModal]);

  const generateEcrfPdfReport = useCallback(async () => {
    if (!study?.studyNumber || !study?.title || !patient || !profile?.fullName)
      throw new Error('Cannot generate eCRF report');

    await generateEcrfReport(
      {
        id: studyId,
        title: study?.title,
        studyNumber: study?.studyNumber,
        groups: study?.groups,
        hasEcrf: study?.hasEcrf,
        patientFinishingFormId: study?.patientFinishingFormId,
      },
      patient,
      profile.fullName,
    );

    closeModal();
  }, [
    study?.studyNumber,
    study?.title,
    study?.groups,
    study?.hasEcrf,
    study?.patientFinishingFormId,
    patient,
    profile?.fullName,
    studyId,
    closeModal,
  ]);

  const ExportHandler = useCallback(async () => {
    try {
      setError('');
      setDownloading(true);

      if (logType === 'auditlog') {
        const fileResponse = await QueryFactory.ExportLogQuery.Client.getEventLog(studyId, patient?.uniqueId);

        if (!fileResponse) {
          return;
        }

        downloadFile(fileResponse);
        closeModal();
        return;
      }

      if (logType === 'statistics' && fileType === 'csv') {
        const fileResponse = await getStatistics(
          studyId,
          selectedSurveys,
          selectedRecords,
          study?.hasEcrf ? [FormLifecycleState.Approved] : undefined,
          patient?.uniqueId,
          !exportAllResultVersions,
        );

        if (!fileResponse) {
          return;
        }

        downloadFile(fileResponse);
        closeModal();
        return;
      }

      if (logType === 'statistics' && fileType === 'pdf') {
        await generateRecordsPdfReport();
        await generateSurveyPdfReport();
      }

      if (logType === 'ecrf') {
        await generateEcrfPdfReport();
      }

      trackEvent({
        category: MetricCategories.Patient,
        action: MetricActions.ButtonPressed,
        name: 'Export logs for patient',
      });
    } catch (err: any) {
      setError(t('LogExport.CommonError'));
      logger().error('Export log failed', err);
    } finally {
      setDownloading(false);
    }
  }, [
    logType,
    fileType,
    studyId,
    patient?.uniqueId,
    closeModal,
    selectedSurveys,
    selectedRecords,
    study?.hasEcrf,
    exportAllResultVersions,
    generateRecordsPdfReport,
    generateSurveyPdfReport,
    generateEcrfPdfReport,
    t,
  ]);

  const surveySection = useMemo(() => {
    if (!surveyTypes || surveyTypes.length === 0) {
      return <></>;
    }

    return (
      <div className={Style.surveySection}>
        <div className={clsx({ [Style.disabled]: isDownloading })}>{t('LogExport.SurveySectionHeader')}</div>

        <AppButton
          variant={'icon-link'}
          colorSchema={'primary'}
          text={isAllSurveysSelected ? t('Common_None') : t('Common_SelectAll')}
          className={Style.uncheckAllButton}
          disabled={isDownloading}
          onClick={() => (isAllSurveysSelected ? setSelectedSurveys([]) : setSelectedSurveys(surveyTypes))}
        />

        {surveyTypes.map((type) => (
          <AppCheckboxInput
            key={type}
            checked={selectedSurveys.includes(type)}
            label={type}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedSurveys((state) => [...state, type]);
                return;
              }

              setSelectedSurveys((state) => state.filter((s) => s !== type));
            }}
            disabled={isDownloading}
          />
        ))}

        {fileType === 'pdf' && (
          <AppSwitcherInput
            label={t('LogExport.IncludeMissingSurveys')}
            disabled={isDownloading}
            checked={withMissedSurvey}
            className={Style.switcher}
            onChange={(e) => {
              setWithMissedSurveys(e.target.checked);
            }}
          />
        )}
      </div>
    );
  }, [surveyTypes, isDownloading, t, fileType, withMissedSurvey, isAllSurveysSelected, selectedSurveys]);

  const recordSection = useMemo(() => {
    if (!formTypes || formTypes.length === 0) {
      return <></>;
    }

    return (
      <div className={Style.surveySection}>
        <div className={clsx({ [Style.disabled]: isDownloading })}>{t('LogExport.RecordSectionHeader')}</div>
        <AppButton
          variant={'icon-link'}
          colorSchema={'primary'}
          text={isAllRecordsSelected ? t('Common_None') : t('Common_SelectAll')}
          className={Style.uncheckAllButton}
          disabled={isDownloading}
          onClick={() =>
            isAllRecordsSelected
              ? setSelectedRecords((selected) => {
                  return selected.filter((x) => x === notesForm?.type);
                })
              : setSelectedRecords((selected) => [...selected, ...formTypes])
          }
        />

        {formTypes.map((type) => (
          <AppCheckboxInput
            key={type}
            checked={selectedRecords.includes(type)}
            label={type}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRecords((state) => [...state, type]);
                return;
              }

              setSelectedRecords((state) => state.filter((s) => s !== type));
            }}
            disabled={isDownloading}
          />
        ))}

        {study?.hasEcrf && (
          <AppSwitcherInput
            label={t('LogExport.ExportAllResultVersions')}
            disabled={isDownloading}
            checked={exportAllResultVersions}
            className={Style.switcher}
            onChange={(e) => {
              setExportAllResultVersions(e.target.checked);
            }}
          />
        )}
      </div>
    );
  }, [
    formTypes,
    isDownloading,
    t,
    study?.hasEcrf,
    exportAllResultVersions,
    isAllRecordsSelected,
    notesForm?.type,
    selectedRecords,
  ]);

  const notesSection = useMemo(() => {
    const notesFormType = notesForm?.type;
    if (!notesFormType || !data?.formTypes.includes(notesFormType)) {
      return <></>;
    }

    return (
      <div className={Style.surveySection}>
        <div className={clsx({ [Style.disabled]: isDownloading })}>{t('LogExport.NotesSectionHeader')}</div>

        <AppCheckboxInput
          checked={selectedRecords.includes(notesFormType)}
          label={notesFormType}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRecords((state) => [...state, notesFormType]);
              return;
            }
            setSelectedRecords((state) => state.filter((s) => s !== notesFormType));
          }}
          disabled={isDownloading}
        />
      </div>
    );
  }, [notesForm?.type, data?.formTypes, isDownloading, t, selectedRecords]);

  return (
    <DialogModal
      title={modalTitle}
      visible={modal.visible}
      onHide={closeModal}
      onDismissed={() => {
        setSelectedSurveys([]);
        setFileType('csv');
        setLogType(hasOnlyExportEcrfPermission ? 'ecrf' : 'auditlog');
        setWithMissedSurveys(false);
        setExportAllResultVersions(false);
        setSignPdf(false);
      }}
      isClickOutside={true}
      bodyClassName={clsx(Style.modalBody, { [Style.patientDataExport]: logType === 'statistics' })}
      testId={'export-logs-from'}
      footer={{
        errors: error,
        leftButton: {
          text: t('Common_Cancel'),
          onClick: closeModal,
        },
        rightButton: {
          text: t('Common_Export'),
          onClick: ExportHandler,
          isLoading: isDownloading,
          disabled:
            isDownloading || (logType === 'statistics' && selectedSurveys.length === 0 && selectedRecords.length === 0),
        },
      }}
    >
      <div className={Style.content}>
        <AppInputLabel text={t('LogExport.ExportTypes.Header')}>
          <AppRadioGroupInput
            options={LogTypeOptions}
            isVertical
            labelField={'optionText'}
            onChange={(x) => {
              setLogType(x.key);
              setSelectedSurveys([]);
              setSelectedRecords([]);
            }}
            valueField={'key'}
            value={logType}
            disabled={isDownloading}
          />
        </AppInputLabel>

        {logType === 'statistics' && (
          <>
            {patient && (
              <AppInputLabel text={t('LogExport.ExportTypes.FileTypeHeader')}>
                <AppRadioGroupInput
                  options={FileTypeOptions}
                  labelField={'optionText'}
                  onChange={(x) => {
                    setFileType(x.key);
                    setSelectedSurveys([]);
                    setSelectedRecords([]);
                  }}
                  valueField={'key'}
                  value={fileType}
                  disabled={isDownloading}
                />
              </AppInputLabel>
            )}

            {fileType === 'pdf' && study?.hasPdfSigning && (
              <PermissionsCheck permissions={Permissions.ExportForm}>
                <AppCheckboxInput
                  label={t('LogExport.SignPdf')}
                  checked={signPdf}
                  disabled={isDownloading}
                  onChange={(e) => {
                    setSignPdf(e.target.checked);
                  }}
                />
              </PermissionsCheck>
            )}

            <Loading loading={isInitialLoading} renderChildren={false} containerClassName={Style.loader}>
              {surveySection}
              {recordSection}
              {notesSection}
            </Loading>
          </>
        )}
      </div>
    </DialogModal>
  );
};

const useLogTypeOptions = (exportScope: 'study' | 'patient') => {
  const study = useStudy();
  const { t } = useTranslation();
  const { hasPermission } = useHasPermissions();

  const studyScopeOptions = useMemo(
    () =>
      conditionalConcat<LogType>(
        hasPermission(Permissions.ExportAuditLog) && [
          {
            key: 'auditlog',
            optionText: t('LogExport.ExportTypes.StudyAuditLogs'),
          },
        ],
        hasPermission(Permissions.ExportForm) && [
          {
            key: 'statistics',
            optionText: t('LogExport.ExportTypes.AllPatientsData'),
          },
        ],
      ),
    [hasPermission, t],
  );

  const patientScopeOptions = useMemo(
    () =>
      conditionalConcat<LogType>(
        hasPermission(Permissions.ExportAuditLog) && [
          {
            key: 'auditlog',
            optionText: t('LogExport.ExportTypes.PatientAuditLogs'),
          },
        ],
        hasPermission(Permissions.ExportForm) && [
          {
            key: 'statistics',
            optionText: t('LogExport.ExportTypes.PatientData'),
          },
        ],
        study?.hasEcrf &&
          hasPermission(Permissions.ExportPatientEcrfReport) &&
          exportScope === 'patient' && [
            {
              key: 'ecrf',
              optionText: t('LogExport.ExportTypes.Ecrf'),
            },
          ],
      ),
    [exportScope, hasPermission, study?.hasEcrf, t],
  );

  return exportScope === 'study' ? studyScopeOptions : patientScopeOptions;
};

const useFileTypeOptions = () => {
  const { t } = useTranslation();

  return useMemo<FileType[]>(() => {
    return [
      {
        key: 'csv',
        optionText: t('LogExport.ExportTypes.Csv'),
      },
      {
        key: 'pdf',
        optionText: t('LogExport.ExportTypes.Pdf'),
      },
    ];
  }, [t]);
};
