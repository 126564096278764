/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo } from 'react';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';
import { enumToDropdownOptions } from 'src/components/uikit/inputs/dropdown/dropdown-helper';
import { ISurveyOption, ISurveyQuestion, QuestionType, SkipOptions } from 'src/services/api/api-client';
import { TypographyStyles } from 'src/styles';
import { useContextSelector } from 'use-context-selector';
import { changeField } from '../../components/editableField/editableField.component';
import { OptionOverview } from '../../components/optionOverview/optionOverview.component';
import { EditableQuestionContext, QuestionSchemeEditorContext } from '../../container/questionSchemeUIEditor.context';
import { createCommonOption } from '../../helpers/option-helpers';
import Style from './questionToolBox.module.css';
import { AppTextInput } from 'uikit/inputs';
import { AppInputLabel } from 'uikit/wrappers';
import { AppCheckboxInput } from 'uikit/inputs';
import { AppInputError } from 'uikit/wrappers';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';

export const QuestionToolBox = () => {
  const questionFromContext = useContextSelector(QuestionSchemeEditorContext, (x) => x.selectedQuestion);
  const setQuestionFromContext = useContextSelector(QuestionSchemeEditorContext, (x) => x.selectQuestion);
  const saveQuestion = useContextSelector(QuestionSchemeEditorContext, (x) => x.saveQuestion);
  const errorsFromContext = useContextSelector(QuestionSchemeEditorContext, (x) => x.errors);

  const setEditableQuestion = useContextSelector(EditableQuestionContext, (x) => x.setEditableQuestion)!;
  const editableQuestion = useContextSelector(EditableQuestionContext, (x) => x.editableQuestion);
  const editableOptionId = useContextSelector(EditableQuestionContext, (x) => x.editableOptionId);
  const setEditableOptionId = useContextSelector(EditableQuestionContext, (x) => x.setEditableOptionId)!;
  const deleteOption = useContextSelector(EditableQuestionContext, (x) => x.deleteOption)!;

  useEffect(() => {
    setEditableQuestion(questionFromContext);
  }, [questionFromContext, setEditableQuestion]);

  const saveHandler = useCallback(async () => {
    if (editableQuestion && saveQuestion && questionFromContext) {
      saveQuestion(questionFromContext.id, editableQuestion);
    }
  }, [editableQuestion, questionFromContext, saveQuestion]);

  const cancelHandler = useCallback(() => {
    setEditableQuestion(null);
    return setQuestionFromContext && setQuestionFromContext(null);
  }, [setEditableQuestion, setQuestionFromContext]);

  const onCreateOptionHandler = useCallback(() => {
    if (!editableQuestion) {
      return;
    }

    const option: ISurveyOption = {
      discriminator: 'CommonOption',
      questionScheme: null,
      optionText: `Option${editableQuestion.options?.length}`,
      optionValue: `Value${editableQuestion.options?.length}`,
      scoreValue: editableQuestion.options?.length ?? 0,
    };

    setEditableQuestion(
      changeField<ISurveyQuestion>(editableQuestion, 'options', [
        ...(editableQuestion.options ?? []),
        createCommonOption(option),
      ]),
    );
  }, [editableQuestion, setEditableQuestion]);

  const isErrors = useMemo(() => {
    return Object.keys(errorsFromContext).length > 0;
  }, [errorsFromContext]);

  const errorsText = useMemo(() => {
    return Object.keys(errorsFromContext)
      .map((key) => errorsFromContext[key])
      .join('\n');
  }, [errorsFromContext]);

  return (
    <div className={Style.container}>
      {editableQuestion ? (
        <>
          <div className={Style.settings}>
            <AppInputLabel text={'Question text'}>
              <AppTextInput
                value={editableQuestion?.questionText}
                type={'text-area'}
                onChange={(e) =>
                  setEditableQuestion(changeField<ISurveyQuestion>(editableQuestion, 'questionText', e.target.value))
                }
              />
            </AppInputLabel>
            <AppInputLabel text="Question type">
              <AppDropDownWithSuggestionInput
                value={enumToDropdownOptions(QuestionType).find(
                  (option) => editableQuestion.questionType === QuestionType[option.key],
                )}
                options={enumToDropdownOptions(QuestionType)
                  // ConditionalQuestion = 5 is deprecated
                  .filter((x) => x.key !== QuestionType[5])}
                onChange={(option) => {
                  return (
                    option?.key &&
                    setEditableQuestion(
                      changeField<ISurveyQuestion>(editableQuestion, 'questionType', QuestionType[option?.key]),
                    )
                  );
                }}
              />
            </AppInputLabel>
            <AppInputLabel text={'Skip by default'}>
              <AppDropDownWithSuggestionInput
                value={enumToDropdownOptions(SkipOptions).find(
                  (option) => editableQuestion.isSkippedRender === SkipOptions[option.key],
                )}
                options={enumToDropdownOptions(SkipOptions)}
                onChange={(option) => {
                  return (
                    option?.key &&
                    setEditableQuestion(
                      changeField<ISurveyQuestion>(editableQuestion, 'isSkippedRender', SkipOptions[option?.key]),
                    )
                  );
                }}
              />
            </AppInputLabel>
            <AppCheckboxInput
              label={'Can be skipped by user'}
              checked={editableQuestion?.canBeSkipped}
              onChange={(e) =>
                setEditableQuestion(changeField<ISurveyQuestion>(editableQuestion, 'canBeSkipped', e.target.checked))
              }
            />
          </div>
          <div>
            <div className={TypographyStyles.plainText12}>{'Answer options:'}</div>
            <div className={Style.optionsContainer}>
              {editableQuestion.options?.map((option, i) => (
                <div key={i} className={clsx({ [Style.selectedOption]: i === editableOptionId })}>
                  <OptionOverview
                    route={{ optionIndex: i }}
                    option={option}
                    isEditMode={true}
                    onEditOption={() => setEditableOptionId(i)}
                    onDeleteOption={() => deleteOption(i)}
                  />
                </div>
              ))}
            </div>
            <AppButton
              text={'Add option'}
              variant={'icon-link'}
              colorSchema={'primary'}
              onClick={onCreateOptionHandler}
              className={Style.addOptionButton}
            />
          </div>

          <AppInputError errors={errorsText} hideBorder position={'top'}>
            <div className={Style.buttonContainer}>
              <AppButton
                text={'Save'}
                variant={'button'}
                colorSchema={'primary'}
                onClick={saveHandler}
                disabled={isErrors}
              />
              <AppButton text={'Cancel'} variant={'button'} colorSchema={'secondary'} onClick={cancelHandler} />
            </div>
          </AppInputError>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
