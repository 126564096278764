import _ from 'lodash';
import { ISurveyOption, ISurveyQuestion, QuestionType } from 'src/services/api/api-client';

export type ErrorKeys = keyof ISurveyQuestion | keyof ISurveyOption;

export type ValidationRuleType = {
  key: ErrorKeys;
  text: string;
  validate: (question: ISurveyQuestion) => boolean | undefined;
};

export const ValidationRules: { [key: string]: ValidationRuleType } = {
  OptionValueRule: {
    key: 'optionValue',
    text: 'Option value should be unique',
    validate: (editableQuestion: ISurveyQuestion) => {
      const optionValues = editableQuestion?.options?.map((o) => o.optionValue) ?? [];
      const uniq = _.uniq(optionValues).length;
      return optionValues.length !== uniq;
    },
  },

  OptionCountRule: {
    key: 'options',
    text: 'Question has no options',
    validate: (editableQuestion: ISurveyQuestion) => {
      return !editableQuestion?.options?.length;
    },
  },

  DefaultValueRule: {
    key: 'defaultValue',
    text: 'Default option already exist',
    validate: (editableQuestion: ISurveyQuestion) => {
      return (editableQuestion?.options?.filter((o) => o.optionValue === o.defaultValue).length ?? 0) > 1;
    },
  },

  SingleChoiceAnswerRule: {
    key: 'answerValue',
    text: 'Only one answer can be choice for "Single choice question" type',
    validate: (editableQuestion: ISurveyQuestion) => {
      return (
        editableQuestion.questionType === QuestionType.SingleChose &&
        (editableQuestion?.options?.filter((o) => o.optionValue === o.answerValue).length ?? 0) > 1
      );
    },
  },
};
