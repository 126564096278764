import React, { useMemo, FC, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useModal, UseModalProps } from '../../../../application/hooks/useModal';
import { DataType } from '../../uiEditor/uiEditor';
import styles from '../../uiEditor/uiEditor.module.scss';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../../application/localisation/useCommonLocalization';
import { DateFormats, localFormat, toDateOrNull } from '../../../../helpers/date-helpers';
import { Deferred } from '../../../../helpers/Deferred';
import { SerializedNode } from '@craftjs/core';
import { IFormFieldWithOptionsProps } from 'src/features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

export const useNotEditableFieldModal = () => {
  const modal = useModal('CLOSED');
  const deferred = useRef<Deferred>();

  return useMemo(
    () => ({
      open: () => {
        deferred.current = new Deferred();
        modal.openModal();
        return deferred.current.promise;
      },
      element: (notEditableFields: SerializedNode[], dataBlockValues: DataType | undefined) => (
        <NotEditableFieldsModal
          modal={modal}
          onSave={() => {
            modal.closeModal();
            deferred.current?.resolve();
          }}
          notEditableFields={notEditableFields}
          dataBlockValues={dataBlockValues}
        />
      ),
    }),
    [modal],
  );
};

export const NotEditableFieldsModal: FC<{
  modal: UseModalProps;
  notEditableFields: SerializedNode[];
  dataBlockValues: DataType | undefined;
  onSave: () => void;
}> = (props) => {
  const { t } = useScopedTranslation('Forms');
  const commonLocalizer = useCommonLocalization();
  const { modal, onSave, notEditableFields, dataBlockValues } = props;
  const study = useStudy();

  const renderNotEditableFields = useMemo(() => {
    if (!dataBlockValues) {
      return <></>;
    }

    const result = notEditableFields.map((node) => {
      const getAnswerText = () => {
        if (node.props.dataKey === 'GroupId') {
          return study?.groups.find((g) => g.id === Number(dataBlockValues.GroupId))?.title;
        }

        const fieldWithOptionTypes = ['RadioInput', 'CheckBoxGroupInput', 'SelectInput'];

        // @ts-ignore
        if (fieldWithOptionTypes.includes(node.type.resolvedName)) {
          const value = dataBlockValues[node.props.dataKey];
          const nodeProps = node.props as IFormFieldWithOptionsProps;

          if (Array.isArray(value))
            return nodeProps.options
              .filter((x) => value.includes(x.value))
              ?.map((x) => x.displayValue)
              .join('\n\r');

          return nodeProps.options.find((x) => x.value === value)?.displayValue;
        }

        const dateField = toDateOrNull(dataBlockValues[node.props.dataKey]);
        const isNotNumber = isNaN(Number(dataBlockValues[node.props.dataKey]));

        return dateField && isNotNumber
          ? localFormat(dateField, DateFormats.longDate)
          : dataBlockValues[node.props.dataKey];
      };

      return (
        <p key={node.props.dataKey}>
          <span>{node.props.label ?? node.props.text ?? node.props.dataKey}</span>
          <div style={{ whiteSpace: 'pre-line' }}>{getAnswerText()}</div>
        </p>
      );
    });

    return <div className={styles.patientDataContainer}>{result}</div>;
  }, [dataBlockValues, notEditableFields, study?.groups]);

  return (
    <DialogModal
      title={t('PatientDataModal.Header')}
      testId={'not-editable-fields-modal'}
      visible={modal.visible}
      bodyClassName={styles.modalBody}
      onHide={modal.closeModal}
      footer={{
        leftButton: {
          text: commonLocalizer('Common_Back'),
          onClick: modal.closeModal,
        },
        rightButton: {
          text: t('Controls.SaveButton'),
          onClick: onSave,
        },
      }}
    >
      <>
        <p className={styles.mainText}>
          <Trans i18nKey={'Forms.PatientDataModal.MainText'}>
            <span className={styles.awfulText} />
          </Trans>
        </p>

        {renderNotEditableFields}
      </>
    </DialogModal>
  );
};
