import React, { useContext, useMemo, FC } from 'react';
import { NoteContext } from '../../NoteContext';
import { IFormFieldWithOptionsProps } from '../../../../../features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import { NoteField } from '../../NoteField';
import { ConditionalFieldBehaviorEnum } from '../../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import { Element } from '@craftjs/core';
import { noteBodyFormControlsResolver } from './noteBodyFormControlsResolver';
import { getStatusIcon } from '../../../../formEditor/controls/Inputs/statusIcons/statusIcons.constants';

export const FieldWithOptions: FC<IFormFieldWithOptionsProps> = (props) => {
  const noteContext = useContext(NoteContext);
  const inputValue = noteContext.currentResultVersion.value?.[props.dataKey] as string | string[] | undefined | null;
  const isOverview = noteContext.mode === 'overview';

  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  const valueVms = useMemo(() => {
    return props.options
      .filter((x) => multipleInputValue?.includes(x.value))
      .map((x) => ({
        key: x.value,
        displayValue: x.displayValue,
        icon: x.statusIconName ? getStatusIcon(x.statusIconName) : undefined,
      }));
  }, [multipleInputValue, props.options]);

  const conditionalContainer = useMemo(() => {
    return props.options
      .filter((x) => x.withConditionalField)
      .filter(
        (option) =>
          option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
          multipleInputValue?.includes(option.value),
      )
      .map((option) => {
        return <Element key={option.value} id={option.key.toString()} is={noteBodyFormControlsResolver.Container} />;
      });
  }, [props.options, multipleInputValue]);

  if (valueVms.length === 0) return <></>;

  // Fields with status icons are displayed in note header
  if (props.hasStatusIcons && !isOverview) return <></>;

  return (
    <>
      <NoteField label={props.label ?? props.dataKey} values={valueVms} />
      {conditionalContainer}
    </>
  );
};
