import React, { forwardRef, useMemo } from 'react';
import clsx from 'clsx';
import Style from './appTextField.module.scss';
import { AppTextInput, AppTextInputProps } from 'uikit/inputs';
import { AppInputErrorProps, AppInputError, ErrorSign, AppInputSkip, FieldSkipProps } from 'uikit/wrappers';
import { AppInputLabelProps, AppInputLabel } from 'uikit/wrappers';
import { AppInputTriggerProps, AppInputTrigger } from 'uikit/wrappers';

export type AppTextFieldProps = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  triggerProps?: AppInputTriggerProps;
  skipProps?: FieldSkipProps;
  maxLength?: number;
  units?: string;
} & Omit<AppTextInputProps, 'invalid'>;

export const AppTextField = forwardRef<HTMLInputElement, AppTextFieldProps>(
  ({ maxLength, errorProps, triggerProps, labelProps, skipProps, value, units, disabled, ...rest }, ref) => {
    const lengthSection = useMemo(() => {
      if (!maxLength) {
        return <></>;
      }

      return (
        <span
          className={clsx(Style.maxLengthSection, {
            [Style.maxLengthError]: (value as string)?.length > maxLength,
          })}
        >{`${(value as string)?.length ?? 0}/${maxLength}`}</span>
      );
    }, [value, maxLength]);

    const rightSection = useMemo(() => {
      return (
        <div className={Style.rightSection}>
          {lengthSection}
          {errorProps?.errors && <ErrorSign />}
        </div>
      );
    }, [errorProps?.errors, lengthSection]);

    return (
      <AppInputError {...errorProps}>
        <AppInputLabel
          disabled={disabled}
          rightSection={rightSection}
          className={clsx({ [Style.textAreaWithCross]: triggerProps?.type })}
          {...labelProps}
          subText={units?.trim() ? ` [${units.trim()}]` : undefined}
        >
          <AppInputSkip
            inputCanBeSkipped={!!skipProps && skipProps.inputCanBeSkipped}
            skipText={skipProps?.skipText}
            showReasons={skipProps?.showReasons}
            disabled={disabled}
            skipReason={skipProps?.skipReason}
            onSkipReasonChange={skipProps?.onSkipReasonChange ?? (() => {})}
          >
            <AppInputTrigger disabled={disabled} {...triggerProps}>
              <AppTextInput
                ref={ref}
                disabled={disabled}
                value={value || ''}
                invalid={!!errorProps?.errors || (!!errorProps?.errors?.length && errorProps?.errors?.length > 0)}
                {...rest}
              />
            </AppInputTrigger>
          </AppInputSkip>
        </AppInputLabel>
      </AppInputError>
    );
  },
);
