﻿import React, { FC, useCallback, useRef } from 'react';
import { useModal } from 'src/application/hooks/useModal';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import styles from './useMonitoringFormsEditing.module.scss';
import { HookFormTextInput } from '../../../hookFormControls/hookFormTextInput';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { HookFormRadioGroup } from '../../../hookFormControls/hookFormRadioGroup';
import { HookFormDropDown } from '../../../hookFormControls/hookFormDropDown';
import { AppButton } from 'uikit/buttons';
import { ReactComponent as Add } from 'src/assets/img/records/add_24.svg';
import { FieldGroup } from 'uikit/fields/fieldGroup/FieldGroup';
import { RepeatableFormSection } from 'uikit/fields/repeatableFormSection/RepeatableFormSection';
import {
  CreateMonitoringFormDto,
  FieldSkipReason,
  IMonitoringFormDto,
  UpdateMonitoringFormDto,
} from '../../../services/api/api-client';
import { createMonitoringForm, updateMonitoringForm } from '../../../services/api/api-client/MonitoringFormsClient';
import { useQueryClient } from '@tanstack/react-query';
import { getMonitoringFormsQueryKey } from '../../../services/api/api-client/MonitoringFormsQuery';
import { ValidationFormRules } from '../../../helpers/validation-helpers';
import { FormState, MonitoringFormType, MonitoringFormValue } from './MonitoringFormValue';
import {
  useConsentFormsReviewedOptions,
  useMonitoringFormTypeOptions,
  usePatientIdsOptions,
  usePersonNamesOptions,
  useSdvOptions,
  useStudyDocumentsOptions,
  useYesNoOptions,
} from './fieldOptions';
import { Deferred } from '../../../helpers/Deferred';
import { Loading } from 'uikit/suspense/Loading';
import { PickFieldsWithType } from '../../../helpers/typeUtils';
import {
  TextInputWithSkipCheckbox,
  DropDownWithSkipCheckbox,
  RadioGroupWithSkipCheckbox,
  CommentField,
} from './MonitoringFormComponents';
import { ClearAnswers } from './MonitoringFormHelper';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
import { logger } from '../../../application/logging/logging';
import { ConfirmResult, useConfirmationModal } from 'src/components/dialogModal/useConfirmationModal';
import { useTranslation } from 'react-i18next';
import { HookFormCheckboxField } from 'src/hookFormControls/HookFormCheckboxGroupField';

export const useMonitoringFormsEditing = () => {
  const { t } = useTranslation();
  const modal = useModal('CLOSED');
  const queryClient = useQueryClient();
  const confirmationModal = useConfirmationModal();
  const onHideConfirmationModal = useConfirmationModal({ containerClassName: styles.leaveModal });

  type EditingResult = { type: 'Saved'; report: IMonitoringFormDto } | { type: 'Canceled' };
  const deferred = useRef<Deferred<EditingResult>>();

  const form = useForm<FormState>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const submit = form.handleSubmit(async (values) => {
    const fields = ClearAnswers(values.fields);

    const mappedValue = {
      testingCenter: fields.testingCenter,
      dateOfVisit: fields.dateOfVisit,
      // NOTE: If you want to change the name of this field or delete it, also change the DeleteParticipant method.
      personNames: fields.personNames?.map((x) => x.key.toString()),
      enoughTimeToHoldMeeting: fields.enoughTimeToHoldMeeting,
      enoughTimeToHoldMeeting_comment: fields.enoughTimeToHoldMeeting_comment,
      generalComment: fields.generalComment?.value,
      delegationLogFilledInOrSupplemented: fields.delegationLogFilledInOrSupplemented?.value,
      delegationLogFilledInOrSupplemented_comment: fields.delegationLogFilledInOrSupplemented_comment,
      technicalProblemsAtFacilityAffectingStudy: fields.technicalProblemsAtFacilityAffectingStudy?.value,
      technicalProblemsAtFacilityAffectingStudy_comment: fields.technicalProblemsAtFacilityAffectingStudy_comment,
      properStorageOfStudyDocuments: fields.properStorageOfStudyDocuments?.value,
      properStorageOfStudyDocuments_comment: fields.properStorageOfStudyDocuments_comment,
      givenStudyDocuments: fields.givenStudyDocuments?.value,
      givenStudyDocuments_comment: fields.givenStudyDocuments_comment,

      studyDocuments: fields.studyDocuments?.map((x) => x.key),
      testPlanVersion: fields.testPlanVersion,
      patientInformationAndConsentVersion: fields.patientInformationAndConsentVersion,
      insuranceDocuments: fields.insuranceDocuments,
      communicationWithEc: fields.communicationWithEc,
      supportingDocuments: fields.supportingDocuments,
      randomizationList: fields.randomizationList,
      otherStudyDocuments: fields.otherStudyDocuments,

      typeOfMonitoringVisit: fields.typeOfMonitoringVisit,
      typeOfMonitoringVisit_comment: fields.typeOfMonitoringVisit_comment,
      spatialChangesAtTestCenter: fields.spatialChangesAtTestCenter?.value,
      spatialChangesAtTestCenter_comment: fields.spatialChangesAtTestCenter_comment,
      studyDocumentExchanged: fields.studyDocumentExchanged?.value,
      studyDocumentExchanged_comment: fields.studyDocumentExchanged_comment,
      exchangedDocumentNameAndReason: fields.exchangedDocumentNameAndReason,
      personalIdentificationListIsComplete: fields.personalIdentificationListIsComplete?.value,
      personalIdentificationListIsComplete_comment: fields.personalIdentificationListIsComplete_comment,
      patientIdentificationListIsComplete: fields.patientIdentificationListIsComplete?.value,
      patientIdentificationListIsComplete_comment: fields.patientIdentificationListIsComplete_comment,
      consentFormsReviewed: fields.consentFormsReviewed?.value?.key,
      declarationsOfConsentIsComplete: fields.declarationsOfConsentIsComplete?.value,
      declarationsOfConsentIsComplete_comment: fields.declarationsOfConsentIsComplete_comment,
      openQueriesDiscussed: fields.openQueriesDiscussed?.value,
      openQueriesDiscussed_comment: fields.openQueriesDiscussed_comment,
      resetOfDevices: fields.resetOfDevices?.value,
      resetOfDevices_comment: fields.resetOfDevices_comment,
      studyEquipmentPool: fields.studyEquipmentPool?.value,
      studyEquipmentPool_comment: fields.studyEquipmentPool_comment,
      referenceToStudyDocuments: fields.referenceToStudyDocuments?.value,
      referenceToStudyDocuments_comment: fields.referenceToStudyDocuments_comment,
      referenceToAuthority: fields.referenceToAuthority?.value,
      referenceToAuthority_comment: fields.referenceToAuthority_comment,
      clarificationOfPublication: fields.clarificationOfPublication?.value,
      clarificationOfPublication_comment: fields.clarificationOfPublication_comment,

      // NOTE: Patients section
      patientRange: fields.patientRange,
      patients: fields.patients?.map((x) => ({
        patientUniqueId: x.patientUniqueId?.key,
        sdv: x.sdv,
        sdvFixedRecord: x.sdvFixedRecord,
        consentIsCorrectlyObtained: x.consentIsCorrectlyObtained?.value,
        consentIsCorrectlyObtained_comment: x.consentIsCorrectlyObtained_comment,
        verifiableInclusionAndExclusionCriteriaAreCorrect: x.verifiableInclusionAndExclusionCriteriaAreCorrect?.value,
        verifiableInclusionAndExclusionCriteriaAreCorrect_comment:
          x.verifiableInclusionAndExclusionCriteriaAreCorrect_comment,
        accordingToStudySchedule: x.accordingToStudySchedule?.value,
        accordingToStudySchedule_comment: x.accordingToStudySchedule_comment,
        accordingToTestPlan: x.accordingToTestPlan?.value,
        accordingToTestPlan_comment: x.accordingToTestPlan_comment,
        newInformationDiscussed: x.newInformationDiscussed?.value,
        newInformationDiscussed_comment: x.newInformationDiscussed_comment,
        sourceDataDocumentationAvailable: x.sourceDataDocumentationAvailable?.value,
        sourceDataDocumentationAvailable_comment: x.sourceDataDocumentationAvailable_comment,
      })),

      confirmation: fields.confirmation,
    } satisfies MonitoringFormValue;

    type KeyOfSkippableField = keyof PickFieldsWithType<
      FormState['fields'],
      { fieldSkipReason: FieldSkipReason | undefined } | undefined
    >;

    const mappedFieldSkipReasons: { [key in KeyOfSkippableField]: FieldSkipReason } = {
      generalComment: fields.generalComment?.fieldSkipReason as FieldSkipReason,
      delegationLogFilledInOrSupplemented: fields.delegationLogFilledInOrSupplemented
        ?.fieldSkipReason as FieldSkipReason,
      technicalProblemsAtFacilityAffectingStudy: fields.technicalProblemsAtFacilityAffectingStudy
        ?.fieldSkipReason as FieldSkipReason,
      properStorageOfStudyDocuments: fields.properStorageOfStudyDocuments?.fieldSkipReason as FieldSkipReason,
      givenStudyDocuments: fields.givenStudyDocuments?.fieldSkipReason as FieldSkipReason,
      spatialChangesAtTestCenter: fields.spatialChangesAtTestCenter?.fieldSkipReason as FieldSkipReason,
      studyDocumentExchanged: fields.studyDocumentExchanged?.fieldSkipReason as FieldSkipReason,
      personalIdentificationListIsComplete: fields.personalIdentificationListIsComplete
        ?.fieldSkipReason as FieldSkipReason,
      patientIdentificationListIsComplete: fields.patientIdentificationListIsComplete
        ?.fieldSkipReason as FieldSkipReason,

      consentFormsReviewed: fields.consentFormsReviewed?.fieldSkipReason as FieldSkipReason,
      declarationsOfConsentIsComplete: fields.declarationsOfConsentIsComplete?.fieldSkipReason as FieldSkipReason,
      openQueriesDiscussed: fields.openQueriesDiscussed?.fieldSkipReason as FieldSkipReason,
      resetOfDevices: fields.resetOfDevices?.fieldSkipReason as FieldSkipReason,
      studyEquipmentPool: fields.studyEquipmentPool?.fieldSkipReason as FieldSkipReason,
      referenceToStudyDocuments: fields.referenceToStudyDocuments?.fieldSkipReason as FieldSkipReason,
      referenceToAuthority: fields.referenceToAuthority?.fieldSkipReason as FieldSkipReason,
      clarificationOfPublication: fields.clarificationOfPublication?.fieldSkipReason as FieldSkipReason,

      ...fields.patients
        ?.map((x, i) => ({
          [`patients.${i}.consentIsCorrectlyObtained`]: x.consentIsCorrectlyObtained
            ?.fieldSkipReason as FieldSkipReason,
          [`patients.${i}.verifiableInclusionAndExclusionCriteriaAreCorrect`]: x
            .verifiableInclusionAndExclusionCriteriaAreCorrect?.fieldSkipReason as FieldSkipReason,
          [`patients.${i}.accordingToStudySchedule`]: x.accordingToStudySchedule?.fieldSkipReason as FieldSkipReason,
          [`patients.${i}.accordingToTestPlan`]: x.accordingToTestPlan?.fieldSkipReason as FieldSkipReason,
          [`patients.${i}.newInformationDiscussed`]: x.newInformationDiscussed?.fieldSkipReason as FieldSkipReason,
          [`patients.${i}.sourceDataDocumentationAvailable`]: x.sourceDataDocumentationAvailable
            ?.fieldSkipReason as FieldSkipReason,
        }))
        .reduce((sum, x) => ({ ...sum, ...x }), {}),
    };

    let reportDto: IMonitoringFormDto;
    try {
      if (values.monitoringFormId) {
        const dto = new UpdateMonitoringFormDto({
          value: mappedValue,
          fieldSkipReasons: mappedFieldSkipReasons,
        });

        reportDto = await updateMonitoringForm(values.monitoringFormId, dto);
      } else {
        const dto = new CreateMonitoringFormDto({
          studyId: values.studyId,
          type: fields.typeOfMonitoringVisit!,
          value: mappedValue,
          fieldSkipReasons: mappedFieldSkipReasons,
        });

        reportDto = await createMonitoringForm(dto);
      }
    } catch (e) {
      // TODO: Handle error somehow
      logger().error('Monitoring form editing operation failed', e);
      return;
    }
    await queryClient.invalidateQueries(getMonitoringFormsQueryKey(values.studyId));
    modal.closeModal();

    deferred.current?.resolve({ type: 'Saved', report: reportDto });
    deferred.current = undefined;
  });

  const submitHandler = useCallback(async () => {
    const isValid = await form.trigger('fields', { shouldFocus: true });
    if (!isValid) return;

    const isConfirmed = form.watch('fields.confirmation');
    if (isConfirmed) {
      await submit();
      return;
    }

    const result = await confirmationModal.open({
      title: t('MonitoringForms.Editing.ConfirmationModal.Title'),
      text: t('MonitoringForms.Editing.ConfirmationModal.Text'),
      cancelButtonText: t('MonitoringForms.Editing.ConfirmationModal.CancelButtonText'),
      okButtonText: t('MonitoringForms.Editing.ConfirmationModal.OkButtonText'),
    });

    if (result === ConfirmResult.Confirm) await submit();
  }, [confirmationModal, form, submit, t]);

  const onHide = useCallback(async () => {
    if (form.formState.isDirty) {
      const result = await onHideConfirmationModal.open({
        title: t('Forms.ConfirmationModal.Header'),
        text: t('Forms.ConfirmationModal.MainText'),
        cancelButtonColorSchema: 'destroy',
        cancelButtonText: t('Forms.ConfirmationModal.DiscardBtn'),
        okButtonColorSchema: 'primary',
        okButtonText: t('Forms.ConfirmationModal.Save'),
      });

      if (result === ConfirmResult.Closed) return;
      if (result === ConfirmResult.Confirm) {
        await submitHandler();
        return;
      }
    }

    modal.closeModal();
    deferred.current?.resolve({ type: 'Canceled' });
  }, [form.formState.isDirty, modal, onHideConfirmationModal, submitHandler, t]);

  const studyDocumentsOptions = useStudyDocumentsOptions();
  const personNamesOptions = usePersonNamesOptions();
  const { patientIdsOptions } = usePatientIdsOptions();
  const consentFormsReviewedOptions = useConsentFormsReviewedOptions();

  return {
    startFilling: (args: { studyTitle: string; studyId: number }) => {
      form.reset({
        studyName: args.studyTitle,
        studyId: args.studyId,
        fields: {
          // Workaround for https://github.com/react-hook-form/react-hook-form/issues/11836
          patients: [],
        },
      });
      modal.openModal();

      deferred.current = new Deferred<EditingResult>();
      return deferred.current.promise;
    },
    startEditing: (args: { studyTitle: string; studyId: number; monitoringForm: IMonitoringFormDto }) => {
      const values = args.monitoringForm.value as MonitoringFormValue;
      const fieldSkipReasons = args.monitoringForm.fieldSkipReasons;
      // Workaround for https://github.com/react-hook-form/react-hook-form/issues/11836
      form.resetField('fields.patients', { defaultValue: [] });
      form.reset({
        monitoringFormId: args.monitoringForm.id,
        studyName: args.studyTitle,
        studyId: args.studyId,
        fields: {
          testingCenter: values.testingCenter,
          dateOfVisit: values.dateOfVisit,
          personNames: values.personNames?.map((x) => personNamesOptions.find((p) => p.key === x || p.text === x)!),
          enoughTimeToHoldMeeting: values.enoughTimeToHoldMeeting,
          enoughTimeToHoldMeeting_comment: values.enoughTimeToHoldMeeting_comment,
          generalComment: {
            value: values.generalComment,
            fieldSkipReason: fieldSkipReasons.generalComment,
          },
          delegationLogFilledInOrSupplemented: {
            value: values.delegationLogFilledInOrSupplemented,
            fieldSkipReason: fieldSkipReasons.delegationLogFilledInOrSupplemented,
          },
          delegationLogFilledInOrSupplemented_comment: values.delegationLogFilledInOrSupplemented_comment,
          technicalProblemsAtFacilityAffectingStudy: {
            value: values.technicalProblemsAtFacilityAffectingStudy,
            fieldSkipReason: fieldSkipReasons.technicalProblemsAtFacilityAffectingStudy,
          },
          technicalProblemsAtFacilityAffectingStudy_comment: values.technicalProblemsAtFacilityAffectingStudy_comment,
          properStorageOfStudyDocuments: {
            value: values.properStorageOfStudyDocuments,
            fieldSkipReason: fieldSkipReasons.properStorageOfStudyDocuments,
          },
          properStorageOfStudyDocuments_comment: values.properStorageOfStudyDocuments_comment,
          givenStudyDocuments: {
            value: values.givenStudyDocuments,
            fieldSkipReason: fieldSkipReasons.givenStudyDocuments,
          },
          givenStudyDocuments_comment: values.givenStudyDocuments_comment,

          studyDocuments: values.studyDocuments?.map((x) => studyDocumentsOptions.find((y) => y.key === x)!),
          testPlanVersion: values.testPlanVersion,
          patientInformationAndConsentVersion: values.patientInformationAndConsentVersion,
          insuranceDocuments: values.insuranceDocuments,
          communicationWithEc: values.communicationWithEc,
          supportingDocuments: values.supportingDocuments,
          randomizationList: values.randomizationList,
          otherStudyDocuments: values.otherStudyDocuments as MonitoringFormType,

          typeOfMonitoringVisit: values.typeOfMonitoringVisit as MonitoringFormType,
          typeOfMonitoringVisit_comment: values.typeOfMonitoringVisit_comment,
          spatialChangesAtTestCenter: {
            value: values.spatialChangesAtTestCenter,
            fieldSkipReason: fieldSkipReasons.spatialChangesAtTestCenter,
          },
          spatialChangesAtTestCenter_comment: values.spatialChangesAtTestCenter_comment,
          studyDocumentExchanged: {
            value: values.studyDocumentExchanged,
            fieldSkipReason: fieldSkipReasons.studyDocumentExchanged,
          },
          studyDocumentExchanged_comment: values.studyDocumentExchanged_comment,
          exchangedDocumentNameAndReason: values.exchangedDocumentNameAndReason,
          personalIdentificationListIsComplete: {
            value: values.personalIdentificationListIsComplete,
            fieldSkipReason: fieldSkipReasons.personalIdentificationListIsComplete,
          },
          personalIdentificationListIsComplete_comment: values.personalIdentificationListIsComplete_comment,
          patientIdentificationListIsComplete: {
            value: values.patientIdentificationListIsComplete,
            fieldSkipReason: fieldSkipReasons.patientIdentificationListIsComplete,
          },
          patientIdentificationListIsComplete_comment: values.patientIdentificationListIsComplete_comment,
          consentFormsReviewed: {
            value: consentFormsReviewedOptions.find((x) => x.key === values.consentFormsReviewed),
            fieldSkipReason: fieldSkipReasons.consentFormsReviewed,
          },
          declarationsOfConsentIsComplete: {
            value: values.declarationsOfConsentIsComplete,
            fieldSkipReason: fieldSkipReasons.declarationsOfConsentIsComplete,
          },
          declarationsOfConsentIsComplete_comment: values.declarationsOfConsentIsComplete_comment,
          openQueriesDiscussed: {
            value: values.openQueriesDiscussed,
            fieldSkipReason: fieldSkipReasons.openQueriesDiscussed,
          },
          openQueriesDiscussed_comment: values.openQueriesDiscussed_comment,
          resetOfDevices: {
            value: values.resetOfDevices,
            fieldSkipReason: fieldSkipReasons.resetOfDevices,
          },
          resetOfDevices_comment: values.resetOfDevices_comment,
          studyEquipmentPool: {
            value: values.studyEquipmentPool,
            fieldSkipReason: fieldSkipReasons.studyEquipmentPool,
          },
          studyEquipmentPool_comment: values.studyEquipmentPool_comment,
          referenceToStudyDocuments: {
            value: values.referenceToStudyDocuments,
            fieldSkipReason: fieldSkipReasons.referenceToStudyDocuments,
          },
          referenceToStudyDocuments_comment: values.referenceToStudyDocuments_comment,
          referenceToAuthority: {
            value: values.referenceToAuthority,
            fieldSkipReason: fieldSkipReasons.referenceToAuthority,
          },
          referenceToAuthority_comment: values.referenceToAuthority_comment,
          clarificationOfPublication: {
            value: values.clarificationOfPublication,
            fieldSkipReason: fieldSkipReasons.clarificationOfPublication,
          },
          clarificationOfPublication_comment: values.clarificationOfPublication_comment,

          // NOTE: Patients section
          patientRange: values.patientRange,
          patients: values.patients?.map((x, i) => ({
            patientUniqueId: patientIdsOptions.find((o) => o.key === x.patientUniqueId),
            sdv: x.sdv,
            sdvFixedRecord: x.sdvFixedRecord,
            consentIsCorrectlyObtained: {
              value: x.consentIsCorrectlyObtained,
              fieldSkipReason: fieldSkipReasons[`patients.${i}.consentIsCorrectlyObtained`],
            },
            consentIsCorrectlyObtained_comment: x.consentIsCorrectlyObtained_comment,
            verifiableInclusionAndExclusionCriteriaAreCorrect: {
              value: x.verifiableInclusionAndExclusionCriteriaAreCorrect,
              fieldSkipReason: fieldSkipReasons[`patients.${i}.verifiableInclusionAndExclusionCriteriaAreCorrect`],
            },
            verifiableInclusionAndExclusionCriteriaAreCorrect_comment:
              x.verifiableInclusionAndExclusionCriteriaAreCorrect_comment,
            accordingToStudySchedule: {
              value: x.accordingToStudySchedule,
              fieldSkipReason: fieldSkipReasons[`patients.${i}.accordingToStudySchedule`],
            },
            accordingToStudySchedule_comment: x.accordingToStudySchedule_comment,
            accordingToTestPlan: {
              value: x.accordingToTestPlan,
              fieldSkipReason: fieldSkipReasons[`patients.${i}.accordingToTestPlan`],
            },
            accordingToTestPlan_comment: x.accordingToTestPlan_comment,
            newInformationDiscussed: {
              value: x.newInformationDiscussed,
              fieldSkipReason: fieldSkipReasons[`patients.${i}.newInformationDiscussed`],
            },
            newInformationDiscussed_comment: x.newInformationDiscussed_comment,
            sourceDataDocumentationAvailable: {
              value: x.sourceDataDocumentationAvailable,
              fieldSkipReason: fieldSkipReasons[`patients.${i}.sourceDataDocumentationAvailable`],
            },
            sourceDataDocumentationAvailable_comment: x.sourceDataDocumentationAvailable_comment,
          })),

          confirmation: values.confirmation,
        } satisfies FormState['fields'],
      });

      modal.openModal();

      deferred.current = new Deferred<EditingResult>();
      return deferred.current.promise;
    },
    element: (
      <>
        <FormProvider {...form}>
          <MonitoringFormEditingModal
            visible={modal.visible}
            onHide={onHide}
            onSubmit={submitHandler}
            submitting={form.formState.isSubmitting}
          />
        </FormProvider>
        {confirmationModal.modal}
        {onHideConfirmationModal.modal}
      </>
    ),
  };
};

const MonitoringFormEditingModal: FC<{
  visible: boolean;
  onHide: () => void;
  onSubmit: () => void;
  submitting: boolean;
}> = (props) => {
  const { t } = useScopedTranslation('MonitoringForms');
  const form = useFormContext<FormState>();
  const error = form.formState.errors.root;

  const patients = useFieldArray<FormState>({
    control: form.control,
    name: 'fields.patients',
  });

  const yesNoOptions = useYesNoOptions();
  const personNamesOptions = usePersonNamesOptions();
  const studyDocumentsOptions = useStudyDocumentsOptions();
  const monitoringFormTypeOptions = useMonitoringFormTypeOptions();
  const sdvOptions = useSdvOptions();
  const { isLoading, patientIdsOptions } = usePatientIdsOptions();
  const consentFormsReviewedOptions = useConsentFormsReviewedOptions();

  return (
    <DialogModal
      bodyClassName={styles.modalBody}
      title={t('Editing.Modal.Title')}
      visible={props.visible}
      onHide={props.onHide}
      footer={{
        errors: error ? t('Editing.Modal.Error') : undefined,
        leftButton: {
          text: t('Editing.Modal.Cancel'),
          onClick: props.onHide,
          disabled: props.submitting,
        },
        rightButton: {
          text: t('Editing.Modal.Save'),
          onClick: props.onSubmit,
          disabled: props.submitting || !form.formState.isDirty,
          isLoading: props.submitting,
        },
      }}
    >
      <FieldGroup title={t('Fields.GeneralInformation.Title')}>
        <HookFormTextInput
          control={form.control}
          name={'fields.testingCenter'}
          labelProps={{ text: t('Fields.GeneralInformation.TestingCenter.Label'), isBold: true }}
          placeholder={t('Fields.GeneralInformation.TestingCenter.Placeholder')}
          rules={{ ...ValidationFormRules().requiredRule }}
        />
        <HookFormTextInput
          control={form.control}
          type={'date'}
          name={'fields.dateOfVisit'}
          labelProps={{ text: t('Fields.GeneralInformation.DateOfVisit.Label'), isBold: true }}
          rules={{ ...ValidationFormRules().requiredRule }}
        />
        <HookFormDropDown
          isMultiple
          suggestion
          control={form.control}
          name={'fields.personNames'}
          labelProps={{ text: t('Fields.GeneralInformation.PersonNames.Label'), isBold: true }}
          placeholder={t('Fields.GeneralInformation.PersonNames.Placeholder')}
          options={personNamesOptions}
          rules={{ ...ValidationFormRules().requiredRule }}
        />
        <div>
          <HookFormRadioGroup
            control={form.control}
            name={'fields.enoughTimeToHoldMeeting'}
            options={yesNoOptions}
            labelProps={{ text: t('Fields.GeneralInformation.EnoughTimeToHoldMeeting.Label'), isBold: true }}
            valueField={'value'}
            labelField={'label'}
            rules={{ ...ValidationFormRules().requiredRule }}
          />
          <CommentField form={form} name={'fields.enoughTimeToHoldMeeting_comment'} />
        </div>
        <TextInputWithSkipCheckbox
          control={form.control}
          type={'text-area'}
          name={'fields.generalComment'}
          labelProps={{ text: t('Fields.GeneralInformation.GeneralComment.Label'), isBold: true }}
          placeholder={t('Fields.GeneralInformation.GeneralComment.Placeholder')}
        />
      </FieldGroup>
      <FieldGroup title={t('Fields.StudyStaff.Title')}>
        <div>
          <RadioGroupWithSkipCheckbox
            control={form.control}
            name={'fields.delegationLogFilledInOrSupplemented'}
            options={yesNoOptions}
            labelProps={{ text: t('Fields.StudyStaff.DelegationLogFilledInOrSupplemented.Label'), isBold: true }}
          />
          <CommentField form={form} name={'fields.delegationLogFilledInOrSupplemented_comment'} />
        </div>
      </FieldGroup>
      <FieldGroup title={t('Fields.TypeOfMonitoringVisit.Label')}>
        <HookFormRadioGroup
          labelProps={{ text: '' }}
          rules={{ ...ValidationFormRules().requiredRule }}
          name={'fields.typeOfMonitoringVisit'}
          control={form.control}
          disabled={!!form.watch('monitoringFormId')}
          options={monitoringFormTypeOptions}
          valueField={'value'}
          labelField={'label'}
        />
      </FieldGroup>
      {form.watch('fields.typeOfMonitoringVisit') !== undefined && (
        <>
          <FieldGroup title={t('Fields.Infrastructure.Title')}>
            {(form.watch('fields.typeOfMonitoringVisit') === 'Routine' ||
              form.watch('fields.typeOfMonitoringVisit') === 'Final') && (
              <div>
                <RadioGroupWithSkipCheckbox
                  control={form.control}
                  name={'fields.spatialChangesAtTestCenter'}
                  options={yesNoOptions}
                  labelProps={{ text: t('Fields.SpatialChangesAtTestCenter.Label'), isBold: true }}
                />
                <CommentField form={form} name={'fields.spatialChangesAtTestCenter_comment'} />
              </div>
            )}
            <div>
              <RadioGroupWithSkipCheckbox
                control={form.control}
                name={'fields.technicalProblemsAtFacilityAffectingStudy'}
                options={yesNoOptions}
                labelProps={{
                  text: t('Fields.Infrastructure.TechnicalProblemsAtFacilityAffectingStudy.Label'),
                  isBold: true,
                }}
              />
              <CommentField form={form} name={'fields.technicalProblemsAtFacilityAffectingStudy_comment'} />
            </div>
            <div>
              <RadioGroupWithSkipCheckbox
                control={form.control}
                name={'fields.properStorageOfStudyDocuments'}
                options={yesNoOptions}
                labelProps={{ text: t('Fields.Infrastructure.ProperStorageOfStudyDocuments.Label'), isBold: true }}
              />
              <CommentField form={form} name={'fields.properStorageOfStudyDocuments_comment'} />
            </div>
          </FieldGroup>
          <FieldGroup title={t('Fields.StudyDocumentation.Title')}>
            <div>
              <RadioGroupWithSkipCheckbox
                control={form.control}
                name={'fields.givenStudyDocuments'}
                options={yesNoOptions}
                labelProps={{
                  text:
                    form.watch('fields.typeOfMonitoringVisit') === 'Initiate'
                      ? t('Fields.StudyDocumentation.GivenStudyDocuments.Label')
                      : t('Fields.StudyDocumentation.ChangingStudyDocuments.Label'),
                  isBold: true,
                }}
              />
              <CommentField form={form} name={'fields.givenStudyDocuments_comment'} />
            </div>
            {form.watch('fields.givenStudyDocuments')?.value === 'true' && (
              <>
                <HookFormDropDown
                  labelProps={{ text: t('Fields.StudyDocumentation.StudyDocuments.Label'), isBold: true }}
                  placeholder={t('Fields.StudyDocumentation.StudyDocuments.Placeholder')}
                  rules={{ ...ValidationFormRules().requiredRule }}
                  options={studyDocumentsOptions}
                  name={'fields.studyDocuments'}
                  control={form.control}
                  isMultiple
                />
                {form.watch('fields.studyDocuments')?.some((x) => x.key === 'TestPlan') && (
                  <HookFormTextInput
                    control={form.control}
                    name={'fields.testPlanVersion'}
                    labelProps={{ text: t('Fields.StudyDocumentation.TestPlanVersion.Label') }}
                    placeholder={t('Fields.StudyDocumentation.TestPlanVersion.Placeholder')}
                    rules={{ ...ValidationFormRules().requiredRule }}
                  />
                )}
                {form.watch('fields.studyDocuments')?.some((x) => x.key === 'PatientInformationAndConsent') && (
                  <HookFormTextInput
                    control={form.control}
                    name={'fields.patientInformationAndConsentVersion'}
                    labelProps={{
                      text: t('Fields.StudyDocumentation.PatientInformationAndConsentVersion.Label'),
                    }}
                    placeholder={t('Fields.StudyDocumentation.PatientInformationAndConsentVersion.Placeholder')}
                    rules={{ ...ValidationFormRules().requiredRule }}
                  />
                )}
                {form.watch('fields.studyDocuments')?.some((x) => x.key === 'InsuranceDocuments') && (
                  <HookFormTextInput
                    type={'date'}
                    control={form.control}
                    name={'fields.insuranceDocuments'}
                    labelProps={{ text: t('Fields.StudyDocumentation.InsuranceDocuments.Label') }}
                    rules={{ ...ValidationFormRules().requiredRule }}
                  />
                )}
                {form.watch('fields.studyDocuments')?.some((x) => x.key === 'CommunicationWithEc') && (
                  <HookFormTextInput
                    type={'date'}
                    control={form.control}
                    name={'fields.communicationWithEc'}
                    labelProps={{ text: t('Fields.StudyDocumentation.CommunicationWithEcVersion.Label') }}
                    rules={{ ...ValidationFormRules().requiredRule }}
                  />
                )}
                {form.watch('fields.studyDocuments')?.some((x) => x.key === 'SupportingDocuments') && (
                  <HookFormTextInput
                    type={'text-area'}
                    control={form.control}
                    name={'fields.supportingDocuments'}
                    labelProps={{ text: t('Fields.StudyDocumentation.SupportingDocuments.Label') }}
                    placeholder={t('Fields.StudyDocumentation.SupportingDocuments.Placeholder')}
                    rules={{ ...ValidationFormRules().requiredRule }}
                  />
                )}
                {form.watch('fields.studyDocuments')?.some((x) => x.key === 'RandomizationList') && (
                  <HookFormTextInput
                    type={'date'}
                    control={form.control}
                    name={'fields.randomizationList'}
                    labelProps={{ text: t('Fields.StudyDocumentation.RandomizationList.Label') }}
                    rules={{ ...ValidationFormRules().requiredRule }}
                  />
                )}
                {form.watch('fields.studyDocuments')?.some((x) => x.key === 'Other') && (
                  <HookFormTextInput
                    type={'text-area'}
                    control={form.control}
                    name={'fields.otherStudyDocuments'}
                    labelProps={{
                      text: t('Fields.StudyDocumentation.OtherStudyDocuments.Label'),
                    }}
                    placeholder={t('Fields.StudyDocumentation.OtherStudyDocuments.Placeholder')}
                    rules={{ ...ValidationFormRules().requiredRule }}
                  />
                )}
              </>
            )}
            {(form.watch('fields.typeOfMonitoringVisit') === 'Routine' ||
              form.watch('fields.typeOfMonitoringVisit') === 'Final') && (
              <>
                <div>
                  <RadioGroupWithSkipCheckbox
                    control={form.control}
                    name={'fields.personalIdentificationListIsComplete'}
                    options={yesNoOptions}
                    labelProps={{
                      text: t('Fields.PatientDocumentation.PersonalIdentificationListIsComplete.Label'),
                      isBold: true,
                    }}
                  />
                  <CommentField form={form} name={'fields.personalIdentificationListIsComplete_comment'} />
                </div>
                <div>
                  <RadioGroupWithSkipCheckbox
                    control={form.control}
                    name={'fields.patientIdentificationListIsComplete'}
                    options={yesNoOptions}
                    labelProps={{
                      text: t('Fields.PatientDocumentation.PatientIdentificationListIsComplete.Label'),
                      isBold: true,
                    }}
                  />
                  <CommentField form={form} name={'fields.patientIdentificationListIsComplete_comment'} />
                </div>
              </>
            )}
          </FieldGroup>
        </>
      )}
      {(form.watch('fields.typeOfMonitoringVisit') === 'Routine' ||
        form.watch('fields.typeOfMonitoringVisit') === 'Final') && (
        <FieldGroup title={t('Fields.DeclarationsOfConsent.Title')}>
          <DropDownWithSkipCheckbox
            control={form.control}
            name={'fields.consentFormsReviewed'}
            labelProps={{ text: t('Fields.DeclarationsOfConsent.ConsentFormsReviewed.Label'), isBold: true }}
            placeholder={t('Fields.DeclarationsOfConsent.ConsentFormsReviewed.Placeholder')}
            options={consentFormsReviewedOptions}
            isRequired
          />
          <div>
            <RadioGroupWithSkipCheckbox
              control={form.control}
              name={'fields.declarationsOfConsentIsComplete'}
              options={yesNoOptions}
              labelProps={{
                text: t('Fields.DeclarationsOfConsent.DeclarationsOfConsentIsComplete.Label'),
                isBold: true,
              }}
            />
            <CommentField form={form} name={'fields.declarationsOfConsentIsComplete_comment'} />
          </div>
        </FieldGroup>
      )}
      {form.watch('fields.typeOfMonitoringVisit') === 'Final' && (
        <>
          <FieldGroup title={t('Fields.QueryManagement.Title')}>
            <div>
              <RadioGroupWithSkipCheckbox
                control={form.control}
                name={'fields.openQueriesDiscussed'}
                options={yesNoOptions}
                labelProps={{
                  text: t('Fields.QueryManagement.OpenQueriesDiscussed.Label'),
                  isBold: true,
                }}
              />
              <CommentField form={form} name={'fields.openQueriesDiscussed_comment'} />
            </div>
          </FieldGroup>
        </>
      )}
      {/* NOTE: Patients section */}
      {(form.watch('fields.typeOfMonitoringVisit') === 'Routine' ||
        form.watch('fields.typeOfMonitoringVisit') === 'Final') && (
        <FieldGroup title={t('Fields.PatientMonitoring.Title')}>
          <HookFormTextInput
            control={form.control}
            name={'fields.patientRange'}
            labelProps={{ text: t('Fields.PatientMonitoring.PatientRange.Label'), isBold: true }}
            placeholder={t('Fields.PatientMonitoring.PatientRange.Placeholder')}
            rules={{ ...ValidationFormRules().requiredRule }}
          />
          {patients.fields.map((field, i) => (
            <RepeatableFormSection
              title={t('Fields.PatientMonitoring.RepeatableSectionTitle', { index: i + 1 })}
              withBackground={i % 2 === 0}
              key={field.id}
              onDelete={() => patients.remove(i)}
            >
              <Loading loading={isLoading}>
                <HookFormDropDown
                  control={form.control}
                  name={`fields.patients.${i}.patientUniqueId`}
                  rules={{ ...ValidationFormRules().requiredRule }}
                  labelProps={{ text: t('Fields.PatientMonitoring.PatientUniqueId.Label'), isBold: true }}
                  options={patientIdsOptions}
                  suggestion
                  isRequired
                />
              </Loading>
              <HookFormRadioGroup
                labelProps={{ text: t('Fields.PatientMonitoring.Sdv.Label'), isBold: true }}
                name={`fields.patients.${i}.sdv`}
                control={form.control}
                options={sdvOptions}
                valueField={'value'}
                labelField={'label'}
                rules={{ ...ValidationFormRules().requiredRule }}
              />
              {form.watch(`fields.patients.${i}.sdv`) === 'FixedRecord' && (
                <HookFormTextInput
                  control={form.control}
                  name={`fields.patients.${i}.sdvFixedRecord`}
                  type="text"
                  labelProps={{ text: t('Fields.PatientMonitoring.SdvFixedRecord.Label'), isBold: true }}
                  rules={{ ...ValidationFormRules().requiredRule }}
                />
              )}
              <div>
                <RadioGroupWithSkipCheckbox
                  control={form.control}
                  name={`fields.patients.${i}.consentIsCorrectlyObtained`}
                  options={yesNoOptions}
                  labelProps={{ text: t('Fields.PatientMonitoring.ConsentIsCorrectlyObtained.Label'), isBold: true }}
                />
                <CommentField form={form} name={`fields.patients.${i}.consentIsCorrectlyObtained_comment`} />
              </div>
              <div>
                <RadioGroupWithSkipCheckbox
                  control={form.control}
                  name={`fields.patients.${i}.verifiableInclusionAndExclusionCriteriaAreCorrect`}
                  options={yesNoOptions}
                  labelProps={{
                    text: t('Fields.PatientMonitoring.VerifiableInclusionAndExclusionCriteriaAreCorrect.Label'),
                    isBold: true,
                  }}
                />
                <CommentField
                  form={form}
                  name={`fields.patients.${i}.verifiableInclusionAndExclusionCriteriaAreCorrect_comment`}
                />
              </div>
              <div>
                <RadioGroupWithSkipCheckbox
                  control={form.control}
                  name={`fields.patients.${i}.accordingToStudySchedule`}
                  options={yesNoOptions}
                  labelProps={{
                    text: t('Fields.PatientMonitoring.AccordingToStudySchedule.Label'),
                    isBold: true,
                  }}
                />
                <CommentField form={form} name={`fields.patients.${i}.accordingToStudySchedule_comment`} />
              </div>

              <div>
                <RadioGroupWithSkipCheckbox
                  control={form.control}
                  name={`fields.patients.${i}.accordingToTestPlan`}
                  options={yesNoOptions}
                  labelProps={{
                    text: t('Fields.PatientMonitoring.AccordingToTestPlan.Label'),
                    isBold: true,
                  }}
                />
                <CommentField form={form} name={`fields.patients.${i}.accordingToTestPlan_comment`} />
              </div>

              <div>
                <RadioGroupWithSkipCheckbox
                  control={form.control}
                  name={`fields.patients.${i}.newInformationDiscussed`}
                  options={yesNoOptions}
                  labelProps={{
                    text: t('Fields.PatientMonitoring.NewInformationDiscussed.Label'),
                    isBold: true,
                  }}
                />
                <CommentField form={form} name={`fields.patients.${i}.newInformationDiscussed_comment`} />
              </div>
              <div>
                <RadioGroupWithSkipCheckbox
                  control={form.control}
                  name={`fields.patients.${i}.sourceDataDocumentationAvailable`}
                  options={yesNoOptions}
                  labelProps={{
                    text: t('Fields.PatientMonitoring.SourceDataDocumentationAvailable.Label'),
                    isBold: true,
                  }}
                />
                <CommentField form={form} name={`fields.patients.${i}.sourceDataDocumentationAvailable_comment`} />
              </div>
            </RepeatableFormSection>
          ))}

          <AppButton
            variant={'icon-link'}
            colorSchema={'primary'}
            text={t('Editing.Modal.AddPatient')}
            Icon={Add}
            onClick={() =>
              patients.append({
                patientUniqueId: undefined,
                sdv: undefined,
                sdvFixedRecord: undefined,
                consentIsCorrectlyObtained: undefined,
                consentIsCorrectlyObtained_comment: undefined,
                verifiableInclusionAndExclusionCriteriaAreCorrect: undefined,
                verifiableInclusionAndExclusionCriteriaAreCorrect_comment: undefined,
                accordingToStudySchedule: undefined,
                accordingToStudySchedule_comment: undefined,
                accordingToTestPlan: undefined,
                accordingToTestPlan_comment: undefined,
                newInformationDiscussed: undefined,
                newInformationDiscussed_comment: undefined,
                sourceDataDocumentationAvailable: undefined,
                sourceDataDocumentationAvailable_comment: undefined,
              })
            }
          />
        </FieldGroup>
      )}
      {form.watch('fields.typeOfMonitoringVisit') === 'Final' && (
        <>
          <FieldGroup title={t('Fields.TestProducts.Title')}>
            <div>
              <RadioGroupWithSkipCheckbox
                control={form.control}
                name={'fields.resetOfDevices'}
                options={yesNoOptions}
                labelProps={{
                  text: t('Fields.TestProducts.ResetOfDevices.Label'),
                  isBold: true,
                }}
              />
              <CommentField form={form} name={'fields.resetOfDevices_comment'} />
            </div>
            <div>
              <RadioGroupWithSkipCheckbox
                control={form.control}
                name={'fields.studyEquipmentPool'}
                options={yesNoOptions}
                labelProps={{
                  text: t('Fields.TestProducts.StudyEquipmentPool.Label'),
                  isBold: true,
                }}
              />
              <CommentField form={form} name={'fields.studyEquipmentPool_comment'} />
            </div>
          </FieldGroup>
          <FieldGroup title={t('Fields.StudyGraduation.Title')}>
            <div>
              <RadioGroupWithSkipCheckbox
                control={form.control}
                name={'fields.referenceToStudyDocuments'}
                options={yesNoOptions}
                labelProps={{
                  text: t('Fields.StudyGraduation.ReferenceToStudyDocuments.Label'),
                  isBold: true,
                }}
              />
              <CommentField form={form} name={'fields.referenceToStudyDocuments_comment'} />
            </div>
            <div>
              <RadioGroupWithSkipCheckbox
                control={form.control}
                name={'fields.referenceToAuthority'}
                options={yesNoOptions}
                labelProps={{
                  text: t('Fields.StudyGraduation.ReferenceToAuthority.Label'),
                  isBold: true,
                }}
              />
              <CommentField form={form} name={'fields.referenceToAuthority_comment'} />
            </div>
            <div>
              <RadioGroupWithSkipCheckbox
                control={form.control}
                name={'fields.clarificationOfPublication'}
                options={yesNoOptions}
                labelProps={{
                  text: t('Fields.StudyGraduation.ClarificationOfPublication.Label'),
                  isBold: true,
                }}
              />
              <CommentField form={form} name={'fields.clarificationOfPublication_comment'} />
            </div>
          </FieldGroup>
        </>
      )}
      <HookFormCheckboxField
        control={form.control}
        name={'fields.confirmation'}
        label={t('Fields.Confirmation.Label')}
      />
    </DialogModal>
  );
};
