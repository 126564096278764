/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC, useLayoutEffect, useMemo } from 'react';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import {
  getQuestionFromAnswerScheme,
  groupOptionsByInnerScheme,
  isConditionalOption,
} from 'src/helpers/question-helper';
import Style from './surveyTableRow.module.css';
import { ISurveyOption, ISurveyQuestion, QuestionType } from 'src/services/api/api-client';
import { Tooltip } from 'src/components/uikit/tooltip/tooltip.component';
import { ReactComponent as CommentIcon } from '../../assets/img/survey/comment.svg';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { AppSwiper } from 'src/components/swiper/appSwiper.component';
import { AnswersTableRowProps } from './surveyAnswers';
import { slidesPerView, slideWidthPx } from './constants';
import { SwiperSlide } from 'swiper/react';
import { ExpandedRow } from './expandedRow.component';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';

export const AnswersTableRow: FC<AnswersTableRowProps> = ({
  viewMode,
  parentQuestion,
  question,
  answers,
  swipeIndex,
  index,
}) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection');

  useLayoutEffect(() => {
    const elements = document.getElementsByClassName('question-row');
    for (let i = 0; i < elements.length - 1; i++) {
      elements[i]?.classList.add(Style.borderBottom);
    }
  }, []);

  const answerComponentList = useMemo(() => {
    return answers?.map((answerScheme, i) => {
      // In this case, the user missed the survey
      if (!answerScheme) {
        return (
          <SwiperSlide
            key={i}
            className={clsx(Style.answer, Style.empty, { [Style.singleAnswer]: viewMode === 'single' })}
          />
        );
      }

      if (!question.id) {
        throw new Error('Question ID is not exists');
      }

      const questionFromAnswerScheme = getQuestionFromAnswerScheme(answerScheme, parentQuestion, question);

      // This question was not shown to the user
      if (!questionFromAnswerScheme) {
        return (
          <SwiperSlide
            key={i}
            className={clsx(Style.answer, Style.empty, { [Style.singleAnswer]: viewMode === 'single' })}
          >
            <div className={Style.skippedQuestion}>{t('LastAvailableStats.N/a')}</div>
          </SwiperSlide>
        );
      }

      // This question was skipped by checked 'No answer' option
      const answerOption = questionFromAnswerScheme.options?.find((x) => x.answerValue !== null);
      if (!answerOption || isNullOrEmpty(answerOption.answerValue)) {
        return (
          <SwiperSlide
            key={i}
            className={clsx(Style.answer, Style.empty, { [Style.singleAnswer]: viewMode === 'single' })}
          >
            <div className={Style.skippedQuestion}>{t('LastAvailableStats.Skipped')}</div>
          </SwiperSlide>
        );
      }

      return (
        <SwiperSlide key={i} className={clsx(Style.answer, { [Style.singleAnswer]: viewMode === 'single' })}>
          {renderAnswer(question, answerOption, viewMode === 'single')}
        </SwiperSlide>
      );
    });
  }, [answers, question, parentQuestion, viewMode, t]);

  return (
    <div className={clsx(TypographyStyles.plainText14, Style.container)}>
      <div className={clsx('question-row', Style.row)}>
        <div className={Style.index}>{index}</div>
        <div className={Style.questionTextContainer}>
          <div className={Style.questionText}>{question.questionText}</div>
        </div>

        <AppSwiper
          slideWidth={slideWidthPx}
          slidesPerView={slidesPerView}
          containerStyles={Style.answersContainer}
          swipeIndex={swipeIndex}
        >
          {answerComponentList}
        </AppSwiper>
        <div className={Style.lastColumn} />
      </div>

      {Object.entries(groupOptionsByInnerScheme(question.options.filter(isConditionalOption))).map(([key, options]) => {
        return (
          <ExpandedRow
            key={key}
            question={question}
            groupedOptions={options}
            viewMode={viewMode}
            swipeIndex={swipeIndex}
            answers={answers}
          />
        );
      })}
    </div>
  );
};

const renderAnswer = (question: ISurveyQuestion, answer: ISurveyOption, isSingleAnswer?: boolean) => {
  switch (question.questionType) {
    case QuestionType.Scale:
    case QuestionType.SingleChose:
      return question.isYesNoQuestion ? (
        <>{answer?.optionText}</>
      ) : (
        <Tooltip
          text={answer?.optionText ?? ''}
          headerText={''}
          textStyles={TypographyStyles.plainText14}
          hostStyles={Style.tooltipHost}
          disabled={!answer?.optionText}
        >
          {answer?.answerValue}
        </Tooltip>
      );
    case QuestionType.ConditionalQuestion:
      return <>{answer?.optionText}</>;
    case QuestionType.FreeInput:
      if (isNullOrEmpty(answer?.answerValue)) {
        return <></>;
      }

      if (isSingleAnswer) {
        return <div className={TypographyStyles.paragraph12}>{answer?.answerValue}</div>;
      }

      return (
        <Tooltip text={answer?.answerValue ?? ''} headerText={''} textStyles={TypographyStyles.paragraph14}>
          <CommentIcon />
        </Tooltip>
      );

    default:
      return <>{answer?.optionText}</>;
  }
};
