import React from 'react';
import { useNode } from '@craftjs/core';
import MathJax from 'react-mathjax';
import { MathBlockSettings } from './mathSettings';
import { useTranslation } from 'react-i18next';

export type MathBlockProps = {
  text: string;
  fillSpace: string;
  margin: number[];
  justifyContent: string;
};

const defaultProps = {
  text: '\\frac{-b\\pm\\sqrt{b^2-4ac}}{2a}',
  fillSpace: 'no',
  margin: [0, 0, 0, 0],
  justifyContent: 'flex-start',
};

export const MathBlock = (props: Partial<MathBlockProps>) => {
  props = {
    ...defaultProps,
    ...props,
  };

  const { t } = useTranslation();
  const { connectors } = useNode((state) => ({
    selected: state.events.selected,
    childrenIds: state.data.nodes,
  }));
  const { connect, drag } = connectors;
  const { text, fillSpace, margin, justifyContent } = props;

  return (
    <div
      style={{
        display: 'flex',
        margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : '',
        justifyContent,
        width: fillSpace === 'yes' ? '100%' : 'auto',
      }}
      ref={(ref: HTMLDivElement) => connect(drag(ref))}
    >
      <MathJax.Provider>
        {text ? <MathJax.Node formula={text} /> : t('FaqEditor.Toolbox.Components.MathBlock')}
      </MathJax.Provider>
    </div>
  );
};

MathBlock.craft = {
  displayName: 'MathBlock',
  props: defaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    toolbar: MathBlockSettings,
  },
};
