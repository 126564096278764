import { useRef, useEffect } from 'react';
import { useContextSelector } from 'use-context-selector';
import { FaqEditorContext } from '../faqEditor.context';

export const useNodeRef = (nodeId: string) => {
  const nodeRef = useRef(null);
  const addToNodeRefs = useContextSelector(FaqEditorContext, (v) => v.addToNodeRefs);
  const removeFromNodeRefs = useContextSelector(FaqEditorContext, (v) => v.removeFromNodeRefs);

  useEffect(() => {
    addToNodeRefs?.(nodeId, nodeRef);

    return () => {
      removeFromNodeRefs?.(nodeId);
    };
  }, [addToNodeRefs, nodeId, removeFromNodeRefs]);

  return nodeRef;
};
