import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useEditor } from '@craftjs/core';
import { Trans, useTranslation } from 'react-i18next';
import {
  CreateFormConfigDto,
  FormDto,
  ICreateFormConfigDto,
  IFormDto,
  IPatchFormConfigDto,
  PatchFormConfigDto,
  ValidationProblemDetails,
} from '../../../services/api/api-client';
import { QueryFactory } from '../../../services/api';
import { logger } from '../../../application/logging/logging';
import { useModal } from '../../../application/hooks/useModal';
import styles from './barStyles.module.scss';
import { DialogModal } from '../../dialogModal/dialogModal.component';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppTextInput } from 'uikit/inputs';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';
import { UIEditorSaveResetControls } from './saveResetComponent/UIEditorSaveResetControls';
import { StudyNameComponent } from 'src/components/studyName/studyName.component';
import _ from 'lodash';
import { useFormBuildingPreview } from 'src/features/forms/editing/useFormBuildingPreview';
import { ConfirmResult, useConfirmationModal } from 'src/components/dialogModal/useConfirmationModal';
import { showErrorToast } from 'src/components/toast/toast-helper';
import { useForm } from 'react-hook-form';
import { HookFormTextInput } from 'src/hookFormControls/hookFormTextInput';
import { HookFormCheckboxField } from 'src/hookFormControls/HookFormCheckboxGroupField';
import { ValidationFormRules } from 'src/helpers/validation-helpers';
import { pascalToCamelCase } from 'src/helpers/error-helpers';
import { getNodesWithSameDataKeys } from '../uiEditor/uiEditor-helper';

export const TopBar = (props: {
  studyId: number;
  formConfigs: FormDto[];
  selectedConfig: FormDto | null;
  onConfigSaved?: (dto: FormDto) => void;
  onConfigDeleted?: () => void;
  onConfigSelect?: (configId: number | null) => void;
}) => {
  const dropDownOptions: DropdownOption<number>[] = useMemo(() => {
    return _.orderBy(props.formConfigs, [(x) => x.type.toLowerCase()]).map((x) => ({
      text: x.type,
      key: x.id,
    }));
  }, [props.formConfigs]);

  const selectedDropDownOption = useMemo(
    () => dropDownOptions.find((x) => x.key === props.selectedConfig?.id),
    [dropDownOptions, props.selectedConfig?.id],
  );

  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const saveAsModal = useSaveAsModal();
  const deleteModal = useDeleteConfirmationModal();
  const conflictModal = useConfirmationModal();
  const duplicatedDataKeysModal = useConfirmationModal();

  const [isLoading, setIsLoading] = useState(false);
  const { actions, query } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const handleConfigSelection = useCallback(
    (value: (typeof dropDownOptions)[0] | undefined) => {
      props.onConfigSelect?.(value?.key ?? null);
    },
    [props],
  );

  const resetUndoHistory = useCallback(() => {
    actions.history.clear();
    props.onConfigSelect?.(null);
  }, [actions.history, props]);

  const isSchemaValid = useCallback(async () => {
    const allNodes = query.getNodes();
    const nodesWithSameDataKey = getNodesWithSameDataKeys(allNodes);
    if (!Object.keys(nodesWithSameDataKey)?.length) return true;

    const result = await duplicatedDataKeysModal.open({
      title: t('StudySettingsPage.FormBuilder.topbar.DuplicatedDataKeysModal.Title'),
      text: (
        <div className={styles.duplicatedDataKeyContainer}>
          <div>{t('StudySettingsPage.FormBuilder.topbar.DuplicatedDataKeysModal.Text')}</div>
          {Object.entries(nodesWithSameDataKey).map(([dataKey, nodes]) => (
            <div key={dataKey} className={styles.dataKeyContainer}>
              <div>
                <span className={styles.label}>
                  {t('StudySettingsPage.FormBuilder.topbar.DuplicatedDataKeysModal.DataKey')}
                </span>
                {dataKey}
              </div>
              {nodes.map((node, i) => (
                <div key={node.id}>
                  <span className={styles.label}>{i + 1}</span>
                  {node.data.props.label ?? node.data.props.dataKey}
                </div>
              ))}
            </div>
          ))}
        </div>
      ),
      cancelButtonText: t('StudySettingsPage.FormBuilder.topbar.DuplicatedDataKeysModal.CancelButton'),
      okButtonText: t('StudySettingsPage.FormBuilder.topbar.DuplicatedDataKeysModal.OkButton'),
    });

    return result === ConfirmResult.Confirm;
  }, [duplicatedDataKeysModal, query, t]);

  const saveNewConfig = useCallback(async () => {
    const currentSchema = JSON.parse(query.serialize());
    await saveAsModal.open({
      formDto: props.selectedConfig,
      handler: async (data) => {
        const dto: ICreateFormConfigDto = {
          layoutSchema: currentSchema,
          studyId: props.studyId,
          type: data.type,
          isPatientCreationForm: data.isPatientCreationForm,
          isMultiInstance: data.isMultiInstance,
          canBeSkipped: data.canBeSkipped,
        };

        const formDto = await QueryFactory.FormsQuery.Client.createForm(new CreateFormConfigDto(dto));
        actions.history.clear();
        props.onConfigSaved?.(formDto);
      },
    });
  }, [actions.history, props, query, saveAsModal]);

  const patchConfig = useCallback(async () => {
    if (!props.selectedConfig) {
      await saveNewConfig();
      return;
    }

    const currentSchema = JSON.parse(query.serialize());
    const dto: IPatchFormConfigDto = {
      layoutSchema: currentSchema,
      type: props.selectedConfig.type,
    };

    try {
      setIsLoading(true);

      const formDto = await QueryFactory.FormsQuery.Client.patchForm(
        props.selectedConfig.id,
        new PatchFormConfigDto(dto),
      );

      actions.history.clear();
      props.onConfigSaved?.(formDto);
    } catch (error: any) {
      if (error.status === 409) {
        const result = await conflictModal.open({
          title: t('StudySettingsPage.FormBuilder.topbar.ConflictModal.Header'),
          text: t('StudySettingsPage.FormBuilder.topbar.ConflictModal.MainText'),
          cancelButtonText: commonLocalizer('Common_no'),
          okButtonText: commonLocalizer('Common_yes'),
        });

        if (result === ConfirmResult.Confirm) {
          await saveNewConfig();
        }
      } else {
        logger().error(error);
        showErrorToast(error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [actions.history, commonLocalizer, conflictModal, props, query, saveNewConfig, t]);

  const deleteConfig = useCallback(async () => {
    if (!props.selectedConfig) {
      return;
    }

    const result = await deleteModal.open({ expectedString: props.selectedConfig.type });
    if (result !== ConfirmResult.Confirm) return;

    setIsLoading(true);
    try {
      await QueryFactory.FormsQuery.Client.removeForm(props.selectedConfig.id);
      props.onConfigDeleted?.();
    } catch (error: any) {
      logger().error(error);
      showErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  }, [deleteModal, props]);

  const formPreview = useFormBuildingPreview();
  const onPreviewOpen = useCallback(() => {
    formPreview.startFormPreview(JSON.parse(query.serialize()), props.selectedConfig?.type || 'No title');
  }, [formPreview, props.selectedConfig?.type, query]);

  return (
    <>
      <div className={styles.topToolBox}>
        <div className={styles.newRecord}>
          <StudyNameComponent />

          <AppDropDownWithSuggestionInput
            className={styles.templateDropdown}
            placeholder={t('StudySettingsPage.FormBuilder.topbar.chooseTemplate')}
            options={dropDownOptions}
            value={selectedDropDownOption}
            onChange={handleConfigSelection}
            suggestion={true}
          />
        </div>

        <UIEditorSaveResetControls
          isShowControls={true}
          save={async () => {
            const isValid = await isSchemaValid();
            if (!isValid) return;
            await patchConfig();
          }}
          saveAs={async () => {
            const isValid = await isSchemaValid();
            if (!isValid) return;
            await saveNewConfig();
          }}
          resetToDefault={resetUndoHistory}
          disabled={isLoading}
          canDelete={!!props.selectedConfig}
          delete={deleteConfig}
          onPreviewOpen={onPreviewOpen}
        />
      </div>

      {saveAsModal.modal}
      {conflictModal.modal}
      {deleteModal.modal}
      {duplicatedDataKeysModal.modal}
      {formPreview.element}
    </>
  );
};

type OpenDeleteConfirmationModalOptions = {
  expectedString: string;
};

// TODO: Move it to the UIKit component as "Confirmation modal with expected string".
// We actually use it a lot.
const useDeleteConfirmationModal = () => {
  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const modal = useModal('CLOSED');
  const promiseRef = useRef<(test: ConfirmResult) => void>();

  const [confirmationInput, setConfirmationInput] = useState<string>();
  const [options, setOptions] = useState<Required<OpenDeleteConfirmationModalOptions>>();

  const closeHandler = useCallback(() => {
    promiseRef?.current?.(ConfirmResult.Closed);
    modal.closeModal();
  }, [modal]);

  return {
    open: (o: OpenDeleteConfirmationModalOptions): Promise<ConfirmResult> => {
      setOptions((cur) => ({ ...cur, ...o }));
      modal.openModal();

      return new Promise<ConfirmResult>((resolve) => {
        promiseRef.current = resolve;
      });
    },
    modal: (
      <DialogModal
        title={t('StudySettingsPage.FormBuilder.topbar.ConfirmModal.Title')}
        visible={modal.visible}
        onHide={closeHandler}
        isClickOutside={true}
        onDismissed={() => setConfirmationInput(undefined)}
        bodyClassName={styles.modalBody}
        footer={{
          leftButton: {
            text: commonLocalizer('Common_Cancel'),
            onClick: () => {
              promiseRef?.current?.(ConfirmResult.Cancel);
              modal.closeModal();
            },
          },
          rightButton: {
            colorSchema: 'destroy',
            text: commonLocalizer('Common_Delete'),
            disabled: confirmationInput !== options?.expectedString,
            onClick: () => {
              promiseRef?.current?.(ConfirmResult.Confirm);
              modal.closeModal();
            },
          },
        }}
      >
        <div className={styles.confirmationMessage}>
          <Trans
            ns={'dev'}
            i18nKey={'StudySettingsPage.FormBuilder.topbar.ConfirmModal.Message'}
            values={{ confirmation: options?.expectedString }}
          >
            <strong className={styles.awfulText} />
            <strong />
          </Trans>
        </div>
        <AppTextInput
          value={confirmationInput ?? ''}
          onChange={(e) => setConfirmationInput(e.target.value)}
          placeholder={t('StudySettingsPage.FormBuilder.topbar.ConfirmModal.Placeholder')}
        />
      </DialogModal>
    ),
  };
};

type FormState = {
  type: string;
  isPatientCreationForm: boolean;
  isMultiInstance: boolean;
  canBeSkipped: boolean;
};

type OpenSaveAsModalOptions = {
  formDto: IFormDto | null;
  handler: (data: FormState) => Promise<void>;
};

const useSaveAsModal = () => {
  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const modal = useModal('CLOSED');
  const promiseRef = useRef<(result: ConfirmResult) => void>();
  const handlerRef = useRef<(data: FormState) => Promise<void>>();

  const [generalError, setGeneralError] = useState<string>();

  const form = useForm<FormState>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const closeHandler = useCallback(() => {
    promiseRef?.current?.(ConfirmResult.Closed);
    modal.closeModal();
  }, [modal]);

  const submit = form.handleSubmit(async (data) => {
    try {
      await handlerRef.current?.(data);
      promiseRef?.current?.(ConfirmResult.Confirm);
      modal.closeModal();
    } catch (ex: any) {
      if (ex.errors && Object.keys(ex.errors).length > 0) {
        for (const errorKey of Object.keys(ex.errors)) {
          const formKey = pascalToCamelCase(errorKey) as keyof FormState;
          form.setError(formKey, {
            type: 'server',
            message: ex.errors[errorKey][0],
          });
        }
      } else if (ex instanceof ValidationProblemDetails) {
        setGeneralError(ex.detail ?? undefined);
        return;
      } else {
        logger().error(ex);
        const commonError = t('StudySettingsPage.FormBuilder.topbar.errors.cantSave', { detail: ex.detail });
        setGeneralError(commonError);
      }
    }
  });

  return {
    open: (o: OpenSaveAsModalOptions): Promise<ConfirmResult> => {
      form.reset({
        canBeSkipped: false,
        isMultiInstance: false,
        isPatientCreationForm: false,
        type: o.formDto?.type,
      });

      handlerRef.current = o.handler;
      modal.openModal();

      return new Promise<ConfirmResult>((resolve) => {
        promiseRef.current = resolve;
      });
    },
    modal: (
      <DialogModal
        title={t('StudySettingsPage.FormBuilder.topbar.NewFormModal.Title')}
        visible={modal.visible}
        onHide={modal.closeModal}
        bodyClassName={styles.modalBody}
        onDismissed={closeHandler}
        isClickOutside={true}
        footer={{
          errors: generalError,
          leftButton: {
            text: commonLocalizer('Common_Cancel'),
            disabled: form.formState.isSubmitting,
            onClick: () => {
              promiseRef?.current?.(ConfirmResult.Cancel);
              modal.closeModal();
            },
          },
          rightButton: {
            text: commonLocalizer('Common_Save'),
            isLoading: form.formState.isSubmitting,
            disabled: form.formState.isSubmitting,
            onClick: submit,
          },
        }}
      >
        <div className={styles.saveAsModalBody}>
          <HookFormTextInput
            control={form.control}
            name={'type'}
            labelProps={{ text: t('StudySettingsPage.FormBuilder.topbar.NewFormModal.Label') }}
            placeholder={t('StudySettingsPage.FormBuilder.topbar.NewFormModal.Placeholder')}
            rules={{ ...ValidationFormRules().requiredRule }}
          />
          <HookFormCheckboxField
            control={form.control}
            name={'isMultiInstance'}
            label={t('StudySettingsPage.FormBuilder.topbar.multiInstance')}
          />
          <HookFormCheckboxField
            control={form.control}
            name={'isPatientCreationForm'}
            label={t('StudySettingsPage.FormBuilder.topbar.patientCreation')}
          />
          <HookFormCheckboxField
            control={form.control}
            name={'canBeSkipped'}
            label={t('StudySettingsPage.FormBuilder.topbar.canBeSkipped')}
          />
        </div>
      </DialogModal>
    ),
  };
};
