import * as React from 'react';
import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import {
  AppDropdownField,
  AppDropdownFieldProps,
} from '../components/uikit/fields/dropdown/appDropdownField.component';
import { DropdownOption } from '../components/uikit/inputs/dropdown/appDropdownInput';

export type HookFormProps<D extends DropdownOption, TFieldValues extends FieldValues = FieldValues> = Omit<
  AppDropdownFieldProps<D>,
  'onChange' | 'value'
> & {
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  rules?: Exclude<RegisterOptions, 'valueAsDate' | 'setValueAs'>;
};

export function HookFormDropDown<D extends DropdownOption, TFieldValues extends FieldValues = FieldValues>(
  props: HookFormProps<D, TFieldValues>,
) {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={props.rules}
      render={({ field: { onChange, value }, fieldState }) => (
        // @ts-ignore
        <AppDropdownField<D>
          value={value}
          errorProps={{ errors: fieldState.error?.message }}
          onChange={onChange}
          {...props}
        />
      )}
    />
  );
}
