import React, { FC, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import Style from './useFormOverview.module.scss';
import { useModal } from '../../../application/hooks/useModal';
import {
  FormLifecycleState,
  IFormResultVersionDto,
  IRouteProgressDto,
  Permissions,
  RecallFormResultApprovalDto,
} from '../../../services/api/api-client';
import { AppButton, AppButtonProps } from 'uikit/buttons';
import { FormModalWithContext } from 'src/components/formEditor/modals/formModalWithContext';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { EditingResult, StartEditingArgs } from '../editing/useFormEditing';
import {
  getFormResultByIdQueryKey,
  getFormResultsQueryKey,
  useGetFormByIdQuery,
  useGetFormResultByIdQuery,
  useGetFormResultsQuery,
} from 'src/services/api/api-client/FormsQuery';
import {
  approveFormResult,
  markFormResultAsDraft,
  recallFormResultApproval,
} from 'src/services/api/api-client/FormsClient';
import { useQueryClient } from '@tanstack/react-query';
import { FormScoreComponent } from './FormScore.component';
import { OverviewHeader } from './headers/OverviewHeader';
import { useRecallApprovalReason } from './useRecallApprovalReason.component';
import { EcrfOverviewHeader } from './headers/EcrfOverviewHeader';
import {
  getPatientsQueryKey,
  getPatientStudyCountersQueryKey,
  useGetPatientQuery,
} from '../../../services/api/api-client/PatientQuery';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useFormResultSkipRevoking } from '../../../components/formEditor/modals/formResultSkipRevokingModal/useFormResultSkipRevoking';
import { Trans } from 'react-i18next';
import { ConfirmResult, useConfirmationModal } from '../../../components/dialogModal/useConfirmationModal';
import { conditionalConcat } from '../../../helpers/arrayHelpers';
import { useHasPermissions } from '../../../helpers/auth/auth-helper';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { TypographyStyles } from 'styles';
import {
  getPatientRouteProgressQueryKey,
  useGetPatientRouteProgressQuery,
} from 'src/services/api/api-client/StudyRoutesQuery';
import { useFinishingPatientFormState } from './useFinishingPatientFormState';
import { OverviewNavbar, OverviewNavbarItemProps } from 'uikit/fields/overview/OverviewNavbar';
import { DateFormats, localFormat } from 'src/helpers/date-helpers';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { showErrorToast } from 'src/components/toast/toast-helper';

export type OpenOverviewArgs = {
  patientId: string;
  formResultId: number;
  navigateToNodeId?: string;
};

export const useFormOverview = (props?: { onEdit: (args: StartEditingArgs) => Promise<EditingResult> }) => {
  const client = useQueryClient();
  const study = useStudy();

  const overviewModal = useModal();
  const recallApproval = useRecallApprovalReason();

  const [patientId, setPatientId] = useState<string>();
  const [resultId, setResultId] = useState<number>();
  const [nodeId, setNodeId] = useState<string | undefined>();

  // #region Queries

  const { data: patient, isInitialLoading: isPatientInitialLoading } = useGetPatientQuery(
    { id: patientId! },
    { enabled: !!patientId, suspense: false },
  );

  const { data: formResult, isFetching: isFormResultInitialLoading } = useGetFormResultByIdQuery(
    { resultId: resultId! },
    { enabled: !!resultId, suspense: false, keepPreviousData: true },
  );

  const { data: formConfig, isFetching: isFormConfigInitialLoading } = useGetFormByIdQuery(
    { id: formResult?.formConfigId || 0 },
    { enabled: !!formResult?.formConfigId, suspense: false, keepPreviousData: true },
  );

  const { data: routeProgress, isInitialLoading: isRouteProgressInitialLoading } = useGetPatientRouteProgressQuery(
    { patientId: patientId! },
    { enabled: !!patientId && !formConfig?.isMultiInstance, suspense: false },
  );

  const { data: multipleResults, isInitialLoading: isMultipleResultsInitialLoading } = useGetFormResultsQuery(
    { patientId: patientId!, formConfigId: formConfig?.id },
    { enabled: !!patientId && formConfig?.isMultiInstance, suspense: false },
  );

  // #endregion

  const isLoading = useMemo(
    () =>
      isPatientInitialLoading ||
      isFormResultInitialLoading ||
      isFormConfigInitialLoading ||
      isRouteProgressInitialLoading ||
      isMultipleResultsInitialLoading,
    [
      isFormConfigInitialLoading,
      isFormResultInitialLoading,
      isMultipleResultsInitialLoading,
      isPatientInitialLoading,
      isRouteProgressInitialLoading,
    ],
  );

  const currentFormResultVersion = useMemo(() => _.last(formResult?.versions), [formResult?.versions]);

  const currentStep = useMemo(
    () => routeProgress?.steps.find((x) => x.forms.some((f) => f.formResultId === formResult?.id)),
    [formResult?.id, routeProgress?.steps],
  );

  const navbarItems: OverviewNavbarItemProps[] = useMemo(() => {
    const filledSteps =
      routeProgress?.steps
        .map((x) => ({ stepName: x.name, forms: x.forms.filter((f) => f.formConfigId === formConfig?.id) }))
        .filter((x) => x.forms.some((f) => f.formResultId))
        .map<OverviewNavbarItemProps>((step, i, arr) => {
          return {
            key: step.stepName,
            selected: step.stepName === currentStep?.name,
            disabled: arr.length === 1,
            text: step.stepName,
            onClick: () => {
              const nextForm = _.first(step.forms);
              if (!nextForm) return;

              recallApproval.close();
              setResultId(nextForm.formResultId || undefined);
            },
          };
        }) || [];

    const filledMultipleForms =
      multipleResults?.data
        .map((x) => ({ createdAt: x.versions.find((v) => v.versionNumber === 1)!.createdAt, resultId: x.id }))
        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        .map((x, i, arr) => {
          return {
            key: x.resultId,
            selected: x.resultId === formResult?.id,
            disabled: arr.length === 1,
            text: localFormat(x.createdAt, DateFormats.longDate),
            onClick: () => {
              recallApproval.close();
              setResultId(x.resultId);
            },
          };
        }) || [];

    return formConfig?.isMultiInstance ? filledMultipleForms : filledSteps;
  }, [
    routeProgress?.steps,
    multipleResults?.data,
    formConfig?.isMultiInstance,
    formConfig?.id,
    currentStep?.name,
    recallApproval,
    formResult?.id,
  ]);

  //#region Editing

  const onEditResult = useCallback(
    async (options?: { asSubmitted?: boolean; recallReason?: string }) => {
      overviewModal.closeModal();

      await props?.onEdit({
        formResult: formResult!,
        form: formConfig,
        asSubmitted: options?.asSubmitted,
        recallReason: options?.recallReason,
        stepName: currentStep?.name,
      });

      overviewModal.openModal();
    },
    [overviewModal, props, formResult, formConfig, currentStep?.name],
  );

  //#endregion

  const openOverview = useCallback(
    async (args: OpenOverviewArgs) => {
      setPatientId(args.patientId);
      setResultId(args.formResultId);
      setNodeId(args.navigateToNodeId);

      await Promise.all([
        client.invalidateQueries(getFormResultsQueryKey()),
        client.invalidateQueries(getFormResultByIdQueryKey(args.formResultId)),
      ]);
      overviewModal.openModal();
    },
    [client, overviewModal],
  );

  const closeOverview = useCallback(() => {
    overviewModal.closeModal();

    setPatientId(undefined);
    setResultId(undefined);
    setNodeId(undefined);
  }, [overviewModal]);

  const recallApprovalHandler = useCallback(async () => {
    if (!formResult || !currentFormResultVersion) return;

    const result = await recallApproval.open();
    if (result.state === 'aborted') return;

    await recallFormResultApproval(
      formResult.id,
      currentFormResultVersion.versionNumber,
      new RecallFormResultApprovalDto({
        reason: result.recallReason,
      }),
    );

    await Promise.all([
      client.invalidateQueries(getFormResultsQueryKey()),
      client.invalidateQueries(getPatientRouteProgressQueryKey(patientId!)),
      client.invalidateQueries(getPatientsQueryKey()),
      client.invalidateQueries(getFormResultByIdQueryKey(formResult.id)),
    ]);
  }, [client, formResult, currentFormResultVersion, patientId, recallApproval]);

  //#region Overview header

  const overviewHeader: ReactElement = useMemo(() => {
    if (!currentFormResultVersion || !formConfig?.type || !formResult) {
      return <></>;
    }

    return study?.hasEcrf ? (
      <EcrfOverviewHeader
        currentFormResultVersion={currentFormResultVersion}
        formType={formConfig?.type}
        currentFormResult={formResult}
        onEdit={onEditResult}
      />
    ) : (
      <OverviewHeader
        currentFormResultVersion={currentFormResultVersion}
        formType={formConfig?.type}
        currentFormResult={formResult}
        onEdit={onEditResult}
      />
    );
  }, [currentFormResultVersion, formConfig?.type, formResult, study?.hasEcrf, onEditResult]);

  //#endregion

  const header = useMemo(() => {
    return (
      <IssueMark
        key={formConfig?.type}
        issueContext={{
          subject: 'Patient',
          topic: 'Records',
          linkedPatientUniqId: patient?.patientId,
          topicAdditional: formConfig?.type,
        }}
        position={{ top: 2, right: 8 }}
        countDescendants={false}
        containerClassName={Style.issueMarkContainer}
      >
        <div className={Style.issueMarkTarget} />
      </IssueMark>
    );
  }, [formConfig?.type, patient?.patientId]);

  return {
    isLoading: isLoading,
    open: openOverview,
    close: closeOverview,
    element: (
      <>
        {!!patientId && formResult && formConfig && (
          <FormModalWithContext
            mode={'Overview'}
            formConfig={formConfig}
            prevAnswersValue={undefined}
            formResultVersion={currentFormResultVersion}
            navigationMenu={
              <div className={Style.navContainer}>
                <IssueMark
                  issueContext={{
                    subject: 'Patient',
                    topic: 'Records',
                    topicAdditional: formConfig?.type,
                    linkedPatientUniqId: patient?.patientId,
                    resultId: formResult?.id || undefined,
                    stepName: formConfig?.isMultiInstance ? 'multiple' : currentStep?.name,
                  }}
                >
                  <OverviewNavbar items={navbarItems} />
                </IssueMark>
                {overviewHeader}
              </div>
            }
            isLoading={isLoading}
            footer={
              study?.hasEcrf && (!patient?.archivedBy || !patient?.archivedAt)
                ? recallApproval.component || (
                    <OverviewFooter
                      stepName={currentStep?.name}
                      patientId={patientId}
                      patientUniqueId={patient?.patientId}
                      formType={formConfig.type}
                      formConfigId={formConfig.id}
                      onRecallApproval={recallApprovalHandler}
                      routeProgress={routeProgress!}
                      formResultVersion={currentFormResultVersion!}
                      onEdit={onEditResult}
                    />
                  )
                : undefined
            }
            formScoreComponent={<FormScoreComponent formResultVersion={currentFormResultVersion} />}
            modal={{
              title: formConfig?.type,
              testId: `${formConfig?.type}-overview-form`,
              visible: overviewModal.visible,
              onHide: () => {
                overviewModal.closeModal();
                recallApproval.close();
              },
              header: header,
              headerClassName: Style.modalHeaderMark,
            }}
            patientId={patientId!}
            navigateToNodeId={nodeId}
            stepName={currentStep?.name}
          />
        )}
      </>
    ),
  };
};

const OverviewFooter: FC<{
  formType: string;
  formConfigId: number;
  routeProgress: IRouteProgressDto;
  stepName: string | undefined;
  patientId: string;
  patientUniqueId: string | undefined;
  formResultVersion: IFormResultVersionDto;
  onEdit: (options?: { asSubmitted?: boolean; recallReason?: string }) => void;
  onRecallApproval: () => void;
}> = ({
  formResultVersion,
  formConfigId,
  onEdit,
  formType,
  routeProgress,
  stepName,
  patientId,
  patientUniqueId,
  onRecallApproval,
}) => {
  const { t } = useScopedTranslation('Forms.Overview');
  const study = useStudy();
  const queryClient = useQueryClient();

  const recallSubmitHandler = useCallback(async () => {
    await markFormResultAsDraft(formResultVersion.formResultId, formResultVersion.versionNumber);
    await Promise.all([
      queryClient.invalidateQueries(getFormResultsQueryKey()),
      queryClient.invalidateQueries(getPatientsQueryKey()),
      queryClient.invalidateQueries(getPatientRouteProgressQueryKey(patientId!)),
      queryClient.invalidateQueries(getFormResultByIdQueryKey(formResultVersion.formResultId)),
    ]);
  }, [formResultVersion.formResultId, formResultVersion.versionNumber, patientId, queryClient]);

  const formResultSkipRevoking = useFormResultSkipRevoking();
  const revokeSkipHandler = useCallback(() => {
    formResultSkipRevoking.start({
      stepName: stepName,
      routeProgress: routeProgress,
      patientId: patientId,
      formConfigId: formConfigId,
      formResultId: formResultVersion.formResultId,
    });
  }, [formConfigId, formResultSkipRevoking, formResultVersion.formResultId, patientId, routeProgress, stepName]);

  const finishingPatientForm = useFinishingPatientFormState(patientId);

  const approveConfirmation = useConfirmationModal();
  const approveHandler = useCallback(async () => {
    const isFinishingPatientForm = finishingPatientForm.type === formType;
    const formContent = isFinishingPatientForm
      ? {
          text: (
            <span>
              <Trans
                i18nKey={'Forms.Overview.ApproveReleasePatientConfirmationModal.Text'}
                values={{ patientUniqueId, formType }}
              >
                <span className={Style.mediumText} />
                <span className={Style.awfulText} />
              </Trans>
            </span>
          ),
          title: t('ApproveReleasePatientConfirmationModal.Title'),
          okButtonText: t('ApproveReleasePatientConfirmationModal.OkButtonText'),
        }
      : {
          text: (
            <span>
              <Trans i18nKey={'Forms.Overview.ApproveConfirmationModal.Text'} values={{ patientUniqueId, formType }}>
                <span className={Style.mediumText} />
                <span className={Style.awfulText} />
              </Trans>
            </span>
          ),
          title: t('ApproveConfirmationModal.Title'),
          okButtonText: t('ApproveConfirmationModal.OkButtonText'),
        };

    const approveResult = await approveConfirmation.open(formContent);
    if (approveResult !== ConfirmResult.Confirm) return;

    try {
      await approveFormResult(formResultVersion.formResultId, formResultVersion.versionNumber);
      await Promise.all([
        queryClient.invalidateQueries(getFormResultsQueryKey()),
        queryClient.invalidateQueries(getPatientRouteProgressQueryKey(patientId)),
        queryClient.invalidateQueries(getPatientStudyCountersQueryKey(study!.id)),
        queryClient.invalidateQueries(getPatientsQueryKey()),
        queryClient.invalidateQueries(getPatientRouteProgressQueryKey(patientId!)),
        queryClient.invalidateQueries(getFormResultByIdQueryKey(formResultVersion.formResultId)),
      ]);
    } catch (error: any) {
      showErrorToast(error);
    }
  }, [
    finishingPatientForm.type,
    formType,
    patientUniqueId,
    t,
    approveConfirmation,
    formResultVersion.formResultId,
    formResultVersion.versionNumber,
    queryClient,
    patientId,
    study,
  ]);

  const { hasPermission } = useHasPermissions();
  const isFinalForm = formType === finishingPatientForm?.type;

  const canChangeFormState: { value: boolean; tooltipComponent: ReactNode } = useMemo(() => {
    if (isFinalForm) {
      return {
        value: finishingPatientForm.canBeFilled && !finishingPatientForm.isLoading,
        tooltipComponent: (
          <div className={TypographyStyles.plainText12}>
            <span>{t('Footer.ToEnableApproveNextForms')}</span>
            {finishingPatientForm.notApprovedFormList.map((x, i) => (
              <div key={i} className={Style.tooltipContent}>
                <span>{x.formType}</span>
                <span>{x.stepName ?? x.createdAt}</span>
              </div>
            ))}
          </div>
        ),
      };
    }

    return {
      value:
        (finishingPatientForm?.state === undefined ||
          finishingPatientForm?.state === FormLifecycleState.Draft ||
          finishingPatientForm?.state === FormLifecycleState.Recalled) &&
        !finishingPatientForm.isLoading,
      tooltipComponent: t('Footer.ToEnableRevokeForm', { formType: finishingPatientForm?.type }),
    };
  }, [isFinalForm, finishingPatientForm, t]);

  const buttonVms = conditionalConcat<AppButtonProps & { key: string; tooltip?: ReactNode }>(
    hasPermission(isFinalForm ? Permissions.PatientRelease : Permissions.PatientCreate) &&
      formResultVersion.lifecycleState === FormLifecycleState.Draft && [
        {
          key: 'submit',
          testId: 'submit-button',
          variant: 'button',
          colorSchema: 'primary',
          disabled: !canChangeFormState.value,
          text: t('Footer.Submit'),
          tooltip: canChangeFormState.tooltipComponent,
          onClick: () =>
            onEdit({
              asSubmitted: true,
              recallReason: formResultVersion.approvalRecallReason ?? undefined,
            }),
        },
      ],
    hasPermission(isFinalForm ? Permissions.PatientRelease : Permissions.PatientCreate) &&
      formResultVersion.lifecycleState === FormLifecycleState.Submitted &&
      !formResultVersion.isSkipped && [
        {
          key: 'RevokeSubmitting',
          testId: 'revoke-submitting-button',
          variant: 'button',
          colorSchema: 'destroy',
          disabled: !canChangeFormState.value,
          text: t('Footer.RevokeSubmitting'),
          tooltip: canChangeFormState.tooltipComponent,
          onClick: recallSubmitHandler,
        },
      ],
    hasPermission(isFinalForm ? Permissions.PatientRelease : Permissions.PatientCreate) &&
      formResultVersion.lifecycleState === FormLifecycleState.Submitted &&
      formResultVersion.isSkipped && [
        {
          key: 'RevokeSkipping',
          testId: 'revoke-skipping-button',
          variant: 'button',
          colorSchema: 'destroy',
          disabled: !canChangeFormState.value,
          text: t('Footer.RevokeSkipping'),
          tooltip: canChangeFormState.tooltipComponent,
          onClick: revokeSkipHandler,
        },
      ],
    hasPermission(isFinalForm ? Permissions.PatientRelease : Permissions.PatientCreate) &&
      formResultVersion.lifecycleState === FormLifecycleState.Recalled && [
        {
          key: 'CreateNewVersion',
          testId: 'create-new-version-button',
          variant: 'button',
          colorSchema: 'primary',
          disabled: !canChangeFormState.value,
          text: t('Footer.CreateNewVersion'),
          tooltip: canChangeFormState.tooltipComponent,
          onClick: () =>
            onEdit({
              asSubmitted: false,
              recallReason: formResultVersion.approvalRecallReason ?? undefined,
            }),
        },
      ],
    hasPermission(isFinalForm ? Permissions.ApproveClosureForm : Permissions.ApproveForm) &&
      formResultVersion.lifecycleState === FormLifecycleState.Submitted && [
        {
          key: 'Approve',
          testId: 'approve-button',
          variant: 'button',
          colorSchema: 'primary',
          disabled: !canChangeFormState.value,
          text: t('Footer.Approve'),
          tooltip: canChangeFormState.tooltipComponent,
          onClick: approveHandler,
        },
      ],
    hasPermission(isFinalForm ? Permissions.ApproveClosureForm : Permissions.ApproveForm) &&
      formResultVersion.lifecycleState === FormLifecycleState.Approved && [
        {
          key: 'RevokeApproval',
          testId: 'revoke-approval-button',
          variant: 'button',
          colorSchema: 'destroy',
          disabled: !canChangeFormState.value,
          text: t('Footer.RevokeApproval'),
          tooltip: canChangeFormState.tooltipComponent,
          onClick: onRecallApproval,
        },
      ],
  );

  if (buttonVms.length === 0) return null;

  return (
    <div className={Style.footer}>
      <div className={Style.buttonsContainer}>
        {buttonVms.map((x) => (
          // eslint-disable-next-line react/jsx-key
          <Tooltip testIdPrefix={'create-new-version'} text={''} disabled={!x.disabled} tooltipContent={x.tooltip}>
            <AppButton {...x} />
          </Tooltip>
        ))}
      </div>
      {formResultSkipRevoking.element}
      {approveConfirmation.modal}
    </div>
  );
};
