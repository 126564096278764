import React from 'react';
import { InformationPageProps } from 'src/components/informationPageContainer/InformationPage';
import style from './InformationPage.module.scss';
import clsx from 'clsx';
import { CommonStyles, TypographyStyles } from 'src/styles';
import { AppButton } from 'uikit/buttons';

export const InformationPageContainer = (props: InformationPageProps) => {
  const { header, text, img, buttonText, buttonAction } = props;

  return (
    <div className={clsx(CommonStyles.simplePageContainer, style.container)}>
      {img && <img src={img} alt={'Information page image'} className={style.img} />}
      <div className={style.texContent}>
        <div className={TypographyStyles.informationPageHeader}>{header}</div>
        <div className={TypographyStyles.paragraph14}>{text}</div>
        {buttonText && <AppButton colorSchema={'primary'} text={buttonText} onClick={buttonAction} />}
      </div>
    </div>
  );
};
