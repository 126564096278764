﻿import React, { FC, useCallback, useId, useMemo, useRef } from 'react';
import {
  FormSkipReason,
  IFormResultDto,
  MarkFormResultAsSkippedDto,
  SkipFormResultDto,
} from '../../../../services/api/api-client';
import { useQueryClient } from '@tanstack/react-query';
import { useModal } from '../../../../application/hooks/useModal';
import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import { markFormResultAsSkipped, skipFormResult } from '../../../../services/api/api-client/FormsClient';
import { getFormResultByIdQueryKey, getFormResultsQueryKey } from '../../../../services/api/api-client/FormsQuery';
import { getPatientRouteProgressQueryKey } from '../../../../services/api/api-client/StudyRoutesQuery';
import { getPatientQueryKey, getPatientsQueryKey } from '../../../../services/api/api-client/PatientQuery';
import { Deferred } from '../../../../helpers/Deferred';
import { handleSubmitFormError } from '../../../../application/error-handling/useErrorHandler';
import { useTranslation } from 'react-i18next';
import { ValidationFormRules } from '../../../../helpers/validation-helpers';
import { AppRadioGroupField, AppTextField } from 'uikit/fields';
import styles from './useFormResultSkipping.module.scss';
import clsx from 'clsx';
import { TypographyStyles } from 'styles';
import {
  getNotificationBatchesQueryKey,
  getNotificationCountsQueryKey,
  getNotificationsQueryKey,
} from '../../../../services/api/api-client/NotificationQuery';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

type FormState = {
  skipReason: FormSkipReason | null;
  skipReasonDetails: string;
  formType: string;
  stepName: string | null;
  formResultId: number | null | undefined;
  patientId: string;
  formConfigId: number;
};

type FormResultSkippingResult = { $type: 'Saved'; formResult: IFormResultDto } | { $type: 'Canceled' };

export const useFormResultSkipping = () => {
  const study = useStudy();
  const queryClient = useQueryClient();
  const modal = useModal('CLOSED');
  const deferred = useRef<Deferred<FormResultSkippingResult>>();

  const form = useForm<FormState>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const submit = form.handleSubmit(async (values) => {
    let formResult: IFormResultDto;

    try {
      if (!values.formResultId) {
        const dto = new SkipFormResultDto({
          patientId: values.patientId,
          skipReason: values.skipReason!,
          skipReasonDetails: values.skipReasonDetails,
          formConfigId: values.formConfigId,
          stepName: values.stepName!,
        });

        formResult = await skipFormResult(dto);
      } else {
        const dto = new MarkFormResultAsSkippedDto({
          skipReason: values.skipReason!,
          skipReasonDetails: values.skipReasonDetails,
        });

        formResult = await markFormResultAsSkipped(values.formResultId, dto);
      }
    } catch (e) {
      handleSubmitFormError(e, form.setError);
      return;
    }

    await Promise.all([
      queryClient.invalidateQueries(getFormResultsQueryKey(formResult.formConfigId)),
      queryClient.invalidateQueries(getFormResultByIdQueryKey(formResult.id)),
      queryClient.invalidateQueries(getPatientRouteProgressQueryKey(formResult.patientId)),
      queryClient.invalidateQueries(getPatientQueryKey(formResult.patientId)),
      queryClient.invalidateQueries(getPatientsQueryKey()),
      queryClient.invalidateQueries(getNotificationCountsQueryKey(study!.id)),
      queryClient.invalidateQueries(getNotificationBatchesQueryKey(undefined, [study!.id])),
      queryClient.invalidateQueries(getNotificationsQueryKey(undefined, study!.id)),
    ]);

    modal.closeModal();
    deferred.current?.resolve({ $type: 'Saved', formResult: formResult });
  });

  const cancel = useCallback(() => {
    modal.closeModal();
    deferred.current?.resolve({ $type: 'Canceled' });
  }, [modal]);

  return {
    start: (args: {
      formType: string;
      stepName: string | null;
      formResultId: number | null | undefined;
      patientId: string;
      formConfigId: number;
    }) => {
      modal.openModal();
      form.reset({
        formType: args.formType,
        patientId: args.patientId,
        stepName: args.stepName,
        formResultId: args.formResultId,
        formConfigId: args.formConfigId,
        skipReason: null,
        skipReasonDetails: '',
      } satisfies FormState);
      deferred.current = new Deferred();
      return deferred.current.promise;
    },
    element: (
      <FormProvider {...form}>
        <SkipFormResultModal visible={modal.visible} onHide={cancel} onSave={submit} />
      </FormProvider>
    ),
  };
};

const SkipFormResultModal: FC<{ visible: boolean; onHide: () => void; onSave: () => void }> = (props) => {
  const { t } = useTranslation();
  const formId = useId();
  const form = useFormContext<FormState>();
  const error = form.formState.errors.root;

  return (
    <DialogModal
      testId={'skip-form-modal'}
      title={form.watch('formType')}
      subTitle={form.watch('stepName')}
      containerClassName={styles.modalContainer}
      bodyClassName={styles.modalBody}
      visible={props.visible}
      onHide={props.onHide}
      footer={{
        errors: error ? t('Forms.FormResultSkipping.Modal.Error') : undefined,
        leftButton: {
          text: t('Forms.FormResultSkipping.Modal.Cancel'),
          onClick: props.onHide,
          disabled: form.formState.isSubmitting,
        },
        rightButton: {
          text: t('Forms.FormResultSkipping.Modal.Save'),
          onClick: props.onSave,
          isLoading: form.formState.isSubmitting,
          disabled: form.formState.isSubmitting,
        },
      }}
    >
      <form id={`formResultSkipping-${formId}`}>
        <Description />
        <SkipReasonField />
        <DetailsField />
      </form>
    </DialogModal>
  );
};

const Description: FC = () => {
  const { t } = useTranslation();

  return (
    <p className={clsx(styles.description, TypographyStyles.paragraph14)}>
      {t('Forms.FormResultSkipping.Modal.Description')}
    </p>
  );
};

const SkipReasonField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'skipReason',
    rules: {
      ...ValidationFormRules().requiredRule,
    },
  });

  const options = useMemo(
    () => [
      { value: FormSkipReason.Missed, text: t('Forms.FormSkipReason.Missed') },
      {
        value: FormSkipReason.NotApplicable,
        text: t('Forms.FormSkipReason.NotApplicable'),
      },
    ],
    [t],
  );

  return (
    <AppRadioGroupField
      labelProps={{ text: t('Forms.FormResultSkipping.Modal.Fields.Reason.Label'), isBold: true }}
      options={options}
      value={field.value ?? undefined}
      valueField={'value'}
      labelField={'text'}
      onChange={(x) => field.onChange(x?.value)}
      errorProps={{
        errors: fieldState.error?.message,
      }}
    />
  );
};

const DetailsField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'skipReasonDetails',
    rules: {
      ...ValidationFormRules().requiredRule,
    },
  });

  return (
    <AppTextField
      type="text-area"
      labelProps={{ text: t('Forms.FormResultSkipping.Modal.Fields.Details.Label') }}
      placeholder={t('Forms.FormResultSkipping.Modal.Fields.Details.Placeholder')}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      errorProps={{ errors: fieldState.error?.message }}
    />
  );
};
