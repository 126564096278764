import { ICheckBoxInput } from '../../../../../features/forms/base/controls/inputs/CheckBoxInput';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { NoteContext } from '../../NoteContext';
import { LocalizedResourceDictionaryKeys } from '../../../../../application/localisation/i18next';
import { NoteField } from '../../NoteField';

export const CheckBoxInput: ICheckBoxInput = (props) => {
  const { t } = useTranslation();
  const noteContext = useContext(NoteContext);
  const inputValue = noteContext.currentResultVersion.value?.[props.dataKey] as string | string[] | undefined | null;
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  if (!singleInputValue) return <></>;

  return (
    <NoteField
      label={props.label ?? props.dataKey}
      value={t(`Forms.Controls.checkBox_${singleInputValue}` as LocalizedResourceDictionaryKeys)}
    />
  );
};
