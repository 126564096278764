﻿import { useModal } from '../../../../application/hooks/useModal';
import React, { FC, memo, useCallback, useRef, useState } from 'react';
import { getFormResultByIdQueryKey, getFormResultsQueryKey } from '../../../../services/api/api-client/FormsQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { revokeFormResultSkip } from '../../../../services/api/api-client/FormsClient';
import { getPatientsQueryKey } from '../../../../services/api/api-client/PatientQuery';
import { IRouteProgressDto } from '../../../../services/api/api-client';
import { Deferred } from '../../../../helpers/Deferred';
import styles from './useFormResultSkipRevoking.module.scss';
import { TypographyStyles } from 'styles';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import { getPatientRouteProgressQueryKey } from 'src/services/api/api-client/StudyRoutesQuery';
import getAnswersFromPrevFormResult from 'src/features/forms/editing/getInitialAnswers';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

type Args = {
  stepName: string | undefined;
  formConfigId: number;
  routeProgress: IRouteProgressDto;
  patientId: string;
  formResultId: number;
};

export const useFormResultSkipRevoking = () => {
  const queryClient = useQueryClient();
  const modal = useModal('CLOSED');
  const deferred = useRef<Deferred>();
  const [args, setArgs] = useState<Args>();

  const mutation = useMutation(async () => {
    const { formResultId, formConfigId, patientId, routeProgress, stepName } = args!;
    const initialValue =
      (stepName && (await getAnswersFromPrevFormResult(stepName, formConfigId, routeProgress, patientId))) || {};

    await revokeFormResultSkip(formResultId, initialValue);
    await Promise.all([
      queryClient.invalidateQueries(getFormResultsQueryKey()),
      queryClient.invalidateQueries(getPatientsQueryKey()),
      queryClient.invalidateQueries(getFormResultByIdQueryKey(formResultId)),
      queryClient.invalidateQueries(getPatientRouteProgressQueryKey(patientId)),
    ]);

    modal.closeModal();
    deferred.current?.resolve();
    deferred.current = undefined;
  });

  const onCancel = useCallback(() => {
    modal.closeModal();
    deferred.current = undefined;
  }, [modal]);

  return {
    start: useCallback(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      (args: Args) => {
        modal.openModal();
        setArgs(args);
        deferred.current = new Deferred();
        return deferred.current.promise;
      },
      [modal],
    ),
    element: (
      <FormResultSkipRevokingModal
        visible={modal.visible}
        onCancel={onCancel}
        onSubmit={mutation.mutate}
        isLoading={mutation.isLoading}
        isError={mutation.isError}
      />
    ),
  };
};

let FormResultSkipRevokingModal: FC<{
  visible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  isLoading: boolean;
  isError: boolean;
}> = (props) => {
  const { t } = useScopedTranslation('Forms.FormResultSkipRevoking.Modal');

  return (
    <DialogModal
      title={t('Header')}
      bodyClassName={styles.modalBody}
      visible={props.visible}
      onHide={props.onCancel}
      footer={{
        errors: props.isError ? t('Error') : undefined,
        leftButton: {
          text: t('Cancel'),
          onClick: props.onCancel,
          disabled: props.isLoading,
        },
        rightButton: {
          text: t('Revoke'),
          colorSchema: 'destroy',
          onClick: props.onSubmit,
          isLoading: props.isLoading,
        },
      }}
    >
      <p className={TypographyStyles.paragraph14}>{t('Warning')}</p>
    </DialogModal>
  );
};
FormResultSkipRevokingModal = memo(FormResultSkipRevokingModal);
