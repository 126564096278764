﻿import { buttonColumn, ButtonColumnOptions } from 'uikit/table/columns/ButtonColumn';
import { ReactComponent as Chevron } from 'src/assets/img/patient/chevronPatientRow.svg';
import styles from './ExpandRowButtonColumn.module.scss';

export type ExpandRowButtonColumnOptions<TRow> = Omit<ButtonColumnOptions<TRow>, 'onClick' | 'icon' | 'id'> & {
  /** @default expandButton */
  id?: string;
};

export function expandRowButtonColumn<TRow>(options: ExpandRowButtonColumnOptions<TRow>) {
  return buttonColumn<TRow>({
    id: 'expandButton',
    colorSchema: 'secondary',
    ...options,
    icon: Chevron,
    onClick: ({ row }) => row.toggleExpanded(),
    className: ({ row }) => (row.getIsExpanded() ? styles.expanded : undefined),
  });
}
