import i18next from 'i18next';
import MDBReader, { Value } from 'mdb-reader';
import { IAnalysisPresetScheme } from 'src/services/api/api-client';

export const getDataFromDatabase = (
  database: MDBReader,
  schemeList: IAnalysisPresetScheme[],
): { [x: string]: Value } | undefined => {
  const data = schemeList
    .flatMap((scheme) => {
      if (!scheme.keyColumnsWithValue || !scheme.dataColumns || scheme.dataColumns?.length === 0) {
        return undefined;
      }

      const keyColumns = Object.keys(scheme.keyColumnsWithValue);
      const dataColumns = Array.from(new Set([...keyColumns, ...scheme.dataColumns]));
      return (
        database
          .getTable(scheme.tableName)
          .getData({ columns: dataColumns })
          .filter((row) => keyColumns.every((key) => row[key] === scheme.keyColumnsWithValue![key]))
          // Sorting the retrieved data by dataColumns from the schema
          .map((x) => {
            return Object.keys(x)
              .sort(function (a, b) {
                return dataColumns.indexOf(a) - dataColumns.indexOf(b);
              })
              .reduce((obj, key) => {
                obj[key] = x[key];
                return obj;
              }, {});
          })
      );
    })
    .filter(Boolean);

  if (!data || data.length === 0) throw Error(i18next.t('ExternalAnalysis.Presets.Errors.HasNoData'));
  if (data && data.length > 1) throw Error(i18next.t('ExternalAnalysis.Presets.Errors.MultipleRowData'));
  return data[0];
};

export const getColumnValues = (database: MDBReader, tableName: string, keyColumnName: string): string[] => {
  return database
    ?.getTable(tableName)
    .getData({ columns: [keyColumnName] })
    .flatMap(Object.values) as string[];
};
