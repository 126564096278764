import React, { useContext } from 'react';
import { INumberInput } from 'src/features/forms/base/controls/inputs/INumberInput';
import { NoteField } from '../../NoteField';
import { NoteContext } from '../../NoteContext';

export const NumberInput: INumberInput = (props) => {
  const noteContext = useContext(NoteContext);
  const inputValue = noteContext.currentResultVersion.value?.[props.dataKey] as string | string[] | undefined | null;
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  if (!singleInputValue) return <></>;

  return (
    <NoteField
      label={props.label ?? props.dataKey}
      value={`${props.decimal !== undefined ? Number(singleInputValue).toFixed(props.decimal) : singleInputValue} ${
        props.units ?? ''
      }`}
    />
  );
};
