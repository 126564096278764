import React, { FC, useRef, useState } from 'react';
import clsx from 'clsx';
import Style from './buttonWithMenu.module.scss';
import { DropDownMenuProps } from 'uikit/dropDownMenu/dropDownMenu';
import { DropDownMenu } from 'uikit/dropDownMenu/dropDownMenu.component';
import { AppButton } from '../button/button.component';
import { ReactComponent as Chevron } from 'assets/img/common/arrow_24.svg';

export type AppButtonWithMenuProps = Pick<
  DropDownMenuProps,
  'options' | 'disabled' | 'isLoading' | 'hasLoaded' | 'loadingText' | 'onClick'
> & {
  buttonClassName?: string;
  className?: string;
  buttonText: string;
  testIdPrefix?: string;
  mainButtonDisabled?: boolean;
};

export const AppButtonWithMenu: FC<AppButtonWithMenuProps> = ({
  options,
  buttonClassName,
  className,
  buttonText,
  disabled,
  isLoading,
  hasLoaded,
  onClick,
  testIdPrefix,
  mainButtonDisabled,
}) => {
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className={clsx(Style.container, className)}>
      <AppButton
        testId={`${testIdPrefix}-button`}
        disabled={disabled || mainButtonDisabled}
        isLoading={isLoading}
        hasLoaded={hasLoaded}
        colorSchema={'primary'}
        variant={'button'}
        text={buttonText}
        className={clsx({ [Style.button]: !!options?.length }, buttonClassName)}
        onClick={onClick}
      />
      {!!options?.length && (
        <DropDownMenu
          testId={`${testIdPrefix}-menu`}
          disabled={disabled}
          colorSchema={'primary'}
          variant={'button'}
          options={options}
          text={<Chevron className={clsx(Style.chevron, { [Style.rotated]: expanded })} />}
          className={Style.menuButton}
          onExpendChange={setExpanded}
          calloutStyles={{ minWidth: containerRef.current?.clientWidth }}
        />
      )}
    </div>
  );
};
