import React from 'react';
import { AppInputError, AppInputErrorProps, AppInputSkip, ErrorSign, FieldSkipProps } from 'uikit/wrappers';
import { AppInputLabel, AppInputLabelProps } from 'uikit/wrappers';
import { DropdownOption } from '../../inputs/dropdown/appDropdownInput';
import {
  AppDropDownWithSuggestionInput,
  AppDropDownWithSuggestionProps,
} from 'uikit/inputs/dropdown/appDropDownWithSuggestion';

export type AppDropdownFieldProps<D extends DropdownOption> = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipProps?: FieldSkipProps;
} & AppDropDownWithSuggestionProps<D>;

export function AppDropdownField<D extends DropdownOption>({
  errorProps,
  labelProps,
  skipProps,
  disabled,
  ...rest
}: AppDropdownFieldProps<D>) {
  return (
    <AppInputError {...errorProps}>
      <AppInputLabel disabled={disabled} rightSection={errorProps?.errors ? <ErrorSign /> : <></>} {...labelProps}>
        <AppInputSkip
          inputCanBeSkipped={!!skipProps && skipProps?.inputCanBeSkipped}
          skipText={skipProps?.skipText}
          showReasons={skipProps?.showReasons}
          disabled={disabled}
          skipReason={skipProps?.skipReason}
          onSkipReasonChange={skipProps?.onSkipReasonChange ?? (() => {})}
          position="top-8px"
        >
          <AppDropDownWithSuggestionInput disabled={disabled} invalid={!!errorProps?.errors} {...rest} />
        </AppInputSkip>
      </AppInputLabel>
    </AppInputError>
  );
}
