import React, { InputHTMLAttributes, useMemo, useState } from 'react';
import clsx from 'clsx';
import Style from './appTextInput.module.scss';
import { AppButton } from 'uikit/buttons';

import { ReactComponent as Eye } from './../../../../assets/img/common/eye_16.svg';
import { ReactComponent as EyeCrossed } from './../../../../assets/img/common/eye_crossed_16.svg';

export type AppTextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  type?: 'text' | 'text-area' | 'password' | 'search' | 'number' | 'color' | 'date' | 'time';
  invalid?: boolean;
  inputClassName?: string;
};

export const AppTextInput = React.forwardRef<HTMLInputElement, AppTextInputProps>(
  ({ type, className, invalid, inputClassName, ...rest }, ref) => {
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);

    const fieldType = useMemo(() => {
      if (type === 'search') {
        return 'text';
      }

      return passwordIsVisible ? 'text' : type;
    }, [passwordIsVisible, type]);

    return (
      <div className={clsx(Style.container, className)}>
        {type === 'text-area' ? (
          <textarea
            // @ts-ignore
            ref={ref}
            className={clsx(Style.base, Style.textArea, inputClassName)}
            data-invalid={invalid}
            {...rest}
          />
        ) : (
          <input
            ref={ref}
            type={fieldType || 'text'}
            onFocus={(e) => e.target.addEventListener('wheel', (x) => x.preventDefault(), { passive: false })}
            className={clsx(
              Style.base,
              {
                [Style.search]: type === 'search',
              },
              inputClassName,
            )}
            data-invalid={invalid}
            {...rest}
          />
        )}

        {type === 'password' && (
          <AppButton
            className={Style.showPasswordButton}
            variant={'icon-link'}
            colorSchema={'secondary'}
            Icon={passwordIsVisible ? EyeCrossed : Eye}
            onClick={() => {
              setPasswordIsVisible((state) => !state);
            }}
            disabled={rest.disabled}
          />
        )}
      </div>
    );
  },
);
