import * as AppEvents from './app-events';
import { ActionType, getType } from 'typesafe-actions';
import { ApplicationState, defaultAppState } from './app-state';
import _ from 'lodash';

export type AppActions = ActionType<typeof AppEvents>;
export { AppEvents };

export function appReducer(state: ApplicationState = defaultAppState, action: AppActions): ApplicationState {
  switch (action.type) {
    case getType(AppEvents.selectStudy): {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
