import React, { PropsWithChildren } from 'react';
import { RPDF } from '../../react-pdf';

export const TableRow: React.FC<PropsWithChildren<any>> = (props: PropsWithChildren<any>) => {
  return (
    <RPDF.View minPresenceAhead={16} style={[RPDF.tableStyles.rowContainer, RPDF.tableStyles.divider]}>
      {props.children}
    </RPDF.View>
  );
};
