import { InternalAxiosRequestConfig } from 'axios';
import { IDoctorStudyDto } from 'src/services/api/api-client';

export const injectStudyId = (study: IDoctorStudyDto | undefined) => (config: InternalAxiosRequestConfig) => {
  if (study) {
    config.headers = config?.headers || {};
    config.headers.StudyId = study.id;
    config.headers.StudyNumber = study.studyNumber;
  }

  return config;
};
