import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useGetNavigationTree } from './formFillingNavigationBar/useGetNavigationTree';
import { FormFillingNavigationContextType, FormFillNavigationContext } from './FormFillingNavigationContext';

export const FormFillingNavigationProvider: FC<PropsWithChildren<FormFillingNavigationContextType>> = ({
  children,
}) => {
  const navigationTree = useGetNavigationTree();
  const [navBarIsOpen, setNavBarIsOpen] = useState(!!navigationTree.length);

  // This effect closes the navbar if the component is already mounted
  useEffect(() => {
    setNavBarIsOpen(!!navigationTree.length);
  }, [navigationTree.length]);

  return (
    <FormFillNavigationContext.Provider value={{ navBarIsOpen, setNavBarIsOpen }}>
      {children}
    </FormFillNavigationContext.Provider>
  );
};
