﻿import React, { FC, PropsWithChildren, ReactNode } from 'react';
import styles from './PropertiesList.module.scss';
import clsx from 'clsx';

type PropertiesListProps = PropsWithChildren<{
  colored?: boolean;
  className?: string;
  testId?: string;
}>;
type PropertyProps = {
  name: string;
  nameClassName?: string;
  value: ReactNode;
  valueClassName?: string;

  testId?: string;
  nameTestId?: string;
  valueTestId?: string;
};

const PropertiesList: FC<PropertiesListProps> & { Property: FC<PropertyProps> } = (props) => (
  <div className={clsx(styles.propertiesList, props.className, { [styles.colored]: props.colored })}>
    {props.children}
  </div>
);

PropertiesList.Property = (props) => (
  <div className={styles.property} data-test-id={props.testId}>
    <div className={clsx(styles.propertyName, props.nameClassName)} data-test-id={props.nameTestId}>
      {props.name}
    </div>
    <div className={clsx(styles.propertyValue, props.valueClassName)} data-test-id={props.valueTestId}>
      {props.value}
    </div>
  </div>
);

export default PropertiesList;
