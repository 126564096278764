import React, { FC } from 'react';
import { RPDF } from '../react-pdf';
import { ReportHeader } from '../components/ReportHeader';
import { ReportPagination } from '../components/ReportPagination';
import { ReportEmptyList } from '../components/ReportEmptyList';
import { IssueReportOptions } from './issueReport';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import i18n from 'i18next';
import { IssueReportTable } from './IssueTable';
import { FirstPageInfo } from './issueFirstPage';
import { IssueReportFilters } from './issueFilters';
import { IGroupDto, IIssueDto } from '../../../services/api/api-client';
import { IssueQueryParamsType } from '../../../components/issue/bar/issueFilters-helper';

export const IssueReportTemplate: React.FC<IssueReportOptions> = (props) => {
  const { t } = useScopedTranslation('Reports.Issue');
  const { data, filters, studyNumber, furtherInfo, studyName } = props;

  return (
    <RPDF.Document
      language={i18n.language}
      producer={'LM Study'}
      title={t('DocumentHeader')}
      author={'LM user'}
      subject={studyNumber}
    >
      <FirstPageInfo data={{ issuesCount: data.length, furtherInfo, studyNumber: studyNumber, studyName: studyName }} />
      <IssueTablePage
        title={t('DocumentHeader')}
        data={data}
        filters={filters}
        studyGroups={filters ? props.studyGroups : []}
      />
    </RPDF.Document>
  );
};

export const IssueTablePage: FC<{
  data: IIssueDto[];
  filters?: IssueQueryParamsType;
  studyGroups: IGroupDto[];
  title: string;
}> = ({ data, filters, studyGroups, title }) => {
  return (
    <RPDF.Page size="A4" orientation="landscape" style={RPDF.styles.landscapePage}>
      <ReportHeader
        titleText={title}
        footer={filters && <IssueReportFilters filters={filters} groups={studyGroups} />}
      />
      {data.length > 0 ? (
        <RPDF.View>
          <IssueReportTable data={data} />
        </RPDF.View>
      ) : (
        <ReportEmptyList />
      )}
      <ReportPagination />
    </RPDF.Page>
  );
};
