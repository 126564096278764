import React, { useMemo } from 'react';
import { IIndicatorModel } from '../../../services/api/api-client';
import { ReactComponent as LeakageIcon } from '../../../assets/img/patient/indicators/leackage.svg';
import { useTranslation } from 'react-i18next';
import Style from '../baseIndicator.module.css';
import clsx from 'clsx';
import { WarningSignIndicator } from '../warning.component';
import { getIndicatorStyleColor } from '../../../helpers/color-helper';
import { TherapyTooltipComponent } from '../therapyTooltip/therapyTooltip.component';

export const LeakageIndicator = (props: { indicator?: IIndicatorModel | null }) => {
  const { t } = useTranslation();

  const tooltipText = useMemo(() => {
    return t('Common_Leakage', { value: props?.indicator?.value.toString() });
  }, [t, props]);

  const icon = useMemo(() => {
    if (props.indicator?.therapyIsNotReceivedForAggregatedPeriod) {
      return <WarningSignIndicator />;
    }

    return (
      <LeakageIcon
        className={clsx(getIndicatorStyleColor(props.indicator?.qualityMetric), Style.base, Style.defaultCursor)}
      />
    );
  }, [props.indicator?.therapyIsNotReceivedForAggregatedPeriod, props.indicator?.qualityMetric]);

  return (
    <TherapyTooltipComponent
      indicator={props?.indicator}
      tooltipValueText={tooltipText}
      testId={'leakage'}
      Icon={LeakageIcon}
      values={props.indicator?.therapyDayValues ?? []}
    >
      {icon}
    </TherapyTooltipComponent>
  );
};
