import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useModal } from 'src/application/hooks/useModal';
import { QueryFactory } from 'src/services/api';
import { FinishPatientStudyDto, FinishStudyReason } from 'src/services/api/api-client';
import { ReactComponent as StopIcon } from '../../assets/img/patient/stop_20.svg';
import { AppButton } from '../uikit/buttons/button/button.component';
import { Tooltip } from '../uikit/tooltip/tooltip.component';
import { FinishStudyComponentProps } from './finishStudy';
import Style from './finishStudy.module.css';
import { useQueryClient } from '@tanstack/react-query';
import { logger } from 'src/application/logging/logging';
import { isNullOrEmpty } from '../../helpers/string-helper';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../application/localisation/useCommonLocalization';
import { AppInputLabel } from '../uikit/wrappers/label/appInputLabel.component';
import { AppTextInput } from '../uikit/inputs/text/appTextInput.component';
import { AppRadioInput } from '../uikit/inputs/radio/appRadioInput.component';
import { TypographyStyles } from 'styles';
import clsx from 'clsx';
import { getPatientRouteProgressQueryKey } from 'src/services/api/api-client/StudyRoutesQuery';
import { FooterButtonProps } from '../dialogModal/dialogModal';
import { DialogModal } from '../dialogModal/dialogModal.component';

/**
 * @deprecated Used only for study without eCRF
 */
export const FinishStudyComponent = (props: FinishStudyComponentProps) => {
  const { patient } = props;
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.FinishStudy');
  const commonLocalizer = useCommonLocalization();
  const queryClient = useQueryClient();
  const [step, setStep] = useState<number>(1);
  const [confirmationInput, setConfirmationInput] = useState<string>('');
  const [description, setDescription] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const finishStudyModal = useModal();

  const confirmationString = patient.uniqueId;
  const isPlannedFinishAllowed = QueryFactory.PatientQuery.useIsFinishStudyAllowedQuery(patient.id, {
    suspense: false,
  }).data;

  const [reason, setReason] = useState<FinishStudyReason>(
    isPlannedFinishAllowed ? FinishStudyReason.Planned : FinishStudyReason.Unplanned,
  );

  const updateReasonRadioButton = useCallback(() => {
    setReason(isPlannedFinishAllowed ? FinishStudyReason.Planned : FinishStudyReason.Unplanned);
  }, [isPlannedFinishAllowed]);

  useLayoutEffect(() => {
    updateReasonRadioButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finishStudyModal.visible]);

  const buttonPressHandler = useCallback(async () => {
    await queryClient.invalidateQueries(QueryFactory.PatientQuery.isFinishStudyAllowedQueryKey(patient.id));
    setConfirmationInput('');
    setDescription('');
    setStep(1);
    finishStudyModal.openModal();
  }, [finishStudyModal, patient.id, queryClient]);

  const finishHandler = useCallback(async () => {
    if (!reason) {
      return;
    }

    setIsLoading(true);

    try {
      await QueryFactory.PatientQuery.Client.finishStudyForPatient(
        patient.id,
        new FinishPatientStudyDto({
          finishStudyDescription: reason === FinishStudyReason.Unplanned ? description : undefined,
          reason: reason,
        }),
      );

      await Promise.all([
        queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientsQueryKey()),
        queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientStudyCountersQueryKey(patient.studyId)),
        queryClient.invalidateQueries(getPatientRouteProgressQueryKey(patient.id)),
        // TODO: maybe we don't need to force it as we will update patient in general upper
        queryClient.invalidateQueries(QueryFactory.FormsQuery.getFormResultsQueryKey({ patientId: patient.id })),
      ]);

      finishStudyModal.closeModal();
    } catch (e: any) {
      logger().info(`Failed to finish study for patient ${patient.id}. ${e}`);
    } finally {
      setIsLoading(false);
    }
  }, [description, finishStudyModal, patient.id, patient.studyId, queryClient, reason]);

  const radioButtonGroup = useMemo(
    () => (
      <div>
        <AppInputLabel text={t('Modal.Reason.Caption')} className={Style.radioButtonsLabel}>
          <div className={Style.radioButtons}>
            <AppRadioInput
              caption={t('Modal.Reason.Unplanned')}
              checked={reason === FinishStudyReason.Unplanned}
              onChange={(e) => setReason(e.target.checked ? FinishStudyReason.Unplanned : FinishStudyReason.Planned)}
            />
            <Tooltip
              text={t('Modal.TooltipText')}
              disabled={isPlannedFinishAllowed}
              hostStyles={Style.tooltipHost}
              textStyles={Style.tooltipText}
              testIdPrefix={'finish-study-form-planned'}
            >
              <AppRadioInput
                caption={t('Modal.Reason.Planned')}
                checked={reason === FinishStudyReason.Planned}
                onChange={(e) => setReason(e.target.checked ? FinishStudyReason.Planned : FinishStudyReason.Unplanned)}
                disabled={!isPlannedFinishAllowed}
              />
            </Tooltip>
          </div>
        </AppInputLabel>
      </div>
    ),
    [isPlannedFinishAllowed, reason, t],
  );

  const stepOne = useMemo(() => {
    return (
      <div className={Style.stepOneContainer}>
        {radioButtonGroup}
        {reason === FinishStudyReason.Unplanned && (
          <AppInputLabel text={t('Modal.ReasonDescriptionLabel')}>
            <AppTextInput
              placeholder={t('Modal.ReasonDescriptionPlaceholder')}
              value={description ?? ''}
              onChange={(e) => setDescription(e.target.value)}
            />
          </AppInputLabel>
        )}
      </div>
    );
  }, [description, radioButtonGroup, reason, t]);

  const stepTwo = useMemo(() => {
    return (
      <div className={Style.stepTwoContainer}>
        <div className={clsx(Style.confirmationMessage, TypographyStyles.paragraph14)}>
          <Trans
            i18nKey={'Dashboard.PatientAccordion.GeneralInfo.FinishStudy.Confirmation.Message'}
            values={{ confirmation: confirmationString }}
          >
            <strong className={Style.awfulText} />
            <strong />
          </Trans>
        </div>
        <AppTextInput
          value={confirmationInput}
          onChange={(e) => setConfirmationInput(e.target.value)}
          placeholder={t('Confirmation.Placeholder')}
        />
      </div>
    );
  }, [confirmationString, confirmationInput, t]);

  const steps = [<></>, stepOne, stepTwo];

  const leftButton: FooterButtonProps | undefined = useMemo(() => {
    if (step === 1)
      return {
        text: commonLocalizer('Common_Cancel'),
        onClick: finishStudyModal.closeModal,
      };

    if (step === 2)
      return {
        text: commonLocalizer('Common_Back'),
        onClick: () => setStep((s) => s - 1),
      };

    return undefined;
  }, [commonLocalizer, finishStudyModal.closeModal, step]);

  const rightButton: FooterButtonProps | undefined = useMemo(() => {
    if (step === 1)
      return {
        text: commonLocalizer('Common_Next'),
        onClick: () => setStep((s) => s + 1),
        disabled: (reason === FinishStudyReason.Unplanned && isNullOrEmpty(description)) || reason === undefined,
      };

    if (step === 2)
      return {
        text: commonLocalizer('Common_Finish'),
        colorSchema: 'destroy',
        onClick: finishHandler,
        disabled: confirmationInput !== confirmationString || isLoading,
      };

    return undefined;
  }, [commonLocalizer, confirmationInput, confirmationString, description, finishHandler, isLoading, reason, step]);

  if (patient.isStudyFinished || !patient.isStudyStarted) {
    return <></>;
  }

  return (
    <div>
      <Tooltip text={t('NotAllIssuesAreClosed')} disabled={patient.allIssuesAreClosed}>
        <AppButton
          testId="patient-card-finish-study"
          variant={'icon-link'}
          colorSchema={'destroy'}
          Icon={StopIcon}
          className={Style.stopStudyButton}
          data-text={t('Button')}
          onClick={buttonPressHandler}
          disabled={!patient.allIssuesAreClosed}
        />
      </Tooltip>

      <DialogModal
        title={step === 1 ? t('Modal.Title') : t('Confirmation.Title')}
        visible={finishStudyModal.visible}
        onHide={finishStudyModal.closeModal}
        isClickOutside={true}
        bodyClassName={Style.modalBody}
        testId={'finish-study-from'}
        footer={{ leftButton, rightButton }}
      >
        {steps[step]}
      </DialogModal>
    </div>
  );
};
