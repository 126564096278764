import React, { CSSProperties } from 'react';
import {
  AppInputError,
  AppInputErrorProps,
  AppInputLabel,
  AppInputLabelProps,
  AppInputSkip,
  FieldSkipProps,
} from 'uikit/wrappers';
import { FieldsWithType } from '../../../../helpers/typeUtils';
import { AppCheckboxGroupInput } from 'uikit/inputs/checkbox/appCheckboxGroupInput.component';

export type AppCheckboxGroupFieldProps<
  TOption extends Record<Key, string | number>,
  Key extends FieldsWithType<TOption, string | number>,
> = {
  style?: CSSProperties;
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipProps?: FieldSkipProps;
  disabled?: boolean;
  options: TOption[];
  value?: TOption[Key][];
  valueField: Key;
  labelField: keyof TOption;
  onChange?: (value: TOption[Key][] | null) => void;
};

export const AppCheckboxGroupField = <
  TOption extends Record<Key, string | number>,
  Key extends FieldsWithType<TOption, string | number>,
>({
  errorProps,
  labelProps,
  skipProps,
  disabled,
  options,
  value,
  valueField,
  labelField,
  onChange,
}: AppCheckboxGroupFieldProps<TOption, Key>) => {
  return (
    <AppInputError {...errorProps}>
      <AppInputLabel disabled={disabled} {...labelProps}>
        <AppInputSkip
          disabled={disabled}
          inputCanBeSkipped={!!skipProps && skipProps.inputCanBeSkipped}
          skipReason={skipProps?.skipReason}
          onSkipReasonChange={skipProps?.onSkipReasonChange ?? (() => {})}
          skipText={skipProps?.skipText}
          showReasons={skipProps?.showReasons}
          position="top-4px"
        >
          <AppCheckboxGroupInput
            value={value}
            onChange={onChange}
            disabled={disabled}
            options={options}
            valueField={valueField}
            labelField={labelField}
          />
        </AppInputSkip>
      </AppInputLabel>
    </AppInputError>
  );
};
