﻿import React, { createContext, FC, memo, useCallback, useContext, useMemo } from 'react';
import TablePageLayout from 'src/components/tablePageLayout/TablePageLayout';
import { StudyNameComponent } from 'src/components/studyName/studyName.component';
import { useTranslation } from 'react-i18next';
import { useSortBy } from 'uikit/table/updateSortByInUrl';
import { createPagingParams, pagingSortingToBackendRequest } from '../../helpers/pagination-helper';
import { useGetIssuesGroupedByPatientsQuery } from '../../services/api/api-client/IssueQuery';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useIssueTableUserColumns } from './table/issueTable.columns';
import { AppTable } from 'uikit/table/AppTable';
import { IssueTableExpandedRow } from './table/issueTableExpandedRow.component';
import { AppPagination } from 'uikit/pagination/AppPagination';
import { ReactComponent as NoIssues } from 'src/assets/img/issues/no_issues.svg';
import { ReactComponent as ExportBig } from 'src/assets/img/common/export_16.svg';
import { createEnumParam, StringParam, useQueryParams } from 'react-router-url-params';
import { NumberArrayParam, StringArrayParam } from '../../helpers/query-params';
import { IssuesStateEnum, Permissions } from '../../services/api/api-client';
import { MonitoringPageFilters } from './MonitoringPage.Filters';
import { DecodedValueMap } from 'use-query-params';
import { SetQueryLocal } from 'react-router-url-params/src/useQueryParams';
import { MonitoringFormsSection } from './MonitoringFormsSection/MonitoringFormsSection';
import { useMonitoringExportModal } from './export/useMonitoringExport';
import { AppButton } from 'uikit/buttons';
import { useStudy } from 'src/helpers/hooks/useStudy';
import Style from './MonitoringPage.module.scss';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';

export const issueFiltersQueryParamsConfig = {
  search: StringParam,
  issueState: createEnumParam(['Opened', 'Processed', 'Closed']),
  subjects: StringArrayParam,
  patientUniqueId: StringParam,
  groupIds: NumberArrayParam,
};

export const monitoringPageQueryParamsConfig = {
  ...createPagingParams({
    defaultPerPage: 20,
    sortingKeys: ['uniqueId'] as const,
    defaultSortBy: 'uniqueId',
  }),
  ...issueFiltersQueryParamsConfig,
};
export const MonitoringPage: FC = () => {
  const { t } = useTranslation();
  const study = useStudy();
  const { hasPermission } = useHasPermissions();

  const [queryParams, setQueryParams] = useQueryParams(monitoringPageQueryParamsConfig);
  const issueState = queryParams.issueState && IssuesStateEnum[queryParams.issueState];

  const sortBy = useSortBy(queryParams, setQueryParams);

  const groupedIssues = useGetIssuesGroupedByPatientsQuery(
    {
      studyId: study?.id ?? 0,
      patientUniqId: queryParams.patientUniqueId,
      state: issueState,
      subjectList: queryParams.subjects,
      patientGroupIds: queryParams.groupIds,
      ...pagingSortingToBackendRequest(queryParams),
    },
    {
      suspense: false,
      keepPreviousData: true,
      enabled: !!study?.id,
    },
  );

  const setIssueFilterQueryParams = useCallback<typeof setQueryParams & object>(
    (changes, updateType) => {
      setQueryParams(
        typeof changes === 'function'
          ? (prev) => ({ ...changes(prev), sortBy: undefined, page: undefined })
          : { ...changes, sortBy: undefined, page: undefined },
        updateType,
      );
    },
    [setQueryParams],
  );

  const dataTable = useReactTable({
    data: groupedIssues.data?.data || [],
    columns: useIssueTableUserColumns(),
    getCoreRowModel: getCoreRowModel(),
    getRowId: (group) => (group.uniqueId ? `${group.uniqueId}-patient` : 'study'),
    manualSorting: true,
    enableSortingRemoval: false,
    state: {
      sorting: sortBy.sortingState,
    },
    onSortingChange: sortBy.onSortingChange,
  });

  const pageContextValue = useMemo<MonitoringPageContextState>(
    () => ({ queryParams, setQueryParams: setIssueFilterQueryParams }),
    [queryParams, setIssueFilterQueryParams],
  );

  return (
    <PageContext.Provider value={pageContextValue}>
      <TablePageLayout width="full" testId={'monitoring-page'}>
        <TablePageLayout.Header>
          <TablePageLayout.Header.Row className={Style.header}>
            <StudyNameComponent />
            <ExportButton />
          </TablePageLayout.Header.Row>
          <MonitoringPageFilters />
        </TablePageLayout.Header>
        <TablePageLayout.TableContainer loading={groupedIssues.isLoading || groupedIssues.isPreviousData}>
          <AppTable
            testId="grouped-issues"
            table={dataTable}
            placeholder={{
              Icon: NoIssues,
              text: t('Issues.Filters.NoIssuesText'),
              hide: groupedIssues.isLoading || groupedIssues.isPreviousData,
            }}
            expandedRow={(row) => {
              if (!study?.id) return <React.Fragment key={row.original.uniqueId} />;

              return (
                <IssueTableExpandedRow
                  key={row.original.uniqueId}
                  studyId={study?.id}
                  patientUid={row.original.uniqueId}
                  issueState={issueState}
                  groupIds={queryParams.groupIds}
                />
              );
            }}
          />
          <AppPagination
            page={queryParams.page}
            perPage={queryParams.perPage}
            totalCount={groupedIssues.data?.totalCount || 0}
            changePagination={setQueryParams}
          />
        </TablePageLayout.TableContainer>
        {hasPermission(Permissions.MonitoringReportView) && <MonitoringFormsSection />}
      </TablePageLayout>
    </PageContext.Provider>
  );
};

export type MonitoringPageContextState = {
  queryParams: DecodedValueMap<typeof monitoringPageQueryParamsConfig>;
  setQueryParams: SetQueryLocal<typeof monitoringPageQueryParamsConfig>;
};

const PageContext = createContext<MonitoringPageContextState>(undefined as any as MonitoringPageContextState);

export const useMonitoringPageContext = () => {
  const context = useContext(PageContext);
  if (!context) throw Error('MonitoringPageContext is not provided');

  return context;
};

const ExportButton: FC = memo(() => {
  const monitoringExportModal = useMonitoringExportModal();

  return (
    <>
      <AppButton variant={'icon-link'} colorSchema={'primary'} Icon={ExportBig} onClick={monitoringExportModal.open} />
      {monitoringExportModal.element}
    </>
  );
});
