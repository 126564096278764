import React, { useMemo } from 'react';
import { useSkipReasonText } from 'src/components/formEditor/controls/Inputs/base/hooks';
import { useContextSelector } from 'use-context-selector';
import { localFormat, toDateOrNull } from '../../../../../helpers/date-helpers';
import { IDateInput } from '../../../../forms/base/controls/inputs/DateInput';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const DateInput: IDateInput = ({ label, dataKey, skipCheckBoxText }) => {
  const inputValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  const skipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.[dataKey]);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const formatedValue = useMemo(() => {
    const date = toDateOrNull(singleInputValue);
    return date ? localFormat(date) : '';
  }, [singleInputValue]);

  return <Field label={label ?? dataKey} skipReason={skipReasonText} value={formatedValue} />;
};
