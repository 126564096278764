import React from 'react';
import i18n from 'i18next';
import { EditReasonEnum } from 'src/services/api/api-client';
import { ReactComponent as Cross } from 'src/assets/img/initialsurvey/bold_cross_16.svg';
import { ReactComponent as Plus } from 'src/assets/img/initialsurvey/bold_plus_16.svg';
import { ReactComponent as Update } from 'src/assets/img/initialsurvey/update_16.svg';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';

export const editReasonsSelectOptions = (): DropdownOption[] => [
  {
    key: EditReasonEnum.WrongEntry,
    text: i18n.t('Forms.EditReasonModal.1'),
    icon: <Cross />,
  },
  {
    key: EditReasonEnum.Adding,
    text: i18n.t('Forms.EditReasonModal.2'),
    icon: <Plus />,
  },
  {
    key: EditReasonEnum.Update,
    text: i18n.t('Forms.EditReasonModal.3'),
    icon: <Update />,
  },
];
