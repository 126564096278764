import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { dateLocales, defaultNS, fallbackLng, Language, Languages, Namespaces } from './locales';

const languageDetector = new LanguageDetector(null, {
  order: ['querystring', 'localStorage', 'sessionStorage', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],

  lookupLocalStorage: 'i18nextLng',
  lookupCookie: 'i18next',
  caches: ['localStorage', 'cookie'],
});
export function initializeLocalization(): Promise<any> {
  return i18n
    .use(intervalPlural)
    .use(languageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      compatibilityJSON: 'v3',
      defaultNS,
      fallbackLng,
      ns: Namespaces,
      load: 'languageOnly',
      preload: [fallbackLng],
      saveMissing: import.meta.env.NODE_ENV !== 'production',
      lowerCaseLng: true,
      initImmediate: true,
      debug: false,
      supportedLngs: Languages,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      backend: {
        loadPath: '/dictionaries/{{lng}}/{{ns}}.json',
        queryStringParams: { v: import.meta.env.DEV ? Date.now().toString() : build.REACT_APP_VERSION }, // to avoid caching
      },
    })
    .then(() => {
      return changeLanguage(i18n.languages[0] as Language);
    });
}

export async function changeLanguage(language: string) {
  // handle there all locale dependent resources like a date presentation
  await i18n.changeLanguage(language);
  document.documentElement.lang = language;
}

export const getCurrentDateLocale = () => {
  return { locale: dateLocales[i18n.language] };
};
