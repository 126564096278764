import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { TypographyStyles } from 'src/styles';
import Style from './surveyTableRow.module.css';
import { HeaderTableRowProps } from './surveyAnswers';
import { DateFormats, localFormat } from 'src/helpers/date-helpers';
import { ReactComponent as RightArrowSmall } from '../../assets/img/survey/rightArrowSmall.svg';
import { ReactComponent as LeftArrowSmall } from '../../assets/img/survey/leftArrowSmall.svg';
import { ReactComponent as Doctor } from '../../assets/img/survey/doctor_16.svg';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';
import { AppSwiper } from 'src/components/swiper/appSwiper.component';
import { slidesPerView, slideWidthPx } from './constants';
import { Tooltip } from '../../components/uikit/tooltip/tooltip.component';
import { SwiperSlide } from 'swiper/react';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';

export const HeaderTableRow = (props: HeaderTableRowProps) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection');
  const scoreComponentList = props.data.map((data, i) => {
    if (!data.date) {
      return <div className={Style.answer} key={i} />;
    }

    return (
      <SwiperSlide className={clsx(Style.answer, Style.header, { [Style.singleAnswer]: props.isSingleAnswer })} key={i}>
        {data.answeredBy && (
          <Tooltip text={t('LastAvailableStats.FilledBy', { doctor: data.answeredBy.fullName })}>
            <Doctor />
          </Tooltip>
        )}
        <div data-test-id={'survey-answer-date'}>{localFormat(data.date, DateFormats.longDate)}</div>
      </SwiperSlide>
    );
  });

  const isNextDisabled = useMemo(() => {
    return (props.swipeIndex ?? 0) + slidesPerView >= props.data.length;
  }, [props.data.length, props.swipeIndex]);

  const isPrevDisabled = useMemo(() => {
    return (props.swipeIndex ?? 0) <= 0;
  }, [props.swipeIndex]);

  const goNext = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    props.onSwipe((props.swipeIndex ?? 0) + 6);
  }, [props]);

  const goPrev = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    props.onSwipe((props.swipeIndex ?? 0) - 6);
  }, [props]);

  return (
    <div className={clsx(TypographyStyles.plainText14, Style.container)}>
      <div className={clsx(Style.row, Style.borderBottom)}>
        <div className={Style.index} />
        <div className={Style.questionTextContainer}>
          <div className={Style.questionText} />
          {scoreComponentList.length > slidesPerView && (
            <AppButton
              disabled={isPrevDisabled}
              variant={'icon-link'}
              colorSchema={'primary'}
              Icon={LeftArrowSmall}
              onClick={goPrev}
            />
          )}
        </div>
        <AppSwiper
          slideWidth={slideWidthPx}
          slidesPerView={slidesPerView}
          containerStyles={Style.answersContainer}
          swipeIndex={props.swipeIndex}
        >
          {scoreComponentList}
        </AppSwiper>
        <div className={Style.lastColumn}>
          {scoreComponentList.length > slidesPerView && (
            <AppButton
              disabled={isNextDisabled}
              variant={'icon-link'}
              colorSchema={'primary'}
              Icon={RightArrowSmall}
              onClick={goNext}
            />
          )}
        </div>
      </div>
    </div>
  );
};
