import React from 'react';
import { TableCellProps } from './reportTable';
import { RPDF } from '../../react-pdf';

export const TableCell: React.FC<TableCellProps> = (props: TableCellProps) => {
  return (
    <RPDF.View style={props.styles}>
      <RPDF.Text style={RPDF.typography.text8}>{props.value}</RPDF.Text>
    </RPDF.View>
  );
};
