import React, { FC } from 'react';
import { AppInputError, AppInputErrorProps, AppInputSkip, FieldSkipProps } from 'uikit/wrappers';
import { AppInputLabel, AppInputLabelProps } from 'uikit/wrappers';
import { AppSliderInputProps } from '../../inputs/slider/appSliderInput';
import { AppSliderInput } from 'uikit/inputs';

export type AppSliderFieldType = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipProps?: FieldSkipProps;
} & AppSliderInputProps;

export const AppSliderField: FC<AppSliderFieldType> = ({
  errorProps,
  labelProps,
  skipProps,
  disabled,
  value,
  ...rest
}) => {
  return (
    <AppInputError {...errorProps}>
      <AppInputLabel disabled={disabled} {...labelProps}>
        <AppInputSkip
          disabled={disabled}
          skipReason={skipProps?.skipReason}
          onSkipReasonChange={skipProps?.onSkipReasonChange ?? (() => {})}
          showReasons={skipProps?.showReasons}
          skipText={skipProps?.skipText}
          inputCanBeSkipped={skipProps?.inputCanBeSkipped}
          position={'center'}
        >
          <AppSliderInput disabled={disabled} value={value} {...rest} />
        </AppInputSkip>
      </AppInputLabel>
    </AppInputError>
  );
};
