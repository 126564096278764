﻿import { getFormResults, getForms } from '../../../services/api/api-client/FormsClient';
import _ from 'lodash';
import {
  IFormDto,
  IFormResultDto,
  IGroupDto,
  IRouteProgressDto,
  RouteProgressStateEnum,
} from '../../../services/api/api-client';
import { FilledRecord } from './recordsReport';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { QueryFactory } from '../../../services/api';
import { createReport, triggerDownloadFileDialog } from '../report-helpers';
import { normalizeFileName } from '../../../helpers/string-helper';
import i18n from 'i18next';
import { getRecordResultListItemVms } from '../../../components/formEditor/controls/ConstantBlocks/RecordResultList/useGetFormList';
import { RecordsReportTemplate } from './recordsReport.layout';
import React from 'react';

export const generateRecordsReport = async (
  patient: { id: string; uniqueId: string; tags?: Record<string, string>; routeProgress: IRouteProgressDto },
  study: {
    id: number;
    title: string;
    studyNumber: string;
    groups: IGroupDto[];
    hasEcrf: boolean;
    patientFinishingFormId: number | null | undefined;
  },
  authorFullName: string,
  formTypes: string[],
  exportAllVersions: boolean,
  signPdf: boolean,
) => {
  const formResults = await getFormResults(undefined, undefined, undefined, patient.id);
  const configIds = [...new Set(formResults.data.map((fr) => fr.formConfigId))];
  const formConfigs = await getForms(configIds);

  //#region Multi instance

  const multiInstanceFilledRecords = formResults.data
    .map((fr) => {
      if (exportAllVersions) {
        return {
          form: _.find(formConfigs, { id: fr.formConfigId }) as IFormDto,
          resultDto: fr,
        };
      }

      return {
        form: _.find(formConfigs, { id: fr.formConfigId }) as IFormDto,
        resultDto: { ...fr, versions: [_.last(fr.versions)!] },
      };
    })
    .filter((x) => x.form && x.form.isMultiInstance && formTypes.some((type) => type === x.form?.type))
    .reverse()
    .map((multiform, i) => {
      return { ...multiform, stepName: `#${i + 1}` };
    });

  //#endregion

  //#region Filled records

  const filledRecords = patient.routeProgress.steps
    .map((step) => ({
      formResults: step.forms
        .filter((x) => x.formResultId !== null)
        .filter((fr) => formTypes.some((type) => type === fr.formType))
        .map((x) => ({ id: x.formResultId!, type: x.formType })),
      stepName: step.name,
    }))
    .filter((x) => x.formResults.length > 0)
    .reduce((acc: FilledRecord[], cur) => {
      if (exportAllVersions) {
        return [
          ...acc,
          ...(cur.formResults?.map((fr) => ({
            form: _.find(formConfigs, { type: fr.type }) as IFormDto,
            resultDto: _.find(formResults.data, { id: fr.id }) as IFormResultDto,
            stepName: cur.stepName,
          })) ?? []),
        ];
      }

      return [
        ...acc,
        ...(cur.formResults?.map((fr) => {
          const formResult = _.find(formResults.data, { id: fr.id }) as IFormResultDto;
          return {
            form: _.find(formConfigs, { type: fr.type }) as IFormDto,
            resultDto: { ...formResult, versions: [_.last(formResult.versions)!] },
            stepName: cur.stepName,
          };
        }) ?? []),
      ];
    }, []);

  //#endregion

  //#region Missed records

  const missedRecords = patient.routeProgress.steps
    .filter((step) => step.state === RouteProgressStateEnum.Missed)
    .map((step) => {
      return {
        stepName: step.name,
        formTypes:
          step.forms
            .filter((x) => x.formResultId === null)
            .filter((fl) => formTypes.some((type) => type === fl.formType))
            .filter((fl) => !filledRecords?.some((fr) => fr.form.id === fl.formConfigId && fr.stepName === step.name))
            .map((fb) => fb.formType) ?? [],
      };
    })
    .flatMap((cur) =>
      cur.formTypes.map((x) => ({
        stepName: cur.stepName,
        type: x,
      })),
    );

  //#endregion Missed records

  const formConfigIdsForNotes = study.groups
    .filter((x) => x.formConfigIdForNotes)
    .map((x) => x.formConfigIdForNotes as number);

  const { mandatoryForms, multipleForms } = getRecordResultListItemVms(
    study.patientFinishingFormId,
    formResults.data,
    formConfigIdsForNotes,
    patient.routeProgress,
  );

  let recordsBlob = await createReport(
    <RecordsReportTemplate
      {...{
        filledRecords: [...(filledRecords ?? []), ...multiInstanceFilledRecords],
        missedRecords: missedRecords ?? [],
        studyNumber: study.studyNumber,
        studyName: study.title,
        patientUid: patient.uniqueId,
        profileFullName: authorFullName,
        groups: study.groups,
        patientTags: patient.tags,
        patientId: patient.id,
        mandatoryForms,
        multipleForms,
        studyHasEcrf: study.hasEcrf,
      }}
    />,
  );

  const filename = `Study-${study.studyNumber}-Patient-${patient.uniqueId}-${
    formTypes.length === 1 ? formTypes[0].replace(' ', '_') : 'Records'
  }-${localFormat(new Date(), DateFormats.forFiles)}`;

  if (signPdf) {
    const signedPdf = await QueryFactory.PdfClient.signPdf(
      { data: recordsBlob, fileName: filename },
      i18n.t('LogExport.SigningReason'),
      420,
      95,
      60,
      115,
    );
    recordsBlob = signedPdf.data;
  }

  triggerDownloadFileDialog(recordsBlob, normalizeFileName(filename));
};
