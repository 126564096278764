import React from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { Container } from '../container';
import { Text } from '../text';
import { ReactComponent as CommonAddIcon } from '../../../../assets/img/common/add_40.svg';
import Styles from './collapsible.module.css';
import clsx from 'clsx';
import { Collapse } from 'uikit/collapse/Collapse';

export type CollapsibleProps = {
  isExpanded: boolean;
};

const defaultProps = {
  isExpanded: false,
};

export const Collapsible = (props: Partial<CollapsibleProps>) => {
  props = {
    ...defaultProps,
    ...props,
  };

  const { isExpanded } = props;

  const { connectors, setProp } = useNode((node) => ({
    selected: node.events.selected,
    childrenIds: node.data.nodes,
  }));
  const { connect, drag } = connectors;
  const { enabled: isEditorMode } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <div style={{ width: '100%', overflow: 'hidden' }} ref={(ref: HTMLDivElement) => connect(drag(ref))}>
      <div
        className={clsx(Styles.header, { [Styles.cursorPointer]: !isEditorMode })}
        onClick={() => !isEditorMode && setProp((prop) => (prop.isExpanded = !prop.isExpanded))}
      >
        <Element id={'header'} is={Text} fontSize={'24'} fontWeight={'500'} margin={[24, 0, 24, 0]} />
        <button
          className={Styles.button}
          onClick={(e) => (isEditorMode ? setProp((prop) => (prop.isExpanded = !prop.isExpanded)) : e.preventDefault())}
        >
          <CommonAddIcon className={clsx(Styles.icon, { [Styles.iconOpened]: isExpanded })} />
        </button>
      </div>
      <Collapse in={!!isExpanded} unmountOnExit>
        <Element id={'content'} canvas={true} is={Container} width="100%" />
      </Collapse>
    </div>
  );
};

Collapsible.craft = {
  displayName: 'Collapsible',
  rules: {
    canDrag: () => true,
  },
};
