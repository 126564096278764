﻿import React, { FC, memo, PropsWithChildren, useCallback, useMemo } from 'react';
import { FieldSkipReason } from '../../../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../application/redux-store/root-store';
import { LocalizedResourceDictionaryKeys } from '../../../../application/localisation/i18next';
import styles from './AppInputSkip.module.scss';
import { AppCheckboxInput } from 'uikit/inputs';
import { DropDownMenu } from 'uikit/dropDownMenu/dropDownMenu.component';
import { DropDownMenuOption } from 'uikit/dropDownMenu/dropDownMenu';
import clsx from 'clsx';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { buildTestId } from '../../../../helpers/test-helpers';
import { useStudy } from 'src/helpers/hooks/useStudy';

export type FieldSkipProps = Pick<
  AppInputSkipProps,
  'inputCanBeSkipped' | 'skipReason' | 'skipText' | 'showReasons' | 'onSkipReasonChange'
>;

export type AppInputSkipProps = PropsWithChildren<{
  /** @default true */
  inputCanBeSkipped?: boolean;
  skipText?: string;
  /** @default studyHasEcrf */
  showReasons?: boolean;
  disabled?: boolean;
  className?: string;
  skipReason: FieldSkipReason | undefined;
  onSkipReasonChange: (reason: FieldSkipReason | undefined) => void;
  /** @default center */
  position?: 'top' | 'top-4px' | 'top-8px' | 'center';
  testId?: string;
}>;

export const AppInputSkip: FC<AppInputSkipProps> = ({
  inputCanBeSkipped = true,
  disabled,
  skipText,
  showReasons,
  onSkipReasonChange,
  children,
  skipReason,
  position = 'center',
  testId,
}) => {
  const { t } = useTranslation();
  const study = useStudy();
  showReasons = showReasons ?? study?.hasEcrf;

  const options: DropDownMenuOption[] = useMemo(
    () =>
      Object.keys(FieldSkipReason)
        .filter((x) => !Number(x) && Number(x) !== 0)
        .filter((x) => x !== FieldSkipReason[FieldSkipReason.NoReason])
        .map<DropDownMenuOption>((x) => ({
          key: FieldSkipReason[x],
          text: t(`Forms.Controls.SkipQuestionReason.${x}` as LocalizedResourceDictionaryKeys),
          action: () => onSkipReasonChange?.(FieldSkipReason[x]),
        })),
    [onSkipReasonChange, t],
  );

  if (!inputCanBeSkipped) {
    return <>{children}</>;
  }

  const replaceInput = skipReason !== undefined;

  return (
    <div className={styles.appInputSkip} data-test-id={buildTestId(testId, 'app-input-skip')}>
      <div className={styles.inputContainer}>
        {replaceInput ? (
          <InputReplacer
            reason={skipReason}
            showReasons={showReasons}
            skipText={skipText}
            options={options}
            testId={testId}
            disabled={disabled}
          />
        ) : (
          children
        )}
      </div>
      <TriggerCheckbox
        options={options}
        position={replaceInput ? 'center' : position}
        disabled={disabled}
        reason={skipReason}
        onChangeReason={onSkipReasonChange}
        testId={testId}
      />
    </div>
  );
};

const InputReplacer: FC<{
  reason: FieldSkipReason;
  showReasons: boolean | undefined;
  options: DropDownMenuOption[];
  skipText: string | undefined;
  testId: string | undefined;
  disabled?: boolean;
}> = memo(({ reason, skipText, showReasons, options, testId, disabled }) => {
  const { t } = useTranslation();

  return (
    <div
      data-disabled={disabled}
      className={styles.inputReplacer}
      data-test-id={buildTestId(testId, 'app-input-skip-input-replacer')}
    >
      {showReasons ? (
        <>
          <span>{t('Forms.Controls.AppInputSkip.InputReplacer')}</span>
          <DropDownMenu
            disabled={disabled}
            colorSchema={'primary'}
            options={options}
            closeCalloutByClick
            hasChevron
            text={
              reason === FieldSkipReason.NoReason
                ? '-'
                : t(`Forms.Controls.SkipQuestionReason.${FieldSkipReason[reason]}` as LocalizedResourceDictionaryKeys)
            }
          />
        </>
      ) : (
        skipText || t('Forms.Controls.SkipQuestionCheckBox')
      )}
    </div>
  );
});

const TriggerCheckbox: FC<{
  position: 'top' | 'top-4px' | 'top-8px' | 'center';
  disabled: boolean | undefined;
  options: DropDownMenuOption[];
  reason: FieldSkipReason | undefined;
  onChangeReason: ((reason: FieldSkipReason | undefined) => void) | undefined;
  testId: string | undefined;
}> = memo((props) => {
  const { t } = useTranslation();
  const study = useStudy();

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!study?.hasEcrf) {
        props.onChangeReason?.(e.target.checked ? FieldSkipReason.NoReason : undefined);
      }

      if (e.target.checked) return;
      props.onChangeReason?.(undefined);
    },
    [props, study?.hasEcrf],
  );

  return (
    <div
      className={clsx({
        [styles.top4Px]: props.position === 'top-4px',
        [styles.top8Px]: props.position === 'top-8px',
        [styles.center]: props.position === 'center',
      })}
    >
      <Tooltip text={t('Forms.Controls.AppInputSkip.TriggerCheckbox.Tooltip')}>
        <DropDownMenu
          options={props.options}
          disabled={props.reason !== undefined || !study?.hasEcrf}
          customComponent={
            <AppCheckboxInput
              data-test-id={buildTestId(props.testId, 'app-input-skip-trigger')}
              variant={'destroy'}
              disabled={props.disabled}
              checked={props.reason !== undefined}
              onChange={onChangeHandler}
            />
          }
        />
      </Tooltip>
    </div>
  );
});
