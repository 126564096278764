import { useNode } from '@craftjs/core';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { CommentDataKey } from '../../../../../components/formEditor/uiEditor/provider/formFill.context';
import { ConditionalFieldBehaviorEnum } from '../../../../forms/base/controls/inputs/base/baseControlSettings';
import { IRadioInput } from '../../../../forms/base/controls/inputs/RadioInput';
import { RPDF } from '../../../react-pdf';
import { renderNode } from '../../recordReport-helper';
import { recordReportFormControlsResolver } from '../../recordReport.resolver';
import { useSkipReasonText } from 'src/components/formEditor/controls/Inputs/base/hooks';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const RadioInput: IRadioInput = ({
  dataKey,
  label,
  text,
  options,
  numerationStartIndex,
  numerationOrder,
  skipCheckBoxText,
}) => {
  const { t } = useTranslation();
  const { id } = useNode();

  const nodes = useContextSelector(RecordContext, (v) => v.nodes);
  const linkedNodes = nodes.find((n) => n.id === id)?.linkedNodes;
  const inputValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const commentValue = useContextSelector(RecordContext, (v) => v.formResult?.[CommentDataKey(dataKey)]);
  const skipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.[dataKey]);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  const displayValue = useMemo(() => {
    return options.filter((x) => x.value === singleInputValue).map((x) => x.displayValue)[0];
  }, [singleInputValue, options]);

  const answerIndex = useMemo(() => {
    let optionIndex = options.findIndex((x) => x.value === singleInputValue);
    if (!numerationOrder || numerationOrder === 'NoNum' || optionIndex === -1) {
      return null;
    }

    if (numerationOrder === 'Rtl') {
      optionIndex = options.reverse().findIndex((x) => x.value === singleInputValue);
    }

    return (numerationStartIndex || 0) + optionIndex;
  }, [options, numerationOrder, numerationStartIndex, singleInputValue]);

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = options.filter((x) => x.withConditionalField);

    const finalArray = optionsWithConditionalField.filter(
      (option) =>
        option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
        multipleInputValue?.includes(option.value),
    );

    return Array.from(finalArray, (option) => {
      const childId = _.get(linkedNodes, option.key);
      if (!childId) {
        return <RPDF.View key={option.key} />;
      }

      return <RPDF.View key={option.key}>{renderNode(nodes, recordReportFormControlsResolver, childId)}</RPDF.View>;
    });
  }, [options, multipleInputValue, linkedNodes, nodes]);

  return (
    <>
      <Field
        label={label ?? text ?? dataKey}
        skipReason={skipReasonText}
        value={{ index: answerIndex, text: displayValue }}
      />
      {commentValue && <Field label={t('Forms.Controls.CommentFieldCaption')} value={commentValue} />}
      {conditionalContainer.length > 0 && conditionalContainer}
    </>
  );
};
