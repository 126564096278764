import { FormStateType } from 'src/features/forms/form-helper';
import { FormLifecycleState, IFillingProgress } from 'src/services/api/api-client';

export type RecordRowVm = {
  formConfigId: number;
  formType: string;
  isMultiInstance: boolean;
  formResults: FormResultVm[];
  fillingProgress: IFillingProgress | null | undefined;
  formLifeCycle: FormLifecycleState | null | undefined;
  state: FormStateType | undefined;
  isSkipped: boolean | undefined;
};

export type FormResultVm = {
  formResultId: number | undefined;
  stepName: string | undefined;
  stepIsActive: boolean;
  lastFormResultVersion?: number | null;
  isEditable?: boolean | null;
};

// TODO: May be we should store it in the study config or somewhere else
export const UE_FORM_TYPE = 'SUE';
