export enum MetricActions {
  // Common cases.
  Loaded = 'Loaded',
  Opened = 'Opened',
  LoggedIn = 'Logged In',
  Logout = 'Log Out',
  Activated = 'Activated',
  ButtonPressed = 'Button pressed',
  TabChanged = 'Tab Changed',
  FilterApplied = 'Filter Applied',
  LinkPressed = 'Link pressed',
  ValueApplied = 'Value applied',
  ValueChanged = 'Value changed',
  LanguageChanged = 'Lanuage changed',
  Started = 'Started',
  Finished = 'Finished',
  Send = 'Send',
  Fetch = 'Fetch',
  GoNext = 'Go Next',
  GoBack = 'Go Back',
  Generated = 'Generated',
  ReGenerated = 'Re-Generated',
}
