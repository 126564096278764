import React, { useMemo } from 'react';
import Style from './navAppBar.module.css';
import { LanguageSwitcher } from '../../components/languageSwitcher/languageSwitcher.component';
import { AppRoutes, navigateToRoute } from '../../navigation/routes';
import { NavButton } from './navButton.component';
import { Permissions } from 'src/services/api/api-client';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { useMatch } from 'react-router';
import { ReactComponent as BackIcon } from '../../assets/img/common/backArrow.svg';
import { AccountInfoComponent } from '../../components/accoutInfo/accountInfo.component';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';
import { useStaffProfile, useStudy } from 'src/helpers/hooks/useStudy';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';

export const NavAppMenu = () => {
  const { t } = useScopedTranslation('NavMenu');
  const { hasPermission, hasOneOfPermissions } = useHasPermissions();

  const { profile } = useStaffProfile();
  const { id: studyId, closingApprovedAt, infoPageConfiguration } = useStudy() ?? {};

  const studiesMatch = useMatch({ path: AppRoutes.Studies });
  const adminMatch = useMatch({ path: AppRoutes.Admin, end: false });
  const impressumMatch = useMatch({ path: AppRoutes.Impressum, end: false });

  const navigations = useMemo(() => {
    return (
      <div className={Style.tabsContainer}>
        <NavButton
          title={''}
          route={AppRoutes.Studies}
          variant={'link'}
          Icon={BackIcon}
          isHidden={
            (!!studiesMatch ||
              !!adminMatch ||
              ((profile.studies.length ?? 0) < 2 &&
                !hasOneOfPermissions(Permissions.StudyManage, Permissions.UserManage, Permissions.RoleAssign))) &&
            !impressumMatch
          }
          styles={Style.backButton}
        />
        {studyId && (
          <>
            <PermissionsCheck permissions={Permissions.PatientView}>
              <NavButton
                title={t('Tabs.Study')}
                route={navigateToRoute.dashboard(studyId)}
                variant={'primary'}
                isHidden={!!studiesMatch || !!adminMatch}
                end={true}
              />
              <NavButton
                title={t('Tabs.StudyInfo')}
                route={navigateToRoute.studyInfoPage(studyId)}
                variant={'primary'}
                isHidden={!!studiesMatch || !!adminMatch || !infoPageConfiguration?.sections.length}
                end={true}
              />
            </PermissionsCheck>
            {!closingApprovedAt && (
              <PermissionsCheck permissions={Permissions.StudyConfigure}>
                <NavButton
                  title={t('Tabs.Settings')}
                  route={navigateToRoute.studySettings(studyId)}
                  variant={'primary'}
                  isHidden={!!studiesMatch || !!adminMatch}
                />
              </PermissionsCheck>
            )}
            <PermissionsCheck permissions={Permissions.IssueView}>
              <NavButton
                title={t('Tabs.Monitoring')}
                route={navigateToRoute.monitoring(studyId)}
                variant={'primary'}
                isHidden={!!studiesMatch || !!adminMatch}
              />
            </PermissionsCheck>
            <PermissionsCheck permissions={Permissions.ParticipantsView}>
              <NavButton
                title={t('Tabs.Participants')}
                route={navigateToRoute.participants(studyId)}
                variant={'primary'}
                isHidden={!!studiesMatch || !!adminMatch}
              />
            </PermissionsCheck>
          </>
        )}

        {hasOneOfPermissions(Permissions.StudyManage, Permissions.RoleAssign, Permissions.UserManage) && (
          <>
            {profile?.studies && !!profile.studies.length && (
              <NavButton
                title={t('Tabs.Studies')}
                route={AppRoutes.Studies}
                variant={'primary'}
                isHidden={!studiesMatch && !adminMatch}
              />
            )}
            <NavButton
              title={t('Tabs.Admin')}
              route={AppRoutes.Admin}
              variant={'primary'}
              isHidden={!studiesMatch && !adminMatch}
            />
          </>
        )}
      </div>
    );
  }, [
    studiesMatch,
    adminMatch,
    profile.studies,
    hasPermission,
    impressumMatch,
    studyId,
    t,
    infoPageConfiguration?.sections.length,
    closingApprovedAt,
    hasOneOfPermissions,
  ]);

  return (
    <>
      <div className={Style.navContainer} data-test-id={'navbar-container'}>
        {navigations}

        <div className={Style.languageContainer}>
          <LanguageSwitcher />
        </div>

        <div className={Style.navButtons}>
          {studyId && (
            <NavButton
              title={t('FAQ')}
              route={navigateToRoute.faqPage(studyId)}
              variant={'primary'}
              testId={'faq-button'}
            />
          )}
          <NavButton
            title={t('Impressum')}
            route={AppRoutes.Impressum}
            variant={'primary'}
            testId={'impressum-button'}
          />
        </div>

        <AccountInfoComponent profile={profile} />
      </div>
    </>
  );
};
