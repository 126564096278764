import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { CommentDataKey } from '../../../../../components/formEditor/uiEditor/provider/formFill.context';
import { IFilesInput } from '../../../../forms/base/controls/inputs/Files';
import { AppAttachmentValue } from 'uikit/fields/attachments/attachmentsField/appAttachmentsField';
import { useSkipReasonText } from 'src/components/formEditor/controls/Inputs/base/hooks';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const FilesInput: IFilesInput = ({ label, dataKey, skipCheckBoxText, withComment }) => {
  const { t } = useTranslation();
  const inputValue = useContextSelector(RecordContext, (v) => {
    return v.formResult ? (v.formResult[dataKey] as AppAttachmentValue[]) : null;
  });
  const commentValue = useContextSelector(RecordContext, (v) => v.formResult?.[CommentDataKey(dataKey)]);
  const skipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.[dataKey]);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  return (
    <>
      <Field label={label ?? dataKey} skipReason={skipReasonText} value={inputValue?.map((x) => x.fileName)} />
      {withComment && <Field label={t('Forms.Controls.CommentFieldCaption')} value={commentValue} />}
    </>
  );
};
