import { useEditor, useNode, Element } from '@craftjs/core';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { radioInputDefaultPropsFactory } from 'src/features/forms/base/controls/inputs/RadioInput';
import {
  ISliderInput,
  ISliderInputProps,
  sliderInputApplicableValidationRules,
} from 'src/features/forms/base/controls/inputs/SliderInput';
import { AppSliderField } from 'uikit/fields';
import { useContextSelector } from 'use-context-selector';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import {
  useFieldDataFromUiEditorContext,
  useFieldValidation,
  useRefForDataBlockNavigation,
  useSkipReasonText,
} from './base/hooks';
import styles from '../controlsStyle.module.css';
import { ConditionalFieldBehaviorEnum } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { useTranslation } from 'react-i18next';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs';
import { enumToDropdownOptions } from 'uikit/inputs/dropdown/dropdown-helper';
import { SliderColorSchemeEnum } from 'uikit/inputs/slider/appSliderInput';
import { Container } from '../Containers/Container';
import OptionsSettingsSection from '../../toolbox/settingsPanel/content/OptionsSettingsSection';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';

export const SliderInput: ISliderInput = (props) => {
  const {
    size,
    dataKey,
    skipCheckBoxText,
    hasSkipCheckBox,
    isDisableWhenEditing,
    isDisabled,
    minValue,
    maxValue,
    step,
    marksStep,
    startPoint,
    hideMarks,
  } = props;

  const {
    enabled: isConstructorMode,
    actions: { setProp },
  } = useEditor((state) => ({
    enabled: state.options.enabled,
    allNodes: state.nodes,
  }));

  const {
    actions: { setProp: setSelfProp },
    connectors: { connect, drag },
    id,
    linkedNodes,
  } = useNode((state) => ({
    linkedNodes: state.data.linkedNodes,
  }));

  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const {
    isOnlyView,
    isEditMode,
    singleInputValue,
    setDataBlockFieldValue,
    skipReason,
    onSkipReasonChange,
    fieldEditReason,
  } = useFieldDataFromUiEditorContext(dataKey);

  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const blockRef = useRefForDataBlockNavigation();
  const [initialValue] = useState(singleInputValue);
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  // This effect update conditional options state
  useLayoutEffect(() => {
    if (isConstructorMode) return;

    disableNotSelectedOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleInputValue, isDisabled]);

  const isDisabledComponent = useMemo(
    () => !props.isEditable || (isDisableWhenEditing && isEditMode && !!initialValue) || isDisabled || isSubmitting,
    [initialValue, isDisableWhenEditing, isDisabled, isEditMode, isSubmitting, props.isEditable],
  );

  const currentValue = useMemo(() => {
    if (isConstructorMode) {
      return isNullOrEmpty(props.defaultValue as string | undefined) ? undefined : Number(props.defaultValue);
    }

    return isNullOrEmpty(singleInputValue) ? undefined : Number(singleInputValue);
  }, [isConstructorMode, props.defaultValue, singleInputValue]);

  const disableNotSelectedOptions = useCallback(() => {
    const containersIds = Object.values(linkedNodes);
    if (!containersIds) {
      return;
    }

    const enabledOptionKeys: string[] = props.options
      .filter(
        (option) =>
          option.valueRangeCondition &&
          currentValue !== undefined &&
          option.valueRangeCondition[0] <= currentValue &&
          currentValue <= option.valueRangeCondition[1],
      )
      .map((x) => x.key.toString());

    Object.entries(linkedNodes).forEach(([key, containerId]) => {
      setProp(containerId, (p) => (p.isDisabled = isDisabled || !enabledOptionKeys.includes(key)));

      // NOTE: Here was removing values of disabled nodes
      // but it caused that some values were not displayed
      // in editing mode,
      // because it deleted values of other fields,
      // if they had the same DataKey
    });
  }, [currentValue, isDisabled, linkedNodes, props.options, setProp]);

  const setScalarValue = useCallback(
    (newValue: number | undefined) => {
      if (isConstructorMode) {
        setSelfProp((p: ISliderInputProps) => {
          p.defaultValue = newValue?.toString();
        });
      } else {
        setDataBlockFieldValue?.(dataKey, newValue?.toString());
      }
    },
    [isConstructorMode, setSelfProp, setDataBlockFieldValue, dataKey],
  );

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = props.options.filter((x) => x.withConditionalField);

    const finalArray = isConstructorMode
      ? optionsWithConditionalField
      : optionsWithConditionalField.filter((option) => {
          return (
            option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
            currentValue === Number.parseFloat(option.displayValue) ||
            (currentValue !== undefined &&
              option.valueRangeCondition &&
              option.valueRangeCondition[0] <= currentValue &&
              currentValue <= option.valueRangeCondition[1])
          );
        }, true);

    return Array.from(finalArray, (option) => (
      <React.Fragment key={option.key}>
        {isConstructorMode && (
          <div
            className={styles.tabLabel}
          >{`${option.displayValue} (${option.valueRangeCondition?.[0]} - ${option.valueRangeCondition?.[1]})`}</div>
        )}
        <Element key={option.key} id={option.key.toString()} is={Container} canvas={true} />
      </React.Fragment>
    ));
  }, [props.options, isConstructorMode, currentValue]);

  const editableComponent = (
    <div
      data-test-field-type={'scale-input'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef}>
        <AppSliderField
          showMarks={!hideMarks}
          startWith={minValue}
          endWith={maxValue}
          step={step}
          marksStep={marksStep}
          startPoint={startPoint}
          caption={{
            startText: props.captionStartText,
            centerText: props.captionCenterText,
            endText: props.captionEndText,
          }}
          labelProps={{
            text: props.hideLabel ? undefined : props.label || dataKey,
            isBold: props.isBold,
            tooltip: props.tooltip,
          }}
          disabled={isDisabledComponent}
          errorProps={{
            errors: dataBlockValidation?.text,
          }}
          value={currentValue}
          colorScheme={props.colorScheme ?? SliderColorSchemeEnum.SingleColor}
          onChange={setScalarValue}
          showNumberInput={props.showNumberInput}
          skipProps={{
            inputCanBeSkipped: hasSkipCheckBox,
            skipText: skipCheckBoxText,
            onSkipReasonChange: onSkipReasonChange(dataKey),
            skipReason: skipReason,
          }}
        />
        {conditionalContainer}
      </div>
    </div>
  );

  const caption = useMemo(() => {
    const leftText = isNullOrEmpty(props.captionStartText) ? undefined : `${props.minValue}: ${props.captionStartText}`;
    const rightText = isNullOrEmpty(props.captionEndText) ? undefined : `${props.maxValue}: ${props.captionEndText}`;
    return [leftText, rightText].filter(Boolean).length > 0
      ? ` (${[leftText, rightText].filter(Boolean).join(', ')})`
      : undefined;
  }, [props.captionEndText, props.captionStartText, props.maxValue, props.minValue]);

  const notEditableComponent = (
    <>
      <IssueMark
        issueContext={{
          subject: 'Patient',
          topic: 'Records',
          topicAdditional: formConfig?.type,
          linkedPatientUniqId: patient?.patientId,
          fieldDescription: props.label ?? props.dataKey,
          resultId: formResultVersion?.formResultId,
          fieldId: id,
          stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
        }}
        ignoreFieldsForCount={['stepName']}
        position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
      >
        <OverviewField
          label={props.label ?? props.dataKey}
          caption={caption}
          skipped={!singleInputValue && skipReason !== undefined}
          skipReason={skipReasonText}
          value={singleInputValue}
          editReason={fieldEditReason}
        />
      </IssueMark>
      {conditionalContainer.length > 0 && conditionalContainer}
    </>
  );

  return <>{!isOnlyView ? editableComponent : notEditableComponent}</>;
};

const SliderInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as ISliderInputProps,
  }));

  // NOTE: This state allows to erase the "MinValue" field completely
  const [minValue, setMinValue] = useState<number | undefined>(props.minValue);
  useEffect(() => {
    if (minValue === undefined) return;
    setProp((setProps: ISliderInputProps) => {
      setProps.minValue = Math.floor(minValue);
    }, 500);
  }, [minValue, setProp]);

  // NOTE: This state allows to erase the "MaxValue" field completely
  const [maxValue, setMaxValue] = useState<number | undefined>(props.maxValue);
  useEffect(() => {
    if (maxValue === undefined) return;
    setProp((setProps: ISliderInputProps) => {
      setProps.maxValue = Math.floor(maxValue);
    }, 500);
  }, [maxValue, setProp]);

  // NOTE: This state allows to erase the "Step" field completely
  const [stepValue, setStepValue] = useState<number | undefined>(props.step);
  useEffect(() => {
    if (stepValue === undefined) return;
    setProp((setProps: ISliderInputProps) => {
      setProps.step = +stepValue.toFixed(2);
    }, 500);
  }, [stepValue, setProp]);

  // NOTE: This state allows to erase the "Mark's step" field completely
  const [markStepValue, setMarkStepValue] = useState<number | undefined>(props.marksStep);
  useEffect(() => {
    if (markStepValue === undefined) return;
    setProp((setProps: ISliderInputProps) => {
      setProps.marksStep = Math.floor(markStepValue);
    }, 500);
  }, [markStepValue, setProp]);

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.slider.showNumberInput')}
          isChecked={!!props.showNumberInput}
          setValue={(newValue) =>
            setProp((setProps: ISliderInputProps) => {
              setProps.showNumberInput = newValue ? newValue : undefined;
            }, 500)
          }
        />
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.slider.hideMarks')}
          isChecked={!!props.hideMarks}
          setValue={(newValue) =>
            setProp((setProps: ISliderInputProps) => {
              setProps.hideMarks = newValue ? newValue : undefined;
            }, 500)
          }
        />
      </ControlsGroup>
      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.slider.minValue')} flexible>
        <SettingsInput
          type={'number'}
          value={minValue}
          onChange={(e) => {
            setMinValue(isNullOrEmpty(e.target.value) ? undefined : +e.target.value);
          }}
          onBlur={() => setMinValue(props.minValue)}
        />
      </ControlsGroup>
      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.slider.maxValue')} flexible>
        <SettingsInput
          type={'number'}
          value={maxValue}
          onChange={(e) => {
            setMaxValue(isNullOrEmpty(e.target.value) ? undefined : +e.target.value);
          }}
          onBlur={() => setMaxValue(props.maxValue)}
        />
      </ControlsGroup>
      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.slider.step')} flexible>
        <SettingsInput
          type={'number'}
          value={stepValue}
          onChange={(e) => {
            setStepValue(isNullOrEmpty(e.target.value) ? undefined : +e.target.value);
          }}
          onBlur={() => setStepValue(props.step)}
        />
      </ControlsGroup>
      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.slider.startPoint')} flexible>
        <SettingsInput
          type={'number'}
          value={props.startPoint}
          onChange={(e) => {
            setProp((p: ISliderInputProps) => {
              p.startPoint = isNullOrEmpty(e.target.value) ? undefined : +e.target.value;
              p.defaultValue = e.target.value;
            }, 500);
          }}
        />
      </ControlsGroup>
      {!props.hideMarks && (
        <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.slider.markStep')} flexible>
          <SettingsInput
            type={'number'}
            value={markStepValue}
            onChange={(e) => {
              setMarkStepValue(isNullOrEmpty(e.target.value) ? undefined : +e.target.value);
            }}
            onBlur={() => setMarkStepValue(props.marksStep)}
          />
        </ControlsGroup>
      )}
      <ControlsGroup
        flexible
        collapsible
        expanded={!!props.captionStartText}
        header={t('StudySettingsPage.FormBuilder.settings.slider.captionStartText')}
        onOpen={() => {
          setProp((prop: ISliderInputProps) => {
            prop.captionStartText = 'Left text';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ISliderInputProps) => {
            prop.captionStartText = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          value={props.captionStartText}
          onChange={(e) => {
            setProp((setProps: ISliderInputProps) => {
              setProps.captionStartText = e.target.value;
            }, 500);
          }}
        />
      </ControlsGroup>
      <ControlsGroup
        flexible
        collapsible
        expanded={!!props.captionCenterText}
        header={t('StudySettingsPage.FormBuilder.settings.slider.captionCenterText')}
        onOpen={() => {
          setProp((prop: ISliderInputProps) => {
            prop.captionCenterText = 'Center text';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ISliderInputProps) => {
            prop.captionCenterText = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          value={props.captionCenterText}
          onChange={(e) => {
            setProp((setProps: ISliderInputProps) => {
              setProps.captionCenterText = e.target.value;
            }, 500);
          }}
        />
      </ControlsGroup>
      <ControlsGroup
        flexible
        collapsible
        expanded={!!props.captionEndText}
        header={t('StudySettingsPage.FormBuilder.settings.slider.captionEndText')}
        onOpen={() => {
          setProp((prop: ISliderInputProps) => {
            prop.captionEndText = 'Right text';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ISliderInputProps) => {
            prop.captionEndText = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          value={props.captionEndText}
          onChange={(e) => {
            setProp((setProps: ISliderInputProps) => {
              setProps.captionEndText = e.target.value;
            }, 500);
          }}
        />
      </ControlsGroup>
      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.slider.colorScheme')} flexible>
        <AppDropDownWithSuggestionInput
          isRequired={true}
          options={enumToDropdownOptions(SliderColorSchemeEnum)}
          value={
            enumToDropdownOptions(SliderColorSchemeEnum).find(
              (x) => x.key === SliderColorSchemeEnum[props.colorScheme],
            ) ??
            enumToDropdownOptions(SliderColorSchemeEnum).find(
              (x) => x.key === SliderColorSchemeEnum.SingleColor.toString(),
            )
          }
          onChange={(x) => {
            setProp((setProps: ISliderInputProps) => {
              setProps.colorScheme = SliderColorSchemeEnum[x!.key];
            });
          }}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const SliderInputSettings = () => {
  const { props } = useNode((node) => ({
    props: node.data.props as ISliderInputProps,
  }));
  return (
    <>
      <TitleSettings />
      <SliderInputGeneralSettings />
      <OptionsSettingsSection
        variant={'slider'}
        minValue={props.minValue}
        maxValue={props.maxValue}
        hasVariable={false}
      />
      <LayoutSettings />
      <ValidationSettings applicableRules={sliderInputApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

SliderInput.craft = {
  props: radioInputDefaultPropsFactory(),
  related: {
    settings: SliderInputSettings,
  },
};
