import MatomoTracker from '@datapunt/matomo-tracker-js';
import { TrackEventParams, TrackPageViewParams } from '@datapunt/matomo-tracker-js/lib/types';
import { logger } from '../../logging/logging';

let analyticTracker: MatomoTracker | null;

export function initMatomo(url: string, siteId: number) {
  analyticTracker = new MatomoTracker({
    urlBase: url,
    siteId: siteId,
    heartBeat: {
      // optional, enabled by default
      active: false, // optional, default value: true
    },
    linkTracking: false, // optional, default value: true
    configurations: {
      // any valid matomo configuration
    },
  });

  analyticTracker.trackPageView();
  logger().info('Matomo has been initialized');
}

export function trackEvent(params: TrackEventParams) {
  analyticTracker?.trackEvent(params);
}

export function trackPageView(params: TrackPageViewParams) {
  analyticTracker?.trackPageView(params);
}
