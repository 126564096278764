import React from 'react';
import { RPDF } from '../../react-pdf';
import { ReportTableHeaderColumn } from './reportTable';

export const TableHeaderColumn: React.FC<ReportTableHeaderColumn> = (props: ReportTableHeaderColumn) => {
  return (
    <RPDF.View style={props.styles}>
      <RPDF.Text style={[RPDF.typography.heading5, props.textStyles]}>{props.label}</RPDF.Text>
    </RPDF.View>
  );
};
