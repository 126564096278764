import React from 'react';
import clsx from 'clsx';
import { DateRangeProps } from './daterange';
import { useTranslation } from 'react-i18next';
import styles from './daterange.module.css';
import { AppInputError } from 'uikit/wrappers';
import { AppInputLabel } from 'uikit/wrappers';
import { AppInputSkip } from 'uikit/wrappers';
import { AppTextInput } from 'uikit/inputs';

export const DateRangeComponent = (props: DateRangeProps) => {
  const { t } = useTranslation();
  const {
    onStartChange,
    onEndChange,
    onStartDateBlur,
    onEndDateBlur,
    startDate,
    endDate,
    label,
    disabled,
    startDateErrorText,
    endDateErrorText,
    tooltip,
    skipProps,
  } = props;

  return (
    <div className={clsx(styles.container)}>
      <AppInputError errors={startDateErrorText || endDateErrorText}>
        <AppInputLabel disabled={disabled} isBold={props.isBold} text={label} tooltip={tooltip}>
          <AppInputSkip
            inputCanBeSkipped={!!skipProps && skipProps?.inputCanBeSkipped}
            disabled={disabled}
            skipText={skipProps?.skipText}
            showReasons={skipProps?.showReasons}
            skipReason={skipProps?.skipReason}
            onSkipReasonChange={skipProps?.onSkipReasonChange ?? (() => {})}
          >
            <div className={styles.wrapContainer}>
              <AppTextInput
                placeholder={t('Common_From')}
                type={'date'}
                disabled={disabled}
                onChange={onStartChange}
                value={startDate || ''}
                max={endDate}
                invalid={!!startDateErrorText}
                onBlur={onStartDateBlur}
              />

              <span className={styles.divider}>—</span>

              <AppTextInput
                placeholder={t('Common_Until')}
                type={'date'}
                disabled={disabled}
                value={endDate || ''}
                onChange={onEndChange}
                min={startDate}
                invalid={!!endDateErrorText}
                onBlur={onEndDateBlur}
              />
            </div>
          </AppInputSkip>
        </AppInputLabel>
      </AppInputError>
    </div>
  );
};
