import React, { useMemo } from 'react';
import { useSkipReasonText } from 'src/components/formEditor/controls/Inputs/base/hooks';
import { useContextSelector } from 'use-context-selector';
import { ICheckBoxGroupInput } from '../../../../forms/base/controls/inputs/CheckBoxGroupInput';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';
import { ConditionalFieldBehaviorEnum } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import _ from 'lodash';
import { useNode } from '@craftjs/core';
import { renderNode } from '../../recordReport-helper';
import { recordReportFormControlsResolver } from '../../recordReport.resolver';
import { RPDF } from 'src/features/report/react-pdf';

export const CheckBoxGroupInput: ICheckBoxGroupInput = ({ label, dataKey, options, skipCheckBoxText }) => {
  const { id } = useNode();

  const nodes = useContextSelector(RecordContext, (v) => v.nodes);
  const linkedNodes = nodes.find((n) => n.id === id)?.linkedNodes;

  const inputValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  const skipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.[dataKey]);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const displayValues = useMemo(() => {
    return options.filter((x) => multipleInputValue?.includes(x.value)).map((x) => x.displayValue);
  }, [multipleInputValue, options]);

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = options.filter((x) => x.withConditionalField);

    const finalArray = optionsWithConditionalField.filter(
      (option) =>
        option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
        multipleInputValue?.includes(option.value),
    );

    return Array.from(finalArray, (option) => {
      const childId = _.get(linkedNodes, option.key);
      if (!childId) {
        return <RPDF.View key={option.key} />;
      }

      return <RPDF.View key={option.key}>{renderNode(nodes, recordReportFormControlsResolver, childId)}</RPDF.View>;
    });
  }, [options, multipleInputValue, linkedNodes, nodes]);

  return (
    <>
      <Field label={label ?? dataKey} skipReason={skipReasonText} value={displayValues} />
      {conditionalContainer.length > 0 && conditionalContainer}
    </>
  );
};
