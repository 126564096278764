import { IPatientListDto, ISurveyDto, SurveyType, TableColumnsEnum } from '../../services/api/api-client';
import { ColumnDef } from '@tanstack/react-table';
import {
  useCategoryColumn,
  useIndicatorColumns,
  useLastNoteColumn,
  useRecentChangesColumn,
  useStageColumn,
  useSurveyColumns,
  useUniqueIdColumn,
} from './columns/columns.constants';
import { conditionalConcat } from '../../helpers/arrayHelpers';
import { expandRowButtonColumn } from 'uikit/table/columns/ExpandRowButtonColumn';
import { useMemo } from 'react';

export type PatientTableColumnsOptions = {
  onClick?: (survey: SurveyType, patient: string, headerTitle: string, answId?: number | null) => void;
  tableColumns: TableColumnsEnum;
  surveys: ISurveyDto[];
};

export const usePatientTableColumns = (options: PatientTableColumnsOptions): ColumnDef<IPatientListDto>[] => {
  let nextIsColored = false;
  const getIsColored = () => {
    const res = nextIsColored;
    nextIsColored = !nextIsColored;
    return res;
  };

  const columnOptions = {
    getIsColored,
    tableColumns: options.tableColumns,
  };

  const uniqueIdColumn = useUniqueIdColumn(columnOptions);
  const indicatorColumns = useIndicatorColumns(columnOptions);
  const surveyColumns = useSurveyColumns({ ...columnOptions, onClick: options.onClick, surveys: options.surveys });
  const categoryColumn = useCategoryColumn(columnOptions);
  const stageColumn = useStageColumn(columnOptions);
  const lastNoteColumn = useLastNoteColumn(columnOptions);
  const recentChangesColumn = useRecentChangesColumn(columnOptions);

  return useMemo(() => {
    return conditionalConcat(
      [uniqueIdColumn],
      indicatorColumns,
      surveyColumns,
      categoryColumn && [categoryColumn],
      stageColumn && [stageColumn],
      lastNoteColumn && [lastNoteColumn],
      recentChangesColumn && [recentChangesColumn],
      [expandRowButtonColumn({})],
    );
  }, [
    categoryColumn,
    indicatorColumns,
    lastNoteColumn,
    recentChangesColumn,
    stageColumn,
    surveyColumns,
    uniqueIdColumn,
  ]);
};
