﻿import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

const Portal: FC<
  PropsWithChildren<{
    /** @default document.body */
    element?: Element;
  }>
> = ({ children, element }) => {
  return createPortal(children, element ?? document.body);
};

export default Portal;
