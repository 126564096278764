import { AnswersTableRowProps } from '../../../../containers/surveyAnswers/surveyAnswers';
import React, { useMemo } from 'react';
import { RPDF } from '../../react-pdf';
import { getQuestionById, groupOptionsByInnerScheme, isConditionalOption } from '../../../../helpers/question-helper';
import { ExpandedRowProps } from '../../../../containers/surveyAnswers/expandedRow.component';
import _ from 'lodash';
import { Style } from '@react-pdf/types/style';
import { SurveyReportAnswerContent } from './SurveyReportAnswerContent';

export type SurveyAnswerProps = Omit<AnswersTableRowProps, 'swipeIndex' | 'viewMode' | 'containerStyles' | 'index'> & {
  index?: number;
  containerStyle?: Style;
  questionStyle?: Style;
  answerStyle?: Style;
};

export const SurveyAnswerRow: React.FC<SurveyAnswerProps> = (props: SurveyAnswerProps) => {
  const { answers, index, parentQuestion, question, containerStyle } = props;

  return (
    <>
      <RPDF.View wrap={false} style={[RPDF.controlStyles.recordRow, containerStyle || {}]} key={question.id}>
        <RPDF.View style={[{ gap: 8, flexDirection: 'row', flex: 2 }, props.questionStyle || {}]}>
          <RPDF.Text style={RPDF.controlStyles.counter}>{index}</RPDF.Text>
          <RPDF.Text style={RPDF.controlStyles.questionCell}>{question.questionText}</RPDF.Text>
        </RPDF.View>
        <RPDF.Text style={[RPDF.controlStyles.answerCell, props.answerStyle || {}]}>
          <SurveyReportAnswerContent answers={answers} question={question} parentQuestion={parentQuestion} />
        </RPDF.Text>
      </RPDF.View>

      {Object.entries(groupOptionsByInnerScheme(question.options.filter(isConditionalOption))).map(([key, options]) => {
        return (
          <SurveyReportConditionalAnswerRow key={key} question={question} groupedOptions={options} answers={answers} />
        );
      })}
    </>
  );
};

type SurveyReportConditionalAnswerRowProps = Omit<ExpandedRowProps, 'swipeIndex' | 'viewMode'>;
export const SurveyReportConditionalAnswerRow = (props: SurveyReportConditionalAnswerRowProps) => {
  const { groupedOptions, answers, question } = props;

  const isSelectedOption = useMemo(() => {
    const firstScheme = _.first(answers);
    if (!answers || !answers.length || !firstScheme) {
      return false;
    }

    const questionFromAnswerScheme = getQuestionById(firstScheme, question.id);
    if (!questionFromAnswerScheme) {
      return false;
    }

    const answeredOption = questionFromAnswerScheme.options.find((o) => o.answerValue !== null);
    if (!answeredOption) {
      return false;
    }

    return groupedOptions.map((o) => o.optionValue).includes(answeredOption.optionValue);
  }, [answers, groupedOptions, question.id]);

  const accordionContent = useMemo(
    () =>
      _.first(groupedOptions)?.questionScheme!.questions?.map((q, i) => (
        <RPDF.View key={i}>
          <SurveyAnswerRow
            index={i + 1}
            parentQuestion={question}
            question={q}
            answers={answers}
            containerStyle={{ paddingLeft: 20 }}
          />
        </RPDF.View>
      )),
    [groupedOptions, question, answers],
  );

  if (!isSelectedOption) {
    return <></>;
  }

  return <>{accordionContent}</>;
};
