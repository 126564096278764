import { defaultToolSettings } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { createId } from 'src/helpers/typeUtils';
import {
  ApplicableRules,
  ValidationDefaultSettings,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import { UserComponent } from '@craftjs/core';

export interface IDateInputProps extends IFormFieldProps {
  /** @deprecated This prop was used as label text before and replaced by {@link IFormFieldProps.label label}.
   *  The prop was left for backward compatibility. */
  text?: string;
  placeholder?: string;
  defaultValueIsNow?: boolean;
}

export type IDateInput = UserComponent<IDateInputProps>;

export function dateInputDefaultPropsFactory(): IDateInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    validation: {
      ...ValidationDefaultSettings,
    },
  };
}

export const dateInputApplicableValidationRules: ApplicableRules = {
  isRequired: true,
  maxDate: true,
  minDate: true,
};
