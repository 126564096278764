﻿import React, { FC, PropsWithChildren, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Transition } from 'react-transition-group';
import styles from './Collapse.module.scss';

export type CollapseProps = PropsWithChildren<{
  in: boolean;
  unmountOnExit?: boolean;
  className?: string;
}>;

// Source: https://github.com/mui/material-ui/blob/ec5df5f94f472628103eb1cfbecccc6afc2658bd/packages/mui-material/src/styles/createTransitions.js#L35C41-L35C41
function getTransitionDuration(height: number) {
  if (!height) return 0;
  const constant = height / 36;
  // https://www.wolframalpha.com/input/?i=(4+%2B+15+*+(x+%2F+36+)+**+0.25+%2B+(x+%2F+36)+%2F+5)+*+10
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}

export const Collapse: FC<CollapseProps> = (props) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const transitionDuration = useRef<number>();
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    return () => {
      if (timer.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(timer.current);
    };
  }, []);

  const getWrapperSize = () => wrapperRef.current?.clientHeight ?? 0;

  const onEnter = () => {
    const node = nodeRef.current!;
    node.style.height = '0';
  };

  const onEntering = () => {
    const wrapperSize = getWrapperSize();
    const node = nodeRef.current!;
    const duration = getTransitionDuration(wrapperSize);
    transitionDuration.current = duration;
    node.style.transitionDuration = `${duration}ms`;
    node.style.height = `${wrapperSize}px`;
  };

  const onEntered = () => {
    const node = nodeRef.current!;
    node.style.height = 'auto';
  };

  const onExit = () => {
    const wrapperSize = getWrapperSize();
    const node = nodeRef.current!;
    node.style.height = `${wrapperSize}px`;
  };

  const onExiting = () => {
    const node = nodeRef.current!;
    const wrapperSize = getWrapperSize();
    const duration = getTransitionDuration(wrapperSize);
    transitionDuration.current = duration;
    node.style.height = '0';
    node.style.transitionDuration = `${duration}ms`;
  };

  const addEndListener = (next: () => void) => {
    timer.current = setTimeout(next, transitionDuration.current ?? 0);
  };

  return (
    <Transition
      in={props.in}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      nodeRef={nodeRef}
      addEndListener={addEndListener}
      mountOnEnter={props.unmountOnExit}
      unmountOnExit={props.unmountOnExit}
    >
      {(state) => (
        <div
          ref={nodeRef}
          className={clsx(styles.collapse, {
            [styles.entered]: state === 'entered',
            [styles.exited]: state === 'exited' && !props.in,
          })}
        >
          <div className={styles.wrapper} ref={wrapperRef}>
            <div className={styles.wrapperInner}>{props.children}</div>
          </div>
        </div>
      )}
    </Transition>
  );
};
