import { ITimeInput } from '../../../../../features/forms/base/controls/inputs/TimeInput';
import React, { useContext } from 'react';
import { NoteContext } from '../../NoteContext';
import { NoteField } from '../../NoteField';

export const TimeInput: ITimeInput = (props) => {
  const noteContext = useContext(NoteContext);
  const inputValue = noteContext.currentResultVersion.value?.[props.dataKey] as string | string[] | undefined | null;
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  if (!singleInputValue) return <></>;

  return <NoteField label={props.label ?? props.dataKey} value={singleInputValue} />;
};
