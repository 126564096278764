import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { IssueCard } from '../../../components/issue/card/issueCard.component';
import { QueryFactory } from '../../../services/api';
import Style from './issuesBar.module.css';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { Permissions } from 'src/services/api/api-client';
import { FloatingButton } from '../../uikit/buttons/floatingButton/floatingButton.component';
import { AppButton } from '../../uikit/buttons/button/button.component';
import { t } from 'i18next';
import { TypographyStyles } from '../../../styles';
import { IssueFilters } from './issueFilters.component';
import { defaultIssueQueryParams, IssueQueryParamsType } from './issueFilters-helper';
import { ReactComponent as OpenIssueIcon } from '../../../assets/img/issues/open_issue_32.svg';
import { ReactComponent as CrossIcon } from '../../../assets/img/cross.svg';
import { ReactComponent as IssuePlus } from '../../../assets/img/issues/add_issue_40.svg';
import { ReactComponent as NoIssues } from '../../../assets/img/issues/no_issues.svg';
import { ReactComponent as SelectedIssueIcon } from '../../../assets/img/issues/selected_issue_16.svg';
import { IssuesContext } from '../provider/issues.context';
import { useContextSelector } from 'use-context-selector';
import { SortOrder } from '../../../services/api/api-client';
import { Loading } from 'uikit/suspense/Loading';
import { AppSwitcherInput } from 'uikit/inputs';
import { patientFilterParams } from 'src/components/patientTable/patientFilters.helpers';
import { useQueryParams, NumberParam } from 'use-query-params';
import { useGetIssueQuery } from 'src/services/api/api-client/IssueQuery';
import { useStudy } from 'src/helpers/hooks/useStudy';

const LIMIT_SIZE = 20;

export const IssuesBar = () => {
  const queryClient = useQueryClient();
  const { id: studyId, closingApprovedAt } = useStudy() ?? {};
  const { createIssue, isIssuesVisible, setIssuesVisible } = useContextSelector(IssuesContext, (x) => x);

  const didMountRef = useRef(false);
  const [queryParams, setQueryParams] = useState<IssueQueryParamsType>(defaultIssueQueryParams);
  const [patientQueryParams] = useQueryParams({
    ...patientFilterParams(),
    issueId: NumberParam,
  });

  const infiniteQueryResult = useInfiniteQuery(
    QueryFactory.IssueQuery.getIssuesForStudyQueryKey(studyId ?? 0),
    ({ pageParam = 0 }) =>
      QueryFactory.IssueQuery.Client.getIssuesForStudy(
        studyId ?? 0,
        queryParams.patientUid ? queryParams.patientUid : undefined,
        undefined,
        queryParams.state,
        queryParams.subjectList,
        queryParams.patientGroupList,
        queryParams.unreadOnly || undefined,
        pageParam,
        LIMIT_SIZE,

        queryParams.sortBy,
        queryParams.desc ? SortOrder.Desc : SortOrder.Asc,
      ),
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * LIMIT_SIZE < lastPage.totalCount) {
          return pages.length * LIMIT_SIZE;
        }

        return undefined;
      },
      suspense: false,
      enabled: studyId !== null,
    },
  );

  // This effect refetch data when queryParams is changed
  useEffect(() => {
    // Prevent on mount refetch
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    if (infiniteQueryResult.isFetching) {
      queryClient.cancelQueries(QueryFactory.IssueQuery.getIssuesForStudyQueryKey(studyId!));
    }

    infiniteQueryResult.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const { data: selectedIssue } = useGetIssueQuery(
    { id: patientQueryParams.issueId! },
    { enabled: !!patientQueryParams.issueId, suspense: false },
  );

  const issueList = useMemo(
    () => infiniteQueryResult.data?.pages.flatMap((x) => x.data),
    [infiniteQueryResult.data?.pages],
  );

  const [isOpen, setIsOpen] = useState(false);

  const onScroll = useCallback(
    async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (scrollTop + clientHeight + 20 >= scrollHeight && infiniteQueryResult.hasNextPage) {
        if (infiniteQueryResult.isFetchingNextPage) return;
        await infiniteQueryResult.fetchNextPage();
      }
    },
    [infiniteQueryResult],
  );

  const hasNewIssues = useMemo(() => {
    return issueList?.some((i) => i.isNew);
  }, [issueList]);

  const hasNoFilteredData = useMemo(() => {
    return (
      !issueList?.length &&
      (queryParams.state ||
        queryParams.patientUid ||
        queryParams.patientGroupList ||
        queryParams.unreadOnly ||
        (queryParams.subjectList && queryParams.subjectList?.length > 0))
    );
  }, [
    issueList?.length,
    queryParams.patientGroupList,
    queryParams.patientUid,
    queryParams.state,
    queryParams.subjectList,
    queryParams.unreadOnly,
  ]);

  const placeholder = useMemo(() => {
    return (
      <div className={Style.placeholder}>
        <NoIssues />
        <span className={TypographyStyles.plainText14}>{t('Issues.Filters.NoIssuesText')}</span>
      </div>
    );
  }, []);

  return (
    <>
      <div className={clsx(Style.container, { [Style.hide]: !isOpen })} data-test-id={'issue-bar'}>
        <div className={Style.title}>
          <div className={Style.titleWithSwitcher}>
            <div className={TypographyStyles.buttonLinkText} data-test-id={'issue-bar-title'}>
              {t('Issues.BarTitle')}
            </div>
            <AppSwitcherInput checked={isIssuesVisible} onChange={(e) => setIssuesVisible?.(e.target.checked)} />
          </div>

          <AppButton
            onClick={() => setIsOpen(false)}
            variant={'icon-link'}
            colorSchema={'basic'}
            Icon={CrossIcon}
            testId={'issue-bar-close-button'}
          />
        </div>

        <IssueFilters
          queryParams={queryParams}
          onChange={(params: IssueQueryParamsType) => {
            setQueryParams((prev) => {
              return {
                ...prev,
                ...params,
              };
            });
          }}
        />

        {!issueList?.some((x) => x.id === selectedIssue?.id) && selectedIssue && (
          <IssueCard {...selectedIssue} disabled={infiniteQueryResult.isRefetching} />
        )}

        <div onScroll={onScroll} className={Style.scrollableContent}>
          {hasNoFilteredData ? (
            placeholder
          ) : (
            <>
              <div
                className={clsx(Style.issuesList, {
                  [Style.opacity]: infiniteQueryResult.isRefetching,
                })}
              >
                {issueList &&
                  issueList.map((dto) => (
                    <div key={dto.id} className={Style.issueCardWrapper}>
                      <IssueCard
                        {...(selectedIssue?.id === dto.id ? selectedIssue : dto)}
                        disabled={infiniteQueryResult.isRefetching}
                      />
                    </div>
                  ))}
              </div>
              <Loading
                loading={infiniteQueryResult.isFetchingNextPage && !!infiniteQueryResult.hasNextPage}
                testId={'issue-bar'}
                containerClassName={Style.loading}
              />
            </>
          )}

          {!closingApprovedAt && (
            <PermissionsCheck permissions={Permissions.IssueCreate}>
              <FloatingButton
                Icon={IssuePlus}
                className={Style.addButton}
                onClick={() => createIssue?.(undefined)}
                testId={'issue-bar-add-button'}
              />
            </PermissionsCheck>
          )}
        </div>
      </div>

      <button className={Style.openButton} onClick={() => setIsOpen(true)} data-test-id={'issue-bar-open-button'}>
        <div className={Style.newIssuesIndicator}>
          {hasNewIssues && <div data-test-id={'issue-bar-open-button-blue-mark'} />}
          {!!patientQueryParams.issueId && <SelectedIssueIcon className={Style.selectedIssueIcon} />}
          <OpenIssueIcon />
        </div>
      </button>
    </>
  );
};
