import React, { useCallback, useMemo, useRef, useState, FC } from 'react';
import Style from './accountInfo.module.scss';
import { MetricActions } from '../../application/analitics/matomo/matomo-actions';
import { MetricCategories } from '../../application/analitics/matomo/matomo-categories';
import { trackEvent } from '../../application/analitics/matomo/matomo-tracker';
import { IDoctorProfileDto } from '../../services/api/api-client';
import clsx from 'clsx';
import { AppButton } from 'uikit/buttons';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { useOnClickOutside } from '../../application/hooks/useClickOutside';
import { logOut } from '../../helpers/auth/auth-interceptor';
import { useUserRoles } from 'src/helpers/auth/auth-helper';

export const AccountInfoComponent: FC<{
  profile: IDoctorProfileDto | undefined;
}> = (props) => {
  const { profile } = props;
  const { t } = useScopedTranslation('NavMenu');
  const [expanded, setExpended] = useState<boolean>(false);
  const ref = useRef(null);
  const roles = useUserRoles();
  const initials = useMemo(() => {
    return profile?.fullName
      .split(' ')
      .map((x) => x.substring(0, 1))
      .join('')
      .toUpperCase();
  }, [profile?.fullName]);

  const onLogout = useCallback(async () => {
    await logOut();
    trackEvent({
      category: MetricCategories.Application,
      action: MetricActions.Logout,
    });
  }, []);

  useOnClickOutside(ref, () => {
    setExpended(false);
  });

  return (
    <div ref={ref} className={Style.container} data-test-id={'account-info'}>
      <button className={clsx(Style.account, { [Style.expanded]: expanded })} onClick={() => setExpended((x) => !x)}>
        {initials}
      </button>

      {expanded && (
        <div data-test-id={'account-menu'} className={Style.callout}>
          <div className={Style.infoContainer} data-test-id={'username-block'}>
            <p>{profile?.fullName}</p>
            {profile?.email && <p>{profile?.email}</p>}
            {roles?.map((x) => (
              <p key={x}>{x}</p>
            ))}
          </div>

          <AppButton
            className={Style.logoutButton}
            variant={'icon-link'}
            colorSchema={'primary'}
            onClick={onLogout}
            text={t('Exit')}
            testId={'logout-button'}
          />
        </div>
      )}
    </div>
  );
};
