import { useEditor } from '@craftjs/core';
import { useLayer } from '@craftjs/layers';
import React from 'react';
import { EditableLayerName } from './editableLayerName';
import Style from './customLayer.module.css';
import clsx from 'clsx';

import { ReactComponent as Eye } from '../../../assets/img/faqBuilder/layers/eye.svg';
import { ReactComponent as Chain } from '../../../assets/img/faqBuilder/layers/chain.svg';

export const DefaultLayerHeader: React.FC = () => {
  const {
    id,
    depth,
    expanded,
    children,
    connectors: { drag, layerHeader },
    actions: { toggleLayer },
  } = useLayer((layer) => {
    return {
      expanded: layer.expanded,
    };
  });

  const { hidden, actions, selected, isLinkedNode } = useEditor((state, query) => {
    return {
      hidden: state.nodes[id] && state.nodes[id].data.hidden,
      selected: query.getEvent('selected').first() === id,
      isLinkedNode: query.node(id).isLinkedNode(),
    };
  });

  return (
    <div
      ref={(ref) => ref && !isLinkedNode && drag(ref)}
      className={clsx(Style.header, { [Style.selected]: selected, [Style.hidden]: hidden })}
    >
      <div onClick={() => actions.setHidden(id, !hidden)}>
        <Eye className={Style.icon} />
      </div>
      <div ref={(ref) => ref && layerHeader(ref)} className={Style.inner} style={{ marginLeft: depth * 20 }}>
        <div className={Style.layerName}>
          <EditableLayerName />
        </div>

        <div className={Style.chainIconWrapper}>{isLinkedNode && <Chain className={Style.icon} />}</div>

        {children && children.length > 0 && (
          <div className={Style.collapsible} onMouseDown={() => toggleLayer()}>
            {expanded ? '-' : '+'}
          </div>
        )}
      </div>
    </div>
  );
};
