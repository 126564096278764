import { UserComponent } from '@craftjs/core';
import { IFormFieldProps } from './base/FormFieldProps';
import { defaultToolSettings } from './base/baseControlSettings';
import { createId } from 'src/helpers/typeUtils';
import {
  ApplicableRules,
  ValidationDefaultSettings,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';

export type INumberInputProps = IFormFieldProps & {
  placeholder?: string;
  isSkipped?: boolean;
  hasSkipCheckBox?: boolean;
  units?: string;
  decimal?: number;
};

export type INumberInput = UserComponent<INumberInputProps>;

export function numberInputDefaultPropsFactory(): INumberInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    validation: { ...ValidationDefaultSettings },
  };
}

export const numberApplicableValidationRules: ApplicableRules = {
  isRequired: true,
  maxValue: true,
  minValue: true,
  pattern: true,
};
