import clsx from 'clsx';
import React, { useMemo } from 'react';
import { TypographyStyles } from 'src/styles';
import Style from './surveyTableRow.module.css';
import { ScoreTableRowProps } from './surveyAnswers';
import { getScoreStyleColor } from 'src/helpers/color-helper';
import { SwiperSlide } from 'swiper/react';
import { AppSwiper } from 'src/components/swiper/appSwiper.component';
import { slidesPerView, slideWidthPx } from './constants';
import { QualityMetric, ScoreCalculationStatusEnum } from '../../services/api/api-client';
import { Tooltip } from '../../components/uikit/tooltip/tooltip.component';
import { ReactComponent as EmptyScore } from '../../assets/img/survey/empty_survey_score_32.svg';
import { formatScoreValue } from 'src/helpers/question-helper';
import { ErrorScoreCalculation } from '../../components/errorScoreCalculation/errorScoreCalculation.component';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';

export const ScoreTableRow = (props: ScoreTableRowProps) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection');

  const scoreComponentList = props.scores?.map((score, i) => {
    // Missed score
    if (!score?.answerId) {
      return (
        <SwiperSlide
          key={i}
          className={clsx(Style.answer, Style.missedAnswer, Style.empty, {
            [Style.singleAnswer]: props.isSingleAnswer,
          })}
        >
          {t('LastAvailableStats.Missed')}
        </SwiperSlide>
      );
    }

    // No calculated score
    if (score.quality === QualityMetric.NotCalculated) {
      return (
        <SwiperSlide
          key={i}
          className={clsx(Style.answer, getScoreStyleColor(score.quality), {
            [Style.singleAnswer]: props.isSingleAnswer,
          })}
        >
          <div className={Style.tooltipWrapper}>
            <Tooltip
              text={t('LastSurveyModal.NotCalculatedTooltip')}
              styles={Style.noScoreTooltip}
              textStyles={Style.tooltipText}
            >
              <EmptyScore />
            </Tooltip>
          </div>
        </SwiperSlide>
      );
    }

    const renderScoreComponent = () => {
      if (score.scoreStatus === ScoreCalculationStatusEnum.Done) {
        return formatScoreValue(score?.score);
      }

      if (
        score.scoreStatus === ScoreCalculationStatusEnum.Error ||
        score.scoreStatus === ScoreCalculationStatusEnum.InProcess
      ) {
        return (
          <ErrorScoreCalculation
            retryHandlerType={'RetrySurvey'}
            answerId={score.answerId}
            canRetry={score.canRetryScoreCalculation && score.answerId !== null}
            isInProcess={score.scoreStatus === ScoreCalculationStatusEnum.InProcess}
          />
        );
      }

      return <></>;
    };

    return (
      <SwiperSlide
        key={i}
        className={clsx(Style.answer, getScoreStyleColor(score.quality), {
          [Style.singleAnswer]: props.isSingleAnswer,
        })}
      >
        {renderScoreComponent()}
      </SwiperSlide>
    );
  });

  const colorStyleForLastColumn = useMemo(() => {
    const swipeIndex = props.swipeIndex ?? 0;
    let index = props.scores.length > slidesPerView ? swipeIndex + slidesPerView : props.scores.length;

    if (index > props.scores.length) {
      index = props.scores.length;
    }

    return getScoreStyleColor(props.scores[index - 1]?.quality);
  }, [props.scores, props.swipeIndex]);

  return (
    <div className={clsx(TypographyStyles.plainText14, Style.container, Style.scoreRow, props.containerClassName)}>
      <div className={clsx(Style.row)}>
        <div className={Style.index} />
        <div className={clsx(Style.questionTextContainer)}>
          <div className={Style.questionText}>{props.text}</div>
        </div>
        <AppSwiper
          slideWidth={slideWidthPx}
          slidesPerView={slidesPerView}
          containerStyles={Style.answersContainer}
          swipeIndex={props.swipeIndex}
        >
          {scoreComponentList}
        </AppSwiper>
        <div className={clsx(Style.lastColumn, colorStyleForLastColumn)} />
      </div>
    </div>
  );
};
