import React, { useCallback } from 'react';
import ContentEditable from 'react-contenteditable';
import { useNode, useEditor } from '@craftjs/core';
import { TextSettings } from './textSettings';
import { useNodeRef } from '../../hooks/useNodeRef';

export type TextProps = {
  fontSize: string;
  textAlign: string;
  fontWeight: string;
  color: string;
  text: string;
  margin: number[];
};

const defaultProps = {
  fontSize: '18',
  textAlign: 'left',
  fontWeight: '400',
  color: '#001311',
  text: 'Text',
  margin: [0, 0, 0, 0],
};

export const Text = (props: Partial<TextProps>) => {
  props = {
    ...defaultProps,
    ...props,
  };

  const { fontSize, textAlign, fontWeight, color, text, margin } = props;

  const {
    connectors: { connect },
    setProp,
    id,
  } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const ref = useNodeRef(id);

  // Prevent pasting of HTML code and styles
  const onPast = useCallback((ev: React.ClipboardEvent<HTMLDivElement>) => {
    ev.preventDefault();
    const t = ev.clipboardData.getData('text');
    document.execCommand('insertText', false, t);
  }, []);

  return (
    <div style={{ width: '100%' }} ref={ref}>
      <ContentEditable
        onPaste={onPast}
        innerRef={connect}
        // @ts-ignore
        html={text}
        disabled={!enabled}
        onChange={(e) => {
          setProp((prop) => (prop.text = e.target.value), 500);
        }}
        tagName="div"
        style={{
          margin: margin ? `${margin[0]}px ${margin[1]}px ${margin[2]}px ${margin[3]}px` : '',
          color: color,
          fontSize: `${fontSize}px`,
          fontWeight,
          textAlign,
        }}
      />
    </div>
  );
};

Text.craft = {
  displayName: 'Text',
  props: defaultProps,
  related: {
    toolbar: TextSettings,
  },
};
