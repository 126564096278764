﻿import { CellContext, ColumnDef } from '@tanstack/react-table';
import React, { FunctionComponent, SVGProps } from 'react';
import styles from './ButtonColumn.module.scss';
import clsx from 'clsx';

export type ButtonColumnColorSchema = 'primary' | 'secondary';

export interface ButtonColumnOptions<TRow> {
  id: string;
  onClick: (cell: CellContext<TRow, unknown>) => void;
  icon: FunctionComponent<SVGProps<any>>;
  /** @default secondary */
  colorSchema?: ButtonColumnColorSchema;
  disabled?: (cell: CellContext<TRow, unknown>) => boolean;
  visible?: (cell: CellContext<TRow, unknown>) => boolean;
  className?: string | ((cell: CellContext<TRow, unknown>) => string | undefined);
  iconClassName?: string;
}

// TODO: Add Tooltip if button is disabled (no design yet)
export function buttonColumn<TRow>(options: ButtonColumnOptions<TRow>): ColumnDef<TRow> {
  const Icon = options.icon;

  return {
    id: options.id,
    cell: (cell) => {
      if (typeof options.visible === 'function' && !options.visible(cell)) return <></>;

      const className = typeof options.className === 'function' ? options.className(cell) : options.className;

      return (
        <div className={styles.buttonContainer}>
          <button
            className={clsx(styles.button, className, {
              [styles.primary]: options.colorSchema === 'primary',
              [styles.secondary]: options.colorSchema === 'secondary' || !options.colorSchema,
            })}
            onClick={() => options.onClick(cell)}
            disabled={options.disabled?.(cell)}
          >
            <Icon className={clsx(styles.icon, options.iconClassName)} />
          </button>
        </div>
      );
    },
    header: '',
    meta: { cellClassName: styles.buttonCell, dontUseHeaderWidth: true },
  };
}
