import React from 'react';
import { DevError } from '../../../helpers/components/DevError/DevError.component';
import { IFormControlsResolver } from '../../forms/base/controls/IFormControlsResolver';
import { Container } from './controls/containers/container';
import { FieldGroupContainer } from './controls/containers/fieldGroupContainer';
import { StepContainer } from './controls/containers/stepContainer';
import { TabContainer } from './controls/containers/tabContainer';
import { AttentionText } from './controls/content/AttentionText';
import { EmptyBlock } from './controls/content/EmptyBlock';
import { ImageBlock } from './controls/content/ImageBlock';
import { Text } from './controls/content/Text';
import { Title } from './controls/content/Title';
import { CheckBoxGroupInput } from './controls/inputs/checkBoxGroupInput';
import { CheckBoxInput } from './controls/inputs/checkBoxInput';
import { DateInput } from './controls/inputs/dateInput';
import { DateRangeInput } from './controls/inputs/dateRangeInput.';
import { FilesInput } from './controls/inputs/filesInput';
import { SelectInput } from './controls/inputs/selectInput';
import { TagInput } from './controls/inputs/tagInput';
import { TextInput } from './controls/inputs/textInput';
import { TimeInput } from './controls/inputs/timeInput';
import { RadioInput } from './controls/inputs/radioInput';
import { BmiInput } from './controls/specials/bmiInput';
import { GroupInput } from './controls/specials/groupInput';
import { NumberInput } from './controls/inputs/numberInput';
import { RecordResultList } from './controls/inputs/recordResultList';
import { SliderInput } from './controls/inputs/sliderInput';

export const recordReportFormControlsResolver: IFormControlsResolver = {
  // Containers
  Container: Container,
  StepContainer: StepContainer,
  TabsContainer: TabContainer,
  FieldGroupContainer: FieldGroupContainer,

  // Content
  EmptyBlock: EmptyBlock,
  Text: Text,
  AttentionText: AttentionText,
  Title: Title,
  ImageBlock: ImageBlock,

  // Inputs
  TextInput: TextInput,
  NumberInput: NumberInput,
  DateInput: DateInput,
  TimeInput: TimeInput,
  CheckBoxInput: CheckBoxInput,
  CheckBoxGroupInput: CheckBoxGroupInput,
  Files: FilesInput,
  DateRangeInput: DateRangeInput,
  TagInput: TagInput,
  RadioInput: RadioInput,
  SelectInput: SelectInput,
  SliderInput: SliderInput,
  RecordResultList: RecordResultList,

  // Special blocks
  BmiInput: BmiInput,
  GroupInput: GroupInput,
  CalculatedBlock: () => <DevError messages={['Control "CalculatedBlock" is not implemented']} />,
};
