import React, { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseModalProps } from '../../../application/hooks/useModal';
import { logger } from '../../../application/logging/logging';
import { pascalToCamelCase } from '../../../helpers/error-helpers';
import Style from './generateReportForm.module.css';
import { AppTextField } from '../../uikit/fields/text/appTextField.component';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

export type GenerateReportFormParams = {
  callback: (data: UseFormGenerateReportType) => Promise<void>;
};

export type UseFormGenerateReportType = {
  furtherInfo?: string;
};

export type GenerateReportFormType = {
  modal: UseModalProps<GenerateReportFormParams>;
};

export const GenerateReportForm: FC<GenerateReportFormType> = ({ modal }) => {
  const { t } = useTranslation();
  const [internalError, setInternalError] = useState(false);

  const { callback } = modal.params;

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    watch,
  } = useForm<UseFormGenerateReportType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const furtherInfo = watch('furtherInfo');

  // This effect reset InternalError
  useEffect(() => {
    setInternalError(false);
  }, [furtherInfo]);

  const onSubmit = handleSubmit(async (data: UseFormGenerateReportType) => {
    setInternalError(false);
    try {
      await callback(data);
      modal.closeModal();
    } catch (ex: any) {
      if (ex.errors && Object.keys(ex.errors).length > 0) {
        for (const errorKey of Object.keys(ex.errors)) {
          const formKey = pascalToCamelCase(errorKey) as keyof UseFormGenerateReportType;
          setError(formKey, {
            type: 'server',
            message: ex.errors[errorKey][0],
          });
        }
      } else {
        logger().error(ex);
        setInternalError(true);
      }
    }
  });

  const content = useMemo(() => {
    return (
      <form id={'rejectIssueForm'} onSubmit={onSubmit} autoComplete={'off'}>
        <Controller
          control={control}
          name={'furtherInfo'}
          render={({ field: { onChange, value } }) => (
            <AppTextField
              type={'text-area'}
              labelProps={{ text: t('Issues.GenerateReportModal.FurtherInfoLabel') }}
              placeholder={t('Issues.GenerateReportModal.FurtherInfoPlaceholder')}
              value={value}
              onChange={onChange}
              disabled={isSubmitting}
              errorProps={{ errors: errors?.furtherInfo?.message }}
            />
          )}
        />
      </form>
    );
  }, [control, errors?.furtherInfo?.message, isSubmitting, onSubmit, t]);

  return (
    <DialogModal
      bodyClassName={Style.modalBody}
      title={t('Issues.GenerateReportModal.Title')}
      visible={modal.visible}
      onHide={modal.closeModal}
      footer={{
        errors: internalError ? t('Issues.GeneralError') : undefined,
        leftButton: {
          text: t('Common_Cancel'),
          variant: 'button',
          colorSchema: 'secondary',
          onClick: modal.closeModal,
          disabled: isSubmitting,
        },
        rightButton: {
          text: t('Issues.GenerateReportModal.ConfirmButton'),
          variant: 'button',
          colorSchema: 'primary',
          type: 'submit',
          disabled: isSubmitting,
          isLoading: isSubmitting,
          form: 'rejectIssueForm',
        },
      }}
      onDismissed={reset}
      isDisabled={isSubmitting}
      testId={'report-issue-modal'}
    >
      {content}
    </DialogModal>
  );
};
