﻿import { ColumnDef } from '@tanstack/react-table';

export type DummyColumnOptions = {
  /** @default dummy */
  id?: string;
};

export function dummyColumn<TRow>(options?: DummyColumnOptions): ColumnDef<TRow> {
  return {
    id: options?.id || 'dummy',
    header: '',
    cell: () => null,
    meta: {
      dontUseHeaderWidth: true,
    },
  };
}
