import { InternalAxiosRequestConfig } from 'axios';
import { createId } from '../typeUtils';

const appVersion = () => import.meta.env.REACT_APP_VERSION! ?? build.REACT_APP_VERSION;
const platform = 'web';

// it's constant while the app is running, and different after app restarts
export const sessionId = `${platform}-${createId()}-${createId()}-${createId()}-${appVersion()}`;

/**
 * Intercepts all HTTP calls via axios and adds a session-id header.
 */
export const sessionAxiosInterceptor = (config: InternalAxiosRequestConfig) => {
  config.headers.ClientSession = sessionId;
  config.headers.ClientVersion = appVersion();
  config.headers.ClientPlatform = platform;
  return config;
};
