export function hasFlag(generalEnum: number, specificEnum: number) {
  return (generalEnum & specificEnum) === specificEnum;
}

export const getAllPresentedFlags = (generalEnum: number): number[] => {
  const flags: number[] = [];
  for (let i = 1; i <= generalEnum; i = i * 2) {
    if (hasFlag(generalEnum, i)) {
      flags.push(i);
    }
  }

  return flags;
};

export const enumToStringArr = (e: any): string[] => Object.keys(e).filter((x) => !Number(x) && Number(x) !== 0);
