﻿import { useModal } from '../../application/hooks/useModal';
import React, { FC, useCallback, useState } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { HookFormTextInput } from '../../hookFormControls/hookFormTextInput';
import { ValidationFormRules } from '../../helpers/validation-helpers';
import { HookFormDropDown } from '../../hookFormControls/hookFormDropDown';
import {
  mapPatientCardsToDropdownOptions,
  mapTableColumnsToDropdownOptions,
  PatientCardOption,
  patientCardsToDropdownOptions,
  tableColumnsOptions,
} from '../../containers/adminDashboard/StudyList/study-helpers';
import { AppTagInputField } from 'uikit/fields';
import { AppInputLabel } from 'uikit/wrappers';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { CardSectionsEnum, CreateGroupDto, TableColumnsEnum } from '../../services/api/api-client';
import { useId } from '@fluentui/react-hooks';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import styles from './useGroupCreating.module.scss';
import { createGroup } from '../../services/api/api-client/StudyClient';
import _ from 'lodash';
import { handleSubmitFormError } from '../../application/error-handling/useErrorHandler';
import { getDoctorProfileInfoQueryKey } from '../../services/api/api-client/ProfileQuery';
import { getStudyListQueryKey } from '../../services/api/api-client/StudyQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

export const useGroupCreating = () => {
  const modal = useModal();
  const [studyId, setStudyId] = useState(0);

  const form = useForm<FormState>({
    defaultValues: {
      title: '',
      tableColumns: mapTableColumnsToDropdownOptions(
        TableColumnsEnum.Stage | TableColumnsEnum.Categorization | TableColumnsEnum.Note,
      ),
      surveyColumns: [],
      surveysToPatientCard: [],
      patientCardSections: mapPatientCardsToDropdownOptions([
        [CardSectionsEnum.GeneralInfo, CardSectionsEnum.Stage, CardSectionsEnum.StudyPeriod],
        [CardSectionsEnum.RecordsGroupedByStep],
        [CardSectionsEnum.Notes],
      ]),
      formConfigIdForNotes: null,
      noteDateDataKey: null,
      mainNoteFieldDataKey: null,
    },
  });

  return {
    element: (
      <GroupCreatingModal
        form={form}
        onHide={modal.closeModal}
        visible={modal.visible}
        studyId={studyId}
        onSuccess={modal.closeModal}
      />
    ),
    // eslint-disable-next-line @typescript-eslint/no-shadow
    start: (studyId: number) => {
      setStudyId(studyId);
      form.reset();
      modal.openModal();
    },
  };
};

type FormState = {
  title: string;
  tableColumns: DropdownOption[];
  surveyColumns: string[];
  surveysToPatientCard: string[];
  patientCardSections: PatientCardOption[];
  formConfigIdForNotes: number | null;
  noteDateDataKey: string | null;
  mainNoteFieldDataKey: string | null;
};

const GroupCreatingModal: FC<{
  visible: boolean;
  onHide: () => void;
  form: UseFormReturn<FormState>;
  studyId: number;
  onSuccess: () => void;
}> = (props) => {
  const queryClient = useQueryClient();
  const { t: modalLocalizer } = useScopedTranslation('GroupCreating.Modal', 'dev');
  const { t } = useTranslation();
  const formId = useId();

  const submit = useCallback(
    async (values: FormState) => {
      try {
        await createGroup(
          props.studyId,
          new CreateGroupDto({
            title: values.title,
            surveyColumns: values.surveyColumns,
            surveysToPatientCard: values.surveysToPatientCard,
            patientCardSections: _(values.patientCardSections)
              .groupBy((x) => x.key)
              .map((x) => x.flatMap((y) => y.card))
              .value(),
            tableColumns: _(values.tableColumns).sumBy('key'),
            noteDateDataKey: values.noteDateDataKey,
            mainNoteFieldDataKey: values.mainNoteFieldDataKey,
            formConfigIdForNotes: values.formConfigIdForNotes,
          }),
        );
        props.onHide();
      } catch (e) {
        handleSubmitFormError(e, props.form.setError);
        return;
      }

      await Promise.all([
        queryClient.invalidateQueries(getDoctorProfileInfoQueryKey()),
        queryClient.invalidateQueries(getStudyListQueryKey()),
      ]);
    },
    [props, queryClient],
  );

  return (
    <DialogModal
      visible={props.visible}
      onHide={props.onHide}
      title={modalLocalizer('Header')}
      bodyClassName={styles.modalBody}
      footer={{
        errors: !!props.form.formState.errors.root ? modalLocalizer('Error') : undefined,
        leftButton: {
          text: t('Common_Cancel'),
          onClick: props.onHide,
          disabled: props.form.formState.isSubmitting,
        },
        rightButton: {
          text: t('Common_Create'),
          type: 'submit',
          disabled: props.form.formState.isSubmitting,
          isLoading: props.form.formState.isSubmitting,
          form: formId,
        },
      }}
    >
      <form id={formId} onSubmit={props.form.handleSubmit(submit)} autoComplete={'off'}>
        <HookFormTextInput
          control={props.form.control}
          name={'title'}
          labelProps={{ text: modalLocalizer('Title.FieldLabel') }}
          placeholder={modalLocalizer('Title.FieldPlaceholder')}
          rules={ValidationFormRules().requiredRule}
        />

        <HookFormDropDown
          isMultiple={true}
          labelProps={{ text: modalLocalizer('TableColumns.FieldLabel') }}
          placeholder={modalLocalizer('TableColumns.FieldPlaceholder')}
          options={tableColumnsOptions()}
          name={'tableColumns'}
          control={props.form.control}
        />

        <HookFormDropDown
          isMultiple={true}
          labelProps={{ text: modalLocalizer('PatientCardSections.FieldLabel') }}
          placeholder={modalLocalizer('PatientCardSections.FieldPlaceholder')}
          options={patientCardsToDropdownOptions()}
          name={'patientCardSections'}
          control={props.form.control}
        />

        <Controller
          control={props.form.control}
          render={({ field: { onChange, value } }) => (
            <AppTagInputField
              labelProps={{
                text: modalLocalizer('SurveyColumns.FieldLabel'),
              }}
              placeholder={modalLocalizer('SurveyColumns.FieldPlaceholder')}
              values={value || []}
              onDeleteTag={(tagIndex) => {
                const newArray = value?.slice() ?? [];
                newArray.splice(tagIndex, 1);
                onChange(newArray);
              }}
              onAddTag={(newSurvey) => onChange([...(value || []), newSurvey])}
            />
          )}
          name={'surveyColumns'}
        />

        <Controller
          control={props.form.control}
          render={({ field: { onChange, value } }) => (
            <AppTagInputField
              labelProps={{
                text: modalLocalizer('SurveysToPatientCard.FieldLabel'),
              }}
              placeholder={modalLocalizer('SurveysToPatientCard.FieldPlaceholder')}
              values={value || []}
              onDeleteTag={(tagIndex) => {
                const newArray = value?.slice() ?? [];
                newArray.splice(tagIndex, 1);
                onChange(newArray);
              }}
              onAddTag={(newSurvey) => onChange([...(value || []), newSurvey])}
            />
          )}
          name={'surveysToPatientCard'}
        />

        <AppInputLabel text={modalLocalizer('Notes.Caption')} isBold={true}>
          <HookFormTextInput
            control={props.form.control}
            name={'formConfigIdForNotes'}
            labelProps={{ text: modalLocalizer('Notes.FormConfigId.FieldLabel') }}
            placeholder={modalLocalizer('Notes.FormConfigId.FieldPlaceholder')}
          />

          <HookFormTextInput
            control={props.form.control}
            name={'mainNoteFieldDataKey'}
            labelProps={{ text: modalLocalizer('Notes.MainDataKey.FieldLabel') }}
            placeholder={modalLocalizer('Notes.MainDataKey.FieldPlaceholder')}
          />

          <HookFormTextInput
            control={props.form.control}
            name={'noteDateDataKey'}
            labelProps={{ text: modalLocalizer('Notes.DateDataKey.FieldLabel') }}
            placeholder={modalLocalizer('Notes.DateDataKey.FieldPlaceholder')}
          />
        </AppInputLabel>
      </form>
    </DialogModal>
  );
};
