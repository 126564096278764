import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModal } from 'src/application/hooks/useModal';
import { ButtonColorSchema } from 'uikit/buttons';
import { FooterButtonProps } from 'src/components/dialogModal/dialogModal';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
import { useTranslation } from 'react-i18next';
import { AppTextField } from 'uikit/fields';
import Style from './textSettings.module.css';
import { SelectionData } from './selectionData';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import {
  checkLinkForDeleting,
  deleteLink,
  editCurrentLink,
  makeSelectionLink,
  validateUrl,
} from './helpers/linkHelpers';

export type AddLinkOptions = {
  selection: SelectionData | null;
} & (
  | {
      actionType: LinkActionType.Add;
      link?: never;
    }
  | {
      actionType: LinkActionType.Edit;
      link: string;
    }
);

export enum AddLinkResult {
  Success,
  Closed,
}

export type AddLinkModalOptions = {
  containerClassName?: string;
};

export enum LinkActionType {
  Add,
  Edit,
}

export const useAddLinkModal = (params?: AddLinkModalOptions) => {
  const modal = useModal('CLOSED');
  const { t } = useTranslation();

  const [linkError, setLinkError] = useState<string>('');
  const [options, setOptions] = useState<AddLinkOptions>({
    selection: null,
    link: '',
    actionType: LinkActionType.Edit,
  });
  const [linkValue, setLinkValue] = useState<string>('');

  const promiseRef = useRef<(test: AddLinkResult) => void>();

  const closeHandler = useCallback(() => {
    setLinkValue('');
    setLinkError('');
    modal.closeModal();
  }, [modal]);

  const hideHandler = useCallback(() => {
    promiseRef?.current?.(AddLinkResult.Closed);
    closeHandler();
  }, [modal]);

  const okButton: FooterButtonProps = useMemo(
    () => ({
      testId: 'confirm-button',
      colorSchema: 'primary',
      text: t('FaqEditor.TextEditing.Link.Modal.Buttons.Save'),
      disabled: isNullOrEmpty(linkValue),
      onClick: () => {
        const isUrlValid = validateUrl(linkValue);
        if (isUrlValid && options.selection) {
          promiseRef?.current?.(AddLinkResult.Success);
          options.actionType === LinkActionType.Add
            ? makeSelectionLink(linkValue, options.selection)
            : editCurrentLink(options.selection, linkValue);
          closeHandler();
        } else {
          setLinkError(t('FaqEditor.TextEditing.Link.Modal.Error'));
        }
      },
    }),
    [linkValue, modal, options.selection, t],
  );

  const cancelButton: FooterButtonProps = useMemo(
    () => ({
      testId: 'cancel-button',
      colorSchema: 'secondary',
      text: t('FaqEditor.TextEditing.Link.Modal.Buttons.Cancel'),
      onClick: () => {
        hideHandler();
      },
    }),
    [hideHandler],
  );

  const title: string = useMemo(
    () =>
      options.actionType === LinkActionType.Add
        ? t('FaqEditor.TextEditing.Link.Modal.AddLinkTitle')
        : t('FaqEditor.TextEditing.Link.Modal.EditLinkTitle'),
    [options.actionType],
  );

  const deleteLinkButton: FooterButtonProps = useMemo(
    () => ({
      testId: 'delete-button',
      colorSchema: 'destroy',
      text: t('FaqEditor.TextEditing.Link.Modal.Buttons.Delete'),
      onClick: () => {
        if (!isNullOrEmpty(linkValue) || linkValue === options.link) return;
        if (!options.selection) return;
        deleteLink(options.selection.ranges);
        promiseRef?.current?.(AddLinkResult.Success);
        closeHandler();
      },
    }),
    [closeHandler, linkValue],
  );

  return {
    open: (o: AddLinkOptions): Promise<AddLinkResult> => {
      setOptions(o);
      setLinkValue(o.link ?? '');
      setLinkError('');
      modal.openModal();

      return new Promise<AddLinkResult>((resolve) => {
        promiseRef.current = resolve;
      });
    },
    modal: (
      <DialogModal
        bodyClassName={Style.modalBody}
        testId={'add-link-modal'}
        visible={modal.visible}
        onHide={hideHandler}
        title={title}
        containerClassName={params?.containerClassName}
        footer={{
          leftButton: cancelButton,
          rightButton: checkLinkForDeleting(options.link ?? '', linkValue) ? deleteLinkButton : okButton,
        }}
      >
        <AppTextField
          type={'text'}
          value={linkValue}
          placeholder={t('FaqEditor.TextEditing.Link.Modal.Input.Placeholder')}
          onChange={(e) => setLinkValue(e.target.value)}
          labelProps={{
            text: t('FaqEditor.TextEditing.Link.Modal.Input.Title'),
          }}
          errorProps={{ errors: linkError }}
        />
      </DialogModal>
    ),
  };
};
