import React, { useCallback, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useModal } from '../../../../application/hooks/useModal';
import { addAnswerToQuestionSchema, GetAnswerValue, questionSchemaToArray } from '../../../../helpers/question-helper';
import { QueryFactory } from '../../../../services/api';
import {
  AnswerResultDto,
  IQuestionScheme,
  QuestionScheme,
  QuestionType,
  SurveyQuestion,
} from '../../../../services/api/api-client';
import { AppButton } from 'uikit/buttons';
import { DialogModal } from '../../../dialogModal/dialogModal.component';
import { DataBlockValidation } from '../../../formEditor/uiEditor/provider/formFill.context';
import { SurveyFillingProps } from './surveyFilling';
import { SurveyFillingComponent } from './surveyFilling.component';
import Style from './surveyFilling.module.css';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../../application/localisation/useCommonLocalization';

export const SurveyFilling = (props: SurveyFillingProps) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection');
  const commonLocalizer = useCommonLocalization();
  const mainModal = useModal();
  const confirmationModal = useModal();

  const queryClient = useQueryClient();

  const [schemaWithAnswers, setSchemaWithAnswers] = useState<QuestionScheme>(props.questionnaire.questionsScheme);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [dataValidation, setDataValidation] = useState<DataBlockValidation>({});
  const [skippedQuestions, setSkippedQuestions] = useState<string[]>([]);
  const questionRefs = useRef({});

  const validateAnswers = useCallback(
    (scheme: IQuestionScheme) => {
      const missedAnswers: DataBlockValidation | undefined = questionSchemaToArray(scheme)
        ?.filter((q) => !GetAnswerValue(q) && !skippedQuestions.includes(q.id))
        .reduce((obj, cur) => {
          return {
            ...obj,
            [cur.id]: { validationState: 'Fail', text: cur.questionText },
          };
        }, {});

      return missedAnswers;
    },
    [skippedQuestions],
  );

  const finishHandler = useCallback(async () => {
    confirmationModal.closeModal();
    setIsSubmitting(true);

    const invalidAnswers = validateAnswers(schemaWithAnswers) ?? {};
    if (Object.keys(invalidAnswers).length > 0) {
      setDataValidation(invalidAnswers);
      setIsSubmitting(false);
      questionRefs.current[Object.keys(invalidAnswers)[0]]?.scrollIntoView(true);
      return;
    }

    const newAnswer = new AnswerResultDto({
      id: null,
      answerScheme: new QuestionScheme(schemaWithAnswers),
      isSubmitted: true,
      questionnaireId: props.questionnaire.id,
    });

    try {
      await QueryFactory.SurveyQuery.Client.saveAnswer(newAnswer, true);
      mainModal.closeModal();

      await Promise.all([
        queryClient.invalidateQueries(QueryFactory.SurveyQuery.getQuestionnairesQueryKey()),
        queryClient.invalidateQueries(QueryFactory.SurveyQuery.getLastSurveysStatisticsQueryKey()),
        queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientsQueryKey()),
        queryClient.invalidateQueries(QueryFactory.SurveyQuery.getAnswersStatisticQueryKey()),
      ]);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  }, [confirmationModal, validateAnswers, schemaWithAnswers, props.questionnaire.id, mainModal, queryClient]);

  const answerHandler = useCallback(
    (question: SurveyQuestion, answeredValue: string | null) => {
      setDataValidation((state) => {
        delete state[question.id];
        return { ...state };
      });

      addAnswerToQuestionSchema(answeredValue, question, schemaWithAnswers);
      setSchemaWithAnswers(schemaWithAnswers);
    },
    [schemaWithAnswers],
  );

  const mainModalCloseHandler = useCallback(() => {
    const hasAnyAnswer = questionSchemaToArray(schemaWithAnswers)?.some(GetAnswerValue);
    if (hasAnyAnswer || skippedQuestions.length > 0) {
      confirmationModal.openModal();
      return;
    }

    mainModal.closeModal();
  }, [confirmationModal, mainModal, schemaWithAnswers, skippedQuestions.length]);

  const confirmationModalCloseHandler = useCallback(() => {
    schemaWithAnswers.questions?.forEach((q) => addAnswerToQuestionSchema(null, q, schemaWithAnswers));

    setSchemaWithAnswers(schemaWithAnswers);
    setError(undefined);
    setSkippedQuestions([]);
    setDataValidation({});

    confirmationModal.closeModal();
    mainModal.closeModal();
  }, [confirmationModal, mainModal, schemaWithAnswers]);

  const onSkipHandler = useCallback(
    (question: SurveyQuestion, isSkipped: boolean) => {
      if (isSkipped) {
        setSkippedQuestions((state) => Array.from(new Set([...state, question.id])));
        answerHandler(question, question.questionType === QuestionType.FreeInput ? '' : null);
      } else {
        setSkippedQuestions((state) => state.filter((id) => id !== question.id));
      }
    },
    [answerHandler],
  );

  return (
    <div>
      <AppButton
        variant={'icon-link'}
        colorSchema={'secondary'}
        onClick={() => mainModal.openModal()}
        text={commonLocalizer('Common_FillIn')}
        verticalAlign={true}
        Icon={props.buttonIcon}
        className={Style.fillButton}
        testId={`fill-survey-button--${props.surveyType}`}
      />

      <DialogModal
        bodyClassName={Style.modalBody}
        title={
          props.questionnaire?.type === undefined || props.questionnaire?.type === null
            ? commonLocalizer('Common_NoData')
            : props.questionnaire?.typeString
        }
        visible={mainModal.visible}
        onHide={mainModalCloseHandler}
        footer={{
          errors: error,
          leftButton: {
            text: commonLocalizer('Common_Cancel'),
            onClick: mainModalCloseHandler,
          },
          rightButton: {
            text: commonLocalizer('Common_Finish'),
            onClick: finishHandler,
            disabled: isSubmitting,
            isLoading: isSubmitting,
          },
        }}
        testId={`survey-filling-form-${props.questionnaire.typeString}`}
      >
        {props.questionnaire ? (
          <>
            <div className={Style.schemaWrapper}>
              <div className={Style.caption}>{t('SurveyFilling.Caption')}</div>
              <SurveyFillingComponent
                patientId={props.patientId}
                schema={schemaWithAnswers}
                onChange={answerHandler}
                validationObject={dataValidation}
                skippedQuestions={skippedQuestions}
                onSkip={onSkipHandler}
                onAddRef={(el, id) => (questionRefs.current[id] = el)}
              />
            </div>
          </>
        ) : (
          commonLocalizer('Common_NoData')
        )}
      </DialogModal>

      <DialogModal
        visible={confirmationModal.visible}
        onHide={confirmationModal.closeModal}
        testId={'discard-modal-confirmation'}
        footer={{
          leftButton: {
            colorSchema: 'destroy',
            text: t('SurveyFilling.ConfirmationModal.DiscardBtn'),
            type: 'reset',
            onClick: confirmationModalCloseHandler,
          },
          rightButton: {
            disabled: isSubmitting,
            isLoading: isSubmitting,
            hasLoaded: isSubmitting,
            text: t('SurveyFilling.ConfirmationModal.Save'),
            type: 'submit',
            onClick: finishHandler,
          },
        }}
        title={t('SurveyFilling.ConfirmationModal.Header')}
      >
        {t('SurveyFilling.ConfirmationModal.MainText')}
      </DialogModal>
    </div>
  );
};
