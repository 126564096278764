import React from 'react';
import { QueryFactory } from '../../../services/api';
import { createPagingParams, pagingSortingToBackendRequest } from '../../../helpers/pagination-helper';
import { useUserColumns } from './users.columns';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'use-query-params';
import { userFilterParams, userFilterToBackendRequest } from './users-helpers';
import { UserFilters } from './UserFilters.component';
import { IStaffDto } from '../../../services/api/api-client';
import { UserDetailsCard } from './UserDetailsCard.component';
import TablePageLayout from '../../../components/tablePageLayout/TablePageLayout';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { AppTable } from 'uikit/table/AppTable';
import { useSortBy } from 'uikit/table/updateSortByInUrl';
import { ReactComponent as UserPlus } from 'assets/img/patient/user_plus.svg';
import { useCreatedUserModal } from './useCreatedUserModal';

export const usersQueryParamsConfig = {
  ...createPagingParams({
    defaultPerPage: 30,
    defaultSortBy: 'id',
    sortingKeys: ['id', 'lastVisitDateTime'],
  }),
  ...userFilterParams(),
};

export const Users = () => {
  const { t } = useTranslation('dev');
  const [queryParams, setQueryParams] = useQueryParams(usersQueryParamsConfig);
  const createUserModal = useCreatedUserModal();

  const userList = QueryFactory.UsersQuery.useGetAllUsersQuery(
    {
      ...pagingSortingToBackendRequest(queryParams),
      ...userFilterToBackendRequest(queryParams),
      searchQuery: queryParams.searchQuery,
    },
    {
      suspense: false,
      keepPreviousData: true,
    },
  );

  const sortBy = useSortBy(queryParams, setQueryParams);

  const rowDataTable = useReactTable<IStaffDto>({
    data: userList?.data?.data || [],
    manualSorting: true,
    onSortingChange: sortBy.onSortingChange,
    state: {
      sorting: sortBy.sortingState,
    },
    columns: useUserColumns(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <TablePageLayout>
        <TablePageLayout.Header>
          <TablePageLayout.Header.Row>
            <TablePageLayout.Header.Title
              text={t('Admin.UserList.Caption')}
              secondaryText={userList.data?.totalCount.toString()}
            />
          </TablePageLayout.Header.Row>
          <UserFilters queryParams={queryParams} setQueryParams={setQueryParams} />
        </TablePageLayout.Header>
        <TablePageLayout.TableContainer loading={userList.isLoading || userList.isPreviousData}>
          <AppTable
            table={rowDataTable}
            placeholder={{ hide: userList.isLoading || userList.isPreviousData, text: t('Admin.UserList.No_Data') }}
            expandedRow={(row) => <UserDetailsCard key={row.index} {...row.original} />}
          />
          <TablePageLayout.TableContainer.Pagination
            page={queryParams.page}
            perPage={queryParams.perPage}
            totalCount={userList?.data?.totalCount || 0}
            changePagination={setQueryParams}
          />
          <TablePageLayout.TableContainer.FloatingButton onClick={createUserModal.start} Icon={UserPlus} />
        </TablePageLayout.TableContainer>
      </TablePageLayout>
      {createUserModal.modal}
    </>
  );
};
