import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedResourceDictionaryKeys } from 'src/application/localisation/i18next';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { FormListType } from 'src/components/formEditor/controls/ConstantBlocks/RecordResultList/useGetFormList';
import { IRecordResultList, RecordResultListItemData } from 'src/features/forms/base/controls/inputs/RecordResultList';
import { DoubleCheckIcon } from 'src/features/report/components/svg/10px/DoubleCheckIcon';
import { DoubleCrossIcon } from 'src/features/report/components/svg/10px/DoubleCrossIcon';
import { RPDF } from 'src/features/report/react-pdf';
import { ReportColors } from 'src/features/report/report-colors';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { FormSkipReason, IScoreSection, ScoreGroupEnum } from 'src/services/api/api-client';
import { useContextSelector } from 'use-context-selector';
import { RecordsReportContext } from '../../recordsReport.context';
import { RecordContext } from '../../RecordContext';

export const RecordResultList: IRecordResultList = ({ dataKey }) => {
  const { t } = useScopedTranslation('Forms.Controls.RecordResultList');
  const inputValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const { mandatoryForms, multipleForms } = useContextSelector(RecordsReportContext, (v) => v);

  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);

  const inputValueArr: RecordResultListItemData[] = useMemo(
    () => (multipleInputValue?.length ? multipleInputValue.map((str) => JSON.parse(str)) : []),
    [multipleInputValue],
  );

  const formResultIds = useMemo(() => inputValueArr.map((x) => x.resultId), [inputValueArr]);

  const mandatoryFormsFiltered = useMemo(
    () => mandatoryForms?.filter((x) => x.formResults.some((r) => formResultIds.includes(r.resultId))),
    [formResultIds, mandatoryForms],
  );

  const multipleFormsFiltered = useMemo(
    () => multipleForms?.filter((x) => x.formResults.some((r) => formResultIds.includes(r.resultId))),
    [formResultIds, multipleForms],
  );

  return (
    <RPDF.View>
      {!!mandatoryFormsFiltered?.length && (
        <RecordResultContainer header={t('ObligatoryForms')}>
          {mandatoryFormsFiltered.map(({ formType, formResults }) => (
            <RecordResultItem
              key={formType}
              formType={formType}
              formResults={formResults}
              inputValueArr={inputValueArr}
            />
          ))}
        </RecordResultContainer>
      )}
      {!!multipleFormsFiltered?.length && (
        <RecordResultContainer header={t('MultipleForms')}>
          {multipleFormsFiltered.map(({ formType, formResults }) => (
            <RecordResultItem
              key={formType}
              formType={formType}
              formResults={formResults}
              inputValueArr={inputValueArr}
            />
          ))}
        </RecordResultContainer>
      )}
    </RPDF.View>
  );
};

export const RecordResultContainer: FC<PropsWithChildren<{ header: string }>> = ({ header, children }) => (
  <RPDF.View>
    <RPDF.Text
      style={[
        RPDF.typography.heading5b,
        RPDF.tableStyles.divider,
        { paddingVertical: 4, borderBottomColor: ReportColors.main300 },
      ]}
    >
      {header}
    </RPDF.Text>
    {children}
  </RPDF.View>
);

export const RecordResultItem: FC<FormListType & { inputValueArr: RecordResultListItemData[] }> = ({
  formType,
  formResults,
  inputValueArr,
}) => {
  const { t } = useTranslation();

  const getResultIdComment = useCallback(
    (resultId: number) => inputValueArr.find((x) => x.resultId === resultId)?.comment,
    [inputValueArr],
  );

  const resultsToView = useMemo(
    () =>
      formResults.map((x) => {
        const savedVersionNumber = inputValueArr.find((a) => a.resultId === x.resultId)?.versionNumber;

        return {
          resultId: x.resultId,
          stepName: x.stepName,
          createdAt: x.createdAt,
          currentVersion: x.versions.find((v) => v.versionNumber === savedVersionNumber),
        };
      }),
    [formResults, inputValueArr],
  );

  return (
    <RPDF.View style={[RPDF.tableStyles.divider, { paddingVertical: 8 }]}>
      <RPDF.View style={[RPDF.typography.heading5b, { flexDirection: 'row', gap: 2, paddingBottom: 8 }]}>
        <RPDF.Text>{formType}</RPDF.Text>
        <RPDF.Text style={{ color: ReportColors.plain }}>{`(${formResults.length})`}</RPDF.Text>
      </RPDF.View>
      <RPDF.View style={{ gap: 16 }}>
        {resultsToView.map((result, i) => {
          const comment = getResultIdComment(result.resultId);
          const formState = result.currentVersion?.isSkipped ? 'missed' : 'filled';
          const translatedSkipReason =
            result.currentVersion?.skipReason === FormSkipReason.NotApplicable
              ? t('Forms.FormSkipReason.NotApplicable')
              : result.currentVersion?.skipReason === FormSkipReason.Missed
              ? t('Forms.FormSkipReason.Missed')
              : undefined;

          return (
            <RPDF.View key={i} style={{ gap: 2 }}>
              <RPDF.View style={{ flexDirection: 'row', gap: 2 }}>
                {formState === 'filled' ? <DoubleCheckIcon /> : <DoubleCrossIcon />}
                <RPDF.Text style={[RPDF.typography.heading5, { color: ReportColors.green500 }]}>{formType}</RPDF.Text>
                <RPDF.Text style={[RPDF.typography.text8, { color: ReportColors.plain }]}>
                  {result.stepName ? result.stepName : result.createdAt}
                </RPDF.Text>
              </RPDF.View>
              {result.currentVersion?.score && (
                <RPDF.View style={{ flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
                  {result.currentVersion?.score?.sectionScore.map((section: IScoreSection) => (
                    <ScoreRow
                      key={section.scoreGroup}
                      scoreGroup={t(
                        `Forms.Score.ScoreGroup.${
                          ScoreGroupEnum[section.scoreGroup]
                        }` as LocalizedResourceDictionaryKeys,
                      )}
                      score={section.score}
                      decimal={result.currentVersion?.score?.decimal}
                    />
                  ))}
                  {result.currentVersion?.score?.hasTotalScore && (
                    <ScoreRow
                      scoreGroup={t('Forms.Score.TotalScore')}
                      score={result.currentVersion?.score?.totalScore}
                      decimal={result.currentVersion?.score?.decimal}
                    />
                  )}
                </RPDF.View>
              )}

              <CommentRow
                header={`${t('Forms.Controls.RecordResultList.SkipFormReason')}:`}
                text={translatedSkipReason}
              />
              <CommentRow
                header={`${t('Forms.Controls.RecordResultList.SkipFormReasonDetails')}:`}
                text={result.currentVersion?.skipReasonDetails}
              />
              <CommentRow header={`${t('Forms.Controls.RecordResultList.CommentLabel')}:`} text={comment} />
            </RPDF.View>
          );
        })}
      </RPDF.View>
    </RPDF.View>
  );
};

export const CommentRow: FC<{ header: string; text?: string | null | undefined }> = ({ header, text }) => {
  if (isNullOrEmpty(text)) return null;
  const gap = ' ';

  return (
    <RPDF.Text>
      <RPDF.Text style={RPDF.typography.heading5}>{header}</RPDF.Text>
      <RPDF.Text style={RPDF.typography.heading5}>{gap}</RPDF.Text>
      <RPDF.Text style={RPDF.typography.text8}>{text}</RPDF.Text>
    </RPDF.Text>
  );
};

export const ScoreRow: FC<{ scoreGroup: string; score?: number | null | undefined; decimal?: number | null }> = ({
  scoreGroup,
  score,
  decimal,
}) => {
  const { t } = useTranslation();

  return (
    <RPDF.View
      style={{
        backgroundColor: ReportColors.extraLightGrey,
        paddingHorizontal: 4,
        paddingVertical: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 4,
        width: '33%',
      }}
    >
      <RPDF.Text style={RPDF.typography.text8}>{scoreGroup}</RPDF.Text>
      <RPDF.Text style={RPDF.typography.heading5}>
        {score !== null ? score?.toFixed(decimal ?? 0) : t('Common_dash')}
      </RPDF.Text>
    </RPDF.View>
  );
};
