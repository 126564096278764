import React, { useState } from 'react';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import Style from './studyName.module.scss';

const isEllipsisActive = (e: HTMLElement | null) => {
  return e ? e.offsetWidth < e.scrollWidth : false;
};

export const StudyNameComponent = () => {
  const { studyNumber, title } = useStudy() ?? {};
  const [isEllipsis, setIsEllipsis] = useState(false);

  return (
    <div className={Style.container}>
      <Tooltip
        disabled={!isEllipsis}
        text={title || ''}
        hostStyles={Style.tooltipHost}
        styles={Style.tooltipCommonContainer}
      >
        <div className={Style.text} ref={(x) => setIsEllipsis(isEllipsisActive(x))}>
          {title}
        </div>
      </Tooltip>
      <div className={Style.number}>{studyNumber}</div>
    </div>
  );
};
