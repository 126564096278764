import { useEditor } from '@craftjs/core';
import React, { FC, PropsWithChildren } from 'react';
import { Sidebar } from './sidebar';
import { Toolbox } from './toolbox';
import Style from './viewport.module.css';
import { Header } from './header';
import clsx from 'clsx';
import { LayersPanel } from './layersPanel';
import { BufferPanel } from './bufferPanel';
import { SearchPanel } from './searchPanel';

export const Viewport: FC<PropsWithChildren> = ({ children }) => {
  const { enabled, connectors } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <div className={Style.container}>
      <LayersPanel />
      {enabled && (
        <div>
          <Toolbox />
          <BufferPanel />
        </div>
      )}

      <div id="page-container" className={Style.pageContainer}>
        <Header />
        <SearchPanel />
        <div
          id="craftjs-renderer"
          className={Style.craftjsRenderer}
          ref={(ref) => ref && connectors.select(connectors.hover(ref, ''), '')}
        >
          <div className={clsx(Style.content, { [Style.contentEditor]: enabled })}>{children}</div>
        </div>
      </div>
      {enabled && <Sidebar />}
    </div>
  );
};
