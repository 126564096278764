import React from 'react';
import { ReactComponent as DeviceIssue } from 'src/assets/img/formBuilder/statusIcons/device_filled_16.svg';
import { ReactComponent as AppIssue } from 'src/assets/img/formBuilder/statusIcons/phone_filled_16.svg';
import { ReactComponent as MaskIssue } from 'src/assets/img/formBuilder/statusIcons/mask_filled_16.svg';
import { ReactComponent as PsycheIssue } from 'src/assets/img/formBuilder/statusIcons/mental_filled_16.svg';
import { ReactComponent as MedicalIssueCOPD } from 'src/assets/img/formBuilder/statusIcons/lungs_filled_16.svg';
import { ReactComponent as SideEffectsIssue } from 'src/assets/img/formBuilder/statusIcons/pain_filled_16.svg';
import { ReactComponent as OtherIssue } from 'src/assets/img/formBuilder/statusIcons/other_filled_16.svg';
import { ReactComponent as BlueMan } from 'src/assets/img/formBuilder/statusIcons/patient_filled_16.svg';
import { ReactComponent as YellowMan } from 'src/assets/img/formBuilder/statusIcons/patient_filled_16_yellow.svg';
import { ReactComponent as RedMan } from 'src/assets/img/formBuilder/statusIcons/patient_filled_16_red.svg';
import { ReactComponent as DeviceExclamation } from 'src/assets/img/formBuilder/statusIcons/device_exclamation_filled_16.svg';
import { ReactComponent as QuestionnaireExclamation } from 'src/assets/img/formBuilder/statusIcons/survey_filled_16.svg';
import { ReactComponent as ChartPattern } from 'src/assets/img/formBuilder/statusIcons/data_filled_16.svg';
import { ReactComponent as SpeechBalloon } from 'src/assets/img/formBuilder/statusIcons/chat_filled_16.svg';
import { ReactComponent as Magnifier } from 'src/assets/img/formBuilder/statusIcons/magnifier_filled_16.svg';
import { DropdownOption } from '../../../../uikit/inputs/dropdown/appDropdownInput';

// If you change an icon name you must ensure that the name is also change in all form configs
export const statusIcons = {
  DeviceIssue: <DeviceIssue />,
  AppIssue: <AppIssue />,
  MaskIssue: <MaskIssue />,
  PsycheIssue: <PsycheIssue />,
  MedicalIssueCOPD: <MedicalIssueCOPD />,
  SideEffectsIssue: <SideEffectsIssue />,
  ScheduleIssue: <SpeechBalloon />, // TODO: Remove duplicated icon
  OtherIssue: <OtherIssue />,
  BlueMan: <BlueMan />,
  YellowMan: <YellowMan />,
  RedMan: <RedMan />,
  DeviceExclamation: <DeviceExclamation />,
  QuestionnaireExclamation: <QuestionnaireExclamation />,
  ChartPattern: <ChartPattern />,
  SpeechBalloon: <SpeechBalloon />,
  Magnifier: <Magnifier />,
} as Record<string, JSX.Element>;

export const defaultStatusIcon = <OtherIssue />;

export function getStatusIcon(iconName: string | undefined) {
  return (iconName && statusIcons[iconName]) || defaultStatusIcon;
}

export const statusIconDropDownOptions: DropdownOption<string>[] = Object.getOwnPropertyNames(statusIcons).map((x) => ({
  text: x,
  icon: statusIcons[x],
  key: x,
}));
