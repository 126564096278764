import { defaultToolSettings } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import {
  ApplicableRules,
  ValidationDefaultSettings,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { createId } from 'src/helpers/typeUtils';
import { IFormFieldWithOptionsProps } from 'src/features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import { UserComponent } from '@craftjs/core';

export const numerationOrderValues = ['NoNum', 'Ltr', 'Rtl'] as const;

export interface IRadioInputProps extends IFormFieldWithOptionsProps {
  /** @deprecated This prop was used as label text before and replaced by {@link IFormFieldProps.label label}.
   *  The prop was left for backward compatibility. */
  text?: string;
  isVertical?: boolean;

  /** Optional dependent text field */
  withComment?: boolean;

  /** This field adds numbering in front of the options and their order
   * @example
   * (1) Option one
   * (2) Option two
   */
  numerationOrder?: (typeof numerationOrderValues)[number];

  /** This field specifies the number from which numbering of options begins */
  numerationStartIndex?: number;
}

export type IRadioInput = UserComponent<IRadioInputProps>;

export function radioInputDefaultPropsFactory(): IRadioInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    options: [
      { displayValue: 'Option 1', value: createId(), key: 0 },
      { displayValue: 'Option 2', value: createId(), key: 1 },
    ],
    validation: { ...ValidationDefaultSettings },
  };
}

export const radioInputApplicableValidationRules: ApplicableRules = {
  isRequired: true,
};
