import React from 'react';
import { useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import { ITabsContainer } from '../../../../forms/base/controls/containers/TabsContainer';
import { RPDF } from '../../../react-pdf';
import { renderNode } from '../../recordReport-helper';
import { recordReportFormControlsResolver } from '../../recordReport.resolver';
import { RecordContext } from '../../RecordContext';

export const TabContainer: ITabsContainer = ({ tabLabelList }) => {
  const { id } = useNode();
  const nodes = useContextSelector(RecordContext, (v) => v.nodes);
  const linkedNodes = nodes.find((n) => n.id === id)?.linkedNodes;

  return (
    <RPDF.View>
      {linkedNodes &&
        Object.entries(linkedNodes).map(([key, childId]) => {
          // Tabs start from 1
          const tabNumber = Number(key) + 1;
          const tabName = tabLabelList && tabLabelList.length > 0 ? tabLabelList[Number(key)] : tabNumber;
          return (
            <RPDF.View key={tabNumber} style={{ marginVertical: 4 }}>
              <RPDF.Text minPresenceAhead={40} style={[RPDF.typography.heading4b, { paddingVertical: 4 }]}>
                {tabName}
              </RPDF.Text>
              {renderNode(nodes, recordReportFormControlsResolver, childId)}
            </RPDF.View>
          );
        })}
    </RPDF.View>
  );
};
