/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import clsx from 'clsx';
import { useContextSelector } from 'use-context-selector';
import styles from '../controlsStyle.module.css';
import { useTranslation } from 'react-i18next';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { IContainer, IContainerProps } from '../../../../features/forms/base/controls/containers/Container';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import { enumToDropdownOptions } from 'uikit/inputs/dropdown/dropdown-helper';
import { Permissions } from 'src/services/api/api-client';

export const Container: IContainer = (props) => {
  const { hasPermission } = useHasPermissions();
  const {
    connectors: { connect, drag },
    childrenIds,
  } = useNode((state) => ({
    childrenIds: state.data.nodes,
  }));

  const {
    actions: { setProp },
    builderMode,
  } = useEditor((state) => ({
    builderMode: state.options.enabled,
  }));

  const { isPadding, children, isDisabled } = {
    ...ContainerDefaultProps,
    ...props,
  };

  useEffect(() => {
    if (builderMode) return;

    childrenIds.forEach((id) => {
      setProp(id, (p: any) => (p.isDisabled = isDisabled || (props.permission && !hasPermission(props.permission))));
    });
  }, [childrenIds, hasPermission, isDisabled, props.permission, setProp, builderMode]);

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');

  const viewComponent = <div className={styles.containerBlockView}>{children}</div>;

  const editableComponent = (
    <div
      className={clsx(styles.containerBlock, {
        [styles.containerBlockPadding]: isPadding,
        [styles.horizontalLayout]: props.isFlexBox,
      })}
      style={{
        gap: props.flexBoxGap ? props.flexBoxGap : 0,
        paddingTop: props.padding?.top,
        paddingRight: props.padding?.right,
        paddingBottom: props.padding?.bottom,
        paddingLeft: props.padding?.left,
      }}
      draggable={builderMode}
      ref={(ref: HTMLDivElement) => builderMode && connect(drag(ref))}
      data-border={builderMode}
    >
      {children}
    </div>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

export const ContainerSettings = () => {
  const { t } = useTranslation('dev');
  const { props, actions } = useNode((node) => ({
    props: node.data.props as IContainerProps,
    id: node.id,
  }));
  const { setProp } = actions;

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.container.flexBox')}
          isChecked={!!props.isFlexBox}
          setValue={(newValue) =>
            setProp((itemProps: IContainerProps) => {
              itemProps.isFlexBox = newValue ? newValue : undefined;
            })
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={'Padding - top'}
        expanded={props.padding?.top !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, top: 20 };
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, top: undefined };
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.padding?.top}
          min={0}
          max={100}
          onChange={(e) =>
            setProp((prop: IContainerProps) => {
              prop.padding = { ...prop.padding, top: e.target?.value ? Number(e.target.value) : undefined };
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={'Padding - right'}
        expanded={props.padding?.right !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, right: 20 };
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, right: undefined };
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.padding?.right}
          min={0}
          max={100}
          onChange={(e) =>
            setProp((prop: IContainerProps) => {
              prop.padding = { ...prop.padding, right: e.target?.value ? Number(e.target.value) : undefined };
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={'Padding - bottom'}
        expanded={props.padding?.bottom !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, bottom: 20 };
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, bottom: undefined };
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.padding?.bottom}
          min={0}
          max={100}
          onChange={(e) =>
            setProp((prop: IContainerProps) => {
              prop.padding = { ...prop.padding, bottom: e.target?.value ? Number(e.target.value) : undefined };
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={'Padding - left'}
        expanded={props.padding?.left !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, left: 20 };
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.padding = { ...prop.padding, left: undefined };
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.padding?.left}
          min={0}
          max={100}
          onChange={(e) =>
            setProp((prop: IContainerProps) => {
              prop.padding = { ...prop.padding, left: e.target?.value ? Number(e.target.value) : undefined };
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={t('StudySettingsPage.FormBuilder.settings.container.flexBoxGap')}
        expanded={props.flexBoxGap !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.flexBoxGap = 20;
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.flexBoxGap = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          type={'number'}
          value={props.flexBoxGap}
          min={0}
          max={100}
          onChange={(e) =>
            setProp((prop: IContainerProps) => {
              prop.flexBoxGap = e.target?.value ? Number(e.target.value) : undefined;
            }, 500)
          }
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={'Permission to fill'}
        expanded={props.permission !== undefined}
        onOpen={() => {
          setProp((prop: IContainerProps) => {
            prop.permission = 1;
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IContainerProps) => {
            prop.permission = undefined;
          }, 500);
        }}
      >
        <AppDropDownWithSuggestionInput
          options={enumToDropdownOptions(Permissions)}
          value={enumToDropdownOptions(Permissions).find(
            (x) => props.permission && x.key === Permissions[props.permission],
          )}
          onChange={(x) => {
            setProp((prop: IContainerProps) => {
              prop.permission = x ? Permissions[x.key] : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

export const ContainerDefaultProps: IContainerProps = {};

Container.craft = {
  props: ContainerDefaultProps,
  related: {
    settings: ContainerSettings,
  },
};
