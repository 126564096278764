import { RPDF } from '../react-pdf';
import React from 'react';

export const ReportHeader: React.FC<{
  titleText: string;
  subTitleText?: string;
  footer?: JSX.Element;
}> = ({ titleText, footer, subTitleText }) => {
  return (
    <RPDF.View fixed style={RPDF.styles.headerContainer}>
      <RPDF.View style={{ flexDirection: 'row', gap: 8 }}>
        <RPDF.Text style={RPDF.typography.heading1}>{titleText}</RPDF.Text>
        <RPDF.Text style={RPDF.typography.text20}>{subTitleText}</RPDF.Text>
      </RPDF.View>

      {footer && <RPDF.View>{footer}</RPDF.View>}
    </RPDF.View>
  );
};
