/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UseModalProps } from '../../../application/hooks/useModal';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { FileDtoToAppAttachmentValue } from '../../../helpers/file-helper';
import { QueryFactory } from '../../../services/api';
import {
  CheckedFieldsEnum,
  FileDto,
  IIssueDto,
  IssueOperationStateEnum,
  IssuesStateEnum,
} from '../../../services/api/api-client';
import { AppAttachmentsView } from 'uikit/fields';
import { AppButton } from 'uikit/buttons';
import { Permissions } from 'src/services/api/api-client';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { DropDownMenuOption } from 'uikit/dropDownMenu/dropDownMenu';
import { DropDownMenu } from 'uikit/dropDownMenu/dropDownMenu.component';
import { IssueStateIndicator } from '../stateIndicator/issueStateIndicator.component';
import { IssueHistory } from '../history/issueHistory.component';
import { hasFlag } from '../../../helpers/enum-helper';
import { useQueryClient } from '@tanstack/react-query';
import Style from './issueView.module.scss';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import TabsStyles from '../../../components/tabs/tabs.module.css';
import { isNullOrEmpty } from '../../../helpers/string-helper';
import { logger } from '../../../application/logging/logging';
import { IssueComment } from '../comment/issueComment.component';
import { useContextSelector } from 'use-context-selector';
import { IssuesContext } from '../provider/issues.context';
import { AppTextInput } from '../../uikit/inputs/text/appTextInput.component';
import { AppModalContainer } from '../../uikit/modal/modal.component';
import { Loading } from 'uikit/suspense/Loading';
import { IssueCreationTypeWithId } from '../form/issueForm.component';
import { FeatureFlags } from 'src/application/constants/feature-flags';

import { ReactComponent as NoDiscussion } from 'assets/img/issues/no_discussion.svg';
import { ReactComponent as Edit } from 'assets/img/common/edit_20.svg';
import { ReactComponent as Delete } from 'assets/img/common/delete_20.svg';
import { ReactComponent as Check } from 'assets/img/common/check_16.svg';
import { ReactComponent as Cross } from 'assets/img/common/cross_16.svg';
import { ReactComponent as Dots } from 'assets/img/common/dots_24.svg';
import { ReactComponent as Send } from 'assets/img/common/send_24.svg';
import { ReactComponent as Warning } from 'assets/img/common/tiny_warning_16.svg';
import { ReactComponent as NavigateIcon } from 'assets/img/issues/navigate_20.svg';
import { useStaffProfile } from 'src/helpers/hooks/useStudy';

export type IssueViewType = {
  modal: UseModalProps<number>;
};

const issueFieldToRender: (keyof IIssueDto)[] = [
  'createdByUser',
  'createdAt',
  'state',
  'subject',
  'topic',
  'topicAdditional',
  'fieldDescription',
  'description',
  'recommendedAction',
  'deadlineAt',
  'files',
];

export const IssueViewModal: FC<IssueViewType> = ({ modal }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    editIssue,
    deleteIssue,
    approveOperation,
    rejectOperation,
    revokeOperation,
    performOperation,
    processingIssueId,
    navigateToIssue,
  } = useContextSelector(IssuesContext, (x) => x)!;

  const issueId = modal.params;

  const { data: issue } = QueryFactory.IssueQuery.useGetIssueQuery(issueId, {
    enabled: modal.visible && !!issueId,
    suspense: false,
  });
  const { data: issueHistory, isInitialLoading: historyIsLoading } = QueryFactory.IssueQuery.useGetIssueHistoryQuery(
    issueId,
    {
      enabled: modal.visible,
      suspense: false,
    },
  );
  const { data: issueComments, isInitialLoading: commentsIsLoading } = QueryFactory.IssueQuery.useGetIssueCommentsQuery(
    issueId,
    {
      enabled: modal.visible,
      suspense: false,
    },
  );
  const { profile } = useStaffProfile();
  const [message, setMessage] = useState<string>('');
  const [isSending, setIsSending] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const dummyMessageRef = useRef<HTMLDivElement>(null);
  const issueRef = useRef<HTMLDivElement>(null);

  // This effect check issue on open modal
  useEffect(() => {
    if (modal.visible) {
      QueryFactory.IssueQuery.Client.checkIssue(issueId).then(() =>
        Promise.all([
          queryClient.refetchQueries(QueryFactory.NotificationQuery.getNotificationsQueryKey()),
          queryClient.invalidateQueries(QueryFactory.NotificationQuery.getNotificationBatchesQueryKey()),
          queryClient.invalidateQueries(QueryFactory.NotificationQuery.getNotificationCountsQueryKey()),
        ]),
      );
    }
  }, [issueId, modal.visible, queryClient]);

  //#region Render issue field functions

  const renderDateField = useCallback((rawFieldValue: Date) => {
    return localFormat(rawFieldValue, DateFormats.dateTime);
  }, []);

  const renderDeadlineField = useCallback(
    (rawFieldValue: Date) => {
      if (!issue) return <></>;

      return (
        <div
          className={clsx(Style.deadline, {
            [Style.isExpired]: issue.isExpired,
          })}
        >
          {issue.isExpired && <Warning />}
          <div>{localFormat(rawFieldValue, DateFormats.longDate)}</div>
        </div>
      );
    },
    [issue],
  );

  const renderSubjectField = useCallback(
    (rawFieldValue: string) => {
      let str: string = t(`Issues.Subject.${rawFieldValue}` as LocalizedResourceDictionaryKeys);

      if (issue?.linkedPatientUniqId) {
        str = str.concat(' ', issue.linkedPatientUniqId);
      }

      return str;
    },
    [issue, t],
  );

  const options = useMemo(() => {
    const optionList: DropDownMenuOption[] = [];

    if (issue?.lastOperation?.state === IssueOperationStateEnum.Rejected) {
      optionList.push({
        key: 'Approve',
        icon: <Check />,
        text: t('Issues.Card.MenuOptions.Approve'),
        action: () => approveOperation?.(issue.lastOperation!),
        className: Style.commonOption,
      });
    }

    if (issue?.lastOperation?.state === IssueOperationStateEnum.Approved) {
      optionList.push({
        key: 'Decline',
        icon: <Cross />,
        text: t('Issues.Card.MenuOptions.Decline'),
        action: () => rejectOperation?.(issue.lastOperation!),
        className: Style.commonOption,
      });
    }

    return optionList;
  }, [approveOperation, issue, rejectOperation, t]);

  const onStatusClickHandler = useCallback(() => {
    if (issue?.lastOperation?.state === IssueOperationStateEnum.None) {
      revokeOperation?.(issue.lastOperation);
    } else {
      performOperation?.(issueId);
    }
  }, [issueId, issue, performOperation, revokeOperation]);

  const navigateToIssueHandler = useCallback(async () => {
    try {
      setIsNavigating(true);
      await navigateToIssue?.(issueId);
      modal.closeModal();
    } catch (e: any) {
      logger().error('Navigation to the issue failed.', e);
    } finally {
      setIsNavigating(false);
    }
  }, [issueId, modal, navigateToIssue]);

  const renderStateField = useCallback(
    (rawFieldValue: IssuesStateEnum) => {
      if (!issue) return <></>;

      return (
        <div className={Style.stateContainer}>
          <div className={Style.state}>
            <IssueStateIndicator
              state={issue.state}
              onClick={onStatusClickHandler}
              isLoading={processingIssueId === issueId}
            />
            <div>{t(`Issues.Status.${IssuesStateEnum[rawFieldValue]}` as LocalizedResourceDictionaryKeys)}</div>
            <div
              className={clsx({ [Style.newMark]: !hasFlag(issue.checkedFields, CheckedFieldsEnum.State) })}
              data-test-id={'issue-view-new-mark'}
            />
          </div>
          <PermissionsCheck permissions={Permissions.IssueApprove}>
            {options.length > 0 && <DropDownMenu Icon={Dots} options={options} colorSchema={'basic'} />}
            {issue.state === IssuesStateEnum.Processed && (
              <div className={Style.resumeButton}>
                <AppButton
                  variant={'icon-link'}
                  colorSchema={'confirm'}
                  text={t('Issues.Card.MenuOptions.Approve')}
                  Icon={Check}
                  onClick={() => {
                    approveOperation?.(issue.lastOperation!);
                  }}
                />
                <AppButton
                  variant={'icon-link'}
                  colorSchema={'decline'}
                  text={t('Issues.Card.MenuOptions.Decline')}
                  Icon={Cross}
                  onClick={() => rejectOperation?.(issue.lastOperation!)}
                />
              </div>
            )}
          </PermissionsCheck>
        </div>
      );
    },
    [issue, onStatusClickHandler, processingIssueId, issueId, t, options, approveOperation, rejectOperation],
  );

  const renderFilesField = useCallback((rawFieldValue: FileDto[]) => {
    return <AppAttachmentsView files={rawFieldValue?.map(FileDtoToAppAttachmentValue)} />;
  }, []);

  const renderCommonFields = (rawFieldValue: any) => {
    return rawFieldValue?.toString();
  };

  //#endregion

  const renderFieldByName = useMemo(() => {
    return {
      createdAt: renderDateField,
      deadlineAt: renderDeadlineField,
      state: renderStateField,
      files: renderFilesField,
      subject: renderSubjectField,
    };
  }, [renderDateField, renderDeadlineField, renderFilesField, renderStateField, renderSubjectField]);

  const renderIssueFieldValue = useCallback(
    <T,>(fieldName: keyof IIssueDto, fieldValue: T) => {
      if (renderFieldByName[fieldName]) {
        return renderFieldByName[fieldName](fieldValue);
      }

      return renderCommonFields(fieldValue);
    },
    [renderFieldByName],
  );

  const renderIssueFields = useCallback(() => {
    if (!issue) return <></>;

    return issueFieldToRender.map((fieldName) => {
      const fieldValue = issue[fieldName];

      if (
        fieldValue === undefined ||
        fieldValue === null ||
        (Array.isArray(fieldValue) && !(fieldValue as any[]).length)
      ) {
        return <React.Fragment key={fieldName} />;
      }

      return (
        <div key={fieldName} className={Style.field}>
          <div className={Style.label}>
            {t(`Issues.ViewModal.FieldLabels.${fieldName}` as LocalizedResourceDictionaryKeys)}
          </div>
          <div className={Style.value} data-test-id={'field-value'}>
            {renderIssueFieldValue<typeof fieldValue>(fieldName, fieldValue)}
          </div>
        </div>
      );
    });
  }, [issue, renderIssueFieldValue, t]);

  const onModalHide = useCallback(async () => {
    modal.closeModal();
    if (!issue) return;
    await queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssuesForStudyQueryKey(issue.studyId));
  }, [modal, issue, queryClient]);

  const addComment = useCallback(async () => {
    if (isNullOrEmpty(message)) return;
    setIsSending(true);

    try {
      await QueryFactory.IssueQuery.Client.addIssueComment(issueId, message);
      await queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssueCommentsQueryKey(issueId));

      setMessage('');
      dummyMessageRef?.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error: any) {
      logger().error(error);
    } finally {
      setIsSending(false);
    }
  }, [issueId, message, queryClient]);

  const removeComment = useCallback(
    async (commentId: number) => {
      setIsDeleting(true);
      try {
        await QueryFactory.IssueQuery.Client.removeIssueComment(commentId);
        await queryClient.invalidateQueries(QueryFactory.IssueQuery.getIssueCommentsQueryKey(issueId));
      } catch (error: any) {
        logger().error(error);
      } finally {
        setIsDeleting(false);
      }
    },
    [issueId, queryClient],
  );

  const commentTabHasNewMark = useMemo(() => {
    return (
      (issue?.checkedFields && !hasFlag(issue.checkedFields, CheckedFieldsEnum.Comment)) ||
      (!issue?.checkedFields && issueComments && issueComments.length > 0)
    );
  }, [issue?.checkedFields, issueComments]);

  const openEditModal = useCallback(async () => {
    if (!issue) return;
    const {
      deadlineAt,
      description,
      files,
      linkedPatientUniqId,
      recommendedAction,
      subject,
      topic,
      topicAdditional,
      id,
    } = issue;

    editIssue?.({
      issueId: id,
      deadlineAt,
      description,
      files: files.map(FileDtoToAppAttachmentValue),
      linkedPatientUniqId: linkedPatientUniqId || undefined,
      recommendedAction,
      subject,
      topic,
      topicAdditional: topicAdditional || undefined,
    } as IssueCreationTypeWithId);
  }, [editIssue, issue]);

  const modalHeader = useMemo(() => {
    if (FeatureFlags.isIssueTargetEnabled() && issue?.linkedPatientUniqId) {
      return (
        <AppButton
          variant={'icon-link'}
          colorSchema={'primary'}
          Icon={NavigateIcon}
          onClick={navigateToIssueHandler}
          className={Style.navigationButton}
          iconClassName={Style.navigateIcon}
          text={isNavigating ? undefined : t('Issues.ViewModal.NavigateToIssue')}
          showTextOnHover={!isNavigating}
          isLoading={isNavigating}
        />
      );
    }

    return <></>;
  }, [isNavigating, issue?.linkedPatientUniqId, navigateToIssueHandler, t]);

  return (
    <AppModalContainer
      visible={modal.visible}
      onHide={onModalHide}
      title={t('Issues.ViewModal.Title', { id: issueId })}
      bodyClassName={Style.modalBody}
      testId={'view-issue-modal'}
      header={modalHeader}
      headerClassName={Style.modalHeader}
    >
      <Loading
        loading={!issue || historyIsLoading || commentsIsLoading}
        renderChildren={false}
        containerClassName={Style.container}
        testId={'issue-view'}
      >
        {issue && (
          <>
            <div ref={issueRef} className={Style.issueContent}>
              {issue.updatedAt && (
                <div className={Style.header} data-test-id={'header'}>
                  {t('Issues.ViewModal.UpdatedAt', {
                    date: localFormat(issue.updatedAt, DateFormats.dateTime),
                    user: issue.updatedByUser,
                  })}
                  {!hasFlag(issue.checkedFields, CheckedFieldsEnum.Content) && <div className={Style.newMark} />}
                </div>
              )}

              <PermissionsCheck permissions={Permissions.IssueCreate}>
                <div className={Style.actionBlock}>
                  {issue.state !== IssuesStateEnum.Closed && (
                    <AppButton
                      variant={'icon-link'}
                      colorSchema={'secondary'}
                      Icon={Edit}
                      onClick={openEditModal}
                      testId={'issue-view-edit-button'}
                    />
                  )}
                  {issue.state === IssuesStateEnum.Opened && (
                    <AppButton
                      variant={'icon-link'}
                      colorSchema={'decline'}
                      Icon={Delete}
                      onClick={() => deleteIssue?.(issueId)}
                      testId={'issue-view-delete-button'}
                    />
                  )}
                </div>
              </PermissionsCheck>
              {renderIssueFields()}
            </div>

            <Tabs
              className={clsx(TabsStyles.tabs, Style.overflowHidden)}
              style={{ height: issueRef?.current?.clientHeight }}
              onSelect={() => {
                setTimeout(() => {
                  dummyMessageRef?.current?.scrollIntoView();
                }, 0);
              }}
            >
              <TabList className={Style.tabList}>
                <Tab
                  className={TabsStyles.tab}
                  selectedClassName={TabsStyles.tab_selected}
                  disabledClassName={TabsStyles.tab_disabled}
                >
                  {t('Issues.ViewModal.Tabs.History')}
                </Tab>
                <Tab
                  className={TabsStyles.tab}
                  selectedClassName={TabsStyles.tab_selected}
                  disabledClassName={TabsStyles.tab_disabled}
                >
                  {commentTabHasNewMark && (
                    <div className={Style.tabNewMark} data-test-id={'issue-discussion-new-mark'} />
                  )}
                  {t('Issues.ViewModal.Tabs.Discussion')}
                </Tab>
              </TabList>
              <TabPanel>
                <div className={Style.historyContainer}>
                  {issueHistory?.actions.map((historyItemDto, i) => (
                    <IssueHistory key={i} {...historyItemDto} />
                  ))}
                </div>
              </TabPanel>
              <TabPanel className={Style.tabPanel} data-test-id={'issue-discussion-section'}>
                <Loading
                  loading={isDeleting}
                  testId={'issue-discussion'}
                  containerClassName={Style.scrollableContainer}
                >
                  {issueComments?.length ? (
                    <>
                      <div className={Style.comments}>
                        {issueComments?.map((comment) => (
                          <IssueComment
                            key={comment.id}
                            hasMenu={!isDeleting && profile?.id === comment.createdByUserId}
                            onDelete={() => removeComment(comment.id)}
                            hasNewMark={
                              (!issue.lastCheckedAt || issue.lastCheckedAt < comment.createdAt) &&
                              profile?.id !== comment.createdByUserId
                            }
                            {...comment}
                          />
                        ))}
                      </div>
                      <div ref={dummyMessageRef} />
                    </>
                  ) : (
                    <div className={Style.placeholder}>
                      <NoDiscussion />
                      <p>{t('Issues.ViewModal.CommentSection.Placeholder')}</p>
                    </div>
                  )}
                </Loading>

                <PermissionsCheck permissions={Permissions.IssueDiscuss}>
                  <div className={Style.inputContainer}>
                    <AppTextInput
                      type={'text-area'}
                      placeholder={t('Issues.ViewModal.CommentSection.InputPlaceholder')}
                      className={Style.inputField}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      disabled={isSending}
                    />
                    <AppButton
                      Icon={Send}
                      variant={'icon-link'}
                      colorSchema={'primary'}
                      onClick={addComment}
                      disabled={isSending}
                      isLoading={isSending}
                      className={Style.sendButton}
                      testId={'issue-comment-send-button'}
                    />
                  </div>
                </PermissionsCheck>
              </TabPanel>
            </Tabs>
          </>
        )}
      </Loading>
    </AppModalContainer>
  );
};
