import React, { useCallback, useMemo, useState, FC } from 'react';
import { IAppDaterangePickerProps } from './daterangePicker';
import { ReactComponent as ArrowIcon } from '../../assets/img/common/arrow_32.svg';
import { ReactComponent as Calendar20Icon } from './../../assets/img/common/calendar_20.svg';
import { ReactComponent as Calendar24Icon } from './../../assets/img/common/calendar_24.svg';
import { ReactComponent as CrossIcon } from './../../assets/img/common/cross_24.svg';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { addDays, compareAsc, compareDesc, format, isAfter, isEqual, startOfDay } from 'date-fns';
import clsx from 'clsx';
import {
  subTimeUnitFromDate,
  getMaxDate,
  getMinDate,
  addTimeUnitToDate,
  localFormat,
} from '../../helpers/date-helpers';
import Style from './daterange.module.scss';
import { useTranslation } from 'react-i18next';
import { unionIntervals, isBeforeOrEqual, intervalContains, isAfterOrEqual } from './daterange-helper';

export const AppDateRangePicker: FC<IAppDaterangePickerProps> = (props) => {
  const {
    i18n: { language },
  } = useTranslation();

  const [firstSelectedRangeBound, setFirstSelectedRangeBound] = useState<Date | null>(null);

  const reservedRanges = useMemo(() => unionIntervals(props.reservedRanges ?? []), [props.reservedRanges]);
  let disabledRanges = useMemo(() => {
    return unionIntervals(
      (props.disabledRanges ?? [])
        .concat(reservedRanges)
        .concat(props.minDate ? [{ start: getMinDate(), end: props.minDate }] : [])
        .concat(props.maxDate ? [{ start: addDays(props.maxDate, 1), end: getMaxDate() }] : []),
    );
  }, [props.disabledRanges, props.maxDate, props.minDate, reservedRanges]);

  disabledRanges = useMemo(() => {
    if (firstSelectedRangeBound === null) return disabledRanges;

    const ranges = [] as Interval[];

    const leftDisabledRange =
      disabledRanges
        .filter((x) => isBeforeOrEqual(x.end, firstSelectedRangeBound))
        .sort((a, b) => compareDesc(a.end, b.end))[0] ?? null;

    if (leftDisabledRange) ranges.push({ start: getMinDate(), end: leftDisabledRange.end });

    const rightDisabledRange =
      disabledRanges
        .filter((x) => isAfter(x.start, firstSelectedRangeBound))
        .sort((a, b) => compareAsc(a.end, b.end))[0] ?? null;

    if (rightDisabledRange) ranges.push({ start: rightDisabledRange.start, end: getMaxDate() });

    return ranges;
  }, [disabledRanges, firstSelectedRangeBound]);

  const tileDisabled = useCallback(
    ({ date, view }: { date: Date; view: string }) =>
      firstSelectedRangeBound === null && view === 'month' && disabledRanges.some((r) => intervalContains(r, date)),
    [firstSelectedRangeBound, disabledRanges],
  );

  const tileClassName = useCallback(
    ({ date, view }: { date: Date; view: string }) => {
      if (view !== 'month') return '';

      const isDisabledRange = disabledRanges.some((x) => intervalContains(x, date));

      const isReservedRange = reservedRanges.some((x) => intervalContains(x, date));
      const isReservedRangeStart = isReservedRange && reservedRanges.some((x) => isEqual(date, x.start));
      const isReservedRangeEnd =
        isReservedRange && reservedRanges.some((x) => isEqual(date, subTimeUnitFromDate(x.end, 'days')));
      const isReservedRangeBothEnds = isReservedRangeStart && isReservedRangeEnd;

      const isFreeRange = !isReservedRange && !isDisabledRange;
      const isFreeRangeStart = isFreeRange && disabledRanges.some((x) => isEqual(date, x.end));
      const isFreeRangeEnd =
        isFreeRange && disabledRanges.some((x) => isEqual(addTimeUnitToDate(date, 'days'), x.start));
      const isFreeRangeBothEnds = isFreeRangeStart && isFreeRangeEnd;

      return clsx({
        [Style.isDisabledRange]: isDisabledRange,

        [Style.isFreeRange]: isFreeRange,
        [Style.isFreeRangeStart]: isFreeRangeStart,
        [Style.isFreeRangeEnd]: isFreeRangeEnd,
        [Style.isFreeRangeBothEnds]: isFreeRangeBothEnds,

        [Style.isReservedRange]: isReservedRange,
        [Style.isReservedRangeStart]: isReservedRangeStart,
        [Style.isReservedRangeEnd]: isReservedRangeEnd,
        [Style.isReservedRangeBothEnds]: isReservedRangeBothEnds,
      });
    },
    [disabledRanges, reservedRanges],
  );

  const onClickDay = useCallback((date: Date) => {
    setFirstSelectedRangeBound((x) => x ?? date);
  }, []);

  const onChange = useCallback(
    (value: Date[] | null) => {
      setFirstSelectedRangeBound(null);
      if (value === null) {
        props.onSelectedRangeChanged(null);
        return;
      }

      value[1] = startOfDay(value[1]);

      const firstBound = disabledRanges.some((x) => intervalContains(x, value[0])) ? value[1] : value[0];

      let date = isEqual(value[0], firstBound) ? value[1] : value[0];

      const disabled = disabledRanges.find((x) => intervalContains(x, date));

      if (disabled) {
        if (isAfterOrEqual(firstBound, disabled.end)) {
          date = new Date(disabled.end);
        } else {
          date = subTimeUnitFromDate(disabled.start, 'days');
        }
      }

      if (isAfter(firstBound, date)) {
        props.onSelectedRangeChanged({ start: date, end: addTimeUnitToDate(firstBound, 'days') });
      } else {
        props.onSelectedRangeChanged({ start: firstBound, end: addTimeUnitToDate(date, 'days') });
      }
    },
    [disabledRanges, props],
  );

  return (
    <DateRangePicker
      className={Style.appDaterangePicker}
      locale={language}
      disabled={props.disabled}
      // @ts-ignore
      value={
        props.selectedRange
          ? [props.selectedRange.start as Date, subTimeUnitFromDate(props.selectedRange.end, 'days')]
          : null
      }
      calendarIcon={<Calendar24Icon />}
      clearIcon={props.selectedRange ? <CrossIcon /> : null}
      // @ts-ignore
      formatMonth={(locale, date) => localFormat(date, 'MMM')}
      // @ts-ignore
      onChange={onChange}
      tileDisabled={tileDisabled}
      tileClassName={tileClassName}
      nextLabel={<ArrowIcon />}
      prevLabel={<ArrowIcon />}
      navigationLabel={({ date }: { date: Date }) => (
        <>
          <Calendar20Icon />
          <span>{format(date, 'MMM yyyy')}</span>
        </>
      )}
      onClickDay={onClickDay}
    />
  );
};
