import React, { useMemo, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import clsx from 'clsx';
import { convertDateToString, isValidDate, localFormat, toDateOrNull } from '../../../../helpers/date-helpers';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import styles from '../controlsStyle.module.css';
import {
  dateInputDefaultPropsFactory,
  dateInputApplicableValidationRules,
  IDateInputProps,
} from '../../../../features/forms/base/controls/inputs/DateInput';
import {
  useRefForDataBlockNavigation,
  useFieldDataFromUiEditorContext,
  useFieldValidation,
  useSkipReasonText,
  useDefaultValuesForConstructorMode,
} from './base/hooks';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { AppTextField } from 'uikit/fields';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { startOfToday, subDays, toDate } from 'date-fns';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';
import { CONST_DATA_KEYS } from '../../uiEditor/uiEditor-helper';

export const DateInput = (props: IDateInputProps) => {
  const study = useStudy();
  const {
    connectors: { connect, drag },
    actions: { setProp: setProps },
    id,
  } = useNode();
  const { size, dataKey, isDisableWhenEditing, isDisabled, hasSkipCheckBox, skipCheckBoxText } = props;

  const { enabled: isConstructorMode } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const {
    isOnlyView,
    isEditMode,
    singleInputValue,
    setDataBlockFieldValue,
    skipReason,
    onSkipReasonChange,
    fieldEditReason,
  } = useFieldDataFromUiEditorContext(dataKey);

  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const { defaultValue } = useDefaultValuesForConstructorMode(props);

  const blockRef = useRefForDataBlockNavigation();
  const [initialValue] = useState(singleInputValue);
  const { dataBlockValidation, triggerFieldValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const isStudyStartDate = props.dataKey === CONST_DATA_KEYS.StudyStartedAt;

  const minDate = useMemo(() => {
    if (isStudyStartDate) {
      const studyStartMinDate = subDays(new Date(), study?.patientStudyStartAdvanceDays ?? 0);
      if (props.validation.minDateAsToday) {
        return convertDateToString(toDate(Math.max(studyStartMinDate.getTime(), startOfToday().getTime())));
      }

      if (props.validation.minDate) {
        const minValueAsDate = new Date(Date.parse(props.validation.minDate));
        return convertDateToString(toDate(Math.max(studyStartMinDate.getTime(), minValueAsDate.getTime())));
      }

      return convertDateToString(subDays(new Date(), study?.patientStudyStartAdvanceDays ?? 0));
    }

    if (props.validation.minDateAsToday) {
      return convertDateToString(startOfToday());
    }

    if (props.validation.minDate) {
      const validationDate = new Date(Date.parse(props.validation.minDate));
      return isValidDate(validationDate) ? convertDateToString(new Date(validationDate)) : undefined;
    }

    return undefined;
  }, [
    isStudyStartDate,
    props.validation.minDate,
    props.validation.minDateAsToday,
    study?.patientStudyStartAdvanceDays,
  ]);

  const maxDate = useMemo(() => {
    if (props.validation.maxDateAsToday) {
      return convertDateToString(startOfToday());
    }

    if (props.validation.maxDate) {
      const validationDate = new Date(Date.parse(props.validation.maxDate));
      return isValidDate(validationDate) ? convertDateToString(new Date(validationDate)) : undefined;
    }

    return undefined;
  }, [props.validation.maxDate, props.validation.maxDateAsToday]);

  const editableComponent = (
    <div
      data-test-field-type={'date-input'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef} className={clsx({ [styles.validationError]: !!dataBlockValidation?.text })}>
        <AppTextField
          labelProps={{
            isBold: props.isBold,
            text: !props.hideLabel ? props.label || props.text || dataKey : undefined,
            tooltip: props.tooltip,
          }}
          placeholder={props.placeholder}
          disabled={
            !props.isEditable || (isDisableWhenEditing && isEditMode && !!initialValue) || isDisabled || isSubmitting
          }
          onChange={(e) => {
            const parsedTime: Date | null = e.target.value ? new Date(Date.parse(e.target.value)) : null;
            if (parsedTime?.toDateString() === 'Invalid Date') {
              return;
            }
            setDataBlockFieldValue?.(dataKey, e.target.value || undefined);

            if (isConstructorMode) {
              setProps((p: IDateInputProps) => {
                p.defaultValue = e.target.value;
              });
            }
          }}
          min={minDate}
          max={maxDate}
          onBlur={triggerFieldValidation}
          type={'date'}
          value={isConstructorMode ? defaultValue : singleInputValue || ''}
          errorProps={{ errors: dataBlockValidation?.text }}
          skipProps={{
            inputCanBeSkipped: hasSkipCheckBox,
            skipText: skipCheckBoxText,
            onSkipReasonChange: onSkipReasonChange(dataKey),
            skipReason: skipReason,
          }}
        />
      </div>
    </div>
  );

  const date = toDateOrNull(singleInputValue);
  const viewComponent = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResultVersion?.formResultId,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
    >
      <OverviewField
        label={props.label ?? props.dataKey}
        value={(date && localFormat(date)) ?? undefined}
        skipped={!singleInputValue && skipReason !== undefined}
        skipReason={skipReasonText}
        editReason={fieldEditReason}
      />
    </IssueMark>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

const DateInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as IDateInputProps,
  }));

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.defaultValueAsNow')}
          isChecked={!!props.defaultValueIsNow}
          setValue={(newValue) =>
            setProp((setProps: IDateInputProps) => {
              setProps.defaultValueIsNow = newValue ? newValue : undefined;
            })
          }
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

const DateInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <DateInputGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={dateInputApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

DateInput.craft = {
  props: dateInputDefaultPropsFactory(),
  related: {
    settings: DateInputSettings,
  },
};
