﻿import { ArrayParam, QueryParamConfig } from 'react-router-url-params';
import { useCallback, useRef, useState } from 'react';
import { DecodedValueMap, decodeQueryParams, encodeQueryParams, QueryParamConfigMap } from 'serialize-query-params';
import { SetQueryLocal } from 'react-router-url-params/src/useQueryParams';
import shallowEqual from 'react-router-url-params/src/shallowEqual';

export const NumberArrayParam: QueryParamConfig<number[] | null | undefined, number[] | null | undefined> = {
  encode(numbers) {
    // null or undefined
    if (!numbers) return numbers;

    const formattedNumbers = numbers.map((x) => x.toString());

    return ArrayParam.encode(formattedNumbers);
  },
  decode(encodedValue) {
    const decodedStrings = ArrayParam.decode(encodedValue);

    return decodedStrings?.map((x) => (x ? parseFloat(x) : NaN)).filter((x) => !isNaN(x));
  },
};

/** As {@link ArrayParam} but without falsy values in an array */
export const StringArrayParam: QueryParamConfig<string[] | null | undefined, string[] | null | undefined> = {
  encode(strings) {
    // null, undefined or empty strings
    if (!strings) return strings;

    return ArrayParam.encode(strings);
  },
  decode(encodedValue) {
    const decodedStrings = ArrayParam.decode(encodedValue);

    return decodedStrings?.filter((x) => x) as string[] | null | undefined;
  },
};

export const useInMemoryQueryParams = <QPCMap extends QueryParamConfigMap>(
  paramConfigMap: QPCMap,
): [DecodedValueMap<QPCMap>, SetQueryLocal<QPCMap>] => {
  const paramConfigMapRef = useRef(paramConfigMap);
  paramConfigMap = shallowEqual(paramConfigMap, paramConfigMapRef.current) ? paramConfigMapRef.current : paramConfigMap;
  paramConfigMapRef.current = paramConfigMap;

  const [decodedParams, setDecodedParams] = useState(() => {
    return decodeQueryParams(paramConfigMap, {});
  });

  const setValue: SetQueryLocal<QPCMap> = useCallback(
    (changes, updateType) => {
      setDecodedParams((prev) => {
        const changesToUse = typeof changes === 'function' ? changes(prev) : changes;

        const encodedParams = {
          ...prev,
          ...encodeQueryParams(paramConfigMapRef.current, changesToUse),
        };

        return updateType === 'push' || updateType === 'replace'
          ? decodeQueryParams(paramConfigMap, encodedParams)
          : { ...prev, ...decodeQueryParams(paramConfigMap, encodedParams) };
      });
    },
    [paramConfigMap],
  );

  return [decodedParams, setValue];
};
