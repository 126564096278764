import { useAuth } from './auth-interceptor';
import { useCallback, useMemo } from 'react';
import { Permissions } from 'src/services/api/api-client';

export const useUserRoles = (): string[] => {
  const authState = useAuth();
  if (!authState?.claims.role) return [];

  return useMemo(() => {
    if (Array.isArray(authState?.claims.role)) return authState?.claims.role as string[];

    return [authState.claims.role];
  }, [authState]);
};

export const useUserPermissions = (): Permissions[] => {
  const authState = useAuth();
  if (!authState?.claims.Permissions) return [];

  return useMemo(() => {
    if (Array.isArray(authState.claims.Permissions))
      return (authState.claims.Permissions as string[]).map((x) => Permissions[x]);

    return [Permissions[authState.claims.Permissions]];
  }, [authState]);
};

export const useUserId = (): string => {
  const authState = useAuth();
  return useMemo(() => authState?.claims.id || '', [authState]);
};

export const useHasPermissions = () => {
  const userPermissions = useUserPermissions();

  const hasOneOfPermissions = useCallback(
    (...permissions: Permissions[]) => permissions.some((permission) => userPermissions.includes(permission)),
    [],
  );
  const hasAllPermissions = useCallback(
    (...permissions: Permissions[]) => permissions.every((permission) => userPermissions.includes(permission)),
    [],
  );
  const hasPermission = useCallback((permission: Permissions) => userPermissions.includes(permission), []);

  return {
    hasPermission,
    hasOneOfPermissions,
    hasAllPermissions,
  };
};
