import React from 'react';
import { CheckboxSettings } from '../settingsInputs/CheckboxSettings';
import { Trans, useTranslation } from 'react-i18next';
import { PanelContainer } from '../../components/panelContainer';
import { ControlsGroup } from '../../components/controlsGroup';
import { SettingsInput } from '../settingsInputs/settingsInput';
import { useNode } from '@craftjs/core';
import { convertDateToString } from 'src/helpers/date-helpers';
import Style from './panelStyles.module.scss';
import { startOfToday } from 'date-fns';

export type ValidationProps = {
  isRequired?: boolean;
  isRequiredErrorMessage?: string | null;
  minValue?: number | null;
  minValueErrorMessage?: string | null;
  maxValue?: number | null;
  maxValueErrorMessage?: string | null;
  pattern?: string | null;
  patternErrorMessage?: string | null;
  maxLength?: number | null;
  maxLengthErrorMessage?: string | null;
  minDate?: string | null;
  minDateErrorMessage?: string | null;
  minDateAsToday?: boolean;
  maxDate?: string | null;
  maxDateErrorMessage?: string | null;
  maxDateAsToday?: boolean;
};

export type ValidationOptions = {
  disable?: boolean;
  ignoreIsRequiredRule?: boolean;
  eCRF?: boolean;
};

export type ValidationSettingsProps = {
  validation: ValidationProps;
};

/** A list of validation rules that can be applied to an input */
export type ApplicableRules = { [key in keyof ValidationProps]?: boolean };

export type ValidationComponentProps = {
  props?: ValidationSettingsProps;
  setProp?: (func: (props: ValidationSettingsProps) => void) => void;
  setState?: (props: ValidationSettingsProps) => void;
  applicableRules: ApplicableRules;
};

export const ValidationSettings: React.FC<ValidationComponentProps> = ({
  applicableRules,
}: ValidationComponentProps) => {
  const { t } = useTranslation('dev');
  const { t: commonTranslation } = useTranslation();
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as ValidationSettingsProps,
  }));

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.Validation.Header')} expanded={false}>
      {applicableRules.isRequired && (
        <ControlsGroup flexible>
          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.Validation.Required')}
            isChecked={props.validation?.isRequired || false}
            setValue={(value) => {
              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.isRequired = value ? value : undefined;
                if (!value) itemProps.validation.isRequiredErrorMessage = undefined;
              }, 500);
            }}
          />
          {props.validation?.isRequired && (
            <SettingsInput
              multiple={true}
              label={t('StudySettingsPage.FormBuilder.Validation.ErrorMessageLabel')}
              placeholder={commonTranslation('LocalErrors.FieldRequired')}
              value={props.validation.isRequiredErrorMessage || undefined}
              onChange={(e) =>
                setProp((p: ValidationSettingsProps) => {
                  p.validation.isRequiredErrorMessage = e.target?.value ? e.target.value : undefined;
                }, 500)
              }
            />
          )}
        </ControlsGroup>
      )}

      {applicableRules.minValue && (
        <ControlsGroup
          collapsible
          flexible
          expanded={props.validation?.minValue !== undefined && props.validation?.minValue !== null}
          header={t('StudySettingsPage.FormBuilder.Validation.Min')}
          onOpen={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.minValue = 0;
            }, 500);
          }}
          onClose={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.minValue = undefined;
              itemProps.validation.minValueErrorMessage = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            type={'number'}
            max={props.validation.maxValue || undefined}
            value={props?.validation?.minValue ?? ''}
            onChange={(e) => {
              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.minValue = e.target.value ? parseFloat(e.target.value) : undefined;
              });
            }}
          />
          <SettingsInput
            multiple={true}
            label={t('StudySettingsPage.FormBuilder.Validation.ErrorMessageLabel')}
            placeholder={commonTranslation('LocalErrors.MinValue', {
              minValue: props.validation.minValue,
            })}
            value={props.validation.minValueErrorMessage || undefined}
            onChange={(e) =>
              setProp((p: ValidationSettingsProps) => {
                p.validation.minValueErrorMessage = e.target?.value ? e.target.value : undefined;
              }, 500)
            }
            tooltip={
              <Trans
                t={t}
                i18nKey={'StudySettingsPage.FormBuilder.Validation.TooltipForErrorMessage'}
                components={{
                  Bold: <span className={Style.boldText} />,
                }}
              />
            }
          />
        </ControlsGroup>
      )}

      {applicableRules.maxValue && (
        <ControlsGroup
          collapsible
          flexible
          expanded={props.validation?.maxValue !== undefined && props.validation?.maxValue !== null}
          header={t('StudySettingsPage.FormBuilder.Validation.Max')}
          onOpen={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.maxValue = (itemProps.validation.minValue || 0) + 100;
            }, 500);
          }}
          onClose={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.maxValue = undefined;
              itemProps.validation.maxValueErrorMessage = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            type={'number'}
            min={props.validation.minValue || undefined}
            value={props?.validation?.maxValue ?? ''}
            onChange={(e) => {
              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.maxValue = e.target.value ? parseFloat(e.target.value) : undefined;
              });
            }}
          />
          <SettingsInput
            multiple={true}
            label={t('StudySettingsPage.FormBuilder.Validation.ErrorMessageLabel')}
            placeholder={commonTranslation('LocalErrors.MaxValue', {
              maxValue: props.validation.maxValue,
            })}
            value={props.validation.maxValueErrorMessage || undefined}
            onChange={(e) =>
              setProp((p: ValidationSettingsProps) => {
                p.validation.maxValueErrorMessage = e.target?.value ? e.target.value : undefined;
              }, 500)
            }
            tooltip={
              <Trans
                t={t}
                i18nKey={'StudySettingsPage.FormBuilder.Validation.TooltipForErrorMessage'}
                components={{
                  Bold: <span className={Style.boldText} />,
                }}
              />
            }
          />
        </ControlsGroup>
      )}

      {applicableRules.minDate && (
        <ControlsGroup
          collapsible
          flexible
          expanded={!!props.validation?.minDate || !!props.validation?.minDateAsToday}
          header={t('StudySettingsPage.FormBuilder.Validation.Min')}
          onOpen={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.minDate = convertDateToString(new Date());
            }, 500);
          }}
          onClose={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.minDate = undefined;
              itemProps.validation.minDateAsToday = undefined;
              itemProps.validation.minDateErrorMessage = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            type={'date'}
            max={props.validation.maxDate || undefined}
            disabled={!!props.validation?.minDateAsToday}
            value={
              !!props.validation?.minDateAsToday
                ? convertDateToString(startOfToday())
                : props.validation.minDate || undefined
            }
            onChange={(e) => {
              const parsedTime: Date | null = e.target.value ? new Date(Date.parse(e.target.value)) : null;
              if (parsedTime?.toDateString() === 'Invalid Date') {
                return;
              }
              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.minDate = e.target.value ? convertDateToString(parsedTime) : undefined;
              }, 500);
            }}
          />
          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.Validation.dateAsNow')}
            isChecked={props.validation?.minDateAsToday || false}
            setValue={(value) => {
              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.minDateAsToday = value ? value : undefined;
                itemProps.validation.minDate = value ? undefined : convertDateToString(startOfToday());
              }, 500);
            }}
            tooltip={
              <Trans
                t={t}
                i18nKey={'StudySettingsPage.FormBuilder.Validation.TooltipDateAsNow'}
                components={{
                  Bold: <span className={Style.boldText} />,
                }}
              />
            }
          />

          <SettingsInput
            multiple={true}
            label={t('StudySettingsPage.FormBuilder.Validation.ErrorMessageLabel')}
            placeholder={commonTranslation('LocalErrors.DateIsTooSmall')}
            value={props.validation.minDateErrorMessage || undefined}
            onChange={(e) =>
              setProp((p: ValidationSettingsProps) => {
                p.validation.minDateErrorMessage = e.target?.value ? e.target.value : undefined;
              }, 500)
            }
            tooltip={
              <Trans
                t={t}
                i18nKey={'StudySettingsPage.FormBuilder.Validation.TooltipForErrorMessage'}
                components={{
                  Bold: <span className={Style.boldText} />,
                }}
              />
            }
          />
        </ControlsGroup>
      )}

      {applicableRules.maxDate && (
        <ControlsGroup
          collapsible
          flexible
          expanded={!!props.validation?.maxDate || !!props.validation?.maxDateAsToday}
          header={t('StudySettingsPage.FormBuilder.Validation.Max')}
          onOpen={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.maxDate = convertDateToString(new Date());
            }, 500);
          }}
          onClose={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.maxDate = undefined;
              itemProps.validation.maxDateAsToday = undefined;
              itemProps.validation.maxDateErrorMessage = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            type={'date'}
            disabled={!!props.validation?.maxDateAsToday}
            min={props.validation.minDate || undefined}
            value={
              !!props.validation?.maxDateAsToday
                ? convertDateToString(startOfToday())
                : props.validation.maxDate || undefined
            }
            onChange={(e) => {
              const parsedTime: Date | null = e.target.value ? new Date(Date.parse(e.target.value)) : null;
              if (parsedTime?.toDateString() === 'Invalid Date') {
                return;
              }

              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.maxDate = e.target.value ? convertDateToString(parsedTime) : undefined;
              }, 500);
            }}
          />

          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.Validation.dateAsNow')}
            isChecked={props.validation?.maxDateAsToday || false}
            setValue={(value) => {
              setProp((itemProps: ValidationSettingsProps) => {
                itemProps.validation.maxDateAsToday = value ? value : undefined;
                itemProps.validation.maxDate = value ? undefined : convertDateToString(startOfToday());
              }, 500);
            }}
            tooltip={
              <Trans
                t={t}
                i18nKey={'StudySettingsPage.FormBuilder.Validation.TooltipDateAsNow'}
                components={{
                  Bold: <span className={Style.boldText} />,
                }}
              />
            }
          />

          <SettingsInput
            multiple={true}
            label={t('StudySettingsPage.FormBuilder.Validation.ErrorMessageLabel')}
            placeholder={commonTranslation('LocalErrors.DateIsTooBig')}
            value={props.validation.maxDateErrorMessage || undefined}
            onChange={(e) =>
              setProp((p: ValidationSettingsProps) => {
                p.validation.maxDateErrorMessage = e.target?.value ? e.target.value : undefined;
              }, 500)
            }
            tooltip={
              <Trans
                t={t}
                i18nKey={'StudySettingsPage.FormBuilder.Validation.TooltipForErrorMessage'}
                components={{
                  Bold: <span className={Style.boldText} />,
                }}
              />
            }
          />
        </ControlsGroup>
      )}

      {applicableRules.pattern && (
        <ControlsGroup
          collapsible
          flexible
          expanded={!!props.validation.pattern}
          header={t('StudySettingsPage.FormBuilder.Validation.Pattern')}
          onOpen={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.pattern = '^[a-zA-Z0-9]*$';
            }, 500);
          }}
          onClose={() => {
            setProp((itemProps: ValidationSettingsProps) => {
              itemProps.validation.pattern = undefined;
              itemProps.validation.patternErrorMessage = undefined;
            }, 500);
          }}
        >
          <SettingsInput
            placeholder={'Enter regular expressions'}
            value={props.validation.pattern || undefined}
            onChange={(e) =>
              setProp((p: ValidationSettingsProps) => {
                p.validation.pattern = e.target?.value ? e.target.value : undefined;
              }, 500)
            }
          />
          <SettingsInput
            multiple={true}
            label={t('StudySettingsPage.FormBuilder.Validation.ErrorMessageLabel')}
            placeholder={commonTranslation('LocalErrors.InvalidPattern')}
            value={props.validation.patternErrorMessage || undefined}
            onChange={(e) =>
              setProp((p: ValidationSettingsProps) => {
                p.validation.patternErrorMessage = e.target?.value ? e.target.value : undefined;
              }, 500)
            }
          />
        </ControlsGroup>
      )}

      {applicableRules.maxLength && (
        <>
          <ControlsGroup
            collapsible
            flexible
            header={t('StudySettingsPage.FormBuilder.Validation.MaxLength')}
            expanded={!!props.validation.maxLength}
            onOpen={() => {
              setProp((prop: ValidationSettingsProps) => {
                prop.validation.maxLength = 120;
              }, 500);
            }}
            onClose={() => {
              setProp((prop: ValidationSettingsProps) => {
                prop.validation.maxLength = undefined;
                prop.validation.maxLengthErrorMessage = undefined;
              }, 500);
            }}
          >
            <SettingsInput
              placeholder={'Enter max length'}
              type={'number'}
              min={0}
              step={1}
              value={props.validation.maxLength || undefined}
              onChange={(e) =>
                setProp((p: ValidationSettingsProps) => {
                  p.validation.maxLength = e.target.value ? parseInt(e.target.value) : undefined;
                }, 500)
              }
            />
            <SettingsInput
              multiple={true}
              label={t('StudySettingsPage.FormBuilder.Validation.ErrorMessageLabel')}
              placeholder={commonTranslation('LocalErrors.LengthIsExceeded')}
              value={props.validation.maxLengthErrorMessage || undefined}
              onChange={(e) =>
                setProp((p: ValidationSettingsProps) => {
                  p.validation.maxLengthErrorMessage = e.target?.value ? e.target.value : undefined;
                }, 500)
              }
              tooltip={
                <Trans
                  t={t}
                  i18nKey={'StudySettingsPage.FormBuilder.Validation.TooltipForErrorMessage'}
                  components={{
                    Bold: <span className={Style.boldText} />,
                  }}
                />
              }
            />
          </ControlsGroup>
        </>
      )}
    </PanelContainer>
  );
};

export const ValidationDefaultSettings: ValidationProps = {
  isRequired: undefined,
  isRequiredErrorMessage: undefined,
  minValue: undefined,
  minValueErrorMessage: undefined,
  maxValue: undefined,
  maxValueErrorMessage: undefined,
  pattern: undefined,
  patternErrorMessage: undefined,
  maxLength: undefined,
  maxLengthErrorMessage: undefined,
  minDate: undefined,
  minDateAsToday: undefined,
  minDateErrorMessage: undefined,
  maxDate: undefined,
  maxDateAsToday: undefined,
  maxDateErrorMessage: undefined,
};
