import React from 'react';
import { AppCheckboxInput } from 'uikit/inputs';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import Style from './settingsInput.module.scss';
import { ReactComponent as TooltipIcon } from 'assets/img/formBuilder/tip_16.svg';

export const CheckboxSettings: React.FC<{
  label: string;
  isChecked: boolean;
  disabled?: boolean;
  setValue: (newValue: boolean) => void;
  tooltip?: string | JSX.Element;
}> = (props) => {
  return (
    <div className={Style.checkboxWrapper}>
      <AppCheckboxInput
        name={props.label}
        label={props.label}
        checked={props.isChecked}
        disabled={props.disabled}
        onChange={(e) => props.setValue(e.target.checked)}
      />

      {props.tooltip && (
        <Tooltip text={''} tooltipContent={props.tooltip} calloutContainerStyles={Style.tooltip}>
          <TooltipIcon />
        </Tooltip>
      )}
    </div>
  );
};
