﻿import {
  EditReasonEnum,
  FormLifecycleState,
  IFillingProgress,
  IFormResultDto,
  IFormResultVersionDto,
  Permissions,
} from 'src/services/api/api-client';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import React, { FC, useMemo } from 'react';
import Style from '../useFormOverview.module.scss';
import { DateFormats, localFormat } from 'src/helpers/date-helpers';
import { ShowRecallReason } from '../useRecallApprovalReason.component';
import { PermissionsCheck } from 'src/helpers/components/PermissionCheck/PermissionCheck.component';
import { UE_FORM_TYPE } from 'src/components/expandedRow/records/recordRow';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { AppButton } from 'uikit/buttons';
import { ReactComponent as EditIcon } from 'src/assets/img/common/edit_20.svg';
import { DropDownMenuOption } from 'uikit/dropDownMenu/dropDownMenu';
import { DropDownMenu } from 'uikit/dropDownMenu/dropDownMenu.component';
import { DirectionalHint } from '@fluentui/react';
import { FormStateIcon, getFormState, getFormStateColor } from '../../form-helper';
import clsx from 'clsx';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { EditReasonIcon } from './EditReasonFormHeader';

export const EcrfOverviewHeader: FC<{
  formType: string;
  currentFormResultVersion: IFormResultVersionDto;
  currentFormResult: IFormResultDto;
  onEdit: (options?: { asSubmitted?: boolean; recallReason?: string }) => void;
  changeVersion?: (newVersion: number) => void;
}> = ({ formType, currentFormResultVersion, currentFormResult, onEdit }) => {
  const { t } = useScopedTranslation('Forms.Overview');
  const study = useStudy();

  const formState = useMemo(
    () =>
      getFormState(
        currentFormResultVersion.formResultId,
        currentFormResultVersion.lifecycleState,
        currentFormResultVersion.isSkipped,
        currentFormResultVersion.fillingProgress,
        null,
        true,
      ),
    [currentFormResultVersion],
  );

  return (
    <>
      <div className={Style.modalHeaderContainer}>
        <div className={clsx(Style.modalHeaderLeftPart, getFormStateColor(formState, 'FormOverview'))}>
          {/* <VersionsMenu versions={currentFormResult.versions} currentVersion={currentFormResultVersion} onChange={changeVersion}/> */}
          <div data-test-id={'version'} className={Style.version}>
            {t('Version', { version: currentFormResultVersion.versionNumber })}
          </div>
          <LastEventInfo version={currentFormResultVersion} />
        </div>
        <div className={Style.modalHeaderRightPart}>
          {currentFormResultVersion?.fillingProgress && !currentFormResultVersion.isSkipped && (
            <FillingProgress value={currentFormResultVersion.fillingProgress} />
          )}
          {currentFormResultVersion.isEditable && (
            <PermissionsCheck
              permissions={
                formType === UE_FORM_TYPE
                  ? Permissions.UeFormEdit
                  : currentFormResult.formConfigId === study?.patientFinishingFormId
                  ? Permissions.PatientRelease
                  : Permissions.PatientCreate
              }
            >
              <EditButton
                onClick={() =>
                  onEdit({
                    asSubmitted: false,
                    recallReason: currentFormResultVersion.approvalRecallReason ?? undefined,
                  })
                }
              />
            </PermissionsCheck>
          )}
        </div>
      </div>
    </>
  );
};

const LastEventInfo: FC<{ version: IFormResultVersionDto }> = ({ version }) => {
  const { t } = useScopedTranslation('Forms.Overview');

  const formState = useMemo(
    () =>
      getFormState(
        version.formResultId,
        version.lifecycleState,
        version.isSkipped,
        version.fillingProgress,
        null,
        true,
      ),
    [version.fillingProgress, version.formResultId, version.isSkipped, version.lifecycleState],
  );

  const vm = useMemo<{
    [key in FormLifecycleState]: {
      icon: JSX.Element;
      date: Date | null;
      fullName: string | undefined;
      localizationKey: Parameters<typeof t>[0];
      editReason?: EditReasonEnum | null;
      updateReasonText?: string | null;
    };
  }>(
    () => ({
      [FormLifecycleState.Recalled]: {
        date: version.approvalIsRecalledAt,
        fullName: version.approvalIsRecalledBy?.fullName,
        icon: <FormStateIcon variant={'FormOverview'} state={formState} />,
        localizationKey: 'MainText.Recalled',
      },
      [FormLifecycleState.Approved]: {
        date: version.approvedAt,
        fullName: version.approvedBy?.fullName,
        icon: <FormStateIcon variant={'FormOverview'} state={formState} />,
        localizationKey: 'MainText.Approved',
      },
      [FormLifecycleState.Submitted]: {
        date: version.submittedAt,
        fullName: version.submittedBy?.fullName,
        icon: <FormStateIcon variant={'FormOverview'} state={formState} />,
        localizationKey: version.isSkipped ? 'MainText.Skipped' : 'MainText.Submitted',
      },
      [FormLifecycleState.Draft]: {
        date: version.updatedAt || version.createdAt,
        fullName: version.doctor.fullName,
        icon: <FormStateIcon variant={'FormOverview'} state={formState} />,
        localizationKey:
          version.createdAt.getTime() !== version.updatedAt.getTime() ? 'MainText.Edited' : 'MainText.Draft',
        editReason: version.editReason,
        updateReasonText: version.updateReasonText,
      },
    }),
    [formState, version],
  )[version.lifecycleState];

  return (
    <div className={Style.lastEventInfo} data-test-id={'form-filling-details'}>
      {vm.icon}
      <div>
        {t(vm.localizationKey as any, {
          date: localFormat(vm.date!, DateFormats.longDate),
          name: vm!.fullName,
        })}
      </div>
      <EditReasonIcon editReason={vm.editReason} updateReasonText={vm.updateReasonText} />
      <ShowRecallReason recallReason={version.approvalRecallReason ?? undefined} />
    </div>
  );
};

const FillingProgress: FC<{ value: IFillingProgress }> = ({ value }) => {
  const { t } = useScopedTranslation('Forms.Overview');

  return (
    <>
      <span>{t('FillingProgress')}</span>
      <span>
        {value.filled}/{value.total}
      </span>
    </>
  );
};

const EditButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useScopedTranslation('Forms.Overview');

  return (
    <Tooltip text={t('EditTooltip')} hostStyles={Style.editButton}>
      <AppButton
        testId={'edit-button'}
        Icon={EditIcon}
        variant={'icon-link'}
        colorSchema={'secondary'}
        onClick={onClick}
      />
    </Tooltip>
  );
};

/** Maybe we will use version switcher in the future */
const VersionsMenu: FC<{
  versions: IFormResultVersionDto[];
  currentVersion: IFormResultVersionDto;
  onChange: (versionNumber: number) => void;
}> = ({ versions, currentVersion, onChange }) => {
  const { t } = useScopedTranslation('Forms.Overview');

  const versionMenuOptions: DropDownMenuOption[] = useMemo(() => {
    return versions.map(
      (v) =>
        ({
          key: v.versionNumber,
          text: t('Version', { version: v.versionNumber }),
          action: () => onChange(v.versionNumber),
          className: Style.versionMenuItem,
        } as DropDownMenuOption),
    );
  }, [onChange, t, versions]);

  const versionMenuSelectedOption: DropDownMenuOption = useMemo(
    () => versionMenuOptions.find((x) => x.key === currentVersion.versionNumber)!,
    [currentVersion.versionNumber, versionMenuOptions],
  );

  return (
    <DropDownMenu
      directionalHint={DirectionalHint.bottomLeftEdge}
      disabled={versionMenuOptions.length < 2}
      hasChevron={versionMenuOptions.length > 1}
      text={versionMenuSelectedOption.text}
      colorSchema={'primary'}
      options={versionMenuOptions}
      calloutClassName={Style.callout}
    />
  );
};
