import React from 'react';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { InfoField, InfoFieldsColumn, MainField } from '../components/reportFields';
import { RPDF } from '../react-pdf';
import { ReportFirstPage } from '../components/ReportFirstPage';
import { ReportSignAndTitleField } from '../components/ReportSignAndTitleField';

export type FirstPageDataType = {
  data: {
    studyNumber: string;
    studyName: string;
    issuesCount: number;
    furtherInfo?: string;
  };
};

export const FirstPageInfo: React.FC<FirstPageDataType> = ({
  data: { furtherInfo, issuesCount, studyNumber, studyName },
}) => {
  const { t } = useScopedTranslation('Reports.Issue');

  const localStyles = RPDF.StyleSheet.create({
    contentIndent: {
      marginTop: 8,
    },
    footer: {
      gap: 24,
    },
  });

  const Header = (pageCounter?: string | JSX.Element) => {
    return (
      <>
        <InfoFieldsColumn>
          <InfoField header={t('FirstPage.CreatedAt')} value={localFormat(new Date(), DateFormats.dateTime)} />
          <InfoField header={t('FirstPage.PageCount')} value={pageCounter} />
        </InfoFieldsColumn>
        <InfoFieldsColumn>
          <InfoField header={t('FirstPage.Study')} value={`${studyName} (${studyNumber})`} />
          <InfoField header={t('FirstPage.IssuesCount')} value={issuesCount.toString()} />
        </InfoFieldsColumn>
      </>
    );
  };
  const SignComponent = () => {
    return (
      <RPDF.View style={localStyles.footer}>
        <ReportSignAndTitleField title={t('FirstPage.SignField.Doctor')} renderDateField={true} />
        <ReportSignAndTitleField title={t('FirstPage.SignField.Monitor')} renderDateField={true} />
      </RPDF.View>
    );
  };

  return (
    <ReportFirstPage
      headerText={t('DocumentHeader')}
      contentStyles={localStyles.contentIndent}
      HeaderComponent={Header}
      SignTitleComponent={SignComponent}
      patientUid={undefined}
    >
      <MainField header={t('FirstPage.FurtherInfo')} value={furtherInfo} />
    </ReportFirstPage>
  );
};
