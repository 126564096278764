import { FormBuilderContext } from 'src/containers/formBuilder/provider/formBuilder.provider';
import { useContextSelector } from 'use-context-selector';

export const useNodeBuffer = () => {
  const buffer = useContextSelector(FormBuilderContext, (x) => x.buffer);
  const addToBuffer = useContextSelector(FormBuilderContext, (x) => x.addToBuffer);
  const removeFromBuffer = useContextSelector(FormBuilderContext, (x) => x.removeFromBuffer!);

  return { buffer, addToBuffer, removeFromBuffer };
};
