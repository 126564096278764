import React from 'react';
import { Toolbar } from '../toolbar/toolbar';
import Style from './viewport.module.css';
import clsx from 'clsx';

export const Sidebar = () => {
  return (
    <div className={clsx(Style.sidebar)}>
      <Toolbar />
    </div>
  );
};
