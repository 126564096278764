﻿import React, { FC, PropsWithChildren } from 'react';
import styles from './ExpandedRowSections.module.scss';

type ExpandedRowSectionsProps = PropsWithChildren;

type ExpandedRowSectionsColumnsProps = PropsWithChildren;

type ExpandedRowSectionProps = PropsWithChildren;

const ExpandedRowSections: FC<ExpandedRowSectionsProps> & {
  Column: FC<ExpandedRowSectionsColumnsProps>;
  Section: FC<ExpandedRowSectionProps>;
} = (props) => {
  return <div className={styles.sections}>{props.children}</div>;
};

ExpandedRowSections.Column = (props) => <div className={styles.sectionsColumn}>{props.children}</div>;

ExpandedRowSections.Section = (props) => <div className={styles.section}>{props.children}</div>;
export default ExpandedRowSections;
