import clsx from 'clsx';
import React from 'react';
import { ChipsProps } from './chips';

import style from './chips.module.scss';

export const Chips = (props: ChipsProps) => {
  const { Icon } = props;

  return (
    <div
      data-test-id={props.testId ? `${props.testId}-chip` : 'chip'}
      aria-disabled={props.disabled}
      aria-checked={props.checked}
      role={props.onClick && 'button'}
      className={clsx(style.chip)}
      onClick={props.onClick}
    >
      {Icon && <Icon className={style.icon} />}
      <span className={style.chipText}>{props.text}</span>
    </div>
  );
};
