import { useNode } from '@craftjs/core';
import { useCallback } from 'react';

export const nodeSharedStateKey = Symbol();

export interface INodeSharedState<S> {
  [nodeSharedStateKey]?: S;
}

/** Calling setSharedState when component had been unmounted leads to an error */
type SetSharedState<S> = (action: (prev: S | undefined) => S | undefined) => void;

export function useNodeSharedState<S>() {
  const {
    sharedState,
    actions: { setCustom },
  } = useNode((node) => ({
    sharedState: node.data.custom[nodeSharedStateKey] as S | undefined,
  }));

  const setSharedState = useCallback<SetSharedState<S>>(
    (action: (prev: S | undefined) => S | undefined) => {
      setCustom((customProps: INodeSharedState<S>) => {
        customProps[nodeSharedStateKey] = action(customProps[nodeSharedStateKey]);
      });
    },
    [setCustom],
  );

  return [sharedState, setSharedState] as [typeof sharedState, typeof setSharedState];
}
