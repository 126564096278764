import { Buffer } from 'buffer';
import React, { FC, PropsWithChildren, useCallback, useState } from 'react';
import { useModal } from '../../application/hooks/useModal';
import { AccessDbContext } from './accessDb.context';
import Style from './accessDb.module.css';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { AppTextInput } from '../../components/uikit/inputs/text/appTextInput.component';
import { AppInputLabel } from '../../components/uikit/wrappers/label/appInputLabel.component';
import { AppCheckboxInput } from '../../components/uikit/inputs/checkbox/appCheckboxInput.component';
import { AppAttachmentValue } from '../../components/uikit/fields/attachments/attachmentsField/appAttachmentsField';
import { AppAttachmentsField } from '../../components/uikit/fields';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
// @ts-ignore
window.Buffer = Buffer;
// @ts-ignore
window.global = window;

export const AccessDbProvider: FC<PropsWithChildren> = (props) => {
  const { t } = useScopedTranslation('ExternalAnalysis.AccessDbProvider');
  const modal = useModal();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [hasPassword, setHasPassword] = useState<boolean>(false);
  const [pwd, setPwd] = useState<string>();
  const [files, setFiles] = useState<AppAttachmentValue[] | null>(null);
  const [data, setData] = useState<any>();
  const [fileName, setFileName] = useState<string>();

  const resetDataBase = () => {
    setHasPassword(false);
    setError(undefined);
    setLoading(false);
    setFiles(null);
    setPwd(undefined);
  };

  const importHandler = useCallback(() => {
    resetDataBase();
    modal.openModal();
  }, [modal]);

  const onFileLoadedHandler = useCallback(
    (buffer: ArrayBuffer) => {
      // TODO: Blocking UI operation
      import('mdb-reader')
        .then((mdbReader) => {
          // @ts-ignore
          const db = new mdbReader.default(Buffer.from(buffer), { password: hasPassword ? pwd : '' });
          setData(db);
          // eslint-disable-next-line no-unused-expressions
          files && files[0] && setFileName(files[0].fileName);
          modal.closeModal();
        })
        .catch((err: any) => {
          if (err.message === 'Wrong password') {
            setError(t('Errors.WrongPassword'));
            return;
          }

          setError(err.message);
        })
        .finally(() => {
          // To fix bug: #35985 Disable buttons after loading
          setTimeout(() => {
            setLoading(false);
          }, 100);
        });
    },
    [files, hasPassword, modal, pwd, t],
  );

  const stepOneNextHandler = useCallback(() => {
    setError(undefined);

    if (!files || !files[0].file) {
      return;
    }

    const file = files[0].file;
    const reader = new FileReader();
    reader.onload = (e) => onFileLoadedHandler(e.target?.result as ArrayBuffer);
    reader.readAsArrayBuffer(file);
    reader.addEventListener('loadstart', () => setLoading(true));
  }, [files, onFileLoadedHandler]);

  return (
    <AccessDbContext.Provider value={{ data: data, fileName: fileName, importAction: importHandler }}>
      {props.children}
      <DialogModal
        bodyClassName={Style.modalBody}
        title={t('ModalTitle')}
        visible={modal.visible}
        onHide={modal.closeModal}
        footer={{
          errors: error,
          leftButton: {
            text: t('Cancel'),
            onClick: modal.closeModal,
            disabled: loading,
          },
          rightButton: {
            text: t('Import'),
            onClick: stepOneNextHandler,
            disabled: loading || !files,
          },
        }}
      >
        <div>
          <div className={Style.stepContainer}>
            <AppAttachmentsField
              label={t('Database')}
              onChange={(f) => {
                resetDataBase();
                setFiles(f);
              }}
              value={files}
              fileTypes={['.accdb', '.mdb']}
              multiple={false}
              disabled={loading}
            />

            <AppCheckboxInput
              label={t('HasPassword')}
              onChange={(e) => setHasPassword(e.target.checked)}
              checked={hasPassword}
              disabled={loading}
            />

            {/* Prevent autocomplete for password field */}
            {hasPassword && (
              <form autoComplete="off">
                <input autoComplete="off" hidden />
                <AppInputLabel text={t('Password')}>
                  <AppTextInput
                    type={'password'}
                    placeholder={t('PasswordPlaceholder')}
                    autoComplete={'new-password'}
                    value={pwd ?? ''}
                    onChange={(e) => setPwd(e.target.value)}
                    disabled={loading}
                  />
                </AppInputLabel>
              </form>
            )}
          </div>
        </div>
      </DialogModal>
    </AccessDbContext.Provider>
  );
};
