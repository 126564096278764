﻿import React, { FC, PropsWithChildren } from 'react';
import styles from './ExpandedRowCard.module.scss';
import clsx from 'clsx';
import { buildTestId } from '../../../helpers/test-helpers';

export type ExpandedRowCardProps = {
  testId?: string;
  containerClassName?: string;
};

export const ExpandedRowCard: FC<PropsWithChildren<ExpandedRowCardProps>> = (props) => {
  return (
    <div className={styles.outlineContainer} data-test-id={buildTestId(props.testId, 'expanded-row-card')}>
      <div className={clsx(styles.container, props.containerClassName)}>{props.children}</div>
    </div>
  );
};
