import { useParams } from 'react-router';
import { IDoctorProfileDto, IDoctorStudyDto } from 'src/services/api/api-client';
import { useGetDoctorProfileInfoQuery } from 'src/services/api/api-client/ProfileQuery';

const defaultDoctorDto: IDoctorProfileDto = {
  id: '',
  fullName: '',
  email: null,
  tagFilters: null,
  studies: [],
};

export const useStaffProfile = (options?: { enabled?: boolean; suspense?: boolean }) => {
  const doctorQuery = useGetDoctorProfileInfoQuery({
    keepPreviousData: true,
    refetchOnMount: false,
    ...options,
  });

  return {
    profile: doctorQuery.data ?? defaultDoctorDto,
    query: doctorQuery,
  };
};

export const useStudy = (): IDoctorStudyDto | undefined => {
  const { id } = useParams();
  const {
    profile: { studies },
  } = useStaffProfile();

  return studies.find((x) => x.id === Number(id));
};
