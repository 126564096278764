﻿import React, { FC, useCallback } from 'react';
import { useModal } from '../../application/hooks/useModal';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { parseISO, startOfToday } from 'date-fns';
import { AppTextField } from 'uikit/fields';
import styles from './useStudyCreating.module.scss';
import { ValidationFormRules } from '../../helpers/validation-helpers';
import { convertDateToString } from '../../helpers/date-helpers';
import { CardSectionsEnum, CreateGroupDto, CreateStudyDto, TableColumnsEnum } from '../../services/api/api-client';
import { createStudy } from '../../services/api/api-client/StudyClient';
import { useQueryClient } from '@tanstack/react-query';
import { getDoctorProfileInfoQueryKey } from '../../services/api/api-client/ProfileQuery';
import { getStudyListQueryKey } from '../../services/api/api-client/StudyQuery';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { handleSubmitFormError } from '../../application/error-handling/useErrorHandler';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

type FormState = {
  studyNumber: string;
  title: string;
  description: string;
  startDate: string;
  duration: number;
};

export const useStudyCreating = () => {
  const modal = useModal();

  const onModalHide = useCallback(() => {
    modal.closeModal();
  }, [modal]);

  const form = useForm<FormState>({
    defaultValues: {
      studyNumber: '',
      title: '',
      description: '',
      startDate: convertDateToString(startOfToday()),
      duration: 14,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  return {
    element: <StudyCreatingModal form={form} onSuccess={onModalHide} onHide={onModalHide} visible={modal.visible} />,
    start: async () => {
      form.reset();
      modal.openModal();
    },
  };
};

const StudyCreatingModal: FC<{
  onHide: () => void;
  visible: boolean;
  onSuccess: () => void;
  form: UseFormReturn<FormState>;
}> = (props) => {
  const queryClient = useQueryClient();
  const { t } = useScopedTranslation('StudyCreating', 'dev');

  const submit = useCallback(
    async (values: FormState) => {
      const dto = new CreateStudyDto({
        studyNumber: values.studyNumber,
        title: values.title,
        description: values.description,
        duration: values.duration,
        startDate: parseISO(values.startDate),
        hasTherapy: false,
        hasMonitoring: false,
        infoPageConfiguration: null,
        token: null,
        prismaCloudUrl: null,
        therapyAggregationPeriod: 7,
        patientStudyStartAdvanceDays: 7,
        groups: [
          new CreateGroupDto({
            title: t('DefaultGroupName'),
            patientCardSections: [
              [CardSectionsEnum.GeneralInfo, CardSectionsEnum.Stage, CardSectionsEnum.StudyPeriod],
              [CardSectionsEnum.RecordsGroupedByStep],
              [CardSectionsEnum.Notes],
            ],
            tableColumns: TableColumnsEnum.Stage | TableColumnsEnum.Categorization | TableColumnsEnum.Note,
          }),
        ],
      });

      try {
        await createStudy(dto);
      } catch (e) {
        handleSubmitFormError(e, props.form.setError);
        return;
      }

      await Promise.all([
        queryClient.invalidateQueries(getDoctorProfileInfoQueryKey()),
        queryClient.invalidateQueries(getStudyListQueryKey()),
      ]);

      props.onSuccess();
    },
    [props, queryClient, t],
  );

  return (
    <DialogModal
      bodyClassName={styles.modalBody}
      title={t('Modal.Header')}
      visible={props.visible}
      onHide={props.onHide}
      footer={{
        errors: !!props.form.formState.errors.root ? t('Modal.Error') : undefined,
        leftButton: {
          text: t('Modal.Cancel'),
          onClick: props.onHide,
          disabled: props.form.formState.isSubmitting,
        },
        rightButton: {
          text: t('Modal.Create'),
          type: 'submit',
          disabled: props.form.formState.isSubmitting,
          isLoading: props.form.formState.isSubmitting,
          form: 'studyForm',
        },
      }}
    >
      <form id={'studyForm'} onSubmit={props.form.handleSubmit(submit)} autoComplete={'off'}>
        <Controller
          render={({ field, fieldState }) => (
            <AppTextField
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              errorProps={{ errors: fieldState.error?.message }}
              labelProps={{ text: t('Modal.Fields.StudyNumber.Label') }}
            />
          )}
          rules={{
            minLength: { value: 3, message: t('Modal.Fields.StudyNumber.Min') },
            maxLength: { value: 5, message: t('Modal.Fields.StudyNumber.Max') },
            ...ValidationFormRules().requiredRule,
          }}
          control={props.form.control}
          name={'studyNumber'}
        />
        <Controller
          render={({ field, fieldState }) => (
            <AppTextField
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              errorProps={{ errors: fieldState.error?.message }}
              labelProps={{ text: t('Modal.Fields.Title.Label') }}
            />
          )}
          rules={{
            ...ValidationFormRules().requiredRule,
          }}
          control={props.form.control}
          name={'title'}
        />
        <Controller
          render={({ field, fieldState }) => (
            <AppTextField
              type={'text-area'}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              errorProps={{ errors: fieldState.error?.message }}
              labelProps={{ text: t('Modal.Fields.Description.Label') }}
            />
          )}
          control={props.form.control}
          name={'description'}
        />
        <Controller
          render={({ field, fieldState }) => (
            <AppTextField
              type={'number'}
              step={1}
              min={1}
              onChange={(e) => {
                const v = parseInt(e.target.value);
                if (isNaN(v)) field.onChange(1);
                else field.onChange(v);
              }}
              onBlur={field.onBlur}
              value={field.value}
              errorProps={{ errors: fieldState.error?.message }}
              labelProps={{ text: t('Modal.Fields.Duration.Label') }}
            />
          )}
          rules={{ ...ValidationFormRules().requiredRule, min: { value: 1, message: t('Modal.Fields.Duration.Min') } }}
          control={props.form.control}
          name={'duration'}
        />
        <Controller
          render={({ field, fieldState }) => (
            <AppTextField
              type={'date'}
              onChange={field.onChange}
              onBlur={field.onBlur}
              value={field.value}
              errorProps={{ errors: fieldState.error?.message }}
              labelProps={{ text: t('Modal.Fields.StartDate.Label') }}
            />
          )}
          rules={{ ...ValidationFormRules().requiredRule }}
          control={props.form.control}
          name={'startDate'}
        />
      </form>
    </DialogModal>
  );
};
