﻿import React, { FC } from 'react';
import { DevError } from '../../helpers/components/DevError/DevError.component';
import ExpandedRowSections from 'uikit/table/ExpandedRowSections';
import { ExpandedRowHeader } from '../../components/expandedRow/header/expandedRowHeader.component';
import { IParticipantDto, Permissions } from '../../services/api/api-client';
import PropertiesList from 'uikit/propertiesList/PropertiesList';
import { localFormat } from '../../helpers/date-helpers';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';
import { AppButton } from 'uikit/buttons';
import { ReactComponent as DeleteIcon } from 'src/assets/img/common/delete_20.svg';
import { ReactComponent as EditIcon } from 'src/assets/img/common/edit_20.svg';
import styles from './ParticipantsPage.module.scss';
import { useParticipantEditing } from './useParticipantEditing';
import { useParticipantDeleting } from './useParticipantDeleting';
import { useTranslation } from 'react-i18next';
import { BulletPoints } from 'uikit/BulletPoints';
import { AppAttachmentsView } from 'uikit/fields';

export const ParticipantsPageExpandedRow: FC<{ participant: IParticipantDto }> = ({ participant }) => {
  return (
    <ExpandedRowSections>
      <ExpandedRowSections.Column>
        <InfoSection participant={participant} />
      </ExpandedRowSections.Column>

      <ExpandedRowSections.Column>
        <TrainingsSection participant={participant} />
      </ExpandedRowSections.Column>
    </ExpandedRowSections>
  );
};

const InfoSection: FC<{ participant: IParticipantDto }> = ({ participant }) => {
  const { t } = useTranslation();
  const participantEditing = useParticipantEditing();
  const participantDeleting = useParticipantDeleting();

  return (
    <ExpandedRowSections.Section>
      <ExpandedRowHeader
        text={t('Participants.Page.Sections.Info.Header')}
        rightHeader={
          <PermissionsCheck permissions={Permissions.ParticipantsEdit}>
            <div className={styles.infoSectionActions}>
              <AppButton
                variant={'icon-link'}
                colorSchema={'decline'}
                Icon={DeleteIcon}
                onClick={() => participantDeleting.start(participant)}
              />
              <AppButton
                variant={'icon-link'}
                colorSchema={'primary'}
                Icon={EditIcon}
                onClick={() => participantEditing.start(participant)}
              />
            </div>
          </PermissionsCheck>
        }
      />
      <PropertiesList>
        <PropertiesList.Property name={t('Participants.Page.Sections.Info.FullName')} value={participant.fullName} />
        <PropertiesList.Property name={t('Participants.Page.Sections.Info.Email')} value={participant.email} />
        <PropertiesList.Property name={t('Participants.Page.Sections.Info.Role')} value={participant.function} />
        {participant.functionComment && (
          <PropertiesList.Property
            name={t('Participants.Page.Sections.Info.FunctionComment')}
            value={participant.functionComment}
          />
        )}
        <PropertiesList.Property
          name={t('Participants.Page.Sections.Info.Tasks')}
          value={<BulletPoints values={participant.tasks} />}
        />
        {participant.tasksComment && (
          <PropertiesList.Property
            name={t('Participants.Page.Sections.Info.TasksComment')}
            value={participant.tasksComment}
          />
        )}
        <PropertiesList.Property
          name={t('Participants.Page.Sections.Info.OnboardedOn')}
          value={localFormat(participant.onboardedOn)}
        />
        <PropertiesList.Property
          name={t('Participants.Page.Sections.Info.OffboardedOn')}
          value={participant.offboardedOn && localFormat(participant.offboardedOn)}
        />
        <PropertiesList.Property
          name={t('Participants.Page.Sections.Info.Attachments')}
          value={
            <AppAttachmentsView
              files={participant.attachments.map((x) => ({ id: x.fileId, fileName: x.fileName, file: null }))}
            />
          }
        />
      </PropertiesList>

      {participantEditing.element}
      {participantDeleting.element}
    </ExpandedRowSections.Section>
  );
};

const TrainingsSection: FC<{ participant: IParticipantDto }> = ({ participant }) => {
  const { t } = useTranslation();

  return null;

  // Not implemented yet
  return (
    <ExpandedRowSections.Section>
      <ExpandedRowHeader text={t('Participants.Page.Sections.Trainings.Header')} />
      <DevError style={{ marginTop: 8 }} messages={['Not implemented yet']} />
    </ExpandedRowSections.Section>
  );
};
