import React, { useContext, FC } from 'react';
import { DevError } from '../../../../helpers/components/DevError/DevError.component';
import { NoteContext } from '../NoteContext';

export const StubFormField: FC<{ dataKey: string }> = (props) => {
  const noteContext = useContext(NoteContext);
  if (noteContext.noteDateDataKey === props.dataKey)
    return <DevError messages={['This field cannot display note date']} />;

  return <></>;
};
