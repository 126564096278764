﻿import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { StudyDocument } from 'src/features/monitoring/monitoringForms/MonitoringFormValue';
import { useGetParticipantsQuery } from 'src/services/api/api-client/ParticipantsQuery';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { useGetPatientUniqueIdsQuery } from 'src/services/api/api-client/PatientQuery';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import { Permissions } from 'src/services/api/api-client';

export type MonitoringFormOption = { value: string; label: string };

export const useYesNoOptions = () => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return [
      {
        value: 'true',
        label: t('Common_yes'),
      },
      {
        value: 'false',
        label: t('Common_no'),
      },
    ] satisfies MonitoringFormOption[];
  }, [t]);

  return options;
};

export const usePersonNamesOptions = (): DropdownOption<string>[] => {
  const study = useStudy();
  const { hasOneOfPermissions } = useHasPermissions();
  const { data: participants } = useGetParticipantsQuery(
    { studyId: study?.id },
    { enabled: !!study?.id && hasOneOfPermissions(Permissions.ParticipantsView, Permissions.MonitoringReportView) },
  );

  return (
    participants?.data.map((p) => ({
      key: p.id,
      text: p.fullName,
    })) ?? []
  );
};

export const useStudyDocumentsOptions = () => {
  const { t } = useScopedTranslation('MonitoringForms.Fields.StudyDocumentation.StudyDocuments.Options');

  const options = useMemo<DropdownOption<StudyDocument>[]>(() => {
    return [
      {
        key: 'TestPlan',
        text: t('TestPlan'),
      },
      {
        key: 'PatientInformationAndConsent',
        text: t('PatientInformationAndConsent'),
      },
      {
        key: 'InsuranceDocuments',
        text: t('InsuranceDocuments'),
      },
      {
        key: 'CommunicationWithEc',
        text: t('CommunicationWithEc'),
      },
      {
        key: 'SupportingDocuments',
        text: t('SupportingDocuments'),
      },
      {
        key: 'RandomizationList',
        text: t('RandomizationList'),
      },
      {
        key: 'Other',
        text: t('Other'),
      },
    ] satisfies DropdownOption<StudyDocument>[];
  }, [t]);

  return options;
};

export const useMonitoringFormTypeOptions = () => {
  const { t } = useScopedTranslation('MonitoringForms.MonitoringFormTypes');

  const options = useMemo(() => {
    return [
      {
        value: 'Initiate',
        label: t('Initiate'),
      },
      {
        value: 'Routine',
        label: t('Routine'),
      },
      {
        value: 'Final',
        label: t('Final'),
      },
    ] satisfies MonitoringFormOption[];
  }, [t]);

  return options;
};

export const useSdvOptions = () => {
  const { t } = useScopedTranslation('MonitoringForms.Fields.PatientMonitoring.Sdv.Options');

  const options = useMemo(() => {
    return [
      {
        value: 'HundredPercent',
        label: t('HundredPercent'),
      },
      {
        value: 'FixedRecord',
        label: t('FixedRecord'),
      },
    ] satisfies MonitoringFormOption[];
  }, [t]);

  return options;
};

export const usePatientIdsOptions = () => {
  const study = useStudy();
  const patientUniqueIdsQuery = useGetPatientUniqueIdsQuery(study!.id, {
    enabled: !!study?.id,
  });

  const options = useMemo(() => {
    return (
      patientUniqueIdsQuery.data?.map((x) => {
        return {
          key: x,
          text: x,
        } satisfies DropdownOption;
      }) ?? []
    );
  }, [patientUniqueIdsQuery.data]);

  return { patientIdsOptions: options, isLoading: patientUniqueIdsQuery.isInitialLoading };
};

export const useConsentFormsReviewedOptions = () => {
  const { t } = useScopedTranslation('MonitoringForms.Fields.DeclarationsOfConsent.ConsentFormsReviewed.Options');

  const options = useMemo(() => {
    return [
      {
        key: 'AllPatients',
        text: t('AllPatients'),
      },
      {
        key: 'AllCurrentPatients',
        text: t('AllCurrentPatients'),
      },
      {
        key: 'RandomPatients',
        text: t('RandomPatients'),
      },
    ] satisfies DropdownOption[];
  }, [t]);

  return options;
};
