import { ExpandedRowHeader } from '../../../components/expandedRow/header/expandedRowHeader.component';
import { TypographyStyles } from '../../../styles';
import React, { useCallback } from 'react';
import { IStaffDto } from '../../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../assets/img/common/edit_20.svg';
import { useModal } from '../../../application/hooks/useModal';

import Style from './userlist.module.scss';
import { EditUserModalContent } from './EditUserModalContent';
import { AppButton } from 'uikit/buttons';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { TagsUserModalContent } from './TagsUserModalContent';
import { Chips } from 'uikit/chips/chips.component';
import { ReactComponent as DeleteIcon } from 'assets/img/common/delete_20.svg';
import { showErrorToast } from '../../../components/toast/toast-helper';
import { useQueryClient } from '@tanstack/react-query';
import { QueryFactory } from '../../../services/api';
import ExpandedRowSections from 'uikit/table/ExpandedRowSections';
import PropertiesList from 'uikit/propertiesList/PropertiesList';

export const UserDetailsCard = (props: IStaffDto) => {
  const { t } = useTranslation('dev');
  const client = useQueryClient();

  const editModalManager = useModal();
  const tagsModalManager = useModal();

  const onDelete = useCallback(async () => {
    try {
      await QueryFactory.UsersQuery.Client.deleteUser(props.id);
      await client.invalidateQueries(QueryFactory.UsersQuery.getAllUsersQueryKey());
    } catch (e) {
      showErrorToast(e);
    }
  }, [client, props.id]);

  return (
    <ExpandedRowSections>
      <ExpandedRowSections.Column>
        <ExpandedRowSections.Section>
          <ExpandedRowHeader
            text={t('Admin.UserList.Details.Caption')}
            rightHeader={
              <div style={{ display: 'flex', gap: 10 }}>
                {props.predefinedUser && (
                  <AppButton
                    variant={'icon-link'}
                    colorSchema={'decline'}
                    text={t('Admin.UserList.Predefined.Delete')}
                    Icon={DeleteIcon}
                    onClick={onDelete}
                  />
                )}
                <AppButton
                  onClick={() => editModalManager.openModal()}
                  variant={'icon-link'}
                  colorSchema={'primary'}
                  text={t('Admin.UserList.Details.Action')}
                  Icon={EditIcon}
                />
              </div>
            }
          />
          <PropertiesList>
            <PropertiesList.Property name={t('Admin.UserList.Details.Id')} value={props.id} />
            <PropertiesList.Property name={t('Admin.UserList.Details.Login')} value={props.userName} />
            <PropertiesList.Property name={t('Admin.UserList.Details.Email')} value={props.email} />
            <PropertiesList.Property name={t('Admin.UserList.Details.Roles')} value={props.roles?.join('\n')} />
            <PropertiesList.Property
              name={t('Admin.UserList.Details.Studies')}
              value={props.studies?.map((x) => x.studyNumber)?.join('\n')}
            />
            <PropertiesList.Property name={t('Admin.UserList.Details.Timezone')} value={props.timeZone} />
          </PropertiesList>
        </ExpandedRowSections.Section>
      </ExpandedRowSections.Column>
      <ExpandedRowSections.Column>
        <ExpandedRowSections.Section>
          <ExpandedRowHeader
            text={t('Admin.UserList.Tags.Caption')}
            rightHeader={
              <AppButton
                onClick={() => tagsModalManager.openModal()}
                variant={'icon-link'}
                colorSchema={'primary'}
                text={t('Admin.UserList.Tags.Action')}
                Icon={EditIcon}
              />
            }
          />

          {props?.tagFilters && Object.keys(props?.tagFilters).length > 0 ? (
            <PropertiesList>
              {Object.entries(props?.tagFilters).map((k, i) => (
                <PropertiesList.Property
                  key={i}
                  name={k[0]}
                  value={
                    <div className={Style.tagsCardRow}>
                      {Array.isArray(k[1]) ? k[1].map((v) => <Chips key={v} checked text={v} />) : JSON.stringify(k[1])}
                    </div>
                  }
                />
              ))}
            </PropertiesList>
          ) : (
            <div className={Style.tagsPlaceholder}>
              <div className={TypographyStyles.paragraph14}>{t('Admin.UserList.Tags.Placeholder')}</div>
            </div>
          )}
        </ExpandedRowSections.Section>
      </ExpandedRowSections.Column>

      <AppModalContainer
        visible={editModalManager.visible}
        onHide={editModalManager.closeModal}
        title={t('Admin.UserList.Details.EditModal.Header')}
        bodyClassName={Style.modalBody}
      >
        <EditUserModalContent {...props} onClose={editModalManager.closeModal} />
      </AppModalContainer>

      <AppModalContainer
        visible={tagsModalManager.visible}
        onHide={tagsModalManager.closeModal}
        title={t('Admin.UserList.Tags.EditModal.Header')}
        bodyClassName={Style.modalBody}
      >
        <TagsUserModalContent {...props} onClose={tagsModalManager.closeModal} />
      </AppModalContainer>
    </ExpandedRowSections>
  );
};
