import { isConditionalOption } from 'src/helpers/question-helper';
import {
  IQuestionScheme,
  ISurveyQuestion,
  QuestionCreationDto,
  QuestionScheme,
  SurveyQuestion,
} from 'src/services/api/api-client';

export function isIQuestionScheme(x: any | IQuestionScheme): x is IQuestionScheme {
  return (x as IQuestionScheme).version !== undefined && (x as IQuestionScheme).description !== undefined;
}

export const toCreateQuestionDto = (question: ISurveyQuestion): QuestionCreationDto => {
  return new QuestionCreationDto({
    ...{
      questionType: question.questionType,
      questionText: question.questionText,
      isSkippedRender: question.isSkippedRender,
      options: question.options!,
      canBeSkipped: question.canBeSkipped,
    },
  });
};

export const getQuestionById = (scheme: IQuestionScheme, id: string): ISurveyQuestion | null => {
  if (!scheme.questions) {
    return null;
  }

  for (const question of scheme.questions) {
    if (question.id === id) {
      return question;
    }

    if (!question.options) {
      continue;
    }

    for (const option of question.options) {
      if (isConditionalOption(option) && option.questionScheme) {
        const result = getQuestionById(option.questionScheme, id);

        if (!result) {
          continue;
        }

        return result;
      }
    }
  }

  return null;
};

export const removeQuestionById = (scheme: IQuestionScheme, id: string): IQuestionScheme => {
  const schemeCopy = Object.assign(Object.create(Object.getPrototypeOf(scheme)), scheme) as IQuestionScheme;

  if (!schemeCopy.questions) {
    return scheme;
  }

  for (const question of schemeCopy.questions) {
    if (question.id === id) {
      schemeCopy.questions = schemeCopy?.questions?.filter((x: ISurveyQuestion) => x.id !== id);
    }

    if (!question.options) {
      continue;
    }

    for (const option of question.options) {
      if (isConditionalOption(option) && option.questionScheme) {
        const result = removeQuestionById(option.questionScheme, id);

        if (result === option.questionScheme) {
          continue;
        }
        option.questionScheme = result as QuestionScheme;
      }
    }
  }

  return schemeCopy;
};

export const pasteQuestionAfter = (
  scheme: IQuestionScheme,
  id: string,
  newQuestion: SurveyQuestion,
): IQuestionScheme | null => {
  const schemeCopy = Object.assign(Object.create(Object.getPrototypeOf(scheme)), scheme);

  if (!schemeCopy.questions) {
    return schemeCopy as IQuestionScheme;
  }

  for (const question of schemeCopy.questions) {
    if (question.id === id) {
      schemeCopy.questions = [...(schemeCopy?.questions ?? []), newQuestion];
    }

    if (!question.options) {
      continue;
    }

    for (const option of question.options) {
      if (isConditionalOption(option) && option.questionScheme) {
        const result = pasteQuestionAfter(option.questionScheme, id, newQuestion);

        if (result === option.questionScheme) {
          continue;
        }
        option.questionScheme = result as QuestionScheme;
      }
    }
  }

  return schemeCopy as IQuestionScheme;
};

export const moveQuestion = (scheme: IQuestionScheme, id: string, direction: 'Up' | 'Down'): IQuestionScheme => {
  const schemeCopy = Object.assign(Object.create(Object.getPrototypeOf(scheme)), scheme) as IQuestionScheme;

  if (!schemeCopy.questions) {
    return schemeCopy;
  }

  for (const question of schemeCopy.questions) {
    if (question.id === id) {
      const curQuestionIndex = scheme?.questions?.findIndex((x) => x.id === id);
      if (curQuestionIndex === undefined || curQuestionIndex === -1) {
        return schemeCopy;
      }

      const newQuestions = Array.from(schemeCopy?.questions, (q, i) => {
        if (schemeCopy?.questions && i === curQuestionIndex) {
          return schemeCopy?.questions[i - (direction === 'Up' ? 1 : -1)];
        }

        if (schemeCopy?.questions && i === curQuestionIndex - (direction === 'Up' ? 1 : -1)) {
          return schemeCopy?.questions[curQuestionIndex];
        }

        return q;
      });

      schemeCopy.questions = newQuestions;
      return schemeCopy;
    }

    if (!question.options) {
      continue;
    }

    for (const option of question.options) {
      if (isConditionalOption(option) && option.questionScheme) {
        const result = moveQuestion(option.questionScheme, id, direction);

        if (result === option.questionScheme) {
          continue;
        }
        option.questionScheme = result as QuestionScheme;
      }
    }
  }

  return schemeCopy;
};
