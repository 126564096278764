export enum MetricCategories {
  Application = 'Application',
  Network = 'Network',
  Page = 'Page',
  Survey = 'Survey',
  EvaluationForm = 'Evaluation form',
  Patient = 'Patient',
  Notes = 'Notes',
  SurveyStatistic = 'Survey statistics',
  Study = 'Study',
}
