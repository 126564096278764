import clsx from 'clsx';
import React, { useMemo } from 'react';
import { PatientRiskGroupProps } from './patientRiskGroup';
import Styles from './patientRiskGroup.module.css';
import { CategoryAchievement, PatientCategoryEnum } from '../../services/api/api-client';
import { useTranslation } from 'react-i18next';
import { getAllPresentedFlags } from '../../helpers/enum-helper';
import { TypographyStyles } from '../../styles';
import { LocalizedResourceDictionaryKeys } from '../../application/localisation/i18next';

export const PatientRiskGroupComponent = (props: PatientRiskGroupProps) => {
  const { t } = useTranslation();
  const { category } = props;

  const renderAchievements = useMemo(() => {
    if (category?.quality === PatientCategoryEnum.Pending) {
      return (
        <span className={Styles.placeholder}>{t('Dashboard.PatientTable.PatientRiskGroup.PendingPlaceholder')}</span>
      );
    }

    if (category?.quality === PatientCategoryEnum.Default) {
      return <span className={Styles.placeholder}>{t('Dashboard.PatientTable.PatientRiskGroup.NoDevice')}</span>;
    }

    if (category?.quality === PatientCategoryEnum.Stopped) {
      return <span className={Styles.placeholder}>{t('Dashboard.PatientTable.PatientRiskGroup.Stopped')}</span>;
    }

    if (!category?.achievements) {
      return <></>;
    }

    const allAchievements = getAllPresentedFlags(category?.achievements);
    return (
      <ul className={clsx({ [Styles.achievements]: true, [Styles.singleAchievements]: allAchievements.length === 1 })}>
        {allAchievements.map((x, index) => {
          return (
            <li key={index}>
              <span className={clsx(TypographyStyles.paragraph12)}>
                {t(
                  `Dashboard.PatientTable.PatientRiskGroup.${CategoryAchievement[x]}` as LocalizedResourceDictionaryKeys,
                )}
              </span>
            </li>
          );
        })}
      </ul>
    );
  }, [category?.achievements, category?.quality, t]);

  if (!category) return <></>;

  return <div className={clsx(Styles.container, TypographyStyles.paragraph12)}>{renderAchievements}</div>;
};
