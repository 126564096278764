import React, { useCallback } from 'react';
import Style from './stepper.module.css';
import clsx from 'clsx';
import { StepperProps } from './stepper';
import { TypographyStyles } from 'src/styles';

export const AppStepper = (props: StepperProps) => {
  const { variant, activeStep, stepCount, labels, onStepClick } = props;

  const renderStepItem = useCallback(
    (stepNumber: number) => {
      return (
        <React.Fragment key={stepNumber}>
          <div className={clsx(Style.stepItemDash, { [Style.completedStep]: activeStep >= stepNumber })} />
          <div
            key={stepNumber}
            data-test-id={`step-navigation-${stepNumber}`}
            onClick={() => onStepClick?.(stepNumber)}
            className={clsx(Style.stepItem, TypographyStyles.buttonText, {
              [Style.completedStep]: activeStep >= stepNumber,
              [Style.inactiveStep]: activeStep < stepNumber,
              [Style.activeStep]: activeStep === stepNumber,
              [Style.cursorPointer]: onStepClick,
            })}
          >
            {stepNumber}
          </div>
        </React.Fragment>
      );
    },
    [onStepClick, activeStep],
  );

  const renderTabItem = useCallback(
    (stepNumber: number) => {
      const tabLabel = (labels && labels[stepNumber - 1]) ?? stepNumber;
      return (
        <div
          key={stepNumber}
          className={clsx(Style.tabItem, { [Style.cursorPointer]: onStepClick })}
          data-test-id={`tab-navigation-${tabLabel}`}
          onClick={() => onStepClick?.(stepNumber)}
        >
          <div
            className={clsx(Style.tabLabel, TypographyStyles.plainText12, {
              [Style.tabLabelCompleted]: activeStep >= stepNumber,
            })}
          >
            {tabLabel}
          </div>
          <div
            className={clsx(Style.tabLine, {
              [Style.tabLineCompleted]: activeStep >= stepNumber,
            })}
          />
        </div>
      );
    },
    [labels, onStepClick, activeStep],
  );

  const content = Array.from({ length: stepCount }, (_, i) => {
    if (variant === 'steps') {
      return renderStepItem(i + 1);
    }

    if (variant === 'tabs') {
      return renderTabItem(i + 1);
    }

    return <></>;
  });

  return (
    <div
      className={clsx({
        [Style.stepperHeader]: variant === 'steps',
        [Style.tabsHeader]: variant === 'tabs',
      })}
    >
      {content}
    </div>
  );
};
