import { de, enUS } from 'date-fns/locale';
import { Locale as DateFNSLocale } from 'date-fns';

export const Languages = ['en', 'de'] as const;
export const Namespaces = ['localization', 'dev'] as const;

export type Language = (typeof Languages)[number];
export type Namespace = (typeof Namespaces)[number];

export const dateLocales: { [key in Language]: DateFNSLocale } = { en: enUS, de: de };

export const fallbackLng: Language = 'en';
export const defaultNS: Namespace = 'localization';
