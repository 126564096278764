import React, { useCallback, useEffect, useMemo, useRef, useState, FC } from 'react';
import { AnswerDto, SurveyDto } from '../../../../services/api/api-client';
import { QuestionnaireToCreate, QuestionnaireVm } from './ManageSurveysModal';
import { isToday, startOfDay, startOfToday } from 'date-fns';
import { useSurveyIcons } from '../../../../application/hooks/useSurveyIcon';
import { getMaxDate, getMinDate } from '../../../../helpers/date-helpers';
import clsx from 'clsx';
import Style from './manageSurveys.module.scss';
import { TypographyStyles } from 'src/styles';
import { AppButton } from '../../../uikit/buttons/button/button.component';
import { ReactComponent as PlusIcon } from '../../../../assets/img/common/plus_20.svg';
import { AppDateRangePicker } from '../../../daterangePickerV2/daterangePicker.component';
import { ReactComponent as CheckIcon } from '../../../../assets/img/common/check_24.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/img/common/cross_24.svg';
import { QueryFactory } from '../../../../services/api';
import { CreatingConfirmationDialog } from './CreatingConfirmationDialog';
import { logger } from '../../../../application/logging/logging';
import { showErrorToast } from '../../../toast/toast-helper';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import Timer = NodeJS.Timer;
import { DropdownOption } from '../../../uikit/inputs/dropdown/appDropdownInput';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';

export const AdditionRow: FC<{
  surveys: SurveyDto[];
  patientId: string;
  questionnaireVms: QuestionnaireVm[];
  answers: AnswerDto[];
  studyStartDate: Date;
  studyFinishDate: Date;
  onCreated: () => Promise<void>;
}> = (props) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection.SurveyManaging.Modal');
  const { answers, onCreated, questionnaireVms, studyFinishDate, studyStartDate, surveys } = props;

  const [isActive, setIsActive] = useState(false);

  //#region Survey selecting

  const [selectedSurvey, setSelectedSurvey] = useState<(typeof options)[0] | undefined>();

  const surveyIcons = useMemo(
    () =>
      surveys
        .map((x) => x.icon)
        // distinct
        .filter((x, i, arr) => arr.indexOf(x) === i),
    [surveys],
  );

  const svgIcons = useSurveyIcons(surveyIcons, 'small', 'filled');

  const options: DropdownOption<number>[] = useMemo(
    () =>
      surveys.map((x) => {
        const Icon = svgIcons.SvgIcons[surveyIcons.indexOf(x.icon)]!;
        return {
          key: x.id,
          icon: <Icon />,
          text: x.typeString,
        };
      }) ?? [],
    [surveyIcons, surveys, svgIcons],
  );

  const onSelectedSurveyChange = useCallback((option: typeof selectedSurvey) => {
    setSelectedSurvey(option);
    setSelectedRange(null);
  }, []);

  //#endregion

  //#region Period selecting

  // The interval is inclusive and the end become exclusive right before a request sending
  const [selectedRange, setSelectedRange] = useState<Interval | null>(null);

  const reservedRanges = useMemo<Interval[]>(() => {
    const ranges = questionnaireVms
      .filter((x) => x.surveyId === selectedSurvey?.key)
      .map((x) => ({
        start: x.start,
        end: x.end,
      }));

    for (const answer of answers.filter((x) => x.surveyId === selectedSurvey?.key)) {
      const creationDate = startOfDay(answer.creationDate);
      ranges.push({
        start: creationDate,
        end: creationDate,
      });
    }

    return ranges;
  }, [answers, questionnaireVms, selectedSurvey?.key]);

  const disabledRanges = useMemo(() => {
    const ranges: Interval[] = [{ start: getMinDate(), end: startOfToday() }];

    if (studyStartDate !== null) ranges.push({ start: getMinDate(), end: studyStartDate });

    if (studyFinishDate !== null) ranges.push({ start: studyFinishDate, end: getMaxDate() });

    return ranges;
  }, [studyStartDate, studyFinishDate]);

  //#endregion

  //#region Success creating toast

  const [successToastIsShowed, setSuccessToastIsShowed] = useState(false);
  const successToastTimer = useRef<Timer | null>(null);
  useEffect(
    () => () => {
      setSuccessToastIsShowed(false);
      const timer = successToastTimer.current;
      if (timer !== null) {
        clearTimeout(timer);
        successToastTimer.current = null;
      }
    },
    [],
  );

  const showToast = useCallback(() => {
    setSuccessToastIsShowed(true);

    const timer = successToastTimer.current;
    if (timer !== null) {
      clearTimeout(timer);
    }

    successToastTimer.current = setTimeout(() => {
      setSuccessToastIsShowed(false);
      successToastTimer.current = null;
    }, 1200);
  }, []);

  //#endregion

  //#region Creating

  const [questionnaireToCreate, setQuestionnaireToCreate] = useState<QuestionnaireToCreate | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const createQuestionnaire = useCallback(
    async (questionnaire: QuestionnaireToCreate, force?: boolean) => {
      if (isToday(questionnaire.range.start) && !force) {
        setQuestionnaireToCreate(questionnaire);
        return;
      }

      setIsCreating(true);
      try {
        await QueryFactory.SurveyQuery.Client.createQuestionnaire(
          props.patientId,
          questionnaire.survey.id,
          new Date(questionnaire.range.start),
          new Date(questionnaire.range.end),
        );

        setIsActive(false);
        setSelectedSurvey(undefined);
        setSelectedRange(null);

        showToast();
        await onCreated();
      } catch (err: any) {
        logger().error(err);
        showErrorToast(err);
      } finally {
        setQuestionnaireToCreate(null);
        setIsCreating(false);
      }
    },
    [showToast, onCreated, props.patientId],
  );

  const onApplyCreating = useCallback(() => {
    // noinspection JSIgnoredPromiseFromCall
    createQuestionnaire(questionnaireToCreate!, true);
  }, [createQuestionnaire, questionnaireToCreate]);

  const onCancelCreating = useCallback(() => {
    setQuestionnaireToCreate(null);
  }, []);

  const onApply = useCallback(() => {
    if (!selectedSurvey || !selectedRange) return;

    // noinspection JSIgnoredPromiseFromCall
    createQuestionnaire({ survey: surveys.find((x) => x.id === selectedSurvey.key)!, range: selectedRange });
  }, [createQuestionnaire, selectedRange, selectedSurvey, surveys]);

  const onCancel = useCallback(() => {
    setIsActive(false);
    setSelectedSurvey(undefined);
    setSelectedRange(null);
  }, []);

  //#endregion

  return (
    <div className={clsx(Style.row, Style.addingRow, { [Style.active]: isActive })}>
      {!isActive && (
        <>
          <AppButton
            variant={'icon-link'}
            colorSchema={'primary'}
            Icon={PlusIcon}
            text={t('AddNew')}
            onClick={() => setIsActive(true)}
            testId={'add-survey-button'}
          />
          {successToastIsShowed && <span className={TypographyStyles.paragraph14}>{t('NewEventSaved')}</span>}
        </>
      )}
      {isActive && (
        <>
          <AppDropDownWithSuggestionInput
            className={Style.typeColumn}
            options={options}
            onChange={onSelectedSurveyChange}
            value={selectedSurvey}
            placeholder={t('SelectTypePlaceholder')}
          />
          <AppDateRangePicker
            disabled={!selectedSurvey}
            selectedRange={selectedRange}
            onSelectedRangeChanged={setSelectedRange}
            disabledRanges={disabledRanges}
            reservedRanges={reservedRanges}
          />
          <div className={Style.actionsColumn}>
            <AppButton
              variant={'icon-link'}
              colorSchema={'confirm'}
              disabled={!selectedSurvey || !selectedRange}
              Icon={CheckIcon}
              onClick={onApply}
            />
            <AppButton onClick={onCancel} variant={'icon-link'} colorSchema={'basic'} Icon={CrossIcon} />
          </div>
        </>
      )}
      <CreatingConfirmationDialog
        questionnaireToCreate={questionnaireToCreate}
        isLoading={isCreating}
        onApply={onApplyCreating}
        onCancel={onCancelCreating}
      />
    </div>
  );
};
