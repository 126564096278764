import * as FaqEvents from './faq-events';
import { ActionType, getType } from 'typesafe-actions';
import { FaqState, defaultFaqState } from './faq-state';

export type FaqActions = ActionType<typeof FaqEvents>;
export { FaqEvents };

export function faqReducer(state: FaqState = defaultFaqState, action: FaqActions): FaqState {
  switch (action.type) {
    case getType(FaqEvents.addToBuffer): {
      return {
        ...state,
        buffer: [...state.buffer, action.payload],
      };
    }

    case getType(FaqEvents.removeFromBuffer): {
      return {
        ...state,
        buffer: state.buffer.filter((x) => x.rootNodeId !== action.payload),
      };
    }

    default:
      return state;
  }
}
