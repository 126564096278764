import React, { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { INumberInput } from 'src/features/forms/base/controls/inputs/INumberInput';
import { useSkipReasonText } from 'src/components/formEditor/controls/Inputs/base/hooks';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const NumberInput: INumberInput = ({ label, dataKey, units, decimal, skipCheckBoxText }) => {
  const inputValue = useContextSelector(RecordContext, (v) => v.formResult?.[dataKey]);
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;
  const skipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.[dataKey]);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const answerValue = useMemo(() => {
    if (skipReason !== undefined) {
      return skipReasonText;
    }

    return singleInputValue
      ? `${decimal !== undefined ? Number(singleInputValue).toFixed(decimal) : singleInputValue} ${units ?? ''}`
      : null;
  }, [decimal, singleInputValue, skipReason, skipReasonText, units]);

  return <Field label={label ?? dataKey} skipReason={skipReasonText} value={answerValue} />;
};
