import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import Style from './reasonModal.module.scss';
import { UseModalProps, useModal } from 'src/application/hooks/useModal';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { FieldEditReason, FieldEditReasonEnum } from 'src/services/api/api-client';
import { Deferred } from '../../../../helpers/Deferred';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
import { useGetFieldChanges } from '../tagsModal/useGetFieldChanges';
import { HookFormRadioGroup } from 'src/hookFormControls/hookFormRadioGroup';
import { layoutToDataKeyFlatList, normalizeDataKey } from '../../uiEditor/uiEditor-helper';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { HookFormTextInput } from 'src/hookFormControls/hookFormTextInput';
import { ValidationFormRules } from 'src/helpers/validation-helpers';
import { ConfirmResult, useConfirmationModal } from 'src/components/dialogModal/useConfirmationModal';
import { DiffValueComponent } from './diffValueComponent/diffValue.component';

export type ReasonModalResult = {
  fieldEditReasons: { [key: string]: FieldEditReason };
};

type AllEach = 'All' | 'Each';
type AllEachOption = {
  key: AllEach;
  text: string;
};

type ReasonModalType = {
  onSave: (value: ReasonModalResult) => void;
  modalManager: UseModalProps;
};

type ReasonModalFormType = {
  allEach: AllEach;
  fieldEditReasonForAll: FieldEditReason;
  fieldEditReasons: {
    key: string;
    value: FieldEditReason;
  }[];
};

export const useReasonModal = () => {
  const modal = useModal('CLOSED');
  const deferred = useRef<Deferred<ReasonModalResult>>();

  return useMemo(
    () => ({
      open: () => {
        modal.openModal();
        deferred.current = new Deferred<ReasonModalResult>();
        return deferred.current.promise;
      },
      element: (
        <ReasonModal
          onSave={(value) => {
            modal.closeModal();
            deferred.current?.resolve(value);
          }}
          modalManager={modal}
        />
      ),
    }),
    [modal],
  );
};

const ReasonModal = (props: ReasonModalType) => {
  const { t } = useScopedTranslation('Forms.EditFieldsReasonModal');
  const commonLocalizer = useCommonLocalization();
  const nodes = useContextSelector(FormFillContext, (x) => x.nodes);
  const confirmationModal = useConfirmationModal({ containerClassName: Style.leavingModal });
  const {
    onSave,
    modalManager: { visible, closeModal },
  } = props;

  const { initialValues, initialSkippedFieldsData } = useContextSelector(FormFillContext, (x) => x);
  const { valueChanges, skipFieldChanges } = useGetFieldChanges();

  const form = useForm<ReasonModalFormType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const dataKeys = useMemo(() => (nodes ? layoutToDataKeyFlatList(nodes) : []), [nodes]);

  const editedFieldKeys = useMemo(() => {
    const changedFieldKeys = [...new Set([...Object.keys(valueChanges), ...Object.keys(skipFieldChanges)])];
    const initialFieldKeys = [
      ...new Set([...Object.keys(initialValues ?? {}), ...Object.keys(initialSkippedFieldsData ?? {})]),
    ];
    const result = [...new Set(changedFieldKeys.filter((x) => initialFieldKeys.includes(x)).map(normalizeDataKey))];

    const orderByList = (a: string, b: string) => dataKeys.indexOf(a) - dataKeys.indexOf(b);

    return result.sort(orderByList);
  }, [dataKeys, initialSkippedFieldsData, initialValues, skipFieldChanges, valueChanges]);

  const resetForm = useCallback(() => {
    form.reset({
      allEach: 'All',
      fieldEditReasons: editedFieldKeys.map((x) => ({ key: x, value: {} as FieldEditReason })),
    });

    // Workaround for https://github.com/react-hook-form/react-hook-form/issues/11836
    form.resetField('fieldEditReasons', {
      defaultValue: editedFieldKeys.map((x) => ({ key: x, value: {} as FieldEditReason })),
    });
  }, [editedFieldKeys, form]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const allEachOptions = useAllEachOptions();

  const onSubmit = form.handleSubmit((data: ReasonModalFormType) => {
    const fieldEditReasons = data.fieldEditReasons.reduce(
      (result, x) => ({
        ...result,
        [x.key]:
          data.allEach === 'All' ? new FieldEditReason(data.fieldEditReasonForAll) : new FieldEditReason(x.value),
      }),
      {},
    );
    onSave({ fieldEditReasons });
  });

  const onDismiss = useCallback(async () => {
    const result = await confirmationModal.open({
      title: t('LeavingModal.Header'),
      text: t('LeavingModal.Text'),
      cancelButtonColorSchema: 'destroy',
      okButtonColorSchema: 'primary',
      cancelButtonText: t('LeavingModal.CancelButton'),
      okButtonText: t('LeavingModal.OkButton'),
    });

    if (result === ConfirmResult.Cancel) {
      resetForm();
      closeModal();
    }

    if (result === ConfirmResult.Confirm) {
      onSubmit();
    }
  }, [closeModal, confirmationModal, onSubmit, resetForm, t]);

  return (
    <>
      <DialogModal
        visible={visible}
        onHide={onDismiss}
        testId={'reason-modal'}
        title={t('ModalHeader')}
        isClickOutside={false}
        containerClassName={Style.modal}
        bodyClassName={Style.modalBody}
        footer={{
          leftButton: {
            text: commonLocalizer('Common_Cancel'),
            type: 'reset',
            onClick: onDismiss,
          },
          rightButton: {
            text: commonLocalizer('Common_Save'),
            type: 'submit',
            onClick: onSubmit,
          },
        }}
      >
        <FormProvider {...form}>
          <div className={Style.fieldsContainer}>
            <HookFormRadioGroup
              control={form.control}
              name={'allEach'}
              options={allEachOptions}
              valueField={'key'}
              labelField={'text'}
              labelProps={{
                text: t('StateField.Label'),
              }}
            />
            {form.watch('allEach') === 'All' && <AllFieldsFormContent />}
            {form.watch('allEach') === 'Each' && <EachFieldFormContent />}
          </div>
        </FormProvider>
      </DialogModal>
      {confirmationModal.modal}
    </>
  );
};

const AllFieldsFormContent: FC = () => {
  const { t } = useScopedTranslation('Forms.EditFieldsReasonModal');
  const form = useFormContext<ReasonModalFormType>();
  const reasonOptions = useReasonOptions();

  return (
    <>
      <HookFormRadioGroup
        control={form.control}
        name={'fieldEditReasonForAll.editReason'}
        labelProps={{ text: t('CauseOfEditingField.Label') }}
        valueField={'key'}
        labelField={'text'}
        options={reasonOptions}
        rules={{ ...ValidationFormRules().requiredRule }}
      />
      {form.watch('fieldEditReasonForAll.editReason') === FieldEditReasonEnum.Update && (
        <HookFormTextInput
          control={form.control}
          name={'fieldEditReasonForAll.editReasonText'}
          type={'text-area'}
          labelProps={{ text: t('ReasonUpdateTextField.Label') }}
          placeholder={t('ReasonUpdateTextField.Placeholder')}
          triggerProps={{
            initialState: 'Trigger',
            text: t('ReasonUpdateTextField.TriggerText'),
            type: 'Comment',
          }}
        />
      )}
    </>
  );
};

const EachFieldFormContent: FC = () => {
  const { t } = useScopedTranslation('Forms.EditFieldsReasonModal');
  const form = useFormContext<ReasonModalFormType>();
  const fieldEditReasons = useFieldArray<ReasonModalFormType>({
    control: form.control,
    name: 'fieldEditReasons',
  });

  const reasonOptions = useReasonOptions();

  return (
    <>
      {fieldEditReasons.fields.map((field, i) => {
        return (
          <React.Fragment key={field.key}>
            <div>
              <DiffValueComponent dataKey={field.key} />
              <HookFormRadioGroup
                control={form.control}
                name={`fieldEditReasons.${i}.value.editReason`}
                labelProps={{ text: '' }}
                valueField={'key'}
                labelField={'text'}
                options={reasonOptions}
                rules={{ ...ValidationFormRules().requiredRule }}
              />
            </div>
            {form.watch(`fieldEditReasons.${i}.value.editReason`) === FieldEditReasonEnum.Update && (
              <HookFormTextInput
                control={form.control}
                name={`fieldEditReasons.${i}.value.editReasonText`}
                type={'text-area'}
                labelProps={{ text: t('ReasonUpdateTextField.Label') }}
                placeholder={t('ReasonUpdateTextField.Placeholder')}
                triggerProps={{
                  initialState: 'Trigger',
                  text: t('ReasonUpdateTextField.TriggerText'),
                  type: 'Comment',
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export const useReasonOptions = () => {
  const { t } = useScopedTranslation('Forms.EditFieldsReasonModal');

  return useMemo(
    () => [
      {
        key: FieldEditReasonEnum.WrongEntry,
        text: t('CauseOfEditingField.Options.WrongEntry'),
      },
      {
        key: FieldEditReasonEnum.Update,
        text: t('CauseOfEditingField.Options.Update'),
      },
    ],
    [t],
  );
};

export const useAllEachOptions = () => {
  const { t } = useScopedTranslation('Forms.EditFieldsReasonModal');

  return useMemo(
    () =>
      [
        {
          key: 'All',
          text: t('StateField.Options.All'),
        },
        {
          key: 'Each',
          text: t('StateField.Options.Each'),
        },
      ] satisfies AllEachOption[],
    [t],
  );
};
