import React, { useCallback, useState } from 'react';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';
import { useContextSelector } from 'use-context-selector';
import { QuestionSchemeOverview } from '../../components/questionSchemeOverview/questionSchemeOverview.component';
import { QuestionSchemeEditorContext } from '../../container/questionSchemeUIEditor.context';
import { EditorToolBoxType as EditorType } from './editor';

import Style from './editor.module.css';

export const Editor = (props: EditorType) => {
  const { route } = props;
  const createQuestion = useContextSelector(QuestionSchemeEditorContext, (x) => x.createQuestion)!;
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const onPressHandler = useCallback(async () => {
    setIsCreating(true);
    await createQuestion(route);
    setIsCreating(false);
  }, [createQuestion, route]);

  return (
    <div className={Style.container}>
      {props.scheme && <QuestionSchemeOverview {...props} />}
      <AppButton
        className={Style.button}
        text={'Add question'}
        variant={'button'}
        colorSchema={'primary'}
        onClick={onPressHandler}
        disabled={isCreating}
      />
    </div>
  );
};
