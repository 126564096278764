import React, { FC } from 'react';
import { PermissionsCheckProps } from './PermissionCheck';
import { useHasPermissions } from '../../auth/auth-helper';

export const PermissionsCheck: FC<PermissionsCheckProps> = (props) => {
  const { hasPermission } = useHasPermissions();
  const accessIsAllowed = hasPermission(props.permissions);

  if (props.children instanceof Function) {
    return <>{props.children(accessIsAllowed)}</>;
  }

  return accessIsAllowed ? <>{props.children}</> : <></>;
};
