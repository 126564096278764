import React from 'react';
import { useEditor } from '@craftjs/core';

export const SettingsPanel = () => {
  const { active, related } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first();
    return {
      active: currentlySelectedNodeId,
      related: currentlySelectedNodeId && state.nodes[currentlySelectedNodeId].related,
    };
  });

  if (!active) {
    return <></>;
  }

  return related.settings && React.createElement(related.settings);
};
