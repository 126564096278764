/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import clsx from 'clsx';
import { TypographyStyles } from 'src/styles';
import { useTranslation } from 'react-i18next';
import styles from '../controlsStyle.module.css';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import {
  calculatedInputPropsFactory,
  ICalculatedInput,
} from '../../../../features/forms/base/controls/specials/CalculatedBlock';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';

export const CalculatedBlock: ICalculatedInput = (props) => {
  const { t } = useTranslation();
  const { connectors, setProp, id } = useNode();
  const { connect, drag } = connectors;

  const { size, dataKey } = {
    ...calculatedInputPropsFactory(),
    ...props,
  };

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const { isEditorEnabled } = useEditor((state) => ({
    isEditorEnabled: state.options.enabled,
  }));

  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const inputValue = useContextSelector(FormFillContext, (v) => (v.values ? v.values[dataKey] : undefined));
  const fieldEditReason = useContextSelector(FormFillContext, (v) => v.fieldEditReasons?.[dataKey]);

  useEffect(() => {
    if (isEditorEnabled) {
      setProp((setProps) => (setProps.label = !props.hideLabel ? props.label || dataKey : undefined));
    }
  }, []);

  const editableComponent = (
    <div
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isEditorEnabled}
      ref={(ref) => isEditorEnabled && connect(drag(ref!))}
    >
      <div className={styles.calculatedBlockContainer}>
        {props.label && (
          <label
            aria-disabled={isSubmitting}
            className={clsx(TypographyStyles.paragraph12, styles.calculatedBlockLabel, {
              [styles.bold]: props.isBold,
            })}
          >
            {props.label}
          </label>
        )}
        <span
          aria-disabled={isSubmitting}
          className={clsx(TypographyStyles.paragraph14, styles.calculatedBlockValue)}
        >{`${inputValue ?? t('Common_dash')} ${props.units ?? ''}`}</span>
      </div>
    </div>
  );

  const viewComponent = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label || props.dataKey,
        resultId: formResultVersion?.formResultId ?? undefined,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
    >
      <OverviewField
        label={props.label ?? props.dataKey}
        value={`${inputValue ?? ''} ${props.units ?? ''}`}
        editReason={fieldEditReason}
      />
    </IssueMark>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

const CalculatedBlockSettings = () => {
  return <></>;
};

CalculatedBlock.craft = {
  defaultProps: calculatedInputPropsFactory(),
  related: {
    settings: CalculatedBlockSettings,
  },
};
