import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import Slider from 'rc-slider';
import Style from './appSliderInput.module.scss';
import { AppSliderInputProps, SliderColorSchemeEnum } from './appSliderInput';
import { Gradient } from '../../../../helpers/gradient';
import 'rc-slider/assets/index.css';
import './styles.css';
import { TypographyStyles } from 'styles';
import { AppTextInput } from '../text/appTextInput.component';
import { isNullOrEmpty } from 'src/helpers/string-helper';

export const AppSliderInput: FC<AppSliderInputProps> = ({
  startWith,
  endWith,
  startPoint,
  value,
  onChange,
  showMarks,
  disabled,
  className,
  caption,
  showNumberInput,
  step = 1,
  marksStep = 1,
  colorScheme = SliderColorSchemeEnum.SingleColor,
  ...rest
}) => {
  const [internalValue, setInternalValue] = useState<number | undefined>(value);
  useEffect(() => {
    setInternalValue(value ?? startPoint);
  }, [startPoint, value]);

  const { min, max, isReversed } = useMemo(() => {
    const isRev = startWith > endWith;
    return isRev
      ? { min: endWith, max: startWith, isReversed: isRev }
      : { min: startWith, max: endWith, isReversed: isRev };
  }, [endWith, startWith]);

  const color = useMemo(() => {
    if (internalValue === undefined || internalValue === null) {
      return undefined;
    }

    const gradient = (() => {
      switch (colorScheme) {
        case SliderColorSchemeEnum.GreenToRed:
          return new Gradient().setColorGradient('#33CAB8', '#F7E06A', '#EC7750');
        case SliderColorSchemeEnum.RedToGreen:
          return new Gradient().setColorGradient('#EC7750', '#F7E06A', '#33CAB8');
        default:
          return new Gradient().setColorGradient('#BBC4EE', '#BBC4EE');
      }
    })();

    return gradient.setMidpoint(max - min).getColor(internalValue - min - 1);
  }, [internalValue, max, min, colorScheme]);

  const marks = useMemo(() => {
    if (!showMarks) return undefined;

    return Array.from({ length: max - min + 1 }, (__, i) => min + i)
      .filter((x, i, arr) => i % marksStep === 0 || i === arr.length - 1)
      .reduce((obj, cur) => {
        return {
          ...obj,
          [cur]: <div className={clsx(Style.mark, { [Style.markSelected]: cur === internalValue })}>{cur}</div>,
        };
      }, {});
  }, [showMarks, max, min, marksStep, internalValue]);

  const prepareValue = useCallback(
    (val: number) => {
      const valueInBorders = Math.max(min, Math.min(max, val));
      const ceilValue = Math.ceil(valueInBorders / step) * step;
      // Trick to remove float error like 0.1 = 0.10000000000000005
      return +ceilValue.toFixed(2);
    },
    [max, min, step],
  );

  const onChangeHandler = useCallback(
    (val: number | number[] | undefined) => {
      if (Array.isArray(val)) return;

      setInternalValue(val);
      return onChange?.(val);
    },
    [onChange],
  );

  return (
    <div className={clsx(Style.main, className)}>
      <div className={Style.container}>
        <div className={clsx(TypographyStyles.plainText14, Style.sliderCaption, { [Style.disabled]: disabled })}>
          <div>{caption?.startText}</div>
          <div>{caption?.centerText}</div>
          <div>{caption?.endText}</div>
        </div>
        <div className={Style.sliderWrapper}>
          <Slider
            disabled={disabled}
            min={min}
            max={max}
            reverse={isReversed}
            defaultValue={startPoint ?? startWith}
            startPoint={startPoint}
            step={step}
            // This trick removes active mark styles and handle
            // @ts-ignore
            value={internalValue ?? null}
            onChange={onChangeHandler}
            className={clsx(Style.slider, { [Style.disabled]: disabled })}
            styles={{
              track: {
                backgroundColor: color,
              },
            }}
            activeDotStyle={{ borderColor: !startPoint ? color : undefined }}
            marks={marks}
            {...rest}
          />
        </div>
      </div>
      {showNumberInput && (
        <AppTextInput
          type={'number'}
          inputClassName={Style.textInput}
          value={internalValue ?? ''}
          onChange={(e) => setInternalValue(isNullOrEmpty(e.target.value) ? undefined : +e.target.value)}
          onBlur={(e) => onChangeHandler(isNullOrEmpty(e.target.value) ? undefined : prepareValue(+e.target.value))}
        />
      )}
    </div>
  );
};
