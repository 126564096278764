import React, { FC, PropsWithChildren, useMemo } from 'react';
import TherapyStyle from './therapyTooltip.module.css';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import Style from '../baseIndicator.module.css';
import { getIndicatorStyleColor } from '../../../helpers/color-helper';
import { BaseIndicatorWrapper } from '../baseIndicator.component';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { TherapyTooltipProps } from './therapyTooltip';
import clsx from 'clsx';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';

export const TherapyTooltipComponent: FC<PropsWithChildren<TherapyTooltipProps>> = (
  props: PropsWithChildren<TherapyTooltipProps>,
) => {
  const { t: tooltipLocalizer } = useScopedTranslation('Dashboard.PatientTable.TherapyTooltip');
  const commonLocalizer = useCommonLocalization();
  const { values, Icon, valueFormatter, tooltipValueText, testId } = props;

  const tooltipHeaderText = useMemo(() => {
    if (
      !props ||
      (!props.indicator?.value && props.indicator?.value !== 0) ||
      props.indicator.deviceIsNotUsedForAggregatedPeriod ||
      props.indicator.therapyIsNotReceivedForAggregatedPeriod ||
      props.indicator.telemonitoringIsDisabled
    ) {
      return '';
    }

    return tooltipLocalizer('TooltipHeader', {
      daysCount: props.indicator.numberOfAggregatedDays,
    });
  }, [props, tooltipLocalizer]);

  const tooltipText = useMemo(() => {
    if (!props) {
      return commonLocalizer('Common_NoData');
    }

    if (props.indicator?.telemonitoringIsDisabled) {
      return tooltipLocalizer('TooltipTelemonitoringIsDisabled');
    }

    if (props.indicator?.therapyIsNotReceivedForAggregatedPeriod) {
      return tooltipLocalizer('TooltipNotReceivedMoreThatThreeDays', {
        postProcess: 'interval',
        count: props?.indicator?.numberOfAggregatedDays,
      });
    }

    if (
      !props.indicator?.therapyIsNotReceivedForAggregatedPeriod &&
      props.indicator?.deviceIsNotUsedForAggregatedPeriod
    ) {
      return tooltipLocalizer('TooltipNotUsed', {
        postProcess: 'interval',
        count: props?.indicator?.numberOfAggregatedDays,
      });
    }

    if (!props.indicator?.value && props.indicator?.value !== 0) {
      return commonLocalizer('Common_NoData');
    }

    return tooltipValueText;
  }, [props, tooltipValueText, tooltipLocalizer, commonLocalizer]);

  const isNullOrEmpty = useMemo(() => {
    return (
      props.indicator?.therapyIsNotReceivedForAggregatedPeriod ||
      props.indicator?.deviceIsNotUsedForAggregatedPeriod ||
      props.indicator?.telemonitoringIsDisabled ||
      !props.indicator
    );
  }, [props.indicator]);

  const listOfParams = useMemo(() => {
    const allParamKeys = values.flatMap((x) => Object.keys(x.values));

    return Array.from(new Set(allParamKeys));
  }, [values]);

  const dailyTable = useMemo(() => {
    return (
      <table className={TherapyStyle.therapyTooltip} data-test-id={'therapy-tooltip-table'}>
        <thead>
          <tr>
            <th>{tooltipLocalizer('therapyDate')}</th>
            {values.map((x, index) => {
              return (
                <th data-test-id={'therapy-tooltip-table-date-column'} key={index}>
                  {localFormat(x.therapyDate, DateFormats.longWithoutYear)}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr data-test-id={'therapy-tooltip-table-quality-row'}>
            <td>{tooltipLocalizer('quality')}</td>
            {values.map((x, index) => {
              return (
                <td data-test-id={'therapy-tooltip-table-quality-value'} key={index}>
                  <Icon className={clsx(getIndicatorStyleColor(x.quality), TherapyStyle.qualityIconBase)} />
                </td>
              );
            })}
          </tr>
          {listOfParams.map((param) => {
            return (
              <tr key={param} data-test-id={`therapy-tooltip-table-${param}-row`}>
                {
                  <td>
                    {
                      // @ts-ignore
                      tooltipLocalizer(`${param}.caption` as LocalizedResourceDictionaryKeys)
                    }
                  </td>
                }
                {values.map((x) => {
                  return (
                    <td
                      data-test-id={`therapy-tooltip-table-${param}-value`}
                      key={`${param}-${x.therapyDate.toISOString()}`}
                      className={clsx({
                        [TherapyStyle.emptyValue]: !x.values[param] && x.values[param] !== 0,
                      })}
                    >
                      {x.values[param] || x.values[param] === 0
                        ? valueFormatter?.(x.values[param], param) ??
                          // @ts-ignore
                          tooltipLocalizer(`${param}.value` as LocalizedResourceDictionaryKeys, {
                            value: x.values[param],
                          })
                        : commonLocalizer('Common_dash')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }, [tooltipLocalizer, commonLocalizer, values, listOfParams, Icon, valueFormatter]);

  if (isNullOrEmpty) {
    return (
      <BaseIndicatorWrapper
        tooltipText={tooltipText}
        headerText={tooltipHeaderText}
        isEnabled={false}
        testId={props.testId}
      >
        {props.children}
      </BaseIndicatorWrapper>
    );
  }

  return (
    <Tooltip
      text={''}
      tooltipContent={dailyTable}
      calloutContainerStyles={Style.wrapperStyles}
      testIdPrefix={'therapy'}
    >
      <div className={Style.container} data-test-id={`indicator-${testId}`}>
        {props.children}
      </div>
    </Tooltip>
  );
};
