/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Editor, Element, Frame, useEditor } from '@craftjs/core';
import { Container } from '../controls/Containers/Container';
import { UiEditorProps } from './uiEditor';
import { TopBar } from '../toolbox/topBar.component';
import { LeftBar } from '../toolbox/leftBar.component';
import { FormDto } from '../../../services/api/api-client';
import { QueryFactory } from '../../../services/api';
import styles from './uiEditor.module.scss';
import { formBuilderControlsResolver } from '../controls/index.';
import { RightBar } from '../toolbox/rightBar.component';
import { RenderNode } from '../toolbox/renderNodes/renderNode';
import { FormFillContext } from './provider/formFill.context';
import { useContextSelector } from 'use-context-selector';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { FormFillingNavigationBar } from './formFillingNavigation/formFillingNavigationBar/FormFillingNavigationBar';

const emptyConfig = {
  ROOT: {
    type: {
      resolvedName: 'Container',
    },
    isCanvas: true,
    props: {
      formSize: 'Small',
      overviewSize: 'Medium',
      flexBoxGap: 12,
    },
    displayName: 'ROOT',
    hidden: false,
    nodes: [],
    linkedNodes: {},
  },
};

export const UiEditorComponent: FC<UiEditorProps> = React.memo(function UIEditor(props) {
  const setNodes = useContextSelector(FormFillContext, (x) => x.setNodes);

  return (
    <Editor
      resolver={formBuilderControlsResolver}
      enabled={props.isInDesignerMode}
      onRender={RenderNode}
      indicator={{
        success: '#09a07c',
        error: '#e75223',
        thickness: 4,
        transition: 'unset',
      }}
      onNodesChange={({ getSerializedNodes }) => setNodes?.(getSerializedNodes())}
    >
      <UiEditorInnerComponent {...props} />
    </Editor>
  );
});

// I needed extract the inner part of UiEditor to can use {@link useEditor}
const UiEditorInnerComponent: FC<UiEditorProps> = React.memo((props) => {
  const { actions, connectors } = useEditor();
  const study = useStudy();
  const [currentFormConfig, setCurrentFormConfig] = useState<FormDto | null>(null);
  const formConfigs = QueryFactory.FormsQuery.useGetFormsQuery(
    { studyId: study?.id },
    { enabled: props.isInDesignerMode && !!study?.id },
  );

  const onSelectFormConfig = useCallback(
    (configId: number | null) => {
      const remoteConfig = formConfigs?.data?.find((x) => x.id === configId);
      setCurrentFormConfig(remoteConfig ?? null);
    },
    [formConfigs.data],
  );

  const handleFormConfigSaved = useCallback(
    async (dto: FormDto) => {
      const newVar = await formConfigs.refetch();
      const fetchedForm = newVar?.data?.find((x) => x.id === dto.id);
      setCurrentFormConfig(fetchedForm ?? null);
    },
    [formConfigs],
  );

  const handleFormConfigDeleted = useCallback(() => {
    setCurrentFormConfig(null);
    formConfigs.refetch();
  }, [formConfigs]);

  useEffect(() => {
    actions.deserialize(JSON.stringify(currentFormConfig?.layoutSchema || props.config || emptyConfig));
    actions.history.clear();
  }, [actions, currentFormConfig, props.config]);

  if (props.isInDesignerMode && study?.id) {
    return (
      <>
        <TopBar
          studyId={study.id}
          onConfigSelect={onSelectFormConfig}
          onConfigSaved={handleFormConfigSaved}
          onConfigDeleted={handleFormConfigDeleted}
          selectedConfig={currentFormConfig}
          formConfigs={formConfigs.data ?? []}
        />
        <div id={'page-container'} className={styles.editorDesigner}>
          <LeftBar />
          <div
            data-test-id={'ui-editor-container'}
            className={styles.editorBodyDesigner}
            ref={(ref) => {
              if (!ref) return;
              connectors.select(connectors.hover(ref, ''), '');
            }}
          >
            <Frame>
              <Element id={'rootCanvas'} canvas={true} is={Container} flexBoxGap={12} />
            </Frame>
          </div>
          <RightBar />
        </div>
      </>
    );
  }

  return (
    <div id="page-container" className={styles.editorContainer}>
      <div className={styles.navBar}>
        <FormFillingNavigationBar />
      </div>
      <div
        // NOTE: The ID is used to navigate the nav panel
        id={'ui-editor-container'}
        className={styles.editor}
        data-test-id={'ui-editor-container'}
      >
        <Frame>
          <Element canvas={true} id={'rootCanvas'} is={Container} />
        </Frame>
      </div>
    </div>
  );
});
