import { RPDF } from '../../react-pdf';
import { ReportColors } from 'src/features/report/report-colors';

export function createControlStyles() {
  return {
    controlStyles: RPDF.StyleSheet.create({
      recordRow: {
        flexDirection: 'row',
        paddingVertical: 8,
        gap: 24,
        ...RPDF.tableStyles.divider,
      },
      questionCell: {
        ...RPDF.typography.heading5,
        flex: 2,
      },
      answerCell: {
        ...RPDF.typography.text8,
        flex: 1,
      },
      counter: {
        ...RPDF.typography.text8,
        fontWeight: 'semibold',
        color: ReportColors.plain,
      },
    }),
  };
}
