import React, { FC } from 'react';
import { RPDF } from '../../../react-pdf';

export const DoubleCrossIcon: FC = () => {
  return (
    <RPDF.Svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        d="M8.81316 2.18298C9.05728 2.42702 9.05734 2.82275 8.81331 3.06687L5.88188 5.9993L8.81331 8.93174C9.05735 9.17586 9.05728 9.57159 8.81316 9.81563C8.56904 10.0597 8.17331 10.0596 7.92927 9.81547L4.99815 6.88334L2.06702 9.81547C1.82298 10.0596 1.42725 10.0597 1.18313 9.81563C0.939015 9.57159 0.938947 9.17586 1.18298 8.93174L4.11441 5.9993L1.18298 3.06687C0.938948 2.82275 0.939016 2.42702 1.18314 2.18298C1.42726 1.93895 1.82298 1.93901 2.06702 2.18313L4.99815 5.11527L7.92927 2.18313C8.17331 1.93901 8.56904 1.93895 8.81316 2.18298ZM11.8133 3.06687C12.0573 2.82275 12.0573 2.42702 11.8131 2.18298C11.569 1.93895 11.1733 1.93901 10.9293 2.18313L7.55612 5.55744L7.11433 5.99938L7.5562 6.44125L10.9293 9.81439C11.1734 10.0585 11.5691 10.0585 11.8132 9.81439C12.0573 9.57031 12.0573 9.17458 11.8132 8.93051L8.88195 5.99923L11.8133 3.06687Z"
        fill="#667878"
      />
    </RPDF.Svg>
  );
};
