﻿import React, { forwardRef } from 'react';
import {
  AppInputError,
  AppInputErrorProps,
  AppInputSkip,
  ErrorSign,
  FieldSkipProps,
} from 'uikit/wrappers';
import { AppInputLabel, AppInputLabelProps } from 'uikit/wrappers';
import { AppAutoCompleteInput, AppAutoCompleteInputProps } from 'uikit/inputs';

export type AppAutoCompleteFieldProps = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipProps?: FieldSkipProps;
  maxLength?: number;
  units?: string;
} & AppAutoCompleteInputProps;

export const AppAutoCompleteField = forwardRef<HTMLInputElement, AppAutoCompleteFieldProps>(
  ({ errorProps, disabled, skipProps, labelProps, ...props }, ref) => {
    return (
      <AppInputError {...errorProps}>
        <AppInputLabel
          disabled={disabled}
          rightSection={errorProps?.errors ? <ErrorSign /> : <></>}
          {...labelProps}
        >
          <AppInputSkip
            disabled={disabled}
            skipReason={skipProps?.skipReason}
            showReasons={skipProps?.showReasons}
            onSkipReasonChange={skipProps?.onSkipReasonChange ?? (() => {})}
            inputCanBeSkipped={!!skipProps && skipProps?.inputCanBeSkipped}
            skipText={skipProps?.skipText}
          >
            <AppAutoCompleteInput
              ref={ref}
              disabled={disabled}
              invalid={!!errorProps?.errors}
              {...props}
            />
          </AppInputSkip>
        </AppInputLabel>
      </AppInputError>
    );
  },
);
