import {
  IFormControlsResolver,
  StubContainer,
  StubContent,
  StubFieldGroupContainer,
} from 'src/features/forms/base/controls/IFormControlsResolver';
import React from 'react';
import { DevError } from '../../../../../helpers/components/DevError/DevError.component';
import { TextInput } from './TextInput';
import { DateInput } from './DateInput';
import { TimeInput } from './TimeInput';
import { DateRangeInput } from './DateRangeInput';
import { FieldWithOptions } from './FieldWithOptions';
import { CheckBoxInput } from './CheckBoxInput';
import { TagInput } from './TagInput';
import { Files } from './Files';
import { NumberInput } from './NumberInput';

export const noteBodyFormControlsResolver: IFormControlsResolver = {
  EmptyBlock: StubContent,
  Container: StubContainer,
  FieldGroupContainer: StubFieldGroupContainer,
  Text: StubContent,
  AttentionText: StubContent,
  Title: StubContent,
  TextInput: TextInput,
  NumberInput: NumberInput,
  DateInput: DateInput,
  TimeInput: TimeInput,
  CheckBoxInput: CheckBoxInput,
  CheckBoxGroupInput: FieldWithOptions,
  RadioInput: FieldWithOptions,
  SelectInput: FieldWithOptions,
  Files: Files,
  StepContainer: StubContainer,
  TabsContainer: StubContainer,
  DateRangeInput: DateRangeInput,
  BmiInput: () => <DevError messages={['Control "BmiInput" is not implemented']} />,
  CalculatedBlock: () => <DevError messages={['Control "CalculatedBlock" is not implemented']} />,
  GroupInput: () => <DevError messages={['Control "GroupInput" is not implemented']} />,
  SliderInput: () => <DevError messages={['Control "SliderInput" is not implemented']} />,
  ImageBlock: StubContent,
  TagInput: TagInput,
  RecordResultList: () => <DevError messages={['Control "RecordResultList" is not implemented']} />,
};
