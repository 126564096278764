import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';
import { downloadJsonFile } from 'src/helpers/file-helper';
import { QuestionScheme } from 'src/services/api/api-client';
import { useContextSelector } from 'use-context-selector';
import { QuestionSchemeEditorContext } from '../../container/questionSchemeUIEditor.context';
import { isIQuestionScheme } from '../../helpers/questionScheme-helper';
import Style from './topToolBox.module.css';
import { AppCheckboxInput } from '../../../uikit/inputs/checkbox/appCheckboxInput.component';
import { StudyNameComponent } from 'src/components/studyName/studyName.component';

export const TopToolBox = () => {
  const [isStringify, setIsStringify] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement | null>(null);
  const questionScheme = useContextSelector(QuestionSchemeEditorContext, (x) => x.questionScheme);
  const setQuestionScheme = useContextSelector(QuestionSchemeEditorContext, (x) => x.setQuestionScheme)!;

  const loadFromFileHandler = useCallback(() => {
    if (ref && ref.current) {
      ref.current.click();
    }
  }, []);

  const clearInputValue = useCallback((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  }, []);

  const fileUploadHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      if (!file) {
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsText(file, 'UTF-8');
      fileReader.onload = (x) => {
        const schema = new QuestionScheme();
        schema.init(JSON.parse(x.target?.result as string));

        if (isIQuestionScheme(schema)) {
          setQuestionScheme(schema);
          return;
        }

        alert('Wrong json scheme!');
      };
    },
    [setQuestionScheme],
  );

  const schemeStr = useMemo(() => {
    if (!questionScheme) {
      return null;
    }

    const str = JSON.stringify(questionScheme);
    if (isStringify) {
      return JSON.stringify(str);
    }

    return str;
  }, [questionScheme, isStringify]);

  const saveFileHandler = useCallback(() => {
    if (!schemeStr) {
      alert('Scheme is empty');
      return;
    }

    downloadJsonFile(schemeStr);
  }, [schemeStr]);

  const copyToClipboardHandler = useCallback(() => {
    if (!schemeStr) {
      alert('Scheme is empty');
      return;
    }
    navigator.clipboard.writeText(schemeStr);
  }, [schemeStr]);

  return (
    <div className={Style.container}>
      <div className={Style.loadSection}>
        <StudyNameComponent />
        <AppButton variant={'button'} colorSchema={'primary'} text="Load from file" onClick={loadFromFileHandler} />
        <input
          style={{ display: 'none' }}
          accept={'.json'}
          ref={ref}
          onChange={fileUploadHandler}
          onClick={clearInputValue}
          type={'file'}
        />
      </div>

      <div className={Style.saveSection}>
        <AppButton variant={'button'} colorSchema={'secondary'} text="Save to file" onClick={saveFileHandler} />
        <AppButton
          variant={'icon-link'}
          colorSchema={'secondary'}
          text="Copy to clipboard"
          textClassName={Style.buttonText}
          onClick={copyToClipboardHandler}
        />
        <AppCheckboxInput
          label={'Stringify'}
          checked={isStringify}
          onChange={(e) => setIsStringify(e.target.checked)}
        />
      </div>
    </div>
  );
};
