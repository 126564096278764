import { useContextSelector } from 'use-context-selector';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import { PropType } from 'src/helpers/typeUtils';
import { ValidateCustomForm } from 'src/helpers/validation-helpers';
import { CommentDataKey, FormFillContext } from '../../../uiEditor/provider/formFill.context';
import { useNode } from '@craftjs/core';
import { FieldSkipReason } from 'src/services/api/api-client';
import { LocalizedResourceDictionaryKeys } from 'src/application/localisation/i18next';
import i18next from 'i18next';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { IDateInputProps } from 'src/features/forms/base/controls/inputs/DateInput';
import { convertDateToString } from 'src/helpers/date-helpers';

export function useFieldDataFromUiEditorContext(dataKey: string) {
  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const isEditMode = useContextSelector(FormFillContext, (v) => v.mode === 'Edit');
  const inputValue = useContextSelector(FormFillContext, (v) => v.values?.[dataKey]);
  const isAnswered =
    inputValue && ((Array.isArray(inputValue) && inputValue.length > 0) || typeof inputValue === 'object');
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;
  const multipleInputValue = useMemo(() => {
    return Array.isArray(inputValue) ? inputValue : inputValue !== undefined ? [inputValue] : undefined;
  }, [inputValue]);
  const commentValue = useContextSelector(FormFillContext, (v) => {
    const value = v.values?.[CommentDataKey(dataKey)];
    return Array.isArray(value) ? value[0] : value;
  });
  const setDataBlockFieldValue = useContextSelector(FormFillContext, (v) => v.setValue);
  const removeFieldValue = useContextSelector(FormFillContext, (v) => v.removeValue);

  const skipReason = useContextSelector(FormFillContext, (v) => v.skippedFieldsData?.[dataKey]);
  const markFieldAsSkipped = useContextSelector(FormFillContext, (v) => v.markFieldAsSkipped);
  const unmarkSkippedField = useContextSelector(FormFillContext, (v) => v.unmarkSkippedField);
  const onSkipReasonChange = useCallback(
    (dataKey: string) => (skipReason: FieldSkipReason | undefined) => {
      skipReason === undefined ? unmarkSkippedField?.(dataKey) : markFieldAsSkipped?.(dataKey, skipReason);
    },
    [],
  );

  const fieldEditReason = useContextSelector(FormFillContext, (v) => v.fieldEditReasons?.[dataKey]);
  const commentFieldEditReason = useContextSelector(
    FormFillContext,
    (v) => v.fieldEditReasons?.[CommentDataKey(dataKey)],
  );

  return {
    isOnlyView,
    isEditMode,
    isAnswered,
    inputValue,
    singleInputValue,
    multipleInputValue,
    commentValue,
    setDataBlockFieldValue,
    removeFieldValue,
    skipReason,
    onSkipReasonChange,
    fieldEditReason,
    commentFieldEditReason,
  };
}

export function useDefaultValuesForConstructorMode(
  props: IFormFieldProps & Pick<IDateInputProps, 'defaultValueIsNow'>,
) {
  return {
    defaultValue: props.defaultValueIsNow ? convertDateToString(new Date()) : props.defaultValue,
    singleDefaultValue: Array.isArray(props.defaultValue) ? props.defaultValue[0] : props.defaultValue,
    multipleDefaultValue: useMemo(() => {
      return Array.isArray(props.defaultValue)
        ? props.defaultValue
        : props.defaultValue !== undefined
        ? [props.defaultValue]
        : undefined;
    }, [props.defaultValue]),
  };
}

export function useRefForDataBlockNavigation() {
  const { id } = useNode();
  const blockRef = useRef(null);
  const addRefToDataBlockNavigation = useContextSelector(FormFillContext, (v) => v.addRefToDataBlockNavigation);

  useEffect(() => {
    addRefToDataBlockNavigation?.(id, blockRef);
  }, [addRefToDataBlockNavigation, id, blockRef]);

  return blockRef;
}

export function useAddFieldToValidation(nodeId: string) {
  const setDataBlockValidation = useContextSelector(FormFillContext, (v) => v.setDataBlockValidation);
  const removeDataBlockValidation = useContextSelector(FormFillContext, (v) => v.removeDataBlockValidation);

  useEffect(() => {
    setDataBlockValidation?.({ [nodeId]: { validationState: 'Ok', text: '' } });
    return () => removeDataBlockValidation?.(nodeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodeId]);
}

export const useFieldValidation = ({
  isEditable,
  isDisabled,
  rules,
  fieldPostfix = '',
}: {
  isEditable: boolean | undefined;
  isDisabled: boolean | undefined;
  rules: PropType<IFormFieldProps, 'validation'>;
  fieldPostfix?: string;
  customDataKey?: string;
}) => {
  const study = useStudy();
  const { key, id } = useNode((node) => ({
    key: node.data.props.dataKey,
  }));

  useAddFieldToValidation(id + fieldPostfix);

  const dataBlockValidation = useContextSelector(FormFillContext, (v) => v.dataBlockValidation?.[id + fieldPostfix]);
  const setDataBlockValidation = useContextSelector(FormFillContext, (v) => v.setDataBlockValidation);
  const inputValue = useContextSelector(FormFillContext, (v) => v.values?.[key + fieldPostfix]);
  const skipReason = useContextSelector(FormFillContext, (v) => v.skippedFieldsData?.[key + fieldPostfix]);

  const triggerFieldValidation = useCallback(() => {
    const validationResult = ValidateCustomForm({
      validationSettings: rules,
      value: inputValue,
      skipReason: skipReason,
      options: {
        disable: !isEditable || isDisabled,
        ignoreIsRequiredRule: true,
        eCRF: study?.hasEcrf,
      },
    });

    setDataBlockValidation?.({
      [id + fieldPostfix]: {
        validationState: validationResult.length > 0 ? 'Fail' : 'Ok',
        text: validationResult.join('\n'),
      },
    });
  }, [inputValue, skipReason]);

  const resetFieldValidation = useCallback(() => {
    setDataBlockValidation?.({
      [id + fieldPostfix]: { validationState: 'Ok', text: '' },
    });
  }, [id, fieldPostfix]);

  // This effect reset validation for disabled or not editable fields
  useEffect(() => {
    if (!isEditable || isDisabled) resetFieldValidation();
  }, [resetFieldValidation, isDisabled, isEditable]);

  // This effect reset validation when the value changes
  useEffect(() => {
    resetFieldValidation();
  }, [inputValue, skipReason]);

  return { dataBlockValidation, setDataBlockValidation, triggerFieldValidation };
};

export const useSkipReasonText = (skipReason: FieldSkipReason | undefined, skipCheckBoxText?: string) =>
  useMemo(() => {
    if (skipReason === undefined) return undefined;

    if (skipReason === FieldSkipReason.NoReason) {
      return skipCheckBoxText || i18next.t('Forms.Controls.SkipQuestionCheckBox');
    }

    return i18next.t(
      `Forms.Controls.SkipQuestionReason.${FieldSkipReason[skipReason!]}` as LocalizedResourceDictionaryKeys,
    );
  }, [skipReason, skipCheckBoxText]);
