import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from 'src/application/localisation/locales';
import Style from './languageSwitcher.module.css';
import { changeLanguage } from '../../application/localisation/localization';
import { trackEvent } from '../../application/analitics/matomo/matomo-tracker';
import { MetricCategories } from '../../application/analitics/matomo/matomo-categories';
import { MetricActions } from '../../application/analitics/matomo/matomo-actions';
import { DropDownMenu } from '../uikit/dropDownMenu/dropDownMenu.component';
import { DropDownMenuOption } from '../uikit/dropDownMenu/dropDownMenu';
import { LocalizedResourceDictionaryKeys } from '../../application/localisation/i18next';
import clsx from 'clsx';

import deLangIcon from './../../assets/img/language/de.svg';
import enLangIcon from './../../assets/img/language/en.svg';
// import frLangIcon from './../../assets/img/language/fr.svg';

const resources: { [key in Language]: any } = {
  de: deLangIcon,
  en: enLangIcon,
  // fr: frLangIcon,
};

export const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);

  const onClick = useCallback(async (lang: string) => {
    await changeLanguage(lang);
    trackEvent({
      category: MetricCategories.Application,
      action: MetricActions.LanguageChanged,
      name: lang,
    });
  }, []);

  const renderCountryIcon = useCallback((language: string) => {
    return (
      <img
        alt={`language icon-${language}`}
        src={resources[language]}
        data-test-id={'language-item'}
        data-resource-id={language}
      />
    );
  }, []);

  const CurrentIcon = useMemo(() => {
    return () => renderCountryIcon(i18n.language);
  }, [renderCountryIcon, i18n.language]);

  const options = useMemo(() => {
    return Object.keys(resources).map((language) => {
      return {
        key: language,
        icon: renderCountryIcon(language),
        text: t(`Languages.${language}` as LocalizedResourceDictionaryKeys),
        action: () => onClick(language),
        className: Style.item,
      } as DropDownMenuOption;
    });
  }, [renderCountryIcon, onClick, t]);

  return (
    <DropDownMenu
      colorSchema={'transparent'}
      Icon={CurrentIcon}
      iconClassName={Style.itemIcon}
      options={options}
      hasChevron={true}
      testId={'language-switcher'}
      data-resource-id={i18n.language}
      className={clsx(Style.container, { [Style.containerExpanded]: expanded })}
      controlClassName={Style.control}
      onExpendChange={setExpanded}
    />
  );
};
