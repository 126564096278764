﻿import React, { FC, useCallback, useState } from 'react';
import { CardSectionsEnum, IGroupDto, TableColumnsEnum } from '../../../../services/api/api-client';
import { useModal } from '../../../../application/hooks/useModal';
import Style from '../studyList.module.css';
import { ExpandedRowHeader } from '../../../../components/expandedRow/header/expandedRowHeader.component';
import { AppButton } from 'uikit/buttons';
import { getAllPresentedFlags } from '../../../../helpers/enum-helper';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { EditStudyGroupsModalContent } from '../EditStudyGroupsModalContent';
import { ReactComponent as EditIcon } from 'src/assets/img/common/edit_20.svg';
import { ReactComponent as AddIcon } from 'src/assets/img/common/add.svg';
import { useGroupCreating } from '../../../../features/study/useGroupCreating';
import { useStudyRouteCreating } from 'src/features/study/useStudyRouteCreating';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { removeGroup } from 'src/services/api/api-client/StudyClient';
import { getStudyListQueryKey } from 'src/services/api/api-client/StudyQuery';
import { useQueryClient } from '@tanstack/react-query';
import { logger } from 'src/application/logging/logging';
import ExpandedRowSections from 'uikit/table/ExpandedRowSections';
import PropertiesList from 'uikit/propertiesList/PropertiesList';
import { BulletPoints } from 'uikit/BulletPoints';

export const GroupsSection: FC<{ studyId: number; groups: IGroupDto[] }> = (props) => {
  const { t } = useScopedTranslation('Admin.StudyList.Groups', 'dev');
  const queryClient = useQueryClient();
  const groupsModalManager = useModal();

  const groupCreating = useGroupCreating();
  const studyRouteCreating = useStudyRouteCreating();

  const [isGroupRemoving, setIsGroupRemoving] = useState<boolean>();

  const removeGroupHandler = useCallback(
    async (groupId: number) => {
      try {
        setIsGroupRemoving(true);
        await removeGroup(groupId);
        await queryClient.invalidateQueries(getStudyListQueryKey());
      } catch (e: any) {
        logger().error('Remove study group is failed', e);
      } finally {
        setIsGroupRemoving(false);
      }
    },
    [queryClient],
  );

  return (
    <ExpandedRowSections.Section>
      <ExpandedRowHeader
        text={t('Caption')}
        rightHeader={
          <div className={Style.groupSectionButtons}>
            <AppButton
              onClick={() => groupCreating.start(props.studyId)}
              variant={'icon-link'}
              colorSchema={'primary'}
              text={t('Add')}
              Icon={AddIcon}
            />
            <AppButton
              onClick={() => groupsModalManager.openModal()}
              variant={'icon-link'}
              colorSchema={'primary'}
              text={t('Edit')}
              Icon={EditIcon}
            />
          </div>
        }
      />

      {props.groups?.map((x, i, array) => (
        <React.Fragment key={x.title}>
          <PropertiesList>
            <PropertiesList.Property
              name={t('Id')}
              valueClassName={Style.groupIdContainer}
              value={
                <>
                  <div>{x.id}</div>
                  <AppButton
                    colorSchema={'decline'}
                    variant={'icon-link'}
                    text={t('RemoveGroup')}
                    onClick={() => removeGroupHandler(x.id)}
                    isLoading={isGroupRemoving}
                    disabled={isGroupRemoving}
                  />
                </>
              }
            />
            <PropertiesList.Property name={t('Title')} value={x.title} />
            <PropertiesList.Property
              name={t('TableColumns')}
              value={<BulletPoints values={getAllPresentedFlags(x.tableColumns).map((f) => TableColumnsEnum[f])} />}
            />
            <PropertiesList.Property name={t('SurveysColumns')} value={<BulletPoints values={x.surveyColumns} />} />
            <PropertiesList.Property
              name={t('PatientCard')}
              value={
                <BulletPoints
                  trim={false}
                  values={x.patientCardSections.map(
                    (cardColumn, i) =>
                      `Card column #${i + 1}: ${cardColumn.map((card) => CardSectionsEnum[card]).join(', ')}`,
                  )}
                />
              }
            />
            <PropertiesList.Property name={t('SurveysCards')} value={x.surveysToPatientCard.join(', ')} />
            <PropertiesList.Property
              name={t('StudyRoute')}
              value={
                x.studyRouteId ? (
                  <AppButton
                    colorSchema={'primary'}
                    variant={'icon-link'}
                    text={t('EditStudyRoute')}
                    onClick={() => studyRouteCreating.start(props.studyId, x.id, x.studyRouteId)}
                  />
                ) : (
                  <AppButton
                    colorSchema={'primary'}
                    variant={'icon-link'}
                    text={t('AddStudyRoute')}
                    onClick={() => studyRouteCreating.start(props.studyId, x.id)}
                  />
                )
              }
            />
          </PropertiesList>
          {i + 1 < array.length && <hr className={Style.dataBreak} />}
        </React.Fragment>
      ))}

      <AppModalContainer
        visible={groupsModalManager.visible}
        onHide={groupsModalManager.closeModal}
        title={t('Modal.Header')}
      >
        <EditStudyGroupsModalContent groups={props.groups} onClose={groupsModalManager.closeModal} />
      </AppModalContainer>
      {groupCreating.element}
      {studyRouteCreating.element}
    </ExpandedRowSections.Section>
  );
};
