﻿import React, { FC } from 'react';
import { RPDF } from '../react-pdf';
import i18n from 'i18next';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { EcrfReportOptions } from './ecrfReport.Options';
import { MissedRecordsPage, RecordPage } from '../records/recordsReport.layout';
import { RecordsReportContext } from '../records/recordsReport.context';
import { IssueTablePage } from '../issues/issueReport.layout';

export const EcrfReportLayout: FC<EcrfReportOptions> = (props) => {
  const { t } = useScopedTranslation('Reports.EcrfReport');

  return (
    <RPDF.Document
      language={i18n.language}
      producer={'LM Study'}
      title={t('DocumentTitle')}
      author={props.authorFullName}
      subject={props.studyNumber}
    >
      <RecordsReportContext.Provider
        value={{
          patientId: props.patientId,
          studyHasEcrf: props.studyHasEcrf,
          studyGroups: props.studyGroups,
          mandatoryForms: props.mandatoryForms,
          multipleForms: props.multipleForms,
        }}
      >
        {props.filledRecords.map((filledRecord) => (
          <RecordPage
            key={filledRecord.resultDto.id}
            title={t('DocumentTitle')}
            patientUniqueId={props.patientUniqueId}
            formResult={filledRecord.resultDto}
            isSingleTypeForms={false}
            formConfig={filledRecord.form}
            stepName={filledRecord.stepName}
          />
        ))}
        {props.missedRecords.length > 0 && (
          <MissedRecordsPage
            missedRecords={props.missedRecords}
            headerText={t('DocumentTitle')}
            patientUniqueId={props.patientUniqueId}
          />
        )}
        {props.issues.length > 0 && (
          <IssueTablePage
            title={t('DocumentTitle')}
            data={props.issues}
            filters={undefined}
            studyGroups={props.studyGroups}
          />
        )}
      </RecordsReportContext.Provider>
    </RPDF.Document>
  );
};
