import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { DeepPartial } from 'react-hook-form/dist/types/utils';
import { NavigateFunction, useNavigate } from 'react-router';
import { pascalToCamelCase } from 'src/helpers/error-helpers';

export const NetworkError = 'Network Error';

export type UseSendFormReturn<T> = {
  /*
  Function to be passed to form.handleSubmit
   */
  handler: (data: T) => Promise<void>;
  /*
  All server-side errors combined
   */
  serverErrorsCombined: string;
};

export function handleSubmitFormError<T extends FieldValues>(
  error: any,
  setError: UseFormSetError<T>,
): {
  // error not related to any field
  overallServerError: string;
  // field-related and overall error in one string
  formErrorsCombined: string;
} {
  // error could be:
  // - strongly-typed error (if server-side action is decorated with
  // [ProducesResponseType(400, Type = typeof(ValidationProblemDetails))]
  // - untyped error, in which case `error.response` will be populated with response in JSON.
  const errorResponseData = error.response?.data || error.response || error;
  const errors = errorResponseData?.errors;
  let overallError = convertToErrorStringInternal(error);

  if (overallError === 'One or more validation errors occurred.') {
    // it doesn't make sense to display this error
    overallError = '';
  }

  if (overallError) {
    setError('root', {
      message: overallError,
    });
  }

  if (errors && Object.keys(errors).length) {
    let formErrorsCombined = '';
    // Some field-bound error (e.g. `user with same Name already exists in DB`)
    Object.keys(errors).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const camelCaseName = pascalToCamelCase(key);
      const errorValue = errors[key];
      const errorString = Array.isArray(errorValue) ? errorValue.join('; ') : errorValue;
      setError(camelCaseName as any, {
        message: errorString,
        type: 'validate',
      });
      if (key.includes('.')) {
        key = key.substring(key.lastIndexOf('.') + 1);
      }
      formErrorsCombined = `${formErrorsCombined}${key}: ${errorString}\n`;
    });

    if (overallError) {
      formErrorsCombined = `${overallError}\n${formErrorsCombined}`;
    }

    return {
      formErrorsCombined: formErrorsCombined,
      overallServerError: overallError,
    };
  }

  return {
    overallServerError: overallError,
    formErrorsCombined: overallError,
  };
}

/** Helper hook for handling form submit.
 *
 * @example
 * const submitForm = useErrorHandler(useCallback(async (data: ICreateSubtenantDto) => {
 *     await ClientFactory.TenantClient.createSubtenant(new CreateSubtenantDto(data));
 *     history.push(sharedRoutes.Subtenants.root);
 * }, []), setError);
 *
 *
 * @example
 * // You could create a submitFormFunction
 * // completely outside of the component (to get rid of useCallback):
 * async function createTenant (data: ICreateSubtenantDto, history: H.History) {
 *   await ClientFactory.TenantClient.createSubtenant(new CreateSubtenantDto(data));
 *   history.push(sharedRoutes.Subtenants.root);
 * }
 */
export function useErrorHandler<TFieldValues extends FieldValues = FieldValues>(
  submitFunction: (data: TFieldValues, navigate: NavigateFunction) => Promise<void>,
  setError: UseFormSetError<TFieldValues>,
  reset?: (values?: DeepPartial<TFieldValues>) => void,
): UseSendFormReturn<TFieldValues> {
  const [formErrorsCombined, setFormErrorsCombined] = useState('');
  const navigate = useNavigate();

  async function submitForm(data: TFieldValues) {
    try {
      await submitFunction(data, navigate);
      reset?.(undefined);
    } catch (error) {
      const errorDetails = handleSubmitFormError(error, setError);
      if (errorDetails.formErrorsCombined) {
        setFormErrorsCombined(errorDetails.formErrorsCombined);
      }
    }
  }

  return {
    handler: submitForm,
    serverErrorsCombined: formErrorsCombined,
  };
}

/**
 * Converts exception object to readable string.
 * Handles ASP.NET Core validation errors (ProblemDetails), and other backend errors.
 * @returns
 * 'Network Error' in case of network errors.
 *
 * 'Unauthorized' in case of 401
 *
 * 'Access Denied' in case of 403.
 */
// (errors are mentioned here for localization purposes)
export function convertToErrorString(error: any): string {
  // error could be:
  // - strongly-typed error (if server-side action is decorated with
  // [ProducesResponseType(400, Type = typeof(ValidationProblemDetails))]
  // - untyped error, in which case `error.response` will be populated with response in JSON.
  const errorResponseData = error.response?.data || error.response || error;
  const errors = errorResponseData?.errors;

  let overallError = convertToErrorStringInternal(error);

  if (errors && Object.keys(errors).length) {
    let formErrorsCombined = '';
    // Some field-bound error (e.g. `user with same Name already exists in DB`)
    Object.keys(errors).forEach((key) => {
      const camelCaseName = key.charAt(0).toLowerCase() + key.slice(1);
      const errorValue = errors[key];
      const errorString = Array.isArray(errorValue) ? errorValue.join('; ') : errorValue;
      if (key.includes('.')) {
        key = key.substring(key.lastIndexOf('.') + 1);
      }
      formErrorsCombined = `${formErrorsCombined}${camelCaseName}: ${errorString}\n`;
    });

    if (overallError === 'One or more validation errors occurred.') {
      // it doesn't make sense to display this error
      overallError = '';
    }

    if (overallError) {
      overallError = `${overallError}\n${formErrorsCombined}`;
    } else {
      overallError = formErrorsCombined;
    }
  }
  return overallError;
}

function convertToErrorStringInternal(error: any): string {
  const errorResponseData = error.response?.data || error;
  const responseDetail = errorResponseData?.detail;
  if (error.status === 401) {
    return 'Unauthorized';
  }
  if (error.status === 403) {
    return 'Access Denied';
  }
  if (responseDetail) {
    // General server-side error not related to certain field (e.g. `Access Denied`)
    return responseDetail;
  }

  if (error.code === 'CSS_CHUNK_LOAD_FAILED') {
    return NetworkError;
  }
  if (error.name === 'ChunkLoadError') {
    return NetworkError;
  }
  if (error.message?.includes('fetch dynamically imported module')) {
    return NetworkError;
  }
  if (error.message?.includes("Cannot read property 'status' of undefined")) {
    // nswag generated client throws it when there's no response
    return NetworkError;
  }
  if (error.message) {
    // e.g. Network Error
    console.log('Error:', error, JSON.stringify(error));
    return error.message;
  } else if (error.title) {
    return error.title;
  } else if (error instanceof String || typeof error === 'string') {
    return error as string;
  }
  console.log('Unknown Error:', error, JSON.stringify(error));
  return error.toString();
}

export function emptyErrorFunction() {
  /*
  Shall be used when errors are handled somewhere else (e.g. inside Loadings)
   */
}
