import clsx from 'clsx';
import React from 'react';
import { ReactComponent as WarningIcon } from './../../assets/img/patient/indicators/warning_25.svg';
import Style from './baseIndicator.module.css';

export const WarningSignIndicator = (props: { isNotCritical?: boolean }) => {
  return (
    <WarningIcon
      className={clsx({ [Style.warning]: true, [Style.base]: true, [Style.notCriticalWarning]: props.isNotCritical })}
      data-test-id={'warning-sign-icon'}
    />
  );
};
