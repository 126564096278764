import React, { FC } from 'react';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { IIssueHistoryActionDto, IssueHistoryActionTypeEnum } from '../../../services/api/api-client';
import Style from './issueHistory.module.scss';

import { ReactComponent as Opened } from '../../../assets/img/issues/opened_16.svg';
import { ReactComponent as Processed } from '../../../assets/img/issues/pre_close_16.svg';
import { ReactComponent as Rejected } from '../../../assets/img/common/cross_16.svg';
import { ReactComponent as Closed } from '../../../assets/img/issues/closed_16.svg';

const icon = {
  [IssueHistoryActionTypeEnum.Opened]: <Opened className={Style.icon} />,
  [IssueHistoryActionTypeEnum.Processed]: <Processed className={Style.icon} />,
  [IssueHistoryActionTypeEnum.Rejected]: <Rejected className={Style.icon} />,
  [IssueHistoryActionTypeEnum.Closed]: <Closed className={Style.icon} />,
};

export const IssueHistory: FC<IIssueHistoryActionDto> = (action) => {
  return (
    <div className={Style.container} data-test-id={'history-item'}>
      <div className={Style.header}>
        <div className={Style.userName} data-test-id={'history-item-username'}>
          {action.takenByUser}
        </div>
        <div className={Style.actionDate} data-test-id={'history-item-date'}>
          {localFormat(action.takenAt, DateFormats.dateTime)}
        </div>
      </div>
      <div className={Style.body}>
        {icon[action.type]}
        <div className={Style.text} data-test-id={'history-item-description'}>
          {action.description}
        </div>
      </div>
    </div>
  );
};
