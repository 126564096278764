import React from 'react';
import { useSkipReasonText } from 'src/components/formEditor/controls/Inputs/base/hooks';
import { useContextSelector } from 'use-context-selector';
import { formatDateRange, toDateOrNull } from '../../../../../helpers/date-helpers';
import { IDateRangeInput } from '../../../../forms/base/controls/inputs/DateRangeInput';
import { Field } from '../../components/Field';
import { RecordContext } from '../../RecordContext';

export const DateRangeInput: IDateRangeInput = ({ label, dataKey, skipCheckBoxText }) => {
  const inputValueStartDate = useContextSelector(
    RecordContext,
    (v) => v.formResult?.[`${dataKey}_from`] as string | undefined,
  );
  const inputValueEndDate = useContextSelector(
    RecordContext,
    (v) => v.formResult?.[`${dataKey}_until`] as string | undefined,
  );

  const skipReason = useContextSelector(RecordContext, (v) => v.skipReasons?.[`${dataKey}_from`]);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  return (
    <Field
      label={label ?? dataKey}
      skipReason={skipReasonText}
      value={formatDateRange(toDateOrNull(inputValueStartDate), toDateOrNull(inputValueEndDate))}
    />
  );
};
