import _ from 'lodash';
import { useMemo } from 'react';
import { useGetPatientFormList } from 'src/components/formEditor/controls/ConstantBlocks/RecordResultList/useGetFormList';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { FormLifecycleState } from 'src/services/api/api-client';
import { useGetFormResultsQuery, useGetFormByIdQuery } from 'src/services/api/api-client/FormsQuery';

export const useFinishingPatientFormState = (patientId: string) => {
  const study = useStudy();

  const {
    isLoading: gettingPatientFormList,
    mandatoryForms,
    multipleForms,
  } = useGetPatientFormList(patientId, study?.patientFinishingFormId);

  const { data: finishingPatientForm, isInitialLoading: isFormResultLoading } = useGetFormResultsQuery(
    { formConfigId: study?.patientFinishingFormId, patientId: patientId },
    { suspense: false },
  );

  const { data: finishingPatientFormConfig, isInitialLoading: isFormConfigLoading } = useGetFormByIdQuery(
    { id: study?.patientFinishingFormId || 0 },
    { suspense: false, enabled: !!study?.patientFinishingFormId },
  );

  const finishingPatientFormState = useMemo(
    () => _.last(finishingPatientForm?.data?.[0]?.versions)?.lifecycleState,
    [finishingPatientForm?.data],
  );

  const notApprovedFormList = useMemo(
    () =>
      [...mandatoryForms, ...multipleForms]
        .flatMap((x) =>
          x.formResults.map((r) => ({
            ...r,
            formType: x.formType,
            lifecycleState: _.last(r.versions)?.lifecycleState,
          })),
        )
        .filter((x) => x.lifecycleState !== FormLifecycleState.Approved),
    [mandatoryForms, multipleForms],
  );

  return {
    isLoading: isFormResultLoading || isFormConfigLoading || gettingPatientFormList,
    type: finishingPatientFormConfig?.type,
    state: finishingPatientFormState,
    notApprovedFormList: notApprovedFormList,
    canBeFilled: !notApprovedFormList.length,
  };
};
