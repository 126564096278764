import React, { useMemo, useState, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseModalProps } from '../../../application/hooks/useModal';
import { logger } from '../../../application/logging/logging';
import { pascalToCamelCase } from '../../../helpers/error-helpers';
import { ValidationFormRules, ValidationConstants } from '../../../helpers/validation-helpers';
import Style from './rejectForm.module.css';
import { AppTextField } from '../../uikit/fields/text/appTextField.component';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

export type IssueRejectFormParams = {
  issueId: number;
  callback: (data: UseFormRejectIssueType) => Promise<void>;
};

export type UseFormRejectIssueType = {
  comment: string;
};

export type RejectIssueFormType = {
  modal: UseModalProps<IssueRejectFormParams>;
};

export const IssueRejectForm: FC<RejectIssueFormType> = ({ modal }) => {
  const { t } = useTranslation();
  const [internalError, setInternalError] = useState(false);

  const { issueId, callback } = modal.params;

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
  } = useForm<UseFormRejectIssueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      comment: '',
    },
  });

  const onSubmit = handleSubmit(async (data: UseFormRejectIssueType) => {
    setInternalError(false);
    try {
      await callback(data);
      modal.closeModal();
    } catch (ex: any) {
      if (ex.errors && Object.keys(ex.errors).length > 0) {
        for (const errorKey of Object.keys(ex.errors)) {
          const formKey = pascalToCamelCase(errorKey) as keyof UseFormRejectIssueType;
          setError(formKey, {
            type: 'server',
            message: ex.errors[errorKey][0],
          });
        }
      } else {
        logger().error(ex);
        setInternalError(true);
      }
    }
  });

  const content = useMemo(() => {
    return (
      <form id={'rejectIssueForm'} onSubmit={onSubmit} autoComplete={'off'}>
        <Controller
          control={control}
          name={'comment'}
          rules={{
            ...ValidationFormRules().maxLengthRule,
            ...ValidationFormRules().requiredRule,
          }}
          render={({ field: { onChange, value } }) => (
            <AppTextField
              maxLength={ValidationConstants.textInputMaxLength}
              type={'text-area'}
              labelProps={{ text: t('Issues.RejectModal.CommentLabel') }}
              placeholder={t('Issues.RejectModal.CommentPlaceholder')}
              value={value}
              onChange={onChange}
              disabled={isSubmitting}
              errorProps={{ errors: errors?.comment?.message }}
            />
          )}
        />
      </form>
    );
  }, [control, errors?.comment?.message, isSubmitting, onSubmit, t]);

  return (
    <DialogModal
      bodyClassName={Style.modalBody}
      title={t('Issues.RejectModal.Title', { id: issueId })}
      visible={modal.visible}
      onHide={modal.closeModal}
      footer={{
        errors: internalError ? t('Issues.GeneralError') : undefined,
        leftButton: {
          text: t('Common_Cancel'),
          onClick: modal.closeModal,
          disabled: isSubmitting,
        },
        rightButton: {
          text: t('Issues.RejectModal.RejectButton'),
          colorSchema: 'destroy',
          type: 'submit',
          disabled: isSubmitting,
          isLoading: isSubmitting,
          form: 'rejectIssueForm',
        },
      }}
      onDismissed={reset}
      isDisabled={isSubmitting}
      testId={'reject-issue-modal'}
    >
      {content}
    </DialogModal>
  );
};
