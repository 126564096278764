import React, { useCallback, useMemo } from 'react';

import { useContextSelector } from 'use-context-selector';
import { QuestionSchemeEditorContext } from '../../container/questionSchemeUIEditor.context';
import { EditableField } from '../editableField/editableField.component';
import { QuestionOverview } from '../questionOverview/questionOverview.component';
import { QuestionSchemeOverviewProps } from './questionSchemeOverview';
import Style from './questionSchemeOverview.module.css';

export const QuestionSchemeOverview = (props: QuestionSchemeOverviewProps) => {
  const { scheme, route } = props;
  const changeQuestionField = useContextSelector(QuestionSchemeEditorContext, (x) => x.changeQuestionField);

  const questions = useMemo(() => {
    return scheme?.questions?.filter((x) => x !== null);
  }, [scheme?.questions]);

  const onApplyHandler = useCallback(
    (key: string, newValue: string) => changeQuestionField && changeQuestionField(key, newValue, route),
    [changeQuestionField, route],
  );

  return (
    <div>
      <div className={Style.fieldsContainer}>
        {scheme &&
          Object.keys(scheme).map((key) => {
            return (
              <EditableField
                key={key}
                fieldName={key}
                fieldValue={scheme[key]}
                onApply={(newValue) => onApplyHandler(key, newValue)}
              />
            );
          })}
      </div>
      <div className={Style.schemeContainer}>
        {questions &&
          questions.map((question, i) => {
            return <QuestionOverview key={question.id} {...question} index={i + 1} lastIndex={questions.length} />;
          })}
      </div>
    </div>
  );
};
