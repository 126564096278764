/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect, useMemo } from 'react';
import { CardSectionsEnum, IPatientListDto } from '../../services/api/api-client';
import Style from './index.module.css';
import { trackEvent } from '../../application/analitics/matomo/matomo-tracker';
import { MetricActions } from '../../application/analitics/matomo/matomo-actions';
import { MetricCategories } from '../../application/analitics/matomo/matomo-categories';
import { SectionConstants } from './section.constants';
import clsx from 'clsx';
import { PatientCardContext } from './PatientCardContext';
import { DevError } from '../../helpers/components/DevError/DevError.component';
import { ExpandedRowCard } from 'uikit/table/ExpandedRowCard';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const ExpandedPatientRow = React.memo((props: IPatientListDto & { rowIndex: number }) => {
  const study = useStudy();
  const group = study?.groups.find((x) => x.id === props.group);
  const cardSections = group?.patientCardSections;

  const devErrors = [] as string[];
  if (!group) devErrors.push('Patient group is not found in app context');

  useEffect(() => {
    trackEvent({
      action: MetricActions.ButtonPressed,
      category: MetricCategories.Patient,
      name: 'Opened patient card (accordion)',
      value: props.rowIndex,
    });

    return () => {
      trackEvent({
        action: MetricActions.ButtonPressed,
        category: MetricCategories.Patient,
        name: 'Closed patient card (accordion)',
        value: props.rowIndex,
      });
    };
  }, [props.rowIndex]);

  const cardContextValue = useMemo(() => ({ patient: props, group: group! }), [props, group]);

  if (devErrors.length > 0) return <DevError messages={devErrors} />;

  return (
    <PatientCardContext.Provider value={cardContextValue}>
      <ExpandedRowCard containerClassName={Style.cardContainer}>
        {cardSections!.map((column, i) => (
          <div key={i} className={Style.column}>
            {column
              .filter((x) => study?.hasDbImport || x !== CardSectionsEnum.Analyzes)
              .map((section, j) => {
                const isDarkBackground = (i + j) % 2 === 1;
                return (
                  <div className={clsx(Style.section, { [Style.background]: isDarkBackground })} key={section}>
                    {SectionConstants[section](props, isDarkBackground)}
                  </div>
                );
              })}
          </div>
        ))}
      </ExpandedRowCard>
    </PatientCardContext.Provider>
  );
});
