﻿import { AppAttachmentValue } from 'uikit/fields/attachments/attachmentsField/appAttachmentsField';
import { RPDF } from '../../react-pdf';
import React, { FC, useMemo } from 'react';
import { ReportColors } from '../../report-colors';
import { localFormat } from 'src/helpers/date-helpers';
import { useTranslation } from 'react-i18next';

export const Field: FC<{
  className?: string;
  label: string;
  caption?: string;
  skipReason?: string;
  value?: string | Date | boolean | { index?: number | null; text: string } | string[] | null;
  attachments?: AppAttachmentValue[];
}> = (props) => {
  const { t } = useTranslation();
  const localStyles = RPDF.StyleSheet.create({
    field: {
      flexDirection: 'row',
      paddingVertical: 8,
      gap: 24,
      ...RPDF.tableStyles.divider,
    },
    label: {
      ...RPDF.typography.heading5,
      flex: 2,
    },
    value: {
      ...RPDF.typography.text8,
      flex: 1,
      flexDirection: 'column',
    },
    valueItem: {
      flexDirection: 'row',
      columnGap: 4,
    },
  });

  const values = useMemo(() => {
    if (Array.isArray(props.value)) return props.value.map((x) => ({ index: undefined, text: x }));
    if (typeof props.value === 'string') return [{ index: undefined, text: props.value }];
    if (props.value instanceof Date) return [{ index: undefined, text: localFormat(props.value) }];
    if (typeof props.value === 'boolean' && props.value === true) return [{ index: undefined, text: t('Common_yes') }];
    if (props.value) return [props.value];

    return [];
  }, [props.value, t]);

  return (
    <RPDF.View wrap={false} style={localStyles.field}>
      <RPDF.Text style={localStyles.label}>{props.label}</RPDF.Text>
      <RPDF.View style={localStyles.value}>
        {props.skipReason && <RPDF.Text>{props.skipReason}</RPDF.Text>}
        {!props.skipReason &&
          values.map((x, i) => (
            <RPDF.View key={i} style={localStyles.valueItem}>
              {typeof x.index === 'number' && (
                <RPDF.Text style={{ color: ReportColors.plain }}>{`(${x.index})`}</RPDF.Text>
              )}
              <RPDF.Text>{x.text}</RPDF.Text>
            </RPDF.View>
          ))}
      </RPDF.View>
    </RPDF.View>
  );
};
