import { defaultToolSettings } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { createId } from 'src/helpers/typeUtils';
import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import {
  ApplicableRules,
  ValidationDefaultSettings,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { UserComponent } from '@craftjs/core';

export interface ICheckBoxInputProps extends IFormFieldProps {
  asSwitcher?: boolean;
}

export type ICheckBoxInput = UserComponent<ICheckBoxInputProps>;

export function checkBoxInputDefaultPropsFactory(): ICheckBoxInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    validation: { ...ValidationDefaultSettings },
  };
}

export const checkBoxApplicableValidationRules: ApplicableRules = {
  isRequired: true,
};
