import React from 'react';
import { ReportFirstPage } from '../components/ReportFirstPage';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { InfoField, InfoFieldsColumn } from '../components/reportFields';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { ReportSignAndTitleField } from '../components/ReportSignAndTitleField';
import { getTagsAsArray } from '../../patientTags/tagsHelper';

export type SurveysFirstPageDataType = {
  profileFullName: string;
  studyNumber: string;
  studyName: string;
  filledSurveysCount: number;
  missedSurveysCount: number;
  patientUid: string;
  headerText: string;
  patientTags: Record<string, string> | undefined;
};

export const SurveysFirstPage = (props: SurveysFirstPageDataType) => {
  const {
    studyNumber,
    filledSurveysCount,
    missedSurveysCount,
    profileFullName,
    patientUid,
    headerText,
    patientTags,
    studyName,
  } = props;
  const { t } = useScopedTranslation('Reports.Surveys.FirstPage');

  const Header = (pages?: string | JSX.Element) => {
    return (
      <>
        <InfoFieldsColumn>
          <InfoField header={t('CreatedAt')} value={localFormat(new Date(), DateFormats.dateTime)} />
          <InfoField header={t('CreatedBy')} value={profileFullName} />
          <InfoField header={t('Study')} value={`${studyName} (${studyNumber})`} />
          <InfoField header={t('PatientId')} value={patientUid} />
          {getTagsAsArray(patientTags ?? {}).map((tag, index) => {
            return <InfoField key={index} header={tag.key} value={tag.value} />;
          })}
        </InfoFieldsColumn>
        <InfoFieldsColumn>
          <InfoField header={t('PageCount')} value={pages} />
          <InfoField header={t('FilledSurveysCount')} value={filledSurveysCount.toString()} />
          <InfoField header={t('MissedSurveysCount')} value={missedSurveysCount.toString()} />
        </InfoFieldsColumn>
      </>
    );
  };

  const SignComponent = () => (
    <ReportSignAndTitleField renderDateField={false} customText={t('Signature', { name: profileFullName })} />
  );

  return (
    <ReportFirstPage
      headerText={headerText}
      HeaderComponent={Header}
      SignTitleComponent={SignComponent}
      patientUid={patientUid}
    />
  );
};
