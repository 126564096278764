import React from 'react';
import { FieldValues, FieldPath, Control, RegisterOptions, Controller } from 'react-hook-form';
import { FieldsWithType } from '../helpers/typeUtils';
import {
  AppCheckboxGroupField,
  AppCheckboxGroupFieldProps,
} from 'uikit/fields/checkboxGroup/AppCheckboxGroupField.component';
import { AppInputError } from 'uikit/wrappers';
import { AppCheckboxInput, AppCheckboxInputProps } from 'uikit/inputs';

type HookFormCheckboxGroupFieldProps<
  TOption extends Record<Key, string | number>,
  Key extends FieldsWithType<TOption, string | number>,
  TFieldValues extends FieldValues = FieldValues,
> = Omit<AppCheckboxGroupFieldProps<TOption, Key>, 'value' | 'onChange'> & {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  rules?: Exclude<RegisterOptions, 'valueAsDate' | 'setValueAs'>;
  onFocus?: () => void;
  defaultValue?: unknown;
};

export function HookFormCheckboxGroupField<
  TOption extends Record<Key, string | number>,
  Key extends FieldsWithType<TOption, string | number>,
>(props: HookFormCheckboxGroupFieldProps<TOption, Key>) {
  const { control, name, rules, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={null}
      render={({ field: { onChange, value, ref } }) => (
        <div tabIndex={0} ref={ref}>
          <AppCheckboxGroupField<TOption, Key> {...rest} value={value || []} onChange={onChange} />
        </div>
      )}
    />
  );
}

type HookFormCheckboxFieldProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  AppCheckboxInputProps,
  'value' | 'onChange'
> & {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  rules?: Exclude<RegisterOptions, 'valueAsDate' | 'setValueAs'>;
  onFocus?: () => void;
  defaultValue?: unknown;
};

export const HookFormCheckboxField = <TFieldValues extends FieldValues>(
  props: HookFormCheckboxFieldProps<TFieldValues>,
) => {
  const { control, name, rules, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <div tabIndex={0} ref={ref}>
          <AppInputError errors={error?.message}>
            <AppCheckboxInput {...rest} checked={!!value} onChange={onChange} />
          </AppInputError>
        </div>
      )}
    />
  );
};
