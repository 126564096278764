﻿import React, { FC } from 'react';
import styles from './overview.module.scss';
import { AppButton } from 'uikit/buttons';
import clsx from 'clsx';

export type OverviewNavbarItemProps = {
  key: string | number;
  selected: boolean;
  disabled?: boolean;
  text: string;
  onClick: () => void;
};

type OverviewNavbarProps = {
  items: OverviewNavbarItemProps[];
};

export const OverviewNavbar: FC<OverviewNavbarProps> = (props) => {
  if (!props.items || props.items.length === 0) return <></>;

  return (
    <div className={styles.overviewNavbar}>
      {props.items.map((x) => (
        <NavbarItem key={x.key} selected={x.selected} disabled={x.disabled} text={x.text} onClick={x.onClick} />
      ))}
    </div>
  );
};

const NavbarItem: FC<OverviewNavbarItemProps> = (props) => {
  return (
    <AppButton
      variant={'icon-link'}
      colorSchema={'secondary'}
      disabled={props.disabled}
      textClassName={clsx(styles.overviewNavbarItem, {
        [styles.overviewNavbarItemSelected]: props.selected,
        [styles.overviewNavbarItemDisabled]: props.disabled,
      })}
      text={props.text}
      onClick={props.onClick}
    />
  );
};
