import React, { useCallback, useMemo } from 'react';
import { GeneralPatientInfoProps } from './generalInfo';
import Style from './generalInfo.module.css';
import { ExpandedRowHeader } from '../header/expandedRowHeader.component';
import { getCurrentDateLocale } from '../../../application/localisation/localization';
import { LogExportModal } from '../exportPatientLog/logExportModal.component';
import { useModal } from '../../../application/hooks/useModal';
import { ReactComponent as ExportIcon } from 'assets/img/common/export.svg';
import { ReactComponent as ArchiveIcon } from 'assets/img/common/archive_20.svg';
import { Permissions } from 'src/services/api/api-client';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { AppButton, AppButtonProps } from 'uikit/buttons';
import { DateFormats, formatRelativeDateOnly, localFormat } from '../../../helpers/date-helpers';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppCheckboxInput } from 'uikit/inputs';
import { Chips } from 'uikit/chips/chips.component';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import useApplyPatientFilter from '../../../features/patients/useApplyPatientFilter';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import PropertiesList from 'uikit/propertiesList/PropertiesList';
import { conditionalConcat } from 'src/helpers/arrayHelpers';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { ConfirmResult, useConfirmationModal } from 'src/components/dialogModal/useConfirmationModal';
import { archivePatient } from 'src/services/api/api-client/PatientClient';
import { Trans } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { QueryFactory } from 'src/services/api';
import { getTagsAsArray } from '../../../features/patientTags/tagsHelper';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const GeneralPatientInfo = React.memo((props: GeneralPatientInfoProps) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo');
  const { t: patientFiltersLocalizer } = useScopedTranslation('Dashboard.PatientFilters');
  const commonLocalizer = useCommonLocalization();
  const queryClient = useQueryClient();
  const { surveys, hasEcrf, groups } = useStudy() ?? {};

  const applyPatientFilter = useApplyPatientFilter();
  const logExportModal = useModal();
  const archiveConfirmation = useConfirmationModal();
  const hasSurveys = surveys && surveys.length > 0;

  const { hasPermission, hasOneOfPermissions } = useHasPermissions();

  const tagsArray = useMemo(() => getTagsAsArray(props.tags), [props.tags]);

  const archiveHandler = useCallback(async () => {
    const result = await archiveConfirmation.open({
      title: t('ArchivePatientModal.Title', { uniqueId: props.uniqueId }),
      text: (
        <span>
          <Trans i18nKey={'Dashboard.PatientAccordion.GeneralInfo.ArchivePatientModal.Text'}>
            <span className={Style.mediumText} />
            <span className={Style.awfulText} />
          </Trans>
        </span>
      ),
      okButtonText: t('ArchivePatientModal.OkButton'),
    });

    if (result === ConfirmResult.Confirm) {
      await archivePatient(props.id);
      await Promise.all([
        queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientsQueryKey()),
        queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientStudyCountersQueryKey(props.studyId)),
        queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientQueryKey(props.id)),
        queryClient.invalidateQueries(QueryFactory.ProfileQuery.getDoctorProfileInfoQueryKey()),
      ]);
    }
  }, [archiveConfirmation, props.id, props.studyId, props.uniqueId, queryClient, t]);

  const headerButtons = useMemo(
    () =>
      conditionalConcat<AppButtonProps & { key: string; tooltipText: string }>(
        hasPermission(Permissions.PatientArchive) &&
          props.canArchive && [
            {
              key: 'ArchiveThePatient',
              onClick: archiveHandler,
              variant: 'icon-link',
              colorSchema: 'primary',
              Icon: ArchiveIcon,
              tooltipText: t('ArchiveThePatient'),
              testId: 'archive-button',
            },
          ],
        hasOneOfPermissions(Permissions.ExportForm, Permissions.ExportPatientEcrfReport) &&
          // In eCRF study data can be exported only for archived patients
          (!hasEcrf || !!props.archivedBy) && [
            {
              key: 'ExportAuditLogs',
              onClick: logExportModal.openModal,
              variant: 'icon-link',
              colorSchema: 'primary',
              Icon: ExportIcon,
              tooltipText: t('ExportAuditLogs'),
              testId: 'export-button',
            },
          ],
      ),
    [
      archiveHandler,
      hasEcrf,
      hasOneOfPermissions,
      hasPermission,
      logExportModal.openModal,
      props.archivedBy,
      props.canArchive,
      t,
    ],
  );

  return (
    <>
      <div className={Style.container} data-test-id={'patient-card-general-info'}>
        <div className={Style.tableWrapper}>
          <ExpandedRowHeader
            text={
              <IssueMark
                issueContext={{
                  subject: 'Patient',
                  linkedPatientUniqId: props.uniqueId,
                  topic: 'GeneralInfo',
                }}
                containerClassName={Style.issueMarkContainer}
              >
                {commonLocalizer('Dashboard.PatientAccordion.GeneralInfo.Header')}
              </IssueMark>
            }
            rightHeader={
              <div className={Style.buttonsContainer}>
                {headerButtons.map(({ tooltipText, ...x }) => (
                  <Tooltip key={x.key} text={tooltipText}>
                    <AppButton {...x} />
                  </Tooltip>
                ))}
              </div>
            }
          />

          <PropertiesList>
            <PropertiesList.Property name={t('DeviceSerial')} value={props.deviceSerialNumber} />
            <PropertiesList.Property
              name={t('PrismaSyncDate')}
              value={
                props.lastTherapyDay
                  ? formatRelativeDateOnly(props.lastTherapyDay, Date.now(), getCurrentDateLocale())
                  : commonLocalizer('Common_Never')
              }
            />
            {props.contractSignedAt && (
              <PropertiesList.Property
                name={t('ContractSignDate')}
                value={localFormat(props.contractSignedAt, DateFormats.longDate)}
              />
            )}
            {!props.isStudyFinished && props.isStudyStarted && (
              <PropertiesList.Property
                name={t('PatientFilters')}
                value={
                  <div className={Style.checkboxWrapper}>
                    <PermissionsCheck permissions={Permissions.PatientCreate}>
                      {(accessAllowed) => (
                        <>
                          <AppCheckboxInput
                            label={patientFiltersLocalizer('IsClinic')}
                            // We use "checked" instead of "defaultChecked" there,
                            // because we update this state from the "Stationäre Aufenthalt" form
                            checked={props?.filters?.isClinic}
                            onChange={(e) => applyPatientFilter(props.id, 'isClinic', e.target.checked)}
                            readOnly={!accessAllowed}
                          />
                          <AppCheckboxInput
                            label={patientFiltersLocalizer('IsControlled')}
                            defaultChecked={props?.filters?.isControlled}
                            onChange={(e) => applyPatientFilter(props.id, 'isControlled', e.target.checked)}
                            readOnly={!accessAllowed}
                          />
                          {hasSurveys && (
                            <AppCheckboxInput
                              label={patientFiltersLocalizer('NoApp')}
                              defaultChecked={props?.filters?.hasNoApp}
                              onChange={(e) => applyPatientFilter(props.id, 'hasNoApp', e.target.checked)}
                              readOnly={!accessAllowed}
                            />
                          )}
                        </>
                      )}
                    </PermissionsCheck>
                  </div>
                }
              />
            )}
            {tagsArray.map((tag) => (
              <PropertiesList.Property
                key={tag.key}
                testId="tag"
                nameTestId="tag-key"
                valueTestId="tag-value"
                name={tag.key}
                value={
                  <div className={Style.tagValueWrapper}>
                    <Chips checked text={tag.value} />
                  </div>
                }
              />
            ))}
          </PropertiesList>
        </div>
      </div>

      {archiveConfirmation.modal}
      <LogExportModal
        patient={{
          id: props.id,
          uniqueId: props.uniqueId,
          routeProgress: props.studyRouteProgress,
          tags: props.tags,
          groupId: props.group,
        }}
        studyId={props.studyId}
        groups={groups!}
        modal={logExportModal}
        modalTitle={commonLocalizer('LogExport.PatientExportModalTitle')}
      />
    </>
  );
});
