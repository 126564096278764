﻿import { DialogModal } from '../../dialogModal/dialogModal.component';
import React, { FC } from 'react';
import { UseModalProps } from '../../../application/hooks/useModal';
import { useTranslation } from 'react-i18next';
import Style from './closeConfirmationModal.module.scss';

export const CloseConfirmationModal: FC<{
  modal: UseModalProps;
  saveButton: JSX.Element | undefined;
  onDiscard: () => void;
}> = (props) => {
  const { t } = useTranslation();

  return (
    <DialogModal
      visible={props.modal.visible}
      onHide={props.modal.closeModal}
      testId={'discard-modal-confirmation'}
      containerClassName={Style.modalContainer}
      footer={{
        leftButton: {
          colorSchema: 'destroy',
          text: t('Forms.ConfirmationModal.DiscardBtn'),
          type: 'reset',
          onClick: () => {
            props.modal.closeModal();
            props.onDiscard();
          },
        },
        customRightButton: props.saveButton,
      }}
      title={t('Forms.ConfirmationModal.Header')}
    >
      <span>{t('Forms.ConfirmationModal.MainText')}</span>
    </DialogModal>
  );
};
