﻿import React, { FC, PropsWithChildren, useCallback, useState } from 'react';
import { NotificationBatchDto, NotificationType } from '../services/api/api-client';
import { Trans, useTranslation } from 'react-i18next';
import { LocalizedResourceDictionaryKeys } from '../application/localisation/i18next';
import styles from './UserNotificationBatchToastMessage.module.scss';
import { useHasPermissions } from '../helpers/auth/auth-helper';
import { Permissions } from 'src/services/api/api-client';
import { OpenFormArgsType } from './notificationProvider';

export const NotificationBatchToastMessage: FC<{
  batch: NotificationBatchDto;
  openIssueView?: (issueId: number) => void;
  openForm: (args: OpenFormArgsType) => Promise<void>;
}> = ({ batch, openIssueView, openForm }) => {
  const { t } = useTranslation();
  const type = NotificationType[batch.type] as keyof typeof NotificationType;

  return (
    <Trans
      t={t}
      count={batch.sourceNotificationIds.length}
      i18nKey={`Notifications.GroupedMessage.${type}` as LocalizedResourceDictionaryKeys}
      values={batch.payload}
      components={{
        bold: <span className={styles.bold} />,
        IssueLink: <a className={styles.link} onClick={() => openIssueView?.(parseInt(batch.payload.IssueId))} />,
        FormLink:
          batch.type === NotificationType.FormDeadline ? (
            <FormLink payload={batch.payload} openForm={openForm} />
          ) : (
            <></>
          ),
      }}
    />
  );
};

const FormLink: FC<
  PropsWithChildren<{
    payload: Record<string, string | null | undefined>;
    openForm: (args: OpenFormArgsType) => Promise<void>;
  }>
> = ({ payload, children, openForm }) => {
  const { t } = useTranslation();
  const { hasPermission } = useHasPermissions();
  const [isLoading, setIsLoading] = useState<boolean>();

  const formId = parseInt(payload.FormId!);
  const formType = payload.FormType!;
  const patientId = payload.PatientId!;
  const patientUniqueId = payload.PatientUniqueId!;
  const stepName = payload.StepName!;
  const resultId = payload.FormResultId;

  const openFormHandler = useCallback(async () => {
    try {
      setIsLoading(true);
      await openForm({ formId, patientId, patientUniqueId, formType, stepName });
    } finally {
      setIsLoading(false);
    }
  }, [formId, formType, openForm, patientId, patientUniqueId, stepName]);

  if (!resultId && !hasPermission(Permissions.PatientCreate)) return <>{children}</>;

  return (
    <a className={styles.link} onClick={openFormHandler}>
      {isLoading ? t('Common_loading') : children}
    </a>
  );
};
