import React, { useCallback, useRef, useState } from 'react';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { AppButton } from 'uikit/buttons';
import { AppTextField } from 'uikit/fields';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { ReactComponent as EditReasonIcon } from 'assets/img/common/message_filled_16.svg';
import Style from './useFormOverview.module.scss';
import clsx from 'clsx';

export type RecallApprovalReasonResult =
  | { state: 'aborted' }
  | {
      state: 'done';
      recallReason: string;
    };

export const useRecallApprovalReason = () => {
  const { t } = useScopedTranslation('Forms.Overview');
  const commonLocalizer = useCommonLocalization();

  const [isVisible, setIsVisible] = useState<boolean>();
  const [recallReason, setRecallReason] = useState<string>('');
  const [recallError, setRecallError] = useState<string>();

  const promiseRef = useRef<(test: RecallApprovalReasonResult) => void>();

  const clearRecallReasonState = useCallback(() => {
    setIsVisible(false);
    setRecallReason('');
    setRecallError(undefined);
  }, []);

  const recallApprovalHandler = useCallback(async () => {
    if (isNullOrEmpty(recallReason)) {
      setRecallError(t('RecallApprovalComponent.ErrorText'));
      return;
    }

    promiseRef?.current?.({ state: 'done', recallReason });
    clearRecallReasonState();
  }, [clearRecallReasonState, recallReason, t]);

  return {
    component: isVisible && (
      <div className={Style.footer}>
        <AppTextField
          type={'text-area'}
          labelProps={{
            text: t('RecallApprovalComponent.FieldLabel'),
          }}
          errorProps={{ errors: recallError }}
          placeholder={t('RecallApprovalComponent.FieldPlaceholder')}
          value={recallReason}
          onChange={(e) => {
            setRecallReason(e.target.value);
            setRecallError(undefined);
          }}
        />
        <div className={Style.buttonsContainer}>
          <AppButton
            variant={'button'}
            colorSchema={'secondary'}
            text={commonLocalizer('Common_Cancel')}
            onClick={() => {
              promiseRef?.current?.({ state: 'aborted' });
              clearRecallReasonState();
            }}
          />
          <AppButton
            testId={'revoke-approval-button'}
            variant={'button'}
            colorSchema={'destroy'}
            text={t('RecallApprovalComponent.RevokeButtonText')}
            onClick={recallApprovalHandler}
          />
        </div>
      </div>
    ),
    open: async () => {
      setIsVisible(true);
      return new Promise<RecallApprovalReasonResult>((resolve) => {
        promiseRef.current = resolve;
      });
    },
    close: () => {
      promiseRef?.current?.({ state: 'aborted' });
      clearRecallReasonState();
    },
  };
};

export const ShowRecallReason = ({ recallReason, className }: { recallReason?: string; className?: string }) => {
  const { t } = useScopedTranslation('Forms.Overview');

  if (!recallReason) return <></>;

  return (
    <Tooltip
      testIdPrefix={'revoke-reason'}
      headerText={t('RevokeReasonTooltipHeader')}
      text={recallReason}
      hostStyles={clsx(className, Style.modalHeaderTooltipIcon)}
    >
      <EditReasonIcon />
    </Tooltip>
  );
};
