import React, { forwardRef } from 'react';
import { AppInputError, AppInputErrorProps, AppInputSkip, ErrorSign, FieldSkipProps } from 'uikit/wrappers';
import { AppInputLabel, AppInputLabelProps } from 'uikit/wrappers';
import { AppTagInput } from 'uikit/inputs';
import { AppTagInputProps } from '../../inputs/tagInput/appTagInput';

export type AppTagInputFieldType = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipProps?: FieldSkipProps;
} & AppTagInputProps;

export const AppTagInputField = forwardRef<HTMLInputElement, AppTagInputFieldType>(
  ({ errorProps, labelProps, skipProps, disabled, ...rest }, ref) => {
    return (
      <AppInputError {...errorProps}>
        <AppInputLabel disabled={disabled} rightSection={errorProps?.errors ? <ErrorSign /> : <></>} {...labelProps}>
          <AppInputSkip
            disabled={disabled}
            skipReason={skipProps?.skipReason}
            onSkipReasonChange={skipProps?.onSkipReasonChange ?? (() => {})}
            inputCanBeSkipped={skipProps?.inputCanBeSkipped}
            skipText={skipProps?.skipText}
            showReasons={skipProps?.showReasons}
            position="top-8px"
          >
            <AppTagInput ref={ref} disabled={disabled} invalid={!!errorProps?.errors} {...rest} />
          </AppInputSkip>
        </AppInputLabel>
      </AppInputError>
    );
  },
);
