﻿import { NotificationType } from 'src/services/api/api-client';

export type NotificationGroup = 'Study' | 'Issues';
export const notificationTypeToGroupMapping = {
  [NotificationType.Other]: 'Study',
  [NotificationType.NewSurveyAssigned]: 'Study',
  [NotificationType.PrismaCheck]: 'Study',
  [NotificationType.PrismaCloudCheck]: 'Study',
  [NotificationType.SurveyIsExpired]: 'Study',
  [NotificationType.NewIssueComment]: 'Issues',
  [NotificationType.IssueDeadline]: 'Issues',
  [NotificationType.FormDeadline]: 'Study',
} satisfies { [key in NotificationType]: NotificationGroup };

export const notificationGroupToTypeMapping = {
  ['Study']: [
    NotificationType.Other,
    NotificationType.NewSurveyAssigned,
    NotificationType.PrismaCheck,
    NotificationType.PrismaCloudCheck,
    NotificationType.SurveyIsExpired,
    NotificationType.FormDeadline,
  ],
  ['Issues']: [NotificationType.NewIssueComment, NotificationType.IssueDeadline],
} satisfies { [key in NotificationGroup]: NotificationType[] };
