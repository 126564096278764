import { FC } from 'react';
import { useRouteError } from 'react-router';
import { StudyNotFoundError } from 'src/helpers/customErrors/customErrors';

export type Route404ErrorBoundaryType = {
  element: JSX.Element;
};

export const Route404ErrorBoundary: FC<Route404ErrorBoundaryType> = ({ element }) => {
  const error = useRouteError() as any;
  if (error.status === 404) {
    return element;
  }
  if (error instanceof StudyNotFoundError) {
    return element;
  }

  throw error;
};
