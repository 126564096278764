import React, { useMemo, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import { useTranslation } from 'react-i18next';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import styles from '../controlsStyle.module.css';
import {
  useFieldDataFromUiEditorContext,
  useRefForDataBlockNavigation,
  useFieldValidation,
  useSkipReasonText,
  useDefaultValuesForConstructorMode,
} from './base/hooks';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { useContextSelector } from 'use-context-selector';
import {
  ITextInput,
  ITextInputProps,
  textApplicableValidationRules,
  textInputDefaultPropsFactory,
} from 'src/features/forms/base/controls/inputs/TextInput';
import { AppTagInput } from 'uikit/inputs';
import { isNullOrEmpty } from '../../../../helpers/string-helper';
import { AppAutoCompleteField } from 'uikit/fields';
import { AppTextField } from 'uikit/fields';
import { AppInputLabel } from 'uikit/wrappers';
import { TriggerComponentState } from 'uikit/wrappers';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';

export const TextInput: ITextInput = (props) => {
  const { size, dataKey, hasSkipCheckBox, isDisableWhenEditing, isDisabled, skipCheckBoxText } = props;
  const { enabled: isConstructorMode } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    connectors: { connect, drag },
    actions: { setProp: setProps },
    id,
  } = useNode();

  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const {
    isOnlyView,
    isEditMode,
    singleInputValue,
    setDataBlockFieldValue,
    skipReason,
    onSkipReasonChange,
    fieldEditReason,
  } = useFieldDataFromUiEditorContext(dataKey);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const blockRef = useRefForDataBlockNavigation();
  const [initialValue] = useState(singleInputValue);
  const { dataBlockValidation, triggerFieldValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const { defaultValue } = useDefaultValuesForConstructorMode(props);

  const isDisabledComponent = useMemo(
    () => !props.isEditable || (isDisableWhenEditing && isEditMode && !!initialValue) || isDisabled || isSubmitting,
    [initialValue, isDisableWhenEditing, isDisabled, isEditMode, isSubmitting, props.isEditable],
  );

  const editableComponent = (
    <div
      data-test-field-type={
        props.suggestions ? 'autocomplete-input' : props.isMultiline ? 'textarea-input' : 'text-input'
      }
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef}>
        {props.suggestions ? (
          <AppAutoCompleteField
            labelProps={{
              text: !props.hideLabel ? props.label || props.text || dataKey : undefined,
              isBold: props.isBold,
              tooltip: props.tooltip,
            }}
            errorProps={{
              errors: dataBlockValidation?.text,
            }}
            suggestions={props.suggestions}
            placeholder={props.placeholder}
            disabled={isDisabledComponent}
            value={isConstructorMode ? defaultValue ?? '' : singleInputValue ?? ''}
            onChange={(newValue) => {
              setDataBlockFieldValue?.(dataKey, newValue);
              if (isConstructorMode) {
                setProps((p: ITextInputProps) => {
                  p.defaultValue = newValue;
                });
              }
            }}
            onBlur={triggerFieldValidation}
            maxLength={props.validation?.maxLength || undefined}
            skipProps={{
              inputCanBeSkipped: hasSkipCheckBox && !props.withTrigger,
              skipText: skipCheckBoxText,
              onSkipReasonChange: onSkipReasonChange(dataKey),
              skipReason: skipReason,
            }}
          />
        ) : (
          <AppTextField
            type={props.isMultiline ? 'text-area' : 'text'}
            disabled={isDisabledComponent}
            placeholder={props.placeholder}
            maxLength={props.validation?.maxLength || undefined}
            value={isConstructorMode ? defaultValue ?? '' : singleInputValue ?? ''}
            onChange={(e) => {
              setDataBlockFieldValue?.(dataKey, e.target?.value);
              if (isConstructorMode) {
                setProps((p: ITextInputProps) => {
                  p.defaultValue = e.target?.value;
                });
              }
            }}
            onBlur={triggerFieldValidation}
            units={props.units}
            labelProps={{
              text: !props.hideLabel ? props.label || props.text || dataKey : undefined,
              isBold: props.isBold,
              tooltip: props.tooltip,
            }}
            errorProps={{
              errors: dataBlockValidation?.text,
            }}
            triggerProps={
              props.withTrigger
                ? {
                    className: styles.triggerComponent,
                    type: props.triggerType,
                    text: props.triggerControl,
                    onHide: () => setDataBlockFieldValue?.(dataKey, undefined),
                    initialState: singleInputValue ? 'Field' : props.triggerState,
                  }
                : undefined
            }
            skipProps={{
              inputCanBeSkipped: hasSkipCheckBox && !props.withTrigger,
              skipText: skipCheckBoxText,
              onSkipReasonChange: onSkipReasonChange(dataKey),
              skipReason: skipReason,
            }}
          />
        )}
      </div>
    </div>
  );

  const viewComponent = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label ?? props.dataKey,
        resultId: formResultVersion?.formResultId,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
    >
      <OverviewField
        label={props.label ?? props.dataKey}
        skipped={!singleInputValue && skipReason !== undefined}
        skipReason={skipReasonText}
        value={singleInputValue ? `${singleInputValue} ${props.units ?? ''}` : ''}
        editReason={fieldEditReason}
      />
    </IssueMark>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

const TextInputGeneralSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as ITextInputProps,
  }));
  const [triggerState, setTriggerState] = useState<TriggerComponentState>(
    props.withTrigger && props.triggerState ? props.triggerState : 'Field',
  );

  const options = [
    { key: 'Field', text: 'Field' },
    { key: 'Trigger', text: 'Trigger' },
  ];

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.isMultiline')}
          isChecked={!!props.isMultiline}
          setValue={(newValue) => {
            setProp((setProps: ITextInputProps) => {
              setProps.isMultiline = newValue ? newValue : undefined;
            });
          }}
        />
      </ControlsGroup>

      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.placeholder')}
        expanded={!!props.placeholder}
        collapsible
        flexible
        onOpen={() => {
          setProp((prop: ITextInputProps) => {
            prop.placeholder = 'Placeholder';
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ITextInputProps) => {
            prop.placeholder = undefined;
          }, 500);
        }}
      >
        <SettingsInput
          multiple
          value={props.placeholder || ''}
          onChange={(e) => {
            setProp((setProps: ITextInputProps) => {
              setProps.placeholder = e.target?.value ? e.target.value : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>

      <ControlsGroup
        collapsible
        flexible
        header={t('StudySettingsPage.FormBuilder.settings.hasSuggestions')}
        expanded={!!props.suggestions}
        onOpen={() => {
          setProp((prop: ITextInputProps) => {
            prop.suggestions = [];
          }, 500);
        }}
        onClose={() => {
          setProp((prop: ITextInputProps) => {
            prop.suggestions = undefined;
          }, 500);
        }}
      >
        <AppTagInput
          values={props.suggestions || []}
          onDeleteTag={(index) => {
            setProp((setProps: ITextInputProps) => {
              const newArray = setProps.suggestions?.slice() ?? [];
              newArray.splice(index, 1);
              setProps.suggestions = newArray;
            });
          }}
          onAddTag={(newValue) => {
            if (isNullOrEmpty(newValue)) return;

            setProp((setProps: ITextInputProps) => {
              const newArray = setProps.suggestions?.slice() ?? [];
              if (newArray.includes(newValue)) {
                return;
              }
              newArray.push(newValue);
              setProps.suggestions = newArray;
            });
          }}
        />
      </ControlsGroup>

      {/* It seems there is no way to edit these settings from form builder */}
      {/* TODO: Should we remove them? */}
      {/* Answer: we shouldn't remove part of code that it is just not configurable.
          I guess it is a bug.
          These types of control used in TeleIntervent study (see add Medicine, add Other information (createPatient form))
      */}
      <ControlsGroup flexible>
        {props.withTrigger && (
          <>
            <AppInputLabel text={t('StudySettingsPage.FormBuilder.settings.triggerText')}>
              <SettingsInput
                value={props.withTrigger ? props.triggerControl : ''}
                onChange={(e) => {
                  if (props.withTrigger) {
                    // @ts-ignore
                    setProp((setProps: ITextInputProps) => {
                      setProps.triggerControl = e.target?.value ? e.target.value : undefined;
                    });
                  }
                }}
              />
            </AppInputLabel>

            <AppInputLabel text={t('StudySettingsPage.FormBuilder.settings.triggerState')}>
              <AppDropDownWithSuggestionInput
                value={options.find((x) => x.key === triggerState)}
                placeholder={triggerState}
                onChange={(val: DropdownOption | undefined) => {
                  setTriggerState(val?.text as TriggerComponentState);
                  if (props.withTrigger) {
                    // @ts-ignore
                    setProp((setProps: ITextInputProps) => (setProps.triggerState = val?.text as TextAreaState));
                  }
                }}
                options={options}
              />
            </AppInputLabel>
          </>
        )}
      </ControlsGroup>
    </PanelContainer>
  );
};

const TextInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <TextInputGeneralSettings />
      <LayoutSettings />
      <ValidationSettings applicableRules={textApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

TextInput.craft = {
  defaultProps: textInputDefaultPropsFactory(),
  related: {
    settings: TextInputSettings,
  },
};
