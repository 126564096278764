import {
  AnswerStatisticsListItem,
  QualityMetric,
  ScoreCalculationStatusEnum,
} from '../../../../services/api/api-client';
import { RPDF } from '../../react-pdf';
import { ReportColors } from '../../report-colors';
import { localFormat } from '../../../../helpers/date-helpers';
import React from 'react';
import { useCommonLocalization } from '../../../../application/localisation/useCommonLocalization';
import { formatScoreValue } from '../../../../helpers/question-helper';
import { useScopedTranslation } from '../../../../application/localisation/useScopedTranslation';
import { Style } from '@react-pdf/types/style';
import { WarningScoreIcon } from '../../components/svg/10px/WarningScoreIcon';
import { NoScoreIcon } from '../../components/svg/10px/NoScoreIcon';

export const SurveyInfoSection = (props: {
  surveyTitle: string;
  resultDto: AnswerStatisticsListItem | AnswerStatisticsListItem[];
}) => {
  const { surveyTitle, resultDto } = props;
  const { t } = useScopedTranslation('Reports.Surveys');
  const isSingleQuestionSurvey = Array.isArray(resultDto);

  const localStyles = RPDF.StyleSheet.create({
    container: {
      height: 24,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: ReportColors.ultraLightGrey,
      justifyContent: 'space-between',
      paddingHorizontal: 8,
    },
    leftHeader: {
      flexDirection: 'row',
      gap: 4,
    },
  });

  const dateInformation = (answerDate: Date, filledBy?: string) => {
    const formattedFilledAtDate = localFormat(answerDate);

    return !!filledBy
      ? t('FilledByAt', {
          date: formattedFilledAtDate,
          doctor: filledBy,
        })
      : formattedFilledAtDate;
  };

  const amountInformation = (count: number) => {
    return t('FilledTimes', { count: count });
  };

  return (
    <RPDF.View style={localStyles.container}>
      <RPDF.View style={localStyles.leftHeader}>
        <RPDF.Text style={RPDF.typography.heading2}>{surveyTitle}</RPDF.Text>
      </RPDF.View>
      <RPDF.Text style={[RPDF.typography.text10, { color: ReportColors.darkGrey }]}>
        {isSingleQuestionSurvey
          ? amountInformation(resultDto.length)
          : dateInformation(resultDto.answerDate, resultDto?.answeredBy?.fullName)}
      </RPDF.Text>
    </RPDF.View>
  );
};

export const SurveyScoreSection = (props: {
  score: number | null;
  scoreStatus: ScoreCalculationStatusEnum | null;
  quality: QualityMetric | null;
}) => {
  const { scoreStatus, score } = props;
  const commonLocalizer = useCommonLocalization();

  if (scoreStatus === ScoreCalculationStatusEnum.NotCalculated && !!score) {
    return <RPDF.View />;
  }

  const localStyles = RPDF.StyleSheet.create({
    container: {
      backgroundColor: ReportColors.extraLightGrey,
      paddingHorizontal: 8,
    },
    totalScoreSection: {
      height: 24,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  });

  const scoreContent = () => {
    if (!score && score !== 0) {
      return scoreStatus !== ScoreCalculationStatusEnum.Done ? <WarningScoreIcon /> : <NoScoreIcon />;
    }

    return <RPDF.Text style={RPDF.typography.text8}>{formatScoreValue(score)}</RPDF.Text>;
  };

  return (
    <RPDF.View style={localStyles.container}>
      <RPDF.View style={localStyles.totalScoreSection}>
        <RPDF.Text style={RPDF.typography.heading5}>{commonLocalizer('Forms.Score.TotalScore')}</RPDF.Text>
        {scoreContent()}
      </RPDF.View>
    </RPDF.View>
  );
};

export const SurveyLegendSection = (props: { legend: string | null | undefined }) => {
  const { legend } = props;

  if (!legend) {
    return <RPDF.View />;
  }

  const localStyles = RPDF.StyleSheet.create({
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  });

  return (
    <RPDF.View style={localStyles.container}>
      <RPDF.Text style={[RPDF.typography.text8, { color: ReportColors.plain }]}>{legend}</RPDF.Text>
    </RPDF.View>
  );
};
