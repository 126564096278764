import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { TooltipProps } from './tooltip';
import Style from './tooltip.module.css';
import clsx from 'clsx';
import {
  Callout,
  DirectionalHint,
  ICalloutContentStyleProps,
  ICalloutContentStyles,
  IStyleFunctionOrObject,
  ITooltipProps,
  TooltipDelay,
  TooltipHost,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

const styleProps: IStyleFunctionOrObject<ICalloutContentStyleProps, ICalloutContentStyles> = {
  beakCurtain: { borderRadius: 4 },
  root: { borderRadius: 4, boxShadow: '0 0 20px 0 #505f9a33' },
};

const defaultTooltipProps: ITooltipProps = {
  calloutProps: {
    calloutMaxWidth: 1100,
    beakWidth: 12,
    styles: {
      beakCurtain: { borderRadius: 4 },
    },
  },
  styles: {
    root: { borderRadius: 4, boxShadow: '0 0 20px 0 #505f9a33' },
  },
};

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = (props) => {
  const [isCalloutVisible, setCalloutVisible] = useState<boolean>(false);
  const calloutButtonId = useId('callout-button');

  const { onDismiss } = props;

  const tooltipProps = useMemo(() => {
    return props.gapSpace === undefined
      ? defaultTooltipProps
      : {
          ...defaultTooltipProps,
          calloutProps: { ...defaultTooltipProps.calloutProps, gapSpace: props.gapSpace },
        };
  }, [props.gapSpace]);

  const toggleIsCalloutVisible = useCallback(() => {
    setCalloutVisible((state) => !state);
    // eslint-disable-next-line no-unused-expressions
    onDismiss && onDismiss(!isCalloutVisible);
  }, [isCalloutVisible, onDismiss]);

  const content = useMemo(() => {
    if (props.tooltipContent) {
      return <>{props.tooltipContent}</>;
    }

    return (
      <>
        {props.headerText && (
          <p
            data-test-id={`${props.testIdPrefix || 'common'}-tooltip-header`}
            className={clsx(Style.header, props.headerTextStyles)}
          >
            {props.headerText}
          </p>
        )}
        <p
          data-test-id={`${props.testIdPrefix || 'common'}-tooltip-text`}
          className={clsx(Style.text, props.textStyles)}
        >
          {props.text}
        </p>
      </>
    );
  }, [
    props.tooltipContent,
    props.headerText,
    props.testIdPrefix,
    props.headerTextStyles,
    props.textStyles,
    props.text,
  ]);

  const renderTooltip = useMemo(() => {
    return (
      <div
        data-test-id={`${props.testIdPrefix || 'common'}-tooltip-content`}
        className={clsx(Style.container, props.calloutContainerStyles)}
      >
        {content}
      </div>
    );
  }, [props.testIdPrefix, props.calloutContainerStyles, content]);

  const disabled = props.disabled || (!props.headerText && !props.text && !props.tooltipContent);

  if (disabled) {
    return <>{props.children}</>;
  }

  if (props.trigger === 'click') {
    return (
      <>
        <div
          onClick={toggleIsCalloutVisible}
          className={clsx(Style.cursorPointer, props.hostStyles)}
          id={calloutButtonId}
        >
          {props.children}
        </div>
        {isCalloutVisible && (
          <Callout
            beakWidth={10}
            gapSpace={0}
            target={`#${calloutButtonId}`}
            className={clsx(Style.callout, props.styles)}
            onDismiss={toggleIsCalloutVisible}
            styles={styleProps}
            directionalHint={props.directionalHint ?? DirectionalHint.bottomCenter}
          >
            {renderTooltip}
          </Callout>
        )}
      </>
    );
  }

  return (
    <TooltipHost
      componentRef={props.componentRef}
      directionalHint={props.directionalHint}
      content={renderTooltip}
      className={Style.callout}
      hostClassName={clsx(Style.host, props.hostStyles)}
      tooltipProps={tooltipProps}
      closeDelay={100}
      delay={TooltipDelay.zero}
    >
      <div className={props.styles} data-test-id={`${props.testIdPrefix || 'common'}-tooltip-container`}>
        {props.children}
      </div>
    </TooltipHost>
  );
};
