import React, { FC } from 'react';
import { FormModalComponent } from './formModalComponent';
import { UiEditorProvider, UiEditorProviderProps } from '../uiEditor/provider/formFillProvider';
import { FormModalComponentProps } from '../uiEditor/uiEditor';
import { FormFillingNavigationProvider } from '../uiEditor/formFillingNavigation/FormFillingNavigationProvider';

export const FormModalWithContext: FC<FormModalComponentProps & UiEditorProviderProps> = (props) => {
  return (
    <UiEditorProvider {...props}>
      <FormFillingNavigationProvider>
        <FormModalComponent {...props} />
      </FormFillingNavigationProvider>
    </UiEditorProvider>
  );
};
