import { ConditionalFieldBehaviorEnum } from '../../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import React, { FC, useCallback } from 'react';
import { IFormFieldWithOptionsProps } from '../../../../../features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import { useTranslation } from 'react-i18next';
import { ExpandedComponent } from '../../../expandedComponent/expandedComponent.control';
import { AppButton } from 'uikit/buttons/button/button.component';
import { ReactComponent as AddIcon } from 'assets/img/common/add.svg';
import styles from '../../../controls/controlsStyle.module.css';
import { ReactComponent as ArrowIcon } from 'assets/img/common/stepperArrow.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/common/delete.svg';
import { CheckboxSettings } from '../settingsInputs/CheckboxSettings';
import { statusIconDropDownOptions } from '../../../controls/Inputs/statusIcons/statusIcons.constants';
import { AppInputLabel } from 'uikit/wrappers/label/appInputLabel.component';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';
import { PanelContainer } from '../../components/panelContainer';
import { ControlsGroup } from '../../components/controlsGroup';
import { useNode } from '@craftjs/core';
import { SettingsInput } from '../settingsInputs/settingsInput';
import { createId } from 'src/helpers/typeUtils';

const conditionalBehaviorOptions: DropdownOption<number>[] = Object.entries(ConditionalFieldBehaviorEnum)
  .filter(([, value]) => typeof value === 'string')
  .map(([key, value]) => {
    return { key: Number(key), text: value as string };
  });

const OptionsSettingsSection: FC<
  {
    hasVariable?: boolean;
  } & (
    | {
        variant?: 'common';
        minValue?: never;
        maxValue?: never;
      }
    | {
        variant: 'slider';
        minValue: number;
        maxValue: number;
      }
  )
> = ({ variant = 'common', minValue, maxValue, hasVariable }) => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as IFormFieldWithOptionsProps,
  }));

  const addOption = useCallback(() => {
    setProp((setProps: IFormFieldWithOptionsProps) => {
      const newOptionKey = setProps.options.reduce((max, x) => Math.max(x.key, max), -1) + 1;

      setProps.options.push({
        key: newOptionKey,
        displayValue: `Option ${setProps.options.length + 1}`,
        value: createId(),
        valueRangeCondition: [minValue!, maxValue!],
      });
    });
  }, [maxValue, minValue, setProp]);

  //#region Option moving and deleting

  const moveOptionUp = useCallback(
    (key: number) => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        const optionIndex = setProps.options.findIndex((x) => x.key === key);
        if (optionIndex === -1) throw new Error('Option was not found');
        if (optionIndex === 0) throw new Error("Option can't be moved up");

        const x = setProps.options[optionIndex];
        const y = setProps.options[optionIndex - 1];
        setProps.options[optionIndex - 1] = x;
        setProps.options[optionIndex] = y;
      });
    },
    [setProp],
  );

  const moveOptionDown = useCallback(
    (key: number) => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        const optionIndex = setProps.options.findIndex((x) => x.key === key);
        if (optionIndex === -1) throw new Error('Option was not found');
        if (optionIndex === setProps.options.length - 1) throw new Error("Option can't be moved down");

        const x = setProps.options[optionIndex];
        const y = setProps.options[optionIndex + 1];
        setProps.options[optionIndex + 1] = x;
        setProps.options[optionIndex] = y;
      });
    },
    [setProp],
  );

  const deleteOption = useCallback(
    (key: number) => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        const optionIndex = setProps.options.findIndex((x) => x.key === key);
        if (optionIndex === -1) throw new Error('Option was not found');

        const [deletedOption] = setProps.options.splice(optionIndex, 1);

        if (setProps.defaultValue === deletedOption.value) {
          setProps.defaultValue = undefined;
        }
      });
    },
    [setProp],
  );

  //#endregion

  //#region Option properties setting

  const setOptionDisplayValue = useCallback(
    (optionKey: number, newDisplayValue: string) => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        const option = setProps.options.find((x) => x.key === optionKey);
        if (!option) throw new Error();

        option.displayValue = newDisplayValue;
      }, 500);
    },
    [setProp],
  );

  const setOptionVariable = useCallback(
    (optionKey: number, variable: string | undefined) => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        const option = setProps.options.find((x) => x.key === optionKey);
        if (!option) throw new Error();

        option.variable = variable;
      }, 500);
    },
    [setProp],
  );

  const setOptionScore = useCallback(
    (optionKey: number, newScore: number | undefined) => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        const option = setProps.options.find((x) => x.key === optionKey);
        if (!option) throw new Error();

        option.score = newScore;
      }, 500);
    },
    [setProp],
  );

  const setHasStatusIcons = useCallback(
    (newValue: boolean) => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        setProps.hasStatusIcons = newValue ? newValue : undefined;
      });
    },
    [setProp],
  );

  /**
   * Used only for slider component
   */
  const setStatusIconName = useCallback(
    (optionKey: number, newIconName: string | undefined) => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        const option = setProps.options.find((x) => x.key === optionKey);
        if (!option) throw new Error();

        option.statusIconName = newIconName;
      });
    },
    [setProp],
  );

  const setOptionRangeValue = useCallback(
    (optionKey: number, newValue: number, position: 'start' | 'end') => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        const option = setProps.options.find((x) => x.key === optionKey);
        if (!option || !option.valueRangeCondition) throw new Error();

        if (position === 'start') {
          option.valueRangeCondition = [newValue, option.valueRangeCondition[1]];
        }

        if (position === 'end') {
          option.valueRangeCondition = [option.valueRangeCondition[0], newValue];
        }
      });
    },
    [setProp],
  );

  //#region Conditional field

  const setWithConditionalField = useCallback(
    (optionKey: number, withConditionalField?: boolean) => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        const option = setProps.options.find((x) => x.key === optionKey);
        if (!option) throw new Error();

        if (!withConditionalField) {
          option.withConditionalField = undefined;
          option.conditionalFieldBehavior = undefined;
          return;
        }

        option.withConditionalField = withConditionalField;
        option.conditionalFieldBehavior = ConditionalFieldBehaviorEnum.Visibility;
      });
    },
    [setProp],
  );

  const setConditionalFieldBehavior = useCallback(
    (optionKey: number, newBehavior?: number) => {
      setProp((setProps: IFormFieldWithOptionsProps) => {
        const option = setProps.options.find((x) => x.key === optionKey);
        if (!option) throw new Error();

        option.conditionalFieldBehavior = newBehavior;
      });
    },
    [setProp],
  );

  //#endregion

  //#endregion

  const optionSettingsSections = props.options.map((option, index, array) => (
    <ExpandedComponent
      containerStyles={styles.settings}
      isExpandedAtStart={false}
      key={option.key}
      header={
        variant === 'slider'
          ? `${option.displayValue} (${option.valueRangeCondition?.[0]} - ${option.valueRangeCondition?.[1]})`
          : option.displayValue
      }
      headerStyles={'truncate'}
      rightHeaderComponent={
        <div className={styles.upAndDownIcons}>
          {array.length > 1 && index < array.length - 1 && (
            <AppButton
              Icon={ArrowIcon}
              onClick={() => moveOptionDown(option.key)}
              variant={'icon-link'}
              colorSchema={'primary'}
            />
          )}
          {array.length > 1 && index > 0 && (
            <AppButton
              Icon={ArrowIcon}
              iconClassName={styles.upIcon}
              onClick={() => moveOptionUp(option.key)}
              variant={'icon-link'}
              colorSchema={'primary'}
            />
          )}
          <AppButton
            Icon={DeleteIcon}
            onClick={() => deleteOption(option.key)}
            variant={'icon-link'}
            colorSchema={'decline'}
          />
        </div>
      }
    >
      <ControlsGroup flexible>
        {props.hasStatusIcons && (
          <AppDropDownWithSuggestionInput
            placeholder={t('StudySettingsPage.FormBuilder.settings.statusIcon')}
            isRequired={false}
            options={statusIconDropDownOptions}
            value={statusIconDropDownOptions.find((x) => x.key === option.statusIconName) ?? undefined}
            onChange={(x) => setStatusIconName(option.key, x?.key)}
          />
        )}
        <SettingsInput
          placeholder={t('StudySettingsPage.FormBuilder.settings.optionTextPlaceholder')}
          value={option.displayValue}
          onChange={(e) => setOptionDisplayValue(option.key, e.target.value)}
        />
      </ControlsGroup>

      <ControlsGroup
        flexible
        collapsible
        header={t('StudySettingsPage.FormBuilder.settings.withConditionalField')}
        expanded={!!option.withConditionalField}
        onOpen={() => {
          setWithConditionalField(option.key, true);
        }}
        onClose={() => {
          setWithConditionalField(option.key, undefined);
          setConditionalFieldBehavior(option.key, undefined);
        }}
      >
        <AppInputLabel text={t('StudySettingsPage.FormBuilder.settings.conditionalBehavior')}>
          <AppDropDownWithSuggestionInput
            isRequired
            options={conditionalBehaviorOptions}
            value={conditionalBehaviorOptions.find((x) => x.key === option.conditionalFieldBehavior)}
            onChange={(x) => setConditionalFieldBehavior(option.key, x?.key as number)}
          />
        </AppInputLabel>
        <AppInputLabel text={'Visible in range'}>
          {variant === 'slider' && (
            <div className={styles.intervalOptionContainer}>
              <SettingsInput
                type={'number'}
                placeholder={'from'}
                value={option.valueRangeCondition?.[0]}
                onChange={(e) => setOptionRangeValue(option.key, +e.target.value, 'start')}
              />
              <SettingsInput
                type={'number'}
                placeholder={'to'}
                value={option.valueRangeCondition?.[1]}
                onChange={(e) => setOptionRangeValue(option.key, +e.target.value, 'end')}
              />
            </div>
          )}
        </AppInputLabel>
      </ControlsGroup>

      <ControlsGroup
        flexible
        collapsible
        header={t('StudySettingsPage.FormBuilder.settings.score')}
        expanded={option.score !== undefined}
        onOpen={() => {
          setOptionScore(option.key, 0);
        }}
        onClose={() => {
          setOptionScore(option.key, undefined);
        }}
      >
        <SettingsInput
          type={'number'}
          placeholder={t('StudySettingsPage.FormBuilder.settings.score')}
          value={option.score ?? undefined}
          onChange={(e) => setOptionScore(option.key, e.target.value ? Number(e.target.value) : undefined)}
        />
      </ControlsGroup>

      <ControlsGroup
        header={t('StudySettingsPage.FormBuilder.settings.optionVariableLabel')}
        tooltip={t('StudySettingsPage.FormBuilder.settings.optionVariableTooltip')}
        flexible
        disabled={!hasVariable}
      >
        {hasVariable && (
          <SettingsInput
            placeholder={t('StudySettingsPage.FormBuilder.settings.optionVariablePlaceholder')}
            value={option.variable}
            onChange={(e) => setOptionVariable(option.key, e.target.value)}
          />
        )}
      </ControlsGroup>
    </ExpandedComponent>
  ));

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.options')} expanded={false}>
      <ControlsGroup flexible>
        {variant === 'common' && (
          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.settings.hasStatusIcons')}
            isChecked={!!props.hasStatusIcons}
            setValue={setHasStatusIcons}
          />
        )}

        <div className={styles.settings}>{optionSettingsSections}</div>
        <AppButton
          Icon={AddIcon}
          onClick={addOption}
          variant={'icon-link'}
          colorSchema={'primary'}
          text={'Add option'}
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

export default OptionsSettingsSection;
