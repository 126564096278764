import React, { useCallback, useEffect, useState } from 'react';
import { capitalize } from '@material-ui/core';
import { ToolbarItem } from '../../editor/toolbar/toolbar.item';
import { ToolbarSection } from '../../editor/toolbar/toolbar.section';
import { TextProps } from '.';
import { AppButton } from 'uikit/buttons';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { useTranslation } from 'react-i18next';
import { cloneSelectionData, getLinkFromSelection, selectionHasOneLink } from './helpers/linkHelpers';
import { AddLinkResult, LinkActionType, useAddLinkModal } from './useLinkModal';

export const TextSettings = () => {
  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const [isSelectionHasOneLink, setIsSelectionHasOneLink] = useState<boolean>(false);

  const { t } = useTranslation();
  const linkModal = useAddLinkModal();

  useEffect(() => {
    const getLinksInfo = () => {
      const selection = window.getSelection();
      if (!selection) return;

      const link = getLinkFromSelection(selection);
      setSelectedLink(link);

      const moreThanOneLink = selectionHasOneLink(selection);
      setIsSelectionHasOneLink(moreThanOneLink);
    };

    document.addEventListener('selectionchange', getLinksInfo);

    return () => {
      document.removeEventListener('selectionchange', getLinksInfo);
    };
  });

  const addLinkHandler = useCallback(async () => {
    const selectedPart = cloneSelectionData();
    if (isNullOrEmpty(selectedPart?.text?.toString())) return;

    const result = await linkModal.open({
      selection: selectedPart,
      actionType: LinkActionType.Add,
    });

    if (result !== AddLinkResult.Success) return;
  }, [selectedLink]);

  const editLinkHandler = useCallback(async () => {
    const selectedPart = cloneSelectionData();
    if (isNullOrEmpty(selectedLink)) return;

    if (!selectedLink) return;

    const result = await linkModal.open({
      selection: selectedPart,
      link: selectedLink,
      actionType: LinkActionType.Edit,
    });

    if (result !== AddLinkResult.Success) return;
  }, [selectedLink]);

  return (
    <>
      <ToolbarSection<TextProps>
        title={'Typography'}
        props={['fontSize', 'fontWeight', 'textAlign']}
        summary={({ fontSize, fontWeight, textAlign }) => {
          return `${fontSize || ''}, ${fontWeight}, ${capitalize(textAlign)}`;
        }}
      >
        <ToolbarItem<TextProps>
          propKey={'fontSize'}
          label={'Font Size'}
          type={'select'}
          options={['12', '14', '18', '24', '36', '48', '72']}
        />
        <ToolbarItem<TextProps>
          propKey={'fontWeight'}
          label={'Weight'}
          type={'select'}
          options={['400', '500', '600', '800']}
        />
        <ToolbarItem<TextProps>
          propKey={'textAlign'}
          label={'Align'}
          type={'select'}
          options={['left', 'center', 'right']}
        />
      </ToolbarSection>

      <ToolbarSection<TextProps>
        title={'Margin'}
        props={['margin']}
        summary={({ margin }) => {
          return `${margin[0] || 0}px ${margin[1] || 0}px ${margin[2] || 0}px ${margin[3] || 0}px`;
        }}
      >
        <ToolbarItem<TextProps> propKey={'margin'} zeroValue={0} index={0} type={'number'} label={'Top'} />
        <ToolbarItem<TextProps> propKey={'margin'} zeroValue={0} index={1} type={'number'} label={'Right'} />
        <ToolbarItem<TextProps> propKey={'margin'} zeroValue={0} index={2} type={'number'} label={'Bottom'} />
        <ToolbarItem<TextProps> propKey={'margin'} zeroValue={0} index={3} type={'number'} label={'Left'} />
      </ToolbarSection>

      <ToolbarSection<TextProps>
        title={'Appearance'}
        props={['color']}
        summary={({ color }) => {
          return <div style={{ color: color }}>{`Text ${color}`}</div>;
        }}
      >
        <ToolbarItem<TextProps> propKey={'color'} type={'color'} label={'Color'} />
      </ToolbarSection>

      <AppButton
        variant={'button'}
        colorSchema={'secondary'}
        text={'Bold'}
        onClick={(evt) => {
          evt.preventDefault();
          document.execCommand('bold', false);
        }}
      />

      <AppButton
        variant={'button'}
        colorSchema={'secondary'}
        text={'Italic'}
        onClick={(evt) => {
          evt.preventDefault();
          document.execCommand('italic', false);
        }}
      />
      {isNullOrEmpty(selectedLink) ? (
        <AppButton
          variant={'button'}
          colorSchema={'secondary'}
          text={t('FaqEditor.TextEditing.Link.Buttons.AddLink')}
          onClick={addLinkHandler}
        />
      ) : (
        <AppButton
          variant={'button'}
          colorSchema={'secondary'}
          text={t('FaqEditor.TextEditing.Link.Buttons.EditLink')}
          onClick={editLinkHandler}
          disabled={!isSelectionHasOneLink}
        />
      )}
      {linkModal.modal}
    </>
  );
};
