import { createContext } from 'use-context-selector';
import { EditableQuestionContextType, QuestionSchemeEditorContextType } from './questionSchemeUIEditor';

export const defaultQuestionSchemeEditorValues: QuestionSchemeEditorContextType = {
  questionScheme: {
    version: '0.0.1',
    description: '',
    legend: '',
    questions: [],
  },
  selectedQuestion: null,
  errors: {},
};

export const defaultEditableQuestionValues: EditableQuestionContextType = {
  editableQuestion: null,
  editableOptionId: null,
};

export const QuestionSchemeEditorContext = createContext<QuestionSchemeEditorContextType>(
  defaultQuestionSchemeEditorValues,
);
export const EditableQuestionContext = createContext<EditableQuestionContextType>(defaultEditableQuestionValues);
