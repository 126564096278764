/** Defines how to render conditional field. */
export enum ConditionalFieldBehaviorEnum {
  /** Nothing will happen. Just rendered together with parent field. */
  None = 0,
  /** All child components will be rendered, but disabled until select right answer */
  Disabling = 1,
  /** Child components will be hidden until right answer selected */
  Visibility = 2,
}

export interface BaseControlSettings {
  size: string;
  isEditable?: boolean;
  hideLabel?: boolean;
  isBold?: boolean;
  hasSkipCheckBox?: boolean;
  skipCheckBoxText?: string;
  isDisabled?: boolean;
  conditionalStateBehaviour?: ConditionalFieldBehaviorEnum;
  isDisableWhenEditing?: boolean;
  ignoreInitialValuesFromPreviousForm?: boolean;
  ignoreInFillingProgress?: boolean;
}

export interface FormSettings {
  FormTypeForInitialAnswers?: string;
  WithInitialAnswers: boolean;
  isPatientCreationForm: boolean;
}

export enum UIEditorBlockWidth {
  Sixth = '16.6%',
  Fifth = '20%',
  Quarter = '25%',
  Third = '33%',
  Half = '50%',
  ThreeQuarters = '75%',
  Stretch = '100%',
}

export const defaultToolSettings: BaseControlSettings = {
  size: UIEditorBlockWidth.Stretch,
  isEditable: true,
};

export const defaultFormSettings: FormSettings = {
  WithInitialAnswers: false,
  FormTypeForInitialAnswers: undefined,
  isPatientCreationForm: false,
};
