import React from 'react';
import { IFormResultDto, IFormResultVersionDto } from '../../../services/api/api-client';

export type NoteContextType = {
  formResult: Omit<IFormResultDto, 'versions'>;
  currentResultVersion: IFormResultVersionDto;
  mode: 'overview' | 'section';
  noteDateDataKey: string;
  patientUniqueId: string;
};
export const NoteContext = React.createContext<NoteContextType>(undefined as any);
