/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { AppButton } from 'uikit/buttons';
import {
  ApiException,
  FieldEditReason,
  FileParameter,
  FormResultDto,
  FormSkipReason,
  IFormDto,
  ProcessFormResultDto,
  UpdateFormResultDto,
} from '../../../services/api/api-client';
import { logger } from 'src/application/logging/logging';
import { useQueryClient } from '@tanstack/react-query';
import { AppAttachmentValueToFileParameter } from 'src/helpers/file-helper';
import { useModal } from 'src/application/hooks/useModal';
import { TypographyStyles } from 'src/styles';
import { ReactComponent as CheckIcon } from 'assets/img/common/checked_16.svg';
import styles, { formModalContainerWithNavbar } from '../uiEditor/uiEditor.module.scss';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { useNotEditableFieldModal } from './notEditableFieldsModal/notEditableFieldsModal';
import { useContextSelector } from 'use-context-selector';
import clsx from 'clsx';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { CloseConfirmationModal } from './closeConfirmationModal';
import { getMultiValuedTagFilters } from '../../../features/patientTags/tagsHelper';
import { useTagsModal } from './tagsModal/TagsModal.component';
import { FormFillContext } from '../uiEditor/provider/formFill.context';
import { FormModalComponentProps } from '../uiEditor/uiEditor';
import { findNodeIdsWithDataKey, isValid, pageStacksIsEqual } from '../uiEditor/uiEditor-helper';
import { useReasonModal } from './reasonModal/reasonModal.component';
import { UiEditorComponent } from '../uiEditor/uiEditor.component';
import { SerializedNodes } from '@craftjs/core';
import { AppButtonWithMenu } from 'uikit/buttons/buttonWithMenu/buttonWithMenu.component';
import { DropDownMenuOption } from 'uikit/dropDownMenu/dropDownMenu';
import { useFormResultSkipping } from './skipFormResultModal/useFormResultSkipping';
import { ReactComponent as ThinCrossIcon } from '../../../assets/img/common/thin_cross_16.svg';
import { useTranslation } from 'react-i18next';
import {
  getNotificationBatchesQueryKey,
  getNotificationCountsQueryKey,
  getNotificationsQueryKey,
} from 'src/services/api/api-client/NotificationQuery';
import { getPatientsQueryKey, getPatientStudyCountersQueryKey } from '../../../services/api/api-client/PatientQuery';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { Loading } from 'uikit/suspense/Loading';
import { getPatientRouteProgressQueryKey } from '../../../services/api/api-client/StudyRoutesQuery';
import { useStaffProfile, useStudy } from 'src/helpers/hooks/useStudy';
import { FormFillNavigationContext } from '../uiEditor/formFillingNavigation/FormFillingNavigationContext';
import { useGetNavigationTree } from '../uiEditor/formFillingNavigation/formFillingNavigationBar/useGetNavigationTree';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
import { useGetFieldChanges } from './tagsModal/useGetFieldChanges';
import { patchResults, processResults } from 'src/services/api/api-client/FormsClient';

export const FormModalComponent: FC<FormModalComponentProps> = (props) => {
  const { id: studyId, hasEcrf, groups } = useStudy() ?? {};
  const { profile } = useStaffProfile();

  const client = useQueryClient();
  const { t } = useScopedTranslation('Forms');
  const commonLocalizer = useCommonLocalization();

  const confirmationModal = useModal();
  const reasonModal = useReasonModal();
  const notEditableFieldsModal = useNotEditableFieldModal();
  const tagsModal = useTagsModal(profile?.tagFilters ?? null);
  const formResultSkipping = useFormResultSkipping();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [commonError, setCommonError] = useState<string | undefined>();

  const {
    mode,
    isSubmitting,
    formConfig,
    formResultVersion,
    values: dataBlockValues,
    notEditableValues,
    dataBlockValidation,
    skippedFieldsData,
    pageStacks,
    currentPageStack,
    patient,
    setIsSubmitting,
    resetState,
    goToNextPage,
    goToPrevPage,
    navigateToBlock,
    triggerValidation,
    setDataBlockValidation,
  } = useContextSelector(FormFillContext, (x) => x);

  const { navBarIsOpen, setNavBarIsOpen } = useContextSelector(FormFillNavigationContext, (x) => x);
  const navigationTree = useGetNavigationTree();

  // This effect clear common error when any validation changes
  useEffect(() => {
    setCommonError(undefined);
  }, [dataBlockValidation]);

  const { anyChanges, anyEditedFields } = useGetFieldChanges();

  const checkTouchedFields = useCallback(() => {
    if (anyChanges && mode !== 'BuildingPreview') {
      return confirmationModal.openModal();
    }

    props.modal.onHide();
  }, [anyChanges, confirmationModal, mode, props.modal]);

  const files = useMemo(() => {
    const getFilesDataKeys = (layout: SerializedNodes): string[] => {
      const keys = [];
      for (const key of Object.keys(layout)) {
        // @ts-ignore
        if (layout[key].type.resolvedName === 'Files') {
          keys.push(layout[key].props.dataKey);
        }
      }

      return keys;
    };

    const filesDataKeys = getFilesDataKeys(formConfig!.layoutSchema);
    const fileNamesList: string[] = [];

    return filesDataKeys
      .flatMap((key) => {
        if (dataBlockValues && dataBlockValues[key]) {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          return dataBlockValues[key].map((f: AppAttachmentValue) => {
            if (fileNamesList.includes(f.fileName)) {
              f.fileName = `${key.replace(/[/\\ ?%*:|"<>]/g, '_')}_${f.fileName}`;
            }

            if (f.file && !f.id) {
              fileNamesList.push(f.fileName);
              return AppAttachmentValueToFileParameter(f);
            }

            return null;
          }) as FileParameter[];
        }

        return null;
      })
      .filter(Boolean) as FileParameter[];
  }, [dataBlockValues, formConfig]);

  const getPatientTags = useCallback(async () => {
    const multiValuedFilters = profile?.tagFilters && getMultiValuedTagFilters(profile?.tagFilters);
    const doctorHasMultiValuedFilters = multiValuedFilters && Object.keys(multiValuedFilters).length > 0;
    const tags =
      formConfig?.isPatientCreationForm && mode === 'Create' && doctorHasMultiValuedFilters
        ? await tagsModal.open()
        : undefined;

    return tags;
  }, [formConfig?.isPatientCreationForm, mode, profile?.tagFilters, tagsModal]);

  const sendResult = useCallback(
    async (asSubmitted: boolean, fieldEditReasons?: { [key: string]: FieldEditReason }) => {
      if (!formConfig) throw new Error('formConfig is not provided');

      setIsSubmitting?.(true);

      try {
        let newFormResult: FormResultDto;

        if (formResultVersion?.formResultId) {
          newFormResult = await patchResults(
            true,
            files,
            new UpdateFormResultDto({
              studyId: studyId!,
              patientId: props.patientId!,
              formResultId: formResultVersion?.formResultId,
              values: JSON.stringify(dataBlockValues),
              fieldSkipReasons: skippedFieldsData || {},
              fieldEditReasons: fieldEditReasons || {},
              asSubmitted: asSubmitted,
            }),
          );
        } else {
          const patientTags = await getPatientTags();
          newFormResult = await processResults(
            true,
            files,
            new ProcessFormResultDto({
              studyId: studyId!,
              patientId: props.patientId!,
              formId: formConfig.id,
              values: JSON.stringify(dataBlockValues),
              stepName: props.stepName,
              tags: patientTags!,
              fieldSkipReasons: skippedFieldsData || {},
              asSubmitted: asSubmitted,
            }),
          );
        }

        await Promise.all([
          client.invalidateQueries(getPatientRouteProgressQueryKey(props.patientId!)),
          client.invalidateQueries(getPatientStudyCountersQueryKey(studyId!)),
          client.invalidateQueries(getPatientsQueryKey()),
          client.invalidateQueries(getNotificationCountsQueryKey(studyId!)),
          client.invalidateQueries(getNotificationBatchesQueryKey(undefined, [studyId!])),
          client.invalidateQueries(getNotificationsQueryKey(undefined, studyId)),
        ]);

        // For submitted animation
        setIsSubmitted(true);
        setTimeout(async () => {
          await props.onSubmitted?.(newFormResult);

          props.modal.onHide();

          setIsSubmitted(false);
          setIsSubmitting?.(false);
        }, 1700);
      } catch (error: any) {
        setIsSubmitting?.(false);

        const isApiException = ApiException.isApiException(error);
        if (isApiException) {
          logger().error(error.message, error);
          setCommonError(t('CommonError'));
          return;
        }

        if (error.errors && !Object.keys(error.errors).length) {
          logger().error(t('CommonError'), error);
          setCommonError(t('CommonError'));
          return;
        }

        for (const dataKey of Object.keys(error.errors)) {
          const nodeIds = findNodeIdsWithDataKey(formConfig?.layoutSchema, dataKey);
          if (!nodeIds.length) {
            setCommonError(t('CommonError'));
            continue;
          }

          for (const nodeId of nodeIds) {
            if (!dataBlockValidation || !Object.keys(dataBlockValidation!).includes(nodeId)) continue;

            setDataBlockValidation?.({
              [nodeId]: {
                validationState: 'Fail',
                text: commonLocalizer(
                  `ServerErrors.${error.errors[dataKey][0]}` as LocalizedResourceDictionaryKeys,
                ) as string,
              },
            });
          }

          const firstErrorBlock = _.first(nodeIds);
          navigateToBlock?.(firstErrorBlock);
        }
      }
    },
    [
      formConfig,
      setIsSubmitting,
      formResultVersion?.formResultId,
      client,
      props,
      studyId,
      files,
      dataBlockValues,
      skippedFieldsData,
      getPatientTags,
      t,
      navigateToBlock,
      dataBlockValidation,
      setDataBlockValidation,
      commonLocalizer,
    ],
  );

  const skipHandler = useCallback(async () => {
    if (!formConfig) throw new Error('formConfig is not provided');

    const skippingResult = await formResultSkipping.start({
      formType: formConfig.type,
      stepName: props.stepName ?? null,
      formResultId: formResultVersion?.formResultId,
      patientId: props.patientId!,
      formConfigId: formConfig.id,
    });

    if (skippingResult.$type === 'Canceled') return;

    props.modal.onHide();
  }, [formConfig, formResultSkipping, formResultVersion?.formResultId, props.modal, props.patientId, props.stepName]);

  const saveHandler = useCallback(
    ({ asSubmitted }: { asSubmitted: boolean }) =>
      async () => {
        confirmationModal.closeModal();
        setCommonError(undefined);

        const validationResult = triggerValidation?.({ ignoreIsRequiredRule: hasEcrf && !asSubmitted, eCRF: hasEcrf });
        if (validationResult && !isValid(validationResult)) {
          const firstFailedBlock = _.first(
            Object.keys(validationResult).filter((key) => validationResult[key].validationState === 'Fail'),
          );
          navigateToBlock?.(firstFailedBlock);
          return;
        }

        const reasonModalResult = mode === 'Edit' && anyEditedFields ? await reasonModal.open() : null;
        if (notEditableValues!.length > 0) {
          await notEditableFieldsModal.open();
        }

        await sendResult(asSubmitted, reasonModalResult?.fieldEditReasons);
      },
    [
      confirmationModal,
      triggerValidation,
      hasEcrf,
      mode,
      reasonModal,
      notEditableValues,
      sendResult,
      navigateToBlock,
      notEditableFieldsModal,
      anyEditedFields,
    ],
  );

  const rightButton = useMemo(() => {
    const isLastPage = pageStacksIsEqual(_.last(pageStacks), currentPageStack);
    if (!isLastPage && !isSubmitting && !confirmationModal.visible)
      return (
        <AppButton
          text={commonLocalizer('Common_Next')}
          variant={'button'}
          colorSchema={'primary'}
          onClick={goToNextPage}
          disabled={isSubmitting || isSubmitted}
          isLoading={isSubmitting}
          hasLoaded={isSubmitted}
        />
      );

    const isNoteForm = groups?.find((x) => x.id === patient?.groupId)?.formConfigIdForNotes === formConfig?.id;

    if (hasEcrf && !isNoteForm) {
      if (props.editAsSubmitted) {
        return (
          <AppButton
            testId={'submit-button'}
            text={t('FillInModal.Submit')}
            variant={'button'}
            colorSchema={'primary'}
            onClick={saveHandler({ asSubmitted: true })}
            disabled={isSubmitting || isSubmitted}
            isLoading={isSubmitting}
            hasLoaded={isSubmitted}
          />
        );
      } else {
        const options = [
          {
            key: 0,
            icon: <CheckIcon />,
            text: t('FillInModal.SaveAndSubmit'),
            action: saveHandler({ asSubmitted: true }),
          },
        ] as DropDownMenuOption[];

        if (formConfig?.canBeSkipped)
          options.push({
            key: 1,
            icon: <ThinCrossIcon />,
            text: t('FillInModal.Skip'),
            action: skipHandler,
          });

        return (
          <AppButtonWithMenu
            testIdPrefix={'save-as'}
            options={options}
            buttonText={t('FillInModal.SaveAsDraft')}
            onClick={saveHandler({ asSubmitted: false })}
            disabled={isSubmitting || isSubmitted}
            isLoading={isSubmitting}
            hasLoaded={isSubmitted}
            mainButtonDisabled={!anyChanges}
          />
        );
      }
    }

    return (
      <AppButton
        text={t('Controls.SaveButton')}
        variant={'button'}
        colorSchema={'primary'}
        onClick={saveHandler({ asSubmitted: false })}
        disabled={isSubmitting || isSubmitted || !anyChanges}
        isLoading={isSubmitting}
        hasLoaded={isSubmitted}
      />
    );
  }, [
    anyChanges,
    commonLocalizer,
    confirmationModal.visible,
    currentPageStack,
    formConfig?.canBeSkipped,
    formConfig?.id,
    goToNextPage,
    groups,
    hasEcrf,
    isSubmitted,
    isSubmitting,
    pageStacks,
    patient?.groupId,
    props.editAsSubmitted,
    saveHandler,
    skipHandler,
    t,
  ]);

  return (
    <>
      <DialogModal
        {...props.modal}
        fullHeight
        footer={{
          errors: commonError,
          scoreSection: props.formScoreComponent,
          legendSection: !formConfig?.legend?.length ? undefined : <LegendBlock formConfig={formConfig} />,
          customButtonBlock: props.footer,
          navigationButton: !navigationTree.length
            ? undefined
            : {
                text: navBarIsOpen ? t('FillInModal.HideNavBar') : t('FillInModal.ShowNavBar'),
                onClick: () => setNavBarIsOpen?.((x) => !x),
                iconFirst: !navBarIsOpen,
                className: styles.openNavBarButton,
              },
          leftButton:
            mode === 'Overview' || mode === 'BuildingPreview'
              ? undefined
              : pageStacksIsEqual(_.first(pageStacks), currentPageStack)
              ? {
                  text: commonLocalizer('Common_Cancel'),
                  onClick: checkTouchedFields,
                  disabled: isSubmitting || isSubmitted,
                }
              : {
                  text: commonLocalizer('Common_Back'),
                  onClick: goToPrevPage,
                  disabled: isSubmitting || isSubmitted,
                },
          customRightButton: mode === 'Overview' || mode === 'BuildingPreview' ? undefined : rightButton,
        }}
        onHide={() => !isSubmitting && !isSubmitted && checkTouchedFields()}
        onDismissed={resetState}
        isDisabled={isSubmitting || isSubmitted}
        containerClassName={clsx(styles.formModalContainer, {
          [formModalContainerWithNavbar]: navBarIsOpen,
          [styles.overviewModalContainer]: mode === 'Overview',
        })}
        bodyClassName={styles.formModalBody}
      >
        <Loading loading={!!props.isLoading} containerClassName={styles.loader}>
          {props.navigationMenu}
          {formResultVersion?.isSkipped && mode === 'Overview' ? (
            <SkippedFormBody
              skipReason={formResultVersion.skipReason!}
              skipReasonDetails={formResultVersion.skipReasonDetails!}
            />
          ) : (
            <div className={styles.data}>
              <UiEditorComponent isInDesignerMode={false} config={formConfig?.layoutSchema} />
            </div>
          )}
        </Loading>
      </DialogModal>

      {tagsModal.element}
      {reasonModal.element}
      {notEditableFieldsModal.element(notEditableValues!, dataBlockValues)}
      {formResultSkipping.element}
      <CloseConfirmationModal modal={confirmationModal} saveButton={rightButton} onDiscard={props.modal.onHide} />
    </>
  );
};

const LegendBlock: FC<{ formConfig: IFormDto | undefined }> = ({ formConfig }) => {
  return (
    <div className={clsx(TypographyStyles.plainText12, styles.legendContainer)}>
      {formConfig?.legend?.map((text) => (
        <div key={text}>{text}</div>
      ))}
    </div>
  );
};

const SkippedFormBody: FC<{ skipReason: FormSkipReason; skipReasonDetails: string }> = ({
  skipReason,
  skipReasonDetails,
}) => {
  const { t } = useTranslation();

  const translatedSkipReason =
    skipReason === FormSkipReason.NotApplicable
      ? t('Forms.FormSkipReason.NotApplicable')
      : skipReason === FormSkipReason.Missed
      ? t('Forms.FormSkipReason.Missed')
      : undefined;

  return (
    <div>
      <OverviewField label={t('Forms.Overview.SkippedFormBody.SkipReason')} value={translatedSkipReason} />
      <OverviewField label={t('Forms.Overview.SkippedFormBody.SkipReasonDetails')} value={skipReasonDetails} />
    </div>
  );
};
