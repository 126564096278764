﻿import { useModal } from '../../application/hooks/useModal';
import React, { FC, useCallback, useId, useMemo, useRef } from 'react';
import { FormProvider, useController, useForm, useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FileDto, IParticipantDto, UpdateParticipantRequestDto } from '../../services/api/api-client';
import { updateParticipant } from '../../services/api/api-client/ParticipantsClient';
import { handleSubmitFormError } from '../../application/error-handling/useErrorHandler';
import { getParticipantsQueryKey } from '../../services/api/api-client/ParticipantsQuery';
import { AppInputError } from 'uikit/wrappers';
import { ValidationFormRules } from '../../helpers/validation-helpers';
import { AppAttachmentsField, AppDropdownField, AppTextField } from 'uikit/fields';
import { convertDateToString, toDateOrNull } from '../../helpers/date-helpers';
import { addDays, subDays } from 'date-fns';
import { AppCheckboxInput } from 'uikit/inputs';
import { Deferred } from '../../helpers/Deferred';
import styles from './useParticipantEditing.module.scss';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { participantFunctions, participantTasks } from './participantFieldOptions';
import { AppAttachmentValue } from 'uikit/fields/attachments/attachmentsField/appAttachmentsField';
import { FileTypes } from '../../helpers/fileValidation-helpers';
import { AppAttachmentValueToFileParameter } from '../../helpers/file-helper';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
import { ConfirmResult, useConfirmationModal } from 'src/components/dialogModal/useConfirmationModal';

type FormState = {
  participantId: string;
  fullName: string;
  email: string;
  tasks: string[];
  tasksComment?: string;
  function: string;
  functionComment?: string;
  onboardedOn: Date;
  offboardedOn: Date | null;
  attachments: AppAttachmentValue[];
  existedAttachments: FileDto[];
  confirmed: boolean;
};

export const useParticipantEditing = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const modal = useModal('CLOSED');
  const deferred = useRef<Deferred<IParticipantDto | undefined>>();
  const onHideConfirmationModal = useConfirmationModal({ containerClassName: styles.leaveModal });

  const form = useForm<FormState>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const submit = form.handleSubmit(async (values: FormState) => {
    const newAttachments = values.attachments.filter((x) => x.file !== null).map(AppAttachmentValueToFileParameter);
    const attachmentIdsToDelete = values.existedAttachments
      .filter((x) => !values.attachments.some((y) => y.id === x.fileId))
      .map((x) => x.fileId);

    const dto = new UpdateParticipantRequestDto({
      fullName: values.fullName,
      email: values.email,
      tasks: values.tasks,
      tasksComment: values.tasksComment,
      function: values.function,
      functionComment: values.functionComment,
      onboardedOn: values.onboardedOn,
      offboardedOn: values.offboardedOn,
      attachmentIdsToDelete: attachmentIdsToDelete,
    });

    let updatedParticipant;
    try {
      updatedParticipant = await updateParticipant(values.participantId, newAttachments, dto);
    } catch (e) {
      handleSubmitFormError(e, form.setError);
      return;
    }

    await queryClient.invalidateQueries(getParticipantsQueryKey(updatedParticipant.studyId));
    deferred.current?.resolve(updatedParticipant);
    modal.closeModal();
  });

  const onModalHide = useCallback(async () => {
    if (form.formState.isDirty) {
      const result = await onHideConfirmationModal.open({
        title: t('Forms.ConfirmationModal.Header'),
        text: t('Forms.ConfirmationModal.MainText'),
        cancelButtonColorSchema: 'destroy',
        cancelButtonText: t('Forms.ConfirmationModal.DiscardBtn'),
        okButtonColorSchema: 'primary',
        okButtonText: t('Forms.ConfirmationModal.Save'),
      });

      if (result === ConfirmResult.Closed) return;
      if (result === ConfirmResult.Confirm) {
        await submit();
        return;
      }
    }

    modal.closeModal();
    deferred.current?.resolve(undefined);
  }, [form.formState.isDirty, modal, onHideConfirmationModal, submit, t]);

  return {
    element: form.watch('participantId') && (
      <>
        <FormProvider {...form}>
          <ParticipantEditingModal onHide={onModalHide} onCreate={submit} visible={modal.visible} />
        </FormProvider>
        {onHideConfirmationModal.modal}
      </>
    ),
    // eslint-disable-next-line @typescript-eslint/no-shadow
    start: (participant: IParticipantDto) => {
      form.reset({
        participantId: participant.id,
        email: participant.email,
        tasks: participant.tasks,
        function: participant.function,
        fullName: participant.fullName,
        onboardedOn: participant.onboardedOn,
        offboardedOn: participant.offboardedOn,
        attachments: participant.attachments.map((x) => ({ id: x.fileId, fileName: x.fileName, file: null })),
        existedAttachments: participant.attachments,
        confirmed: false,
        tasksComment: participant.tasksComment ?? '',
        functionComment: participant.functionComment ?? '',
      });
      deferred.current = new Deferred();
      modal.openModal();
      return deferred.current.promise;
    },
  };
};

const ParticipantEditingModal: FC<{
  onHide: () => void;
  onCreate: () => void;
  visible: boolean;
}> = (props) => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const formId = useId();
  const isSubmitting = form.formState.isSubmitting;
  const error = form.formState.errors.root;

  const functionFieldValue = form.watch('function');
  const tasksFieldValue = form.watch('tasks');

  return (
    <DialogModal
      bodyClassName={styles.modalBody}
      visible={props.visible}
      onHide={props.onHide}
      title={t('Participants.Updating.Modal.Header')}
      footer={{
        errors: error ? t('Participants.Updating.Modal.Error') : undefined,
        leftButton: {
          text: t('Participants.Updating.Modal.Cancel'),
          onClick: props.onHide,
          disabled: isSubmitting,
        },
        rightButton: {
          text: t('Participants.Updating.Modal.Save'),
          type: 'button',
          disabled: isSubmitting || !form.formState.isDirty,
          isLoading: isSubmitting,
          onClick: props.onCreate,
        },
      }}
    >
      <form id={`participantEditing-${formId}`} className={styles.container}>
        <FullNameField />
        <EmailField />
        <FunctionField />
        {functionFieldValue === 'Sonstiges' && <FunctionCommentField />}
        <TasksField />
        {tasksFieldValue.includes('Sonstiges') && <TasksCommentField />}
        <OnboardedField />
        <AttachmentsField />
        <OffboardedField />
        <ConfirmCheckbox />
      </form>
    </DialogModal>
  );
};

const FullNameField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'fullName',
    rules: {
      ...ValidationFormRules().requiredRule,
    },
  });

  return (
    <AppTextField
      labelProps={{ text: t('Participants.Updating.Modal.Fields.FullName.Label'), isBold: true }}
      placeholder={t('Participants.Updating.Modal.Fields.FullName.Placeholder')}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      errorProps={{ errors: fieldState.error?.message }}
    />
  );
};

const EmailField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'email',
    rules: {
      ...ValidationFormRules().requiredRule,
    },
  });

  return (
    <AppTextField
      labelProps={{ text: t('Participants.Updating.Modal.Fields.Email.Label'), isBold: true }}
      placeholder={t('Participants.Updating.Modal.Fields.Email.Placeholder')}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      errorProps={{ errors: fieldState.error?.message }}
    />
  );
};

const TasksField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'tasks',
    rules: {
      ...ValidationFormRules().requiredRule,
    },
  });

  const options = useMemo(() => {
    return participantTasks.map<DropdownOption>((x) => ({
      text: x,
      key: x,
    }));
  }, []);

  const selectedOptions = options.filter((x) => field.value.some((y) => x.key === y));

  return (
    <AppDropdownField
      isMultiple
      labelProps={{ text: t('Participants.Updating.Modal.Fields.Tasks.Label'), isBold: true }}
      placeholder={t('Participants.Updating.Modal.Fields.Tasks.Placeholder')}
      options={options}
      value={selectedOptions}
      onChange={(x) => field.onChange(x?.map((y) => y.key) ?? [])}
      errorProps={{
        errors: fieldState.error?.message,
      }}
    />
  );
};

const FunctionField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'function',
    rules: {
      ...ValidationFormRules().requiredRule,
    },
  });

  const options = useMemo(() => {
    return participantFunctions.map<DropdownOption>((x) => ({
      text: x,
      key: x,
    }));
  }, []);

  const selectedOptions = options.filter((x) => field.value === x.key);

  return (
    <AppDropdownField
      isRequired
      labelProps={{ text: t('Participants.Updating.Modal.Fields.Role.Label'), isBold: true }}
      placeholder={t('Participants.Updating.Modal.Fields.Role.Placeholder')}
      options={options}
      value={selectedOptions.find((x) => x.key === field.value)}
      onChange={(x) => field.onChange(x?.key ?? '')}
      errorProps={{
        errors: fieldState.error?.message,
      }}
    />
  );
};

const OnboardedField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'onboardedOn',
    rules: {
      ...ValidationFormRules().requiredRule,
    },
  });

  const offboardedOn = form.watch('offboardedOn') ?? undefined;
  const max = offboardedOn && subDays(offboardedOn, 1);

  return (
    <AppTextField
      type={'date'}
      labelProps={{ text: t('Participants.Updating.Modal.Fields.OnboardedOn.Label'), isBold: true }}
      onChange={(e) => field.onChange(toDateOrNull(e.target.value))}
      onBlur={field.onBlur}
      value={convertDateToString(field.value)}
      errorProps={{ errors: fieldState.error?.message }}
      max={convertDateToString(max)}
    />
  );
};

const OffboardedField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'offboardedOn',
  });

  const onboardedOn = form.watch('onboardedOn') ?? undefined;
  const min = onboardedOn && addDays(onboardedOn, 1);

  return (
    <AppTextField
      type={'date'}
      labelProps={{ text: t('Participants.Updating.Modal.Fields.OffboardedOn.Label'), isBold: true }}
      onChange={(e) => field.onChange(toDateOrNull(e.target.value))}
      onBlur={field.onBlur}
      value={convertDateToString(field.value)}
      errorProps={{ errors: fieldState.error?.message }}
      min={convertDateToString(min)}
    />
  );
};

const AttachmentsField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'attachments',
    rules: {
      validate: ValidationFormRules().filesValidate,
    },
  });

  return (
    <AppAttachmentsField
      label={t('Participants.Updating.Modal.Fields.Attachments.Label')}
      isBold={true}
      onChange={field.onChange}
      value={field.value}
      fileTypes={FileTypes}
      multiple
      errorText={fieldState.error?.message}
    />
  );
};

const ConfirmCheckbox: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'confirmed',
    rules: {
      validate: (value) => value || t('Participants.Updating.Modal.Fields.Confirm.Error'),
    },
  });

  return (
    <AppInputError errors={fieldState.error?.message}>
      <AppCheckboxInput
        checked={field.value}
        label={t('Participants.Updating.Modal.Fields.Confirm.Label')}
        onChange={field.onChange}
        onBlur={field.onBlur}
        disabled={!form.formState.isDirty}
      />
    </AppInputError>
  );
};

const TasksCommentField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'tasksComment',
    rules: {
      ...ValidationFormRules().requiredRule,
    },
  });

  return (
    <AppTextField
      type={'text-area'}
      labelProps={{ text: t('Participants.Creating.Modal.Fields.TasksComment.Label'), isBold: true }}
      placeholder={t('Participants.Creating.Modal.Fields.TasksComment.Placeholder')}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      errorProps={{ errors: fieldState.error?.message }}
    />
  );
};

const FunctionCommentField: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<FormState>();
  const { field, fieldState } = useController({
    control: form.control,
    name: 'functionComment',
    rules: {
      ...ValidationFormRules().requiredRule,
    },
  });

  return (
    <AppTextField
      type={'text-area'}
      labelProps={{ text: t('Participants.Creating.Modal.Fields.FunctionComment.Label'), isBold: true }}
      placeholder={t('Participants.Creating.Modal.Fields.FunctionComment.Placeholder')}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
      errorProps={{ errors: fieldState.error?.message }}
    />
  );
};
