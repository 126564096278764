import React from 'react';
import { useNode } from '@craftjs/core';
import { strArrToDropdownOptions } from '../../../uikit/inputs/dropdown/dropdown-helper';
import { AppTextInput } from '../../../uikit/inputs/text/appTextInput.component';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs/dropdown/appDropDownWithSuggestion';

export type ToolbarItemProps<T> = {
  prefix?: string;
  label?: string;
  propKey: keyof T;
  index?: number;
  zeroValue?: number | string | undefined;
  onChange?: (value: any) => any;
} & (
  | {
      type: 'select';
      options: string[];
    }
  | {
      type: 'text' | 'number' | 'checkbox' | 'color';
      options?: never;
    }
);

export const ToolbarItem = <T,>({
  propKey,
  type,
  onChange,
  index,
  options,
  zeroValue,
  ...props
}: ToolbarItemProps<T>) => {
  const {
    actions: { setProp },
    propValue,
  } = useNode((node) => ({
    propValue: (node.data.props as T)[propKey],
  }));

  const value = Array.isArray(propValue) ? propValue[index!] : propValue;

  return (
    <div>
      {type === 'text' || type === 'number' || type === 'color' ? (
        <AppTextInput
          type={type}
          value={value}
          onChange={(v) => {
            setProp((p: T) => {
              if (Array.isArray(propValue)) {
                p[propKey][index!] = onChange ? onChange(v.target.value || zeroValue) : v.target.value || zeroValue;
              } else {
                p[propKey] = onChange ? onChange(v.target.value || zeroValue) : v.target.value || zeroValue;
              }
            }, 500);
          }}
          {...props}
        />
      ) : type === 'select' && options ? (
        <AppDropDownWithSuggestionInput
          options={strArrToDropdownOptions(options)}
          value={strArrToDropdownOptions(options).find((x) => x.key === value)}
          onChange={(v) => {
            setProp((p: T) => {
              if (Array.isArray(propValue)) {
                p[propKey][index!] = onChange ? onChange(v?.key) : v?.key;
              } else {
                p[propKey] = onChange ? onChange(v?.key) : v?.key;
              }
            }, 500);
          }}
          {...props}
        />
      ) : type === 'checkbox' ? (
        <div>checkbox</div>
      ) : null}
    </div>
  );
};
