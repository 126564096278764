import { ApplicationState } from '../../features/app/app-state';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { FaqState } from '../../features/faq/faq-state';

export interface RootState {
  app: ApplicationState;
  faq: FaqState;
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useFaqSelector: TypedUseSelectorHook<RootState> = useSelector;
