﻿/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from 'react-i18next';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import {
  IFieldGroupContainer,
  IFieldGroupContainerProps,
} from '../../../../features/forms/base/controls/containers/FieldGroupContainer';
import { Container } from './Container';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { SettingsInput } from '../../toolbox/settingsPanel/settingsInputs/settingsInput';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { FieldGroup, FieldGroupLevel } from 'uikit/fields/fieldGroup/FieldGroup';
import { OverviewFieldGroup } from 'uikit/fields/overview/OverviewFieldGroup';
import { AppDropDownWithSuggestionInput } from 'uikit/inputs';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';

export const FieldGroupContainer: IFieldGroupContainer = (props) => {
  const {
    connectors: { connect, drag },
    childrenIds,
    linkedNodes,
    id: nodeId,
    store,
  } = useNode((state) => ({
    selected: state.events.selected,
    childrenIds: state.data.nodes,
    linkedNodes: state.data.linkedNodes,
  }));

  const {
    actions: { setProp },
    builderMode,
  } = useEditor((state) => ({
    builderMode: state.options.enabled,
  }));

  const { title, hideTitle, isDisabled, level } = {
    ...FieldGroupContainerProps,
    ...props,
  };

  useEffect(() => {
    if (builderMode) return;

    [...Object.values(linkedNodes), ...childrenIds].forEach((id) => {
      setProp(id, (p: any) => (p.isDisabled = isDisabled));
    });
  }, [builderMode, childrenIds, isDisabled, linkedNodes, setProp]);

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const isSubmitting = useContextSelector(FormFillContext, (x) => x.isSubmitting);

  const editableComponent = useMemo(
    () => (
      <FieldGroup
        ref={(ref: HTMLDivElement) => builderMode && connect(drag(ref))}
        titleRef={(ref: HTMLDivElement | null) => {
          if (!ref || builderMode) return;
          store.actions.setDOM(nodeId, ref);
        }}
        disabled={isSubmitting || isDisabled}
        draggable={builderMode}
        title={title ?? ''}
        hideTitle={hideTitle}
        level={level}
      >
        <Element id="drop" canvas is={Container} flexBoxGap={12} isDisabled={isDisabled} />
      </FieldGroup>
    ),
    [builderMode, connect, drag, hideTitle, isDisabled, isSubmitting, level, nodeId, store.actions, title],
  );

  const viewComponent = (
    <OverviewFieldGroup
      titleRef={(ref: HTMLDivElement | null) => {
        if (!ref || builderMode) return;
        store.actions.setDOM(nodeId, ref);
      }}
      title={title || ''}
      hideTitle={hideTitle}
      level={level}
    >
      <Element id="drop" canvas is={Container} isDisabled={isDisabled} />
    </OverviewFieldGroup>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

export const FieldGroupContainerSettings = () => {
  const { t } = useTranslation('dev');
  const { props, actions } = useNode((node) => ({
    props: node.data.props as IFieldGroupContainerProps,
    id: node.id,
  }));
  const { setProp } = actions;

  const levelOptions = useFieldGroupLevelOptions();

  return (
    <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')}>
      <ControlsGroup
        collapsible
        expanded={!!props.title}
        header={t('StudySettingsPage.FormBuilder.settings.title')}
        flexible
        onOpen={() => {
          setProp((prop: IFieldGroupContainerProps) => {
            prop.title = 'Title';
            prop.hideTitle = false;
          }, 500);
        }}
        onClose={() => {
          setProp((prop: IFieldGroupContainerProps) => {
            prop.title = undefined;
            prop.hideTitle = true;
          }, 500);
        }}
      >
        <SettingsInput
          multiple
          value={props.title || ''}
          onChange={(e) => {
            setProp((setProps: IFieldGroupContainerProps) => {
              setProps.title = e.target?.value ? e.target.value : undefined;
            }, 500);
          }}
        />
      </ControlsGroup>

      <ControlsGroup header={t('StudySettingsPage.FormBuilder.settings.fieldGroup.level')} flexible>
        <AppDropDownWithSuggestionInput
          options={levelOptions}
          value={levelOptions.find((x) => x.key === props.level)}
          onChange={(x) => {
            setProp((prop: IFieldGroupContainerProps) => {
              prop.level = x?.key as FieldGroupLevel;
            }, 500);
          }}
        />
      </ControlsGroup>

      <ControlsGroup flexible>
        <CheckboxSettings
          label={t('StudySettingsPage.FormBuilder.settings.useForNavigation')}
          isChecked={!!props.useForNavigation}
          setValue={(newValue) =>
            setProp((setProps: IFieldGroupContainerProps) => {
              setProps.useForNavigation = newValue ? newValue : undefined;
            }, 500)
          }
        />
      </ControlsGroup>
    </PanelContainer>
  );
};

export const FieldGroupContainerProps: IFieldGroupContainerProps = {
  title: 'Field group',
};

FieldGroupContainer.craft = {
  props: FieldGroupContainerProps,
  related: {
    settings: FieldGroupContainerSettings,
  },
};

const useFieldGroupLevelOptions = () => {
  const { t } = useTranslation();

  return useMemo<DropdownOption[]>(() => {
    return [
      {
        key: 'first',
        text: t('Forms.Controls.FieldGroup.Levels.First'),
      },
      {
        key: 'second',
        text: t('Forms.Controls.FieldGroup.Levels.Second'),
      },
      {
        key: 'third',
        text: t('Forms.Controls.FieldGroup.Levels.Third'),
      },
    ] satisfies { key: FieldGroupLevel; text: string }[];
  }, [t]);
};
