﻿import { useModal } from '../../../application/hooks/useModal';
import React, { FC, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import styles from './useMonitoringExport.module.scss';
import { HookFormCheckboxGroupField } from '../../../hookFormControls/HookFormCheckboxGroupField';
import { HookFormRadioGroup } from '../../../hookFormControls/hookFormRadioGroup';
import { useGetMonitoringFormsQuery } from '../../../services/api/api-client/MonitoringFormsQuery';
import { generateMonitoringFormsReport } from '../../report';
import { triggerDownloadFileDialog } from '../../report/report-helpers';
import { useContextSelector } from 'use-context-selector';
import { IssuesContext } from '../../../components/issue/provider/issues.context';
import { useMonitoringPageContext } from '../MonitoringPage';
import { IssuesStateEnum, Permissions } from '../../../services/api/api-client';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { useStaffProfile, useStudy } from 'src/helpers/hooks/useStudy';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
import { conditionalConcat } from 'src/helpers/arrayHelpers';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import { usePersonNamesOptions } from '../monitoringForms/fieldOptions';

type FormState = {
  itemsToExport: ('issues' | 'monitoringReport')[] | null;
  issuesToFilter: 'filtered' | 'all';
};

export const useMonitoringExportModal = () => {
  const modal = useModal('CLOSED');
  const personNamesOptions = usePersonNamesOptions();

  const { id: studyId, title, studyNumber } = useStudy() ?? {};
  const { profile } = useStaffProfile();
  const monitoringFormsQuery = useGetMonitoringFormsQuery(studyId!, {
    enabled: !!studyId,
  });

  const { generateReport: generateIssuesReport } = useContextSelector(IssuesContext, (x) => x);
  const { queryParams } = useMonitoringPageContext();

  const form = useForm<FormState>({
    defaultValues: {
      issuesToFilter: 'all',
      itemsToExport: [],
    },
  });

  const onExport = form.handleSubmit(async (values) => {
    if (!monitoringFormsQuery.data || !profile) return;

    modal.closeModal();

    if (values.itemsToExport?.includes('issues')) {
      if (values.issuesToFilter === 'filtered') {
        await generateIssuesReport?.({
          patientUid: queryParams.patientUniqueId ?? undefined,
          state: queryParams.issueState ? IssuesStateEnum[queryParams.issueState] : undefined,
          subjectList: queryParams.subjects ?? undefined,
          patientGroupList: queryParams.groupIds ?? undefined,
        });
      } else {
        await generateIssuesReport?.();
      }
    }

    if (values.itemsToExport?.includes('monitoringReport')) {
      const blob = await generateMonitoringFormsReport({
        forms: monitoringFormsQuery.data,
        studyName: title!,
        studyNumber: studyNumber!,
        authorFullName: profile.fullName,
        participants: personNamesOptions,
      });

      const fileName = `Study-${studyNumber}-Monitoring-Report-${localFormat(new Date(), DateFormats.forFiles)}`;
      triggerDownloadFileDialog(blob, fileName);
    }
  });

  return {
    open: () => {
      form.reset({ itemsToExport: [], issuesToFilter: 'all' });
      modal.openModal();
    },
    element: (
      <FormProvider {...form}>
        <MonitoringExportModal visible={modal.visible} onHide={modal.closeModal} onExport={onExport} />
      </FormProvider>
    ),
  };
};

const MonitoringExportModal: FC<{ visible: boolean; onHide: () => void; onExport: () => void }> = (props) => {
  const { t } = useScopedTranslation('Monitoring.Export.Modal');
  const { hasPermission } = useHasPermissions();

  const form = useFormContext<FormState>();

  const anyOptionSelected = !!form.watch('itemsToExport')?.length;

  const exportItemOptions = useMemo(
    () =>
      conditionalConcat(
        hasPermission(Permissions.IssueView) && [
          {
            value: 'issues',
            text: t('ExportItems.Options.Issues'),
          },
        ],
        hasPermission(Permissions.MonitoringReportView) && [
          {
            value: 'monitoringReport',
            text: t('ExportItems.Options.MonitoringReport'),
          },
        ],
      ),
    [hasPermission, t],
  );

  const exportIssuesOptions = useMemo(
    () => [
      {
        key: 1,
        value: 'filtered',
        text: t('ExportIssues.Options.Filtered'),
      },
      {
        key: 2,
        value: 'all',
        text: t('ExportIssues.Options.All'),
      },
    ],
    [t],
  );

  return (
    <DialogModal
      title={t('Title')}
      visible={props.visible}
      onHide={props.onHide}
      bodyClassName={styles.modalBody}
      footer={{
        leftButton: {
          text: t('Cancel'),
          onClick: props.onHide,
        },
        rightButton: {
          text: t('Export'),
          onClick: props.onExport,
          disabled: !anyOptionSelected,
        },
      }}
    >
      <HookFormCheckboxGroupField
        labelProps={{ text: t('ExportIssues.Label') }}
        options={exportItemOptions}
        valueField={'value'}
        labelField={'text'}
        name={'itemsToExport'}
        // @ts-ignore
        control={form.control}
      />
      {form.watch('itemsToExport')?.includes('issues') && (
        <HookFormRadioGroup
          labelProps={{ text: t('ExportIssues.Label') }}
          options={exportIssuesOptions}
          valueField={'value'}
          labelField={'text'}
          name={'issuesToFilter'}
          control={form.control}
        />
      )}
    </DialogModal>
  );
};
