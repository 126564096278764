import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandedRowHeader } from '../../expandedRow/header/expandedRowHeader.component';
import { AnalysisImportComponent } from '../import/analysisImport.component';
import { AnalysisViewComponent } from '../view/analysisView.component';
import Style from './analysisSection.module.css';
import { Permissions } from 'src/services/api/api-client';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { PatientCardContext } from 'src/components/expandedRow/PatientCardContext';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import PropertiesList from 'uikit/propertiesList/PropertiesList';
import { useGetAllAnalysisPresetsForStudyQuery } from 'src/services/api/api-client/AnalyzesQuery';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';

export const AnalysisSection = () => {
  const study = useStudy();
  const { t } = useTranslation();
  const { hasOneOfPermissions } = useHasPermissions();
  const { patient } = useContext(PatientCardContext);
  const hasPermissions = hasOneOfPermissions(Permissions.AnalysisPresetView, Permissions.AnalysisView);
  const { data: analysisPresets } = useGetAllAnalysisPresetsForStudyQuery(study?.id, {
    enabled: hasPermissions,
  });

  return (
    <div data-test-id={'patient-card-analysis'}>
      <IssueMark
        issueContext={{
          subject: 'Patient',
          topic: 'Analyzes',
          linkedPatientUniqId: patient.uniqueId,
        }}
      >
        <ExpandedRowHeader text={t('ExternalAnalysis.Presets.SectionHeader')} />
      </IssueMark>

      <PropertiesList className={Style.container}>
        {hasPermissions &&
          analysisPresets?.map((presetDto) => (
            <PropertiesList.Property
              key={presetDto.id}
              name={presetDto.name}
              value={
                <div className={Style.tableItemRightContainer}>
                  <PermissionsCheck permissions={Permissions.AnalysisView}>
                    <AnalysisViewComponent
                      key={`view_${presetDto.id}`}
                      title={presetDto.name}
                      patientId={patient.id}
                      presetId={presetDto.id}
                    />
                  </PermissionsCheck>

                  <PermissionsCheck permissions={Permissions.AnalysisManage}>
                    <AnalysisImportComponent key={`import_${presetDto.id}`} preset={presetDto} patientId={patient.id} />
                  </PermissionsCheck>
                </div>
              }
            />
          ))}
      </PropertiesList>
    </div>
  );
};
