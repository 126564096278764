import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import Style from './useStudyClosing.module.scss';
import clsx from 'clsx';
import { AppButton } from 'uikit/buttons';
import { ReactComponent as StopIcon } from 'assets/img/patient/stop_20.svg';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { ConfirmResult, useConfirmationModal } from 'src/components/dialogModal/useConfirmationModal';
import { approveClosureStudy, closeStudy, revokeClosureApprovalStudy } from 'src/services/api/api-client/StudyClient';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import { showErrorToast } from 'src/components/toast/toast-helper';
import { useQueryClient } from '@tanstack/react-query';
import { QueryFactory } from 'src/services/api';
import { Permissions, RoleDto } from 'src/services/api/api-client';
import { ReactComponent as DotIcon } from 'src/assets/img/formState/dot_16.svg';
import { ReactComponent as CheckIcon } from 'src/assets/img/formState/check_16.svg';
import { ReactComponent as ResetIcon } from 'src/assets/img/common/reset_24.svg';
import { useModal } from 'src/application/hooks/useModal';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';
import { AppTextInput } from 'uikit/inputs';
import { Trans, useTranslation } from 'react-i18next';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { useGetRolesQuery } from 'src/services/api/api-client/UserRolesQuery';
import { StudyInfoCard } from '../studyInfo/StudyInfoPageLayout';

type CloseStudyConditionsType = {
  dataIsLoading: boolean;
  allPatientAreArchived: boolean;
  allIssuesAreClosed: boolean;
  closingIsApproved: boolean;
  studyIsClosed: boolean;
  hasLeastOnePermissions: boolean;
  hasOnlyApproveClosureStudyPermission: boolean;
  hasOnlyCloseStudyPermission: boolean;
};

export const useStudyClosing = () => {
  const { t } = useScopedTranslation('CloseStudy');
  const queryClient = useQueryClient();
  const study = useStudy();
  const { hasPermission, hasOneOfPermissions } = useHasPermissions();
  const approveConfirmationModal = useConfirmationModal();
  const revokeApprovalConfirmationModal = useConfirmationModal();
  const closeConfirmationModal = useCloseConfirmationModal();
  const rolesQuery = useGetRolesQuery({ suspense: false });

  const closeStudyConditions: CloseStudyConditionsType = useMemo(
    () =>
      ({
        allPatientAreArchived: !!study?.allPatientsAreArchived,
        allIssuesAreClosed: !!study?.allIssuesAreClosed,
        studyIsClosed: !!study?.closedAt,
        closingIsApproved: !!study?.closingApprovedAt,
        dataIsLoading: rolesQuery.isInitialLoading,
        hasOnlyApproveClosureStudyPermission:
          hasPermission(Permissions.ApproveClosureStudy) && !hasPermission(Permissions.CloseStudy),
        hasOnlyCloseStudyPermission:
          !hasPermission(Permissions.ApproveClosureStudy) && hasPermission(Permissions.CloseStudy),
        hasLeastOnePermissions: hasOneOfPermissions(Permissions.ApproveClosureStudy, Permissions.CloseStudy),
      } satisfies CloseStudyConditionsType),
    [hasOneOfPermissions, hasPermission, rolesQuery.isInitialLoading, study],
  );

  const buttonIsHidden = useMemo(
    () => closeStudyConditions.studyIsClosed || !closeStudyConditions.hasLeastOnePermissions,
    [closeStudyConditions],
  );

  const buttonIsDisabled = useMemo(
    () =>
      closeStudyConditions.studyIsClosed ||
      !closeStudyConditions.allPatientAreArchived ||
      !closeStudyConditions.allIssuesAreClosed ||
      closeStudyConditions.dataIsLoading ||
      (!closeStudyConditions.closingIsApproved && closeStudyConditions.hasOnlyCloseStudyPermission) ||
      (closeStudyConditions.closingIsApproved && closeStudyConditions.hasOnlyApproveClosureStudyPermission),

    [closeStudyConditions],
  );

  const approveHandler = useCallback(async () => {
    const result = await approveConfirmationModal.open({
      title: t('ApproveConfirmationModal.Title'),
      text: (
        <span>
          <Trans
            t={t}
            i18nKey={'ApproveConfirmationModal.Text'}
            values={{ studyNumber: study?.studyNumber, studyName: study?.title }}
            components={{
              Bold: <span className={Style.boldText} />,
              Red: <span className={Style.redText} />,
            }}
          />
        </span>
      ),
      okButtonColorSchema: 'destroy',
      okButtonText: t('ApproveConfirmationModal.OkButtonText'),
    });

    if (result !== ConfirmResult.Confirm) return;

    await approveClosureStudy(study!.id!);
    await queryClient.invalidateQueries(QueryFactory.ProfileQuery.getDoctorProfileInfoQueryKey());
  }, [approveConfirmationModal, queryClient, study, t]);

  const closeHandler = useCallback(async () => {
    const result = await closeConfirmationModal.open();

    if (result !== ConfirmResult.Confirm) return;

    await closeStudy(study!.id!);
    await queryClient.invalidateQueries(QueryFactory.ProfileQuery.getDoctorProfileInfoQueryKey());
  }, [closeConfirmationModal, queryClient, study]);

  const onCLickHandler = useCallback(async () => {
    try {
      if (hasPermission(Permissions.ApproveClosureStudy) && !study?.closingApprovedAt) {
        await approveHandler();
        return;
      }

      if (hasPermission(Permissions.CloseStudy) && !!study?.closingApprovedAt && !study?.closedAt) {
        await closeHandler();
        return;
      }
    } catch (err) {
      showErrorToast(err);
    }
  }, [approveHandler, closeHandler, hasPermission, study?.closedAt, study?.closingApprovedAt]);

  const revokeApproval = useCallback(async () => {
    try {
      const result = await revokeApprovalConfirmationModal.open({
        okButtonColorSchema: 'destroy',
        title: t('RevokeApprovalConfirmationModal.Title'),
        text: t('RevokeApprovalConfirmationModal.Text'),
        okButtonText: t('RevokeApprovalConfirmationModal.OkButtonText'),
      });

      if (result !== ConfirmResult.Confirm) return;

      await revokeClosureApprovalStudy(study!.id);
      await queryClient.invalidateQueries(QueryFactory.ProfileQuery.getDoctorProfileInfoQueryKey());
    } catch (err) {
      showErrorToast(err);
    }
  }, [queryClient, revokeApprovalConfirmationModal, study, t]);

  const rolesCanApprove = useMemo(
    () => rolesQuery.data?.filter((x) => x.permissions.includes(Permissions.ApproveClosureStudy)) ?? [],
    [rolesQuery.data],
  );

  return {
    buttonComponent: buttonIsHidden ? undefined : (
      <div>
        <Tooltip
          text={''}
          tooltipContent={
            <TooltipComponent
              buttonIsDisabled={buttonIsDisabled}
              closeStudyConditions={closeStudyConditions}
              rolesCanApprove={rolesCanApprove}
            />
          }
        >
          <AppButton
            variant={'icon-link'}
            colorSchema={'destroy'}
            Icon={StopIcon}
            onClick={onCLickHandler}
            disabled={!!buttonIsDisabled}
          />
        </Tooltip>
        {approveConfirmationModal.modal}
      </div>
    ),
    cardComponent: !closeStudyConditions.closingIsApproved ? undefined : (
      <StudyInfoCard className={clsx(Style.card, { [Style.center]: closeStudyConditions.studyIsClosed })}>
        <CardComponent
          closeStudyConditions={closeStudyConditions}
          rolesCanApprove={rolesCanApprove}
          rolesCanClose={rolesQuery.data?.filter((x) => x.permissions.includes(Permissions.CloseStudy)) ?? []}
          onRevokeApproval={revokeApproval}
          onCloseStudy={onCLickHandler}
        />
        {revokeApprovalConfirmationModal.modal}
        {closeConfirmationModal.modal}
      </StudyInfoCard>
    ),
  };
};

const TooltipComponent: FC<{
  closeStudyConditions: CloseStudyConditionsType;
  buttonIsDisabled: boolean;
  rolesCanApprove: RoleDto[];
}> = ({ closeStudyConditions, buttonIsDisabled, rolesCanApprove }) => {
  const { t } = useScopedTranslation('CloseStudy.CloseCondition');

  if (!buttonIsDisabled) return <>{t('Tooltip')}</>;

  const roles = rolesCanApprove.map((x) => x.name).join(', ');

  return (
    <div className={Style.tooltipContainer}>
      <span>{t('Title')}</span>
      <TooltipRow
        state={closeStudyConditions.allPatientAreArchived ? 'done' : 'inProgress'}
        text={t('AllPatientArchived')}
      />
      <TooltipRow
        state={closeStudyConditions.allIssuesAreClosed ? 'done' : 'inProgress'}
        text={t('AllIssuesAreClosed')}
      />
      {closeStudyConditions.hasOnlyCloseStudyPermission && !closeStudyConditions.closingIsApproved && (
        <TooltipRow state={'inProgress'} text={t('StudyClosureApproved', { roles })} />
      )}
    </div>
  );
};

const CardComponent: FC<{
  closeStudyConditions: CloseStudyConditionsType;
  rolesCanApprove: RoleDto[];
  rolesCanClose: RoleDto[];
  onRevokeApproval: () => void;
  onCloseStudy: () => void;
}> = ({ closeStudyConditions, rolesCanApprove, rolesCanClose, onRevokeApproval, onCloseStudy }) => {
  const { t } = useScopedTranslation('CloseStudy');
  const { hasPermission } = useHasPermissions();
  const study = useStudy();

  if (closeStudyConditions.studyIsClosed) {
    return (
      <div className={Style.closedCardContainer}>
        <div className={Style.title}>{t('ClosedBy')}</div>
        <div>{study?.closingApprovedBy?.fullName}</div>
        <div>{study?.closedBy?.fullName}</div>
      </div>
    );
  }

  return (
    <div className={Style.cardContainer}>
      <div className={Style.title}>{t('ClosingProgress')}</div>
      <div className={Style.rows}>
        <TooltipRow
          state={study?.closingApprovedBy ? 'done' : 'inProgress'}
          text={
            study?.closingApprovedBy ? study.closingApprovedBy.fullName : rolesCanApprove.map((x) => x.name).join(', ')
          }
          buttonComponent={
            !closeStudyConditions.studyIsClosed &&
            closeStudyConditions.closingIsApproved &&
            hasPermission(Permissions.ApproveClosureStudy) ? (
              <AppButton colorSchema={'primary'} variant={'icon-link'} Icon={ResetIcon} onClick={onRevokeApproval} />
            ) : undefined
          }
        />

        <TooltipRow
          state={study?.closedBy ? 'done' : 'inProgress'}
          text={study?.closedBy ? study.closedBy.fullName : rolesCanClose.map((x) => x.name).join(', ')}
          buttonComponent={
            !closeStudyConditions.studyIsClosed &&
            closeStudyConditions.closingIsApproved &&
            hasPermission(Permissions.CloseStudy) ? (
              <AppButton colorSchema={'destroy'} variant={'icon-link'} Icon={StopIcon} onClick={onCloseStudy} />
            ) : undefined
          }
        />
      </div>
    </div>
  );
};

const TooltipRow: FC<{
  state: 'done' | 'inProgress';
  text: string;
  buttonComponent?: JSX.Element;
}> = ({ state, text, buttonComponent }) => {
  return (
    <div className={clsx(Style.textWithIcon, { [Style.green]: state === 'done' })}>
      {state === 'done' ? <CheckIcon /> : <DotIcon />}
      <div>{text}</div>
      {buttonComponent}
    </div>
  );
};

const useCloseConfirmationModal = () => {
  const { t: commonTranslation } = useTranslation();
  const { t } = useScopedTranslation('CloseStudy');
  const modal = useModal('CLOSED');
  const promiseRef = useRef<(test: ConfirmResult) => void>();
  const study = useStudy();
  const [input, setInput] = useState('');

  const closeHandler = useCallback(() => {
    promiseRef?.current?.(ConfirmResult.Closed);
    modal.closeModal();
  }, [modal]);

  const okClickHandler = useCallback(() => {
    promiseRef?.current?.(ConfirmResult.Confirm);
    modal.closeModal();
  }, [modal]);

  const cancelClickHandler = useCallback(() => {
    promiseRef?.current?.(ConfirmResult.Cancel);
    modal.closeModal();
  }, [modal]);

  return {
    open: (): Promise<ConfirmResult> => {
      modal.openModal();

      return new Promise<ConfirmResult>((resolve) => {
        promiseRef.current = resolve;
      });
    },
    modal: (
      <DialogModal
        visible={modal.visible}
        onHide={closeHandler}
        title={t('CloseConfirmationModal.Title')}
        containerClassName={Style.confirmationModalContainer}
        footer={{
          leftButton: {
            text: commonTranslation('Common_Cancel'),
            onClick: cancelClickHandler,
          },
          rightButton: {
            colorSchema: 'destroy',
            text: t('CloseConfirmationModal.OkButtonText'),
            onClick: okClickHandler,
            disabled: input !== study?.studyNumber,
          },
        }}
      >
        <span>
          <Trans
            t={t}
            i18nKey={'CloseConfirmationModal.Text'}
            values={{ studyNumber: study?.studyNumber, studyName: study?.title }}
            components={{
              Bold: <span className={Style.boldText} />,
              Red: <span className={Style.redText} />,
            }}
          />
          <AppTextInput
            className={Style.modalInput}
            placeholder={t('CloseConfirmationModal.InputPlaceholder')}
            onChange={(e) => setInput(e.target.value)}
          />
        </span>
      </DialogModal>
    ),
  };
};
