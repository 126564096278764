import React from 'react';
import { PaginatorProps } from './dailyCheckCalendar';
import Style from './dailyCheckCalendar.module.css';
import { TypographyStyles } from '../../styles';
import { ReactComponent as ForwardBtn } from '../../assets/img/survey/paginationForward.svg';
import clsx from 'clsx';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';

export const DailycheckPaginator = (props: PaginatorProps) => {
  const { itemsPerPage, totalNumberOfItems, handlePagination, counterValue } = props;
  const pageNumbers: number[] = [];

  for (let i = 1; i <= Math.ceil(totalNumberOfItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleForwardClick = () => handlePagination(counterValue + 1);
  const handleBackClick = () => handlePagination(counterValue - 1);

  return (
    <nav className={Style.pagination}>
      <AppButton
        variant={'icon-link'}
        colorSchema={'primary'}
        Icon={ForwardBtn}
        disabled={counterValue === 1}
        className={Style.paginationBtnBack}
        onClick={handleBackClick}
      />
      <ul className={Style.paginationList}>
        {pageNumbers.map((count) => (
          <li key={count} className={Style.paginationListItem}>
            <span
              onClick={() => handlePagination(count)}
              className={clsx({
                [Style.paginationListItem]: true,
                [TypographyStyles.buttonLinkText]: true,
                [Style.selectedPage]: count === counterValue,
              })}
            >
              {count}
            </span>
          </li>
        ))}
      </ul>
      <AppButton
        variant={'icon-link'}
        colorSchema={'primary'}
        Icon={ForwardBtn}
        disabled={counterValue === pageNumbers.length}
        onClick={handleForwardClick}
      />
    </nav>
  );
};
