/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { TypographyStyles } from 'src/styles';
import clsx from 'clsx';
import { useContextSelector } from 'use-context-selector';
import { UIEditorBlockWidth } from '../../../../features/forms/base/controls/inputs/base/baseControlSettings';
import styles from '../controlsStyle.module.css';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { IEmptyBlock, IEmptyBlockProps } from '../../../../features/forms/base/controls/content/EmptyBlock';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';

export const EmptyBlock: IEmptyBlock = (props) => {
  const { connectors } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));
  const { connect, drag } = connectors;
  const { size } = {
    ...EmptyBlockDefaultProps,
    ...props,
  };

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');

  const { isConstructorMode } = useEditor((state) => ({
    isConstructorMode: state.options.enabled,
  }));

  if (isOnlyView) {
    return <></>;
  }

  return (
    <div
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      {isConstructorMode ? (
        <p className={clsx(TypographyStyles.plainText14, styles.emptyContainer)}>Empty block - Height 20px</p>
      ) : (
        <div style={{ height: 20 }} />
      )}
    </div>
  );
};

const EmptyBlockSettings = () => {
  return <LayoutSettings />;
};

export const EmptyBlockDefaultProps: IEmptyBlockProps = {
  size: UIEditorBlockWidth.Quarter,
};

EmptyBlock.craft = {
  defaultProps: EmptyBlockDefaultProps,
  related: {
    settings: EmptyBlockSettings,
  },
};
