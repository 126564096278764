﻿import React, { FC, useCallback, useMemo, useState } from 'react';
import { AppModalContainer } from 'uikit/modal/modal.component';
import { useModal } from '../../../application/hooks/useModal';
import { useGetMonitoringFormsQuery } from '../../../services/api/api-client/MonitoringFormsQuery';
import { FieldSkipReason, IMonitoringFormDto, Permissions } from '../../../services/api/api-client';
import { OverviewNavbar, OverviewNavbarItemProps } from 'uikit/fields/overview/OverviewNavbar';
import { MonitoringFormValue } from './MonitoringFormValue';
import { OverviewFieldGroup } from 'uikit/fields/overview/OverviewFieldGroup';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { DateFormats, localFormat, toDateOrNull } from '../../../helpers/date-helpers';
import styles from './useMonitoringFormsOverview.module.scss';
import {
  useConsentFormsReviewedOptions,
  useMonitoringFormTypeOptions,
  usePersonNamesOptions,
  useSdvOptions,
  useStudyDocumentsOptions,
  useYesNoOptions,
} from './fieldOptions';
import { OverviewRepeatableFormSection } from 'uikit/fields/overview/OverviewRepeatableFormSection';
import { ReactComponent as EditIcon } from 'src/assets/img/common/edit_20.svg';
import { ReactComponent as ExportIcon } from 'src/assets/img/common/export_20.svg';
import { AppButton } from 'uikit/buttons';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { useMonitoringFormsEditing } from './useMonitoringFormsEditing';
import { generateMonitoringFormsReport } from '../../report';
import { triggerDownloadFileDialog } from '../../report/report-helpers';
import { useStaffProfile, useStudy } from 'src/helpers/hooks/useStudy';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';

export const useMonitoringFormsOverview = () => {
  const modal = useModal('CLOSED');
  const { id: studyId, title, studyNumber } = useStudy() ?? {};
  const monitoringFormEditing = useMonitoringFormsEditing();
  const personNamesOptions = usePersonNamesOptions();

  const { profile } = useStaffProfile();

  const reportsQuery = useGetMonitoringFormsQuery(studyId!, {
    enabled: !!studyId,
  });

  const [selectedReportId, setSelectedReportId] = useState<string>();
  const selectedReport = reportsQuery.data?.find((x) => x.id === selectedReportId);

  const onEdit = useCallback(async () => {
    if (!selectedReport) return;

    modal.closeModal();
    await monitoringFormEditing.startEditing({
      studyId: studyId!,
      studyTitle: title!,
      monitoringForm: selectedReport,
    });

    modal.openModal();
  }, [selectedReport, modal, monitoringFormEditing, studyId, title]);

  const onExport = useCallback(async () => {
    if (!selectedReport || !profile.id) return;

    const blob = await generateMonitoringFormsReport({
      forms: [selectedReport],
      studyName: title!,
      studyNumber: studyNumber!,
      authorFullName: profile.fullName,
      participants: personNamesOptions,
    });

    const fileName = `Study-${studyNumber}-Monitoring-Report-${localFormat(new Date(), DateFormats.forFiles)}`;

    triggerDownloadFileDialog(blob, fileName);
  }, [selectedReport, profile.id, profile.fullName, title, studyNumber, personNamesOptions]);

  return {
    open: (args: { reportId: string }) => {
      setSelectedReportId(args.reportId);
      modal.openModal();
    },
    element: reportsQuery.data && selectedReport && selectedReportId && (
      <>
        <MonitoringFormsOverviewModal
          reports={reportsQuery.data}
          visible={modal.visible}
          onHide={modal.closeModal}
          selectedReport={selectedReport}
          onSelectReport={setSelectedReportId}
          onEdit={onEdit}
          onExport={onExport}
          personNamesOptions={personNamesOptions}
        />
        {monitoringFormEditing.element}
      </>
    ),
  };
};

const MonitoringFormsOverviewModal: FC<{
  reports: IMonitoringFormDto[];
  visible: boolean;
  onHide: () => void;
  selectedReport: IMonitoringFormDto;
  onSelectReport: (reportId: string) => void;
  onEdit: () => void;
  onExport: () => void;
  personNamesOptions: DropdownOption<string>[];
}> = (props) => {
  const { t } = useScopedTranslation('MonitoringForms');

  const navbarItemsProps = useMemo(() => {
    return props.reports
      .map<OverviewNavbarItemProps>((x, i, arr) => ({
        key: x.id,
        text: `#${arr.length - i}`,
        disabled: arr.length === 1,
        selected: x.id === props.selectedReport.id,
        onClick: () => props.onSelectReport(x.id),
      }))
      .reverse();
  }, [props]);

  const currentReport = props.selectedReport;
  const currentReportValues = currentReport.value as MonitoringFormValue;

  const yesNoOptions = useYesNoOptions();
  const studyDocumentsOptions = useStudyDocumentsOptions();
  const monitoringFormTypeOptions = useMonitoringFormTypeOptions();
  const sdvOptions = useSdvOptions();
  const consentFormsReviewedOptions = useConsentFormsReviewedOptions();

  const getYesNoLabel = (value: string | undefined) => yesNoOptions.find((x) => x.value === value)?.label;
  const getStudyDocumentsLabel = (key: string | undefined) => studyDocumentsOptions.find((x) => x.key === key)?.text;
  const getPersonNameLabel = (key: string | undefined) =>
    props.personNamesOptions.find((x) => key?.includes(x.key))?.text ?? '';
  const getMonitoringFormTypeLabel = (value: string | undefined) =>
    monitoringFormTypeOptions.find((x) => x.value === value)?.label;
  const getSdvOptionsLabel = (value: string | undefined) => sdvOptions.find((x) => x.value === value)?.label;
  const getConsentFormsReviewedLabel = (key: string | undefined) =>
    consentFormsReviewedOptions.find((x) => x.key === key)?.text;

  const { t: controlsTranslation } = useScopedTranslation('Forms.Controls');
  const getSkipReasonProps = (path: string) => {
    const reason = currentReport.fieldSkipReasons[path];

    if (reason === undefined) return undefined;

    const translation =
      reason === FieldSkipReason.NoReason
        ? controlsTranslation('SkipQuestionCheckBox')
        : controlsTranslation(`SkipQuestionReason.${FieldSkipReason[reason!]}` as LocalizedResourceDictionaryKeys);

    return { skipped: true, skipReason: translation as string };
  };

  return (
    <AppModalContainer title={t('Overview.Modal.Title')} visible={props.visible} onHide={props.onHide}>
      <div className={styles.header}>
        <OverviewNavbar items={navbarItemsProps} />
        <Toolbar report={currentReport} onExport={props.onExport} onEdit={props.onEdit} />
      </div>
      <div className={styles.body}>
        <OverviewFieldGroup title={t('Fields.GeneralInformation.Title')}>
          <OverviewField
            label={t('Fields.GeneralInformation.TestingCenter.Label')}
            value={currentReportValues.testingCenter}
          />
          <OverviewField
            label={t('Fields.GeneralInformation.DateOfVisit.Label')}
            value={toDateOrNull(currentReportValues.dateOfVisit)}
          />
          <OverviewField
            label={t('Fields.GeneralInformation.PersonNames.Label')}
            values={currentReportValues.personNames?.map((x) => getPersonNameLabel(x))}
          />
          <OverviewField
            label={t('Fields.GeneralInformation.EnoughTimeToHoldMeeting.Label')}
            value={getYesNoLabel(currentReportValues.enoughTimeToHoldMeeting)}
          />
          <OverviewField
            label={t('Fields.GeneralInformation.GeneralComment.Label')}
            value={currentReportValues.generalComment}
            {...getSkipReasonProps('generalComment')}
          />
        </OverviewFieldGroup>
        <OverviewFieldGroup title={t('Fields.StudyStaff.Title')}>
          <OverviewField
            label={t('Fields.StudyStaff.DelegationLogFilledInOrSupplemented.Label')}
            value={getYesNoLabel(currentReportValues.delegationLogFilledInOrSupplemented)}
            {...getSkipReasonProps('delegationLogFilledInOrSupplemented')}
          />
          {currentReportValues.delegationLogFilledInOrSupplemented_comment && (
            <OverviewField
              label={t('Fields.Comment.Label')}
              value={currentReportValues.delegationLogFilledInOrSupplemented_comment}
            />
          )}
        </OverviewFieldGroup>
        <OverviewFieldGroup title={t('Fields.TypeOfMonitoringVisit.Label')}>
          <OverviewField
            label={t('Fields.TypeOfMonitoringVisit.Label')}
            value={getMonitoringFormTypeLabel(currentReportValues.typeOfMonitoringVisit)}
          />
        </OverviewFieldGroup>
        <OverviewFieldGroup title={t('Fields.Infrastructure.Title')}>
          {(currentReportValues.typeOfMonitoringVisit === 'Routine' ||
            currentReportValues.typeOfMonitoringVisit === 'Final') && (
            <>
              <OverviewField
                label={t('Fields.SpatialChangesAtTestCenter.Label')}
                value={getYesNoLabel(currentReportValues.spatialChangesAtTestCenter)}
                {...getSkipReasonProps('spatialChangesAtTestCenter')}
              />
              {currentReportValues.spatialChangesAtTestCenter_comment && (
                <OverviewField
                  label={t('Fields.Comment.Label')}
                  value={currentReportValues.spatialChangesAtTestCenter_comment}
                />
              )}
            </>
          )}
          <OverviewField
            label={t('Fields.Infrastructure.TechnicalProblemsAtFacilityAffectingStudy.Label')}
            value={getYesNoLabel(currentReportValues.technicalProblemsAtFacilityAffectingStudy)}
            {...getSkipReasonProps('technicalProblemsAtFacilityAffectingStudy')}
          />
          {currentReportValues.technicalProblemsAtFacilityAffectingStudy_comment && (
            <OverviewField
              label={t('Fields.Comment.Label')}
              value={currentReportValues.technicalProblemsAtFacilityAffectingStudy_comment}
            />
          )}
          <OverviewField
            label={t('Fields.Infrastructure.ProperStorageOfStudyDocuments.Label')}
            value={getYesNoLabel(currentReportValues.properStorageOfStudyDocuments)}
            {...getSkipReasonProps('properStorageOfStudyDocuments')}
          />
          {currentReportValues.properStorageOfStudyDocuments_comment && (
            <OverviewField
              label={t('Fields.Comment.Label')}
              value={currentReportValues.properStorageOfStudyDocuments_comment}
            />
          )}
        </OverviewFieldGroup>

        <OverviewFieldGroup title={t('Fields.StudyDocumentation.Title')}>
          <OverviewField
            label={
              currentReportValues.typeOfMonitoringVisit === 'Initiate'
                ? t('Fields.StudyDocumentation.GivenStudyDocuments.Label')
                : t('Fields.StudyDocumentation.ChangingStudyDocuments.Label')
            }
            value={getYesNoLabel(currentReportValues.givenStudyDocuments)}
            {...getSkipReasonProps('givenStudyDocuments')}
          />
          {currentReportValues.givenStudyDocuments_comment && (
            <OverviewField label={t('Fields.Comment.Label')} value={currentReportValues.givenStudyDocuments_comment} />
          )}
          {currentReportValues.givenStudyDocuments === 'true' && (
            <>
              <OverviewField
                label={t('Fields.StudyDocumentation.StudyDocuments.Label')}
                values={currentReportValues.studyDocuments?.map((x) => getStudyDocumentsLabel(x)!).filter((x) => x)}
              />
              {currentReportValues.studyDocuments?.includes('TestPlan') && (
                <OverviewField
                  label={t('Fields.StudyDocumentation.TestPlanVersion.Label')}
                  value={currentReportValues.testPlanVersion}
                />
              )}
              {currentReportValues.studyDocuments?.includes('PatientInformationAndConsent') && (
                <OverviewField
                  label={t('Fields.StudyDocumentation.PatientInformationAndConsentVersion.Label')}
                  value={currentReportValues.patientInformationAndConsentVersion}
                />
              )}
              {currentReportValues.studyDocuments?.includes('InsuranceDocuments') && (
                <OverviewField
                  label={t('Fields.StudyDocumentation.InsuranceDocuments.Label')}
                  value={toDateOrNull(currentReportValues.insuranceDocuments)}
                />
              )}
              {currentReportValues.studyDocuments?.includes('CommunicationWithEc') && (
                <OverviewField
                  label={t('Fields.StudyDocumentation.CommunicationWithEcVersion.Label')}
                  value={toDateOrNull(currentReportValues.communicationWithEc)}
                />
              )}
              {currentReportValues.studyDocuments?.includes('SupportingDocuments') && (
                <OverviewField
                  label={t('Fields.StudyDocumentation.SupportingDocuments.Label')}
                  value={currentReportValues.supportingDocuments}
                />
              )}
              {currentReportValues.studyDocuments?.includes('RandomizationList') && (
                <OverviewField
                  label={t('Fields.StudyDocumentation.RandomizationList.Label')}
                  value={toDateOrNull(currentReportValues.randomizationList)}
                />
              )}
              {currentReportValues.studyDocuments?.includes('Other') && (
                <OverviewField
                  label={t('Fields.StudyDocumentation.OtherStudyDocuments.Label')}
                  value={currentReportValues.otherStudyDocuments}
                />
              )}
            </>
          )}
          {(currentReportValues.typeOfMonitoringVisit === 'Routine' ||
            currentReportValues.typeOfMonitoringVisit === 'Final') && (
            <>
              <OverviewField
                label={t('Fields.PatientDocumentation.PersonalIdentificationListIsComplete.Label')}
                value={getYesNoLabel(currentReportValues.personalIdentificationListIsComplete)}
                {...getSkipReasonProps('personalIdentificationListIsComplete')}
              />
              {currentReportValues.personalIdentificationListIsComplete_comment && (
                <OverviewField
                  label={t('Fields.Comment.Label')}
                  value={currentReportValues.personalIdentificationListIsComplete_comment}
                />
              )}
              <OverviewField
                label={t('Fields.PatientDocumentation.PatientIdentificationListIsComplete.Label')}
                value={getYesNoLabel(currentReportValues.patientIdentificationListIsComplete)}
                {...getSkipReasonProps('patientIdentificationListIsComplete')}
              />
              {currentReportValues.patientIdentificationListIsComplete_comment && (
                <OverviewField
                  label={t('Fields.Comment.Label')}
                  value={currentReportValues.patientIdentificationListIsComplete_comment}
                />
              )}
            </>
          )}
        </OverviewFieldGroup>

        {(currentReportValues.typeOfMonitoringVisit === 'Routine' ||
          currentReportValues.typeOfMonitoringVisit === 'Final') && (
          <>
            <OverviewFieldGroup title={t('Fields.DeclarationsOfConsent.Title')}>
              <OverviewField
                label={t('Fields.DeclarationsOfConsent.ConsentFormsReviewed.Label')}
                value={getConsentFormsReviewedLabel(currentReportValues.consentFormsReviewed)}
                {...getSkipReasonProps('consentFormsReviewed')}
              />
              <OverviewField
                label={t('Fields.DeclarationsOfConsent.DeclarationsOfConsentIsComplete.Label')}
                value={getYesNoLabel(currentReportValues.declarationsOfConsentIsComplete)}
                {...getSkipReasonProps('declarationsOfConsentIsComplete')}
              />
              {currentReportValues.declarationsOfConsentIsComplete_comment && (
                <OverviewField
                  label={t('Fields.Comment.Label')}
                  value={currentReportValues.declarationsOfConsentIsComplete_comment}
                />
              )}
            </OverviewFieldGroup>
          </>
        )}

        {currentReportValues.typeOfMonitoringVisit === 'Final' && (
          <>
            <OverviewFieldGroup title={t('Fields.QueryManagement.Title')}>
              <OverviewField
                label={t('Fields.QueryManagement.OpenQueriesDiscussed.Label')}
                value={getYesNoLabel(currentReportValues.openQueriesDiscussed)}
                {...getSkipReasonProps('openQueriesDiscussed')}
              />
              {currentReportValues.openQueriesDiscussed_comment && (
                <OverviewField
                  label={t('Fields.Comment.Label')}
                  value={currentReportValues.openQueriesDiscussed_comment}
                />
              )}
            </OverviewFieldGroup>
          </>
        )}

        {(currentReportValues.typeOfMonitoringVisit === 'Routine' ||
          currentReportValues.typeOfMonitoringVisit === 'Final') &&
          (currentReportValues.patients?.length ?? 0) > 0 && (
            <>
              <OverviewFieldGroup title={t('Fields.PatientMonitoring.Title')}>
                <OverviewField
                  label={t('Fields.PatientMonitoring.PatientRange.Label')}
                  value={currentReportValues.patientRange}
                />
                {currentReportValues.patients?.map((patient, i) => (
                  <OverviewRepeatableFormSection
                    key={i}
                    title={t('Fields.PatientMonitoring.RepeatableSectionTitle', { index: i + 1 })}
                  >
                    <OverviewField
                      label={t('Fields.PatientMonitoring.PatientUniqueId.Label')}
                      value={patient.patientUniqueId}
                    />
                    <OverviewField
                      label={t('Fields.PatientMonitoring.Sdv.Label')}
                      value={getSdvOptionsLabel(patient.sdv)}
                    />
                    {patient.sdv === 'FixedRecord' && (
                      <OverviewField
                        label={t('Fields.PatientMonitoring.SdvFixedRecord.Label')}
                        value={patient.sdvFixedRecord}
                      />
                    )}
                    <OverviewField
                      label={t('Fields.PatientMonitoring.ConsentIsCorrectlyObtained.Label')}
                      value={getYesNoLabel(patient.consentIsCorrectlyObtained)}
                      {...getSkipReasonProps(`patients.${i}.consentIsCorrectlyObtained`)}
                    />
                    {patient.consentIsCorrectlyObtained_comment && (
                      <OverviewField
                        label={t('Fields.Comment.Label')}
                        value={patient.consentIsCorrectlyObtained_comment}
                      />
                    )}
                    <OverviewField
                      label={t('Fields.PatientMonitoring.VerifiableInclusionAndExclusionCriteriaAreCorrect.Label')}
                      value={getYesNoLabel(patient.verifiableInclusionAndExclusionCriteriaAreCorrect)}
                      {...getSkipReasonProps(`patients.${i}.verifiableInclusionAndExclusionCriteriaAreCorrect`)}
                    />
                    {patient.verifiableInclusionAndExclusionCriteriaAreCorrect_comment && (
                      <OverviewField
                        label={t('Fields.Comment.Label')}
                        value={patient.verifiableInclusionAndExclusionCriteriaAreCorrect_comment}
                      />
                    )}
                    <OverviewField
                      label={t('Fields.PatientMonitoring.AccordingToStudySchedule.Label')}
                      value={getYesNoLabel(patient.accordingToStudySchedule)}
                      {...getSkipReasonProps(`patients.${i}.accordingToStudySchedule`)}
                    />
                    {patient.accordingToStudySchedule_comment && (
                      <OverviewField
                        label={t('Fields.Comment.Label')}
                        value={patient.accordingToStudySchedule_comment}
                      />
                    )}
                    <OverviewField
                      label={t('Fields.PatientMonitoring.AccordingToTestPlan.Label')}
                      value={getYesNoLabel(patient.accordingToTestPlan)}
                      {...getSkipReasonProps(`patients.${i}.accordingToTestPlan`)}
                    />
                    {patient.accordingToTestPlan_comment && (
                      <OverviewField label={t('Fields.Comment.Label')} value={patient.accordingToTestPlan_comment} />
                    )}
                    <OverviewField
                      label={t('Fields.PatientMonitoring.NewInformationDiscussed.Label')}
                      value={getYesNoLabel(patient.newInformationDiscussed)}
                      {...getSkipReasonProps(`patients.${i}.newInformationDiscussed`)}
                    />
                    {patient.newInformationDiscussed_comment && (
                      <OverviewField
                        label={t('Fields.Comment.Label')}
                        value={patient.newInformationDiscussed_comment}
                      />
                    )}
                    <OverviewField
                      label={t('Fields.PatientMonitoring.SourceDataDocumentationAvailable.Label')}
                      value={getYesNoLabel(patient.sourceDataDocumentationAvailable)}
                      {...getSkipReasonProps(`patients.${i}.sourceDataDocumentationAvailable`)}
                    />
                    {patient.sourceDataDocumentationAvailable_comment && (
                      <OverviewField
                        label={t('Fields.Comment.Label')}
                        value={patient.sourceDataDocumentationAvailable_comment}
                      />
                    )}
                  </OverviewRepeatableFormSection>
                ))}
              </OverviewFieldGroup>
            </>
          )}

        {currentReportValues.typeOfMonitoringVisit === 'Final' && (
          <>
            <OverviewFieldGroup title={t('Fields.TestProducts.Title')}>
              <OverviewField
                label={t('Fields.TestProducts.ResetOfDevices.Label')}
                value={getYesNoLabel(currentReportValues.resetOfDevices)}
                {...getSkipReasonProps('resetOfDevices')}
              />
              {currentReportValues.resetOfDevices_comment && (
                <OverviewField label={t('Fields.Comment.Label')} value={currentReportValues.resetOfDevices_comment} />
              )}

              <OverviewField
                label={t('Fields.TestProducts.StudyEquipmentPool.Label')}
                value={getYesNoLabel(currentReportValues.studyEquipmentPool)}
                {...getSkipReasonProps('studyEquipmentPool')}
              />
              {currentReportValues.studyEquipmentPool_comment && (
                <OverviewField
                  label={t('Fields.Comment.Label')}
                  value={currentReportValues.studyEquipmentPool_comment}
                />
              )}
            </OverviewFieldGroup>
            <OverviewFieldGroup title={t('Fields.StudyGraduation.Title')}>
              <OverviewField
                label={t('Fields.StudyGraduation.ReferenceToStudyDocuments.Label')}
                value={getYesNoLabel(currentReportValues.referenceToStudyDocuments)}
                {...getSkipReasonProps('referenceToStudyDocuments')}
              />
              {currentReportValues.referenceToStudyDocuments_comment && (
                <OverviewField
                  label={t('Fields.Comment.Label')}
                  value={currentReportValues.referenceToStudyDocuments_comment}
                />
              )}
              <OverviewField
                label={t('Fields.StudyGraduation.ReferenceToAuthority.Label')}
                value={getYesNoLabel(currentReportValues.referenceToAuthority)}
                {...getSkipReasonProps('referenceToAuthority')}
              />
              {currentReportValues.referenceToAuthority_comment && (
                <OverviewField
                  label={t('Fields.Comment.Label')}
                  value={currentReportValues.referenceToAuthority_comment}
                />
              )}
              <OverviewField
                label={t('Fields.StudyGraduation.ClarificationOfPublication.Label')}
                value={getYesNoLabel(currentReportValues.clarificationOfPublication)}
                {...getSkipReasonProps('clarificationOfPublication')}
              />
              {currentReportValues.clarificationOfPublication_comment && (
                <OverviewField
                  label={t('Fields.Comment.Label')}
                  value={currentReportValues.clarificationOfPublication_comment}
                />
              )}
            </OverviewFieldGroup>
          </>
        )}

        <OverviewField label={t('Fields.Confirmation.Label')} value={currentReportValues.confirmation} />
      </div>
    </AppModalContainer>
  );
};

const Toolbar: FC<{ report: IMonitoringFormDto; onExport: () => void; onEdit: () => void }> = ({
  report,
  onExport,
  onEdit,
}) => {
  const { t } = useScopedTranslation('MonitoringForms.Overview.Modal');
  const { hasPermission } = useHasPermissions();

  return (
    <div className={styles.toolbar}>
      <div className={styles.leftPart}>
        <div className={styles.icon}>
          <EditIcon />
        </div>
        {report.createdAt.getTime() === report.updatedAt.getTime()
          ? t('Created', {
              date: localFormat(report.updatedAt, DateFormats.longDate),
              name: report.filledBy.fullName,
            })
          : t('Edited', {
              date: localFormat(report.updatedAt, DateFormats.longDate),
              name: report.filledBy.fullName,
            })}
      </div>
      <div className={styles.rightPart}>
        <AppButton Icon={ExportIcon} variant={'icon-link'} colorSchema={'primary'} onClick={onExport} />
        {hasPermission(Permissions.MonitoringReportCreate) && (
          <AppButton Icon={EditIcon} variant={'icon-link'} colorSchema={'primary'} onClick={onEdit} />
        )}
      </div>
    </div>
  );
};
