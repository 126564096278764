import React from 'react';
import { IText } from '../../../../forms/base/controls/content/Text';
import { RPDF } from '../../../react-pdf';

export const Text: IText = ({ text, isHiddenOnOverview }) => {
  if (isHiddenOnOverview) {
    return <RPDF.View />;
  }

  return (
    <RPDF.Text style={[RPDF.typography.text8, RPDF.tableStyles.divider, { paddingVertical: 4 }]}>{text}</RPDF.Text>
  );
};
