import { IFilesInput } from '../../../../../features/forms/base/controls/inputs/Files';
import React, { useContext } from 'react';
import { NoteContext } from '../../NoteContext';
import { NoteField } from '../../NoteField';
import { AppAttachmentValue } from 'uikit/fields/attachments/attachmentsField/appAttachmentsField';

export const Files: IFilesInput = (props) => {
  const noteContext = useContext(NoteContext);
  const inputValue = (noteContext.currentResultVersion.value?.[props.dataKey] as AppAttachmentValue[] | null) ?? null;
  const isOverview = noteContext.mode === 'overview';

  if (!inputValue?.length) return <></>;

  return <NoteField label={props.label ?? props.dataKey} attachments={inputValue} withoutCollapsible={isOverview} />;
};
