﻿import React, { useMemo, FC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import styles from './TagsModal.module.scss';
import { DropdownOption } from 'uikit/inputs/dropdown/appDropdownInput';
import { useModal, UseModalProps } from 'src/application/hooks/useModal';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { toDictionary } from 'src/helpers/arrayHelpers';
import { ValidationFormRules } from 'src/helpers/validation-helpers';
import { HookFormDropDown } from 'src/hookFormControls/hookFormDropDown';
import { TagFilters } from 'src/services/api/api-client';
import { Deferred } from '../../../../helpers/Deferred';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

type FormData = Record<string, DropdownOption<string>>;

export const useTagsModal = (tagFilters: TagFilters | null) => {
  const modal = useModal('CLOSED');
  const deferred = useRef<Deferred<Record<string, string>>>();

  return useMemo(
    () => ({
      open: () => {
        modal.openModal();
        deferred.current = new Deferred<Record<string, string>>();
        return deferred.current.promise;
      },
      element: tagFilters && (
        <TagsModal
          onSave={(value) => {
            deferred.current?.resolve(value);
            modal.closeModal();
          }}
          modal={modal}
          tagFilters={tagFilters}
          onClose={() => {
            deferred.current?.reject();
            modal.closeModal();
          }}
        />
      ),
    }),
    [modal, tagFilters],
  );
};

const tagValuesToOptions = (values: string[]): DropdownOption<string>[] => {
  return values.map((value) => ({ key: value, text: value }));
};

const TagsModal: FC<{
  onSave: (tags: Record<string, string>) => void;
  onClose: () => void;
  modal: UseModalProps;
  tagFilters: TagFilters;
}> = (props) => {
  const { t } = useScopedTranslation('Forms.TagsModal');
  const commonLocalizer = useCommonLocalization();
  const {
    onSave,
    onClose,
    modal: { visible },
  } = props;

  const tagFilters = useMemo(() => {
    return Object.keys(props.tagFilters)
      .filter((key) => Array.isArray(props.tagFilters[key]))
      .map((key) => ({ key: key, values: props.tagFilters[key] as string[] }));
  }, [props.tagFilters]);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return tagFilters
        .filter((x) => x.values.length === 1)
        .reduce(
          toDictionary(
            (x) => x.key,
            (x) => tagValuesToOptions(x.values)[0],
          ),
          {},
        );
    }, [tagFilters]),
  });

  const onSubmit = handleSubmit((data) => {
    const tags = Object.keys(data).reduce(
      toDictionary(
        (key) => key,
        (key) => data[key].key,
      ),
      {},
    );
    onSave(tags);
  });

  const fieldVms = useMemo(() => {
    return tagFilters
      .filter((x) => x.values.length > 1)
      .map((x) => ({
        key: x.key,
        options: tagValuesToOptions(x.values),
      }));
  }, [tagFilters]);

  return (
    <DialogModal
      visible={visible}
      onHide={onClose}
      testId={'select-tags-modal'}
      title={t('ModalHeader')}
      isClickOutside={false}
      containerClassName={styles.modal}
      bodyClassName={styles.modalBody}
      footer={{
        leftButton: {
          text: commonLocalizer('Common_Cancel'),
          type: 'reset',
          onClick: onClose,
        },
        rightButton: {
          text: commonLocalizer('Common_Save'),
          type: 'submit',
          disabled: !isValid,
          form: 'tagsForm',
        },
      }}
    >
      <form id={'tagsForm'} onSubmit={onSubmit} autoComplete={'off'}>
        {fieldVms.map((x) => (
          <HookFormDropDown
            key={x.key}
            isRequired={true}
            labelProps={{ text: x.key }}
            placeholder={t('Placeholder', { tagKey: x.key })}
            name={x.key}
            control={control}
            rules={{
              ...ValidationFormRules().requiredRule,
            }}
            options={x.options}
            errorProps={{ errors: errors?.[x.key]?.message }}
          />
        ))}
      </form>
    </DialogModal>
  );
};
