﻿import React, { FC, useCallback, useMemo } from 'react';
import styles from './overview.module.scss';
import clsx from 'clsx';
import { TypographyStyles } from 'styles';
import { AppAttachmentValue } from 'uikit/fields/attachments/attachmentsField/appAttachmentsField';
import { AppAttachmentsView } from 'uikit/fields';
import { DateFormats, localFormat } from 'src/helpers/date-helpers';
import { FieldEditReasonEnum, IFieldEditReasonWithStaff } from 'src/services/api/api-client';
import { ReactComponent as Cross } from 'src/assets/img/initialsurvey/bold_cross_16.svg';
import { ReactComponent as Update } from 'src/assets/img/initialsurvey/update_16.svg';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { useGetAllUsersQuery } from 'src/services/api/api-client/UsersQuery';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { DirectionalHint } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

type OverviewFieldProps = {
  className?: string;
  label: string;
  caption?: string;
  skipped?: boolean;
  skipReason?: string;
  value?: string | Date | boolean | { displayValue: string; key: string | number; icon?: JSX.Element } | null;
  values?: (string | { displayValue: string; key: string | number; icon?: JSX.Element })[] | null;
  attachments?: AppAttachmentValue[];
  editReason?: IFieldEditReasonWithStaff | undefined;
};

export const OverviewField: FC<OverviewFieldProps> = (props) => {
  const { t: commonLocalization } = useTranslation();
  const { t } = useScopedTranslation('Forms.Overview');

  const vms = useMemo(() => {
    const _vms = props.values
      ?.filter((x) => x)
      .map((x, i) => (typeof x === 'string' ? { key: i, displayValue: x } : x));

    if (_vms && _vms.length > 0) return _vms;

    if (!props.value) return [];

    return [
      typeof props.value === 'string'
        ? { key: 1, displayValue: props.value }
        : typeof props.value === 'boolean' && props.value === true
        ? { key: 1, displayValue: commonLocalization('Common_yes') }
        : props.value instanceof Date
        ? { key: 1, displayValue: localFormat(props.value) }
        : props.value,
    ];
  }, [commonLocalization, props.value, props.values]);

  return (
    <div className={clsx(styles.overviewRow, props.className)}>
      <div>
        <span className={TypographyStyles.heading2}>{props.label}</span>
        {props.caption && <span className={TypographyStyles.plainText14}>{props.caption}</span>}
      </div>
      <div className={clsx(TypographyStyles.plainText14, styles.value)}>
        {props.skipped && <span className={clsx(styles.skipReason)}>{props.skipReason}</span>}
        {!props.skipped &&
          vms.map((x) => (
            <span key={x.key}>
              {x.icon} {x.displayValue}
            </span>
          ))}
        {props.attachments && <AppAttachmentsView files={props.attachments} />}
        {!vms.length && !props.attachments?.length && !props.skipped && props.editReason && (
          <span className={styles.answerRemoved}>{t('AnswersRemoved')}</span>
        )}
      </div>
      {props.editReason && <EditReasonIcon editReason={props.editReason} />}
    </div>
  );
};

export const EditReasonIcon: FC<{ editReason: IFieldEditReasonWithStaff }> = ({ editReason }) => {
  const { t } = useScopedTranslation('Forms.Overview.EditFieldReasonIcon');
  const study = useStudy();
  const vm = useEditReasonType(editReason.editReason);
  const { data: userList } = useGetAllUsersQuery(
    {
      studyIds: [study!.id],
    },
    {
      enabled: !!study?.id,
      suspense: false,
      keepPreviousData: true,
    },
  );

  const fullName = useMemo(
    () => userList?.data.find((x) => x.id === editReason.editedById)?.fullName ?? t('UnknownUser'),
    [editReason.editedById, t, userList?.data],
  );

  const tooltip = useMemo(() => {
    return (
      <div className={styles.editReasonTooltip}>
        <div className={styles.editBy}>
          {t('EditedBy', {
            date: localFormat(editReason.editedAt, DateFormats.longDate),
            fullName: fullName,
          })}
        </div>
        <div className={styles.reason}>{vm.reason}</div>
        <div className={styles.reasonText}>{editReason?.editReasonText}</div>
      </div>
    );
  }, [editReason?.editReasonText, editReason.editedAt, fullName, t, vm.reason]);

  if (!editReason) return <div className={styles.dummy} />;

  return (
    <Tooltip
      text={''}
      tooltipContent={tooltip}
      styles={styles.iconWrapper}
      directionalHint={DirectionalHint.rightCenter}
      hostStyles={styles.tooltipHost}
    >
      {vm.icon}
    </Tooltip>
  );
};

const useEditReasonType = (editReason: FieldEditReasonEnum) => {
  const { t } = useScopedTranslation('Forms.Overview.EditFieldReasonIcon');

  return useMemo(
    () =>
      ({
        [FieldEditReasonEnum.Update]: {
          icon: <Update />,
          reason: t('Update'),
        },
        [FieldEditReasonEnum.WrongEntry]: {
          icon: <Cross />,
          reason: t('WrongEntry'),
        },
      }[editReason]),
    [editReason, t],
  );
};
