/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import clsx from 'clsx';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import styles from '../controlsStyle.module.css';
import {
  checkBoxGroupInputDefaultPropsFactory,
  checkBoxGroupApplicableValidationRules,
  ICheckBoxGroupInputProps,
  ICheckBoxGroupInput,
} from '../../../../features/forms/base/controls/inputs/CheckBoxGroupInput';
import {
  useRefForDataBlockNavigation,
  useDefaultValuesForConstructorMode,
  useFieldDataFromUiEditorContext,
  useFieldValidation,
  useSkipReasonText,
} from './base/hooks';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { getStatusIcon } from './statusIcons/statusIcons.constants';
import { ConditionalFieldBehaviorEnum } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { Container } from '../Containers/Container';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import OptionsSettingsSection from '../../toolbox/settingsPanel/content/OptionsSettingsSection';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { TitleSettings } from '../../toolbox/settingsPanel/content/TitleSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { AppCheckboxGroupField } from 'uikit/fields/checkboxGroup/AppCheckboxGroupField.component';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';

export const CheckBoxGroupInput: ICheckBoxGroupInput = (props) => {
  const {
    actions: { setProp: setSelfProp },
    connectors: { connect, drag },
    id,
    linkedNodes,
  } = useNode((state) => ({
    linkedNodes: state.data.linkedNodes,
  }));

  const { size, dataKey, label, isDisabled, skipCheckBoxText, hasSkipCheckBox, isEditable, isDisableWhenEditing } =
    props;
  const { multipleDefaultValue } = useDefaultValuesForConstructorMode(props);

  const {
    isConstructorMode,
    allNodes,
    actions: { setProp },
  } = useEditor((state) => ({
    isConstructorMode: state.options.enabled,
    allNodes: state.nodes,
  }));

  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const {
    isOnlyView,
    multipleInputValue,
    setDataBlockFieldValue,
    skipReason,
    onSkipReasonChange,
    isEditMode,
    fieldEditReason,
  } = useFieldDataFromUiEditorContext(dataKey);
  const skipReasonText = useSkipReasonText(skipReason, skipCheckBoxText);

  const blockRef = useRefForDataBlockNavigation();

  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  // This effect update conditional options state
  useLayoutEffect(() => {
    if (isConstructorMode) return;

    disableNotSelectedOptions();
  }, [multipleInputValue, isDisabled]);

  const disableNotSelectedOptions = useCallback(() => {
    const containersIds = Object.values(linkedNodes);
    if (!containersIds) {
      return;
    }

    const selectedOptions = props.options.filter((x) => multipleInputValue?.includes(x.value));

    const selectedOptionKeys = selectedOptions.map((o) => o.key.toString());
    Object.entries(linkedNodes).forEach(([key, containerId]) => {
      if (key === 'comment') return;

      // Disable option
      setProp(
        containerId,
        (p) => (p.isDisabled = isDisabled || !selectedOptionKeys.length || !selectedOptionKeys.includes(key)),
      );

      // NOTE: Here was removing values of disabled nodes
      // but it caused that some values were not displayed
      // in editing mode,
      // because it deleted values of other fields,
      // if they had the same DataKey
    });
  }, [isOnlyView, linkedNodes, props.options, setSelfProp, allNodes, multipleInputValue, isDisabled]);

  const onChange = useCallback(
    (newValues: string[] | null) => {
      if (isConstructorMode) {
        setSelfProp((setProps: ICheckBoxGroupInputProps) => {
          setProps.defaultValue = newValues ?? undefined;
        });
      } else {
        setDataBlockFieldValue?.(dataKey, newValues ?? undefined);
      }
    },
    [isConstructorMode],
  );

  const conditionalContainer = useMemo(() => {
    const optionsWithConditionalField = props.options.filter((x) => x.withConditionalField);

    const finalArray = isConstructorMode
      ? optionsWithConditionalField
      : optionsWithConditionalField.filter(
          (option) =>
            option.conditionalFieldBehavior !== ConditionalFieldBehaviorEnum.Visibility ||
            multipleInputValue?.includes(option.value),
        );

    return Array.from(finalArray, (option) => (
      <React.Fragment key={option.key}>
        {isConstructorMode && <div className={styles.tabLabel}>{option.displayValue ?? ''}</div>}
        <Element key={option.value} id={option.key.toString()} is={Container} canvas={true} flexBoxGap={12} />
      </React.Fragment>
    ));
  }, [props.options, isConstructorMode, multipleInputValue]);

  const disabled = useMemo(
    () => !isEditable || isDisabled || isSubmitting || (isDisableWhenEditing && isEditMode),
    [isDisableWhenEditing, isDisabled, isEditMode, isEditable, isSubmitting],
  );

  const fieldOptions = useMemo(
    () =>
      props.options.map((option) => ({
        displayValue: option.displayValue,
        value: option.value,
      })),
    [props.options, props.hasStatusIcons],
  );

  const editableComponent = (
    <div
      data-test-field-type={'checkbox-group-input'}
      className={styles.container}
      style={{
        width: size,
      }}
      draggable={isConstructorMode}
      ref={(ref) => isConstructorMode && connect(drag(ref!))}
    >
      <div ref={blockRef} className={clsx({ [styles.validationError]: !!dataBlockValidation?.text })}>
        <AppCheckboxGroupField
          labelProps={{
            text: !props.hideLabel ? props.label || dataKey : undefined,
            tooltip: props.tooltip,
            isBold: props.isBold,
          }}
          skipProps={{
            inputCanBeSkipped: hasSkipCheckBox,
            skipText: skipCheckBoxText,
            skipReason: skipReason,
            onSkipReasonChange: onSkipReasonChange(dataKey),
          }}
          value={isConstructorMode ? multipleDefaultValue : multipleInputValue}
          errorProps={{ errors: dataBlockValidation?.text }}
          disabled={disabled}
          options={fieldOptions}
          valueField={'value'}
          labelField={'displayValue'}
          onChange={onChange}
        />

        {conditionalContainer}
      </div>
    </div>
  );

  const displayValues = useMemo(() => {
    return props.options
      .filter((x) => multipleInputValue?.includes(x.value))
      .map((x) => ({
        key: x.key,
        icon: (props.hasStatusIcons && getStatusIcon(x.statusIconName)) || undefined,
        displayValue: x.displayValue,
      }));
  }, [multipleInputValue, props.options, props.hasStatusIcons]);

  const viewComponent = (
    <>
      <IssueMark
        issueContext={{
          subject: 'Patient',
          topic: 'Records',
          topicAdditional: formConfig?.type,
          linkedPatientUniqId: patient?.patientId,
          fieldDescription: props.label ?? props.dataKey,
          resultId: formResultVersion?.formResultId,
          fieldId: id,
          stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
        }}
        ignoreFieldsForCount={['stepName']}
        position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
      >
        <OverviewField
          label={label ?? dataKey}
          values={displayValues}
          skipped={skipReason !== undefined}
          skipReason={skipReasonText}
          editReason={fieldEditReason}
        />
      </IssueMark>
      {conditionalContainer.length > 0 && conditionalContainer}
    </>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

const CheckBoxInputSettings = () => {
  return (
    <>
      <TitleSettings />
      <OptionsSettingsSection hasVariable />
      <LayoutSettings />
      <ValidationSettings applicableRules={checkBoxGroupApplicableValidationRules} />
      <CommonFieldSettings />
    </>
  );
};

CheckBoxGroupInput.craft = {
  props: checkBoxGroupInputDefaultPropsFactory(),
  related: {
    settings: CheckBoxInputSettings,
  },
};
