import React, { FC } from 'react';
import { RPDF } from '../../../react-pdf';
import { ReportColors } from '../../../report-colors';

export const DoubleCrossIcon: FC = () => {
  return (
    <RPDF.Svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        d="M9.81809 3.06694C10.0622 2.82286 10.0622 2.42714 9.81809 2.18306C9.57401 1.93898 9.17828 1.93898 8.93421 2.18306L6.55864 4.55863L6.11669 5.00057L6.55864 5.44251L8.93421 7.81806C9.17829 8.06214 9.57401 8.06214 9.81809 7.81806C10.0622 7.57398 10.0622 7.17825 9.81809 6.93418L7.88446 5.00057L9.81809 3.06694ZM6.81808 2.1831C7.06216 2.42718 7.06216 2.82291 6.81808 3.06698L4.88446 5.00059L6.81808 6.9342C7.06216 7.17827 7.06216 7.574 6.81809 7.81808C6.57401 8.06216 6.17828 8.06216 5.9342 7.81808L4.00057 5.88447L2.06694 7.81808C1.82286 8.06216 1.42713 8.06216 1.18306 7.81808C0.93898 7.574 0.938981 7.17827 1.18306 6.9342L3.11669 5.00059L1.18306 3.06698C0.938985 2.82291 0.938983 2.42718 1.18306 2.1831C1.42714 1.93902 1.82287 1.93902 2.06694 2.1831L4.00057 4.11671L5.9342 2.1831C6.17828 1.93902 6.57401 1.93902 6.81808 2.1831Z"
        fill={ReportColors.green500}
      />
    </RPDF.Svg>
  );
};
