import React from 'react';
import { AppInputLabel, AppInputLabelProps, FieldSkipProps } from 'uikit/wrappers';
import { AppInputError, AppInputErrorProps } from 'uikit/wrappers';
import { AppRadioGroupInputProps } from '../../inputs/radio/appRadioGroupInput';
import { AppRadioGroupInput } from 'uikit/inputs';
import { AppInputSkip } from 'uikit/wrappers/skipCheckbox/AppInputSkip';

export type AppRadioGroupFieldProps<TOption, TValue extends string | number> = {
  labelProps: AppInputLabelProps;
  errorProps?: AppInputErrorProps;
  skipProps?: FieldSkipProps;
} & AppRadioGroupInputProps<TOption, TValue>;

export function AppRadioGroupField<TOption, TValue extends string | number = string | number>({
  errorProps,
  labelProps,
  skipProps,
  disabled,
  ...rest
}: AppRadioGroupFieldProps<TOption, TValue>) {
  return (
    <AppInputError {...errorProps}>
      <AppInputLabel disabled={disabled} {...labelProps}>
        <AppInputSkip
          inputCanBeSkipped={!!skipProps && skipProps.inputCanBeSkipped}
          disabled={disabled}
          skipReason={skipProps?.skipReason}
          skipText={skipProps?.skipText}
          onSkipReasonChange={skipProps?.onSkipReasonChange ?? (() => {})}
          position="top-4px"
        >
          <AppRadioGroupInput disabled={disabled} {...rest} />
        </AppInputSkip>
      </AppInputLabel>
    </AppInputError>
  );
}
