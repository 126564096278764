/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import clsx from 'clsx';
import React, { FC, useMemo, useState } from 'react';
import { IQuestionScheme, SurveyOption, SurveyQuestion } from '../../services/api/api-client';
import { AnswersTableRow } from './answerTableRow.component';
import { ReactComponent as Chevron } from '../../assets/img/blueChevron.svg';
import Style from './surveyTableRow.module.css';
import { getQuestionById } from '../../helpers/question-helper';
import _ from 'lodash';

export type ExpandedRowProps = {
  question: SurveyQuestion;
  groupedOptions: SurveyOption[];
  swipeIndex?: number;
  viewMode: 'single' | 'table';
  answers: (IQuestionScheme | null)[];
};

export const ExpandedRow: FC<ExpandedRowProps> = ({ question, groupedOptions, swipeIndex, viewMode, answers }) => {
  const isSelectedOption = useMemo(() => {
    const firstScheme = _.first(answers);
    if (!answers || !answers.length || !firstScheme) {
      return false;
    }

    const questionFromAnswerScheme = getQuestionById(firstScheme, question.id);
    if (!questionFromAnswerScheme) {
      return false;
    }

    const answeredOption = questionFromAnswerScheme.options.find((o) => o.answerValue !== null);
    if (!answeredOption) {
      return false;
    }

    return groupedOptions.map((o) => o.optionValue).includes(answeredOption.optionValue);
  }, [answers, groupedOptions, question.id]);

  const [isExpended, setIsExpended] = useState<boolean>(viewMode === 'single');

  const accordionContent = useMemo(
    () =>
      _.first(groupedOptions)?.questionScheme!.questions?.map((q, i) => (
        <div key={i} className={clsx(Style.accordionContainer, { [Style.accordionContainerOpen]: isExpended })}>
          <AnswersTableRow
            index={i + 1}
            parentQuestion={question}
            question={q}
            answers={answers}
            swipeIndex={swipeIndex}
            viewMode={viewMode}
          />
        </div>
      )),
    [groupedOptions, isExpended, question, answers, swipeIndex, viewMode],
  );

  if (viewMode === 'single' && !isSelectedOption) {
    return <></>;
  }

  return (
    <div className={Style.conditionOption}>
      {viewMode === 'single' ? (
        <></>
      ) : (
        <div className={clsx(Style.conditionalOptionText, Style.borderBottom)}>
          {groupedOptions.map((o) => o.optionText).join(', ')}
          <button
            className={Style.accordionBtn}
            onClick={() => {
              setIsExpended(!isExpended);
            }}
          >
            <Chevron
              className={clsx({
                [Style.accordionIcon]: true,
                [Style.accordionBtnRotated]: isExpended,
              })}
            />
          </button>
        </div>
      )}

      {accordionContent}
    </div>
  );
};
