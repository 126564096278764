import React from 'react';
import { useEditor } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import { TypographyStyles } from '../../../../styles';
import Style from './toolbar.module.css';
import clsx from 'clsx';

export const Toolbar = () => {
  const { t } = useTranslation();
  const { active, related } = useEditor((state, query) => {
    const currentlySelectedNodeId = query.getEvent('selected').first();
    return {
      active: currentlySelectedNodeId,
      related: currentlySelectedNodeId && state.nodes[currentlySelectedNodeId].related,
    };
  });

  return (
    <div className={Style.toolbarContainer}>
      {active ? (
        related.toolbar && React.createElement(related.toolbar)
      ) : (
        <div className={clsx(Style.placeholder, TypographyStyles.plaintext12)}>
          {t('FaqEditor.Toolbar.Placeholder')}
        </div>
      )}
    </div>
  );
};
