import {
  IFormControlsResolver,
  StubFieldGroupContainer,
} from '../../../../../features/forms/base/controls/IFormControlsResolver';
import { IDateInput } from '../../../../../features/forms/base/controls/inputs/DateInput';
import Style from '../lastNote.module.css';
import React, { FC, PropsWithChildren, useContext } from 'react';
import { LastNoteContext } from '../lastNote.component';
import { DateFormats, localFormat, toDateOrNull } from '../../../../../helpers/date-helpers';
import { DevError } from '../../../../../helpers/components/DevError/DevError.component';
import { PatientTableContext } from '../../../PatientTableContext';
import { ITextInput } from '../../../../../features/forms/base/controls/inputs/TextInput';
import { INumberInput } from 'src/features/forms/base/controls/inputs/INumberInput';

const DateField: IDateInput = (props) => {
  const patientTableContext = useContext(PatientTableContext);
  const noteContext = useContext(LastNoteContext);
  const inputValue = noteContext.answers?.[props.dataKey] as string | string[] | undefined | null;
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  if (props.dataKey === patientTableContext.mainNoteFieldDataKey) return <DevError messages={['Not implemented']} />;
  if (patientTableContext.noteDateDataKey !== props.dataKey) return <></>;

  const parsedDate = toDateOrNull(singleInputValue);

  if (!parsedDate) return <></>;

  return <p className={Style.lastNoteDate}>{localFormat(parsedDate, DateFormats.longDate)}</p>;
};

const TextField: ITextInput = (props) => {
  const patientTableContext = useContext(PatientTableContext);
  const noteContext = useContext(LastNoteContext);
  const inputValue = noteContext.answers?.[props.dataKey] as string | string[] | undefined | null;
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  if (props.dataKey === patientTableContext.noteDateDataKey) return <DevError messages={['Invalid configuration']} />;

  if (props.dataKey !== patientTableContext.mainNoteFieldDataKey) return <></>;

  if (!singleInputValue) return <></>;

  return (
    <p className={Style.mainField}>
      <span className={Style.mainFieldTitle}>{props.label ?? props.dataKey}:</span>
      {singleInputValue}
    </p>
  );
};

const NumberField: INumberInput = (props) => {
  const patientTableContext = useContext(PatientTableContext);
  const noteContext = useContext(LastNoteContext);
  const inputValue = noteContext.answers?.[props.dataKey] as string | string[] | undefined | null;
  const singleInputValue = Array.isArray(inputValue) ? inputValue[0] : inputValue;

  if (props.dataKey === patientTableContext.noteDateDataKey) return <DevError messages={['Invalid configuration']} />;
  if (props.dataKey !== patientTableContext.mainNoteFieldDataKey) return <></>;
  if (!singleInputValue) return <></>;

  return (
    <p className={Style.mainField}>
      <span className={Style.mainFieldTitle}>{props.label ?? props.dataKey}:</span>
      {`${props.decimal !== undefined ? Number(singleInputValue).toFixed(props.decimal) : singleInputValue} ${
        props.units ?? ''
      }`}
    </p>
  );
};

const StubContainer: FC<PropsWithChildren> = ({ children }) => <>{children}</>;
const StubContent: FC = () => <></>;
const StubField: FC<{ dataKey: string }> = (props) => {
  const patientTableContext = useContext(PatientTableContext);

  if (
    props.dataKey === patientTableContext.mainNoteFieldDataKey ||
    props.dataKey === patientTableContext.noteDateDataKey
  )
    return <DevError messages={['Not implemented']} />;

  return <></>;
};
export const lastNoteColumnFormControlsResolver: IFormControlsResolver = {
  DateInput: DateField,
  EmptyBlock: StubContent,
  Container: StubContainer,
  FieldGroupContainer: StubFieldGroupContainer,
  Text: StubContent,
  AttentionText: StubContent,
  Title: StubContent,
  TextInput: TextField,
  NumberInput: NumberField,
  SliderInput: StubField,
  TimeInput: StubField,
  CheckBoxInput: StubField,
  CheckBoxGroupInput: StubField,
  RadioInput: StubField,
  SelectInput: StubField,
  Files: StubField,
  StepContainer: StubContainer,
  TabsContainer: StubContainer,
  DateRangeInput: StubField,
  BmiInput: StubField,
  CalculatedBlock: StubField,
  GroupInput: StubField,
  ImageBlock: StubContent,
  TagInput: StubField,
  RecordResultList: StubField,
};
