import React, { FC } from 'react';
import { RPDF } from '../../../react-pdf';

export const SubmitIcon: FC = () => {
  return (
    <RPDF.Svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        d="M10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6ZM11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM8.84047 4.8638C9.04139 4.67437 9.0507 4.35792 8.86126 4.157C8.67183 3.95608 8.35538 3.94677 8.15446 4.1362L5.47971 6.65804L3.82149 5.26601C3.60999 5.08846 3.29461 5.11599 3.11706 5.32749C2.93951 5.53898 2.96704 5.85437 3.17853 6.03192L5.17752 7.71001L5.51828 7.99607L5.84199 7.69085L8.84047 4.8638Z"
        fill="#667878"
      />
    </RPDF.Svg>
  );
};
