import React, { FC } from 'react';
import { RPDF } from '../../../react-pdf';

export const RevokeIcon: FC = () => {
  return (
    <RPDF.Svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <RPDF.Path
        d="M6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10C6.95305 10 7.82704 9.66732 8.51414 9.11132C8.72881 8.93761 9.04365 8.97081 9.21735 9.18548C9.39106 9.40014 9.35785 9.71498 9.14319 9.88868C8.28457 10.5835 7.19025 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C7.49052 1 8.82814 1.65207 9.74394 2.68601V1.5C9.74394 1.22386 9.9678 1 10.2439 1C10.5201 1 10.7439 1.22386 10.7439 1.5V4.5V5H10.2439H7.5C7.22386 5 7 4.77614 7 4.5C7 4.22386 7.22386 4 7.5 4H9.46502C8.77316 2.80399 7.48001 2 6 2Z"
        fill="#667878"
      />
    </RPDF.Svg>
  );
};
