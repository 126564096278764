import { IDateRangeInput } from '../../../../../features/forms/base/controls/inputs/DateRangeInput';
import React, { useContext } from 'react';
import { NoteContext } from '../../NoteContext';
import { NoteField } from '../../NoteField';
import { formatDateRange, toDateOrNull } from '../../../../../helpers/date-helpers';

export const DateRangeInput: IDateRangeInput = (props) => {
  const noteContext = useContext(NoteContext);
  const inputValueStartDate = noteContext.currentResultVersion.value?.[`${props.dataKey}_from`] as
    | string
    | undefined
    | null;
  const inputValueEndDate = noteContext.currentResultVersion.value?.[`${props.dataKey}_until`] as
    | string
    | undefined
    | null;

  if (!inputValueEndDate && !inputValueStartDate) return <></>;

  return (
    <NoteField
      label={props.label ?? props.dataKey}
      value={formatDateRange(toDateOrNull(inputValueStartDate), toDateOrNull(inputValueEndDate))}
    />
  );
};
