import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { TypographyStyles } from '../../styles';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../application/localisation/useCommonLocalization';
import { Permissions } from 'src/services/api/api-client';
import { QueryFactory } from '../../services/api';
import { NumberParam, useQueryParams } from 'use-query-params';
import { createPagingParams, pagingSortingToBackendRequest } from '../../helpers/pagination-helper';
import {
  patientFilterParams,
  patientFilterToBackendRequest,
} from '../../components/patientTable/patientFilters.helpers';
import {
  IPatientListDto,
  IRouteProgressDto,
  RouteProgressStateEnum,
  PatientStudyStateEnum,
  UpdatePatientRouteProgressDto,
} from '../../services/api/api-client';
import { useModal } from '../../application/hooks/useModal';
import Style from './patientPipeline.module.scss';
import { AppButton } from 'uikit/buttons';
import { PipelineNode } from '../../components/pipeline/pipelineNode.component';
import { logger } from '../../application/logging/logging';
import { showErrorToast } from '../../components/toast/toast-helper';
import { PermissionsCheck } from '../../helpers/components/PermissionCheck/PermissionCheck.component';
import { PatientPipelineFilters } from './patientPipelineFilters';
import TablePageLayout from '../../components/tablePageLayout/TablePageLayout';
import { AppTable } from 'uikit/table/AppTable';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useSortBy } from 'uikit/table/updateSortByInUrl';
import { usePatientPipelinesColumns } from './patientPipelines.columns';
import { add, startOfToday } from 'date-fns';
import { AppTextInput } from 'uikit/inputs';
import { convertDateToString, toDateOrNull } from '../../helpers/date-helpers';
import { StudyNameComponent } from 'src/components/studyName/studyName.component';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { DialogModal } from 'src/components/dialogModal/dialogModal.component';

export const patientQueryParamsConfig = {
  ...createPagingParams({
    defaultSortBy: 'uniqueId',
    sortingKeys: ['uniqueId'] as const,
    defaultPerPage: 30,
    defaultDesc: false,
  }),
  studyId: NumberParam,
  ...patientFilterParams(null, PatientStudyStateEnum.InProgress),
};

export const PatientPipelineList = () => {
  const { t } = useScopedTranslation('StudySettingsPage.Patients', 'dev');
  const commonLocalization = useCommonLocalization();
  const { id: studyId, groups } = useStudy() ?? {};
  const { visible, closeModal, openModal } = useModal();
  const [patientId, setPatientId] = useState<string | undefined>();
  const [selectedPipeline, setSelectedPipeline] = useState<IRouteProgressDto>();
  const [stepsToOpen, setStepsToOpen] = useState<Record<string, Date | undefined>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [queryParams, setQueryParams] = useQueryParams(patientQueryParamsConfig);

  const patients = QueryFactory.PatientQuery.useGetPatientsQuery(
    {
      searchQuery: queryParams.searchQuery,
      ...pagingSortingToBackendRequest(queryParams),

      studyId: studyId,
      ...patientFilterToBackendRequest(queryParams),
      patientGroup: queryParams.patientGroup || null,
    },
    {
      suspense: false,
      keepPreviousData: true,
    },
  );

  const handleOnClick = useCallback(
    (patient: IPatientListDto) => {
      openModal();
      setPatientId(patient.id);
      setSelectedPipeline(patient.studyRouteProgress);
    },
    [openModal, setPatientId],
  );

  const onSave = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!patientId) {
        throw new Error('Patient is empty or incorrect data selected');
      }

      await QueryFactory.StudyRoutesQuery.Client.openRouteProgressStepManually(
        patientId,
        new UpdatePatientRouteProgressDto({ stepsToOpen: stepsToOpen as Record<string, Date> }),
      );

      await patients.refetch();
      closeModal();
    } catch (e: any) {
      logger().error('Updating pipeline has failed', e);
      showErrorToast(e);
    } finally {
      setIsLoading(false);
    }
  }, [closeModal, stepsToOpen, patientId, patients]);

  const openStepHandler = useCallback(
    (stepName: string) => () => {
      setStepsToOpen((state) => ({ ...state, [stepName]: add(startOfToday(), { days: 2 }) }));
    },
    [],
  );

  const sortBy = useSortBy(queryParams, setQueryParams);

  const dataTable = useReactTable({
    data: patients?.data?.data || [],
    manualSorting: true,
    state: {
      sorting: sortBy.sortingState,
    },
    onSortingChange: sortBy.onSortingChange,
    columns: usePatientPipelinesColumns(groups ?? [], handleOnClick),
    getCoreRowModel: getCoreRowModel(),
  });

  const pipelineBody = useMemo(() => {
    if (!patientId) return <></>;
    const patientUniqueId = patients.data!.data.find((x) => x.id === patientId)!.uniqueId;

    return (
      <div className={Style.pipelineContainer}>
        {selectedPipeline?.steps
          .filter((step) => step.state !== RouteProgressStateEnum.None)
          .map((step, index) => {
            const manuallyOpenedUntil =
              stepsToOpen[step.name] ??
              (step.state === RouteProgressStateEnum.InProgressManually ? step.manuallyOpenedUntil : null);

            return (
              <div key={step.name} className={Style.nodeWrapper}>
                <PipelineNode
                  routeProgress={selectedPipeline}
                  patientId={patientId}
                  patientUniqueId={patientUniqueId}
                  name={step.name}
                  shortName={step.shortName || ''}
                  prevStep={index ? selectedPipeline.steps[index - 1] : undefined}
                  step={{
                    ...step,
                    state:
                      stepsToOpen[step.name] !== undefined ? RouteProgressStateEnum.InProgressManually : step.state,
                  }}
                  isFinishNode={!!step.isFinalStep}
                />
                <PermissionsCheck permissions={Permissions.StudyConfigure}>
                  {(step.state === RouteProgressStateEnum.Missed ||
                    step.state === RouteProgressStateEnum.Done ||
                    step.state === RouteProgressStateEnum.Unexpected) &&
                    !manuallyOpenedUntil && (
                      <AppButton
                        colorSchema={'secondary'}
                        variant={'icon-link'}
                        text={t('Modal.Open')}
                        onClick={openStepHandler(step.name)}
                      />
                    )}
                  {manuallyOpenedUntil && (
                    <>
                      {t('Modal.CloseOn')}
                      <AppTextInput
                        type={'date'}
                        min={convertDateToString(startOfToday())}
                        value={convertDateToString(manuallyOpenedUntil)}
                        onChange={(e) => {
                          const newValue = toDateOrNull(e.target.value);
                          if (newValue !== null)
                            setStepsToOpen((oldState) => ({
                              ...oldState,
                              [step.name]: newValue,
                            }));
                        }}
                      />
                    </>
                  )}
                </PermissionsCheck>
              </div>
            );
          })}
      </div>
    );
  }, [patientId, patients.data, selectedPipeline, stepsToOpen, t, openStepHandler]);

  return (
    <TablePageLayout>
      <TablePageLayout.Header>
        <StudyNameComponent />
        <div className={clsx(TypographyStyles.buttonLinkText, Style.counter)}>
          {t('Caption', { count: patients?.data?.totalCount })}
        </div>
        <PatientPipelineFilters
          groups={groups ?? []}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          studyId={studyId!}
        />
      </TablePageLayout.Header>
      <TablePageLayout.TableContainer loading={patients.isPreviousData || patients.isLoading}>
        <AppTable
          table={dataTable}
          placeholder={{ hide: patients.isPreviousData || patients.isLoading, text: t('No_Data') }}
        />
        <TablePageLayout.TableContainer.Pagination
          page={queryParams.page}
          perPage={queryParams.perPage}
          totalCount={patients?.data?.totalCount ?? 0}
          changePagination={setQueryParams}
        />
      </TablePageLayout.TableContainer>

      <DialogModal
        onDismissed={() => {
          setPatientId(undefined);
          setStepsToOpen({});
          setSelectedPipeline(undefined);
        }}
        visible={visible}
        onHide={closeModal}
        title={t('Modal.Header')}
        containerClassName={Style.modal}
        bodyClassName={Style.body}
        footer={{
          leftButton: {
            text: commonLocalization('Common_Cancel'),
            onClick: closeModal,
            disabled: isLoading,
          },
          rightButton: {
            text: t('Modal.Save'),
            onClick: onSave,
            isLoading: isLoading,
            disabled: !patientId,
          },
        }}
      >
        {pipelineBody}
      </DialogModal>
    </TablePageLayout>
  );
};
