import React, { FC, Suspense } from 'react';
import { StudyNameComponent } from 'src/components/studyName/studyName.component';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { StudyInfoPageSection as StudyInfoPageSectionEnum } from 'src/services/api/api-client';
import { StudyPeriodSection } from './sections/StudyPeriodSection';
import { StudyInfoPageLayout, StudyInfoPageSection } from './StudyInfoPageLayout';

export const StudyInfoPage = () => {
  const study = useStudy();

  return (
    <Suspense>
      <StudyInfoPageLayout testId={'study-info-page'} isLoading={false}>
        <StudyInfoPageLayout.Header>
          <StudyNameComponent />
          {/* <StudyInfoPageLayout.Header.Filters>{'Filters'}</StudyInfoPageLayout.Header.Filters> */}
        </StudyInfoPageLayout.Header>
        <StudyInfoPageLayout.Columns>
          {study?.infoPageConfiguration?.sections.map((col, i) => (
            <StudyInfoPageLayout.Columns.Column key={i} colored={i % 2 !== 0}>
              {col.map((section) => StudyInfoSectionResolver[section])}
            </StudyInfoPageLayout.Columns.Column>
          ))}
        </StudyInfoPageLayout.Columns>
      </StudyInfoPageLayout>
    </Suspense>
  );
};

const NotImplemented: FC<{ title: string }> = ({ title }) => (
  <StudyInfoPageSection title={title}>
    <div>{'Not implemented yet'}</div>
  </StudyInfoPageSection>
);

const StudyInfoSectionResolver: Record<StudyInfoPageSectionEnum, JSX.Element> = {
  [StudyInfoPageSectionEnum.StudyPeriod]: <StudyPeriodSection />,
  [StudyInfoPageSectionEnum.Patients]: <NotImplemented title={'Patients'} />,
  [StudyInfoPageSectionEnum.Records]: <NotImplemented title={'Records'} />,
  [StudyInfoPageSectionEnum.Attachments]: <NotImplemented title={'Attachments'} />,
  [StudyInfoPageSectionEnum.Notes]: <NotImplemented title={'Notes'} />,
  [StudyInfoPageSectionEnum.Users]: <NotImplemented title={'Users'} />,
};
