﻿import styles from './appCheckboxGroupInput.module.scss';
import React, { useCallback } from 'react';
import { AppCheckboxInput } from 'uikit/inputs';
import { FieldsWithType } from '../../../../helpers/typeUtils';

export type AppCheckboxGroupInputProps<
  TOption extends Record<Key, string | number>,
  Key extends FieldsWithType<TOption, string | number>,
> = {
  options: TOption[];
  valueField: Key;
  labelField: keyof TOption;
  disabled?: boolean;
  value?: TOption[Key][];
  onChange?: (value: TOption[Key][] | null) => void;
  numerationStartIndex?: number;
};

export function AppCheckboxGroupInput<
  TOption extends Record<Key, string | number>,
  Key extends FieldsWithType<TOption, string | number>,
>({ disabled, options, value, valueField, labelField, onChange }: AppCheckboxGroupInputProps<TOption, Key>) {
  const onChangeHandler = useCallback(
    (option: TOption) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        onChange?.([...(value || []), option[valueField]]);
        return;
      }

      const newValue = value?.filter((x: any) => x !== option[valueField]);
      onChange?.(newValue?.length ? newValue : null);
    },
    [onChange, value, valueField],
  );

  return (
    <div>
      {options.map((option) => (
        <div key={option[valueField]} className={styles.checkbox}>
          <AppCheckboxInput
            key={option[valueField]}
            label={option[labelField].toString()}
            disabled={disabled}
            defaultChecked={value?.includes(option[valueField])}
            onChange={onChangeHandler(option)}
          />
        </div>
      ))}
    </div>
  );
}
