import React, { FC, useCallback, useMemo, useState } from 'react';
import Style from './roleList.module.scss';
import { ColumnDef, getCoreRowModel, getGroupedRowModel, GroupingState, useReactTable } from '@tanstack/react-table';
import { Trans, useTranslation } from 'react-i18next';
import TablePageLayout from 'src/components/tablePageLayout/TablePageLayout';
import { IRoleDto, Permissions } from 'src/services/api/api-client';
import { useGetRolesQuery } from 'src/services/api/api-client/UserRolesQuery';
import { useRoleCreationModal } from './useRoleCreationModal';
import { ReactComponent as UserPlusIcon } from 'assets/img/patient/user_plus.svg';
import { ReactComponent as TooltipIcon } from 'assets/img/common/tip_filled_20.svg';
import { ReactComponent as DeleteIcon } from 'assets/img/common/cross_16_1px.svg';
import { AppTable } from 'uikit/table/AppTable';
import { enumToStringArr } from 'src/helpers/enum-helper';
import { AppCheckboxInput } from 'uikit/inputs';
import { addPermission, removePermission, removeRole } from 'src/services/api/api-client/UserRolesClient';
import { QueryFactory } from 'src/services/api';
import { useQueryClient } from '@tanstack/react-query';
import { showErrorToast } from 'src/components/toast/toast-helper';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { LocalizedResourceDictionaryKeys } from 'src/application/localisation/i18next';
import { AppButton } from 'uikit/buttons';
import { ConfirmResult, useConfirmationModal } from 'src/components/dialogModal/useConfirmationModal';

export const RoleList = () => {
  const { t } = useTranslation('dev');
  const rolesQuery = useGetRolesQuery();
  const queryClient = useQueryClient();

  const createRoleModal = useRoleCreationModal();
  const permissionConfirmationModal = useConfirmationModal({
    containerClassName: Style.confirmationModalContainer,
  });

  const addPermissionHandler = useCallback(
    async (roleId: string, roleName: string, permission: Permissions, appliedPermissions: Permissions[]) => {
      const result = await permissionConfirmationModal.open({
        title: t('Admin.UserRoles.AddPermissionModal.Title'),
        text: (
          <span>
            <Trans
              t={t}
              i18nKey={'Admin.UserRoles.AddPermissionModal.Text'}
              values={{
                permission: permission,
                role: roleName,
              }}
              components={{
                Bold: <span className={Style.boldText} />,
              }}
            />
          </span>
        ),
        cancelButtonText: t('Admin.UserRoles.AddPermissionModal.CancelButton'),
        okButtonText: t('Admin.UserRoles.AddPermissionModal.OkButton'),
        okButtonColorSchema: 'primary',
      });
      if (result !== ConfirmResult.Confirm) return;

      await addPermission(roleId, permission);
      if (permission === Permissions.ApproveClosureForm && !appliedPermissions.includes(Permissions.PatientRelease)) {
        await addPermission(roleId, Permissions.PatientRelease);
      }
    },
    [permissionConfirmationModal, t],
  );

  const removePermissionHandler = useCallback(
    async (roleId: string, roleName: string, permission: Permissions) => {
      const result = await permissionConfirmationModal.open({
        title: t('Admin.UserRoles.RemovePermissionModal.Title'),
        text: (
          <span>
            <Trans
              t={t}
              i18nKey={'Admin.UserRoles.RemovePermissionModal.Text'}
              values={{
                permission: permission,
                role: roleName,
              }}
              components={{
                Bold: <span className={Style.boldText} />,
              }}
            />
          </span>
        ),
        cancelButtonText: t('Admin.UserRoles.RemovePermissionModal.CancelButton'),
        okButtonText: t('Admin.UserRoles.RemovePermissionModal.OkButton'),
        okButtonColorSchema: 'destroy',
      });
      if (result !== ConfirmResult.Confirm) return;

      await removePermission(roleId, permission);
    },
    [permissionConfirmationModal, t],
  );

  const changePermission = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      roleId: string,
      roleName: string,
      permission: Permissions,
      appliedPermissions: Permissions[],
    ) => {
      try {
        if (e.target.checked) {
          await addPermissionHandler(roleId, roleName, permission, appliedPermissions);
        } else {
          await removePermissionHandler(roleId, roleName, permission);
        }

        await queryClient.invalidateQueries(QueryFactory.UserRolesQuery.getRolesQueryKey());
      } catch (error) {
        showErrorToast(error);
      }
    },
    [addPermissionHandler, queryClient, removePermissionHandler],
  );

  const roles = useMemo(() => rolesQuery.data || [], [rolesQuery.data]);

  const [grouping, setGrouping] = React.useState<GroupingState>(['groupName']);
  const [columnVisibility, setColumnVisibility] = useState({
    groupName: false,
  });

  const tableData = useMemo(
    () =>
      enumToStringArr(Permissions)
        .map((x) => {
          return {
            permission: x,
            groupName:
              t(`Admin.UserRoles.PermissionGroups.${permissionAndGroup[x]}` as LocalizedResourceDictionaryKeys) ??
              t('Admin.UserRoles.PermissionGroups.Other'),
            ...roles.reduce((obj, cur) => ({ ...obj, [cur.name]: cur.permissions.includes(Permissions[x]) }), {}),
          } satisfies RoleRow;
        })
        .sort(orderByPermissionGroup),
    [roles, t],
  );

  const rowDataTable = useReactTable<RoleRow>({
    data: tableData,
    manualSorting: false,
    state: {
      grouping,
      columnVisibility,
    },
    columns: useRolesColumns(roles, changePermission),
    getCoreRowModel: getCoreRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
  });

  return (
    <>
      <TablePageLayout className={Style.tablePage}>
        <TablePageLayout.Header>
          <TablePageLayout.Header.Row>
            <TablePageLayout.Header.Title text={t('Admin.UserRoles.Caption')} />
          </TablePageLayout.Header.Row>
        </TablePageLayout.Header>
        <TablePageLayout.TableContainer loading={rolesQuery.isLoading || rolesQuery.isPreviousData}>
          <AppTable
            table={rowDataTable}
            placeholder={{
              hide: rolesQuery.isLoading || rolesQuery.isPreviousData,
              text: t('Admin.UserRoles.No_Data'),
            }}
          />
          <TablePageLayout.TableContainer.FloatingButton onClick={createRoleModal.start} Icon={UserPlusIcon} />
        </TablePageLayout.TableContainer>
      </TablePageLayout>
      {createRoleModal.modal}
      {permissionConfirmationModal.modal}
    </>
  );
};

const useRolesColumns = (
  roles: IRoleDto[],
  onChangePermission: (
    e: React.ChangeEvent<HTMLInputElement>,
    roleId: string,
    roleName: string,
    permission: Permissions,
    appliedPermissions: Permissions[],
  ) => Promise<void>,
): ColumnDef<RoleRow, any>[] => {
  const { t } = useTranslation('dev');

  return useMemo(
    () =>
      [
        {
          accessorKey: 'groupName',
          header: '',
          enableSorting: false,
          enableHiding: true,
          meta: {
            cellClassName: Style.cellClassName,
          },
        },
        {
          accessorKey: 'permission',
          header: '',
          enableSorting: false,
          size: 240,
          meta: {
            cellClassName: Style.permissionCell,
          },
          cell: ({ row }) => {
            const permission = t(
              `Admin.UserRoles.Permissions.${row.original.permission}.ShortName` as LocalizedResourceDictionaryKeys,
            );
            const tooltipText = t(
              `Admin.UserRoles.Permissions.${row.original.permission}.Tooltip` as LocalizedResourceDictionaryKeys,
            );

            return (
              <div className={Style.permissionColumn}>
                <div>{permission}</div>
                <Tooltip text={tooltipText}>
                  <TooltipIcon />
                </Tooltip>
              </div>
            );
          },
        },
        ...roles?.map((x, i) => ({
          id: x.id,
          accessorKey: x.name,
          header: () => <ColumnHeader role={x} />,
          enableSorting: false,
          size: 120,
          cell: ({ row }: any) => {
            const disabled =
              row.original.permission === Permissions.RoleAssign ||
              // `Permissions.ApproveClosureForm` makes sense only with `Permissions.PatientRelease` as we automatically
              (x.permissions.includes(Permissions.ApproveClosureForm) &&
                row.original.permission === Permissions.PatientRelease);

            return (
              <div className={Style.checkboxWrapper}>
                <AppCheckboxInput
                  disabled={disabled}
                  checked={row.original[x.name]}
                  onChange={(e) => onChangePermission(e, x.id, x.name, row.original.permission, x.permissions)}
                />
              </div>
            );
          },
          meta: {
            isColored: !(i % 2),
            headerClassName: Style.header,
            cellClassName: Style.cellClassName,
          },
        })),
        {
          accessorKey: 'dummy',
          header: '',
          enableSorting: false,
          meta: {
            dontUseHeaderWidth: true,
            cellClassName: Style.cellClassName,
          },
        },
      ] satisfies ColumnDef<RoleRow, any>[],
    [onChangePermission, roles, t],
  );
};

const ColumnHeader: FC<{ role: IRoleDto }> = ({ role }) => {
  const { t } = useTranslation('dev');
  const queryClient = useQueryClient();
  const confirmationModal = useConfirmationModal();

  const onDelete = useCallback(async () => {
    const result = await confirmationModal.open({
      title: t('Admin.UserRoles.RemoveRoleModal.Title'),
      text: (
        <span>
          <Trans
            t={t}
            i18nKey={'Admin.UserRoles.RemoveRoleModal.Text'}
            values={{
              roleName: role.name,
            }}
            components={{
              Bold: <span className={Style.boldText} />,
            }}
          />
        </span>
      ),
      cancelButtonText: t('Admin.UserRoles.RemoveRoleModal.CancelButton'),
      okButtonText: t('Admin.UserRoles.RemoveRoleModal.OkButton'),
      okButtonColorSchema: 'destroy',
    });

    if (result === ConfirmResult.Confirm) {
      try {
        await removeRole(role.id);
        await queryClient.invalidateQueries(QueryFactory.UserRolesQuery.getRolesQueryKey());
      } catch (error) {
        showErrorToast(error);
      }
    }
  }, [confirmationModal, queryClient, role.id, role.name, t]);

  return (
    <>
      <div className={Style.customHeader}>
        <div>{role.name}</div>
        <AppButton
          variant={'icon-link'}
          colorSchema={'basic'}
          Icon={DeleteIcon}
          onClick={onDelete}
          iconClassName={Style.deleteIcon}
        />
      </div>
      {confirmationModal.modal}
    </>
  );
};

export const PermissionGroups = [
  'Study',
  'User',
  'Patient',
  'Issue',
  'Analysis',
  'Approve',
  'Export',
  'Participants',
  'Ue Form',
  'Monitoring',
  'Other',
] as const;
export type PermissionGroup = (typeof PermissionGroups)[number];

const permissionAndGroup: { [key in keyof typeof Permissions]: PermissionGroup } = {
  StudyManage: 'Study',
  StudyConfigure: 'Study',
  CloseStudy: 'Study',

  RoleAssign: 'User',
  UserManage: 'User',

  PatientView: 'Patient',
  PatientCreate: 'Patient',
  PatientRelease: 'Patient',
  PatientArchive: 'Patient',

  IssueView: 'Issue',
  IssueCreate: 'Issue',
  IssueProcess: 'Issue',
  IssueApprove: 'Issue',
  IssueDiscuss: 'Issue',

  AnalysisPresetView: 'Analysis',
  AnalysisPresetManage: 'Analysis',
  AnalysisView: 'Analysis',
  AnalysisManage: 'Analysis',

  ApproveForm: 'Approve',
  ApproveClosureForm: 'Approve',
  ApproveClosureStudy: 'Approve',

  ExportForm: 'Export',
  ExportPopulation: 'Export',
  ExportPatientEcrfReport: 'Export',
  ExportAuditLog: 'Export',

  ParticipantsView: 'Participants',
  ParticipantsEdit: 'Participants',
  ParticipantsApprove: 'Participants',

  UeFormCreate: 'Ue Form',
  UeFormEdit: 'Ue Form',

  MonitoringReportView: 'Monitoring',
  MonitoringReportCreate: 'Monitoring',
};

type RoleRow = {
  groupName: PermissionGroup;
  permission: string;
};

const orderByPermissionGroup = (a: RoleRow, b: RoleRow) => {
  if (PermissionGroups.indexOf(a.groupName) < PermissionGroups.indexOf(b.groupName)) return -1;
  if (PermissionGroups.indexOf(a.groupName) > PermissionGroups.indexOf(b.groupName)) return 1;
  return 0;
};
