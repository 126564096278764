import React, { FC, useMemo } from 'react';
import { subTimeUnitFromDate, formatDateRange, localFormat, DateFormats } from '../../../helpers/date-helpers';
import { IPatientListDto } from '../../../services/api/api-client';
import { FinishStudyComponent } from '../../finishStudy/finishStudy.component';
import { StartStudyComponent } from '../../startStudy/startStudy.component';
import Style from './studyPeriodSection.module.scss';
import { Permissions } from 'src/services/api/api-client';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const StudyPeriodSection = (patient: IPatientListDto) => {
  const study = useStudy();
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo');

  const actionComponent = useMemo(() => {
    if (patient.studyStartedAt) {
      return (
        <div className={Style.actionComponent}>
          <span>{formatDateRange(patient.studyStartedAt, subTimeUnitFromDate(patient.studyFinishDate, 'days'))}</span>
          {!study?.hasEcrf && (
            <PermissionsCheck permissions={Permissions.PatientRelease}>
              <FinishStudyComponent patient={patient} />
            </PermissionsCheck>
          )}
        </div>
      );
    }

    return (
      <div className={Style.actionComponent}>
        <span>{t('StartStudy.NotSpecified')}</span>
        <PermissionsCheck permissions={Permissions.PatientCreate}>
          <StartStudyComponent patientId={patient.id} studyId={patient.studyId} />
        </PermissionsCheck>
      </div>
    );
  }, [patient, study?.hasEcrf, t]);

  return (
    <div className={Style.container} data-test-id={'patient-card-period'}>
      <StudyPeriodSectionItem name={t('PlannedStudyPeriod')} value={actionComponent} />
      {patient.studyStartedAt && patient.isStudyFinished && (
        <>
          <StudyPeriodSectionItem
            name={t('RealStudyPeriod')}
            value={formatDateRange(patient.studyStartedAt, subTimeUnitFromDate(patient.studyFinishedAt, 'seconds'))}
          />
          {!study?.hasEcrf && (
            <StudyPeriodSectionItem
              name={t('ReasonOfFinish')}
              value={patient.studyFinishedDescription ?? t('FinishStudy.Modal.Reason.Planned')}
            />
          )}
        </>
      )}
      {patient.archivedBy && patient.archivedAt && (
        <StudyPeriodSectionItem
          name={t('ArchiveSection.Title')}
          value={t('ArchiveSection.Text', {
            date: localFormat(patient.archivedAt, DateFormats.longDate),
            user: patient.archivedBy.fullName,
          })}
        />
      )}
    </div>
  );
};

export const StudyPeriodSectionItem: FC<{ name: string; value: string | JSX.Element }> = ({ name, value }) => {
  return (
    <div className={Style.itemContainer}>
      <div>{name}</div>
      <div>{value}</div>
    </div>
  );
};
