import React, { useEffect, useMemo } from 'react';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { useEditor, useNode } from '@craftjs/core';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from 'src/components/formEditor/uiEditor/provider/formFill.context';
import { useGetPatientFormList } from './useGetFormList';
import { RecordResultContainer, RecordResultItem } from './RecordResultContainer';
import Style from './RecordResultList.module.scss';
import clsx from 'clsx';
import { Loading } from 'uikit/suspense/Loading';
import { IRecordResultList, RecordResultListItemData } from 'src/features/forms/base/controls/inputs/RecordResultList';
import { useFieldDataFromUiEditorContext } from '../../Inputs/base/hooks';
import _ from 'lodash';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { EditReasonIcon } from 'uikit/fields/overview/OverviewField';

export const RecordResultList: IRecordResultList = ({ dataKey }) => {
  const { t } = useScopedTranslation('Forms.Controls.RecordResultList');
  const study = useStudy();
  const patient = useContextSelector(FormFillContext, (v) => v.patient);

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const {
    connectors: { connect, drag },
  } = useNode();

  const isOnlyView = useContextSelector(FormFillContext, (v) => v.mode === 'Overview');
  const { multipleInputValue, setDataBlockFieldValue, fieldEditReason } = useFieldDataFromUiEditorContext(dataKey);
  const inputValueArr: RecordResultListItemData[] = useMemo(
    () => (multipleInputValue?.length ? multipleInputValue.map((str) => JSON.parse(str)) : []),
    [multipleInputValue],
  );

  const { isLoading, mandatoryForms, multipleForms } = useGetPatientFormList(
    patient?.id,
    study?.patientFinishingFormId,
    {
      approvedOnly: !isOnlyView,
    },
  );

  // This effect updates result if the version number changes
  useEffect(() => {
    if (isOnlyView || ![...mandatoryForms, ...multipleForms].length) return;

    const newValues = [...mandatoryForms, ...multipleForms]
      .flatMap((x) => x.formResults)
      .map(
        (x) =>
          ({
            resultId: x.resultId,
            versionNumber: _.last(x.versions)!.versionNumber,
            comment: inputValueArr.find((a) => a.resultId === x.resultId)?.comment,
          } satisfies RecordResultListItemData),
      );

    const isAnyVersionChanges =
      !inputValueArr.length ||
      inputValueArr.some(
        (val) => val.versionNumber !== newValues.find((x) => x.resultId === val.resultId)?.versionNumber,
      );
    if (!isAnyVersionChanges) return;

    const stringifiedValues = newValues.map((x) => JSON.stringify(x));
    setDataBlockFieldValue?.(dataKey, stringifiedValues, { dontTouchField: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlyView, isLoading, mandatoryForms, multipleForms]);

  const content = useMemo(
    () => (
      <>
        {mandatoryForms.length > 0 && (
          <RecordResultContainer
            header={t('ObligatoryForms')}
            isOnlyView={isOnlyView}
            rightComponent={fieldEditReason && isOnlyView ? <EditReasonIcon editReason={fieldEditReason} /> : undefined}
          >
            {mandatoryForms.map(({ formType, formResults }) => (
              <RecordResultItem
                key={formType}
                formType={formType}
                formResults={formResults}
                isOnlyView={isOnlyView}
                dataKey={dataKey}
              />
            ))}
          </RecordResultContainer>
        )}
        {multipleForms.length > 0 && (
          <RecordResultContainer header={t('MultipleForms')} isOnlyView={isOnlyView}>
            {multipleForms.map(({ formType, formResults }) => (
              <RecordResultItem
                key={formType}
                formType={formType}
                formResults={formResults}
                isOnlyView={isOnlyView}
                dataKey={dataKey}
              />
            ))}
          </RecordResultContainer>
        )}
      </>
    ),
    [dataKey, fieldEditReason, isOnlyView, mandatoryForms, multipleForms, t],
  );

  if (isLoading) return <Loading loading={isLoading} />;

  return (
    <div
      className={clsx(Style.mainContainer, { [Style.overviewPaddings]: isOnlyView })}
      draggable={enabled}
      ref={(ref) => enabled && connect(drag(ref!))}
    >
      {enabled ? <div>{"Placeholder for record's result list"}</div> : content}
    </div>
  );
};
