import React, { FC, useMemo } from 'react';
import styles from './StudyPeriodSection.module.scss';
import { CardValue, StudyInfoCard, StudyInfoPageSection } from '../StudyInfoPageLayout';
import { useStudyClosing } from 'src/features/study/useStudyClosing';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { formatDateRange } from 'src/helpers/date-helpers';
import { addDays, formatDuration, intervalToDuration } from 'date-fns';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';

export const StudyPeriodSection = () => {
  const { t } = useScopedTranslation('StudyInfoPage.StudyPeriodSection');
  const study = useStudy();
  const { buttonComponent: closeStudyButtonComponent, cardComponent: closeStudyCardComponent } = useStudyClosing();

  const plannedCloseAt = useMemo(() => study && addDays(study.startDate, study.duration), [study]);
  const timeLeft = useMemo(
    () =>
      plannedCloseAt &&
      intervalToDuration({
        start: new Date(),
        end: plannedCloseAt,
      }),
    [plannedCloseAt],
  );
  const duration: Duration | undefined = useMemo(
    () =>
      !study
        ? undefined
        : plannedCloseAt && study.startDate
        ? intervalToDuration({
            start: study.startDate,
            end: plannedCloseAt,
          })
        : { days: study.duration },
    [plannedCloseAt, study],
  );

  return (
    <StudyInfoPageSection
      title={t('Title')}
      contentClassName={styles.container}
      rightHeader={formatDateRange(study?.startDate, study?.closingApprovedAt ?? plannedCloseAt)}
    >
      <StudyInfoCard className={styles.card}>
        <div className={styles.cardTitle}>{t('Duration')}</div>
        <div className={styles.durationSection}>
          <CardPeriodValue duration={duration} periodType={'years'} />
          <CardPeriodValue duration={duration} periodType={'months'} />
          <CardPeriodValue duration={duration} periodType={'days'} />
        </div>
      </StudyInfoCard>
      {closeStudyCardComponent ?? (
        <StudyInfoCard className={styles.card}>
          <div className={styles.cardTitle}>
            <div>{t('TimeLeft')}</div>
            {closeStudyButtonComponent}
          </div>
          <div className={styles.durationSection}>
            <CardPeriodValue duration={timeLeft} periodType={'years'} />
            <CardPeriodValue duration={timeLeft} periodType={'months'} />
            <CardPeriodValue duration={timeLeft} periodType={'days'} />
          </div>
        </StudyInfoCard>
      )}
    </StudyInfoPageSection>
  );
};

const CardPeriodValue: FC<{
  duration: Duration | undefined;
  periodType: keyof Duration;
}> = ({ duration, periodType }) => {
  if (!duration?.[periodType]) return <></>;

  return (
    <CardValue
      mainText={duration[periodType]!.toString()}
      secondText={formatDuration({ [periodType]: duration[periodType] }).replace(/[0-9\s]*/g, '')}
    />
  );
};
