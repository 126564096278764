import { useModal } from '../../../application/hooks/useModal';
import Style from './useFormEditing.module.scss';
import React, { useCallback, useRef, useState } from 'react';
import {
  IFormDto,
  IFormResultDto,
  IFormResultVersionDto,
  IRouteProgressDto,
  RouteProgressStateEnum,
} from '../../../services/api/api-client';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { logger } from '../../../application/logging/logging';
import { FormModalWithContext } from 'src/components/formEditor/modals/formModalWithContext';
import getAnswersFromPrevFormResult from './getInitialAnswers';
import useApplyPatientFilter from '../../patients/useApplyPatientFilter';
import { useClosedStepModal } from './closedStepModal/ClosedStepModal';
import { getPatientRouteProgress } from '../../../services/api/api-client/StudyRoutesClient';
import { getFormById } from '../../../services/api/api-client/FormsClient';
import { Deferred } from '../../../helpers/Deferred';
import _ from 'lodash';
import { DataType } from 'src/components/formEditor/uiEditor/uiEditor';
import { ConfirmResult, useConfirmationModal } from 'src/components/dialogModal/useConfirmationModal';
import { ShowRecallReason } from '../overview/useRecallApprovalReason.component';
import { getPatientRouteProgressQueryKey } from 'src/services/api/api-client/StudyRoutesQuery';
import {
  getFormResultByIdQueryKey,
  getFormResultDatesQueryKey,
  getFormResultsQueryKey,
} from 'src/services/api/api-client/FormsQuery';

export type EditingResult = { type: 'Saved'; formResult: IFormResultDto } | { type: 'Canceled' };
export type StartEditingArgs = {
  formResult: Promise<IFormResultDto> | IFormResultDto;
  routeProgress?: Promise<IRouteProgressDto> | IRouteProgressDto;
  stepName: string | undefined;
  form?: IFormDto;
  asSubmitted?: boolean;
  recallReason?: string;
};

export type FillingResult = { type: 'Saved'; formResult: IFormResultDto } | { type: 'Canceled' };
export type StartFillingArgs = {
  patientId: string | undefined;
  routeProgress?: Promise<IRouteProgressDto> | IRouteProgressDto;
  formId: number;
  stepName?: string;
};

const documentsWithPatientStatus = ['Stationäre Aufenthalt'];

export const useFormEditing = (options?: { testId?: string }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const applyPatientFilter = useApplyPatientFilter();

  const editModal = useModal();
  const createModal = useModal();
  const clinicStateModal = useConfirmationModal();
  const closedStepModal = useClosedStepModal();

  const [patientId, setPatientId] = useState<string>();
  const [formConfig, setFormConfig] = useState<IFormDto>();
  const [formResultVersion, setFormResultVersion] = useState<IFormResultVersionDto>();
  const [prevAnswers, setInitialAnswers] = useState<DataType>();
  const [routeStepName, setRouteStepName] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [editAsSubmitted, setEditAsSubmitted] = useState<boolean>();
  const [recallReason, setRecallReason] = useState<string>();

  //#region Start filling

  const deferredFillingResult = useRef<Deferred<FillingResult>>();

  const startFormFilling = useCallback(
    async (args: StartFillingArgs) => {
      setIsLoading(true);
      const deferredResult = new Deferred<FillingResult>();
      deferredFillingResult.current = deferredResult;

      try {
        const formConfigFromServer = await getFormById(args.formId);
        if (args.stepName && args.patientId) {
          const _routeProgress = (await args.routeProgress) ?? (await getPatientRouteProgress(args.patientId));
          const stepState = _routeProgress.steps.find((x) => x.name === args.stepName)?.state;

          if (
            stepState !== RouteProgressStateEnum.InProgress &&
            stepState !== RouteProgressStateEnum.InProgressManually
          ) {
            await closedStepModal.open({ formType: formConfigFromServer.type, stepName: args.stepName });
            deferredResult.resolve({ type: 'Canceled' });
            return deferredResult.promise;
          }

          const answersFromPrevResults = await getAnswersFromPrevFormResult(
            args.stepName,
            formConfigFromServer.id,
            _routeProgress!,
            args.patientId,
          );

          setInitialAnswers(answersFromPrevResults ?? undefined);
        }

        setPatientId(args.patientId);
        setFormConfig(formConfigFromServer);
        setRouteStepName(args.stepName);
      } catch (error: any) {
        deferredResult.reject(error);
        logger().error(error);
      } finally {
        setIsLoading(false);
      }

      createModal.openModal();
      return deferredResult.promise;
    },
    [closedStepModal, createModal],
  );

  //#endregion

  //#region Start editing

  const deferredEditingResult = useRef<Deferred<EditingResult>>();

  const startFormEditing = useCallback(
    async (args: StartEditingArgs) => {
      setIsLoading(true);
      const deferredResult = new Deferred<EditingResult>();
      deferredEditingResult.current = deferredResult;

      try {
        const _formResult = await args.formResult;
        if (!args.form) {
          args.form = await getFormById(_formResult.formConfigId);
        }

        const lastFormResultVersion = _.last(_formResult.versions)!;

        if (lastFormResultVersion.isSkipped) {
          const _routeProgress = (await args.routeProgress) ?? (await getPatientRouteProgress(_formResult.patientId));

          const answersFromPrevResults = args.stepName
            ? await getAnswersFromPrevFormResult(args.stepName, args.form.id, _routeProgress!, _formResult.patientId)
            : null;

          setInitialAnswers(answersFromPrevResults ?? {});
        }

        setRecallReason(args.recallReason);
        setEditAsSubmitted(args.asSubmitted);
        setFormResultVersion(_.last(_formResult.versions));
        setPatientId(_formResult.patientId);
        setFormConfig(args.form);
        setRouteStepName(args.stepName);
      } catch (error: any) {
        deferredResult.reject(error);
        logger().error(error);
      } finally {
        setIsLoading(false);
      }

      editModal.openModal();

      return deferredResult.promise;
    },
    [editModal],
  );

  //#endregion

  const onResultSaved = useCallback(
    async (savedFormResult: IFormResultDto) => {
      await Promise.all([
        queryClient.invalidateQueries(getFormResultsQueryKey()),
        queryClient.invalidateQueries(getFormResultDatesQueryKey()),
        queryClient.invalidateQueries(getPatientRouteProgressQueryKey(patientId!)),
        queryClient.invalidateQueries(getFormResultByIdQueryKey(savedFormResult.id)),
      ]);

      if (documentsWithPatientStatus.includes(formConfig?.type || '')) {
        const result = await clinicStateModal.open({
          text: t('Dashboard.InClinicModal.Text'),
          title: t('Dashboard.InClinicModal.Title'),
          cancelButtonText: t('Common_no'),
          okButtonText: t('Common_yes'),
          okButtonFirst: true,
        });

        await applyPatientFilter(patientId!, 'isClinic', result === ConfirmResult.Confirm);
      }

      // This is two different promises. One is used for Fill and another one for Edit form.
      deferredEditingResult.current?.resolve({ type: 'Saved', formResult: savedFormResult });
      deferredFillingResult.current?.resolve({ type: 'Saved', formResult: savedFormResult });
    },
    [queryClient, patientId, formConfig?.type, clinicStateModal, t, applyPatientFilter],
  );

  return {
    isLoading,
    startFormFilling,
    startFormEditing,
    element: (
      <React.Fragment key={formConfig?.id}>
        {formConfig && (
          <FormModalWithContext
            mode={'Create'}
            formConfig={formConfig}
            prevAnswersValue={prevAnswers}
            formResultVersion={undefined}
            modal={{
              title: t('Forms.FillInModal.CreateFormHeader', { formType: formConfig.type }),
              subTitle: (
                <>
                  {routeStepName}
                  <ShowRecallReason className={Style.recallReason} recallReason={recallReason} />
                </>
              ),
              testId: options?.testId || `${formConfig.type}-form`,
              visible: createModal.visible,
              onHide: () => {
                createModal.closeModal();
                deferredFillingResult.current?.resolve({ type: 'Canceled' });
              },
            }}
            patientId={patientId!}
            stepName={routeStepName}
            onSubmitted={onResultSaved}
          />
        )}
        {formConfig && (
          <FormModalWithContext
            mode={'Edit'}
            formConfig={formConfig}
            prevAnswersValue={prevAnswers}
            formResultVersion={formResultVersion}
            modal={{
              title: t('Forms.FillInModal.EditFormHeader', { formType: formConfig.type }),
              subTitle: (
                <>
                  {routeStepName}
                  <ShowRecallReason className={Style.recallReason} recallReason={recallReason} />
                </>
              ),
              testId: options?.testId || `${formConfig.type}-form`,
              visible: editModal.visible,
              onHide: () => {
                editModal.closeModal();
                deferredEditingResult.current?.resolve({ type: 'Canceled' });
              },
            }}
            patientId={patientId!}
            editAsSubmitted={editAsSubmitted}
            stepName={routeStepName}
            onSubmitted={onResultSaved}
          />
        )}
        {closedStepModal.element}
        {clinicStateModal.modal}
      </React.Fragment>
    ),
  };
};
