import React, { FC, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IAdminStudyDto, IPatchStudyDto, PatchStudyDto } from '../../../services/api/api-client';
import Style from './editStudy.module.scss';
import { ValidationFormRules } from '../../../helpers/validation-helpers';
import { AppButton } from 'uikit/buttons';
import { useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { QueryFactory } from '../../../services/api';
import { pascalToCamelCase } from '../../../helpers/error-helpers';
import { logger } from '../../../application/logging/logging';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';
import { AppStepper } from '../../../components/stepper/stepper.component';
import clsx from 'clsx';
import styles from '../../../components/formEditor/controls/controlsStyle.module.css';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { AppSwitcherInput } from 'uikit/inputs';
import { AppInputError } from 'uikit/wrappers';
import { HookFormTextInput } from '../../../hookFormControls/hookFormTextInput';
import { ConfirmResult, useConfirmationModal } from 'src/components/dialogModal/useConfirmationModal';

export const EditStudyInfoModalContent: FC<{
  study: IAdminStudyDto;
  onClose: () => void;
  initialTab?: number;
}> = (props) => {
  const { t } = useTranslation('dev');
  const commonLocalizer = useCommonLocalization();
  const studyNumberWarningModal = useConfirmationModal();
  const [internalError, setInternalError] = useState(false);
  const client = useQueryClient();
  const [currentTab, setCurrentTab] = useState(props.initialTab || 1);
  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    clearErrors,
    setError,
    control,
  } = useForm<IPatchStudyDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: props.study.title,
      description: props.study.description,
      studyNumber: props.study.studyNumber,
      therapyAggregationPeriod: props.study.therapyAggregationPeriod,
      hasTherapy: props.study.hasTherapy,
      hasMonitoring: props.study.hasMonitoring,
      hasPdfSigning: props.study.hasPdfSigning,
      hasEcrf: props.study.hasEcrf,
      hasDbImport: props.study.hasDbImport,
      hasSurveys: props.study.hasSurveys,
      patientStudyStartAdvanceDays: props.study.patientStudyStartAdvanceDays,
    },
  });

  const onSubmit = handleSubmit(async (data: IPatchStudyDto) => {
    try {
      setInternalError(false);
      clearErrors();

      if (props.study.studyNumber !== data.studyNumber) {
        const result = await studyNumberWarningModal.open({
          title: t('Admin.StudyList.Details.StudyNumberWarningModal.Title'),
          text: (
            <span>
              <Trans
                t={t}
                i18nKey={'Admin.StudyList.Details.StudyNumberWarningModal.Text'}
                components={{
                  Bold: <span className={styles.boldText} />,
                }}
              />
            </span>
          ),
          cancelButtonText: t('Admin.StudyList.Details.StudyNumberWarningModal.CancelButton'),
          okButtonText: t('Admin.StudyList.Details.StudyNumberWarningModal.OkButton'),
        });

        if (result !== ConfirmResult.Confirm) {
          return;
        }
      }

      await QueryFactory.StudyQuery.Client.updateSettings(props.study.id, new PatchStudyDto(data));
      await Promise.all([
        client.invalidateQueries(QueryFactory.StudyQuery.getStudyListQueryKey()),
        client.invalidateQueries(QueryFactory.ProfileQuery.getDoctorProfileInfoQueryKey()),
      ]);

      props.onClose();
    } catch (ex: any) {
      if (ex.errors && Object.keys(ex.errors).length > 0) {
        for (const errorKey of Object.keys(ex.errors)) {
          const formKey = pascalToCamelCase(errorKey) as keyof IPatchStudyDto;
          setError(formKey, {
            type: 'server',
            message: t(`ServerErrors.${ex.errors[errorKey][0]}` as LocalizedResourceDictionaryKeys) as string,
          });
        }
      } else {
        logger().error(ex);
        setInternalError(true);
      }
    }
  });

  const content = useMemo(() => {
    return (
      <>
        <div className={clsx({ [styles.stepVisibility]: currentTab !== 1 })}>
          <HookFormTextInput
            control={control}
            name={'title'}
            labelProps={{ text: t('Admin.StudyList.Details.Modal.Title.FieldLabel') }}
            placeholder={t('Admin.StudyList.Details.Modal.Title.FieldPlaceholder')}
            rules={ValidationFormRules().requiredRule}
          />

          <HookFormTextInput
            type={'text-area'}
            control={control}
            name={'description'}
            labelProps={{ text: t('Admin.StudyList.Details.Modal.Descriptions.FieldLabel') }}
            placeholder={t('Admin.StudyList.Details.Modal.Descriptions.FieldPlaceholder')}
          />

          <HookFormTextInput
            control={control}
            name={'studyNumber'}
            labelProps={{ text: t('Admin.StudyList.Details.Modal.StudyNumber.FieldLabel') }}
            placeholder={t('Admin.StudyList.Details.Modal.StudyNumber.FieldPlaceholder')}
            rules={ValidationFormRules().requiredRule}
          />
        </div>

        <div className={clsx({ [styles.stepVisibility]: currentTab !== 2 })}>
          <HookFormTextInput
            control={control}
            type={'number'}
            name={'therapyAggregationPeriod'}
            labelProps={{ text: t('Admin.StudyList.Details.Modal.Aggregation.FieldLabel') }}
            placeholder={t('Admin.StudyList.Details.Modal.Aggregation.FieldPlaceholder')}
            rules={{ ...ValidationFormRules().requiredRule, ...ValidationFormRules().digitsOnlyRule }}
          />

          <HookFormTextInput
            control={control}
            type={'number'}
            name={'patientStudyStartAdvanceDays'}
            labelProps={{ text: t('Admin.StudyList.Details.Modal.PatientStudyStartAdvanceDays.FieldLabel') }}
            placeholder={t('Admin.StudyList.Details.Modal.PatientStudyStartAdvanceDays.FieldPlaceholder')}
            rules={{ ...ValidationFormRules().requiredRule, ...ValidationFormRules().digitsOnlyRule }}
          />

          <Controller
            control={control}
            name={'hasTherapy'}
            render={({ field: { onChange, value } }) => (
              <AppInputError errors={errors?.hasTherapy?.message}>
                <AppSwitcherInput
                  label={t('Admin.StudyList.Details.Modal.HasTherapy.FieldLabel')}
                  onChange={onChange}
                  checked={value}
                />
              </AppInputError>
            )}
          />

          <Controller
            control={control}
            name={'hasMonitoring'}
            render={({ field: { onChange, value } }) => (
              <AppInputError errors={errors?.hasMonitoring?.message}>
                <AppSwitcherInput
                  label={t('Admin.StudyList.Details.Modal.HasMonitoring.FieldLabel')}
                  onChange={onChange}
                  checked={value}
                />
              </AppInputError>
            )}
          />

          <Controller
            control={control}
            name={'hasPdfSigning'}
            render={({ field: { onChange, value } }) => (
              <AppInputError errors={errors?.hasPdfSigning?.message}>
                <AppSwitcherInput
                  label={t('Admin.StudyList.Details.Modal.HasPdfSigning.FieldLabel')}
                  onChange={onChange}
                  checked={value}
                />
              </AppInputError>
            )}
          />

          <Controller
            control={control}
            name={'hasDbImport'}
            render={({ field: { onChange, value } }) => (
              <AppInputError errors={errors?.hasDbImport?.message}>
                <AppSwitcherInput
                  label={t('Admin.StudyList.Details.Modal.HasDbImport.FieldLabel')}
                  onChange={onChange}
                  checked={value}
                />
              </AppInputError>
            )}
          />

          <Controller
            control={control}
            name={'hasEcrf'}
            render={({ field: { onChange, value } }) => (
              <AppInputError errors={errors?.hasEcrf?.message}>
                <AppSwitcherInput
                  label={t('Admin.StudyList.Details.Modal.HasEcrf.FieldLabel')}
                  onChange={onChange}
                  checked={value}
                />
              </AppInputError>
            )}
          />

          <Controller
            control={control}
            name={'hasSurveys'}
            render={({ field: { onChange, value } }) => (
              <AppInputError errors={errors?.hasEcrf?.message}>
                <AppSwitcherInput
                  label={t('Admin.StudyList.Details.Modal.HasSurveys.FieldLabel')}
                  onChange={onChange}
                  checked={value}
                />
              </AppInputError>
            )}
          />
        </div>
      </>
    );
  }, [control, errors, t, currentTab]);

  return (
    <>
      <form onSubmit={onSubmit} className={Style.container} autoComplete={'off'}>
        <AppStepper
          activeStep={currentTab}
          stepCount={2}
          variant={'tabs'}
          labels={['General', 'Configurable']}
          onStepClick={setCurrentTab}
        />

        <div className={Style.content}>{content}</div>

        <div className={Style.content}>
          <AppInputError
            errors={internalError ? t('Admin.StudyList.Details.Modal.Error') : undefined}
            hideBorder
            position={'top'}
          >
            <div className={Style.buttonsGroup}>
              <AppButton
                variant={'button'}
                colorSchema={'secondary'}
                text={commonLocalizer('Common_Cancel')}
                type={'reset'}
                onClick={props.onClose}
                className={Style.button}
              />
              <AppButton
                variant={'button'}
                colorSchema={'primary'}
                text={commonLocalizer('Common_Save')}
                type={'submit'}
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting && !internalError}
                className={Style.button}
              />
            </div>
          </AppInputError>
        </div>
      </form>
      {studyNumberWarningModal.modal}
    </>
  );
};
