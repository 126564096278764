import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useSurveyByType } from '../../../helpers/indicators-helper';
import { QualityMetric, ScoreCalculationStatusEnum } from '../../../services/api/api-client';
import { IndicatorWithStatsModalProps } from '../baseIndicator';
import { BaseIndicatorWrapper } from '../baseIndicator.component';
import Style from '../baseIndicator.module.css';
import { getIndicatorStyleColor } from 'src/helpers/color-helper';
import { getTooltipText } from '../tooltip-helper';
import { WarningSignIndicator } from '../warning.component';
import { useSurveyIcon } from '../../../application/hooks/useSurveyIcon';
import { ErrorScoreCalculation } from '../../errorScoreCalculation/errorScoreCalculation.component';
import { useTranslation } from 'react-i18next';

export const SurveyIndicator = (props: IndicatorWithStatsModalProps) => {
  const { survey, collection, patientId } = props;
  const { t } = useTranslation();
  const surveyIndicatorModel = useSurveyByType(survey.typeString, collection);
  const SurveyIcon = useSurveyIcon(
    props.survey.icon,
    'medium',
    surveyIndicatorModel?.isSurveyFilled && surveyIndicatorModel.qualityMetric === QualityMetric.NotCalculated
      ? 'dashed'
      : 'solid',
    surveyIndicatorModel?.qualityMetric,
  );

  const handleIconClick = useCallback(() => {
    if (
      !surveyIndicatorModel ||
      (surveyIndicatorModel?.qualityMetric === QualityMetric.Empty &&
        surveyIndicatorModel.scoreStatus === ScoreCalculationStatusEnum.NotCalculated)
    ) {
      return;
    }

    props.handleSurveyStatisticsClick?.(
      survey.typeString,
      patientId,
      survey.typeString,
      surveyIndicatorModel?.answerId ?? null,
    );
  }, [patientId, props, survey, surveyIndicatorModel]);

  const icon = useMemo(() => {
    if (
      !surveyIndicatorModel?.isSurveyFilled &&
      (surveyIndicatorModel?.hasSurveyAlreadyExpired || surveyIndicatorModel?.isSurveyCloseToExpired)
    ) {
      const shouldHideWarningSign =
        surveyIndicatorModel.daysBeforeExpiring < 0 &&
        surveyIndicatorModel.expiredStateDisplayDurationInDays > 0 &&
        Math.abs(surveyIndicatorModel.daysBeforeExpiring) >= surveyIndicatorModel.expiredStateDisplayDurationInDays;

      if (!shouldHideWarningSign) {
        return (
          <WarningSignIndicator
            isNotCritical={surveyIndicatorModel.isSurveyCloseToExpired && !surveyIndicatorModel.hasSurveyAlreadyExpired}
          />
        );
      }
    }

    return (
      <SurveyIcon.SvgIcon
        onClick={handleIconClick}
        className={clsx(getIndicatorStyleColor(surveyIndicatorModel?.qualityMetric), Style.base)}
      />
    );
  }, [SurveyIcon, handleIconClick, surveyIndicatorModel]);

  const tooltipText = useMemo(() => {
    return getTooltipText(surveyIndicatorModel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyIndicatorModel, t]);

  if (
    surveyIndicatorModel?.scoreStatus === ScoreCalculationStatusEnum.Error ||
    surveyIndicatorModel?.scoreStatus === ScoreCalculationStatusEnum.InProcess
  ) {
    return (
      <ErrorScoreCalculation
        retryHandlerType={'RetrySurvey'}
        answerId={surveyIndicatorModel.answerId}
        onClick={handleIconClick}
        canRetry={surveyIndicatorModel.canRetryScoreCalculation}
        isInProcess={surveyIndicatorModel?.scoreStatus === ScoreCalculationStatusEnum.InProcess}
      />
    );
  }

  return (
    <BaseIndicatorWrapper
      testId={survey.typeString}
      {...tooltipText}
      isEnabled={surveyIndicatorModel?.qualityMetric !== QualityMetric.Empty}
    >
      {icon}
    </BaseIndicatorWrapper>
  );
};
