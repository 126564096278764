import React, { useCallback, useContext, useState } from 'react';
import { AppButton } from 'src/components/uikit/buttons/button/button.component';
import { StatisticsComponentProps } from './survey';
import Style from './survey.module.scss';
import clsx from 'clsx';
import { IStatisticsItemDto, QualityMetric, ScoreCalculationStatusEnum } from 'src/services/api/api-client';
import { TypographyStyles } from '../../../styles';
import { getIndicatorStyleColor } from 'src/helpers/color-helper';
import { SurveyFilling } from './surveyFilling/surveyFilling.container';
import { SurveyAnswers } from '../../../containers/surveyAnswers/surveyAnswers.component';
import { useModal } from '../../../application/hooks/useModal';
import { DailyCheckCalendar } from '../../../containers/dailyCheckCalendar/dailyCheckCalendar.component';
import { useSurveyIcon } from '../../../application/hooks/useSurveyIcon';
import { SurveyStatisticsIndicator } from './surveyStatisticsIndicator.component';
import { Permissions } from 'src/services/api/api-client';
import { PermissionsCheck } from '../../../helpers/components/PermissionCheck/PermissionCheck.component';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import { HardcodedSurveys } from '../../../application/constants/hardoced-entities';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { PatientCardContext } from '../PatientCardContext';

export const StatisticsComponent = (props: StatisticsComponentProps) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection');
  const commonLocalizer = useCommonLocalization();
  const surveyModal = useModal();
  const calendarModal = useModal();
  const [answerId, setAnswerId] = useState<number | null | undefined>();
  const { SvgIcon: SeeAllIcon } = useSurveyIcon(props.surveyIcon, 'large', 'stacked');
  const { SvgIcon: StatIcon } = useSurveyIcon(props.surveyIcon, 'large', 'solid');
  const {
    patient: { uniqueId: patientUniqId },
  } = useContext(PatientCardContext);

  const renderPlaceholder = useCallback(() => {
    return (
      <div className={Style.surveyPlaceholderContainer}>
        <div>
          <StatIcon className={clsx(Style.icon, getIndicatorStyleColor(QualityMetric.Empty))} />
        </div>
        <div>{t('LastAvailableStats.NoDataYet')}</div>
      </div>
    );
  }, [StatIcon, t]);

  const indicatorClickHandler = useCallback(
    (statisticsItem: IStatisticsItemDto) => {
      if (
        statisticsItem.qualityMetric === QualityMetric.Empty &&
        statisticsItem.scoreStatus === ScoreCalculationStatusEnum.NotCalculated
      ) {
        return;
      }

      setAnswerId(statisticsItem.answerId);

      surveyModal.openModal();
    },
    [surveyModal],
  );

  const renderContent = useCallback(() => {
    if (props.surveyType === HardcodedSurveys.activityCheckType) {
      return (
        <div className={Style.activityCheckItem}>
          <StatIcon />

          {props.statisticValues?.length && (
            <p className={clsx(Style.iconsContainerSign, TypographyStyles.plainText14)}>
              {props.statisticValues && props.statisticValues.length ? props.statisticValues[0].score : 0}
            </p>
          )}
        </div>
      );
    }

    return props.statisticValues?.map((value, i) => {
      if (!value.startDate) {
        return null;
      }

      return (
        <SurveyStatisticsIndicator
          key={i}
          surveyIcon={props.surveyIcon}
          statisticItem={value}
          // Turn off overview for dailyCheck #34857
          onClick={
            props.surveyType === HardcodedSurveys.dailyCheckType ? undefined : () => indicatorClickHandler(value)
          }
        />
      );
    });
  }, [StatIcon, indicatorClickHandler, props.statisticValues, props.surveyIcon, props.surveyType]);

  const onSeeAllButtonPress = useCallback(() => {
    setAnswerId(undefined);
    if (props.surveyType === HardcodedSurveys.dailyCheckType) {
      calendarModal.openModal();
    } else {
      surveyModal.openModal();
    }
  }, [calendarModal, props.surveyType, surveyModal]);

  return (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Surveys',
        linkedPatientUniqId: patientUniqId,
        topicAdditional: props.surveyType,
      }}
    >
      <div className={Style.statisticsGroup}>
        <div className={Style.statisticsGroupHeaderContainer}>
          <div className={Style.leftHeader}>
            <h2 className={clsx(Style.statisticsGroupHeader, TypographyStyles.heading2)}>{props.surveyType}</h2>
            {props.headerExplainerText && !props.isStatisticsEmpty && (
              <p className={clsx(Style.statisticsGroupHeaderExplainer, TypographyStyles.plainText14)}>
                {props.headerExplainerText}
              </p>
            )}
          </div>
          <div>{props.rightHeader}</div>
        </div>
        <PermissionsCheck permissions={Permissions.PatientCreate}>
          {(accessAllowed) =>
            props.isStatisticsEmpty &&
            (!props.questionnaire?.canBeFilledByDoctor || props.isStudyFinished || !accessAllowed) ? (
              renderPlaceholder()
            ) : (
              <div className={Style.iconsContainer}>
                {accessAllowed && StatIcon && props.questionnaire?.canBeFilledByDoctor && (
                  <SurveyFilling
                    surveyType={props.surveyType}
                    patientId={props.patientId}
                    buttonIcon={StatIcon}
                    questionnaire={props.questionnaire}
                  />
                )}

                {renderContent()}

                {!props.isStatisticsEmpty && SeeAllIcon && props.surveyType !== HardcodedSurveys.activityCheckType && (
                  <AppButton
                    onClick={onSeeAllButtonPress}
                    textClassName={Style.btnSign}
                    variant={'icon-link'}
                    colorSchema={'secondary'}
                    Icon={SeeAllIcon}
                    text={commonLocalizer('Common_SeeAll_Short')}
                    verticalAlign={true}
                    testId={`show-survey-overview-button--${props.surveyType}`}
                  />
                )}
              </div>
            )
          }
        </PermissionsCheck>

        <SurveyAnswers
          title={t('StatisticsModal.Title', { survey: props.surveyType })}
          isVisible={surveyModal.visible}
          onClose={surveyModal.closeModal}
          patientId={props.patientId}
          surveyType={props.surveyType}
          answerId={answerId}
        />

        <DailyCheckCalendar
          isVisible={calendarModal.visible}
          onClose={calendarModal.closeModal}
          patientId={props.patientId}
        />
      </div>
    </IssueMark>
  );
};
