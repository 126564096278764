import React, { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import _ from 'lodash';
import { DataType, SkippedFieldsDataType } from '../../uiEditor/uiEditor';

export const useGetFieldChanges = () => {
  const {
    initialValues,
    values,
    initialSkippedFieldsData: initialSkippedFields,
    skippedFieldsData: skippedFields,
  } = useContextSelector(FormFillContext, (x) => x);

  const valueChanges: DataType = useMemo(() => twoWayDiff(values ?? {}, initialValues ?? {}), [values, initialValues]);
  const skipFieldChanges: SkippedFieldsDataType = useMemo(
    () => twoWayDiff(skippedFields ?? {}, initialSkippedFields ?? {}),
    [skippedFields, initialSkippedFields],
  );

  const anyChanges = useMemo(
    () => !_.isEmpty(valueChanges) || !_.isEmpty(skipFieldChanges),
    [skipFieldChanges, valueChanges],
  );

  const anyEditedFields =
    Object.keys(valueChanges).some((key) => _.has(initialValues, key)) ||
    Object.keys(skipFieldChanges).some((key) => _.has(initialSkippedFields, key));

  return { valueChanges, skipFieldChanges, anyChanges, anyEditedFields };
};

/**
 * This method returns the two-way difference between the objects (include removed fields)
 * @param obj1
 * @param obj2
 */
const twoWayDiff = (obj1: Record<string, any>, obj2: Record<string, any>) => {
  const newEntries = baseDiff(obj1, obj2);
  const removedEntries = Object.keys(obj2)
    .filter((key) => obj1[key] === undefined)
    .reduce((result, key) => ({ ...result, [key]: undefined }), {});

  return { ...newEntries, ...removedEntries };
};

/**
 * This method returns difference an object based another one.
 * This method doesn't track removed fields.
 * @param object
 * @param base
 */
const baseDiff = (object: Record<string, any>, base: Record<string, any>) =>
  Object.entries(object).reduce((result: Record<string, any>, [key, value]) => {
    if (isEqual(value, base[key])) return { ...result };

    const changesValue = _.isArray(value) && _.isArray(base[key]) ? _.difference(value, base[key] as string[]) : value;
    return { ...result, [key]: changesValue };
  }, {});

/**
 * Modified lodash _.isEqual method
 * @param a
 * @param b
 */
const isEqual = (a: any, b: any): boolean => {
  if (_.isEqual(a, b)) return true;

  // Empty array is equal to undefined
  if ((_.isArray(a) || _.isObject(a)) && ((_.isEmpty(a) && b === undefined) || (_.isEmpty(b) && a === undefined)))
    return true;

  return false;
};
