import { createContext } from 'use-context-selector';
import { IGroupDto } from 'src/services/api/api-client';
import { FormListType } from 'src/components/formEditor/controls/ConstantBlocks/RecordResultList/useGetFormList';

export type RecordsReportContextType = {
  studyGroups: IGroupDto[];
  patientId: string;
  mandatoryForms?: FormListType[];
  multipleForms?: FormListType[];
  studyHasEcrf: boolean;
};

export const RecordsReportContext = createContext<RecordsReportContextType>(undefined as any);
