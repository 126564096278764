﻿/**
 * @param testId Provided to differentiate several components of one type in one parent component
 * @param postfix Provided by component itself.
 *
 * @example
 * console.log(buildTestId('notes-section', 'loading')) // notes-section--loading
 * console.log(buildTestId(undefined, 'card-section')) // card-section
 * console.log(buildTestId('cancel', undefined) // cancel
 */
export const buildTestId = (testId: string | undefined | null, postfix: string | undefined | null) => {
  if (testId && postfix) return `${testId}--${postfix}`;
  if (testId && !postfix) return testId;
  if (!testId && postfix) return postfix;
  return undefined;
};
