import React, { useCallback } from 'react';
import { trackEvent } from '../../application/analitics/matomo/matomo-tracker';
import { MetricCategories } from '../../application/analitics/matomo/matomo-categories';
import { MetricActions } from '../../application/analitics/matomo/matomo-actions';
import { ReactComponent as UserPlus } from '../../assets/img/patient/user_plus.svg';
import Style from './addPatientButton.module.scss';
import TablePageLayout from '../tablePageLayout/TablePageLayout';
import Portal from 'uikit/portal/Portal';
import { useFormEditing } from 'src/features/forms/editing/useFormEditing';
import { useQueryClient } from '@tanstack/react-query';
import { QueryFactory } from 'src/services/api';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const AddPatientButton = () => {
  const study = useStudy();
  const form = useFormEditing({ testId: 'Initial-form' });
  const queryClient = useQueryClient();

  const onShow = useCallback(async () => {
    if (!study?.patientCreationFormBinding?.id) return;
    await form.startFormFilling({ formId: study?.patientCreationFormBinding?.id, patientId: undefined });
    await queryClient.invalidateQueries(QueryFactory.ProfileQuery.getDoctorProfileInfoQueryKey());
    trackEvent({ action: MetricActions.ButtonPressed, category: MetricCategories.Patient, name: 'AddPatientButton' });
  }, [form, study?.patientCreationFormBinding?.id, queryClient]);

  if (study?.closingApprovedAt) return <></>;

  return (
    <>
      <TablePageLayout.TableContainer.FloatingButton
        onClick={onShow}
        Icon={UserPlus}
        className={Style.button}
        testId={'create-patient-button'}
      />

      <Portal>
        {/* Portal is used because */}
        {/* some element from Fluent UI adds extra <span/> */}
        {/* and it causes a gap between the floating button and this <span/> */}
        {form.element}
      </Portal>
    </>
  );
};
