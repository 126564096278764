import React from 'react';
import { formatRelative } from 'date-fns';
import { getCurrentDateLocale } from '../../../application/localisation/localization';
import { IPatientListDto } from '../../../services/api/api-client';
import { CodeGeneration } from '../../patientAccessCodeGenerationForm/codeGeneration.component';
import { ExpandedRowHeader } from '../header/expandedRowHeader.component';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { useCommonLocalization } from '../../../application/localisation/useCommonLocalization';
import PropertiesList from 'uikit/propertiesList/PropertiesList';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const AppInformation = (patient: IPatientListDto) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo');
  const commonLocalizer = useCommonLocalization();
  const study = useStudy();

  return (
    <div data-test-id={'patient-card-appinfo'}>
      <ExpandedRowHeader text={t('AppInformation')} />

      <PropertiesList>
        {!patient.isStudyFinished && (
          <PropertiesList.Property
            name={t('AppCode.Caption')}
            value={
              <CodeGeneration
                codeGenerated={patient.codeGenerated}
                patientId={patient.id}
                studyNumber={study?.studyNumber || ''}
              />
            }
          />
        )}

        <PropertiesList.Property
          name={t('LastAppUse')}
          value={
            patient.lastVisitDateTime
              ? formatRelative(patient.lastVisitDateTime, Date.now(), getCurrentDateLocale())
              : commonLocalizer('Common_Never')
          }
        />
      </PropertiesList>
    </div>
  );
};
