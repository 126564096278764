import React from 'react';
import { ContainerProps } from '.';
import { ToolbarItem } from '../../editor/toolbar/toolbar.item';
import { ToolbarSection } from '../../editor/toolbar/toolbar.section';

export const ContainerSettings = () => {
  return (
    <>
      <ToolbarSection<ContainerProps>
        title={'Dimensions'}
        props={['width', 'height']}
        summary={({ width, height }) => {
          return `${width || 'auto'} x ${height || 0}`;
        }}
      >
        <ToolbarItem<ContainerProps> propKey={'width'} type="text" label="Width" />
        <ToolbarItem<ContainerProps> propKey={'height'} type="text" label="Height" />
      </ToolbarSection>

      <ToolbarSection<ContainerProps>
        title={'Colors'}
        props={['background']}
        summary={({ background }) => <div style={{ background: background, height: 24, padding: 4 }}>{background}</div>}
      >
        <ToolbarItem<ContainerProps> propKey={'background'} type={'color'} label={'Background'} />
      </ToolbarSection>

      <ToolbarSection<ContainerProps>
        title="Margin"
        props={['margin']}
        summary={({ margin }: any) => {
          return `${margin[0] || 0}px ${margin[1] || 0}px ${margin[2] || 0}px ${margin[3] || 0}px`;
        }}
      >
        <ToolbarItem<ContainerProps> propKey="margin" zeroValue={0} index={0} type="number" label="Top" />
        <ToolbarItem<ContainerProps> propKey="margin" zeroValue={0} index={1} type="number" label="Right" />
        <ToolbarItem<ContainerProps> propKey="margin" zeroValue={0} index={2} type="number" label="Bottom" />
        <ToolbarItem<ContainerProps> propKey="margin" zeroValue={0} index={3} type="number" label="Left" />
      </ToolbarSection>

      <ToolbarSection<ContainerProps>
        title={'Padding'}
        props={['padding']}
        summary={({ padding }: any) => {
          return `${padding[0] || 0}px ${padding[1] || 0}px ${padding[2] || 0}px ${padding[3] || 0}px`;
        }}
      >
        <ToolbarItem<ContainerProps> propKey="padding" zeroValue={0} index={0} type="number" label="Top" />
        <ToolbarItem<ContainerProps> propKey="padding" zeroValue={0} index={1} type="number" label="Right" />
        <ToolbarItem<ContainerProps> propKey="padding" zeroValue={0} index={2} type="number" label="Bottom" />
        <ToolbarItem<ContainerProps> propKey="padding" zeroValue={0} index={3} type="number" label="Left" />
      </ToolbarSection>

      <ToolbarSection<ContainerProps>
        title={'Border'}
        props={['borderRadius', 'borderWidth', 'borderStyle', 'borderColor']}
        summary={({ borderColor, borderStyle }) => (
          <div>
            {borderStyle}
            <span style={{ background: borderColor, height: 24, padding: 4, marginLeft: 4 }}>{borderColor}</span>
          </div>
        )}
      >
        <ToolbarItem<ContainerProps> propKey="borderWidth" zeroValue={0} index={1} type="number" label="Width Right" />
        <ToolbarItem<ContainerProps> propKey="borderWidth" zeroValue={0} index={2} type="number" label="Width Bottom" />
        <ToolbarItem<ContainerProps> propKey="borderWidth" zeroValue={0} index={3} type="number" label="Width Left" />
        <ToolbarItem<ContainerProps> propKey="borderWidth" zeroValue={0} index={0} type="number" label="Width Top" />

        <ToolbarItem<ContainerProps> propKey="borderRadius" zeroValue={0} type="number" label="Radius" />
        <ToolbarItem<ContainerProps>
          propKey="borderStyle"
          type="select"
          label="Style"
          options={['none', 'dotted', 'dashed', 'solid', 'double']}
        />
        <ToolbarItem<ContainerProps> propKey="borderColor" type="color" label="Color" />
      </ToolbarSection>

      <ToolbarSection<ContainerProps> title="Alignment">
        <ToolbarItem<ContainerProps>
          propKey="flexDirection"
          type="select"
          label="Flex Direction"
          options={['row', 'column']}
        />
        <ToolbarItem<ContainerProps> propKey="fillSpace" type="select" label="Fill space" options={['yes', 'no']} />
        <ToolbarItem<ContainerProps>
          propKey="alignItems"
          type="select"
          label="Align Items"
          options={['flex-start', 'center', 'flex-end']}
        />
        <ToolbarItem<ContainerProps>
          propKey="justifyContent"
          type="select"
          label="Justify Content"
          options={['flex-start', 'center', 'flex-end']}
        />
      </ToolbarSection>
    </>
  );
};
