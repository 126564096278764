import clsx from 'clsx';
import Style from './appAttachmentsView.module.css';
import React, { useCallback, useMemo, useState } from 'react';
import { AppButton } from '../../../buttons/button/button.component';
import { ReactComponent as FileIcon } from 'src/assets/img/comment/file.svg';
import { ReactComponent as ChevronIcon } from 'src/assets/img/common/chevron.svg';
import { TypographyStyles } from '../../../../../styles';
import { useScopedTranslation } from '../../../../../application/localisation/useScopedTranslation';
import { AppAttachmentValue } from '../attachmentsField/appAttachmentsField';
import { AppFileComponent } from './appFile.component';
import { downloadFile } from 'src/helpers/file-helper';
import { logger } from 'src/application/logging/logging';
import { getFileById } from 'src/services/api/api-client/FilesClient';

export type AppAttachmentsViewProps = {
  isEditMode?: boolean;
  files?: AppAttachmentValue[];
  onDeletePress?: (file: AppAttachmentValue) => void;
  onOpenPress?: (fileId: string | null) => void;
  testId?: string;
  disabled?: boolean;
  withoutCollapsible?: boolean;
};

export const AppAttachmentsView = (props: AppAttachmentsViewProps) => {
  const { t } = useScopedTranslation('Forms.Controls.Attachments');
  const [expanded, setExpended] = useState<boolean>(props.withoutCollapsible || false);
  const [loadingFileIds, setLoadingFileIds] = useState<string[]>([]);

  const downloadFileHandler = useCallback(
    async (id: string | null) => {
      if (!id || loadingFileIds?.includes(id)) return;

      props.onOpenPress?.(id);
      try {
        setLoadingFileIds((x) => [...x, id]);
        const fileResponse = await getFileById(id);
        downloadFile(fileResponse);
      } catch (error: any) {
        logger().error(error);
      } finally {
        setLoadingFileIds((x) => x?.filter((s) => s !== id));
      }
    },
    [loadingFileIds, props],
  );

  const toggle = useCallback(() => {
    setExpended((x) => !x);
  }, []);

  const content = useMemo(() => {
    return props.files?.map((file, i) => {
      return props.isEditMode ? (
        <AppFileComponent file={file} onClick={props.onDeletePress} key={i} disabled={props.disabled} />
      ) : (
        <AppButton
          key={i}
          variant={'icon-link'}
          colorSchema={'primary'}
          text={file.fileName}
          Icon={FileIcon}
          className={Style.fileButton}
          textClassName={Style.fileText}
          onClick={() => downloadFileHandler(file.id)}
          isLoading={!!file.id && loadingFileIds?.includes(file.id)}
        />
      );
    });
  }, [downloadFileHandler, loadingFileIds, props.disabled, props.files, props.isEditMode, props.onDeletePress]);

  return (
    <div data-test-id={props.testId}>
      <div
        className={clsx({
          [Style.filesWrapper]: true,
          [Style.fileWrapperHeight]: !props.isEditMode && !expanded && props.files && props.files.length > 0,
        })}
      >
        {content}
      </div>
      {!props.isEditMode && props.withoutCollapsible !== true && (
        <div className={Style.expendContainer}>
          {props.files && props.files?.length > 3 && (
            <button onClick={toggle} className={clsx({ [Style.expandButton]: true })}>
              <ChevronIcon className={clsx({ [Style.chevron]: true, [Style.chevronExpanded]: !expanded })} />
              <p className={clsx([TypographyStyles.paragraph14, Style.expendButtonText])}>
                {expanded ? t('Hide') : t('Show')}
              </p>
            </button>
          )}
        </div>
      )}
    </div>
  );
};
