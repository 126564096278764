import React, { CSSProperties, FC } from 'react';
import Style from './devError.module.scss';

export const DevError: FC<{ messages: string[]; style?: CSSProperties }> = (props) => {
  return (
    <div style={props.style} className={Style.devError}>
      {props.messages.join('; ')}
    </div>
  );
};
