import React from 'react';
import { useNode } from '@craftjs/core';
import { ToolbarSection } from '../../editor/toolbar/toolbar.section';
import { MathBlockProps } from '.';
import { ToolbarItem } from '../../editor/toolbar/toolbar.item';
import { useTranslation } from 'react-i18next';
import { AppTextInput } from '../../../uikit/inputs/text/appTextInput.component';
import { AppInputLabel } from '../../../uikit/wrappers/label/appInputLabel.component';

export const MathBlockSettings = () => {
  const { t } = useTranslation();
  const { setProp, text } = useNode((node) => ({
    text: node.data.props.text,
  }));

  return (
    <>
      <ToolbarSection<MathBlockProps>
        title="Margin"
        props={['margin']}
        summary={({ margin }: any) => {
          return `${margin[0] || 0}px ${margin[1] || 0}px ${margin[2] || 0}px ${margin[3] || 0}px`;
        }}
      >
        <ToolbarItem<MathBlockProps> propKey="margin" zeroValue={0} index={0} type="number" label="Top" />
        <ToolbarItem<MathBlockProps> propKey="margin" zeroValue={0} index={1} type="number" label="Right" />
        <ToolbarItem<MathBlockProps> propKey="margin" zeroValue={0} index={2} type="number" label="Bottom" />
        <ToolbarItem<MathBlockProps> propKey="margin" zeroValue={0} index={3} type="number" label="Left" />
      </ToolbarSection>

      <ToolbarSection<MathBlockProps> title="Alignment">
        <ToolbarItem<MathBlockProps> propKey="fillSpace" type="select" label="Fill space" options={['yes', 'no']} />
        <ToolbarItem<MathBlockProps>
          propKey="justifyContent"
          type="select"
          label="Justify Content"
          options={['flex-start', 'center', 'flex-end']}
        />
      </ToolbarSection>
      <AppInputLabel text={t('FaqEditor.Toolbar.Settings.InputFormulaLabel')}>
        <AppTextInput
          type={'text-area'}
          value={text}
          onChange={(e) => {
            setProp((prop) => (prop.text = e.target.value), 500);
          }}
        />
      </AppInputLabel>
    </>
  );
};
