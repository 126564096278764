import React, { FC } from 'react';
import Style from './appCheckboxInput.module.scss';
import clsx from 'clsx';

export type AppCheckboxInputVariant = 'primary' | 'destroy';

export type AppCheckboxInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string;
  readOnly?: boolean;
  labelClassName?: string;
  /** @default primary */
  variant?: AppCheckboxInputVariant;
};

export const AppCheckboxInput: FC<AppCheckboxInputProps> = ({
  disabled,
  readOnly,
  className,
  label,
  labelClassName,
  variant = 'primary',
  ...rest
}) => {
  return (
    <label data-disabled={disabled || readOnly} data-read-only={readOnly} className={clsx(Style.label, labelClassName)}>
      <input
        className={clsx(Style.input, className, {
          [Style.primary]: variant === 'primary',
          [Style.destroy]: variant === 'destroy',
        })}
        type={'checkbox'}
        disabled={disabled || readOnly}
        data-read-only={readOnly}
        {...rest}
      />
      {label}
    </label>
  );
};
