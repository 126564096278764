import React, { FC, useContext, useMemo } from 'react';
import { TypographyStyles } from 'src/styles';
import { AppButton } from 'uikit/buttons/button/button.component';
import { FormResultVm, RecordRowVm, UE_FORM_TYPE } from './recordRow';
import Style from './recordRow.module.scss';
import { DropDownMenu } from 'uikit/dropDownMenu/dropDownMenu.component';
import clsx from 'clsx';
import _ from 'lodash';
import { Permissions } from 'src/services/api/api-client';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { PatientCardContext } from '../PatientCardContext';
import { useFormEditingAndOverview } from 'src/features/forms/useFormEditingAndOverview';
import { ReactComponent as Eye } from 'src/assets/img/common/eye_24.svg';
import { FillingProgress } from 'src/features/forms/components/FillingProgress';
import { ReactComponent as FilledAddIcon } from 'src/assets/img/records/fill_add_24.svg';
import { ReactComponent as AddIcon } from 'src/assets/img/records/add_24.svg';
import { ReactComponent as EditIcon } from 'src/assets/img/records/edit_24.svg';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { FormStateIcon, FormStateType, getFormStateColor } from 'src/features/forms/form-helper';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const RecordRow: FC<{
  vm: RecordRowVm;
  withEditButton?: boolean;
  withVersion?: boolean;
  type: 'groupedByStep' | 'groupedByType' | 'multiple';
}> = ({ vm, withEditButton, type, withVersion }) => {
  const { formType, formConfigId, formResults, fillingProgress, state, formLifeCycle, isSkipped, isMultiInstance } = vm;
  const study = useStudy();
  const {
    patient: { uniqueId: patientUniqueId, studyRouteProgress, id: patientId },
  } = useContext(PatientCardContext);

  const { hasPermission } = useHasPermissions();

  const formEditingAndOverview = useFormEditingAndOverview();
  const filledFormsCount = useMemo(() => {
    return formResults.filter((x) => x.formResultId).length;
  }, [formResults]);

  const formsToView = useMemo(
    () => formResults.filter((x) => x.formResultId).sort((a, b) => (a.formResultId ?? 0) - (b.formResultId ?? 0)),
    [formResults],
  );
  const formsToFill = useMemo(() => formResults.filter((x) => !x.formResultId && x.stepIsActive), [formResults]);
  const formsToEdit = useMemo(() => formResults.filter((x) => x.formResultId && x.isEditable), [formResults]);
  const lastFormResult = useMemo(() => _.last(formsToView), [formsToView]);

  const showEditButton = useMemo(
    () =>
      withEditButton &&
      formsToEdit.length > 0 &&
      hasPermission(
        formType === UE_FORM_TYPE
          ? Permissions.UeFormCreate
          : formConfigId === study?.patientFinishingFormId
          ? Permissions.PatientRelease
          : Permissions.PatientCreate,
      ),
    [formConfigId, formType, formsToEdit.length, hasPermission, study?.patientFinishingFormId, withEditButton],
  );

  const showFillButton = useMemo(
    () =>
      formsToFill.length > 0 &&
      hasPermission(
        formType === UE_FORM_TYPE
          ? Permissions.UeFormCreate
          : formConfigId === study?.patientFinishingFormId
          ? Permissions.PatientRelease
          : Permissions.PatientCreate,
      ),
    [formConfigId, formType, formsToFill.length, hasPermission, study?.patientFinishingFormId],
  );

  return (
    <>
      <div className={Style.row} data-test-id={`record-${formType}`} data-id={'record-row'}>
        <LeftPart
          resultsCount={filledFormsCount}
          formType={formType}
          state={state}
          isMultiInstance={isMultiInstance}
          stepName={type === 'groupedByStep' ? formResults[0].stepName : undefined}
          withVersion={withVersion && study?.hasEcrf}
          lastFormResultVersion={lastFormResult?.lastFormResultVersion || undefined}
        />
        <div className={Style.rightPart}>
          {fillingProgress && (
            <div className={Style.fillingProgressWrapper}>
              <FillingProgress value={fillingProgress} formLifeCycle={formLifeCycle} isSkipped={isSkipped!} />
            </div>
          )}
          {!showFillButton && !showEditButton && <ButtonPlaceholder />}
          {showEditButton && (
            <EditButton
              formsToEdit={formsToEdit}
              onEdit={(formResult) =>
                formEditingAndOverview.startEditing({
                  formResultId: formResult.formResultId!,
                  patientUniqueId: patientUniqueId,
                  stepName: formResult.stepName,
                })
              }
              loading={formEditingAndOverview.formEditIsLoading}
            />
          )}
          {showFillButton && (
            <FillButton
              formsToFill={formsToFill}
              isMultiInstance={isMultiInstance}
              onFill={(form) => {
                formEditingAndOverview.startFilling({
                  patientId: patientId,
                  patientUniqueId: patientUniqueId,
                  routeProgress: studyRouteProgress,
                  formId: formConfigId,
                  stepName: form.stepName,
                });
              }}
              loading={formEditingAndOverview.formEditIsLoading}
            />
          )}

          {formsToView.length > 0 && lastFormResult ? (
            <ViewButton
              loading={formEditingAndOverview.formOverviewIsLoading}
              onClick={() =>
                formEditingAndOverview.openOverview({
                  patientId: patientId,
                  formResultId: lastFormResult.formResultId!,
                })
              }
            />
          ) : (
            <ButtonPlaceholder />
          )}
        </div>
      </div>

      {formEditingAndOverview.element}
    </>
  );
};

const ViewButton: FC<{ onClick: () => void; loading: boolean }> = ({ loading, onClick }) => {
  return (
    <AppButton
      onClick={onClick}
      Icon={Eye}
      variant={'icon-link'}
      colorSchema={'primary'}
      testId={'form-view-button'}
      isLoading={loading}
      loaderClassName={Style.loader}
    />
  );
};

const FillButton: FC<{
  formsToFill: FormResultVm[];
  onFill: (form: FormResultVm) => void;
  loading: boolean;
  isMultiInstance: boolean;
}> = ({ formsToFill, onFill, loading, isMultiInstance }) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.Records');

  return (
    <>
      {formsToFill.length === 1 && (
        <AppButton
          testId={'form-fill-button'}
          onClick={() => onFill(formsToFill[0])}
          variant={'icon-link'}
          colorSchema={'primary'}
          Icon={isMultiInstance ? AddIcon : FilledAddIcon}
          isLoading={loading}
          loaderClassName={Style.loader}
        />
      )}

      {formsToFill.length > 1 && (
        <DropDownMenu
          Icon={isMultiInstance ? AddIcon : FilledAddIcon}
          colorSchema={'primary'}
          isLoading={loading}
          loaderClassName={Style.loader}
          options={formsToFill.map((form) => ({
            key: form.stepName!,
            text: t('StepName', { step: form.stepName }),
            action: () => onFill(form),
          }))}
        />
      )}
    </>
  );
};

const EditButton: FC<{ formsToEdit: FormResultVm[]; onEdit: (formResult: FormResultVm) => void; loading: boolean }> = ({
  formsToEdit,
  onEdit,
  loading,
}) => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.Records');

  return (
    <>
      {formsToEdit.length === 1 && (
        <AppButton
          testId={'form-edit-button'}
          onClick={() => onEdit(formsToEdit[0])}
          variant={'icon-link'}
          colorSchema={'primary'}
          Icon={EditIcon}
          isLoading={loading}
          loaderClassName={Style.loader}
        />
      )}

      {formsToEdit.length > 1 && (
        <DropDownMenu
          Icon={EditIcon}
          colorSchema={'primary'}
          isLoading={loading}
          loaderClassName={Style.loader}
          options={formsToEdit.map((form) => ({
            key: form.stepName!,
            text: t('StepName', { step: form.stepName }),
            action: () => onEdit(form),
          }))}
        />
      )}
    </>
  );
};

const ButtonPlaceholder: FC = () => <div className={Style.buttonPlaceholder} />;

const LeftPart: FC<{
  resultsCount: number;
  formType: string;
  state: FormStateType | undefined;
  isMultiInstance?: boolean;
  stepName: string | undefined;
  withVersion?: boolean;
  lastFormResultVersion?: number;
}> = ({ resultsCount, formType, state, isMultiInstance, stepName, withVersion, lastFormResultVersion }) => {
  const {
    patient: { uniqueId },
  } = useContext(PatientCardContext);

  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.Records');

  return (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        linkedPatientUniqId: uniqueId,
        topicAdditional: formType,
        stepName: isMultiInstance ? 'multiple' : stepName,
      }}
      containerClassName={Style.issueMarkContainer}
    >
      <div data-test-id={'form-title'} className={clsx(Style.leftPart, getFormStateColor(state, 'PatientCard'))}>
        <FormStateIcon state={state} variant={'PatientCard'} />
        <span>
          <span className={clsx(TypographyStyles.plainText14, Style.formType)}>{formType}</span>
          {withVersion && lastFormResultVersion && (
            <span className={Style.version}>{t('Version', { version: lastFormResultVersion })}</span>
          )}
          {(isMultiInstance || resultsCount > 1) && (
            <span className={clsx(TypographyStyles.heading2, Style.formsCounter)}>{`(${resultsCount})`}</span>
          )}
        </span>
      </div>
    </IssueMark>
  );
};
