import { BooleanParam, QueryParamConfig, StringParam } from 'use-query-params';
import { NumericArrayParam, ArrayParam } from 'react-router-url-params';

export const userFilterParams = () => ({
  withoutStudy: BooleanParam,
  predefinedUser: BooleanParam,
  studyIds: NumericArrayParam,
  roles: ArrayParam,

  searchQuery: StringParam,
});

export type UsersFilterQueryParameters = {
  withoutStudy: QueryParamConfig<boolean | null | undefined, boolean | null | undefined>;
  predefinedUser: QueryParamConfig<boolean | null | undefined, boolean | null | undefined>;
  studyIds: QueryParamConfig<number[] | null | undefined, number[] | null | undefined>;
  searchQuery: QueryParamConfig<string | null | undefined, string | null | undefined>;
  roles: QueryParamConfig<string[] | null | undefined, (string | null)[] | null | undefined>;
};

export type GetAllUsersUsersQueryParameters = {
  searchQuery?: string | null;
  withoutStudy?: boolean | null;
  predefinedUser?: boolean | null;
  studyIds?: number[] | null;
  roles?: string[] | null;
};

export function userFilterToBackendRequest(query: {
  withoutStudy?: boolean | null;
  predefinedUser?: boolean | null;
  studyIds?: (number | null)[] | null;
  roles?: (string | null)[] | null;
}): GetAllUsersUsersQueryParameters {
  return {
    withoutStudy: query.withoutStudy ?? undefined,
    predefinedUser: query.predefinedUser ?? undefined,
    studyIds: (query.studyIds as number[]) ?? undefined,
    roles: (query.roles as string[]) ?? undefined,
  };
}
