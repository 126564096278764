import { useCallback, useMemo, useState } from 'react';

/**
 * Boilerplate hook for handling visibility of modals.
 */
export const useModal = <T = undefined>(defaultState: 'OPEN' | 'CLOSED' = 'CLOSED'): UseModalProps<T> => {
  const [visible, setIsVisible] = useState(defaultState === 'OPEN');
  const [params, setParams] = useState<T>({} as T);

  const openModal = useCallback((p?: T) => {
    setIsVisible(true);
    // TODO: bug is possible
    setParams(p!);
  }, []);

  const closeModal = useCallback(() => {
    setIsVisible(false);
  }, []);

  return useMemo(() => ({ visible, openModal, closeModal, params }), [closeModal, openModal, params, visible]);
};

export type UseModalProps<T = undefined> = {
  visible: boolean;
  openModal: [T] extends [undefined] ? () => void : (params: T) => void;
  closeModal: () => void;
  params: T;
};
