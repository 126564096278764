import _ from 'lodash';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { DataType, SkippedFieldsDataType } from '../uiEditor';
import {
  convertLayoutToPageList,
  getDefaultValuesFromLayout,
  getValuesFromFilledRecords,
  normalizeDataKey,
  scrollToRef,
} from '../uiEditor-helper';
import { DataBlockValidation, Page, FormFillContext, FormMode } from './formFill.context';
import { IFormDto, IFormResultVersionDto, FieldSkipReason } from 'src/services/api/api-client';
import { useGetPatientQuery } from 'src/services/api/api-client/PatientQuery';
import { SerializedNode, SerializedNodes } from '@craftjs/core';
import { ValidationOptions } from '../../toolbox/settingsPanel/content/Validation.component';
import { ValidateCustomForm } from 'src/helpers/validation-helpers';
import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import { isNullOrEmpty } from 'src/helpers/string-helper';

export type UiEditorProviderProps = {
  mode: FormMode;
  formConfig: IFormDto;
  formResultVersion: IFormResultVersionDto | undefined;
  prevAnswersValue: DataType | undefined;
  patientId?: string;
  navigateToNodeId?: string;
  stepName?: string;
};

export const UiEditorProvider: FC<PropsWithChildren<UiEditorProviderProps>> = (props) => {
  const { formConfig, mode, patientId, navigateToNodeId, stepName, prevAnswersValue, formResultVersion } = props;
  const { data: patient } = useGetPatientQuery(patientId!, { enabled: !!patientId, suspense: false });
  const [nodes, setNodes] = useState<SerializedNodes>(formConfig.layoutSchema);

  const initialValues = useMemo(() => {
    const valuesFromLayout = getDefaultValuesFromLayout(formConfig.layoutSchema, patient);
    const valuesFromPrevRecords = getValuesFromFilledRecords(formConfig.layoutSchema, prevAnswersValue);
    return formResultVersion?.value ?? { ...valuesFromLayout, ...valuesFromPrevRecords };
  }, [formConfig.layoutSchema, patient, prevAnswersValue, formResultVersion?.value]);

  //#region Touched fields

  const [touchedFields, setTouchedFields] = useState<{ [key: string]: boolean }>({});

  const markFieldAsTouched = useCallback((nodeId: string) => {
    setTouchedFields((prev) => ({
      ...prev,
      [nodeId]: true,
    }));
  }, []);

  const unmarkTouchedField = useCallback((nodeId: string) => {
    setTouchedFields((prev) => {
      const result = { ...prev };
      delete result[nodeId];
      return result;
    });
  }, []);

  //#endregion

  //#region Values

  const [dataBlockValues, setDataBlockValues] = useState<DataType>(initialValues ?? {});

  useEffect(() => {
    setDataBlockValues(initialValues ?? {});
  }, [initialValues]);

  const changeDataBlockValue = useCallback(
    (dataKey: string, value: any, options?: { dontTouchField?: boolean }) => {
      setDataBlockValues((prevValues) => {
        return {
          ...prevValues,
          [dataKey]: isEmptyValue(value) ? undefined : value,
        };
      });

      if (value !== undefined && !options?.dontTouchField) {
        markFieldAsTouched(dataKey);
      }
    },
    [markFieldAsTouched],
  );

  const removeDataBlockValue = useCallback(
    (key: string) => {
      setDataBlockValues((prevValues) => {
        if (!prevValues) {
          return undefined;
        }

        const result = { ...prevValues };
        delete result[key];
        return result;
      });

      unmarkTouchedField(key);
    },
    [unmarkTouchedField],
  );

  const notEditableValues: SerializedNode[] = useMemo(() => {
    return Object.keys(nodes)
      .filter((key) => {
        const initValue = initialValues && initialValues[nodes[key].props.dataKey];
        const currentValue = dataBlockValues && dataBlockValues[nodes[key].props.dataKey];

        return nodes[key].props.isDisableWhenEditing && nodes[key].props.isEditable && !initValue && currentValue;
      })
      .map((key) => nodes[key]);
  }, [dataBlockValues, nodes, initialValues]);

  //#endregion

  //#region Skipped fields

  const initialSkippedFieldsData = useMemo(
    () => ({ ...formResultVersion?.fieldSkipReasons }),
    [formResultVersion?.fieldSkipReasons],
  );

  const [skippedFieldsData, setSkippedFieldsData] = useState<SkippedFieldsDataType>(initialSkippedFieldsData);

  useEffect(() => {
    setSkippedFieldsData(initialSkippedFieldsData);
  }, [initialSkippedFieldsData]);

  const markFieldAsSkipped = useCallback(
    (dataKey: string, skipReason: FieldSkipReason) => {
      removeDataBlockValue(dataKey);
      markFieldAsTouched(dataKey);
      setSkippedFieldsData((prev) => {
        return {
          ...prev,
          [dataKey]: skipReason,
        };
      });
    },
    [markFieldAsTouched, removeDataBlockValue],
  );

  const unmarkSkippedField = useCallback(
    (dataKey: string) => {
      setSkippedFieldsData((prev) => {
        const result = { ...prev };
        delete result[dataKey];
        return result;
      });

      markFieldAsTouched(dataKey);
    },
    [markFieldAsTouched],
  );

  //#endregion

  //#region Edit field reasons

  const fieldEditReasons = useMemo(
    () => formResultVersion?.fieldEditReasons ?? {},
    [formResultVersion?.fieldEditReasons],
  );

  //#endregion

  //#region Navigation

  const { pages: pageStacks, blocks } = useMemo(() => convertLayoutToPageList(nodes), [nodes]);
  const [blockRefs, setBlockRefs] = useState({});
  const dataBlockNavigation = useMemo(() => {
    return Object.entries(blocks).reduce(
      (result, [nodeId, value]) => ({
        ...result,
        [nodeId]: {
          ...value,
          ref: blockRefs[nodeId],
        },
      }),
      {},
    );
  }, [blocks, blockRefs]);

  const addRefToDataBlockNavigation = useCallback((id: string, ref: any) => {
    setBlockRefs((state) => {
      return {
        ...state,
        [id]: ref,
      };
    });
  }, []);

  const navigateToBlock = useCallback(
    (nodeId: string | undefined) => {
      if (!nodeId) {
        return;
      }

      setCurrentPageStack(dataBlockNavigation[normalizeDataKey(nodeId)]?.pages);
      setTimeout(() => {
        scrollToRef(dataBlockNavigation[normalizeDataKey(nodeId)]?.ref);
      }, 0);
    },
    [dataBlockNavigation],
  );

  useEffect(() => {
    if (navigateToNodeId) {
      navigateToBlock(navigateToNodeId);
    }
  }, [navigateToBlock, navigateToNodeId]);

  //#endregion

  //#region Validation

  const [dataBlockValidation, setDataBlockValidation] = useState<DataBlockValidation>({});

  const setDataBlockValidationHandler = useCallback((data: DataBlockValidation) => {
    setDataBlockValidation((state) => {
      return { ...state, ...data };
    });
  }, []);

  const removeDataBlockValidation = useCallback((key: string) => {
    setDataBlockValidation((state) => {
      delete state[key];
      return { ...state };
    });
  }, []);

  const triggerValidation = useCallback(
    (options: ValidationOptions) => {
      const nodeIds = Object.keys(dataBlockValidation).map((nodeId) => {
        // NOTE: We use postfix only for date range fields. It can be only '_from' or '_until'
        const postfix = nodeId.includes('_from') ? '_from' : nodeId.includes('_until') ? '_until' : '';
        return { nodeId: nodeId.replace(postfix, ''), postfix: postfix };
      });

      const newValidationResult = nodeIds.reduce((obj, { nodeId, postfix }) => {
        const nodeProps = nodes[nodeId].props as IFormFieldProps;
        const validationResult = ValidateCustomForm({
          validationSettings: nodeProps.validation,
          value: dataBlockValues[nodeProps.dataKey + postfix],
          skipReason: skippedFieldsData[nodeProps.dataKey + postfix],
          options: { ...options, disable: options.disable || !nodeProps.isEditable || nodeProps.isDisabled },
        });

        return {
          ...obj,
          [nodeId + postfix]: {
            validationState: validationResult.length > 0 ? 'Fail' : 'Ok',
            text: validationResult.join('\n'),
          },
        };
      }, {});

      setDataBlockValidation(newValidationResult);
      return newValidationResult;
    },
    [dataBlockValidation, dataBlockValues, nodes, skippedFieldsData],
  );

  //#endregion

  //#region Paging

  const [currentPageStack, setCurrentPageStack] = useState(_.first(pageStacks));

  const goToPage = useCallback(
    (page: Page) => {
      setCurrentPageStack(() => {
        const index = pageStacks.findIndex((x) =>
          x.some((val) => val.nodeId === page.nodeId && val.pageNumber === page.pageNumber),
        );
        return pageStacks[index];
      });
    },
    [pageStacks],
  );

  const goToNextPage = useCallback(() => {
    setCurrentPageStack((state) => {
      const index = pageStacks.findIndex((x) =>
        x.every((val, i) => val.nodeId === state![i].nodeId && val.pageNumber === state![i].pageNumber),
      );
      return pageStacks[index + 1] || _.last(pageStacks);
    });
  }, [pageStacks]);

  const goToPrevPage = useCallback(() => {
    setCurrentPageStack((state) => {
      const index = pageStacks.findIndex((x) =>
        x.every((val, i) => val.nodeId === state![i].nodeId && val.pageNumber === state![i].pageNumber),
      );
      return pageStacks[index - 1] ?? _.first(pageStacks);
    });
  }, [pageStacks]);

  //#endregion

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const resetState = useCallback(() => {
    setDataBlockValues(initialValues ?? {});
    setDataBlockValidation({});
    setIsSubmitting(false);
    setTouchedFields({});
    setSkippedFieldsData(formResultVersion?.fieldSkipReasons || {});
    setCurrentPageStack(_.first(pageStacks));
  }, [formResultVersion?.fieldSkipReasons, initialValues, pageStacks]);

  return (
    <FormFillContext.Provider
      value={{
        mode,
        formResultVersion,
        stepName,
        formConfig,
        resetState,

        patient,

        isSubmitting,
        setIsSubmitting,

        initialValues,
        values: dataBlockValues,
        notEditableValues,
        setValue: changeDataBlockValue,
        removeValue: removeDataBlockValue,

        fieldEditReasons,

        initialSkippedFieldsData,
        skippedFieldsData,
        markFieldAsSkipped,
        unmarkSkippedField,

        touchedFields,

        nodes,
        setNodes,

        dataBlockValidation,
        setDataBlockValidation: setDataBlockValidationHandler,
        removeDataBlockValidation,
        triggerValidation,

        addRefToDataBlockNavigation,
        navigateToBlock,

        pageStacks,
        currentPageStack,
        goToPage,
        goToNextPage,
        goToPrevPage,
      }}
    >
      {props.children}
    </FormFillContext.Provider>
  );
};

const isEmptyValue = (x: any) => {
  if (Array.isArray(x)) return !x.length;
  return x === undefined || isNullOrEmpty(x);
};
