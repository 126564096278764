import React, { CSSProperties, FC, PropsWithChildren, ReactNode } from 'react';
import styles from './StudyInfoPageLayout.module.scss';
import { Loading } from 'uikit/suspense/Loading';
import clsx from 'clsx';

type StudyInfoPageLayoutProps = PropsWithChildren<{
  isLoading?: boolean;
  testId?: string;
}>;

type HeaderProps = PropsWithChildren<{
  className?: string;
  style?: CSSProperties;
  testId?: string;
}>;

type ColumnProps = PropsWithChildren<{
  colored?: boolean;
}>;
type ColumnsProps = PropsWithChildren;

export const StudyInfoPageLayout: FC<StudyInfoPageLayoutProps> & {
  Header: FC<HeaderProps> & {
    Filters: FC<PropsWithChildren>;
  };
  Columns: FC<ColumnsProps> & {
    Column: FC<ColumnProps>;
  };
} = ({ isLoading, testId, children }) => {
  return (
    <Loading loading={!!isLoading} containerClassName={styles.scrollWrapper} testId={testId}>
      <div data-test-id={testId} className={styles.scrollWrapper}>
        {children}
      </div>
    </Loading>
  );
};

const Header: typeof StudyInfoPageLayout.Header = ({ children, className, style, testId }) => (
  <div className={clsx(styles.header, className)} style={style} data-test-id={testId}>
    {children}
  </div>
);

Header.Filters = ({ children }) => <div className={styles.filters}>{children}</div>;

StudyInfoPageLayout.Header = Header;

const Columns: typeof StudyInfoPageLayout.Columns = ({ children }) => <div className={styles.columns}>{children}</div>;

Columns.Column = ({ children, colored }) => (
  <div className={styles.column} data-colored={!!colored}>
    {children}
  </div>
);

StudyInfoPageLayout.Columns = Columns;

type StudyInfoPageSectionProps = PropsWithChildren<{
  title: string;
  rightHeader?: ReactNode;
  contentClassName?: string;
}>;

export const StudyInfoPageSection: FC<StudyInfoPageSectionProps> = ({
  title,
  rightHeader,
  children,
  contentClassName,
}) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionHeader}>
        <div className={styles.title}>{title}</div>
        <div className={styles.sectionRightHeader}>{rightHeader}</div>
      </div>
      <div className={contentClassName}>{children}</div>
    </div>
  );
};

export const StudyInfoCard: FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return <div className={clsx(styles.card, className)}>{children}</div>;
};

export const CardValue: FC<{ mainText: string; secondText?: string }> = ({ mainText, secondText }) => {
  return (
    <span className={styles.cardValue}>
      <span>{mainText}</span>
      {secondText && <span>{secondText}</span>}
    </span>
  );
};
