import React from 'react';
import { RPDF } from '../../react-pdf';

export const LmReportLogo: React.FC = () => {
  return (
    <RPDF.Svg width="73" height="22" viewBox="0 0 73 22" fill="none">
      <RPDF.G clip-path="url(#clip0_11286_6269)">
        <RPDF.Path
          d="M71.3064 2.81042V8.71057L67.5153 2.81042H65.8952V11.763H67.5883V5.88148L71.3805 11.763H73V2.81042H71.3064Z"
          fill="#003568"
        />
        <RPDF.Path d="M0 2.81042V11.763H4.14146V10.1438H1.69342V2.81042H0Z" fill="#003568" />
        <RPDF.Path d="M7.1601 1.56343H8.6878V0H7.1601V1.56343Z" fill="#003568" />
        <RPDF.Path d="M10.2339 1.56343H11.7801V0H10.2339V1.56343Z" fill="#003568" />
        <RPDF.Path
          d="M14.0625 7.31473C14.0625 5.99319 13.6024 4.87647 12.7005 3.96445C11.7986 3.05243 10.7126 2.58713 9.4609 2.58713C8.63265 2.58713 7.85955 2.79188 7.14172 3.21994C6.42388 3.64804 5.85329 4.2064 5.44828 4.93232C5.04338 5.65819 4.84091 6.4399 4.84091 7.29609C4.84091 8.58034 5.28267 9.67852 6.16616 10.6091C7.04968 11.5397 8.15409 12.0051 9.47934 12.0051C10.7494 12.0051 11.8353 11.5584 12.7189 10.6463C13.6208 9.73429 14.0625 8.61757 14.0625 7.31473ZM12.3876 7.29609C12.3876 8.15227 12.1114 8.87816 11.5409 9.45517C10.9703 10.0321 10.2892 10.3299 9.49772 10.3299C8.79828 10.3299 8.17248 10.1066 7.62027 9.65988C6.92082 9.08287 6.57113 8.30116 6.57113 7.31473C6.57113 6.42131 6.84717 5.69542 7.39943 5.11843C7.95164 4.54142 8.65109 4.26226 9.4609 4.26226C10.2708 4.26226 10.9703 4.56006 11.5225 5.15563C12.0931 5.73266 12.3876 6.45855 12.3876 7.29609Z"
          fill="#003568"
        />
        <RPDF.Path d="M61.9565 11.763H63.6311V2.81042H61.9565V11.763Z" fill="#003568" />
        <RPDF.Path
          d="M48.8693 4.48555H50.4522V11.763H52.1452V4.48555H53.7653V2.81042H48.8693V4.48555Z"
          fill="#003568"
        />
        <RPDF.Path
          d="M33.7943 11.763H35.4693V5.88148L39.2608 11.763H40.8809V2.81042H39.2056V8.71057L35.414 2.81042H33.7943V11.763Z"
          fill="#003568"
        />
        <RPDF.Path
          d="M16.8603 11.763H18.4617L20.063 5.97454L21.7012 11.763H23.321L25.3089 2.81042H23.6523L22.3823 8.52451L20.7809 2.81042H19.382L17.7991 8.52451L16.529 2.81042H14.854L16.8603 11.763Z"
          fill="#003568"
        />
        <RPDF.Path
          d="M28.6404 5.9746V4.44839H31.8064V2.7919H26.9655V11.7445H31.88V10.0694H28.6404V7.89168H31.4566V6.30966H28.6404V6.06766V5.9746Z"
          fill="#003568"
        />
        <RPDF.Path
          d="M56.9313 6.01178V4.48555H60.0968V2.81042H55.2745V11.7817H60.1709V10.1065H56.9313V7.91027H59.7659V6.34678H56.9313V6.10484V6.01178Z"
          fill="#003568"
        />
        <RPDF.Path
          d="M47.9307 8.33844C47.8015 8.00343 47.5809 7.66837 47.2125 7.35196C46.9915 7.14725 46.7342 6.92389 46.0899 6.53306C45.3166 6.04912 44.7091 5.63964 44.5804 5.45349C44.1754 4.91372 44.4517 4.52287 44.6909 4.28091C44.8749 4.11341 45.1512 4.03895 45.3904 4.03895C45.6664 4.05756 46.2 4.29951 46.6422 4.91372L47.7648 3.90865C47.3598 3.29445 46.9367 3.03388 46.5316 2.82914C46.1266 2.62438 45.703 2.51273 45.2613 2.51273C44.5804 2.51273 43.8995 2.73608 43.4945 3.05249C43.1261 3.33169 42.7216 3.96451 42.6663 4.56012C42.6292 4.98818 42.611 5.43488 42.9055 5.90018C43.1633 6.30971 43.5497 6.73777 44.6171 7.51949C45.1512 7.91037 45.7954 8.33844 45.9608 8.52461C46.1633 8.72932 46.4025 9.12015 46.3659 9.43662C46.3291 9.67857 46.2553 9.88328 46.0528 10.0508C45.8503 10.2183 45.5929 10.2928 45.2985 10.2928C44.7462 10.2928 44.194 9.92051 43.697 9.15739L42.39 10.33C42.7216 10.7767 43.2552 11.3165 43.6231 11.5584C44.0834 11.8748 44.7462 11.9865 45.3719 11.9865C46.1819 11.9865 46.8261 11.7445 47.3231 11.2606C47.8201 10.7767 48.0778 10.1439 48.1146 9.41798C48.1146 9.10156 48.0226 8.59903 47.9307 8.33844Z"
          fill="#003568"
        />
        <RPDF.Path
          d="M52.8266 19.0219C52.8266 18.3147 52.5874 17.7191 51.906 17.7191C51.2065 17.7191 50.9492 18.4263 50.9306 19.0778H52.8266V19.0219ZM51.8879 22C50.6547 22 50.1025 21.2927 50.1025 19.5431C50.1025 17.868 50.8386 17.0304 51.998 17.0304C53.0472 17.0304 53.6733 17.8121 53.6733 19.115C53.6733 19.3012 53.6548 19.5803 53.6362 19.7665H50.9306C50.9306 20.8274 51.2618 21.2741 52.0165 21.2741C52.3477 21.2741 52.9186 21.181 53.397 21.0321L53.5628 21.6463C52.9371 21.8883 52.4216 22 51.8879 22Z"
          fill="#003568"
        />
        <RPDF.Path
          d="M60.4467 21.9442H61.2751V17.1049H60.4467V21.9442ZM60.4467 15.9695H61.2751V15.132H60.4467V15.9695Z"
          fill="#003568"
        />
        <RPDF.Path
          d="M57.7781 15.1135V17.6075C57.2807 17.1608 56.9865 17.0305 56.6734 17.0305C55.7347 17.0305 55.0539 17.924 55.0539 19.5619C55.0539 21.0881 55.606 22.0187 56.4706 22.0187C56.8757 22.0187 57.2071 21.8698 57.8333 21.3486V21.9442H57.9252H58.606V21.3486V15.1135H57.7781ZM57.7781 20.6228C57.3542 21.0322 57.0049 21.2556 56.7102 21.2556C56.1949 21.2556 55.8818 20.5855 55.8818 19.5433C55.8818 18.4637 56.2684 17.8123 56.7653 17.8123C57.0417 17.8123 57.3542 18.017 57.7781 18.4079V20.6228Z"
          fill="#003568"
        />
        <RPDF.Path d="M72.1721 15.1135V21.9442H73V15.1135H72.1721Z" fill="#003568" />
        <RPDF.Path
          d="M47.2678 17.0304C46.8076 17.0304 46.4945 17.1607 45.7583 17.7749C45.5558 17.3468 45.206 17.0304 44.7091 17.0304C44.2674 17.0304 43.9548 17.1607 43.3472 17.6632V17.1048H43.2H42.5191V18.4077V21.9255H43.3472V18.4077C43.7704 17.9983 44.1201 17.7935 44.4146 17.7935C44.9116 17.7935 45.0959 18.3333 45.0959 18.9475V21.9255H45.9055V18.4077C46.3292 17.9983 46.6789 17.7935 46.9733 17.7935C47.4518 17.7935 47.6543 18.3333 47.6543 18.9475V21.9255H48.4824V18.7055C48.501 17.6632 48.004 17.0304 47.2678 17.0304Z"
          fill="#003568"
        />
        <RPDF.Path
          d="M64.6802 21.2183C64.1832 21.2183 63.7047 20.8274 63.7047 19.5059C63.7047 18.203 64.1465 17.7749 64.7359 17.7749C65.03 17.7749 65.3058 17.9052 65.5453 18.0355L65.7844 17.3655C65.4718 17.1608 65.0668 17.0119 64.6618 17.0119C63.5944 17.0119 62.8763 17.8308 62.8763 19.5431C62.8763 21.4602 63.6495 21.9628 64.5515 21.9628C65.0852 21.9628 65.4902 21.7952 65.8217 21.5905L65.5827 20.9391C65.3058 21.1066 65.0484 21.2183 64.6802 21.2183Z"
          fill="#003568"
        />
        <RPDF.Path
          d="M68.7109 17.0304C68.1771 17.0304 67.6072 17.1793 67.0362 17.4027L67.2022 18.0355C67.6991 17.868 68.1593 17.7191 68.6189 17.7191C69.429 17.7191 69.5398 18.1658 69.5398 18.9103V19.0778H69.2267C67.5515 19.0778 66.8523 19.6176 66.8523 20.5296C66.8523 21.4044 67.386 22 68.122 22C68.6746 22 69.0061 21.8511 69.595 21.3299V21.9255H69.6869H70.3677V21.3299V18.6683C70.3677 17.4213 69.8524 17.0304 68.7109 17.0304ZM69.5209 20.6226C69.0239 21.0879 68.7109 21.2741 68.3431 21.2741C67.9565 21.2741 67.6802 20.939 67.6802 20.4923C67.6802 20.027 67.9565 19.7665 69.1532 19.7665H69.5209V20.6226Z"
          fill="#003568"
        />
      </RPDF.G>
      <RPDF.Defs>
        <RPDF.ClipPath id="clip0_11286_6269">
          <rect width="73" height="22" fill="white" />
        </RPDF.ClipPath>
      </RPDF.Defs>
    </RPDF.Svg>
  );
};
