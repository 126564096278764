import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import Style from './panelContainer.module.scss';
import { ReactComponent as Arrow } from 'assets/img/formBuilder/arrow_small_24.svg';
import { AppButton } from 'uikit/buttons';

export type PanelContainerProps = {
  variant?: 'flex' | 'block';
  header: string;
  expanded?: boolean;
  contentClassName?: string;
  children: ReactNode;
};

export const PanelContainer = ({
  children,
  expanded,
  header,
  variant = 'block',
  contentClassName,
}: PanelContainerProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded || false);

  return (
    <div className={Style.container} data-expanded={isExpanded && variant === 'flex'}>
      <div className={Style.header} onClick={() => setIsExpanded((s) => !s)}>
        {header}
        <AppButton
          variant={'icon-link'}
          colorSchema={'basic'}
          Icon={Arrow}
          iconClassName={clsx({ [Style.rotated180]: !isExpanded })}
        />
      </div>
      {isExpanded && <div className={clsx(Style.collapsibleContainer, contentClassName)}>{children}</div>}
    </div>
  );
};
