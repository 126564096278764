﻿import { SurveyReportOptionItem } from './surveyReport';
import { createReport, triggerDownloadFileDialog } from '../report-helpers';
import { SurveyReportLayout } from './surveyReport.layout';
import React from 'react';
import { getAnswersStatistic } from '../../../services/api/api-client/SurveyClient';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { QueryFactory } from '../../../services/api';
import i18n from 'i18next';

export const generateSurveyReport = async (
  patient: {
    id: string;
    uniqueId: string;
    tags: Record<string, string>;
  },
  surveyTypes: string[],
  study: {
    studyNumber: string;
    title: string;
  },
  authorFullName: string,
  withMissedSurvey: boolean,
  signPdf: boolean,
) => {
  const allSurveys: SurveyReportOptionItem[] = [];

  for (const surveyType of surveyTypes) {
    const resultData = await getAnswersStatistic(patient?.id, surveyType);
    allSurveys.push({ ...resultData, surveyTitle: surveyType });
  }

  let blob = await createReport(
    <SurveyReportLayout
      {...{
        studyNumber: study.studyNumber,
        studyName: study.title,
        profileFullName: authorFullName,
        patientUid: patient.uniqueId,
        allSurveys,
        includeMissingSurveys: withMissedSurvey,
        patientTags: patient.tags,
      }}
    />,
  );

  const filename = `Study-${study.studyNumber}-Patient-${patient.uniqueId}-${
    surveyTypes.length === 1 ? surveyTypes[0].replace(' ', '_') : 'Surveys'
  }-${localFormat(new Date(), DateFormats.forFiles)}`;

  if (signPdf) {
    const signedPdf = await QueryFactory.PdfClient.signPdf(
      { data: blob, fileName: filename },
      i18n.t('LogExport.SigningReason'),
      420,
      95,
      60,
      115,
    );
    blob = signedPdf.data;
  }

  triggerDownloadFileDialog(blob, filename);
};
