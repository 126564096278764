import React from 'react';
import { IAttentionText } from '../../../../forms/base/controls/content/AttentionText';
import { RPDF } from '../../../react-pdf';

export const AttentionText: IAttentionText = ({ text, isHiddenOnOverview }) => {
  if (isHiddenOnOverview) {
    return <RPDF.View />;
  }

  return <RPDF.Text style={[RPDF.typography.heading5, { marginVertical: 4 }]}>{text}</RPDF.Text>;
};
