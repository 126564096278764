/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { ValidationSettings } from '../../toolbox/settingsPanel/content/Validation.component';
import { useEditor, useNode } from '@craftjs/core';
import { CheckboxSettings } from '../../toolbox/settingsPanel/settingsInputs/CheckboxSettings';
import styles from '../controlsStyle.module.css';
import {
  IRadioInputProps,
  radioInputDefaultPropsFactory,
} from '../../../../features/forms/base/controls/inputs/RadioInput';
import { IFormFieldOption } from '../../../../features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import {
  useFieldDataFromUiEditorContext,
  useRefForDataBlockNavigation,
  useFieldValidation,
} from '../Inputs/base/hooks';
import { useTranslation } from 'react-i18next';
import { useContextSelector } from 'use-context-selector';
import { FormFillContext } from '../../uiEditor/provider/formFill.context';
import { IPatientGroupInput } from '../../../../features/forms/base/controls/specials/PatientGroupInput';
import { AppRadioGroupField } from 'uikit/fields';
import { LayoutSettings } from '../../toolbox/settingsPanel/content/LayoutSettings';
import { CommonFieldSettings } from '../../toolbox/settingsPanel/content/OtherSettings';
import { IssueMark } from 'src/components/issue/issueTarget/issueMark.component';
import { PanelContainer } from '../../toolbox/components/panelContainer';
import { ControlsGroup } from '../../toolbox/components/controlsGroup';
import { OverviewField } from 'uikit/fields/overview/OverviewField';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { OVERVIEW_FIELD_ISSUE_MARK_POSITION } from 'src/components/issue/issueTarget/issueMark-helper';

export const PatientGroupInput: IPatientGroupInput = (props) => {
  const { connectors, id } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
    allNodes: state.nodes,
  }));
  const { isSubmitting, formConfig, formResultVersion, patient, stepName } = useContextSelector(
    FormFillContext,
    (x) => x,
  );

  const { connect, drag } = connectors;

  const { size, text, dataKey, isDisabled, isDisableWhenEditing } = props;

  const study = useStudy();
  const options = useMemo(
    () =>
      study?.groups.map((group) => {
        return {
          key: group.id,
          value: group.id.toString(),
          displayValue: group.title,
        } as IFormFieldOption;
      }) ?? [],
    [],
  );

  const { isOnlyView, isEditMode, singleInputValue, setDataBlockFieldValue, fieldEditReason } =
    useFieldDataFromUiEditorContext(dataKey);

  const blockRef = useRefForDataBlockNavigation();
  const { dataBlockValidation } = useFieldValidation({
    isEditable: props.isEditable,
    isDisabled: props.isDisabled,
    rules: props.validation,
  });

  const displayValue = useMemo(
    () => options?.find((x) => x.value === singleInputValue)?.displayValue,
    [singleInputValue, options],
  );

  const editableComponent = (
    <div
      data-test-field-type={'radio-input'}
      className={styles.container}
      style={{ width: size }}
      draggable={enabled}
      ref={(ref) => enabled && connect(drag(ref!))}
    >
      <div ref={blockRef}>
        <AppRadioGroupField
          labelProps={{
            isBold: props.isBold,
            tooltip: props.tooltip,
            text: !props.hideLabel ? props.label || text || dataKey : undefined,
          }}
          disabled={!props.isEditable || isDisabled || (isDisableWhenEditing && isEditMode) || isSubmitting}
          labelField={'displayValue'}
          valueField={'value'}
          options={options}
          numerationOrder={props.numerationOrder}
          numerationStartIndex={props.numerationStartIndex}
          value={singleInputValue}
          isVertical={props.isVertical}
          onChange={(option) => {
            if (option && typeof option === 'string') {
              setDataBlockFieldValue?.(dataKey, option);
              return;
            }

            setDataBlockFieldValue?.(dataKey, option?.value);
          }}
          errorProps={{
            errors: dataBlockValidation?.text,
          }}
        />
      </div>
    </div>
  );

  const viewComponent = (
    <IssueMark
      issueContext={{
        subject: 'Patient',
        topic: 'Records',
        topicAdditional: formConfig?.type,
        linkedPatientUniqId: patient?.patientId,
        fieldDescription: props.label || props.text || props.dataKey,
        resultId: formResultVersion?.formResultId,
        fieldId: id,
        stepName: formConfig?.isMultiInstance ? 'multiple' : stepName,
      }}
      ignoreFieldsForCount={['stepName']}
      position={OVERVIEW_FIELD_ISSUE_MARK_POSITION}
    >
      <OverviewField
        label={props.label || props.text || props.dataKey}
        value={displayValue}
        editReason={fieldEditReason}
      />
    </IssueMark>
  );

  return <>{isOnlyView ? viewComponent : editableComponent}</>;
};

const RadioInputSettings = () => {
  const { t } = useTranslation('dev');
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props as IRadioInputProps,
  }));

  return (
    <div className={styles.settings}>
      <CommonFieldSettings />
      <PanelContainer header={t('StudySettingsPage.FormBuilder.settings.generalSettings')} expanded={false}>
        <ControlsGroup flexible>
          <CheckboxSettings
            label={t('StudySettingsPage.FormBuilder.settings.isVertical')}
            isChecked={!!props.isVertical}
            setValue={(newValue) => setProp((setProps: IRadioInputProps) => (setProps.isVertical = newValue))}
          />
        </ControlsGroup>
      </PanelContainer>

      <LayoutSettings />
      <ValidationSettings applicableRules={{ isRequired: true }} />
    </div>
  );
};

PatientGroupInput.craft = {
  props: radioInputDefaultPropsFactory(),
  related: {
    settings: RadioInputSettings,
  },
};
