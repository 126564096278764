import React, { useState } from 'react';
import { TypographyStyles } from 'styles';
import { formatDateRange } from '../../helpers/date-helpers';
import { toDate } from 'date-fns';
import { ReactComponent as Calendar20Icon } from 'src/assets/img/common/calendar_20.svg';
import Style from './daterange.extra.module.scss';
import clsx from 'clsx';
import { AppButton } from 'uikit/buttons';
import { DateRangeCalendar } from './DateRangeCalendar';
import { Callout, DirectionalHint } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { DateRangePickerV3Props } from './DateRangePickerV3';

export const DateRangePickerV3 = (props: DateRangePickerV3Props) => {
  const { selectedInterval, calendarAlignment, ...rest } = props;

  const [expanded, setExpended] = useState<boolean>(false);
  const calloutButtonId = useId('callout-calendar-button');

  return (
    <>
      <AppButton
        id={calloutButtonId}
        variant={'icon-link'}
        colorSchema={'primary'}
        disabled={props.disabled}
        Icon={Calendar20Icon}
        text={formatDateRange(toDate(selectedInterval.start), toDate(selectedInterval.end))}
        onClick={(e) => {
          e.preventDefault();
          setExpended((prev) => !prev);
        }}
        textClassName={clsx(TypographyStyles.heading2, Style.inputContainer)}
      />

      {expanded && (
        <Callout
          styles={{
            beakCurtain: {
              borderRadius: '10px',
            },
            calloutMain: {
              borderRadius: '10px',
            },
          }}
          className={Style.calendarBlock}
          onDismiss={() => setExpended(false)}
          beakWidth={0}
          gapSpace={4}
          target={`#${calloutButtonId}`}
          directionalHint={calendarAlignment || DirectionalHint.bottomLeftEdge}
          directionalHintFixed={false}
        >
          <DateRangeCalendar {...rest} selectedInterval={selectedInterval} />
        </Callout>
      )}
    </>
  );
};
