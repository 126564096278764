﻿import React, { FC, PropsWithChildren } from 'react';
import { AnimationProps, Loading, LoadingProps } from 'uikit/suspense/Loading';
import { Loader as animationData } from '../../../assets/animations/loadin_book';
import Lottie from 'react-lottie-player';
import styles from './PageLoading.module.scss';
import clsx from 'clsx';
const animation: AnimationProps = {
  size: 88,
  element: <Lottie animationData={animationData} style={{ width: 88, height: 88 }} play={true} loop={true} />,
};

export const PageLoading: FC<PropsWithChildren<LoadingProps>> = (props) => {
  return (
    <Loading animation={animation} {...props} containerClassName={clsx(styles.container, props.containerClassName)} />
  );
};
