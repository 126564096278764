import { defaultToolSettings } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import {
  ApplicableRules,
  ValidationDefaultSettings,
  ValidationProps,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { createId } from 'src/helpers/typeUtils';
import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import { UserComponent } from '@craftjs/core';

export interface IFilesProps extends IFormFieldProps {
  size: string;
  isMandatory?: boolean;
  isEditable?: boolean;
  validation: ValidationProps;
  withComment?: boolean;
}

export type IFilesInput = UserComponent<IFilesProps>;

export function filesDefaultPropsFactory(): IFilesProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    validation: { ...ValidationDefaultSettings },
  };
}

export const filesApplicableValidationRules: ApplicableRules = {
  isRequired: true,
};
