import React, { FC } from 'react';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { DateFormats, localFormat } from '../../../helpers/date-helpers';
import { InfoField, InfoFieldsColumn } from '../components/reportFields';
import { ReportFirstPage } from '../components/ReportFirstPage';
import { ReportSignAndTitleField } from '../components/ReportSignAndTitleField';
import { getTagsAsArray } from '../../patientTags/tagsHelper';

export type FirstPageDataType = {
  data: {
    profileFullName: string;
    studyNumber: string;
    studyName: string;
    filledRecordsCount: number;
    missedRecordsCount: number;
    approvedRecordsCount: number;
    patientUid: string;
    headerText: string;
    patientTags: Record<string, string>;
    studyHasEcrf: boolean;
  };
};

export const FirstPage: FC<FirstPageDataType> = ({
  data: {
    profileFullName,
    studyNumber,
    studyName,
    filledRecordsCount,
    missedRecordsCount,
    patientUid,
    headerText,
    patientTags,
    approvedRecordsCount,
    studyHasEcrf,
  },
}) => {
  const { t } = useScopedTranslation('Reports.Records.FirstPage');

  const tagsArray = getTagsAsArray(patientTags);

  const Header = (pages?: string | JSX.Element) => {
    return (
      <>
        <InfoFieldsColumn>
          <InfoField header={t('CreatedAt')} value={localFormat(new Date(), DateFormats.dateTime)} />
          <InfoField header={t('CreatedBy')} value={profileFullName} />
          <InfoField header={t('Study')} value={`${studyName} (${studyNumber})`} />
          <InfoField header={t('PatientId')} value={patientUid} />
          {tagsArray.map((tag, index) => {
            return <InfoField key={index} header={tag.key} value={tag.value} />;
          })}
        </InfoFieldsColumn>
        <InfoFieldsColumn>
          <InfoField header={t('PageCount')} value={pages} />
          <InfoField header={t('FilledRecordsCount')} value={filledRecordsCount.toString()} />
          <InfoField header={t('MissedRecordsCount')} value={missedRecordsCount.toString()} />
        </InfoFieldsColumn>
      </>
    );
  };

  const HeaderForEcrfStudy = (pages?: string | JSX.Element) => {
    return (
      <>
        <InfoFieldsColumn>
          <InfoField header={t('CreatedAt')} value={localFormat(new Date(), DateFormats.dateTime)} />
          <InfoField header={t('CreatedBy')} value={profileFullName} />
          <InfoField header={t('Study')} value={`${studyName} (${studyNumber})`} />
          <InfoField header={t('PatientId')} value={patientUid} />
          {tagsArray.map((tag, index) => {
            return <InfoField key={index} header={tag.key} value={tag.value} />;
          })}
        </InfoFieldsColumn>
        <InfoFieldsColumn>
          <InfoField header={t('PageCount')} value={pages} />
          <InfoField header={t('ApprovedRecordsCount')} value={approvedRecordsCount.toString()} />
        </InfoFieldsColumn>
      </>
    );
  };

  const Footer = () => <ReportSignAndTitleField customText={t('Signature', { name: profileFullName })} />;

  return (
    <ReportFirstPage
      headerText={headerText}
      SignTitleComponent={Footer}
      HeaderComponent={studyHasEcrf ? HeaderForEcrfStudy : Header}
      patientUid={patientUid}
    />
  );
};
