import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useModal } from 'src/application/hooks/useModal';
import { useCommonLocalization } from 'src/application/localisation/useCommonLocalization';
import { ButtonColorSchema } from 'uikit/buttons';
import { FooterButtonProps } from './dialogModal';
import { DialogModal } from './dialogModal.component';
import Style from './dialogModal.module.scss';
import clsx from 'clsx';

export type ConfirmOptions = {
  title: string;
  text: JSX.Element | string;
  okButtonText?: string;
  okButtonColorSchema?: ButtonColorSchema;
  cancelButtonText?: string;
  cancelButtonColorSchema?: ButtonColorSchema;
  okButtonFirst?: boolean;
};

export enum ConfirmResult {
  Confirm,
  Cancel,
  Closed,
}

export type ConfirmationModalOptions = {
  containerClassName?: string;
};

export const useConfirmationModal = (params?: ConfirmationModalOptions) => {
  const commonLocalizer = useCommonLocalization();
  const modal = useModal('CLOSED');
  const [options, setOptions] = useState<Required<ConfirmOptions>>({
    text: 'Default text',
    title: 'Confirmation modal',
    okButtonText: commonLocalizer('Common_Ok'),
    okButtonColorSchema: 'primary',
    cancelButtonText: commonLocalizer('Common_Cancel'),
    cancelButtonColorSchema: 'secondary',
    okButtonFirst: false,
  });

  const promiseRef = useRef<(test: ConfirmResult) => void>();

  const closeHandler = useCallback(() => {
    promiseRef?.current?.(ConfirmResult.Closed);
    modal.closeModal();
  }, [modal]);

  const okButton: FooterButtonProps = useMemo(
    () => ({
      testId: 'confirm-button',
      colorSchema: options.okButtonColorSchema,
      text: options.okButtonText,
      onClick: () => {
        promiseRef?.current?.(ConfirmResult.Confirm);
        modal.closeModal();
      },
    }),
    [modal, options.okButtonColorSchema, options.okButtonText],
  );

  const cancelButton: FooterButtonProps = useMemo(
    () => ({
      testId: 'cancel-button',
      colorSchema: options.cancelButtonColorSchema,
      text: options.cancelButtonText,
      onClick: () => {
        promiseRef?.current?.(ConfirmResult.Cancel);
        modal.closeModal();
      },
    }),
    [modal, options.cancelButtonColorSchema, options.cancelButtonText],
  );

  return {
    open: (o: ConfirmOptions): Promise<ConfirmResult> => {
      setOptions((cur) => ({ ...cur, ...o }));
      modal.openModal();

      return new Promise<ConfirmResult>((resolve) => {
        promiseRef.current = resolve;
      });
    },
    modal: (
      <DialogModal
        testId={'confirmation-modal'}
        visible={modal.visible}
        onHide={closeHandler}
        title={options.title}
        containerClassName={clsx(Style.confirmationModalContainer, params?.containerClassName)}
        footer={{
          leftButton: options.okButtonFirst ? okButton : cancelButton,
          rightButton: options.okButtonFirst ? cancelButton : okButton,
        }}
      >
        {options.text}
      </DialogModal>
    ),
  };
};
