import React from 'react';
import ReactCalendar from 'react-calendar';
import { MonthProps } from './dailyCheckCalendar';
import { useTranslation } from 'react-i18next';
import Style from './dailyCheckCalendar.module.css';
import './Calendar.scss';
import {
  areIntervalsOverlapping,
  eachDayOfInterval,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { AnswerStatisticsListItem, QualityMetric } from 'src/services/api/api-client';
import { ReactComponent as EmptySmile } from '../../assets/img/calendar/emptySmile.svg';
import { ReactComponent as AwesomeSmile } from '../../assets/img/calendar/awesomeSmile.svg';
import { ReactComponent as AwfulSmile } from '../../assets/img/calendar/awfulSmile.svg';
import { ReactComponent as BadSmile } from '../../assets/img/calendar/badSmile.svg';
import { ReactComponent as NormalSmile } from '../../assets/img/calendar/normalSmile.svg';
import { ReactComponent as GoodSmile } from '../../assets/img/calendar/goodSmile.svg';
import { ReactComponent as Error } from '../../assets/img/common/error_20.svg';
import { TypographyStyles } from '../../styles';
import clsx from 'clsx';
import startOfWeek from 'date-fns/startOfWeek';
import { getScoreStyleColor } from 'src/helpers/color-helper';
import { formatScoreValue } from 'src/helpers/question-helper';
import { dateLocales } from 'src/application/localisation/locales';
import { Tooltip } from '../../components/uikit/tooltip/tooltip.component';

const dailyCheckIcons = {
  [QualityMetric.Awful]: <AwfulSmile className={Style.smile} />,
  [QualityMetric.Bad]: <BadSmile className={Style.smile} />,
  [QualityMetric.Normal]: <NormalSmile className={Style.smile} />,
  [QualityMetric.Good]: <GoodSmile className={Style.smile} />,
  [QualityMetric.Awesome]: <AwesomeSmile className={Style.smile} />,
  [QualityMetric.Empty]: <EmptySmile className={Style.smile} />,
};

const avgCellClassNameSelector = (average: number | undefined) => {
  if (!average || average < 1) {
    return '';
  }
  if (average < 2) {
    return getScoreStyleColor(QualityMetric.Awful);
  }
  if (average < 3) {
    return getScoreStyleColor(QualityMetric.Bad);
  }
  if (average < 4) {
    return getScoreStyleColor(QualityMetric.Normal);
  }
  if (average < 5) {
    return getScoreStyleColor(QualityMetric.Good);
  }
  return getScoreStyleColor(QualityMetric.Awesome);
};

// TODO: currently out of support. We use german data locale only
// const calendarTypeSelector = (): CalendarType => {
//   const firstDayOfWeek = startOfWeek(Date.now(), getCurrentDateLocale());
//   if (isMonday(firstDayOfWeek)) {
//     return 'ISO 8601';
//   }
//   return 'US';
// };

const hasErrorScore = (answer: AnswerStatisticsListItem) => {
  return answer.answerId && answer.score === null;
};

export const MonthComponent = (props: MonthProps) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const renderTileContent = (date: Date): JSX.Element => {
    const dateString = date.toString();
    let average: number | undefined;
    // Todo: May be we need to use calendarTypeSelector() instead dateLocales.de a bit later, when sync it with backend.
    // #30154 Different start week on daily calendar.
    const startDayOfWeek = startOfWeek(date, { locale: dateLocales.de });
    const endDayOfWeek = endOfWeek(date, { locale: dateLocales.de });

    if (isSameDay(date, endDayOfWeek)) {
      const weekInterval = { start: startDayOfWeek, end: date };
      const answersInterval = { start: props.minAnsweredDate, end: props.maxAnsweredDate };
      if (areIntervalsOverlapping(weekInterval, answersInterval)) {
        const everyDayOfTimeSpan = eachDayOfInterval(weekInterval);
        const answers = everyDayOfTimeSpan.map((day) => {
          if (day.toString() in props.answers) {
            return props.answers[day.toString()][0];
          }
          return null;
        });
        const answersToCalculateAverage = answers.filter((answer) => answer && answer.quality !== QualityMetric.Empty);
        if (answersToCalculateAverage.length > 0) {
          average =
            answersToCalculateAverage.reduce((previous, current) => {
              if (current && current.score) {
                return previous + current.score;
              }
              return previous + 0;
            }, 0) / answersToCalculateAverage.length;
        }
      }
    }
    const answer = props.answers[dateString];
    return (
      <>
        <div
          className={clsx({
            [Style.dayContainer]: true,
            [Style.firstDayOfWeek]: isSameDay(date, startDayOfWeek),
            [Style.dayContainerEmpty]: date > props.maxAnsweredDate,
          })}
        >
          {date <= startOfDay(props.maxAnsweredDate) && startOfDay(date) >= startOfDay(props.minAnsweredDate) && (
            <div className={clsx({ [Style.dayOutOfMonth]: !isSameMonth(props.activeDate, date) })}>
              {answer ? (
                answer.some(hasErrorScore) ? (
                  <Tooltip
                    calloutContainerStyles={Style.errorTooltip}
                    text={t('Forms.Score.ScoreCalculation.CantRetryMessage')}
                  >
                    <Error className={Style.errorIcon} />
                  </Tooltip>
                ) : (
                  dailyCheckIcons[answer[0].quality]
                )
              ) : (
                dailyCheckIcons[QualityMetric.Empty]
              )}
            </div>
          )}
          <span
            className={clsx({
              [Style.dateNumber]: true,
              [Style.dayOutOfMonth]: !isSameMonth(props.activeDate, date),
              [Style.emptyDayDateNumber]:
                date > props.maxAnsweredDate || startOfDay(date) < startOfDay(props.minAnsweredDate),
            })}
          >
            {date.getDate()}
          </span>
        </div>
        {isSameDay(date, endDayOfWeek) && (
          <div
            className={clsx({
              [Style.averageContainer]: true,
              [avgCellClassNameSelector(average)]: average,
            })}
          >
            <span className={Style.averageValue}>{formatScoreValue(average, false)}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={clsx({ [Style.emptyMonth]: startOfMonth(props.activeDate) > props.maxAnsweredDate })}>
      <h2 className={clsx(Style.monthHeader, TypographyStyles.plainText14)}>
        {format(props.activeDate, 'MMM yyyy')}
        <span className={Style.currentMonth}>
          {isSameMonth(props.activeDate, new Date()) &&
            t('Dashboard.PatientAccordion.SurveySection.StatisticsModal.DailyCheck.CurrentMonth')}
        </span>
      </h2>
      <div
        className={clsx('month-calendar', { [Style.currentMonthBorder]: isSameMonth(props.activeDate, new Date()) })}
      >
        <ReactCalendar
          locale={i18n.language}
          calendarType={'iso8601'}
          showNavigation={false}
          tileContent={({ date }) => renderTileContent(date)}
          tileDisabled={() => true}
          activeStartDate={props.activeDate}
          showFixedNumberOfWeeks={true}
        />
      </div>
    </div>
  );
};
