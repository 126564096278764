import { IQuestionScheme, ISurveyOption, QuestionType, SurveyQuestion } from 'src/services/api/api-client';
import React from 'react';
import { RPDF } from 'src/features/report/react-pdf';
import { getQuestionFromAnswerScheme } from 'src/helpers/question-helper';
import { ReportColors } from 'src/features/report/report-colors';
import { isNullOrEmpty } from 'src/helpers/string-helper';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';

export const SurveyReportAnswerContent = (props: {
  answers: (IQuestionScheme | null)[];
  question: SurveyQuestion;
  parentQuestion?: SurveyQuestion;
}) => {
  const { answers, parentQuestion, question } = props;
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.SurveySection');

  const renderAnswerText = (answer: ISurveyOption) => {
    switch (question.questionType) {
      case QuestionType.Scale:
      case QuestionType.SingleChose:
        return question.isYesNoQuestion ? answer?.optionText : answer?.answerValue;
      case QuestionType.ConditionalQuestion:
        return answer?.optionText;
      case QuestionType.FreeInput:
        return answer?.answerValue || '';

      default:
        return answer?.optionText;
    }
  };

  return (
    <>
      {answers?.map((answerScheme, i) => {
        if (!answerScheme || !question.id) {
          return <RPDF.View key={i} />;
        }

        const questionFromAnswerScheme = getQuestionFromAnswerScheme(answerScheme, parentQuestion, question);

        // This question was not shown to the user
        if (!questionFromAnswerScheme) {
          return (
            <RPDF.View key={i}>
              <RPDF.Text style={[RPDF.controlStyles.answerCell, { color: ReportColors.plain }]}>
                {t('LastAvailableStats.N/a')}
              </RPDF.Text>
            </RPDF.View>
          );
        }

        // This question was skipped by checked 'No answer' option
        const answerOption = questionFromAnswerScheme.options?.find((x) => x.answerValue !== null);
        if (!answerOption || isNullOrEmpty(answerOption.answerValue)) {
          return (
            <RPDF.View key={i}>
              <RPDF.Text style={[RPDF.controlStyles.answerCell, { color: ReportColors.plain }]}>
                {t('LastAvailableStats.Skipped')}
              </RPDF.Text>
            </RPDF.View>
          );
        }

        return (
          <RPDF.View key={i}>
            <RPDF.Text style={RPDF.controlStyles.answerCell}>{renderAnswerText(answerOption)}</RPDF.Text>
          </RPDF.View>
        );
      })}
    </>
  );
};
