﻿import React, { CSSProperties, forwardRef, PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './FieldGroup.module.scss';

export type FieldGroupLevel = 'first' | 'second' | 'third';

type FieldGroupProps = PropsWithChildren<{
  title: string;
  hideTitle?: boolean;
  /** @default second */
  level?: FieldGroupLevel;

  draggable?: boolean;
  disabled?: boolean;
  testId?: string;
  className?: string;
  style?: CSSProperties;

  titleRef?: React.ForwardedRef<HTMLDivElement>;
}>;

export const FieldGroup = forwardRef<HTMLDivElement, FieldGroupProps>((props, ref) => {
  const level = props.level ?? 'second';

  return (
    <div
      data-test-id={props.testId}
      ref={ref}
      draggable={props.draggable}
      className={clsx(
        {
          [styles.first]: level === 'first',
          [styles.second]: level === 'second',
          [styles.third]: level === 'third',
        },
        props.className,
        styles.fieldGroup,
      )}
      style={props.style}
    >
      {!props.hideTitle && (
        <div ref={props.titleRef} aria-disabled={props.disabled} className={styles.title}>
          {props.title}
        </div>
      )}
      {props.children}
    </div>
  );
});
