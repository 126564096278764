﻿import React, { FC, useContext, useMemo } from 'react';
import { useScopedTranslation } from '../../../application/localisation/useScopedTranslation';
import { PatientCardContext } from '../PatientCardContext';
import { FormResultVm, RecordRowVm } from './recordRow';
import { RecordsGroup } from './recordsGroup.component';
import { RecordRow } from './recordRow.component';
import { getFormStateMultiInstance } from 'src/features/forms/form-helper';
import { useStudy } from 'src/helpers/hooks/useStudy';

export const MultiInstanceFormRows: FC = () => {
  const { t } = useScopedTranslation('Dashboard.PatientAccordion.GeneralInfo.Records');
  const study = useStudy();
  const {
    patient: { studyFinishedAt, studyRouteProgress },
    group: { formConfigIdForNotes },
  } = useContext(PatientCardContext);

  const rowVms = useMemo<RecordRowVm[]>(() => {
    return (
      studyRouteProgress.multiInstanceForms
        ?.filter((x) => !studyFinishedAt || x.formResultIds.length > 0)
        .filter((x) => x.formConfigId !== formConfigIdForNotes)
        .filter((x) => x.isAvailableForFilling || x.formResultIds.length > 0)
        .sort((a, b) => a.formType.localeCompare(b.formType))
        .map((x) => {
          const vms = x.formResultIds.map<FormResultVm>((y) => ({
            formResultId: y,
            stepName: undefined,
            stepIsActive: true,
          }));

          if (!studyFinishedAt)
            vms.push({
              formResultId: undefined,
              stepName: undefined,
              stepIsActive: true,
            });

          return {
            formConfigId: x.formConfigId,
            formType: x.formType,
            formResults: vms,
            fillingProgress: undefined,
            formLifeCycle: undefined,
            state: getFormStateMultiInstance(!!study?.hasEcrf, x.aggregatedLifecycleState),
            isSkipped: undefined,
            isMultiInstance: true,
          };
        }) ?? []
    );
  }, [formConfigIdForNotes, study?.hasEcrf, studyFinishedAt, studyRouteProgress.multiInstanceForms]);

  if (rowVms.length === 0) return null;

  return (
    <RecordsGroup title={t('OptionalForms')} isExpandDefault={true}>
      {rowVms.map((row) => (
        <RecordRow key={row.formConfigId} type={'multiple'} vm={row} />
      ))}
    </RecordsGroup>
  );
};
