import React, { useCallback, useMemo, useState, FC } from 'react';
import { Chips } from 'uikit/chips/chips.component';
import { useTranslation } from 'react-i18next';
import { DecodedValueMap, SetQuery } from 'use-query-params';
import { PatientFilterParamsType } from './patientTable';
import { IPatientGroupCountersDto, PatientStudyStateEnum } from '../../services/api/api-client';
import Style from './patientFilters.module.css';
import { TypographyStyles } from '../../styles';
import clsx from 'clsx';
import { AppButton } from 'uikit/buttons';
import { ReactComponent as Filter } from '../../assets/img/common/filter_20.svg';
import { ReactComponent as Reset24 } from '../../assets/img/common/reset_24.svg';
import { ReactComponent as Reset16 } from '../../assets/img/issues/reset_16.svg';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { useQueryClient } from '@tanstack/react-query';
import { QueryFactory } from '../../services/api';
import { useScopedTranslation } from '../../application/localisation/useScopedTranslation';
import { filterTags } from '../../features/patientTags/tagsHelper';
import { useGetTagsQuery } from '../../services/api/api-client/StudyQuery';
import { DirectionalHint } from '@fluentui/react';
import { DropDownMenu } from 'uikit/dropDownMenu/dropDownMenu.component';
import { ReactComponent as CheckboxUnchecked } from 'assets/img/issues/checkbox_unchecked_16.svg';
import { ReactComponent as CheckboxChecked } from 'assets/img/issues/checkbox_checked_16.svg';
import { DropDownMenuOption } from 'uikit/dropDownMenu/dropDownMenu';
import { patientTableQueryParamsConfig } from './patientTable.component';
import TablePageLayout from '../tablePageLayout/TablePageLayout';
import { TabItemProps, Tabs } from 'uikit/tabs/tabs';
import { conditionalConcat } from 'src/helpers/arrayHelpers';
import { useStaffProfile, useStudy } from 'src/helpers/hooks/useStudy';

const dashboardFilters: (keyof PatientFilterParamsType)[] = [
  'isClinic',
  'isControlled',
  'needChecking',
  'hasNoApp',
  'studyStartNotDefine',
  'canBeArchived',
];

export const defaultFilterParamsObject: Partial<DecodedValueMap<ReturnType<typeof patientTableQueryParamsConfig>>> = {
  isClinic: undefined,
  isControlled: undefined,
  needChecking: undefined,
  hasNoApp: undefined,
  studyStartNotDefine: undefined,
  page: 1,
  tags: undefined,
  issueId: undefined,
  canBeArchived: undefined,
};

export const PatientFilters = (props: {
  queryParams: DecodedValueMap<ReturnType<typeof patientTableQueryParamsConfig>>;
  setQueryParams: SetQuery<ReturnType<typeof patientTableQueryParamsConfig>>;
  hasSurveys: boolean;
  hasTherapy: boolean;
  patientCounters: IPatientGroupCountersDto[];
  studyId: number;
}) => {
  const { t } = useTranslation();
  const { closedAt } = useStudy() ?? {};
  const { t: patientFiltersLocalizer } = useScopedTranslation('Dashboard.PatientFilters');
  const { t: patientStateLocalizer } = useScopedTranslation('Dashboard.PatientStudyState');

  const { setQueryParams, queryParams, studyId } = props;
  const queryClient = useQueryClient();
  const tagsQuery = useGetTagsQuery(studyId);

  const applyFilterCount = useMemo(() => {
    return (
      Object.keys(queryParams.tags).length +
      Object.entries(queryParams).filter(
        ([key, v]) => dashboardFilters.includes(key as keyof PatientFilterParamsType) && v !== undefined,
      ).length
    );
  }, [queryParams]);

  const [isHidden, setIsHidden] = useState<boolean>(!applyFilterCount);

  const stateCounters = useMemo(() => {
    return props.patientCounters.find((x) => x.groupId === props.queryParams.patientGroup);
  }, [props.queryParams.patientGroup, props.patientCounters]);

  const resetFilter = useCallback(() => {
    setQueryParams(defaultFilterParamsObject);
  }, [setQueryParams]);

  const onChangeStudyStateFilter = useCallback(
    async (
      queryParamsObject: Partial<DecodedValueMap<ReturnType<typeof patientTableQueryParamsConfig>>>,
      withDefault: boolean,
    ) => {
      await queryClient.invalidateQueries(QueryFactory.PatientQuery.getPatientStudyCountersQueryKey(studyId));
      setQueryParams({
        ...(withDefault ? defaultFilterParamsObject : {}),
        ...queryParamsObject,
      });
    },
    [setQueryParams, studyId],
  );

  const tabs = useMemo(() => {
    return conditionalConcat<TabItemProps>(
      !closedAt && [
        {
          text: patientStateLocalizer('NotStarted', { value: stateCounters?.notStartedCount || 0 }),
          isSelected: queryParams.studyState === PatientStudyStateEnum.NotStarted,
          onClick: () => onChangeStudyStateFilter({ studyState: PatientStudyStateEnum.NotStarted }, true),
          testId: 'Not started',
        },
        {
          text: patientStateLocalizer('InProgress', { value: stateCounters?.inProgressCount || 0 }),
          isSelected: queryParams.studyState === PatientStudyStateEnum.InProgress,
          onClick: () =>
            onChangeStudyStateFilter(
              {
                studyState: PatientStudyStateEnum.InProgress,
                studyStartNotDefine: undefined,
                canBeArchived: undefined,
                page: 1,
              },
              false,
            ),
          testId: 'In progress',
        },
        {
          text: patientStateLocalizer('Finished', { value: stateCounters?.finishedCount || 0 }),
          isSelected: queryParams.studyState === PatientStudyStateEnum.Finished,
          onClick: () => onChangeStudyStateFilter({ studyState: PatientStudyStateEnum.Finished }, true),
          testId: 'Finished',
        },
      ],
      [
        {
          text: patientStateLocalizer('Archived', { value: stateCounters?.archivedCount || 0 }),
          isSelected: queryParams.studyState === PatientStudyStateEnum.Archived,
          onClick: () => onChangeStudyStateFilter({ studyState: PatientStudyStateEnum.Archived }, true),
          testId: 'Archived',
        },
      ],
    );
  }, [closedAt, onChangeStudyStateFilter, patientStateLocalizer, queryParams.studyState, stateCounters]);

  return (
    <div style={{ display: 'contents' }} data-test-id={'patient-filters-container'}>
      <TablePageLayout.Header.Row>
        <Tabs items={tabs} testId={'patient-table-study-finish-tabs'} />
      </TablePageLayout.Header.Row>

      <TablePageLayout.Header.Row>
        {queryParams.studyState !== PatientStudyStateEnum.Archived ||
        (tagsQuery?.data && Object.keys(tagsQuery.data).length > 0) ? (
          <div className={Style.filterGroupWrapper}>
            <div className={Style.filterButtonWrapper}>
              <AppButton
                testId={'filters-toggle'}
                variant={'icon-link'}
                colorSchema={'secondary'}
                Icon={Filter}
                onClick={() => setIsHidden((s) => !s)}
                text={isHidden ? patientFiltersLocalizer('ShowFilters') : patientFiltersLocalizer('HideFilters')}
              />

              {isHidden && applyFilterCount > 0 && (
                <div className={clsx(TypographyStyles.plainText14, Style.filterCount)}>
                  {patientFiltersLocalizer('ApplyCount', { count: applyFilterCount })}
                </div>
              )}
            </div>
            {applyFilterCount > 0 && (
              <Tooltip text={t('Common_Reset')}>
                <AppButton
                  variant={'icon-link'}
                  colorSchema={'decline'}
                  Icon={Reset24}
                  onClick={resetFilter}
                  disabled={!applyFilterCount}
                  data-test-id={'filters-reset'}
                />
              </Tooltip>
            )}
          </div>
        ) : (
          <></>
        )}

        <TablePageLayout.Header.Search
          value={queryParams.searchQuery || ''}
          placeholder={t('Dashboard.PatientTable.Search')}
          onChange={(e) => setQueryParams({ searchQuery: e.target.value, page: undefined })}
        />
      </TablePageLayout.Header.Row>

      {!isHidden && (
        <TablePageLayout.Header.Row>
          <div className={Style.filterGroup} data-test-id={'patient-table-filters'}>
            {queryParams.studyState === PatientStudyStateEnum.InProgress && (
              <div className={Style.chipsGroup}>
                <div className={clsx(TypographyStyles.plainText12, Style.filterGroupName)}>
                  {patientFiltersLocalizer('GeneralFilters')}
                </div>
                <Chips
                  text={patientFiltersLocalizer('IsClinic')}
                  checked={queryParams.isClinic === true}
                  onClick={() => {
                    setQueryParams({
                      isClinic: queryParams.isClinic === true ? undefined : true,
                      page: 1,
                    });
                  }}
                />
                <Chips
                  text={patientFiltersLocalizer('NotIsClinic')}
                  checked={queryParams.isClinic === false}
                  onClick={() => {
                    setQueryParams({
                      isClinic: queryParams.isClinic === false ? undefined : false,
                      page: 1,
                    });
                  }}
                />
                <Chips
                  text={patientFiltersLocalizer('IsControlled')}
                  checked={queryParams.isControlled ?? undefined}
                  onClick={() => {
                    setQueryParams({
                      isControlled: queryParams.isControlled === true ? undefined : true,
                      page: 1,
                    });
                  }}
                />
                {props.hasTherapy && (
                  <Chips
                    text={patientFiltersLocalizer('NeedChecking')}
                    checked={queryParams.needChecking ?? undefined}
                    onClick={() => {
                      setQueryParams({
                        needChecking: queryParams.needChecking === true ? undefined : true,
                        page: 1,
                      });
                    }}
                  />
                )}
                {props.hasSurveys && (
                  <Chips
                    text={patientFiltersLocalizer('NoApp')}
                    checked={queryParams.hasNoApp ?? undefined}
                    onClick={() => {
                      setQueryParams({
                        hasNoApp: queryParams.hasNoApp === true ? undefined : true,
                        page: 1,
                      });
                    }}
                  />
                )}
              </div>
            )}
            {queryParams.studyState === PatientStudyStateEnum.NotStarted && (
              <div className={Style.chipsGroup}>
                <div className={clsx(TypographyStyles.plainText12, Style.filterGroupName)}>
                  {patientFiltersLocalizer('GeneralFilters')}
                </div>
                <Chips
                  text={patientFiltersLocalizer('StudyStartNotDefine')}
                  checked={queryParams.studyStartNotDefine === true}
                  onClick={() => {
                    setQueryParams({
                      studyStartNotDefine: queryParams.studyStartNotDefine === true ? undefined : true,
                      page: 1,
                    });
                  }}
                />
              </div>
            )}
            {queryParams.studyState === PatientStudyStateEnum.Finished && (
              <div className={Style.chipsGroup}>
                <div className={clsx(TypographyStyles.plainText12, Style.filterGroupName)}>
                  {patientFiltersLocalizer('GeneralFilters')}
                </div>
                <Chips
                  text={patientFiltersLocalizer('CanBeArchived')}
                  checked={queryParams.canBeArchived === true}
                  onClick={() => {
                    setQueryParams({
                      canBeArchived: queryParams.canBeArchived === true ? undefined : true,
                      page: 1,
                    });
                  }}
                />
                <Chips
                  text={patientFiltersLocalizer('CanNotBeArchived')}
                  checked={queryParams.canBeArchived === false}
                  onClick={() => {
                    setQueryParams({
                      canBeArchived: queryParams.canBeArchived === false ? undefined : false,
                      page: 1,
                    });
                  }}
                />
              </div>
            )}
            <TagFiltersPart
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              tagsOfStudy={tagsQuery.data ?? {}}
            />
          </div>
        </TablePageLayout.Header.Row>
      )}
    </div>
  );
};

const TagFiltersPart: FC<{
  queryParams: DecodedValueMap<ReturnType<typeof patientTableQueryParamsConfig>>;
  setQueryParams: SetQuery<ReturnType<typeof patientTableQueryParamsConfig>>;
  tagsOfStudy: Record<string, string[]>;
}> = (props) => {
  const { t } = useTranslation();
  const { queryParams, setQueryParams, tagsOfStudy } = props;
  const {
    profile: { tagFilters: doctorTagFilters },
  } = useStaffProfile();

  const { tagsForDropdowns, tagsForChips } = useMemo(() => {
    // We must filter tag here also
    // because the backend doesn't filter them
    // if current user is admin
    const filteredTags = filterTags(doctorTagFilters, tagsOfStudy);

    const tagGroups = Object.keys(filteredTags).map((key) => ({ key, values: filteredTags[key] }));

    tagGroups.sort((a, b) => b.values.length - a.values.length);

    const MAX_TAG_CHIPS_COUNT = 4;

    const chipsCount = tagGroups.reduce((sum, x) => sum + x.values.length, 0);
    const forDropdowns: { key: string; values: string[] }[] = [];
    const forChips: { key: string; values: string[] }[] = [];
    for (const tagGroup of tagGroups) {
      if (chipsCount > MAX_TAG_CHIPS_COUNT && tagGroup.values.length > 1) {
        forDropdowns.push(tagGroup);
      } else {
        forChips.push(tagGroup);
      }
    }

    return { tagsForDropdowns: forDropdowns, tagsForChips: forChips };
  }, [doctorTagFilters, tagsOfStudy]);

  const toggleTags = useCallback(
    (key: string, values: string[]) => {
      return () => {
        const tags = {
          ...queryParams.tags,
        };

        // This is work around for bug in react-query
        tags[key] = [...(tags[key] ?? [])];

        for (const value of values) {
          const index: number = tags[key].indexOf(value);

          if (index === -1) tags[key].push(value);
          else tags[key].splice(index, 1);
        }

        if (!tags[key].length) delete tags[key];

        const tagsCount = Object.keys(tags).length;

        setQueryParams({ tags: tagsCount ? tags : undefined });
      };
    },
    [queryParams, setQueryParams],
  );

  const chipVms = useMemo(
    () =>
      tagsForChips.map((tag) => {
        return {
          key: tag.key,
          values: tag.values.map((value) => {
            const disabled = doctorTagFilters?.[tag.key]?.length === 1;

            return (
              <Chips
                key={value}
                testId={'tag'}
                text={value}
                onClick={disabled ? undefined : toggleTags(tag.key, [value])}
                checked={disabled || !!queryParams.tags[tag.key]?.includes(value)}
                disabled={disabled}
              />
            );
          }),
        };
      }),
    [doctorTagFilters, queryParams.tags, tagsForChips, toggleTags],
  );

  const multivaluedTagFilterVms = useMemo(() => {
    const resetOption = (key: string) => ({
      key: 'reset',
      icon: <Reset16 />,
      text: t('Dashboard.PatientFilters.ResetTag'),
      action: toggleTags(key, queryParams.tags[key] ?? []),
      className: Style.resetOption,
    });

    return tagsForDropdowns
      .map(({ key, values }) => {
        const firstCheckedValue = queryParams.tags[key]?.[0];
        const checkedValueCount = queryParams.tags[key]?.length ?? 0;

        return {
          anySelected: checkedValueCount > 0,
          key: key,
          text: firstCheckedValue ? `${key}: ${firstCheckedValue}` : key,
          counter: checkedValueCount > 1 ? `(+${checkedValueCount - 1})` : '',
          options: values
            .map((value) => {
              const checked = !!queryParams.tags[key]?.includes(value);

              return {
                key: value,
                icon: checked ? <CheckboxChecked /> : <CheckboxUnchecked />,
                text: value,
                checked,
                action: toggleTags(key, [value]),
                className: clsx(Style.commonOption, Style.fillNone),
              } as DropDownMenuOption;
            })
            .concat([resetOption(key)]),
        };
      })
      .flat();
  }, [queryParams.tags, t, tagsForDropdowns, toggleTags]);

  return (
    <>
      {chipVms.map((tags) => (
        <div key={tags.key} className={Style.chipsGroup} data-test-id={'tag-chips-group'}>
          <div className={clsx(TypographyStyles.plainText12, Style.filterGroupName)}>{`${tags.key}:`}</div>
          {tags.values}
        </div>
      ))}
      {multivaluedTagFilterVms.map((vm) => (
        <DropDownMenu
          key={vm.key}
          colorSchema={vm.anySelected ? 'primary' : 'black'}
          text={vm.text}
          counter={vm.counter}
          options={vm.options}
          closeCalloutByClick={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
          hasChevron={true}
          testId={'tag-filter-menu'}
        />
      ))}
    </>
  );
};
