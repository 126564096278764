import React, { FC, PropsWithChildren, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import Style from './modal.module.scss';
import { AppModalProps } from './modal';
import { ReactComponent as CrossIcon } from './../../../assets/img/cross.svg';
import clsx from 'clsx';
import { Modal } from '@fluentui/react';
import { Freeze } from 'react-freeze';
import { useWindowSize } from '../../../application/hooks/useWindowSize';
import { TypographyStyles } from '../../../styles';
import { AppButton } from 'uikit/buttons';

export const AppModalContainer: FC<PropsWithChildren<AppModalProps>> = (props) => {
  const windowSize = useWindowSize();

  const [isFrozen, setFrozen] = useState<boolean>(!props.visible);
  const [footerHeight, setFooterHeight] = useState<number>(0);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (props.visible) {
      setFrozen(false);
    }
  }, [props.visible]);

  const onDismissed = useCallback(() => {
    setFrozen(true);
    props.onDismissed?.();
  }, [props]);

  const bodyHeightStyle = useMemo(() => {
    const upperAndLowerModalGaps = 32 * 2; // FluentUI lib const
    const minModalHeight = 176; // FluentUI lib const
    let height = windowSize.height - (headerHeight + footerHeight + upperAndLowerModalGaps);

    if (height + headerHeight + footerHeight < minModalHeight) {
      height = minModalHeight - (headerHeight + footerHeight);
    }

    return props.fullHeight
      ? {
          height: height,
          maxHeight: undefined,
        }
      : {
          height: undefined,
          maxHeight: height,
        };
  }, [footerHeight, headerHeight, props.fullHeight, windowSize.height]);

  return (
    <Freeze freeze={isFrozen}>
      <Modal
        scrollableContentClassName={Style.scrollableContent}
        isClickableOutsideFocusTrap={props.isClickOutside ?? true}
        onDismiss={props.onHide}
        onDismissed={onDismissed}
        isOpen={props.visible}
        containerClassName={clsx(Style.container, props.containerClassName)}
        layerProps={{ eventBubblingEnabled: true }}
      >
        <div data-test-id={'modal-screenable-area'} className={Style.screenableArea} />
        <div data-test-id={props.testId}>
          <div
            ref={(el) => {
              setHeaderHeight(el?.clientHeight ?? 0);
            }}
            className={Style.header}
          >
            <div className={clsx(Style.headerWrapper, props.headerClassName)} data-test-id={'modal-header'}>
              <div tabIndex={0} className={TypographyStyles.heading1}>
                {props.title}
                {props.subTitle && <span className={Style.subTitle}>{props.subTitle}</span>}
              </div>
              {props.header}
            </div>
            <AppButton
              onClick={props.onHide}
              variant={'icon-link'}
              colorSchema={'basic'}
              testId={'close-modal-button'}
              Icon={CrossIcon}
              disabled={props.isDisabled}
            />
          </div>
          <div className={Style.bodyWrapper}>
            <div
              className={clsx(Style.body, props.bodyClassName)}
              style={bodyHeightStyle}
              data-test-id={`${props.testId}-body`}
            >
              {props.children}
            </div>
          </div>
          <div
            ref={(el) => {
              setFooterHeight(el?.clientHeight ?? 0);
            }}
          >
            {props.footer}
          </div>
        </div>
      </Modal>
    </Freeze>
  );
};
