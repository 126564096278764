import React, { FC, PropsWithChildren, useState } from 'react';
import { ReactComponent as MedIcon } from '../../../../assets/img/initialsurvey/meds.svg';
import { ReactComponent as CommentIcon } from '../../../../assets/img/initialsurvey/comment_20.svg';
import { ReactComponent as OtherIcon } from '../../../../assets/img/initialsurvey/other.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/img/common/cross_16.svg';
import { useTranslation } from 'react-i18next';
import Style from './appInputTrigger.module.scss';
import clsx from 'clsx';
import { AppButton } from '../../buttons/button/button.component';
import { LocalizedResourceDictionaryKeys } from '../../../../application/localisation/i18next';

export type TriggerComponentState = 'Trigger' | 'Field';

export type AppInputTriggerProps = {
  type?: 'Medicine' | 'Comment' | 'Custom' | 'Other';
  initialState?: TriggerComponentState;
  text?: string;
  className?: string;
  disabled?: boolean;
  onHide?: () => void;
};

const icon = {
  Medicine: MedIcon,
  Other: OtherIcon,
  Comment: CommentIcon,
  Custom: undefined,
};

const localizationKey = {
  Medicine: 'Forms.Controls.Trigger_addMedicine',
  Other: 'Forms.Controls.Trigger_addOther',
  Comment: 'Forms.Controls.Trigger_addComment',
};

export const AppInputTrigger: FC<PropsWithChildren<AppInputTriggerProps>> = ({
  type,
  initialState = 'Field',
  text,
  disabled,
  children,
  className,
  onHide,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState<TriggerComponentState>(initialState);

  if (!type) {
    return <>{children}</>;
  }

  if (state === 'Field') {
    return (
      <div className={Style.container}>
        <AppButton
          className={Style.closeButtonContainer}
          Icon={CrossIcon}
          variant={'icon-link'}
          colorSchema={'decline'}
          onClick={() => {
            setState('Trigger');
            onHide?.();
          }}
        />
        {children}
      </div>
    );
  }

  return (
    <AppButton
      className={clsx({ [Style.disabled]: disabled }, className)}
      variant={'icon-link'}
      colorSchema={'primary'}
      text={type === 'Custom' ? text : t(localizationKey[type] as LocalizedResourceDictionaryKeys)}
      Icon={icon[type]}
      disabled={disabled}
      onClick={() => setState('Field')}
    />
  );
};
