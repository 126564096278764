import { defaultToolSettings } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { createId } from 'src/helpers/typeUtils';
import {
  ApplicableRules,
  ValidationDefaultSettings,
  ValidationProps,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { IFormFieldWithOptionsProps } from 'src/features/forms/base/controls/inputs/base/FormFieldWithOptionsProps';
import { UserComponent } from '@craftjs/core';

export interface ISelectInputProps extends IFormFieldWithOptionsProps {
  isMandatory?: boolean;
  validation: ValidationProps;
  placeholder?: string;
  /** Optional dependent text field */
  withComment?: boolean;
  isMulti?: boolean;
}

export type ISelectInput = UserComponent<ISelectInputProps>;

export function selectInputDefaultPropsFactory(): ISelectInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    label: 'DropDown',
    validation: { ...ValidationDefaultSettings },
    options: [
      { displayValue: 'Option 1', value: createId(), key: 0 },
      { displayValue: 'Option 2', value: createId(), key: 1 },
    ],
  };
}

export const selectApplicableValidationRules: ApplicableRules = {
  isRequired: true,
};
