/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';
import { Container } from '../controls/Containers/Container';
import { StepContainer } from '../controls/Containers/StepContainer';
import { TabsContainer } from '../controls/Containers/TabsContainer';
import { Text } from '../controls/content/Text';
import { useTranslation } from 'react-i18next';
import { Element } from '@craftjs/core';
import { UIEditorBlockWidth } from '../../../features/forms/base/controls/inputs/base/baseControlSettings';
import { AttentionText } from '../controls/content/AttentionText';
import { TextInput } from '../controls/Inputs/TextInput';
import { DateInput } from '../controls/Inputs/DateInput';
import { BmiInput } from '../controls/ConstantBlocks/BmiInput';
import { PatientGroupInput } from '../controls/ConstantBlocks/PatientGroupInput';
import { ImageBlock } from '../controls/content/ImageBlock';
import styles from './barStyles.module.scss';
import { RadioInput } from '../controls/Inputs/RadioInput';
import { radioInputDefaultPropsFactory } from '../../../features/forms/base/controls/inputs/RadioInput';
import { dateInputDefaultPropsFactory } from '../../../features/forms/base/controls/inputs/DateInput';
import { TimeInput } from '../controls/Inputs/TimeInput';
import { DateRangeInput } from '../controls/Inputs/DateRangeInput';
import { SelectInput } from '../controls/Inputs/SelectInput';
import { selectInputDefaultPropsFactory } from '../../../features/forms/base/controls/inputs/SelectInput';
import { dateRangeInputDefaultPropsFactory } from '../../../features/forms/base/controls/inputs/DateRangeInput';
import { CheckBoxInput } from '../controls/Inputs/CheckBoxInput';
import { checkBoxInputDefaultPropsFactory } from '../../../features/forms/base/controls/inputs/CheckBoxInput';
import { Files } from '../controls/Inputs/Files';
import { filesDefaultPropsFactory } from '../../../features/forms/base/controls/inputs/Files';
import { TagInput } from '../controls/Inputs/TagInput';
import { CheckBoxGroupInput } from '../controls/Inputs/CheckBoxGroupInput';
import { checkBoxGroupInputDefaultPropsFactory } from '../../../features/forms/base/controls/inputs/CheckBoxGroupInput';
import { tagInputDefaultPropsFactory } from '../../../features/forms/base/controls/inputs/TagInputProps';
import { timeInputDefaultPropsFactory } from '../../../features/forms/base/controls/inputs/TimeInput';
import { textInputDefaultPropsFactory } from 'src/features/forms/base/controls/inputs/TextInput';
import { FieldGroupContainer, FieldGroupContainerProps } from '../controls/Containers/FieldGroupContainer';
import { NumberInput } from '../controls/Inputs/NumberInput';
import { numberInputDefaultPropsFactory } from 'src/features/forms/base/controls/inputs/INumberInput';
import { Layers } from '@craftjs/layers';
import { LayerItem } from './components/layerItem';
import { PanelContainer } from './components/panelContainer';
import { ControlsGroup } from './components/controlsGroup';
import { ControlSelectorItem } from './components/controlSelectorItem';
import { BufferPanel } from './bufferPanel/bufferPanel';
import { SelectorResolver } from './controlsResolvers';
import { RecordResultList } from '../controls/ConstantBlocks/RecordResultList/RecordResultList';
import { sliderInputDefaultPropsFactory } from 'src/features/forms/base/controls/inputs/SliderInput';
import { SliderInput } from '../controls/Inputs/SliderInput';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { CONST_DATA_KEYS } from '../uiEditor/uiEditor-helper';

export const LeftBar = () => {
  const i18next = useTranslation('dev');
  const { hasEcrf } = useStudy() ?? {};

  return (
    <>
      <div className={styles.toolboxContainer}>
        <PanelContainer variant={'flex'} header={'Form fields'} expanded={true}>
          <ControlsGroup header={i18next.t('StudySettingsPage.FormBuilder.blocks.containers')}>
            <ControlSelectorItem
              {...SelectorResolver().Container}
              renderComponent={() => <Element canvas={true} is={Container} isPadding={true} flexBoxGap={12} />}
            />
            <ControlSelectorItem
              {...SelectorResolver().FieldGroupContainer}
              renderComponent={() => (
                <Element is={FieldGroupContainer} {...FieldGroupContainerProps} useForNavigation={true} />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().StepContainer}
              renderComponent={() => <Element is={StepContainer} />}
            />
            <ControlSelectorItem
              {...SelectorResolver().TabsContainer}
              renderComponent={() => <Element is={TabsContainer} />}
            />
          </ControlsGroup>

          <ControlsGroup header={i18next.t('StudySettingsPage.FormBuilder.blocks.contentBlocks.header')}>
            <ControlSelectorItem
              {...SelectorResolver().Text}
              renderComponent={() => <Element is={Text} size={UIEditorBlockWidth.Stretch} />}
            />
            <ControlSelectorItem
              {...SelectorResolver().AttentionText}
              renderComponent={() => <Element is={AttentionText} size={UIEditorBlockWidth.Stretch} />}
            />
            <ControlSelectorItem
              {...SelectorResolver().ImageBlock}
              renderComponent={() => (
                <Element
                  is={ImageBlock}
                  fileId={null}
                  isFill={true}
                  isHiddenOnOverview={true}
                  size={UIEditorBlockWidth.Stretch}
                />
              )}
            />
          </ControlsGroup>

          <ControlsGroup header={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.header')}>
            <ControlSelectorItem
              name={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.patientId.label')}
              Icon={SelectorResolver().TextInput.Icon}
              renderComponent={() => (
                <Element
                  is={TextInput}
                  {...textInputDefaultPropsFactory()}
                  hasSkipCheckBox={false}
                  dataKey={CONST_DATA_KEYS.UniqueId}
                  validation={{ isRequired: true }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.patientId.label')}
                  placeholder={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.patientId.placeholder')}
                  isNumberOnly={true}
                />
              )}
            />

            <ControlSelectorItem
              name={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.deviceSn.label')}
              Icon={SelectorResolver().NumberInput.Icon}
              renderComponent={() => (
                <Element
                  is={TextInput}
                  {...textInputDefaultPropsFactory()}
                  dataKey={CONST_DATA_KEYS.DeviceSerialNumber}
                  validation={{ isRequired: hasEcrf }}
                  hasSkipCheckBox={hasEcrf}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.deviceSn.label')}
                  placeholder={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.deviceSn.placeholder')}
                />
              )}
            />

            <ControlSelectorItem
              name={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.contractSigningDate.label')}
              Icon={SelectorResolver().DateInput.Icon}
              renderComponent={() => (
                <Element
                  is={DateInput}
                  {...dateInputDefaultPropsFactory()}
                  dataKey={CONST_DATA_KEYS.ContractSignedAt}
                  validation={{ isRequired: hasEcrf }}
                  hasSkipCheckBox={hasEcrf}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.contractSigningDate.label')}
                />
              )}
            />

            <ControlSelectorItem
              name={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.studyStartDate.label')}
              Icon={SelectorResolver().DateInput.Icon}
              renderComponent={() => (
                <Element
                  is={DateInput}
                  {...dateInputDefaultPropsFactory()}
                  dataKey={CONST_DATA_KEYS.StudyStartedAt}
                  validation={{ isRequired: hasEcrf }}
                  hasSkipCheckBox={hasEcrf}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.studyStartDate.label')}
                />
              )}
            />

            <ControlSelectorItem
              name={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.group.label')}
              Icon={SelectorResolver().RadioInput.Icon}
              renderComponent={() => (
                <Element
                  is={PatientGroupInput}
                  {...radioInputDefaultPropsFactory()}
                  dataKey={CONST_DATA_KEYS.GroupId}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.group.label')}
                />
              )}
            />

            <ControlSelectorItem
              name={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.bmi.label')}
              Icon={SelectorResolver().BmiInput.Icon}
              renderComponent={() => (
                <Element
                  is={BmiInput}
                  dataKey={'BMI'}
                  size={UIEditorBlockWidth.Stretch}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                />
              )}
            />

            <ControlSelectorItem
              name={i18next.t('StudySettingsPage.FormBuilder.blocks.constFields.recordResultList.label')}
              Icon={SelectorResolver().RecordResultList.Icon}
              renderComponent={() => <Element is={RecordResultList} dataKey={CONST_DATA_KEYS.RecordResultList} />}
            />
          </ControlsGroup>

          <ControlsGroup header={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.header')}>
            <ControlSelectorItem
              {...SelectorResolver().TextInput}
              renderComponent={() => (
                <Element
                  is={TextInput}
                  {...textInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.text.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().NumberInput}
              renderComponent={() => (
                <Element
                  is={NumberInput}
                  {...numberInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.number.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().SliderInput}
              renderComponent={() => (
                <Element
                  is={SliderInput}
                  {...sliderInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.slider.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().DateInput}
              renderComponent={() => (
                <Element
                  is={DateInput}
                  {...dateInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.date.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().DateRangeInput}
              renderComponent={() => (
                <Element
                  is={DateRangeInput}
                  {...dateRangeInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.dateRange.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().TimeInput}
              renderComponent={() => (
                <Element
                  is={TimeInput}
                  {...timeInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.time.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().RadioInput}
              renderComponent={() => (
                <Element
                  is={RadioInput}
                  {...radioInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.radioGroup.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().SelectInput}
              renderComponent={() => (
                <Element
                  is={SelectInput}
                  {...selectInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.dropDown.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().CheckBoxInput}
              renderComponent={() => (
                <Element
                  is={CheckBoxInput}
                  {...checkBoxInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.checkBox.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().CheckBoxGroupInput}
              renderComponent={() => (
                <Element
                  is={CheckBoxGroupInput}
                  {...checkBoxGroupInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.checkBoxGroup.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().TagInput}
              renderComponent={() => (
                <Element
                  is={TagInput}
                  {...tagInputDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.tagInput.label')}
                />
              )}
            />
            <ControlSelectorItem
              {...SelectorResolver().Files}
              renderComponent={() => (
                <Element
                  is={Files}
                  {...filesDefaultPropsFactory()}
                  hasSkipCheckBox={hasEcrf}
                  validation={{ isRequired: hasEcrf }}
                  label={i18next.t('StudySettingsPage.FormBuilder.blocks.fields.fileField.label')}
                />
              )}
            />
          </ControlsGroup>
        </PanelContainer>

        <PanelContainer variant={'flex'} header={'Layers'} expanded={false}>
          <Layers expandRootOnLoad={true} renderLayer={LayerItem} />
        </PanelContainer>

        <PanelContainer variant={'flex'} header={'Buffer'} expanded={false}>
          <BufferPanel />
        </PanelContainer>
      </div>
    </>
  );
};
