import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useEditor, Node } from '@craftjs/core';
import { TextProps } from '../../selectors/text';
import Style from './viewport.module.css';
import { CollapsibleProps } from '../../selectors/collapsible';
import { useOnClickOutside } from '../../../../application/hooks/useClickOutside';
import { useContextSelector } from 'use-context-selector';
import { FaqEditorContext } from '../../faqEditor.context';
import { scrollToRef } from '../../../formEditor/uiEditor/uiEditor-helper';
import { useTranslation } from 'react-i18next';
import { AppTextInput } from '../../../uikit/inputs/text/appTextInput.component';

export const SearchPanel = () => {
  const { t } = useTranslation();
  const [searchStr, setSearchStr] = useState<string>();
  const [searchResult, setSearchResult] = useState<Node[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setIsVisible(false);
  });

  const nodeRefs = useContextSelector(FaqEditorContext, (v) => v.nodeRefs);

  const {
    nodes,
    query,
    actions,
    enabled: isEditorMode,
  } = useEditor((state) => {
    return {
      nodes: state.nodes,
      enabled: state.options.enabled,
    };
  });

  // This effect setSearchResult
  useEffect(() => {
    if (!searchStr) {
      setSearchResult([]);
      return;
    }

    const result = Object.values(nodes).filter(
      (node) =>
        node.data.name === 'Text' &&
        (node.data.props as TextProps).text.toLowerCase().includes(searchStr.toLowerCase() ?? ''),
    );

    setSearchResult(result);
    setIsVisible(searchResult.length > 0);
  }, [searchStr]);

  const navigateToNode = useCallback(
    (node: Node) => {
      const collapseParentNode = (parentNodeId: string | undefined) => {
        if (!parentNodeId) {
          return;
        }

        const parentNode = query.node(parentNodeId).get();
        if (parentNode.data.name === 'Collapsible') {
          actions.setProp(parentNodeId, (prop: CollapsibleProps) => (prop.isExpanded = true));
        }

        collapseParentNode(parentNode.data.parent);
      };

      collapseParentNode(node.data.parent);

      setTimeout(() => {
        scrollToRef(nodeRefs[node.id]);

        nodeRefs[node.id].current?.classList.add('component-searched');
        setTimeout(() => {
          nodeRefs[node.id].current?.classList.remove('component-searched');
        }, 500);
      }, 100);
    },
    [actions, nodeRefs, query],
  );

  if (isEditorMode) {
    return <></>;
  }

  return (
    <div className={Style.searchContainer}>
      <AppTextInput
        disabled={isEditorMode}
        type={'search'}
        onFocus={() => setIsVisible(searchResult.length > 0)}
        className={Style.inputField}
        onChange={(x) => setSearchStr(x.target.value)}
        placeholder={t('FaqEditor.SearchPlaceholder')}
        data-test-id={'faq-search'}
      />
      {isVisible && (
        <div className={Style.searchResultContainer} ref={ref}>
          {searchResult.map((node) => (
            <div
              className={Style.searchItem}
              key={node.id}
              onClick={(e) => {
                e.stopPropagation();
                navigateToNode(node);
              }}
            >
              {searchStr && removeTextBefore(node.data.props.text, searchStr)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

/**
 * Removes the text before the fragment and adds an ellipsis if necessary
 */
const removeTextBefore = (text: string, fragment: string): string => {
  const index = text.toLowerCase().indexOf(fragment.toLowerCase());
  if (index === -1) {
    return '';
  }

  const lastSpaceBeforeKey = text.toLowerCase().split(fragment.toLowerCase())[0].lastIndexOf(' ');
  const result = lastSpaceBeforeKey === -1 ? text : `...${text.slice(lastSpaceBeforeKey)}`;

  return result.replace(/<\/?[^>]+(>|$)/g, '');
};
