/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useCallback } from 'react';
import { QueryFactory } from '../../../../../services/api';
import { IImageBlock } from '../../../../forms/base/controls/content/ImageBlock';
import { RPDF } from '../../../react-pdf';

export const ImageBlock: IImageBlock = ({ fileId, isHiddenOnOverview }) => {
  const getImageSource = useCallback(async () => {
    if (!fileId) {
      throw new Error('No file Id');
    }

    const result = await QueryFactory.FilesQuery.Client.getFileById(fileId);
    if (!result?.data) {
      throw new Error("Can't get file from server");
    }

    return URL.createObjectURL(result?.data);
  }, [fileId]);

  if (isHiddenOnOverview) {
    return <RPDF.View />;
  }

  return <RPDF.Image source={getImageSource()} />;
};
