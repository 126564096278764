import { defaultToolSettings } from 'src/features/forms/base/controls/inputs/base/baseControlSettings';
import { createId } from 'src/helpers/typeUtils';
import {
  ApplicableRules,
  ValidationDefaultSettings,
} from 'src/components/formEditor/toolbox/settingsPanel/content/Validation.component';
import { IFormFieldProps } from 'src/features/forms/base/controls/inputs/base/FormFieldProps';
import { UserComponent } from '@craftjs/core';

export interface IDateRangeInputProps extends IFormFieldProps {
  /** @deprecated This prop was used as label text before and replaced by {@link IFormFieldProps.label label}.
   *  The prop was left for backward compatibility. */
  text?: string;

  defaultValueFromIsNow?: boolean;
  defaultValueUntilIsNow?: boolean;
}

export type IDateRangeInput = UserComponent<IDateRangeInputProps>;

export function dateRangeInputDefaultPropsFactory(): IDateRangeInputProps {
  return {
    ...defaultToolSettings,
    dataKey: createId(),
    validation: { ...ValidationDefaultSettings },
  };
}

export const dateRangeApplicableValidationRules: ApplicableRules = {
  isRequired: true,
  maxDate: true,
  minDate: true,
};
