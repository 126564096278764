import React, { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppRoutes, navigateToRoute } from '../navigation/routes';
import { useHasPermissions, useUserRoles } from '../helpers/auth/auth-helper';
import { Permissions } from 'src/services/api/api-client';
import { useScopedTranslation } from '../application/localisation/useScopedTranslation';
import { InformationPageContainer } from 'src/components/informationPageContainer/InformationPage.Container';
import image from 'src/assets/img/patient/empty_table.svg';
import { useStaffProfile } from 'src/helpers/hooks/useStudy';

export const FirstPageResolver = () => {
  const { t } = useScopedTranslation('ProfileProvider');
  const { hasPermission, hasOneOfPermissions } = useHasPermissions();
  const { profile } = useStaffProfile();
  const roles = useUserRoles();
  const studiesCount = useMemo(() => profile?.studies.length ?? 0, [profile?.studies.length]);

  if (!profile || !roles?.length) return <Navigate to={AppRoutes.NoPermission} />;

  if (studiesCount > 1) return <Navigate to={AppRoutes.Studies} />;

  if (hasOneOfPermissions(Permissions.StudyManage, Permissions.UserManage, Permissions.RoleAssign))
    return <Navigate to={AppRoutes.Admin} />;

  if (studiesCount === 0)
    return <InformationPageContainer header={t('NoStudyTitle')} text={t('NoStudyText')} img={image} />;

  const { id: studyId, groups } = profile.studies[0];
  if (!groups.length) return <InformationPageContainer header={t('NoGroups')} text={''} />;

  if (hasPermission(Permissions.PatientView)) return <Navigate to={navigateToRoute.dashboard(studyId)} />;

  if (hasPermission(Permissions.ParticipantsView)) return <Navigate to={navigateToRoute.participants(studyId)} />;

  if (hasPermission(Permissions.StudyConfigure)) return <Navigate to={navigateToRoute.studySettings(studyId)} />;

  return <Outlet />;
};
